import React from "react";
import NavMini from './components/NavMini';
import Entry from "./components/Entry";
import archiveData from "./data/archiveData";

function ArchiveItem({match}){

  const archiveComponents = archiveData.map(item => <Entry key={item.['book-id']} archive={item} />);
  let page = match.params.id;
  let index = parseInt(page, 10); //replace leading zeros
  let archiveItem = archiveComponents[index];

  return(
        <div>
          <NavMini />
          {archiveItem}
        </div>
  )
}

export default ArchiveItem
