import React from "react";

export default function IconMonster() {
    return(
        <div className="svg-icon">
            <svg version="1.1" id="L1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"  viewBox="0 0 70 60" xmlSpace="preserve" preserveAspectRatio="xMinYMinmeet">
              <g id="Monster">
                <g>
                  <path className="cls-1" d="M68.94,24.58a11.18,11.18,0,0,0-13.06-6l-4-3.46c-1.26,1.73,2.22-3.79,3.91-5.1,4.06-3.16,10.91.51,11.22-.32s-4.68-5-10.45-4.23-9,6.09-9.55,7l-3.8-1.55A13.4,13.4,0,0,0,40,10.14h0l-.53-.05h.11c-.12,0-.25,0-.37,0a20,20,0,0,0-4,0l-.21,0h0l-.17,0a19.61,19.61,0,0,0-4.53,0h-.21l0,0-.76.12a11.94,11.94,0,0,0-2.52.63l-.49.2c-.4.15-.79.3-1.16.47L23,12.43c-.5-.87-3.69-6.22-9.55-7S2.7,9,3,9.7s7.16-2.84,11.22.32c1.69,1.31,5.17,6.83,3.91,5.1l-4,3.46a11.18,11.18,0,0,0-13.06,6A11,11,0,0,0,3.22,36.14c4,3.28,9.23,2.16,10.89-.72,1.24-2.15.63-5.49-1.7-7.94a4.15,4.15,0,0,1-2.17,5.37,4.14,4.14,0,0,1-5-1.76,5.74,5.74,0,0,1,2.42-7.24,5.67,5.67,0,0,1,6.56,1l.55-.51c-.42,1.53-.15,5.07.61,9.56.83,4.84.66,7.82,1.65,8.79A4.62,4.62,0,0,0,22.46,49,7.42,7.42,0,0,0,34.54,54.8l.43-.62.54.83a7.41,7.41,0,0,0,12.07-5.75,4.62,4.62,0,0,0,5.48-6.32c1-1,.82-3.94,1.65-8.78.79-4.56,1.88-8.14,1.56-9.64a5.66,5.66,0,0,1,6.12-.67,5.74,5.74,0,0,1,2.42,7.24,4,4,0,0,1-7.22-3.61c-2.33,2.45-2.94,5.79-1.7,7.94,1.66,2.88,6.9,4,10.89.72A11,11,0,0,0,68.94,24.58ZM35.84,50.42h-.42a12.48,12.48,0,0,1-8.13-3.52c1-1.24,2.06-2.48,3.07-3.73a4.41,4.41,0,0,1,2.42-.88,4.53,4.53,0,0,1,3.08,1.1,4.14,4.14,0,0,1,2.63-.87,4.06,4.06,0,0,1,2.41.87l3.52,3.51A12.56,12.56,0,0,1,35.84,50.42Zm5.33-23.33A15.68,15.68,0,0,0,38,31.93c3.42,1,5.06,3.45,4.61,4.75-.37,1.12-2.4,1.83-4.75,1.61-.65.64-1.29,1.3-1.94,1.94l-1.79-2.17c-2.55.31-4.76-.11-5.15-1.15s1.37-3.49,4.93-5.06a13.37,13.37,0,0,0-9.88-8.23A10.49,10.49,0,0,1,32.53,25a9.82,9.82,0,0,1,3.41,4.48,13,13,0,0,1,3-3.3,13.2,13.2,0,0,1,7-2.59A16.12,16.12,0,0,0,41.17,27.09Z"/>
                  <path className="cls-1" d="M39.91,10.1h-.36l.41,0Z"/>
                </g>
              </g>
            </svg>
        </div>
    )

}
