import React from "react";
import { Route, Redirect } from "react-router-dom";
import { Auth } from "./Auth";

export const PrivateRoute = ({ component: Component, ...rest}) => (
    <Route
        {...rest}
        render={props => {

            let authReq = 0;
            if (props.match.path.startsWith("/level"))
                authReq = parseInt(props.match.path.slice(-1))
            else if (props.match.path.startsWith("/archive"))
                authReq = Auth.getLevelNeeded(parseInt(props.match.params.id, 10));


            if (Auth.authLevel < authReq) {
                return <Redirect to={{
                pathname: "/password/" + authReq,
                state: { from: props.location }
                }}
                />
            } else {
                return <Component {...props} />
            }
        }
    } />
)
