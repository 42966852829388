import React from "react";

export default function IconDeer() {

return (
   <div className="svg-icon">
    <svg version="1.1" id="L4" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 70 60" xmlSpace="preserve" preserveAspectRatio="xMinYMinmeet">
      <g id="Deer">
      <path d="M62.69,33.24c-.29-.49-.82-1.09-5.87-3.42-2.45-1.13-4.53-2-6-2.61A25.64,25.64,0,0,0,40.52,18.9a15.9,15.9,0,0,0,.17-9.29A16.45,16.45,0,0,0,32.7,0c-.36,1.15-.94,2.86-1.79,4.89-1.67,4-2.62,4.9-2.77,6.68s.44,4.21,3.91,7.5l-4.56,2.77a16.3,16.3,0,0,0-6.36-6A16.73,16.73,0,0,0,9.4,14.5a52,52,0,0,0,5.87,9.78A51.53,51.53,0,0,0,20.32,30a43,43,0,0,0-7.66,27.2L40.24,60a10.47,10.47,0,0,1-1.51-5.08,10.17,10.17,0,0,1,.49-3.4,8.93,8.93,0,0,1,2.12-3.94c.56-.59,2.1-2,7-2.93a33.82,33.82,0,0,1,9.94-.32C59.17,43.31,64.71,36.77,62.69,33.24ZM22,25.91c-1.69.33-3.39-1.95-5.22-4.4-1.44-1.94-2.25-3-1.92-3.5.87-1.18,9.19,1.7,9.26,5.13A3,3,0,0,1,22,25.91ZM33,12.69c0-1.78,0-7,1.33-7.15,1.13-.17,2.55,3.51,3.1,5.87.42,1.83,1,4.32-.33,5.37a2.69,2.69,0,0,1-3.09,0C33.47,16.39,33,15.64,33,12.69ZM40.36,37c-1.68,2.82-1.92,3.81-3.26,4.4s-3.05.55-3.42-.16c-.77-1.51,5.11-5.53,4.4-6.68-.44-.72-2.75.74-4.07-.33s-1.13-4.35.48-6.35S39,25,40.69,26.07c1,.6,1.61,1.85,1.3,2.28s-1.72-.54-3.26,0-2.64,2.29-2.18,3.28,2.11,1.18,3.16.64.88-1.35,1.79-1.63a1.86,1.86,0,0,1,2,.48C44,32,42.61,33.22,40.36,37Z"/>
      </g>    
    </svg>
  </div>
  )
}
