import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from './Home';
import About from './About';
import Level from './Level';
import ArchiveItem from './ArchiveItem';
import Guide from './Guide';
import {PrivateRoute} from "./components/PrivateRoute";
import Password from "./Password";
import 'react-tabs/style/react-tabs.css';
import './css/frow.css';
import './App.css';

export default function App() {

      return(
        <Router>
          <div id="myApp" className="App">
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/archive" exact component={Level} />
              <PrivateRoute path="/archive/:id" component={ArchiveItem} />
              <PrivateRoute path="/level1" exact component={Level} />
              <PrivateRoute path="/level2" exact component={Level} />
              <PrivateRoute path="/level3" exact component={Level} />
              <PrivateRoute path="/level4" exact component={Level} />
              <Route path="/about" component={About} />
              <Route path="/guide" component={Guide} />
              <Route path="/password/:id" component={Password} />
              <Route path="*" component={() => "404 NOT FOUND"} />
            </Switch>
          </div>
        </Router>
      )
}
