import React, { useRef } from "react"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link, useLocation } from "react-router-dom";
import Monster from './Monster';
import Face from './Face';
import Sun from './Sun';
import Deer from './Deer';
import Goddess from './Goddess';
import Thumbs from './Thumbs';
import IconGallery from './IconGallery';
import IconPDF from './IconPDF';
import { C } from './Common';

function Entry(props){
  let params = new URLSearchParams(useLocation().search);
  let arabic = props.archive['book-languages'][0];
  let farsi = props.archive['book-languages'][1];
  let english = props.archive['book-languages'][2];
  let bookPath = C.cdn + "/books/book-" + props.archive['book-id'] + ".pdf";
  let bookMap = props.archive['book-map'];
  let images = props.archive['images'];
  let bookType = props.archive['book-type'];
  let bookId =  props.archive['book-id'];
  let openImageIndex = (images != null && params.get("i") != null && parseInt(params.get("i")) < images.length) ? parseInt(params.get("i")) : null;
  let defaultTabIndex = (openImageIndex != null) ? 4 : 0; //This must match index of gallery tab

  const scrollMe = useRef(null);

  function forwardWheel(e) {
    if (scrollMe != null)
      scrollMe.current.scrollTop = scrollMe.current.scrollTop + e.deltaY;
  }

  const renderThumbs = () => {
    return <Thumbs bookId={props.archive['book-id']} images={props.archive['images']} openIndex={openImageIndex} />
  }

  const renderMap = () => {
    if (bookMap === "monster"){
      return <Monster/>;
    } else if (bookMap === "face"){
      return <Face/>;
    } else if (bookMap === "sun"){
      return <Sun/>;
    } else if (bookMap === "deer"){
      return <Deer/>;
    }
  };

  const renderLayout = () => {
    if (bookType === "entry") {
      return ( <div className="archive-entry rtl">
        <Tabs defaultIndex={defaultTabIndex}>
          <TabList>
            <Tab tabIndex="0" disabled={ farsi !== 'FA' }>فارسی</Tab>
            <Tab tabIndex="1" disabled={ arabic !== 'AR' }>عربي</Tab>
            <Tab tabIndex="2" disabled={ english !== 'EN' }>EN</Tab>
            <Tab tabIndex="3" ><IconPDF/></Tab>
            <Tab tabIndex="4" disabled={ images == null }><IconGallery/></Tab>
          </TabList>
          <TabPanel>
          <div className="frow">
            <div className="col-md-1-5 key">‏عنوان</div>
            <div className="col-md-4-5 value"><div dangerouslySetInnerHTML={ {__html: props.archive['fa-title']} } /></div>
            <div className="col-md-1-5 key"> نام پديدآور</div>
            <div className="col-md-4-5 value"><div dangerouslySetInnerHTML={ {__html: props.archive['fa-author']} } /></div>
            <div className="col-md-1-5 key">سال نگارش</div>
            <div className="col-md-4-5 value"><div dangerouslySetInnerHTML={ {__html: props.archive['fa-date']} } /></div>
            <div className="col-md-1-5 key">‏آغاز ، انجام ، انجامه</div>
            <div className="col-md-4-5 value"><div dangerouslySetInnerHTML={ {__html: props.archive['fa-begin-end']} } /></div>
            <div className="col-md-1-5 key">‏مشخصات ظاهري</div>
            <div className="col-md-4-5 value"><div dangerouslySetInnerHTML={ {__html: props.archive['fa-physical-description']} } /></div>
            <div className="col-md-1-5 key">يادداشت مشخصات ظاهری</div>
            <div className="col-md-4-5 value"><div dangerouslySetInnerHTML={ {__html: props.archive['fa-binding-notes']} } /></div>
            <div className="col-md-1-5 key"> توضیحات نسخه</div>
            <div className="col-md-4-5 value">{props.archive['fa-version-description']}</div>
            <div className="col-md-1-5 key">زبان:</div>
            <div className="col-md-4-5 value">{props.archive['fa-original-language']}</div>
            <div className="col-md-1-5 key">محل نگهداری</div>
            <div className="col-md-4-5 value"><div dangerouslySetInnerHTML={ {__html: props.archive['fa-physical-location']} } /></div>
            <div className="col-md-1-5 key">‏منبع تهیه دیجیتال</div>
            <div className="col-md-4-5 value"><div dangerouslySetInnerHTML={ {__html: props.archive['fa-digital-source']} } /></div>
            <div className="col-md-1-5 key">معرفی نسخه</div>
            <div className="col-md-4-5 value"><div dangerouslySetInnerHTML={ {__html: props.archive['fa-description']} } /></div>
            <div className="col-md-1-5 key">دانلود</div>
            <div className="col-md-4-5 value"><Link to={{ pathname: bookPath }} target="_blank" rel="noopener noreferrer"><button className="ltr"><IconPDF/> PDF - {props.archive['book-size']}</button></Link></div>
          </div>
          </TabPanel>
          <TabPanel>
          <div className="frow">
            <div className="col-md-1-5 key">العنوان</div>
            <div className="col-md-4-5 value">{props.archive['ar-title']}</div>
            <div className="col-md-1-5 key">اسم المؤلف</div>
            <div className="col-md-4-5 value"><div dangerouslySetInnerHTML={ {__html: props.archive['ar-author']} } /></div>
            <div className="col-md-1-5 key">سنة التأليف</div>
            <div className="col-md-4-5 value"><div dangerouslySetInnerHTML={ {__html: props.archive['ar-date']} } /></div>
            <div className="col-md-1-5 key">البداية والنهاية والهوية</div>
            <div className="col-md-4-5 value"><div dangerouslySetInnerHTML={ {__html: props.archive['ar-begin-end']} } /></div>
            <div className="col-md-1-5 key">المواصفات الظاهرية</div>
            <div className="col-md-4-5 value"><div dangerouslySetInnerHTML={ {__html: props.archive['ar-physical-description']} } /></div>
            <div className="col-md-1-5 key">ملاحظة حول المواصفات الظاهرية</div>
            <div className="col-md-4-5 value"><div dangerouslySetInnerHTML={ {__html: props.archive['ar-binding-notes']} } /></div>
            <div className="col-md-1-5 key">ملاحظات المخطوطة</div>
            <div className="col-md-4-5 value">{props.archive['ar-version-description']}</div>
            <div className="col-md-1-5 key">ملاحظة عامة</div>
            <div className="col-md-4-5 value">{props.archive['ar-original-language']}</div>
            <div className="col-md-1-5 key">مكان الحفظ</div>
            <div className="col-md-4-5 value"><div dangerouslySetInnerHTML={ {__html: props.archive['ar-physical-location']} } /></div>
            <div className="col-md-1-5 key">المصدر الرقمي</div>
            <div className="col-md-4-5 value"><div dangerouslySetInnerHTML={ {__html: props.archive['ar-digital-source']} } /></div>
            <div className="col-md-1-5 key">لمحة عن المخطوطة:</div>
            <div className="col-md-4-5 value"><div dangerouslySetInnerHTML={ {__html: props.archive['ar-description']} } /></div>
            <div className="col-md-1-5 key">تحميل</div>
            <div className="col-md-4-5 value"><Link to={{ pathname: bookPath }} target="_blank" rel="noopener noreferrer" ><button className="ltr"><IconPDF/> PDF - {props.archive['book-size']}</button></Link></div>
          </div>
          </TabPanel>
          <TabPanel>
            <div className="frow ltr">
              <div className="col-md-1-5 key">Title</div>
              <div className="col-md-4-5 value">{props.archive['en-title']}</div>
              <div className="col-md-1-5 key">Author</div>
              <div className="col-md-4-5 value"><div dangerouslySetInnerHTML={ {__html: props.archive['en-author']} } /></div>
              <div className="col-md-1-5 key">Date</div>
              <div className="col-md-4-5 value"><div dangerouslySetInnerHTML={ {__html: props.archive['en-date']} } /></div>
              <div className="col-md-1-5 key">Physical Description</div>
              <div className="col-md-4-5 value"><div dangerouslySetInnerHTML={ {__html: props.archive['en-physical-description']} } /></div>
              <div className="col-md-1-5 key">Binding Note</div>
              <div className="col-md-4-5 value"><div dangerouslySetInnerHTML={ {__html: props.archive['en-binding-notes']} } /></div>
              <div className="col-md-1-5 key">Title in Original Language</div>
              <div className="col-md-4-5 value"><div dangerouslySetInnerHTML={ {__html: props.archive['en-original-language']} } /></div>
              <div className="col-md-1-5 key">Physical Location</div>
              <div className="col-md-4-5 value"><div dangerouslySetInnerHTML={ {__html: props.archive['en-physical-location']} } /></div>
              <div className="col-md-1-5 key">Digital Source</div>
              <div className="col-md-4-5 value"><div dangerouslySetInnerHTML={ {__html: props.archive['en-digital-source']} } /></div>
              <div className="col-md-1-5 key">Description</div>
              <div className="col-md-4-5 value"><div dangerouslySetInnerHTML={ {__html: props.archive['en-description']} } /></div>
              <div className="col-md-1-5 key">Download</div>
              <div className="col-md-4-5 value"><Link to={{ pathname: bookPath }} target="_blank" rel="noopener noreferrer"><button className="ltr"><IconPDF/> PDF - {props.archive['book-size']}</button></Link></div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="frow pdf">
              <div className="col-md-1-5 key">
                <span style={ farsi !== 'FA' ? {display:"none"} : {display:"block"}}>دانلود</span>
                <span style={ arabic !== 'AR' ? {display:"none"} : {display:"block"}}>تحميل</span>
                <span style={ english !== 'EN' ? {display:"none"} : {display:"block"}}>Download</span>
              </div>
              <div className="col-md-4-5 value">
                <span className="helper"></span>
                <Link to={{ pathname: bookPath }} target="_blank" rel="noopener noreferrer"><button className="ltr"><IconPDF/> PDF - {props.archive['book-size']}</button></Link>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="frow gallery">
               {renderThumbs()}
            </div>
          </TabPanel>
        </Tabs>
      </div>
      )
    } else if (bookType === "page" && bookId === "001" ) {
      return <Goddess/>
    }
  }

  return(
    <div className="frow direction-column-reverse-xs direction-column-reverse-sm">
      <div className="col-md-7-12 entry-inner">
        <header className="title-bar rtl">
          <h2 dangerouslySetInnerHTML={ {__html: props.archive['fa-title']} } ></h2>
        </header>
        <main ref={scrollMe}>
          {renderLayout()}
        </main>
      </div>
      <div className="col-md-5-12 figure" onWheel={forwardWheel}>
        {renderMap()}
      </div>
    </div>

  )
}

export default Entry
