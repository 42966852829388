import React from "react";

export default function IconAbout() {

  return(
  <div className="svg-icon">
  <svg version="1.1" id="About" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"  x="0px" y="0px" viewBox="0 0 70 60" xmlSpace="preserve" preserveAspectRatio="xMinYMinmeet">
  <g id="About">
    <path className="stroke" d="M35.13,57.67c-3.89,1-5.94.27-7.11-.59-2.36-1.75-1.6-4.27-4.14-6.22-2.92-2.24-5.67-.19-7.57-2.28-2.14-2.35.34-6.09-1.92-8.09-1.42-1.27-2.94-.25-5-1.19C7.68,38.55,6.69,34,4.62,30.71h0c2.07-3.26,3.06-7.84,4.74-8.59,2.09-.94,3.61.08,5-1.19,2.26-2-.22-5.74,1.92-8.09,1.9-2.09,4.65,0,7.57-2.28,2.54-2,1.78-4.47,4.14-6.22,1.17-.86,3.22-1.58,7.11-.59,3.9-1,5.95-.27,7.12.59,2.36,1.75,1.6,4.27,4.14,6.22,2.92,2.24,5.67.19,7.57,2.28,2.14,2.35-.34,6.09,1.92,8.09,1.42,1.27,2.94.25,5,1.19,1.68.75,2.67,5.33,4.74,8.59h0c-2.07,3.26-3.06,7.84-4.74,8.59-2.09.94-3.61-.08-5,1.19-2.26,2,.22,5.74-1.92,8.09-1.9,2.09-4.65,0-7.57,2.28-2.54,2-1.78,4.47-4.14,6.22-1.17.86-3.22,1.59-7.11.59Z"/>
    <path d="M51.58,26c-1.34-.6-2.31.05-3.22-.76-1.44-1.28.15-3.66-1.22-5.15-1.21-1.34-3,0-4.82-1.46-1.63-1.25-1.14-2.85-2.65-4-.74-.54-2.05-1-4.54-.38-2.48-.63-3.79-.16-4.53.38-1.5,1.12-1,2.72-2.64,4-1.86,1.43-3.61.12-4.83,1.46s.22,3.87-1.22,5.15C21,26,20,25.38,18.7,26c-.54.24-1,1.09-1.42,2.14a23.71,23.71,0,0,1-1.61,3.34c1.33,2.08,2,5,3,5.48,1.33.59,2.31,0,3.21.75,1.44,1.28-.14,3.66,1.22,5.17,1.22,1.32,3,0,4.83,1.44,1.62,1.25,1.14,2.86,2.64,4,.74.56,2,1,4.53.38,2.49.63,3.8.18,4.54-.38,1.51-1.11,1-2.72,2.65-4,1.86-1.43,3.61-.12,4.82-1.44,1.37-1.51-.22-3.89,1.22-5.17.91-.8,1.88-.16,3.22-.75.53-.24,1-1.09,1.41-2.14a24.78,24.78,0,0,1,1.6-3.34C53.27,29.38,52.64,26.46,51.58,26Zm-7.86,7.08c-.23.51-.43.92-.69,1-.64.29-1.11,0-1.54.36-.69.62.07,1.76-.58,2.48s-1.43,0-2.33.7S38,39,37.32,39.53a2.47,2.47,0,0,1-2.19.19A2.43,2.43,0,0,1,33,39.53c-.73-.53-.5-1.3-1.27-1.9s-1.74-.06-2.32-.7.1-1.86-.58-2.48c-.44-.39-.91-.07-1.55-.36s-.82-1.63-1.45-2.63a11.78,11.78,0,0,0,.77-1.61c.22-.5.42-.91.68-1,.64-.29,1.11,0,1.55-.37.68-.61-.08-1.76.58-2.48s1.42,0,2.32-.69.54-1.37,1.27-1.91a2.44,2.44,0,0,1,2.17-.18,2.48,2.48,0,0,1,2.19.18c.72.54.49,1.31,1.26,1.91s1.74.06,2.33.69-.11,1.87.58,2.48c.43.39.9.08,1.54.37s.82,1.63,1.45,2.63A10.54,10.54,0,0,0,43.72,33.06Z"/>
  </g>
  </svg>
  </div>
  )
}
