 import React from "react";

export default function IconClose() {

  return(
                <span className="svg-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 60 60" xmlSpace="preserve" preserveAspectRatio="xMinYMinmeet">
       <path d="M19.87,48.15l-6.59-6c3.4-3.36,6.4-6.44,9.53-9.39,1.54-1.45,1.64-2.56.06-4.06-3.23-3.06-6.34-6.26-9.29-9.2l6.16-6.27c3.07,3.07,6.28,6.18,9.36,9.4,1.43,1.49,2.49,1.43,3.88,0,3.08-3.23,6.26-6.35,9.43-9.54l6.21,6.37c-3,3-6.09,6.14-9.29,9.13-1.62,1.52-1.74,2.66-.06,4.23,3.16,2.93,6.16,6,9.41,9.24l-6.42,6.2c-3-3-6.13-6.07-9.12-9.26-1.54-1.66-2.69-1.65-4.23,0C26,42.09,22.88,45.1,19.87,48.15Z"/>
               </svg>
                  </span>
                  )
}