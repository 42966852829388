import React from "react";

export default function IconSun() {
      return (
              <div className="svg-icon">
    <svg version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 70 60" xmlSpace="preserve" preserveAspectRatio="xMinYMinmeet">
    <g id="Sun">
    <g>
      <path d="M10.4,30.24v2.83l7.56-.4a18.15,18.15,0,0,1-1.1-3.1Z"/>
      <path d="M17.54,19.33,10.77,17.6l-1,2.65,6.84,2.12a15.86,15.86,0,0,1,.51-2C17.27,20,17.41,19.66,17.54,19.33Z"/>
      <path d="M20.56,5.24,18.25,6.87l4.51,5.7a17.35,17.35,0,0,1,2.72-1.72Z"/>
      <path d="M34.68,8.92l-.83-7.13L31,1.83,31.5,9A17.69,17.69,0,0,1,34.68,8.92Z"/>
      <path d="M42.5,1,39.84,0,37,9.06a18.84,18.84,0,0,1,3.1.58Z"/>
      <path d="M48.35,14.48l5.46-3.92-1.4-2.45-6.56,4.22A17.17,17.17,0,0,1,48.35,14.48Z"/>
      <path d="M49.36,6.08,47.29,4.16l-5.16,6.16A17.33,17.33,0,0,1,45,11.81Z"/>
      <path d="M16.4,24.14l-3.9-.37-.56,2.77,4.5.66A16.44,16.44,0,0,1,16.4,24.14Z"/>
      <path d="M15.07,11.52,13,13.45l5,4.84a15.87,15.87,0,0,1,1.71-2.71Z"/>
      <path d="M27.63,2.6l-2.78.53,1.78,7.18a18.17,18.17,0,0,1,3-1Z"/>
      <path d="M52.46,27.41l6.11.06.32-2.81-6.27-.38A15.65,15.65,0,0,1,52.46,27.41Z"/>
      <path d="M57.59,32.24l1.06-2.62-6.2-2.15a16.37,16.37,0,0,1-.75,3.05Z"/>
      <path d="M58.89,22.81V20l-7,.37a15.26,15.26,0,0,1,.66,3.11Z"/>
      <path d="M55.3,37.3l1.47-2.42-5.59-3a18.83,18.83,0,0,1-1.5,2.75Z"/>
      <path d="M55.66,17.29l-1-2.66-4.63,2a15.24,15.24,0,0,1,1.45,2.7Z"/>
      <path d="M52.27,27.41l.18.06v-.06Z"/>
    </g>
    <path d="M51.89,20.26C49.73,13.78,43.11,9.14,35.45,9,27.21,8.15,19.6,13,17.15,20.34c-2.79,8.32,1.7,18,10.59,21.5-.17,9.14-.34,9-.51,18.16h13V42C49.83,38.55,54.63,28.49,51.89,20.26ZM27.1,26a4.84,4.84,0,0,1-5.22-3.13,9.59,9.59,0,0,1,3.55-2.13A9.77,9.77,0,0,1,31,20.56,4.87,4.87,0,0,1,27.1,26Zm12.39,7.07c-2.11-.57-4.51-.35-6.62-.92-.06-.44.15-1.9.08-2.71-.64-7.68,1.14-11-.37-12.48-.81-.78-2.7-1.09-7.47,1.15l-1-1.74a16.55,16.55,0,0,1,4.6-1.91,16.27,16.27,0,0,1,6.71-.19l-.77,15.34,1.92-2.3.77,3.07,1.53-1.92,2.69.38Zm4-9.25A3.87,3.87,0,0,1,40.2,20a6.67,6.67,0,0,1,3.47-.92,6.79,6.79,0,0,1,4.11,1.49A3.88,3.88,0,0,1,43.54,23.82Z"/>
  </g>
  </svg>
</div>
)
}
