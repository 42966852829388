import React, {useState} from "react";
import { Link, useRouteMatch } from "react-router-dom";
import ModalConfirm from './ModalConfirm';

export default function Sun() {
  const curPathID = parseInt(useRouteMatch().params.id, 10);
  const [activeIndex, setActiveIndex] = useState(curPathID);
  const [tOpenModalClickTimestamp, setTOpenModalClick] = useState(0);

  const handleOnClick = index => {
    setActiveIndex(index);
  };

  const tryNextLevel = () => {
     setTOpenModalClick(Date.now());
  }

  const renderConfirmation = () => {
    return <ModalConfirm tForwardOpen={tOpenModalClickTimestamp} curMap={"sun"} />
  }

  return(
    <div className="frow centered">
    <svg version="1.1" id="sun" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="1008px" height="1040px" viewBox="0 200 1008 1040" enableBackground="new 0 0 1008 1040" xmlSpace="preserve">
    <g>
    	<path className="figure-outline" fillRule="evenodd" clipRule="evenodd" d="M524.482,294.475c2.493-1.982,4.985-3.964,8.85-7.038
    		c2.221,14.667,4.227,27.916,6.257,41.328c7.91,1.368,10.119,0.383,14.028-6.434c4.613-8.042,9.442-15.964,13.826-24.127
    		c1.877-3.494,4.036-7.258,0.199-11.338c-1.23-1.307-0.938-4.044-1.439-6.629c5.02,2.814,7.884,7.021,8.399,13.571
    		c0.878,11.139,1.371,22.314,1.756,33.484c0.12,3.486,1.358,5.059,4.741,6.124c12.969,4.083,12.974,4.256,21.072-6.879
    		c5.199-7.148,10.23-14.42,15.932-22.481c-0.224,9.028,6.287,9.135,12.24,9.909c-2.082,4.307-2.082,4.307-15.671,5.741
    		c-4.291,7.169-8.387,14.015-12.604,21.062c5.42,3.58,10.68,7.055,16.524,10.914c6.922-7.46,13.783-14.996,20.843-22.344
    		c1.387-1.443,3.535-2.154,5.332-3.202c0.49,0.597,0.981,1.195,1.473,1.793c-3.363,5.074-6.377,10.43-10.186,15.144
    		c-3.864,4.783-8.514,8.932-13.075,13.621c3.763,4.883,7.075,9.18,10.389,13.481c5.746-1.93,11.396-8.618,16.517-0.744
    		c1.502,2.311,0.998,7.287-0.56,9.835c-1.503,2.459-5.553,3.358-8.831,5.131c1.425,2.652,2.775,5.656,4.605,8.332
    		c0.743,1.086,2.624,2.199,3.824,2.044c10.505-1.358,20.977-2.981,31.451-4.571c3.579-0.544,7.138-1.22,11.617-1.993
    		c-3.101,3.827-5.715,7.054-8.425,10.4c1.742,2.749,3.58,5.652,5.463,8.622c-7.738,2.618-9.22,2.543-12.066-3.936
    		c-2.348-5.345-5.908-6.236-10.812-5.564c-5.623,0.77-11.244,1.561-16.874,2.281c-2.793,0.357-4.381,1.503-3.887,4.653
    		c0.512,3.253,0.812,6.54,1.243,10.133c10.669,2.348,22.784-2.869,33.999,6.35c-6.11,0.655-10.712,1.376-15.338,1.585
    		c-5.13,0.231-10.302-0.264-15.415,0.117c-1.585,0.117-4.386,2.213-4.282,3.137c0.284,2.504,1.278,6.363,2.98,6.986
    		c4.189,1.535,8.916,1.756,13.46,2.141c6.508,0.553,13.044,0.783,19.571,1.15c0.167,0.579,0.332,1.158,0.499,1.736
    		c-3.22,1.586-6.44,3.171-10.342,5.093c1.266,1.996,2.755,4.342,5.112,8.058c-4.32-0.257-8.533,0.708-10.506-0.966
    		c-1.967-1.671-1.69-5.987-2.479-9.504c-4.92-0.374-9.825-0.92-14.742-1.038c-2.144-0.052-5.649,0.138-6.214,1.323
    		c-1.677,3.528-2.369,7.576-3.011,11.493c-0.106,0.645,1.881,1.873,3.084,2.451c4.455,2.141,8.913,4.314,13.513,6.107
    		c3.751,1.463,5.131,3.7,3.512,8.377c-7.395-3.285-14.671-6.518-22.585-10.033c-13.334,21.149-31.349,37.214-54.227,45.824
    		c-1.724,18.989-3.765,37.876-4.998,56.815c-0.509,7.799,0.963,15.708,1.062,23.572c0.054,4.299-0.246,8.804-1.496,12.876
    		c-2.568,8.366-3.225,25.781-0.122,33.298c4.087-3.47,8.083-6.587,11.766-10.038c12.929-12.111,25.831-24.25,38.573-36.554
    		c6.429-6.211,12.41-12.887,18.747-19.199c4.133-4.119,8.701-7.806,12.781-11.974c3.942-4.026,7.651-8.303,11.188-12.694
    		c2.543-3.157-2.341-9.591-7.817-10.187c-6.358-0.691-12.746-1.101-19.113-1.633c0.84-6.775,1.123-7.031,7.805-6.273
    		c6.092,0.69,12.179,1.432,18.28,2.153c0.397-20.122,3.96-27.853,12.857-26.658c-1.553,6.015-3.146,11.953-4.612,17.921
    		c-1.428,5.809-3.135,11.647,0.377,18.824c6.068-6.015,11.632-11.624,17.303-17.12c5.756-5.579,11.619-11.047,17.675-16.792
    		c-6.08-12.5-20.028-8.464-30.569-13.092c3.883-3.53,7.751-5.58,13.006-4.48c4.288,0.899,8.707,1.845,13.035,1.711
    		c5.688-0.174,7.517,2.57,7.717,7.535c0.048,1.179,0.304,2.351,0.641,4.819c23.831-24.394,46.842-47.949,69.736-71.386
    		c-4.167-4.704-7.752-8.578-11.12-12.631c-1.498-1.804-3.339-3.853-3.661-5.997c-0.668-4.413-2.719-5.305-6.734-4.562
    		c-4.765,0.881-8.383-1.995-9.365-6.911c-0.91-4.554,2.8-11.513,6.767-11.844c1.562-0.129,3.79,1.114,4.832,2.43
    		c5.983,7.554,11.551,15.437,17.574,22.956c2.677,3.343,6.09,6.099,9.881,9.823c2.026-3.659,3.095-6.577,4.982-8.798
    		c4.467-5.253,9.327-10.173,14.023-15.231c6.828-7.356,13.745-14.632,20.454-22.095c7.926-8.814,15.485-17.962,23.552-26.643
    		c6.323-6.806,13.043-13.27,19.927-19.51c1.209-1.096,4.103-0.333,7.729-0.495c-4.984,5.828-8.884,10.792-13.21,15.352
    		c-6.411,6.756-13.409,12.968-19.635,19.882c-8.294,9.212-15.673,19.267-24.171,28.275c-27.277,28.916-54.894,57.512-82.365,86.246
    		c-2.532,2.65-4.834,5.528-7.451,8.089c-14.021,13.71-28.088,27.37-42.171,41.014c-9.619,9.317-19.421,18.447-28.904,27.899
    		c-14.074,14.026-27.556,28.669-42,42.298c-14.617,13.792-30.184,26.577-45.097,40.067c-1.746,1.581-1.976,4.837-3.188,8.059
    		c-5.342,0.281-11.866,0.395-18.343,1.009c-19.675,1.865-39.326,3.957-58.997,5.829c-1.45,0.139-3.251-0.405-4.464-1.245
    		c-13.363-9.257-24.76-20.491-35.082-33.111c-9.55-11.675-20.334-22.344-30.595-33.436c-6.599-7.135-13.369-14.116-19.82-21.379
    		c-7.99-8.999-15.454-18.478-23.648-27.279c-10.153-10.908-20.918-21.242-31.229-32.005c-6.18-6.45-11.907-13.331-17.999-19.87
    		c-13.062-14.021-26.297-27.88-39.327-41.93c-9.052-9.761-17.916-19.698-26.794-29.619c-4.455-4.978-8.634-10.207-13.144-15.132
    		c-14.178-15.485-28.33-30.998-42.758-46.247c-5.142-5.435-11.251-9.943-16.535-15.256c-3.537-3.556-6.188-7.976-9.608-11.665
    		c-4.462-4.815-9.408-9.18-13.937-13.937c-6.033-6.338-11.997-12.755-17.693-19.394c-1.387-1.617-1.614-4.229-2.375-6.383
    		c0.512-0.389,1.025-0.776,1.537-1.164c4.23,3.999,9.41,7.379,12.5,12.121c7.065,10.845,15.304,12.547,27.135,7.829
    		c12.831-5.118,14.164-6.083,10.689-19.65c-0.261-1.019-0.47-2.052-0.85-3.725c7.238,3.641,13.858,6.97,20.477,10.3
    		c-0.213,0.562-0.426,1.125-0.64,1.688c-3.139,0.156-6.279,0.311-9.418,0.466c-0.182,0.481-0.364,0.962-0.545,1.444
    		c4.939,2.652,9.879,5.305,14.819,7.958c0.027,0.498,0.056,0.996,0.084,1.494c-3.362,0.609-6.745,1.826-10.083,1.711
    		c-9.529-0.329-18.615,1.292-27.356,4.946c-5.582,2.333-6.086,4.621-1.747,8.726c8.004,7.572,16.168,14.974,24.302,22.479
    		c7.735-6.99,16.848-15.053,25.727-23.365c2.353-2.203,3.672-5.531,6.075-7.652c1.4-1.236,4.01-1.102,6.076-1.584
    		c-0.404,1.886-0.172,4.4-1.314,5.56c-9.459,9.603-19.139,18.989-28.845,28.344c-1.393,1.343-3.281,2.17-5.527,3.612
    		c5.763,5.956,11.563,12.04,17.467,18.022c4.129,4.183,8.358,8.268,12.618,12.317c4.249,4.039,7.774,9.951,14.675,5.851
    		c0.989,5.178,1.876,9.821,2.763,14.465c0.594,0.054,1.188,0.107,1.781,0.161c7.404-14.968,20.368-24.867,32.368-36.622
    		c1.748,3.511,3.337,6.702,5.042,10.125c3.422-0.697,6.739-1.374,10.198-2.08c-2.162,9.766-6.3,10.832-18.91,5.088
    		c-8.332,8.818-16.725,17.702-26.003,27.521c9.086,9.93,18.867,20.619,29.706,32.465c11.086-12.763,21.71-24.995,32.335-37.228
    		c0.76,0.119,1.52,0.238,2.28,0.357c0.507,2.302,1.26,4.586,1.429,6.912c0.1,1.367-1.194,2.857-1.031,4.191
    		c0.232,1.91,0.804,4.125,2.046,5.436c0.73,0.769,3.167,0.397,4.615-0.1c2.398-0.825,4.637-3.082,6.911-3.04
    		c2.424,0.045,4.812,2.059,7.216,3.213c-1.482,1.501-2.815,3.205-4.497,4.432c-1.104,0.804-2.709,0.92-5.327,1.725
    		c4.841,2.454,8.733,4.427,13.015,6.597c-6.397,4.066-12.301,1.452-17.443-0.939c-5.373-2.499-9.923-6.765-12.759-7.884
    		c-8.067,7.049-16.134,14.097-24.504,21.413c15.637,16.759,31.727,34.002,47.971,51.413c10.53-7.869,11.786-21.843,22.318-29.227
    		c1.976,10.013-5.059,16.083-8.978,24.236c8.361-2.236,15.808-4.229,23.14-6.19c2.432,4.406,0.25,5.321-2.467,6.202
    		c-8.845,2.866-17.652,5.849-28.374,9.419c6.778,8.41,12.629,15.669,18.677,23.172c10.086-10.396,16.976-23.657,29.835-33.272
    		c0.31,5.189,0.513,8.605,0.737,12.353c6.85-2.701,13.485-4.61,20.566-1.045c-1.818,8.343-8.851,3.112-13.66,5.606
    		c4.237,3.484,8.269,6.801,12.301,10.118c-0.368,0.502-0.734,1.004-1.102,1.507c-10.024,0.505-17.562-5.08-26.205-11.047
    		c-5.005,5.752-10.419,11.802-15.548,18.084c-0.66,0.808-0.373,3.244,0.387,4.201c2.831,3.566,6.118,6.765,9.095,10.22
    		c6.087,7.068,11.911,14.371,18.162,21.287c8.193,9.064,16.694,17.849,25.782,27.517c8.288-8.475,16.405-16.543,24.119-24.98
    		c0.878-0.962-0.507-3.991-0.857-6.16c5.212-1.942,11.472-4.274,18.818-7.011c-1.553,11.903-12.659,14.328-17.586,21.937
    		c-4.873,7.526-12.149,13.496-18.665,20.461c15.256,18.253,29.567,38.228,48.77,53.654c0.572-0.258,1.142-0.515,1.713-0.774
    		c0-2.468-0.048-4.937,0.008-7.403c0.695-30.632,1.464-61.264,2.082-91.898c0.207-10.224,0.331-20.466-0.071-30.679
    		c-0.223-5.639-3.764-9.131-8.828-12.138c-10.192-6.05-20.353-12.446-29.354-20.082c-4.566-3.874-7.503-10.438-9.522-16.35
    		c-2.527-7.397-7.977-9.092-14.272-9.604c-4.7-0.383-9.491,0.512-14.244,0.546c-2.699,0.02-5.403-0.549-9.672-1.03
    		c9.79-8.654,19.644-6.463,30.187-4.224c-1.826-7.239-3.585-14.206-5.345-21.184c-10.448-3.25-22.096,3.292-33.217-3.758
    		c4.638-1.89,8.334-3.396,12.567-5.12c-1.899-2.544-3.358-4.625-4.951-6.597c-1.664-2.059-2.044-3.986,0.544-5.449
    		c2.182-1.232,4.413-1.951,5.933,1.159c0.719,1.469,1.937,2.69,2.697,4.144c3.415,6.538,9.084,7.772,15.63,7.064
    		c1.354-5.893,2.819-11.535,3.809-17.26c0.17-0.99-1.438-2.913-2.632-3.4c-4.545-1.85-9.191-3.53-13.922-4.825
    		c-7.756-2.122-7.796-1.975-6.382-11.23c5.732,2.264,11.256,4.575,16.873,6.633c8.569,3.14,9.419,2.771,13.935-4.892
    		c1.862-3.158,3.746-6.303,5.421-9.119c-8.885-8.251-21.151-12.13-28.596-23.148c3.293-0.938,5.851-1.665,8.017-2.282
    		c0.52-3.827,0.638-7.478,1.635-10.872c0.505-1.722,2.627-4.212,3.956-4.175c2.322,0.064,5.161,1.275,6.778,2.967
    		c3.792,3.971,4.231,8.159-0.945,11.819c-1.723,1.219-2.973,3.109-4.493,4.749c6.093,4.947,11.888,9.653,18.559,15.067
    		c6.52-6.724,13.163-13.572,20.12-20.746c-7.529-8.484-14.339-16.054-20.955-23.79c-0.591-0.691,0.073-2.455,0.167-3.911
    		c4.356-1.369,7.68-0.718,10.483,3.236c5.151,7.266,10.605,14.32,16.087,21.344c0.535,0.684,2.365,1.053,3.213,0.687
    		c7.708-3.33,15.332-6.855,23.205-10.425c-1.504-7.095-3-14.152-4.588-21.644c-2.25-0.413-4.933-1.405-7.565-1.284
    		c-5.377,0.248-6.116-2.849-5.644-6.938c0.553-4.772-0.182-10.001,4.594-13.398c4.94-3.513,8.291-2.756,10.43,3.028
    		c1.248,3.372,1.468,7.109,2.448,10.598c2.562,9.125,5.287,18.203,7.895,27.118c0.674,0.22,1.015,0.424,1.357,0.429
    		c16.991,0.275,17.657-0.268,14.089-16.639c-1.449-6.65-4.253-13.005-6.44-19.494L524.482,294.475z M574.581,497.796
    		c10.061,0.914,17.083-3.941,24.25-8.394c15.456-9.603,28.851-21.331,34.3-39.309c3.37-11.115,5.305-22.743,7.005-34.269
    		c2.47-16.739-5.173-31.026-13.231-44.657c-3.623-6.13-9.444-11.183-14.973-15.897c-5.074-4.326-10.919-7.856-16.773-11.109
    		c-2.729-1.516-6.548-0.987-9.419-2.345c-15.516-7.337-31.973-7.95-48.427-6.063c-13.563,1.557-26.875,5.214-40.402,7.254
    		c-6.23,0.939-10.922,3.979-15.313,7.825c-7.155,6.266-14.131,12.74-21.058,19.257c-2.092,1.969-4.415,4.096-5.516,6.634
    		c-3.118,7.182-5.837,14.554-8.391,21.962c-5.625,16.313-6.393,32.579,1.057,48.654c2.596,5.602,4.385,11.708,7.739,16.793
    		c3.532,5.356,7.84,10.682,12.987,14.379c7.829,5.622,16.707,9.77,25.072,14.657c5.078,2.967,9.098,6.532,8.832,13.356
    		c-0.163,4.158,1.2,8.355,1.293,12.547c0.279,12.605,0.703,25.234,0.234,37.823c-0.353,9.473-2.642,18.878-2.925,28.348
    		c-0.5,16.667-0.145,33.358-0.106,50.039c0.015,6.812,2.829,8.84,9.741,7.908c11.607-1.564,23.245-2.919,34.894-4.145
    		c7.778-0.817,15.597-1.254,23.617-1.878c0-2.534-0.028-4.538,0.004-6.54c0.15-9.9,0.002-19.818,0.561-29.696
    		c0.58-10.235,2.332-20.413,2.716-30.648c0.775-20.546,0.939-41.114,1.424-61.671C573.852,505.187,574.274,501.769,574.581,497.796z
    		 M567.105,313.068c-2.909,4.714-5.815,9.427-9.146,14.825C565.825,328.547,567.486,325.799,567.105,313.068z"/>
    	<path className="figure-outline" fillRule="evenodd" clipRule="evenodd" d="M288.617,991.281c0-89.689,0-179.25,0-269.185c149.565,0,298.629,0,449.156,0
    		c0,2.99,0,6.07,0,9.151c0,16.164,0,32.328,0,48.492c0,24.246,0,48.493,0,72.738c0,43.735-0.007,87.47,0.006,131.205
    		c0.002,7.583,0.041,7.596-7.696,7.596c-142.17,0.004-284.342,0.002-426.512,0.002C298.644,991.281,293.719,991.281,288.617,991.281
    		z M293.831,986.915c73.434,0,146.35,0,219.521,0c0.113-2.03,0.279-3.652,0.281-5.274c0.02-17.256-0.137-34.514,0.112-51.768
    		c0.065-4.562-1.253-5.907-5.847-5.883c-33.042,0.183-66.086,0.093-99.129,0.109c-1.788,0.001-3.575,0.171-5.954,0.291
    		c0,20.475,0,40.579,0,60.682c-0.691,0.238-1.383,0.478-2.074,0.719c-0.773-1.53-2.2-3.054-2.218-4.593
    		c-0.171-14.5-0.102-29.005-0.103-43.506c0-4.353,0-8.708,0-13.218c-35.226,0-69.794,0-104.59,0
    		C293.831,945.374,293.831,965.887,293.831,986.915z M403.067,795.47c36.957,0,73.542,0,110.138,0c0-22.945,0-45.421,0-67.91
    		c-36.919,0-73.398,0-110.138,0C403.067,750.219,403.067,772.565,403.067,795.47z M732.337,727.573c-35.639,0-70.646,0-105.809,0
    		c0,22.816,0,45.303,0,67.894c35.468,0,70.589,0,105.809,0C732.337,772.64,732.337,750.28,732.337,727.573z M519.622,727.487
    		c0,22.971,0,45.444,0,68.039c34.006,0,67.647,0,101.369,0c0-22.874,0-45.336,0-68.039
    		C587.114,727.487,553.601,727.487,519.622,727.487z M513.317,800.42c-36.945,0-73.413,0-110.136,0c0,20.188,0,40.066,0,60.071
    		c36.855,0,73.332,0,110.136,0C513.317,840.477,513.317,820.725,513.317,800.42z M293.806,795.531c35.048,0,69.628,0,104.272,0
    		c0-22.886,0-45.365,0-67.904c-34.922,0-69.5,0-104.272,0C293.806,750.356,293.806,772.706,293.806,795.531z M621.025,924.456
    		c-33.943,0-67.557,0-101.454,0c0,20.992,0,41.617,0,62.538c33.943,0,67.569,0,101.454,0
    		C621.025,966.023,621.025,945.397,621.025,924.456z M732.293,800.454c-35.607,0-70.594,0-105.666,0c0,20.215,0,40.09,0,59.977
    		c35.436,0,70.426,0,105.666,0C732.293,840.352,732.293,820.596,732.293,800.454z M398.143,800.41c-35.128,0-69.57,0-104.167,0
    		c0,20.184,0,40.067,0,59.951c34.954,0,69.532,0,104.167,0C398.143,840.215,398.143,820.464,398.143,800.41z M732.291,924.476
    		c-35.586,0-70.564,0-105.749,0c0,21.022,0,41.66,0,62.407c35.464,0,70.567,0,105.749,0
    		C732.291,965.915,732.291,945.401,732.291,924.476z M626.604,866.609c0,17.804,0,35.289,0,52.962c35.397,0,70.372,0,105.541,0
    		c0-17.831,0-35.326,0-52.962C696.767,866.609,661.793,866.609,626.604,866.609z M513.246,866.61c-36.864,0-73.328,0-110.069,0
    		c0,17.813,0,35.31,0,53.009c36.834,0,73.296,0,110.069,0C513.246,901.814,513.246,884.293,513.246,866.61z M621.031,800.453
    		c-33.963,0-67.602,0-101.355,0c0,20.2,0,40.061,0,59.894c34.066,0,67.714,0,101.355,0
    		C621.031,840.193,621.031,820.452,621.031,800.453z M621.034,866.645c-33.956,0-67.593,0-101.36,0c0,17.84,0,35.329,0,52.812
    		c34.034,0,67.654,0,101.36,0C621.034,901.649,621.034,884.291,621.034,866.645z M293.963,866.447c0,17.976,0,35.466,0,53.057
    		c34.933,0,69.511,0,103.999,0c0-17.907,0-35.41,0-53.057C363.21,866.447,328.917,866.447,293.963,866.447z"/>
    	<path className="figure-outline" fillRule="evenodd" clipRule="evenodd" d="M868.025,639.446c2.958,0,4.912,0,7.396,0c0,9.424,0.104,18.563-0.033,27.7
    		c-0.112,7.33-0.56,14.654-0.784,21.984c-0.434,14.128-0.244,28.302-1.342,42.377c-2.077,26.612-5.229,53.14-7.254,79.754
    		c-1.208,15.9-1.054,31.903-1.628,47.855c-1.058,29.324-2.211,58.646-3.303,87.971c-0.682,18.298-1.084,36.61-2.031,54.896
    		c-1.621,31.306-3.655,62.589-5.305,93.896c-0.316,6.009,0.454,12.069,0.489,18.108c0.019,3.457-0.613,6.916-0.638,10.375
    		c-0.082,12.479-0.031,24.955-0.031,37.937c-10.14,0-19.044,0.115-27.942-0.032c-9.685-0.158-19.367-0.529-29.048-0.86
    		c-16.865-0.575-33.73-1.183-50.594-1.794c-11.191-0.404-22.377-0.909-33.568-1.251c-39.44-1.205-78.881-2.417-118.325-3.49
    		c-10.054-0.273-20.135,0.291-30.181-0.109c-19.036-0.758-38.056-1.928-57.08-2.942c-6.415-0.342-12.825-0.936-19.243-1.063
    		c-25.514-0.509-51.029-0.895-76.545-1.294c-23.495-0.366-46.996-0.474-70.484-1.086c-32.081-0.836-64.152-2.019-96.226-3.104
    		c-12.65-0.427-25.303-0.851-37.942-1.519c-27.073-1.431-54.137-3.021-80.565-4.509c0-30.784-0.146-60.451,0.059-90.118
    		c0.109-15.782,0.648-31.568,1.307-47.341c0.863-20.705,2.036-41.396,3.068-62.094c0.082-1.651,0.133-3.304,0.149-4.956
    		c0.392-42.052,0.51-84.11,1.283-126.156c0.351-19.058,2.177-38.082,2.965-57.139c0.301-7.301-0.778-14.652-0.623-21.968
    		c0.496-23.277,1.415-46.548,1.894-69.826c0.286-13.904,0.052-27.819,0.052-41.444c7.861-1.966,8.847-1.553,8.622,5.033
    		c-0.82,23.987-1.816,47.969-2.993,71.942c-0.988,20.136-3.037,40.245-3.36,60.386c-0.83,51.597-0.774,103.207-1.44,154.806
    		c-0.383,29.717-1.411,59.426-2.277,89.135c-0.866,29.695-2.044,59.383-2.783,89.081c-0.369,14.843-0.064,29.704-0.064,45.284
    		c10.454,0.827,19.676,1.828,28.926,2.241c31.159,1.395,62.327,2.648,93.496,3.817c21.26,0.8,42.526,1.425,63.792,2.032
    		c28.565,0.815,57.137,1.489,85.702,2.312c33.387,0.962,66.869,0.778,100.128,3.396c30.491,2.399,60.904,1.104,91.342,1.858
    		c32.85,0.813,65.706,1.39,98.546,2.509c31.356,1.07,62.689,2.837,94.041,4.041c17.053,0.652,34.126,0.776,51.189,1.126
    		c2.182,0.044,4.365,0.006,7.32,0.006c0.616-10.935,1.418-21.483,1.773-32.048c1.327-39.409,2.521-78.823,3.742-118.236
    		c0.419-13.523,0.403-27.072,1.275-40.564c0.62-9.62,2.411-19.161,3.639-28.744c0.232-1.812,0.355-3.646,0.384-5.47
    		c0.309-18.91,0.386-37.825,0.951-56.728c0.325-10.868,1.79-21.705,2.091-32.576c0.392-14.084-0.287-28.205,0.271-42.28
    		c0.63-15.909,1.912-31.805,3.325-47.669c0.646-7.27,2.377-14.437,3.418-21.678c0.33-2.297,0.023-4.684-0.005-7.03
    		c-0.073-5.992-0.31-11.987-0.207-17.977c0.338-19.625,0.8-39.247,1.198-58.871C868.069,644.798,868.025,642.259,868.025,639.446z"
    		/>
    	<path className="figure-outline" fillRule="evenodd" clipRule="evenodd" d="M233.973,831.41c1.881,0.321,3.274,0.557,4.667,0.798
    		c8.217,1.424,11.032,4.814,10.873,13.092c-0.124,6.398-2.002,8.991-9.908,10.888c-5.326,1.276-7.334,3.255-5.84,8.912
    		c0.859,3.258-0.051,6.944,0.176,10.416c0.226,3.44,0.744,6.879,1.381,10.271c0.834,4.447,2.402,8.808,2.781,13.28
    		c0.846,9.961,0.541,19.558-2.59,29.599c-2.525,8.094-1.836,18.041,0.199,26.498c2.707,11.246,3.059,22.295,2.672,33.596
    		c-0.106,3.119,0.249,6.257,0.042,9.363c-0.362,5.402-0.178,10.594,4.715,14.104c5.346,3.834,6.026,9.609,6.985,15.393
    		c1.703,10.27,3.571,20.512,5.323,30.773c0.242,1.415,0.214,2.875,0.306,4.235c-6.122,2.089-12.031-2.477-12.72-10.053
    		c-0.342-3.769,0.544-7.632,0.484-11.445c-0.123-7.769-0.51-15.534-0.796-23.503c-6.703,0.819-12.438,1.519-18.471,2.256
    		c1.011,3.365,2.479,4.569,6.36,3.508c1.902-0.519,4.518,1.571,6.811,2.48c-1.974,2.246-3.675,4.867-6.037,6.578
    		c-1.169,0.846-3.559,0.069-5.389-0.076c-6.243-0.497-6.625-0.256-9.182,6.239c7.788,3.072,17.606-2.548,25.095,5.976
    		c-11.528,3.647-21.465-2.699-32.013-2.21c1.711-3.98,3.337-7.762,5.221-12.141c1.182-0.222,3.043-0.57,5.054-0.948
    		c-6.037-8.334-5.771-8.169,2.434-14.656c5.266-4.164,8.897-8.819,8.209-16.238c-1.13-12.185-1.608-24.427-2.446-36.64
    		c-0.111-1.623-0.309-3.492-1.175-4.765c-3.237-4.755-4.636-9.211-1.197-14.746c0.763-1.226-1.701-4.779-3.133-6.963
    		c-1.461-2.227-3.482-4.086-4.656-6.904c1.934,1.056,3.867,2.11,6.219,3.394c2.786-5.61,5.422-10.921,8.246-16.605
    		c-2.986-1.069-5.473-1.73-7.729-2.853c-1.436-0.714-2.529-2.114-3.777-3.208c1.219-0.841,2.343-1.907,3.68-2.47
    		c2.159-0.908,4.458-1.485,7.747-2.538c-4.525-2.694-8.34-4.965-12.368-7.361c1.815-2.059,3.377-3.828,5.268-5.971
    		c-2.438-1.734-4.757-3.385-7.985-5.679c12.062-5.969,8.625-16.44,8.577-26.124c-9.288-4.028-21.744-4.067-22.999-19.19
    		c7.714,0,15.008,0,23.191,0c0.572-3.296,1.826-7.691,1.961-12.121c0.124-4.056-1.523-7.468-5.47-10.031
    		c-3.162-2.055-5.978-5.335-7.724-8.705c-2.436-4.699,0.55-8.676,5.84-8.821c1.835-0.051,3.673-0.008,5.51-0.008
    		c1.784,0.001,3.569,0,5.493,0c-0.133-4.592-1.618-7.597-6.021-9.044c-1.739-0.571-3.049-2.45-4.555-3.731
    		c1.658-0.843,3.282-1.768,4.99-2.491c0.802-0.34,1.767-0.293,2.638-0.418c-0.545-8.77-5.351-11.349-14.863-11.698
    		c-13.488-0.498-27.025,0.361-40.542,0.576c-5.664,0.091-11.329,0.016-17.941-1.082c1.341-1.02,2.582-2.733,4.042-2.945
    		c11.2-1.633,22.411-3.38,33.682-4.27c8.551-0.673,17.198-0.133,26.082-0.133c-2.776-7.056-5.417-13.767-8.583-21.816
    		c5.488-1.313,10.942-2.62,16.421-3.932c-0.156-5.312-2.472-7.963-8.178-7.399c-14.208,1.405-28.438,2.582-42.657,3.877
    		c-6.526,0.593-13.045,1.247-19.62,1.879c-0.964-3.635-0.413-5.585,3.112-5.961c8.547-0.91,17.067-2.093,25.627-2.838
    		c11.137-0.972,22.299-1.658,33.455-2.401c7.578-0.504,10.918-3.158,10.524-10.896c-0.464-9.109-2.322-18.147-3.577-27.215
    		c-0.962-0.072-1.925-0.145-2.888-0.216c-2.326,7.092-4.654,14.184-7.374,22.476c-1.667-1.793-3.81-3.463-5.118-5.636
    		c-3.027-5.028-5.648-10.298-8.434-15.47c-0.471,0.154-0.942,0.308-1.413,0.462c-0.706,2.486-1.412,4.974-2.186,7.698
    		c-6.179-6.868-5.507-9.133,5.673-19.5c-3.25-2.129-6.393-4.188-10.265-6.725c2.668-1.525,4.897-2.799,7.239-4.138
    		c-1.174-1.935-2.192-3.613-3.31-5.453c1.66-1.562,3.236-3.046,5.277-4.967c-2.378-0.743-4.39-1.29-6.34-2.005
    		c-2.049-0.751-4.917-1.094-5.9-2.607c-3.596-5.54-8.701-6.866-16.509-6.195c2.707-3.193,4.294-5.454,6.266-7.305
    		c3.195-3,6.505-5.924,10.054-8.479c1.345-0.968,3.64-1.46,5.24-1.078c5.896,1.411,8.968,6.422,10.012,11.461
    		c2.326,11.231,3.581,22.694,4.957,34.1c0.292,2.425-0.457,5.111-1.25,7.503c-2.018,6.086-1.021,8.594,5.287,11.687
    		c2.119-7.843,4.234-15.671,6.377-23.606c2.214,0,3.995,0,6.148,0c1.139,14.974,12.64,27.372,9.95,43.237
    		c-1.306,7.696-2.565,15.426-3.262,23.191c-0.672,7.507-0.429,15.007,9.436,18.442c-2.916,1.067-4.52,1.654-5.761,2.108
    		c0,5.58,0,10.801,0,16.414c1.436,0.109,3.009,0.229,5.751,0.441c-2.583,2.753-4.818,4.776-6.576,7.151
    		c-0.904,1.223-1.84,3.618-1.261,4.562c7.517,12.238,6.994,24.548,1.089,37.146C235.622,825.437,234.993,828.299,233.973,831.41z
    		 M217.646,746.766c3.907,8.291,7.758,16.458,11.609,24.627c0.571-0.233,1.143-0.467,1.714-0.698
    		c1.292-6.854-0.848-13.171-3.028-19.61C226.228,746.025,223.448,744.442,217.646,746.766z M241.246,837.428
    		c-5.969,2.905-8.738,7.645-9.104,14.141C242.229,851.922,245.241,847.192,241.246,837.428z M195.583,650.315
    		c1.722-1.535,2.672-2.381,3.622-3.228c-1.521-1.272-2.896-2.858-4.633-3.659c-0.678-0.312-2.171,1.143-3.292,1.793
    		C192.559,646.736,193.839,648.251,195.583,650.315z M223.236,849.516c0.159-0.292,0.318-0.585,0.477-0.877
    		c-3.01-1.584-6.021-3.169-9.859-5.189c0.195,2.757,0.303,4.293,0.429,6.066C217.495,849.516,220.366,849.516,223.236,849.516z"/>
    	<path className="figure-outline" fillRule="evenodd" clipRule="evenodd" d="M713.367,1091.792c-8.562-5.297-16.372-10.779-24.749-15.182
    		c-20.681-10.87-42.642-18.053-65.91-20.831c-20.265-2.418-40.693-3.913-60.792,0.168c-12.437,2.525-24.383,7.465-36.554,11.308
    		c-2.594,0.817-5.342,1.285-7.779,2.426c-6.283,2.934-11.84,6.64-12.931,14.375c-0.216,1.542-1.042,2.997-2.063,5.799
    		c-3.356-5.168-6.094-9.383-9.25-14.24c-4.088,3.632-7.876,7.634-12.301,10.731c-3.881,2.715-8.275,5.338-12.807,6.323
    		c-5.469,1.19-9.605-4.158-8.935-10.415c0.27-2.523,1.049-4.992,2.005-9.343c-4.935,3.308-8.79,5.891-12.951,8.68
    		c-0.641-0.981-1.455-2.266-2.307-3.526c-4.081-6.038-9.513-9.209-17.06-9.205c-4.409,0.002-5.833,1.646-6.979,5.717
    		c-1.356,4.815-3.582,9.638-6.541,13.637c-1.33,1.796-5.406,2.262-8.138,2.055c-1.628-0.123-3.66-2.457-4.476-4.266
    		c-1.994-4.419-0.886-10.882-7.589-12.055c-6.973-1.22-12.979,1.5-18.104,6.054c-4.928,4.383-5.01,4.537-6.669-2.178
    		c-1.192-4.827-3.804-5.522-7.592-3.18c-3.42,2.113-6.791,4.391-9.81,7.023c-1.029,0.898-1.469,3.183-1.242,4.691
    		c1.606,10.664,3.704,21.258,5.104,31.946c0.545,4.163,1.791,9.328-5.177,11.933c-1.051-8.446-2.098-16.365-2.989-24.302
    		c-0.264-2.359,0.132-4.799-0.174-7.149c-0.494-3.799-0.256-8.374-2.335-11.073c-1.703-2.21-6.284-2.434-9.677-3.058
    		c-6.276-1.153-10.402-4.196-11.2-10.901c-0.123-1.033-0.828-1.996-1.445-3.405c-8.346,5.527-17.012,6.087-26.769,4.75
    		c-8.097-1.109-16.661,1.194-26.096,2.088c0,19.261,0,38.425,0,58.188c-3.776-0.749-6.379-1.264-8.313-1.648
    		c-5.547-19.446,1.77-38.174,1.196-57.548c-3.053,0.179-5.698,0.334-8.434,0.494c2.005,4.546,3.934,8.92,6.457,14.642
    		c-3.628-0.742-5.208-0.99-6.748-1.393c-7.73-2.018-9.189-4.218-8.121-12.115c0.834-6.162,4.05-8.495,11.063-8.801
    		c12.588-0.553,25.163-1.381,37.745-2.087c0.916-0.052,1.863-0.227,2.747-0.07c14.981,2.654,29.805-0.79,44.702-0.992
    		c7.249-0.099,14.503,1.782,21.783,2.078c8.852,0.358,17.737,0.104,26.597-0.208c1.096-0.039,2.241-1.973,3.159-3.182
    		c2.312-3.047,4.188-6.494,6.824-9.214c3.984-4.106,8.198-3.541,11.438,1.065c1.354,1.927,2.513,3.992,4.67,7.455
    		c-3.701-0.275-6.179-0.396-8.64-0.666c-1.805-0.196-4.761-1.357-5.159-0.75c-1.104,1.686-1.141,4.073-1.493,5.69
    		c8.933-1.021,17.093-2.152,25.291-2.828c7.275-0.597,14.937-3.267,21.318,3.16c0.793,0.8,2.761,0.515,4.193,0.589
    		c13.887,0.719,27.77,1.621,41.667,1.956c3.417,0.082,7.469-0.865,10.228-2.781c14.514-10.085,30.809-15.703,47.705-19.85
    		c21.672-5.316,43.846-4.562,65.728-3.165c24.329,1.554,47.207,9.713,68.576,21.521c8.146,4.501,15.947,9.669,23.677,14.871
    		C715.522,1084.682,715.322,1084.979,713.367,1091.792z M335.03,1071.764c-0.346,0.457-0.692,0.915-1.038,1.373
    		c1.294,1.208,2.408,3.126,3.912,3.51c9.96,2.539,12.691,1.562,15.474-4.883C347.262,1071.764,341.147,1071.764,335.03,1071.764z
    		 M467.023,1086.804c6.146-1.834,11.017-4.31,14.655-9.859C470.488,1075.737,468.025,1077.38,467.023,1086.804z M405.188,1080.796
    		c3.95,0,5.86,0,8.111,0c0.12-2.425,0.225-4.536,0.329-6.646c-0.528-0.355-1.056-0.712-1.584-1.066
    		C410.071,1075.301,408.099,1077.521,405.188,1080.796z"/>
    	<path className="figure-outline" fillRule="evenodd" clipRule="evenodd" d="M795.132,777.022c0.165-3.73,0.095-7.181-4.239-8.504
    		c-15.938-4.863-19.428-18.069-20.996-32.147c-0.543-4.865,0.723-9.932,1.161-14.908c0.686-0.019,1.37-0.036,2.057-0.054
    		c1.458,6.263,2.586,12.629,4.475,18.76c1.707,5.544,3.826,11.055,6.536,16.171c3.028,5.722,7.536,7.249,13.902,5.961
    		c3.199-0.646,6.406-1.248,10.942-2.131c-3.127,4.763-6.716,8.219-7.679,12.296c-0.866,3.672,1.146,8.022,1.967,12.511
    		c-7.858,0-16.276,0-25.637,0c2.001,2.117,3.824,3.604,5.078,5.474c1.877,2.8,3.904,5.694,4.899,8.853
    		c3.21,10.185,10.117,18.715,23.397,15.708c9.427-2.132,19.061-3.435,28.651-4.743c4.588-0.625,9.521-1.613,13.742,2.705
    		c-7.765,5.87-17.141,5.247-25.751,6.63c-8.628,1.383-17.528,1.062-27.199,1.53c-0.258,3.155-1.036,6.563-0.696,9.855
    		c0.61,5.914-1.105,10.081-7.042,11.771c-5.904,1.683-6.053,5.894-5.042,10.718c1.479,7.062,4.272,8.984,12.347,8.135
    		c12.197-1.284,24.379-2.739,36.573-4.058c1.786-0.192,3.61-0.026,5.396-0.026c1.515,5.151-2.214,5.604-5.364,6.129
    		c-14.603,2.434-29.077,5.68-44.103,4.8c-8.597-0.503-9.655,0.893-9.438,9.396c0.124,4.735,1.026,9.447,1.487,14.18
    		c0.195,1.994,0.668,4.162,0.134,5.994c-1.812,6.194-0.719,11.565,4.221,16.579c-4.868,3.424-3.394,7.647-2.856,12.463
    		c0.709,6.355-0.37,13.021-1.466,19.419c-0.56,3.27-0.26,5.413,2.002,7.844c4.478,4.813,8.916,9.721,9.672,16.793
    		c0.417,3.898-0.291,6.679-4.518,7.99c-2.573,0.799-4.919,2.328-8.131,3.904c2.105,5.143,4.146,10.128,6.579,16.068
    		c-1.922,1.361-4.324,3.062-6.76,4.786c2.402,3.809,4.244,6.728,6.271,9.939c-1.806,1.136-3.545,2.229-5.896,3.711
    		c2.851,2.565,5.344,4.812,8.086,7.278c-2.568,3.102-4.992,6.028-7.728,9.335c3.933,4.343,7.615,8.562,11.464,12.618
    		c4.88,5.142,4.081,11.484,3.795,17.655c-0.04,0.848-1.802,2.049-2.952,2.327c-2.813,0.684-5.733,0.924-8.902,1.386
    		c0,2.415,0,4.725,0.001,7.036c4.267,0.998,8.529,1.998,12.795,2.998c0.022,0.63,0.044,1.258,0.066,1.888
    		c-3.235,1.226-6.47,2.449-10.476,3.965c2.693,1.415,4.979,2.616,7.375,3.875c1.604-1.905,3.088-3.667,4.803-5.704
    		c2.24,1.456,4.322,2.808,6.867,4.46c-5.143,8.622-12.843,5.09-19.68,4.956c0.546,11.778,1.087,23.438,1.629,35.083
    		c10.279,2.728,15.383,8.956,14.683,17.862c-6.812,1.239-6.925,1.118-7.171-5.393c-0.08-2.142-0.461-4.273-0.705-6.408
    		c-0.577-0.21-1.153-0.419-1.729-0.629c-1.359,2.609-3.447,5.104-3.878,7.856c-0.45,2.877,0.696,6.004,1.128,8.901
    		c-5.152,1.903-7.491-0.229-7.884-4.651c-1.211-13.658-2.268-27.329-3.316-41.002c-0.18-2.345-0.025-4.715-0.025-7.451
    		c-2.122-0.133-3.899-0.151-5.651-0.37c-7.19-0.899-12.587-10.901-9.027-16.997c0.571-0.979,2.366-1.343,3.668-1.773
    		c3.269-1.08,6.578-2.035,11.151-3.431c-1.849-20.499-4.106-41.048-5.392-61.656c-0.672-10.761,0.594-21.628,0.573-32.446
    		c-0.028-14.67-0.633-29.341-0.646-44.013c-0.01-12.098,0.615-24.198,0.672-36.297c0.021-4.508-0.396-9.095-1.287-13.51
    		c-1.724-8.504-1.026-16.264,4.326-23.51c1.286-1.741,1.172-4.712,1.207-7.123c0.034-2.337-0.837-4.681-0.851-7.022
    		c-0.091-15.21-0.242-30.422,0.058-45.623c0.085-4.319-1.552-6.088-5.409-6.3c-4.806-0.267-5.998-2.864-5.759-7.299
    		c0.489-9.091,2.468-11.285,11.312-11.367C785.549,776.988,790.098,777.022,795.132,777.022z M790.426,835.715
    		c0.755-0.115,1.51-0.23,2.264-0.347c1.098-7.487,1.405-14.845-3.193-21.404c-0.604,0.084-1.206,0.168-1.81,0.251
    		C788.6,821.383,789.514,828.549,790.426,835.715z M788.082,1047.482c-0.485,0.271-0.973,0.542-1.458,0.812
    		c1.464,11.42,2.063,12.461,8.04,12.034C792.342,1055.795,790.213,1051.639,788.082,1047.482z M785.669,973.779
    		c0.76-0.176,1.52-0.353,2.28-0.529c-0.66-3.745-1.32-7.489-1.98-11.234c-0.683,0.121-1.366,0.243-2.05,0.364
    		C784.503,966.181,785.087,969.979,785.669,973.779z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M326.013,447.896c14.855-4.729,28.673-9.127,43.708-13.913
    		c-0.957,12.637-2.578,23.579-9.96,32.396c-5.052,6.034-11.073,5.255-15.841-1.052c-2.634-3.484-5.401-6.187-10.28-6.244
    		C327.196,459.007,328.927,452.937,326.013,447.896z M350.085,451.907c-0.312,4.162-0.578,7.726-0.921,12.306
    		c2.396-0.809,5.234-0.963,5.646-2.043c2.191-5.737,3.773-11.705,5.566-17.594c-0.612-0.424-1.225-0.848-1.837-1.272
    		c-6.9,2.687-13.801,5.373-20.703,8.058c0.174,0.617,0.347,1.234,0.521,1.851C342.029,452.803,345.702,452.395,350.085,451.907z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M272.708,316.942c0,6.454,0,11.54,0,17.849
    		c9.642-5.741,15.052,0.749,20.957,5.804c-6.319,7.315-14.71,7.122-23.544-0.555c-1.231-1.07-2.499-2.115-3.623-3.291
    		c-2.519-2.633-4.731-2.399-7.584-0.15c-8.273,6.521-16.776,12.75-25.255,19.144c-2.438-4.197-0.831-6.792,2.663-9.522
    		C248.194,336.941,259.824,327.352,272.708,316.942z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M812.659,378.385c0.791-4.073,1.543-7.946,2.345-12.069
    		c-1.432-0.294-2.477-0.496-3.517-0.725c-7.979-1.755-9.16-2.91-6.144-10.401c2.399-5.958,6.511-11.251,10.117-16.668
    		c0.547-0.82,2.482-0.716,5.507-1.473c-3.397,7.543-6.147,13.647-8.895,19.75c0.511,0.459,1.022,0.918,1.535,1.378
    		c3.508-4.54,7.017-9.079,10.526-13.617c0.758,0.485,1.518,0.971,2.276,1.457c-0.627,3.597-1.031,7.251-1.932,10.778
    		c-1.519,5.958-3.861,11.748-4.892,17.773C818.763,379.391,816.631,379.659,812.659,378.385z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M770.684,716.087c-0.307-1.455-0.932-2.923-0.869-4.361
    		c0.708-16.648,1.13-33.326,2.576-49.913c0.357-4.099,3.891-8.066,6.475-11.732c0.707-1.004,3.607-1.39,4.868-0.771
    		c0.927,0.456,1.422,3.172,1.042,4.595c-0.648,2.42-2.041,4.67-3.291,6.894c-4.79,8.508-6.966,17.172-5.507,27.284
    		c0.954,6.617-1.276,13.704-2.161,20.569c-0.314,2.432-0.867,4.833-1.311,7.248C771.899,715.964,771.291,716.026,770.684,716.087z"
    		/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M441.832,506.217c1.932,1.278,3.728,2.466,5.991,3.964
    		c-6.153,7.231-12.07,14.09-17.874,21.042c-3.014,3.61-5.943,7.302-8.701,11.11c-2.752,3.798-6.495,4.773-11.401,4.78
    		C420.625,533.332,431.051,520,441.832,506.217z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M457.252,602.302c3.929-6.113,7.083-10.833,10.039-15.676
    		c2.679-4.392,6.024-5.917,10.601-3.193c-2.206,3.509-4.416,7.026-7.18,11.424c5.759-0.679,10.488-1.237,15.521-1.83
    		c0.213,2.507,0.396,4.655,0.633,7.443C477.212,600.47,467.908,604.369,457.252,602.302z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M612.962,555.617c5.936,1.007,11.33,1.459,16.372,3.04
    		c1.675,0.525,3.619,4.08,3.406,6.026c-0.697,6.355-8.99,11.068-15.025,9.093c-4.594-1.502-5.692-5.177-5.529-9.457
    		C612.278,561.827,612.618,559.344,612.962,555.617z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M307.455,383.99c-0.676,3.343-1.351,6.688-2.208,10.925
    		c7.446-3.352,7.77-13.745,16.545-13.996c1.298,12.693-15.749,30.422-25.821,26.599c0.749-1.252,1.303-2.599,2.214-3.63
    		c4.066-4.605,6.16-9.732,5.442-16.038c-0.172-1.506,1.281-3.198,1.986-4.804C306.227,383.361,306.84,383.675,307.455,383.99z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M429.988,556.419c-3.882-11.429,5.179-18.994,14.573-16.597
    		c0.835,0.213,1.905,0.348,2.4,0.921c3.278,3.791,0.438,13.359-4.343,14.817c-1.21,0.369-2.46,0.769-3.706,0.821
    		C436.007,556.502,433.095,556.419,429.988,556.419z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M749.512,444.596c-6.094-9.01-12.008-17.759-18.202-26.92
    		c4.721-1.534,6.99-1.39,9.045,2.247c2.184,3.864,5.679,6.979,8.559,10.458C752.554,434.779,753.424,439.355,749.512,444.596z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M715.451,483.437c-2.19,0-3.954,0-7.034,0c1.082-7.313,1.95-14.264,3.303-21.118
    		c0.191-0.969,2.74-1.472,4.767-2.471C716.122,468.173,715.798,475.533,715.451,483.437z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M194.064,964.292c5.071-2.538,6.217,1.219,8.322,3.586
    		c2.009,2.259,4.463,4.126,6.718,6.163c-5.484,4.638-6.453,4.655-10.119-0.782C197.094,970.45,195.698,967.308,194.064,964.292z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M524.456,294.471c-1.038,3.797-1.752,7.734-3.302,11.309
    		c-0.498,1.15-3.203,1.344-5.507,0.909c2.95-4.066,5.9-8.133,8.843-12.206C524.482,294.475,524.456,294.471,524.456,294.471z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M188.218,754.613c-1.165-1.529-2.331-3.058-3.496-4.588
    		c1.257-0.782,2.514-1.565,3.771-2.347c0.649,1.919,1.3,3.837,1.95,5.755C189.702,753.826,188.96,754.221,188.218,754.613z"/>
    	<path onClick={() => tryNextLevel()} className="clickable nextLevel" fillRule="evenodd" clipRule="evenodd" fill="#000" d="M574.581,497.796c-0.307,3.973-0.729,7.391-0.811,10.817
    		c-0.484,20.557-0.648,41.125-1.424,61.671c-0.384,10.235-2.136,20.413-2.716,30.648c-0.559,9.878-0.41,19.796-0.561,29.696
    		c-0.032,2.002-0.004,4.006-0.004,6.54c-8.021,0.625-15.839,1.062-23.617,1.878c-11.648,1.225-23.286,2.58-34.894,4.145
    		c-6.913,0.932-9.727-1.096-9.741-7.908c-0.038-16.681-0.393-33.373,0.106-50.039c0.283-9.47,2.572-18.875,2.925-28.348
    		c0.469-12.588,0.045-25.217-0.234-37.823c-0.093-4.192-1.456-8.39-1.293-12.547c0.266-6.824-3.754-10.389-8.832-13.356
    		c-8.365-4.888-17.243-9.036-25.072-14.657c-5.147-3.697-9.456-9.023-12.987-14.379c-3.354-5.085-5.143-11.191-7.739-16.793
    		c-7.45-16.076-6.682-32.341-1.057-48.654c2.554-7.408,5.273-14.78,8.391-21.962c1.101-2.539,3.424-4.666,5.516-6.634
    		c6.927-6.518,13.903-12.992,21.058-19.257c4.392-3.846,9.083-6.885,15.313-7.825c13.527-2.04,26.838-5.697,40.402-7.254
    		c16.454-1.887,32.911-1.274,48.427,6.063c2.871,1.358,6.69,0.83,9.419,2.345c5.854,3.253,11.699,6.783,16.773,11.109
    		c5.528,4.715,11.35,9.767,14.973,15.897c8.059,13.631,15.701,27.918,13.231,44.657c-1.7,11.526-3.635,23.153-7.005,34.269
    		c-5.449,17.978-18.844,29.706-34.3,39.309C591.664,493.854,584.642,498.71,574.581,497.796z M536.265,445.015
    		c0-7.993-0.124-16.375,0.025-24.752c0.257-14.275,0.876-28.546,0.963-42.82c0.042-7.042-2.035-8.815-9.162-10.56
    		c-13.804-3.377-26.278,1.662-38.881,5.922c-4.364,1.475-4.126,4.24-1.997,7.749c3.438-1.492,6.515-3.25,9.824-4.175
    		c6.961-1.944,13.994-4.568,21.085-4.878c10.688-0.468,16.45,5.454,15.644,15.825c-1.501,19.314-3.823,38.566-6.009,57.821
    		c-0.301,2.656-0.882,4.76,2.569,5.269c9.727,1.433,19.453,2.86,29.405,4.323c2.033-4.382,4.059-8.75,6.851-14.771
    		c-3.676,1.028-6.303,1.025-7.638,2.338c-1.399,1.377-1.56,4.015-2.737,7.509c-0.762-4.87-1.28-8.183-1.804-11.517
    		C548.172,439.705,545.885,448.753,536.265,445.015z M471.097,403.7c5.669,4.905,10.381,10.596,16.407,13.852
    		c11.668,6.304,35.424-10.803,34.218-24.115c-0.104-1.134-1.675-2.778-2.845-3.074C500.894,385.812,485.591,391.244,471.097,403.7z
    		 M617.67,392.419c-2.071-1.547-3.406-2.846-4.985-3.674c-10.197-5.348-20.25-11.452-32.6-7.585
    		c-7.558,2.366-10.497,10.096-6.461,16.981c3.789,6.46,8.104,8.474,15.991,7.323C600.066,403.938,609.91,401.08,617.67,392.419z
    		 M614.114,379.262c0.919-3.445,0.513-6.956-3.74-8.567c-5.598-2.12-11.234-4.281-17.035-5.686
    		c-8.979-2.175-18.082-2.939-26.685,1.709c-3.416,1.846-6.687,3.954-4.74,8.697c9.102-1.796,18.109-5.414,26.802-4.771
    		C597.345,371.285,605.656,376.212,614.114,379.262z M532.072,464.83c-0.354,1.494-0.673,2.545-0.848,3.62
    		c-1.019,6.258,0.494,8.215,6.838,8.754c3.28,0.277,6.569,0.575,9.855,0.604c9.238,0.084,13.293-4.974,11.837-14.729
    		c-7.904-2.173-6.135,6.929-10.312,9.062c-5.486-8.438-5.486-8.438-11.735-2.216C536.023,468.399,534.317,466.859,532.072,464.83z"
    		/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="#000" d="M293.831,986.915c0-21.028,0-41.541,0-62.44
    		c34.796,0,69.364,0,104.59,0c0,4.51,0,8.865,0,13.218c0,14.501-0.068,29.006,0.103,43.506c0.019,1.539,1.445,3.062,2.218,4.593
    		c0.691-0.241,1.382-0.48,2.074-0.719c0-20.103,0-40.207,0-60.682c2.379-0.12,4.166-0.29,5.954-0.291
    		c33.043-0.017,66.087,0.073,99.129-0.109c4.594-0.024,5.913,1.321,5.847,5.883c-0.249,17.254-0.093,34.512-0.112,51.768
    		c-0.002,1.622-0.168,3.244-0.281,5.274C440.181,986.915,367.265,986.915,293.831,986.915z M341.603,944.91
    		c-0.95-0.662-2.864-2.534-5.158-3.417c-1.652-0.638-4.712-0.682-5.518,0.319c-1.028,1.277-0.815,3.967-0.373,5.869
    		c0.278,1.194,2.039,1.994,3,3.087c4.042,4.61,8.309,9.062,11.925,13.989c1.765,2.402,1.777,6.086,3.53,8.504
    		c1.3,1.793,4.371,3.742,6.22,3.385c3.436-0.663,3.91-3.721,2.463-7.008c-1.641-3.725-3.003-7.574-4.407-11.169
    		c4.479-1.032,8.344-2.246,12.299-2.737c3.446-0.426,6.458-1.021,6.176-5.201c-0.28-4.161-3.653-4.53-6.905-4.592
    		c-3.505-0.067-7.013-0.016-11.485-0.016c1.223-1.305,1.416-1.632,1.702-1.798c2.189-1.266,4.493-2.372,6.474-3.902
    		c0.242-0.187-0.957-3.487-1.943-3.773c-2.576-0.747-6.031-1.723-7.997-0.604C348.092,937.845,345.415,941.316,341.603,944.91z
    		 M455.152,937.87c-6.235,0.042-11.249,4.281-11.36,9.607c-0.139,6.729,4.367,11.557,11.207,11.263
    		c3.98-0.173,6.027,0.906,6.804,4.962c0.992,5.177,2.239,10.342,3.886,15.343c0.616,1.873,2.663,3.276,4.056,4.893
    		c1.419-1.684,3.659-3.201,4.047-5.098c0.479-2.341-0.439-5.029-1.004-7.512c-1.654-7.271-3.559-14.485-5.123-21.773
    		C466.124,942.376,461.432,937.828,455.152,937.87z"/>
    <Link to={"/archive/033"} className={activeIndex === 33 ? "active" : "inactive"} onClick={() => handleOnClick(33) } >
    	<path className="clickable reverse" fillRule="evenodd" clipRule="evenodd" fill="#000" d="M403.067,795.47c0-22.904,0-45.251,0-67.91
    		c36.74,0,73.219,0,110.138,0c0,22.489,0,44.965,0,67.91C476.608,795.47,440.024,795.47,403.067,795.47z M469.064,751.635
    		c0.761-0.373,1.588-0.654,2.271-1.139c1.776-1.256,4.002-2.299,5.017-4.033c0.545-0.93-0.707-4.184-1.727-4.494
    		c-2.19-0.669-5.342-0.993-7.041,0.137c-3.226,2.144-6.347,5.016-8.355,8.294c-3.444,5.628-1.01,9.928,5.608,10.876
    		c3.347,0.479,6.751,0.542,10.244,0.801c-1.571,4.195-3.255,7.682-4.086,11.359c-0.394,1.746,0.842,3.858,1.339,5.805
    		c2.452-0.78,6.137-0.812,7.144-2.472c4.14-6.826,7.607-14.081,10.972-21.34c0.657-1.422,0.405-4.421-0.585-5.173
    		c-1.398-1.064-4.909-1.679-5.714-0.812C479.739,754.192,474.352,752.262,469.064,751.635z M452.838,777.243
    		c-2.161-9.987-3.873-18.774-6.089-27.431c-0.463-1.808-2.788-3.138-4.254-4.688c-1.235,1.797-3.681,3.726-3.501,5.369
    		c0.983,9.057,2.385,18.091,4.23,27.014c0.313,1.518,3.478,3.386,5.305,3.364C450.088,780.854,451.618,778.353,452.838,777.243z"/>
    </Link>
    <Link to={"/archive/031"} className={activeIndex === 31 ? "active" : "inactive"} onClick={() => handleOnClick(31) } >
    	<path className="clickable reverse" fillRule="evenodd" clipRule="evenodd" fill="#000" d="M732.337,725.815c0,22.708,0,45.067,0,67.896
    		c-35.22,0-70.341,0-105.809,0c0-22.592,0-45.078,0-67.896C661.691,725.815,696.698,725.815,732.337,725.815z M674.441,773.439
    		c-2.856-9.695-5.172-18.489-8.185-27.038c-0.651-1.848-3.971-4.121-5.785-3.902c-3.643,0.439-3.8,3.844-2.985,6.951
    		c2.118,8.067,4.686,16.043,6.265,24.212c0.8,4.148,3.206,4.21,5.802,3.59C671.272,776.841,672.631,774.921,674.441,773.439z
    		 M685.826,766.693c0.206-0.059,0.411-0.118,0.617-0.178c-1.649-7.407-3.128-14.863-5.095-22.187
    		c-0.389-1.444-2.752-3.396-4.044-3.282c-1.427,0.125-3.444,2.183-3.79,3.717c-0.436,1.932,0.541,4.19,0.945,6.302
    		c0.883,4.62,1.939,9.213,2.625,13.862c0.396,2.69-0.271,5.603,0.454,8.157c0.473,1.657,2.594,3.942,3.977,3.943
    		c1.436,0.001,3.514-2.17,4.143-3.831C686.381,771.281,685.826,768.883,685.826,766.693z"/>
    </Link>
    <Link to={"/archive/024"} className={activeIndex === 24 ? "active" : "inactive"} onClick={() => handleOnClick(24) } >
    	<path className="clickable reverse" fillRule="evenodd" clipRule="evenodd" fill="#000" d="M519.622,727.487c33.979,0,67.492,0,101.369,0
    		c0,22.703,0,45.165,0,68.039c-33.722,0-67.363,0-101.369,0C519.622,772.932,519.622,750.458,519.622,727.487z M573.401,753.6
    		c-0.5,0.218-1.001,0.436-1.502,0.652c-1.039-1.478-1.847-3.229-3.177-4.368c-1.624-1.395-4-3.455-5.477-3.064
    		c-1.637,0.435-3.821,3.435-3.649,5.101c0.359,3.494,1.322,7.466,3.434,10.135c5.127,6.48,8.087,13.305,7.913,21.694
    		c-0.036,1.735,2.733,3.528,4.21,5.295c1.331-1.878,3.896-3.843,3.771-5.62c-0.479-6.812-1.822-13.564-2.923-20.868
    		c6.199-0.862,8.212-5.966,7.855-12.144c-0.117-2.047-2.14-3.984-3.292-5.97c-2.146,1.267-4.677,2.181-6.301,3.934
    		C573.264,749.455,573.643,751.815,573.401,753.6z"/>
    </Link>
    <Link to={"/archive/027"} className={activeIndex === 27 ? "active" : "inactive"} onClick={() => handleOnClick(27) } >
    	<path className="clickable reverse" fillRule="evenodd" clipRule="evenodd" fill="#000" d="M513.317,800.42c0,20.305,0,40.057,0,60.071
    		c-36.804,0-73.28,0-110.136,0c0-20.005,0-39.884,0-60.071C439.905,800.42,476.373,800.42,513.317,800.42z M453.08,830.697
    		c-0.831-2.767-1.081-5.287-2.304-7.179c-1.132-1.753-3.158-3.538-5.076-3.939c-1.134-0.237-4.057,2.206-4.039,3.41
    		c0.062,4.126,0.259,8.603,1.907,12.269c2.513,5.594,6.02,10.812,9.655,15.791c1.264,1.732,4.477,3.547,6.235,3.1
    		c3.55-0.902,3.472-4.196,2.006-7.206c-1.336-2.748-2.757-5.454-4.664-9.21c4.535-1.856,8.657-3.376,12.615-5.239
    		c1.782-0.84,4.36-2.164,4.625-3.625c0.672-3.708,0.771-7.727,0.01-11.395c-0.327-1.568-3.37-2.572-5.177-3.831
    		c-1.04,1.792-2.431,3.483-2.995,5.415c-0.37,1.27,0.453,2.89,0.609,3.679c-2.314-1.123-6.181-3.602-6.607-3.107
    		C457.43,822.463,455.797,826.003,453.08,830.697z"/>
    </Link>
    <Link to={"/archive/026"} className={activeIndex === 26 ? "active" : "inactive"} onClick={() => handleOnClick(26) } >
    	<path className="clickable reverse" fillRule="evenodd" clipRule="evenodd" fill="#000" d="M293.806,795.531c0-22.825,0-45.175,0-67.904
    		c34.772,0,69.35,0,104.272,0c0,22.539,0,45.019,0,67.904C363.434,795.531,328.854,795.531,293.806,795.531z M331.978,763.951
    		c0.151,2.746,0.102,4.23,0.334,5.67c1.206,7.479,3.596,9.582,11.101,9.46c6.404-0.104,12.813-0.325,19.206-0.729
    		c6.281-0.396,9.979-5.491,8.948-11.922c-1.291-8.05-5.427-14.779-10.807-20.5c-2.599-2.762-6.705-4.976-10.394-5.478
    		c-1.83-0.249-4.234,3.964-6.445,6.088c-0.496,0.478-1.287,0.634-1.914,0.986C335.111,751.413,332.822,757.883,331.978,763.951z"/>
    </Link>
    <Link to={"/archive/034"} className={activeIndex === 34 ? "active" : "inactive"} onClick={() => handleOnClick(34) } >
    	<path className="clickable reverse" fillRule="evenodd" clipRule="evenodd" fill="#000" d="M621.025,924.456c0,20.941,0,41.567,0,62.538
    		c-33.885,0-67.511,0-101.454,0c0-20.921,0-41.546,0-62.538C553.469,924.456,587.082,924.456,621.025,924.456z M577.331,964.691
    		c-1.407-2.022-2.37-3.792-3.69-5.229c-3.437-3.745-6.839-7.562-10.629-10.929c-2.601-2.312-5.896-2.691-7.999,0.836
    		c-1.936,3.249,0.182,5.189,2.734,7.239c6.682,5.369,13.344,10.765,16.204,19.505c0.551,1.682,3.469,3.402,5.408,3.545
    		c1.385,0.1,3.587-2.132,4.339-3.809c3.058-6.822,5.567-13.886,8.546-20.747c1.312-3.019,2.035-5.802-1.219-7.675
    		c-3.646-2.1-5.877,0.205-7.357,3.313C581.565,955.151,579.617,959.638,577.331,964.691z"/>
    </Link>
    <Link to={"/archive/025"} className={activeIndex === 25 ? "active" : "inactive"} onClick={() => handleOnClick(25) } >
    	<path className="clickable reverse" fillRule="evenodd" clipRule="evenodd" fill="#000" d="M732.293,800.454c0,20.142,0,39.897,0,59.977
    		c-35.24,0-70.23,0-105.666,0c0-19.887,0-39.762,0-59.977C661.699,800.454,696.686,800.454,732.293,800.454z M675.742,824.15
    		c1.124,1.312,1.857,2.089,2.502,2.934c4.855,6.348,9.486,12.889,14.693,18.934c1.175,1.364,4.334,1.021,6.581,1.464
    		c0.229-2.428,0.661-4.862,0.586-7.28c-0.028-0.884-1.271-1.719-1.936-2.598c-6.581-8.712-13.087-17.483-19.807-26.088
    		c-0.977-1.249-3.29-2.677-4.425-2.307c-2.023,0.661-4.548,2.28-5.24,4.111c-3.28,8.686-6.37,17.483-8.652,26.463
    		c-0.496,1.952,2.605,4.817,4.05,7.262c1.809-1.151,4.604-1.906,5.253-3.523C671.784,837.454,673.519,831.104,675.742,824.15z"/>
    </Link>
    <Link to={"/archive/030"} className={activeIndex === 30 ? "active" : "inactive"} onClick={() => handleOnClick(30) } >
    	<path className="clickable reverse" fillRule="evenodd" clipRule="evenodd" fill="#000" d="M398.143,800.41c0,20.054,0,39.805,0,59.951
    		c-34.634,0-69.212,0-104.167,0c0-19.884,0-39.768,0-59.951C328.572,800.41,363.014,800.41,398.143,800.41z M336.032,852.176
    		c-0.636-2.97-1.002-4.929-1.479-6.86c-1.925-7.814-4.03-15.587-5.762-23.441c-0.772-3.505-1.983-6.146-6.034-5.331
    		c-3.711,0.746-4.394,3.838-3.623,6.891c2.462,9.755,4.993,19.505,8.041,29.085c0.543,1.709,3.561,3.133,5.672,3.583
    		C333.654,856.274,335.124,853.352,336.032,852.176z M342.125,839.094c-0.097,6.346,3.269,9.948,9.526,10.198
    		c5.602,0.223,9.135-3.253,9.307-9.157c0.186-6.369-4.77-11.015-12.125-11.37C344.985,828.579,342.22,832.836,342.125,839.094z"/>
    </Link>
      <Link to={"/archive/023"} className={activeIndex === 23 ? "active" : "inactive"} onClick={() => handleOnClick(23) } >
    	<path className="clickable reverse" fillRule="evenodd" clipRule="evenodd" fill="#000" d="M732.291,924.476c0,20.926,0,41.439,0,62.407
    		c-35.182,0-70.285,0-105.749,0c0-20.747,0-41.385,0-62.407C661.727,924.476,696.705,924.476,732.291,924.476z M694.586,943.095
    		c-0.008-0.44-0.016-0.881-0.026-1.321c2.437-0.27,4.907-0.365,7.299-0.85c3.229-0.653,4.88-2.941,3.837-6.022
    		c-0.461-1.362-2.811-3.182-4.049-3.006c-8.293,1.175-17.047,1.966-19.775,12.585c-0.683-0.92-1.143-1.303-1.296-1.782
    		c-0.912-2.854-1.67-6.196-5.782-4.431c-3.64,1.561-3.455,4.903-2.462,8.039c0.48,1.521,1.784,2.761,2.492,4.234
    		c3.418,7.12,7.008,14.177,10.013,21.47c1.321,3.208,2.817,5.314,6.322,4.351c3.518-0.964,4.394-3.754,3.079-6.962
    		c-1.835-4.472-3.946-8.829-6.062-13.505c0.722-0.117,1.382-0.278,2.05-0.325c4.555-0.326,9.222-0.154,13.617-1.168
    		c1.789-0.412,4.212-3.456,4.11-5.167c-0.104-1.693-2.771-3.842-4.774-4.662C700.551,943.501,697.472,943.536,694.586,943.095z
    		 M651.879,938.116c-6.697-0.691-9.041,0.903-7.589,6.722c2.332,9.342,5.452,18.491,8.365,27.684
    		c1.138,3.589,3.942,3.343,6.006,1.364c2.685-2.575,4.778-5.734,1.192-9.479c-1.057-1.103-1.831-2.675-2.238-4.166
    		C655.665,953.104,653.883,945.919,651.879,938.116z"/>
    </Link>
    <Link to={"/archive/028"} className={activeIndex === 28 ? "active" : "inactive"} onClick={() => handleOnClick(28) } >
    	<path className="clickable reverse" fillRule="evenodd" clipRule="evenodd" fill="#000" d="M626.604,866.609c35.189,0,70.163,0,105.541,0
    		c0,17.636,0,35.131,0,52.962c-35.169,0-70.144,0-105.541,0C626.604,901.898,626.604,884.413,626.604,866.609z M681.076,913.634
    		c1.824-3.055,3.809-4.82,3.651-6.364c-1-9.775-2.384-19.516-3.853-29.234c-0.419-2.771-1.607-5.705-5.314-4.649
    		c-3.364,0.957-4.026,3.45-3.336,6.93c1.272,6.397,1.988,12.904,2.947,19.363c0.43,2.889,0.592,5.866,1.453,8.621
    		C677.1,909.818,678.771,910.961,681.076,913.634z"/>
    </Link>
    <Link to={"/archive/032"} className={activeIndex === 32 ? "active" : "inactive"} onClick={() => handleOnClick(32) } >
    	<path className="clickable reverse" fillRule="evenodd" clipRule="evenodd" fill="#000" d="M513.246,866.61c0,17.683,0,35.204,0,53.009
    		c-36.772,0-73.235,0-110.069,0c0-17.699,0-35.195,0-53.009C439.918,866.61,476.382,866.61,513.246,866.61z M455.079,886.378
    		c-0.106-0.613-0.214-1.227-0.32-1.842c1.026-0.331,2.227-0.431,3.043-1.043c1.732-1.301,4.68-3.02,4.547-4.296
    		c-0.203-1.928-2.254-4.411-4.15-5.27c-4.998-2.264-9.446,0.456-11.752,6.486c-2.739,7.163-1.095,12.715,4.727,14.689
    		c3.524,1.194,7.286,1.691,11.672,2.664c-2.325,4.18-4.271,8.069-6.589,11.722c-1.739,2.739-3.027,5.249,0.314,7.512
    		c2.98,2.021,5.454,0.61,6.857-2.083c4.08-7.844,8.021-15.77,11.662-23.822c0.542-1.196-0.352-3.806-1.451-4.765
    		c-1.083-0.945-3.982-1.462-4.856-0.721C464.083,889.604,459.617,886.547,455.079,886.378z"/>
    </Link>
    	<path fillRule="evenodd" clipRule="evenodd" fill="#000" d="M621.031,800.453c0,19.999,0,39.74,0,59.894
    		c-33.642,0-67.289,0-101.355,0c0-19.833,0-39.693,0-59.894C553.43,800.453,587.068,800.453,621.031,800.453z M574.084,821.704
    		c-0.057-0.771-0.015-1.708-0.212-2.593c-0.725-3.25-2.164-6.728-5.963-5.455c-1.92,0.646-4.027,4.301-3.957,6.529
    		c0.128,4.076,1.591,8.177,2.898,12.134c1.767,5.341,3.999,10.526,5.854,15.839c0.817,2.34,0.761,5.99,4.32,4.714
    		c1.862-0.668,4.135-3.932,3.956-5.806c-0.439-4.623-2.189-9.12-3.56-14.216c4.527-0.618,9.407-1.162,14.241-1.975
    		c5.508-0.926,7.908-4.318,6.987-9.756c-0.61-3.608-1.536-7.162-2.186-10.765c-0.56-3.111-1.896-5.116-5.447-4.786
    		c-3.721,0.345-4.464,2.869-4.098,5.867c0.409,3.363,1.194,6.682,1.815,10.021c-0.547,0.328-1.093,0.655-1.642,0.983
    		c-1.072-1.449-1.996-3.049-3.256-4.312c-1.415-1.418-2.999-3.189-4.765-3.549c-1.186-0.24-3.192,1.438-4.133,2.758
    		C574.133,818.471,574.317,820.311,574.084,821.704z M540.026,809.903c-2.491,3.334-4.456,4.893-4.869,6.791
    		c-0.52,2.383-0.195,5.168,0.515,7.559c2.597,8.742,5.48,17.399,8.328,26.063c0.328,1,0.916,2.356,1.722,2.666
    		c1.937,0.741,4.075,0.958,6.131,1.388c0.416-2.121,1.597-4.434,1.12-6.331c-2.558-10.173-5.424-20.274-8.434-30.325
    		C543.87,815.48,542.18,813.554,540.026,809.903z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="#000" d="M621.034,866.645c0,17.646,0,35.005,0,52.812
    		c-33.706,0-67.326,0-101.36,0c0-17.483,0-34.973,0-52.812C553.441,866.645,587.078,866.645,621.034,866.645z M583.831,886.231
    		c-2.344-1.669-4.513-2.6-5.742-4.234c-1.723-2.294-2.628-5.878-6.395-4.022c-3.558,1.755-2.799,4.87-1.533,8.059
    		c2.877,7.242,5.731,14.511,8.112,21.925c1.287,4.012,4.231,4.108,6.823,3.146c1.368-0.509,2.735-3.672,2.471-5.383
    		c-0.606-3.901-2.219-7.645-3.481-11.637c2.339-0.862,4.889-1.438,7.055-2.684c3.589-2.063,4.709-8.197,2.185-13.447
    		c-0.729-1.517-3.413-3.243-4.936-3.024C582.606,875.756,585.26,880.854,583.831,886.231z M544.916,884.456
    		c1.461,8.252,2.55,16.401,4.583,24.309c0.443,1.73,4.319,3.726,6.504,3.604c3.629-0.204,3.166-3.088,2.44-6.211
    		c-1.888-8.148-3.018-16.473-4.83-24.643c-0.348-1.575-2.215-3.356-3.778-3.903c-0.871-0.304-2.862,1.527-3.794,2.801
    		C545.244,881.504,545.239,883.17,544.916,884.456z"/>
    <Link to={"/archive/029"} className={activeIndex === 29 ? "active" : "inactive"} onClick={() => handleOnClick(29) } >
    	<path className="clickable reverse" fillRule="evenodd" clipRule="evenodd" fill="#000" d="M293.963,866.447c34.954,0,69.247,0,103.999,0
    		c0,17.646,0,35.149,0,53.057c-34.488,0-69.066,0-103.999,0C293.963,901.913,293.963,884.423,293.963,866.447z M366.934,912.091
    		c4.99-1.013,10.02-1.868,14.961-3.084c4.623-1.138,6.019-4.483,4.969-8.872c-0.507-2.117-1.087-4.367-2.252-6.155
    		c-4.951-7.595-10.022-15.116-15.349-22.45c-0.799-1.099-3.303-1.636-4.79-1.33c-1.044,0.217-1.829,2.244-2.507,3.576
    		c-1.855,3.644-3.049,7.772-5.51,10.922c-3.241,4.149-5.276,14.409-2.757,20.206C356.199,910.659,360.88,912.342,366.934,912.091z
    		 M330.592,874.037c-5.447,0.105-6.842,3.531-5.435,7.817c2.217,6.742,4.09,13.599,6.073,20.417
    		c1.167,4.015,1.916,8.202,3.588,11.987c0.549,1.244,3.872,2.459,5.085,1.892c1.315-0.617,2.579-3.516,2.26-5.095
    		c-2.023-9.987-4.25-19.951-6.959-29.771C334.396,878.354,331.827,875.908,330.592,874.037z"/>
    </Link>
    	<path fillRule="evenodd" clipRule="evenodd" fill="#000" d="M787.949,973.25c-0.761,0.177-1.521,0.354-2.28,0.529
    		c-0.582-3.8-1.166-7.599-1.75-11.399c0.684-0.121,1.367-0.243,2.05-0.364"/>
      <path onClick={() => tryNextLevel()} className="clickable" fillRule="evenodd" clipRule="evenodd" d="M536.265,445.015c9.62,3.738,11.907-5.311,18.139-6.717
    		c0.523,3.334,1.042,6.647,1.804,11.517c1.178-3.495,1.338-6.132,2.737-7.509c1.335-1.313,3.962-1.31,7.638-2.338
    		c-2.792,6.021-4.817,10.389-6.851,14.771c-9.952-1.463-19.679-2.891-29.405-4.323c-3.451-0.508-2.87-2.612-2.569-5.269
    		c2.186-19.254,4.508-38.507,6.009-57.821c0.807-10.371-4.956-16.292-15.644-15.825c-7.092,0.31-14.125,2.934-21.085,4.878
    		c-3.309,0.925-6.385,2.683-9.824,4.175c-2.129-3.509-2.367-6.274,1.997-7.749c12.603-4.26,25.077-9.299,38.881-5.922
    		c7.127,1.745,9.204,3.519,9.162,10.56c-0.087,14.274-0.706,28.545-0.963,42.82C536.141,428.641,536.265,437.022,536.265,445.015z"
    		/>
    	<path onClick={() => tryNextLevel()} className="clickable" fillRule="evenodd" clipRule="evenodd" d="M471.097,403.7c14.494-12.456,29.797-17.888,47.78-13.337
    		c1.17,0.296,2.741,1.94,2.845,3.074c1.206,13.312-22.55,30.419-34.218,24.115C481.478,414.296,476.766,408.605,471.097,403.7z
    		 M514.033,395.992c-0.23-0.514-0.462-1.028-0.693-1.542c-2.412,1.326-5.286,2.193-7.153,4.065
    		c-5.948,5.962-12.418,8.952-20.792,5.628c-1.474-0.584-3.107-0.77-4.667-1.14c-0.321,0.427-0.642,0.854-0.963,1.281
    		c2.206,2.085,4.073,4.913,6.685,6.105c6.247,2.851,16.038-0.232,20.466-5.723C509.265,401.756,511.66,398.883,514.033,395.992z"/>
    	<path onClick={() => tryNextLevel()} className="clickable" fillRule="evenodd" clipRule="evenodd" d="M617.67,392.419c-7.76,8.661-17.604,11.519-28.055,13.044
    		c-7.888,1.151-12.202-0.863-15.991-7.323c-4.036-6.885-1.097-14.615,6.461-16.981c12.35-3.867,22.402,2.238,32.6,7.585
    		C614.264,389.573,615.599,390.872,617.67,392.419z M582.812,387.432c-3.254,3.601-3.474,8.511-0.461,10.29
    		c4.335,2.559,18.214,0.018,22.363-4.637c-2.392,0.679-4.109,1.087-5.774,1.651c-11.183,3.789-11.178,3.799-15.755-7.006
    		C583.129,387.602,582.939,387.531,582.812,387.432z"/>
    	<path onClick={() => tryNextLevel()} className="clickable" fillRule="evenodd" clipRule="evenodd" d="M614.114,379.262c-8.458-3.05-16.77-7.978-25.398-8.617
    		c-8.692-0.644-17.7,2.974-26.802,4.771c-1.946-4.743,1.324-6.852,4.74-8.697c8.603-4.648,17.705-3.884,26.685-1.709
    		c5.801,1.404,11.438,3.566,17.035,5.686C614.627,372.306,615.033,375.817,614.114,379.262z"/>
    	<path onClick={() => tryNextLevel()} className="clickable" fillRule="evenodd" clipRule="evenodd" d="M532.072,464.83c2.245,2.029,3.951,3.569,5.636,5.093
    		c6.249-6.221,6.249-6.221,11.735,2.216c4.177-2.132,2.407-11.234,10.312-9.062c1.456,9.755-2.599,14.814-11.837,14.729
    		c-3.286-0.029-6.575-0.327-9.855-0.604c-6.344-0.539-7.856-2.496-6.838-8.754C531.399,467.375,531.718,466.324,532.072,464.83z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M341.603,944.91c3.812-3.594,6.489-7.065,10.003-9.064
    		c1.966-1.118,5.421-0.143,7.997,0.604c0.987,0.286,2.185,3.587,1.943,3.773c-1.981,1.53-4.285,2.637-6.474,3.902
    		c-0.286,0.166-0.479,0.493-1.702,1.798c4.473,0,7.98-0.052,11.485,0.016c3.251,0.062,6.625,0.431,6.905,4.592
    		c0.282,4.181-2.73,4.775-6.176,5.201c-3.955,0.491-7.82,1.705-12.299,2.737c1.404,3.595,2.767,7.444,4.407,11.169
    		c1.446,3.287,0.973,6.345-2.463,7.008c-1.849,0.357-4.92-1.592-6.22-3.385c-1.753-2.418-1.765-6.102-3.53-8.504
    		c-3.617-4.928-7.883-9.379-11.925-13.989c-0.96-1.093-2.721-1.893-3-3.087c-0.443-1.902-0.656-4.592,0.373-5.869
    		c0.806-1.001,3.866-0.957,5.518-0.319C338.739,942.376,340.653,944.248,341.603,944.91z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M455.152,937.87c6.28-0.042,10.971,4.506,12.512,11.685
    		c1.564,7.288,3.469,14.503,5.123,21.773c0.565,2.482,1.483,5.171,1.004,7.512c-0.388,1.896-2.628,3.414-4.047,5.098
    		c-1.394-1.616-3.44-3.02-4.056-4.893c-1.647-5.001-2.894-10.166-3.886-15.343c-0.777-4.056-2.824-5.135-6.804-4.962
    		c-6.84,0.294-11.346-4.533-11.207-11.263C443.903,942.151,448.917,937.912,455.152,937.87z"/>
    <Link to={"/archive/033"} className={activeIndex === 33 ? "active" : "inactive"} onClick={() => handleOnClick(33) } >
    	<path className="clickable" fillRule="evenodd" clipRule="evenodd" d="M469.064,751.635c5.288,0.627,10.674,2.558,15.085-2.19
    		c0.806-0.867,4.316-0.253,5.714,0.812c0.99,0.752,1.242,3.751,0.585,5.173c-3.365,7.259-6.832,14.514-10.972,21.34
    		c-1.006,1.659-4.692,1.691-7.144,2.472c-0.498-1.946-1.733-4.059-1.339-5.805c0.831-3.678,2.516-7.164,4.086-11.359
    		c-3.492-0.259-6.897-0.322-10.244-0.801c-6.618-0.948-9.052-5.248-5.608-10.876c2.008-3.278,5.129-6.15,8.355-8.294
    		c1.699-1.13,4.851-0.806,7.041-0.137c1.02,0.311,2.272,3.564,1.727,4.494c-1.014,1.734-3.241,2.777-5.017,4.033
    		C470.652,750.98,469.825,751.262,469.064,751.635z"/>
    	<path className="clickable" fillRule="evenodd" clipRule="evenodd" d="M452.838,777.243c-1.22,1.109-2.75,3.61-4.31,3.629
    		c-1.827,0.021-4.992-1.847-5.305-3.364c-1.845-8.923-3.247-17.957-4.23-27.014c-0.179-1.644,2.267-3.572,3.501-5.369
    		c1.466,1.55,3.792,2.88,4.254,4.688C448.965,758.469,450.677,767.256,452.838,777.243z"/>
    </Link>
    <Link to={"/archive/031"} className={activeIndex === 31 ? "active" : "inactive"} onClick={() => handleOnClick(31) } >
    	<path className="clickable" fillRule="evenodd" clipRule="evenodd" d="M674.441,775.196c-1.811,1.482-3.169,3.401-4.889,3.812
    		c-2.596,0.621-5.002,0.559-5.802-3.589c-1.579-8.169-4.146-16.146-6.265-24.213c-0.814-3.107-0.657-6.512,2.985-6.951
    		c1.814-0.219,5.134,2.056,5.785,3.903C669.27,756.707,671.585,765.502,674.441,775.196z"/>
    	<path className="clickable" fillRule="evenodd" clipRule="evenodd" d="M685.826,768.451c0,2.188,0.555,4.588-0.168,6.503
    		c-0.629,1.661-2.707,3.833-4.143,3.831c-1.383-0.001-3.504-2.285-3.977-3.943c-0.726-2.554-0.058-5.466-0.454-8.157
    		c-0.686-4.648-1.742-9.242-2.625-13.861c-0.404-2.112-1.381-4.37-0.945-6.303c0.346-1.534,2.363-3.592,3.79-3.717
    		c1.292-0.113,3.655,1.838,4.044,3.282c1.967,7.323,3.445,14.779,5.095,22.188C686.237,768.333,686.032,768.393,685.826,768.451z"/>
    </Link>
      <Link to={"/archive/024"} className={activeIndex === 24 ? "active" : "inactive"} onClick={() => handleOnClick(24) } >
    	<path className="clickable" fillRule="evenodd" clipRule="evenodd" d="M573.401,753.6c0.241-1.784-0.138-4.145,0.863-5.224
    		c1.624-1.753,4.155-2.667,6.301-3.934c1.152,1.985,3.175,3.923,3.292,5.97c0.356,6.178-1.656,11.281-7.855,12.144
    		c1.101,7.304,2.443,14.056,2.923,20.868c0.125,1.777-2.44,3.742-3.771,5.62c-1.477-1.767-4.246-3.56-4.21-5.295
    		c0.174-8.39-2.786-15.214-7.913-21.694c-2.111-2.669-3.074-6.641-3.434-10.135c-0.172-1.666,2.013-4.666,3.649-5.101
    		c1.477-0.391,3.853,1.67,5.477,3.064c1.33,1.14,2.138,2.891,3.177,4.368C572.4,754.035,572.901,753.817,573.401,753.6z"/>
    </Link>
    <Link to={"/archive/027"} className={activeIndex === 27 ? "active" : "inactive"} onClick={() => handleOnClick(27) } >
    	<path className="clickable" fillRule="evenodd" clipRule="evenodd" d="M453.08,830.697c2.717-4.694,4.35-8.234,6.8-11.068
    		c0.426-0.494,4.293,1.984,6.607,3.107c-0.156-0.789-0.979-2.409-0.609-3.679c0.564-1.932,1.955-3.623,2.995-5.415
    		c1.807,1.259,4.85,2.263,5.177,3.831c0.762,3.668,0.663,7.687-0.01,11.395c-0.265,1.461-2.842,2.785-4.625,3.625
    		c-3.958,1.863-8.081,3.383-12.615,5.239c1.907,3.756,3.328,6.462,4.664,9.21c1.465,3.01,1.544,6.304-2.006,7.206
    		c-1.758,0.447-4.971-1.367-6.235-3.1c-3.635-4.979-7.142-10.197-9.655-15.791c-1.647-3.666-1.845-8.143-1.907-12.269
    		c-0.019-1.204,2.904-3.647,4.039-3.41c1.917,0.401,3.943,2.187,5.076,3.939C451.999,825.41,452.249,827.931,453.08,830.697z"/>
    </Link>
    <Link to={"/archive/026"} className={activeIndex === 26 ? "active" : "inactive"} onClick={() => handleOnClick(26) } >
    	<path className="clickable" fillRule="evenodd" clipRule="evenodd" d="M331.978,763.951c0.844-6.068,3.133-12.538,10.031-16.425
    		c0.627-0.353,1.418-0.509,1.914-0.986c2.21-2.124,4.615-6.337,6.445-6.088c3.689,0.502,7.795,2.716,10.394,5.478
    		c5.38,5.721,9.516,12.45,10.807,20.5c1.031,6.431-2.667,11.525-8.948,11.922c-6.393,0.404-12.802,0.625-19.206,0.729
    		c-7.504,0.122-9.895-1.98-11.101-9.46C332.08,768.182,332.129,766.697,331.978,763.951z M361.488,769.343
    		c1.391-6.875-1.082-12.349-5.504-15.697c-1.926-1.457-7.265,0.463-10.442,2.065c-4.143,2.09-5.637,8.865-3.92,14.581
    		c1.63-0.252,3.713-0.005,5.021-0.896c2.515-1.712,4.518-5.614,6.869-5.724C355.865,763.562,358.382,766.973,361.488,769.343z"/>
    </Link>
    <Link to={"/archive/034"} className={activeIndex === 34 ? "active" : "inactive"} onClick={() => handleOnClick(34) } >
    	<path className="clickable" fillRule="evenodd" clipRule="evenodd" d="M577.331,964.691c2.286-5.054,4.234-9.54,6.337-13.95
    		c1.48-3.108,3.711-5.413,7.357-3.313c3.254,1.873,2.53,4.656,1.219,7.675c-2.979,6.861-5.488,13.925-8.546,20.747
    		c-0.752,1.677-2.954,3.908-4.339,3.809c-1.939-0.143-4.857-1.863-5.408-3.545c-2.86-8.74-9.522-14.136-16.204-19.505
    		c-2.553-2.05-4.67-3.99-2.734-7.239c2.103-3.527,5.398-3.148,7.999-0.836c3.79,3.367,7.192,7.184,10.629,10.929
    		C574.961,960.899,575.924,962.669,577.331,964.691z"/>
    </Link>
    <Link to={"/archive/025"} className={activeIndex === 25 ? "active" : "inactive"} onClick={() => handleOnClick(25) } >
    	<path className="clickable" fillRule="evenodd" clipRule="evenodd" d="M675.742,824.15c-2.224,6.953-3.958,13.304-6.395,19.371
    		c-0.649,1.617-3.444,2.372-5.253,3.523c-1.444-2.444-4.546-5.31-4.05-7.262c2.282-8.979,5.372-17.777,8.652-26.463
    		c0.692-1.831,3.217-3.45,5.24-4.111c1.135-0.37,3.448,1.058,4.425,2.307c6.72,8.604,13.226,17.376,19.807,26.088
    		c0.664,0.879,1.907,1.714,1.936,2.598c0.075,2.418-0.356,4.853-0.586,7.28c-2.247-0.442-5.406-0.1-6.581-1.464
    		c-5.207-6.045-9.838-12.586-14.693-18.934C677.6,826.239,676.866,825.462,675.742,824.15z"/>
    </Link>
    <Link to={"/archive/030"} className={activeIndex === 30 ? "active" : "inactive"} onClick={() => handleOnClick(30) } >
    	<path className="clickable" fillRule="evenodd" clipRule="evenodd" d="M336.032,852.176c-0.908,1.176-2.378,4.099-3.186,3.926
    		c-2.111-0.45-5.128-1.874-5.672-3.583c-3.047-9.58-5.578-19.33-8.041-29.085c-0.771-3.053-0.088-6.145,3.623-6.891
    		c4.051-0.814,5.262,1.826,6.034,5.331c1.732,7.854,3.837,15.627,5.762,23.441C335.03,847.247,335.396,849.206,336.032,852.176z"/>
    	<path className="clickable" fillRule="evenodd" clipRule="evenodd" d="M342.125,839.094c0.096-6.258,2.86-10.515,6.708-10.329
    		c7.355,0.355,12.311,5.001,12.125,11.37c-0.172,5.904-3.705,9.38-9.307,9.157C345.393,849.042,342.027,845.439,342.125,839.094z"/>
    </Link>
    <Link to={"/archive/023"} className={activeIndex === 23 ? "active" : "inactive"} onClick={() => handleOnClick(23) } >
    	<path className="clickable" fillRule="evenodd" clipRule="evenodd" d="M694.586,943.095c2.886,0.441,5.965,0.406,8.593,1.478
    		c2.004,0.82,4.671,2.969,4.774,4.662c0.102,1.711-2.321,4.755-4.11,5.167c-4.396,1.014-9.062,0.842-13.617,1.168
    		c-0.668,0.047-1.328,0.208-2.05,0.325c2.115,4.676,4.227,9.033,6.062,13.505c1.314,3.208,0.438,5.998-3.079,6.962
    		c-3.505,0.964-5.001-1.143-6.322-4.351c-3.005-7.293-6.595-14.35-10.013-21.47c-0.708-1.474-2.012-2.713-2.492-4.234
    		c-0.993-3.136-1.178-6.479,2.462-8.039c4.112-1.766,4.87,1.577,5.782,4.431c0.153,0.479,0.613,0.862,1.296,1.782
    		c2.729-10.619,11.482-11.41,19.775-12.585c1.238-0.176,3.588,1.644,4.049,3.006c1.043,3.081-0.607,5.369-3.837,6.022
    		c-2.392,0.484-4.862,0.58-7.299,0.85C694.57,942.214,694.578,942.654,694.586,943.095z"/>
    </Link>
    <Link to={"/archive/023"} className={activeIndex === 23 ? "active" : "inactive"} onClick={() => handleOnClick(23) } >
    	<path className="clickable" fillRule="evenodd" clipRule="evenodd" d="M651.879,938.116c2.004,7.803,3.786,14.987,5.736,22.125
    		c0.407,1.491,1.182,3.063,2.238,4.166c3.586,3.744,1.492,6.903-1.192,9.479c-2.063,1.979-4.868,2.225-6.006-1.364
    		c-2.913-9.192-6.033-18.342-8.365-27.684C642.838,939.02,645.182,937.425,651.879,938.116z"/>
    </Link>
    <Link to={"/archive/028"} className={activeIndex === 28 ? "active" : "inactive"} onClick={() => handleOnClick(28) } >
    	<path className="clickable" fillRule="evenodd" clipRule="evenodd" d="M681.076,913.634c-2.305-2.673-3.977-3.815-4.451-5.334
    		c-0.861-2.755-1.023-5.732-1.453-8.621c-0.959-6.459-1.675-12.966-2.947-19.363c-0.69-3.479-0.028-5.973,3.336-6.93
    		c3.707-1.056,4.896,1.879,5.314,4.649c1.469,9.719,2.853,19.459,3.853,29.234C684.885,908.813,682.9,910.579,681.076,913.634z"/>
    </Link>
    <Link to={"/archive/032"} className={activeIndex === 32 ? "active" : "inactive"} onClick={() => handleOnClick(32) } >
    	<path className="clickable" fillRule="evenodd" clipRule="evenodd" d="M455.079,886.378c4.539,0.169,9.004,3.226,13.704-0.768
    		c0.874-0.741,3.773-0.225,4.856,0.721c1.099,0.959,1.992,3.568,1.451,4.765c-3.642,8.053-7.583,15.979-11.662,23.822
    		c-1.403,2.693-3.876,4.104-6.857,2.083c-3.341-2.263-2.054-4.772-0.314-7.512c2.318-3.652,4.265-7.542,6.589-11.722
    		c-4.386-0.973-8.148-1.47-11.672-2.664c-5.822-1.975-7.466-7.526-4.727-14.689c2.306-6.03,6.754-8.75,11.752-6.486
    		c1.896,0.858,3.947,3.342,4.15,5.27c0.133,1.276-2.815,2.995-4.547,4.296c-0.816,0.612-2.017,0.712-3.043,1.043
    		C454.864,885.151,454.972,885.765,455.079,886.378z"/>
    </Link>
    	<path fillRule="evenodd" clipRule="evenodd" d="M574.084,821.704c0.233-1.394,0.049-3.233,0.856-4.366
    		c0.94-1.32,2.947-2.998,4.133-2.758c1.766,0.359,3.35,2.131,4.765,3.549c1.26,1.263,2.184,2.862,3.256,4.312
    		c0.549-0.328,1.095-0.655,1.642-0.983c-0.621-3.339-1.406-6.657-1.815-10.021c-0.366-2.998,0.377-5.522,4.098-5.867
    		c3.552-0.33,4.888,1.675,5.447,4.786c0.649,3.603,1.575,7.156,2.186,10.765c0.921,5.438-1.479,8.83-6.987,9.756
    		c-4.834,0.812-9.714,1.356-14.241,1.975c1.37,5.096,3.12,9.593,3.56,14.216c0.179,1.874-2.094,5.138-3.956,5.806
    		c-3.56,1.276-3.503-2.374-4.32-4.714c-1.855-5.312-4.088-10.498-5.854-15.839c-1.308-3.957-2.771-8.058-2.898-12.134
    		c-0.07-2.229,2.037-5.884,3.957-6.529c3.799-1.272,5.238,2.205,5.963,5.455C574.069,819.996,574.027,820.933,574.084,821.704z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M540.026,809.903c2.153,3.65,3.844,5.577,4.513,7.811
    		c3.01,10.051,5.876,20.152,8.434,30.325c0.477,1.897-0.704,4.21-1.12,6.331c-2.056-0.43-4.194-0.646-6.131-1.388
    		c-0.806-0.31-1.394-1.666-1.722-2.666c-2.848-8.664-5.731-17.321-8.328-26.063c-0.71-2.391-1.034-5.176-0.515-7.559
    		C535.57,814.796,537.535,813.237,540.026,809.903z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M583.831,886.231c1.429-5.378-1.225-10.476,4.559-11.302
    		c1.522-0.219,4.206,1.508,4.936,3.024c2.524,5.25,1.404,11.384-2.185,13.447c-2.166,1.245-4.716,1.821-7.055,2.684
    		c1.263,3.992,2.875,7.735,3.481,11.637c0.265,1.711-1.103,4.874-2.471,5.383c-2.592,0.962-5.536,0.865-6.823-3.146
    		c-2.381-7.414-5.235-14.683-8.112-21.925c-1.266-3.188-2.024-6.304,1.533-8.059c3.767-1.855,4.672,1.729,6.395,4.022
    		C579.318,883.632,581.487,884.562,583.831,886.231z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M544.916,884.456c0.323-1.286,0.328-2.952,1.125-4.044
    		c0.932-1.273,2.923-3.104,3.794-2.801c1.563,0.547,3.431,2.328,3.778,3.903c1.812,8.17,2.942,16.494,4.83,24.643
    		c0.726,3.123,1.188,6.007-2.44,6.211c-2.185,0.122-6.061-1.873-6.504-3.604C547.466,900.857,546.377,892.708,544.916,884.456z"/>
    <Link to={"/archive/029"} className={activeIndex === 29 ? "active" : "inactive"} onClick={() => handleOnClick(29) } >
    	<path className="clickable" fillRule="evenodd" clipRule="evenodd" d="M366.934,912.091c-6.054,0.251-10.735-1.432-13.236-7.188
    		c-2.519-5.797-0.484-16.057,2.757-20.206c2.461-3.149,3.655-7.278,5.51-10.922c0.678-1.332,1.462-3.359,2.507-3.576
    		c1.487-0.306,3.991,0.231,4.79,1.33c5.327,7.334,10.398,14.855,15.349,22.45c1.166,1.788,1.746,4.038,2.252,6.155
    		c1.05,4.389-0.346,7.734-4.969,8.872C376.954,910.223,371.924,911.078,366.934,912.091z M362.047,900.031
    		c1.507-2.105,2.959-6.425,5.351-7.03c6.238-1.577,4.987,4.863,6.417,8.325c3.765-2.712,0.282-10.452-6.726-16.057
    		C361.203,889.398,361.409,895.33,362.047,900.031z"/>
    </Link>
    <Link to={"/archive/029"} className={activeIndex === 29 ? "active" : "inactive"} onClick={() => handleOnClick(29) } >
    	<path className="clickable" fillRule="evenodd" clipRule="evenodd" d="M330.592,874.037c1.235,1.871,3.804,4.316,4.612,7.247
    		c2.708,9.82,4.936,19.784,6.959,29.771c0.319,1.579-0.945,4.478-2.26,5.095c-1.213,0.567-4.536-0.647-5.085-1.892
    		c-1.672-3.785-2.421-7.973-3.588-11.987c-1.983-6.818-3.856-13.675-6.073-20.417C323.75,877.568,325.145,874.143,330.592,874.037z"
    		/>
    </Link>
    </g>
    </svg>
    {renderConfirmation()}
    </div>
  )
}
