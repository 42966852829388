import archiveData from "./../data/archiveData";

const FANUM = ["۰","۱","۲","۳","۴","۵","۶","۷","۸","۹"];
const ARNUM = ["٠","١","٢","٣","٤","٥","٦","٧","٨","٩"];

const levelInfos = {
    "monster": {
        "authReq": 1,
        "code": null
    },
     "face": {
        "authReq": 2,
        "code": "811192116"
    },
     "sun": {
        "authReq": 3,
        "code": "411038668"
    },
     "deer": {
        "authReq": 4,
        "code": "111151111"
    }
};

export const Auth = {
  authLevel: sessionStorage.getItem("uaAuthLevel") || 1,

  levelNeeded: 0,

  authenticate(lvl, cb) {
    sessionStorage.setItem("uaAuthLevel", lvl);
    this.authLevel = sessionStorage.getItem("uaAuthLevel");
    cb();
  },

  getPasswordMatch(lvl, val, cb) {
    let match = false;
    for (var key in levelInfos) {
      if (levelInfos.hasOwnProperty(key)) {
          if (levelInfos[key].authReq == lvl) {
            var faCode = "", arCode = "";
            for (var i=0; i<levelInfos[key]['code'].length; i++) {
              faCode += FANUM[levelInfos[key]['code'][i]];
              arCode += ARNUM[levelInfos[key]['code'][i]];
            }

            match = (levelInfos[key]['code'] == val || faCode == val || arCode == val)
            break;
          }
      }
    }
    cb(match);
  },

  reset(){
    sessionStorage.setItem("uaAuthLevel", 0);
    this.authLevel = sessionStorage.getItem("uaAuthLevel");
  },

  getLevelNeeded(bookId){
      let index = parseInt(bookId, 10);
      let archiveItem = archiveData[index];
      this.levelNeeded = (archiveItem['book-map'].length > 0 ? levelInfos[archiveItem['book-map']]['authReq'] : 1);
      return this.levelNeeded;
  },

  getNextLevelPathFromCurrentMap(curMap) {
      let nextAuthLevel = ((levelInfos[curMap].authReq + 1) <= Object.keys(levelInfos).length) ? levelInfos[curMap].authReq + 1 : 1;
      return "/level"+nextAuthLevel;
  }

};
