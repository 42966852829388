import React, {useState} from "react";
import { Link, useRouteMatch } from "react-router-dom";
import ModalConfirm from './ModalConfirm';

export default function Deer() {
  const curPathID = parseInt(useRouteMatch().params.id, 10);
  const [activeIndex, setActiveIndex] = useState(curPathID);
  const [tOpenModalClickTimestamp, setTOpenModalClick] = useState(0);

  const handleOnClick = index => {
    setActiveIndex(index);
  };

  const tryNextLevel = () => {
     setTOpenModalClick(Date.now());
  }

  const renderConfirmation = () => {
    return <ModalConfirm tForwardOpen={tOpenModalClickTimestamp} curMap={"deer"} />
  }

  return(
    <div className="frow centered">
    <svg version="1.1" id="deer" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="1008px" height="1040px" viewBox="0 200 1008 1040" enableBackground="new 0 0 1008 1040" xmlSpace="preserve">
      <g>
      	<path className="figure-outline" fillRule="evenodd" clipRule="evenodd" d="M145.75,657.415c-0.087,0.654-0.134,1.271-0.256,1.871
      		c-0.902,4.436-3.443,9.292-2.422,13.223c2.88,11.083,6.742,22.071,15.808,30.04c4.328,3.803,7.877,3.163,10.854-1.641
      		c0.26-0.42,0.542-0.858,0.912-1.174c5.687-4.846,11.085-10.101,17.15-14.419c13.818-9.839,27.341-20.344,42.121-28.525
      		c24.08-13.328,47.884-27.499,75.241-33.933c17.138-4.031,33.795-10.165,50.979-13.93c12.778-2.8,26.029-3.636,39.121-4.736
      		c9.755-0.82,19.588-0.775,29.39-0.918c15.813-0.229,31.685-1.201,47.433-0.235c29.389,1.8,59.056-1.814,88.185,4.126
      		c10.062,2.051,19.703,6.189,29.775,8.15c10.413,2.027,21.092,3,31.702,3.62c8.36,0.489,8.921-0.747,9.54-8.847
      		c0.979-12.803-1.093-25.175-4.524-37.427c-0.531-1.896-0.609-3.949-0.686-5.938c-0.737-19.124-1.847-38.246-2.014-57.375
      		c-0.159-18.282-0.093-36.63,1.271-54.845c1.695-22.643,10.787-43.067,22.004-62.555c1.327-2.304,2.982-4.417,4.355-6.697
      		c3.394-5.64,3.171-11.11,0.027-16.995c-5.232-9.79-10.336-19.69-14.735-29.872c-5.244-12.135-12.955-22.521-21.66-32.156
      		c-3.476-3.847-2.477-7.605-2.175-11.591c0.046-0.603,1.94-1.464,3.021-1.528c12.178-0.724,24.285-0.998,35.844,4.15
      		c3.3,1.469,6.971,2.277,10.557,2.905c11.772,2.063,20.849,8.116,28.042,17.572c7.312,9.613,9.703,10.301,21.14,7.418
      		c0.826-0.208,1.929-1.793,1.867-2.665c-0.521-7.324-1.646-14.403-5.561-20.992c-3.096-5.211-5.084-11.087-7.445-16.718
      		c-0.216-0.517,0.234-1.616,0.723-2.079c5.926-5.617,7.047-13.375,9.385-20.615c2.799-8.672,5.928-17.241,8.55-25.963
      		c0.922-3.066,0.769-6.455,1.104-9.67c9.996,1.469,18.276,4.849,23.806,14.342c4.398,7.55,10.871,13.872,16.186,20.917
      		c4.495,5.959,7.046,12.397,6.531,20.338c-0.547,8.429,0.384,16.909-2.433,25.165c-1.313,3.85,0.18,7.201,3.568,9.573
      		c1.228,0.858,2.367,1.931,3.718,2.51c15.401,6.605,26.046,19.286,38.588,29.604c8.189,6.736,18.402,10.192,29.053,12.305
      		c6.905,1.37,13.588,4.156,20.151,6.84c3.963,1.621,6.53,12.053,4.97,16.111c-0.952,2.478-1.739,5.448-1.291,7.964
      		c2.654,14.896-2.233,28.124-9.412,40.464c-5.009,8.611-13.953,13.242-23.577,14.165c-12.392,1.188-24.948,0.597-37.428,0.965
      		c-1.587,0.046-3.481,0.884-4.65,1.978c-6.932,6.487-13.633,13.22-20.532,19.741c-3.604,3.407-5.662,7.331-5.379,12.363
      		c0.761,13.428,1.579,26.854,2.239,40.287c0.489,9.978,0.07,20.053,1.297,29.931c1.984,15.972,4.692,31.675,12.918,46.24
      		c5.229,9.259,7.289,20.09,5.525,31.279c-2.486,15.758-4.358,31.611-6.528,47.418c-0.89,6.486-3.653,12.138-8.332,16.678
      		c-7.434,7.212-10.255,15.677-9.635,26.127c0.777,13.117,0.226,26.312,0.244,39.473c0.001,0.667,0.107,1.372-0.066,1.994
      		c-4.937,17.636-8.433,35.947-19.789,50.949c-7.714,10.188-17.18,18.741-26.825,27.274c-7.649,6.767-16.473,10.561-24.876,15.424
      		c-6.847,3.965-14.317,6.929-21.704,9.851c-3.704,1.464-5.974,3.345-5.978,7.457c0,0.823-0.039,1.729-0.366,2.459
      		c-8.981,20.073-6.163,41.595-7.407,62.561c-0.736,12.401,0.141,24.901,0.373,37.355c0.131,7.051,0.794,14.11,0.577,21.146
      		c-0.692,22.522-0.166,44.96,3.464,67.247c2.466,15.136,10.337,27.249,21.348,37.482c12.7,11.804,21.855,25.892,27.789,42.123
      		c0.797,2.18,1.603,4.406,1.977,6.681c1.637,9.938-4.229,18.399-14.236,19.646c-5.545,0.691-11.264,0.199-16.894-0.035
      		c-13.459-0.561-26.918-1.179-40.358-2.052c-2.028-0.132-4.11-1.537-5.902-2.745c-3.155-2.127-5.983-4.754-9.191-6.79
      		c-1.936-1.229-2.334-2.223-2.276-4.646c0.14-5.878-0.229-11.86-1.331-17.62c-0.325-1.699-3.63-3.229-5.857-4.041
      		c-1.981-0.724-5.255,0.529-6.396-0.629c-3.062-3.107-6.07-6.734-7.622-10.73c-1.515-3.897-0.873-8.576-1.754-12.791
      		c-2.478-11.857-5.307-23.641-7.876-35.48c-0.806-3.708-1.092-7.527-1.862-11.245c-0.933-4.503-2.16-8.943-3.199-13.426
      		c-3.186-13.74-6.432-27.468-9.478-41.239c-1.651-7.465-3.144-14.977-4.345-22.524c-1.383-8.679-3.21-17.413-3.299-26.139
      		c-0.104-10.249-2.058-20.088-3.809-30.04c-1.507-8.562-6.93-14.5-12.84-20.274c-1.45-1.416-2.482-3.701-2.814-5.732
      		c-0.83-5.07-1.401-10.214-1.604-15.347c-0.228-5.767-0.309-11.589,0.238-17.321c0.505-5.3,0.521-5.737-4.999-4.984
      		c-9.4,1.283-18.752,2.982-28.179,3.989c-16.676,1.782-33.393,3.19-50.095,4.728c-7.789,0.717-15.583,1.909-23.379,1.944
      		c-21.112,0.098-42.247,0.185-63.333-0.681c-15.483-0.636-15.738-1.229-22.28,12.476c-7.335,15.366-17.94,28.389-29.157,40.963
      		c-7.528,8.439-15.704,16.334-22.835,25.088c-9.905,12.159-19.088,24.905-28.624,37.368c-0.877,1.146-1.886,2.519-3.135,2.997
      		c-7.215,2.765-11.337,8.574-14.787,14.923c-0.754,1.388-0.757,4.021,0.08,5.31c7.566,11.637,13.218,24.521,24.639,33.628
      		c3.674,2.93,5.078,9.488,6.158,14.672c1.116,5.352,2.807,9.077,8.655,9.865c3.536,0.478,5.866,2.455,7.404,5.82
      		c2.694,5.894,5.548,11.74,8.746,17.372c4.253,7.489,8.757,14.848,13.418,22.093c5.356,8.325,11.045,16.334,13.061,26.42
      		c1.395,6.979,4.235,13.658,5.973,20.585c1.103,4.398,2.519,9.272-1.524,13.021c-1.049,0.973-3.577,1.41-4.894,0.848
      		c-5.415-2.314-10.334-4.053-15.613,0.768c-1.536,1.401-4.974,1.087-7.507,0.948c-4.288-0.234-8.598-0.704-12.809-1.528
      		c-6.7-1.312-13.334-2.464-20.181-1.037c-0.869,0.182-2.229-0.668-2.973-1.422c-5.596-5.665-11.278-11.263-16.582-17.197
      		c-8.19-9.162-16.567-18.218-24.006-27.974c-8.344-10.943-16.129-22.368-23.369-34.072c-3.579-5.785-3.489-12.842-1.181-19.4
      		c0.934-2.652,0.187-3.932-1.929-5.285c-3.084-1.97-5.978-4.236-9.006-6.299c-5.538-3.773-9.242-8.808-11.884-15.02
      		c-1.985-4.669-5.041-8.923-7.896-13.174c-6.307-9.39-12.575-18.819-19.268-27.932c-5.488-7.472-5.021-14.909-0.735-22.346
      		c3.65-6.334,7.799-12.396,11.962-18.415c2.743-3.966,5.78-7.747,8.898-11.431c8.76-10.346,9.053-19.726,1.318-30.888
      		c-2.807-4.05-4.744-8.702-7.102-13.065c-2.285-4.229-4.581-8.451-6.911-12.656c-1.61-2.904-3.79-5.601-4.851-8.688
      		c-7.783-22.622-17.061-44.916-22.518-68.102c-5.896-25.049-5.367-50.888,0.843-76.271c2.395-9.79,2.186-9.842-6.145-15.591
      		c-0.137-0.095-0.261-0.224-0.412-0.282c-10.23-3.979-12.627-12.138-11.473-21.975c1.023-8.724,2.316-17.417,3.583-26.109
      		c0.55-3.78,1.505-7.505,1.969-11.292C131.61,665.039,139.531,657.661,145.75,657.415z M263.618,1138.654
      		c2.795,1.628,5.416,3.516,8.309,4.772c7.468,3.242,7.519,3.131,7.549,11.45c0.003,1-0.048,1.999-0.068,2.999
      		c-0.136,6.578,0.821,7.8,7.133,9.17c15.204,3.3,30.504,2.013,45.781,0.8c0.821-0.065,2.353-2.395,2.086-3.157
      		c-1.958-5.598-3.995-11.207-6.581-16.534c-2.965-6.107-6.267-12.089-9.9-17.822c-3.148-4.971-6.961-9.52-10.484-14.252
      		c-1.165-1.566-2.537-3.015-3.478-4.704c-4.751-8.529-9.377-17.128-14.093-25.678c-0.473-0.858-1.283-1.524-1.882-2.32
      		c-3.27-4.344-6.567-8.667-9.765-13.064c-1.658-2.278-2.676-5.274-4.784-6.92c-3.632-2.834-5.902-5.933-6.116-10.644
      		c-0.053-1.151-1.44-2.347-2.405-3.336c-4.363-4.479-9.207-8.563-13.09-13.419c-4.242-5.306-7.579-11.329-11.38-16.991
      		c-5.072-7.556-3.86-16.155,3.1-21.694c0.766-0.609,1.715-1.281,2.022-2.127c2.234-6.158,5.48-11.196,12.401-12.911
      		c0.819-0.203,1.236-1.848,1.933-2.753c5.822-7.561,10.034-16.463,18.858-21.539c1.428-0.821,1.778-4.099,1.96-6.296
      		c0.115-1.392-1.002-2.886-1.881-5.14c2.596,0.542,4.32,1.128,6.064,1.197c1.518,0.06,3.88,0.063,4.449-0.821
      		c2.526-3.929,5.126-7.993,6.577-12.379c1.464-4.429-3.382-5.394-5.8-7.636c-0.787-0.729-2.04-1.896-1.86-2.505
      		c0.262-0.894,1.678-2.116,2.5-2.044c2.058,0.183,4.376,0.561,6,1.701c1.978,1.39,3.148,3.882,5.032,5.464
      		c3.295,2.768,5.076,2.083,5.344-2.149c0.251-3.966-0.278-7.976-0.148-11.957c0.034-1.022,1.257-2.006,1.933-3.008
      		c0.672,0.657,1.358,1.3,2.012,1.974c1.141,1.175,2.262,2.369,2.845,2.981c2.814-3.472,6.436-6.06,7.266-9.352
      		c1.836-7.276,7.28-10.962,12.468-14.961c4.751-3.661,7.497-8.171,8.768-13.978c1.021-4.672,2.382-9.338,4.205-13.754
      		c3.704-8.979,4.248-10.093,13.694-7.108c11.475,3.624,23.593-3.001,34.512,3.407c0.233,0.137,0.651-0.068,0.983-0.069
      		c7.463-0.011,14.927-0.017,22.391-0.021c23.207-0.012,46.24-1.6,68.809-7.492c1.219-0.319,2.591-0.489,3.818-0.283
      		c12.649,2.131,24.798-2.84,37.341-2.58c4.066,0.084,8.146-1.754,12.253-1.925c3.314-0.137,4.009-1.415,3.652-4.237
      		c-0.146-1.15-0.267-2.369-0.038-3.484c0.234-1.144,0.907-2.197,1.389-3.291c0.863,0.949,2.015,1.762,2.54,2.871
      		c2.613,5.521,3.978,12.081,7.852,16.438c4.462,5.018,4.526,9.648,3.626,15.4c-0.849,5.41-1.895,11.058-1.17,16.372
      		c0.709,5.196,2.595,10.813,5.694,14.95c3.655,4.878,5.931,9.832,6.969,15.666c0.597,3.355,2.204,2.041,3.637,0.455
      		c1.322-1.465,2.636-2.939,3.953-4.409c0.546,0.249,1.092,0.497,1.638,0.746c-0.657,3.522-1.159,7.084-2.036,10.551
      		c-0.473,1.872-2.211,3.548-2.31,5.37c-0.754,13.851,3.018,26.992,6.771,40.108c0.784,2.747,1.827,5.419,3.026,8.929
      		c2.672-2.783,4.409-5.1,6.648-6.719c1.191-0.861,3.733-1.232,4.799-0.522c1.183,0.788,1.971,3.125,1.797,4.659
      		c-0.119,1.043-2.066,2.335-3.411,2.684c-2.624,0.68-5.398,0.775-8.393,1.147c2.681,3.917,5.038,7.364,8.037,11.746
      		c-3.694-0.523-5.953-1.03-8.226-1.098c-1.402-0.042-2.825,0.586-4.238,0.913c0.525,1.301,1.056,2.6,1.575,3.902
      		c1.073,2.687,3.484,5.68,2.957,8.007c-1.557,6.874,0.031,13.225,3.324,18.585c6.433,10.474,7.992,22.355,11.472,33.685
      		c0.532,1.73,0.865,3.627,0.774,5.421c-0.196,3.846,1.045,6.638,4.326,8.881c1.134,0.774,2.22,3.545,1.691,4.504
      		c-2.95,5.364-0.723,10.248,1.38,14.771c2.453,5.277,3.788,9.584-0.079,15.11c-3.435,4.907-1.532,8.06,4.276,10.195
      		c6.087,2.24,12.09,4.736,18.26,6.712c2.901,0.93,3.344,2.423,3.116,5.094c-0.468,5.474-0.166,11.047-1.021,16.446
      		c-0.929,5.854,1.665,8.645,6.716,10.205c1.27,0.392,2.48,0.98,3.758,1.338c16.568,4.643,33.55,4.659,50.537,4.237
      		c0.944-0.023,2.834-2.598,2.581-3.515c-1.493-5.404-3.04-10.886-5.445-15.922c-2.331-4.883-5.095-9.873-8.826-13.682
      		c-3.399-3.469-8.266-5.354-7.235-11.592c0.155-0.94-1.698-2.274-2.718-3.336c-5.536-5.762-12.179-10.812-16.371-17.419
      		c-4.689-7.388-7.457-16.072-10.531-24.389c-3.312-8.964-2.854-19.42-11.381-26.278c-2.146-1.727-3.2-4.564,0.856-6.733
      		c1.27-0.678,1.496-4.055,1.453-6.182c-0.029-1.456-1.35-4.126-2.094-4.126c-4.837-0.002-4.509-3.131-4.643-6.392
      		c-0.23-5.602-1.338-11.438,6.559-13.644c-1.829-1.579-2.896-2.5-3.867-3.339c1.498-1.242,3.814-2.242,3.881-3.372
      		c0.341-5.923,0.637-11.94-0.064-17.804c-0.719-6-2.611-11.858-3.812-17.012c-1.438-0.688-2.663-1.29-3.901-1.861
      		c-1.499-0.691-3.053-1.28-4.498-2.071c-0.542-0.297-0.842-1.035-1.252-1.571c0.539-0.287,1.05-0.715,1.623-0.828
      		c1.048-0.205,2.352,0.125,3.161-0.384c2.451-1.541,3.825-1.065,4.799,1.651c0.395,1.1,1.427,1.971,3.094,4.16
      		c-0.211-35.552,2.146-69.373,11.395-102.24c-3.822-0.701-7.365-0.904-10.567-2.055c-3.682-1.323-4.854-6.191-2.488-9.134
      		c0.496-0.616,2.921-0.633,2.944-0.54c1.734,6.79,5.566,7.492,11.642,5.019c6.965-2.836,15.186-3.41,21.258-7.404
      		c7.703-5.068,16.603-9.116,22.03-17.304c0.423-0.64,1.794-0.976,2.691-0.921c5.514,0.338,6.011-2.819,5.135-7.064
      		c-0.253-1.224-0.407-2.468-0.785-4.801c14.076,6.346,20.566-2.582,26.972-11.872c1.978-2.867,4.695-5.265,6.42-8.256
      		c1.735-3.007,3.632-6.469,3.687-9.76c0.066-4.127,0.374-6.999,4.81-8.383c1.409-0.44,2.918-1.893,3.552-3.265
      		c3.651-7.901,3.898-16.192,2.749-24.675c-3.316,0.644-6.129,1.189-9.028,1.752c-0.433-4.787,1.565-5.66,5.108-5.069
      		c2.41,0.401,5.607,0.965,5.176-3.101c-0.859-8.104-1.943-16.19-3.221-24.238c-0.261-1.653-1.473-3.453-2.771-4.588
      		c-3.78-3.307-3.145-6.555-0.424-9.987c3.62-4.568,7.548-8.923,10.817-13.729c2.441-3.591,4.049-7.75,6.368-12.325
      		c-3.89-0.191-6.593-0.013-9.119-0.599c-1.026-0.238-1.666-2.14-2.48-3.284c1.025-0.701,2.036-1.968,3.079-1.994
      		c2.812-0.073,5.637,0.393,8.458,0.625c3.92,0.323,6.18-1.023,5.222-6.218c-4.934,0.456-9.941,0.918-15.43,1.426
      		c-0.832-1.211-2.212-3.222-3.553-5.177c4.98-5.048,10.152-4.258,15.816-1.873c5.018,2.113,5.404,1.043,6.73-4.181
      		c3.343-13.175,1.282-26.525,2.067-39.768c0.22-3.708,0.034-7.441,0.034-11.163c-0.853-0.412-1.704-0.823-2.556-1.235
      		c-0.886,1.856-1.771,3.711-2.243,4.701c-1.863-6.095-3.893-11.75-5.298-17.554c-2.375-9.807-4.355-19.708-6.52-29.652
      		c-4.227,0.79-8.212,1.535-12.197,2.281c-0.208-0.363-0.417-0.728-0.626-1.091c1.314-1.331,2.431-3.475,3.977-3.85
      		c6.354-1.54,5.951-5.908,5.107-10.665c-0.263-1.476-0.535-2.95-0.799-4.425c-1.099-6.149-2.332-6.899-8.364-6.003
      		c-6.551,0.974-13.176,1.471-19.706,2.555c-1.734,0.287-3.134,2.214-4.875,2.935c-2.014,0.833-5.289,2.334-6.194,1.521
      		c-5.178-4.658-10.94-4.013-16.976-3.155c-4.111,0.584-8.269,1.054-12.416,1.154c-12.639,0.305-25.282,0.451-37.925,0.554
      		c-3.298,0.026-5.125,1.267-4.814,4.712c0.711,7.896,1.058,15.858,2.368,23.657c1.473,8.765,5.871,17.101,3.597,26.37
      		c-1.028,4.19-2.322,8.318-3.233,12.532c-1.787,8.266-8.091,13.25-16.74,12.66c-3.944-0.27-7.92-1.904-11.761-1.562
      		c-16.04,1.431-30.173-7.482-45.798-8.023c-0.765-0.026-1.476-0.794-2.268-0.999c-5.764-1.491-11.485-3.251-17.332-4.296
      		c-6.865-1.227-13.854-1.74-20.771-2.701c-10.248-1.424-19.551,2.12-29.648,4.575c-1.57-3.584-5.136-3.362-9.428-2.954
      		c-11.423,1.086-22.899,1.858-34.368,2.144c-12.615,0.313-25.249-0.044-37.874-0.136c-2.331-0.017-4.66-0.215-6.99-0.232
      		c-5.477-0.042-10.967-0.267-16.43,0.02c-11.46,0.601-22.897,1.859-34.361,2.124c-8.6,0.199-16.015,2.36-22.165,8.531
      		c-0.885,0.888-2.564,1.691-3.705,1.502c-9.292-1.543-17.579,2.193-26.065,4.766c-1.98,0.601-3.758,3.009-5.544,2.919
      		c-8.43-0.425-13.995,5.93-21.155,8.452c-5.565,1.96-10.631,5.356-15.894,8.161c-12.282,6.546-24.558,13.104-36.809,19.709
      		c-1.156,0.623-2.141,1.58-3.167,2.427c-10.987,9.061-23.384,16.384-32.477,27.808c-2.553,3.208-8.47,3.702-11.111,6.881
      		c-8.971,10.797-18.926,21.272-20.081,36.477c-0.095,1.247-0.722,2.509-1.316,3.652c-3.011,5.792-4.432,11.763-2.617,18.265
      		c0.654,2.342,0.981,4.776,1.514,7.454c-2.011,0-3.43,0-5.424,0c0,4.778,0.599,9.664-0.107,14.355
      		c-2.596,17.254,3.218,33.212,7.043,49.485c0.333,1.42,0.928,3.112,1.979,3.976c7.125,5.856,7.791,14.497,10.162,22.46
      		c1.036,3.479,2.802,6.74,4.249,10.096c1.249,2.895,2.908,5.679,3.707,8.692c3.379,12.75,12.3,22.285,18.971,33.112
      		c1.073,1.74,3.218,2.83,4.896,4.187c2.397,1.938,4.104,3.849,1.404,6.974c-0.716,0.829-0.598,2.564-0.521,3.863
      		c0.355,6.011,1.966,12.207,0.982,17.971c-1.311,7.682-5.887,14.875-0.953,24.174c-10.529-2.884-16.964,0.834-21.072,8.726
      		c-0.789,1.515-0.857,3.999-0.179,5.572c2.268,5.267,1.931,8.372-2.659,13.14c-0.932-3.689-1.68-7.067-2.686-10.366
      		c-0.27-0.884-1.214-1.742-2.07-2.188c-0.397-0.207-1.938,0.642-1.918,0.945c0.33,5.157-0.35,10.812,1.6,15.316
      		c2.076,4.795,6.673,8.501,10.332,12.876c1.252-1.028,2.949-2.424,4.776-3.926c3.91,5.759,12.312,9.148,7.619,18.2
      		c-0.405,0.78,0.498,2.21,0.721,3.353c2.056,10.547,8.484,17.713,17.401,23.198c10.298,6.335,15.278,14.768,10.911,27.335
      		c-1.843,5.304-1.208,11.161,3.179,15.413c7.613,7.377,15.552,14.417,23.304,21.651c0.976,0.911,2.371,2.163,2.342,3.223
      		c-0.133,4.999,3.242,7.373,7.019,11.27c-0.268-4.492-0.449-7.529-0.658-11.043c4.959,2.014,8.553,4.703,8.437,9.807
      		C269.935,1135.771,266.571,1136.852,263.618,1138.654z M656.359,327.182c-0.594,0.272-1.188,0.545-1.782,0.817
      		c-1.212-2.652-2.356-5.337-3.65-7.949c-1.899-3.835-3.687-7.754-5.926-11.388c-2.397-3.888-5.755-1.473-8.349-0.226
      		c-0.711,0.341-0.221,3.566,0.049,5.419c0.159,1.096,0.803,2.228,1.51,3.118c2.623,3.299,3.647,8.605,9.586,7.874
      		c0.125,1.229,0.787,2.776,0.281,3.631c-2.049,3.458-1.311,6.386,0.309,9.844c5.255,11.227,11.24,21.913,20.236,30.622
      		c4.061,3.929,2.912,8.65,0.041,11.607c-6.388,6.579-11.941,13.211-15.275,22.079c-4.736,12.603-10.435,24.811-11.569,38.473
      		c-0.199,2.392-0.173,4.233,2.96,4.483c1.077,0.085,2.078,1.141,3.115,1.753c-1.225,0.507-2.408,1.26-3.68,1.465
      		c-2.069,0.333-4.104-0.257-4.516,3.12c-2.375,19.438-2.316,38.861,0.163,58.25c0.676,5.28,1.214,11.136,7.783,13.222
      		c0.312,0.099,0.329,1.121,0.491,1.745c-9.545,1.341-9.546,1.341-9.136,11.388c0.014,0.333,0.067,0.668,0.046,0.998
      		c-0.308,4.811,1.717,7.083,6.746,6.734c18.58-1.287,37.184-2.279,55.742-3.8c3.904-0.32,7.63-2.533,11.509-3.622
      		c1.088-0.306,2.635-0.06,3.617,0.526c5.379,3.208,10.979,2.99,16.912,2.032c5.497-0.886,11.124-1.053,16.703-1.33
      		c3.144-0.156,4.782-3.202,3.479-6.289c-1-2.369-1.868-4.946-2.049-7.483c-0.688-9.628-0.524-19.35-1.771-28.895
      		c-0.803-6.152-3.327-12.121-5.488-18.022c-0.539-1.472-2.579-2.478-4.067-3.497c-1.439-0.987-3.058-1.714-5.622-3.116
      		c2.932-1.875,4.925-3.996,7.251-4.463c5.132-1.033,6.39-3.66,4.891-8.289c-0.201-0.624-0.312-1.324-0.264-1.974
      		c0.153-2.009,0.331-4.025,0.741-5.988c0.037-0.174,2.646-0.137,2.685,0.065c0.423,2.221,0.687,4.492,0.727,6.754
      		c0.012,0.646-1.117,1.264-1.228,1.972c-0.182,1.164-0.431,2.847,0.194,3.494c1.18,1.221,3.181,2.85,4.453,2.568
      		c5.092-1.128,6.562-3.833,5.764-9.055c-1.032-6.755,0.578-9.276,8.624-12.125c-1.188,2.557-2.234,4.28-2.784,6.148
      		c-0.492,1.675-0.516,3.509-0.532,5.275c-0.002,0.233,1.558,0.89,1.89,0.651c2.484-1.792,5.049-3.555,7.182-5.727
      		c2.268-2.309,3.864-5.282,6.163-7.552c2.631-2.596,2.518-4.03-0.646-5.912c-1.853-1.103-3.284-2.914-4.905-4.406
      		c2.389,0.291,4.783,0.531,7.158,0.906c0.617,0.098,1.239,0.53,1.735,0.953c4.562,3.889,10.031,4.259,15.566,3.703
      		c4.955-0.498,9.901-1.328,14.759-2.427c1.747-0.396,3.22-2.002,4.176-2.633c-0.71,2.985,1.694,4.056,5.59,3.681
      		c4.157-0.401,5.065-2.299,3.21-5.989c-0.276-0.55,0.592-2.335,1.249-2.542c1.039-0.329,2.618-0.168,3.46,0.463
      		c0.749,0.561,0.59,2.198,1.22,3.084c0.976,1.373,2.206,3.399,3.469,3.507c1.349,0.115,3.705-1.396,4.131-2.688
      		c1.003-3.046,1.448-6.379,1.511-9.607c0.079-4.111-1.734-6.197-6.486-5.745c-3.793,0.361-7.713-0.606-11.576-0.983
      		c0.064-0.465,0.127-0.93,0.191-1.395c4.783-0.554,9.579-1.014,14.346-1.688c5.278-0.746,10.078-5.222,11.273-10.325
      		c0.844-3.595,0.498-5.983-4.31-5.902c-6.808,0.115-13.628-0.369-20.437-0.713c-0.771-0.039-1.504-0.827-2.255-1.268
      		c0.716-0.704,1.394-1.959,2.154-2.011c2.477-0.168,5.008,0.379,7.472,0.153c6.396-0.585,12.793-1.27,19.117-2.341
      		c1.098-0.186,2.7-2.52,2.608-3.753c-0.545-7.372-6.532-11.64-10.405-17.121c-0.295-0.418-1.269-0.708-1.789-0.562
      		c-4.952,1.377-9.74,2.078-14.011-1.878c-0.473-0.438-1.661-0.001-2.434-0.24c-1.187-0.367-2.465-0.761-3.417-1.508
      		c-2.57-2.017-7.022-1.049-8.55,1.812c-0.713,1.334-1.968,2.379-2.977,3.555c-0.613-1.46-1.731-2.92-1.735-4.382
      		c-0.008-3.253-0.858-5.182-4.52-4.64c-3.033,0.449-5.021-0.65-6.183-3.614c-0.448-1.142-1.652-2.702-2.638-2.8
      		c-5.149-0.513-4.481-4.153-4.649-7.598c-0.121-2.479-0.521-4.973-1.102-7.387c-0.365-1.521-0.897-3.521-2.033-4.251
      		c-5.973-3.839-12.57-2.582-17.169,2.932c-0.744,0.893-1.302,2.48-2.146,2.632c-1.594,0.287-3.334-0.237-5.014-0.424
      		c0.396-1.08,0.473-2.691,1.24-3.153c4.571-2.753,3.212-6.353,1.704-9.999c-1.757-4.25-4.839-4.641-7.801-1.041
      		c-2.031,2.469-4.048,4.951-6.925,8.473c-0.275-4.134-0.466-6.759-0.622-9.386c-0.714-11.958-2.523-13.084-13.107-8.154
      		c-0.137,0.064-0.33,0.008-1.579,0.008c0-4.813-0.072-9.675,0.059-14.531c0.028-1.032,0.799-2.042,1.228-3.063
      		c1.056,0.633,2.702,1.027,3.072,1.939c1.989,4.909,5.685,3.41,9.27,2.887c4.026-0.587,3.605-3.694,3.895-6.447
      		c0.364-3.473,1.104-6.96,0.966-10.413c-0.101-2.511-0.607-5.782-2.243-7.314c-5.706-5.34-8.36-12.08-10.733-19.185
      		c-0.933-2.794-2.844-5.251-4.202-7.916c-2.085-4.087-3.963-8.284-6.171-12.301c-0.797-1.452-2.293-2.519-3.47-3.761
      		c-0.757,1.635-2.228,3.304-2.152,4.9c0.478,10.138-2.892,19.303-6.537,28.456c-0.977,2.452-1.985,4.992-2.323,7.577
      		c-1.056,8.091-0.573,8.417,7.371,9.32c1.312,0.149,2.926,1.655,3.463,2.929c0.275,0.654-1.333,2.423-2.419,3.263
      		c-2.534,1.964-5.269,3.672-7.33,5.08c1.733,4.123,3.262,7.243,4.353,10.509c0.967,2.898,2.459,4.381,5.695,4.511
      		c1.249,0.05,3.234,1.576,3.471,2.721c0.432,2.092,0.674,5.22-0.52,6.48c-5.447,5.749-11.126,10.844-20.379,10.093
      		c-2.664-0.216-5.964,4.45-8.419,7.347c-0.446,0.527,1.833,3.303,2.773,5.084c1.351,2.557,2.626,5.155,3.933,7.735
      		c-0.438,0.214-0.878,0.429-1.316,0.643c-6.647-4.485-11.709-10.02-12.902-18.494c-0.354-2.524-1.189-5.282-2.678-7.281
      		c-3.17-4.257-6.899-8.099-10.423-12.09c-4.287-4.856-8.672-9.115-16.185-6.803c-2.59,0.797-5.626,0.085-8.455,0.179
      		c-1.733,0.057-3.458,0.335-5.875,0.586C654.215,316.288,658.437,320.929,656.359,327.182z M153.683,711.36
      		c-5.017,0.381-8.774,2.556-10.95,7.232c-1.297,2.789-0.929,4.342,2.603,4.543C151.268,723.474,154.506,719.108,153.683,711.36z
      		 M142.887,714.447c0.032-4.921-3.28-8.748-6.43-8.212c-1.237,0.21-2.893,2.097-3.103,3.42c-0.573,3.608,2.515,6.671,6.428,6.5
      		C141.05,716.1,142.264,714.812,142.887,714.447z M132.224,698.756c0.588-0.193,1.176-0.388,1.763-0.581
      		c0-1.939,0.332-3.965-0.137-5.783c-0.241-0.937-1.91-1.504-2.934-2.237c-0.43,0.968-1.401,2.085-1.184,2.876
      		C130.275,695.017,131.357,696.857,132.224,698.756z"/>
      	<path className="figure-outline" fillRule="evenodd" clipRule="evenodd" d="M882.087,970.021c4.91-10.757,24.688-15.553,28.078-7.849
      		c-3.758,5.422-6.682,10.527-10.474,14.877c-4.499,5.159-10.561,8.243-17.508,8.364c-1.874,0.032-4.002-1.996-5.641-3.488
      		c-4.562-4.151-17.542-4.902-22.104-0.833c-1.049,0.936-1.728,2.871-1.698,4.329c0.132,6.65,0.673,13.292,0.888,19.942
      		c0.35,10.816,0.582,21.639,0.816,32.46c0.078,3.598,0.013,7.2,0.013,10.59c5.518-1.976,10.308-4.796,15.292-5.196
      		c6.358-0.511,7.909,5.456,10.808,10.828c0.498-1.291,1.476-2.604,1.419-3.871c-0.411-9.208,7.499-16.128,16.499-13.981
      		c1.04,0.248,2.216,2.372,2.271,3.67c0.063,1.517-0.741,3.3-1.69,4.58c-1.882,2.535-2.261,4.875-0.775,7.771
      		c1.471,2.864,0.808,4.655-2.96,4.659c-4.926,0.004-8.635,2.045-10.237,7.093c-0.447,1.408-1.297,2.696-2.022,4.007
      		c-5.097,9.207-14.168,9.667-20.057,1.029c-0.467-0.688-0.851-1.493-1.47-2.007c-1.647-1.369-3.402-2.609-5.114-3.9
      		c-1.064,2.524-3.065,5.058-3.042,7.571c0.156,16.621,0.786,33.237,1.116,49.858c0.024,1.228-0.795,3.249-1.714,3.597
      		c-9.034,3.411-11.397,11.697-13.833,19.477c-1.787,5.701-5.111,8.399-10.588,8.822c-5.997,0.462-11.27-1.409-14.815-6.488
      		c-0.771-1.106-0.868-3.426-0.233-4.645c2.725-5.232,5.983-10.048,12.544-11.103c2.123-0.341,4.253-0.882,6.261-1.646
      		c4.674-1.777,7.879-5.045,8.667-10.104c0.641-4.104,1.145-8.266,1.259-12.413c0.327-11.808,0.354-23.622,0.654-35.43
      		c0.096-3.776-1.06-5.196-5.137-4.978c-9.13,0.489-18.292,0.348-27.436,0.619c-3.909,0.116-5.979,1.869-5.441,6.32
      		c0.678,5.597,1.918,11.472,0.919,16.855c-2.379,12.825-5.902,25.44-9.075,38.111c-0.205,0.819-1.241,1.771-2.087,2.035
      		c-6.974,2.184-8.896,8.255-11.448,14.081c-4.652,10.622-8.375,11.571-18.005,5.038c-1.92-1.303-3.54-3.042-5.443-4.373
      		c-3.772-2.638-4.396-6.031-3.476-10.369c1.477-6.968,4.175-8.977,11.486-8.185c1.703,0.185,3.543-0.491,5.265-0.972
      		c2.667-0.744,5.23-2.029,7.936-2.43c6.326-0.934,10.126-5.51,7.967-11.614c-2.5-7.067-0.508-13.745-0.007-20.604
      		c0.508-6.951,0.916-13.911,1.298-20.871c0.174-3.16-1.656-3.923-4.491-3.711c-7.468,0.558-14.939,1.183-22.42,1.364
      		c-3.958,0.096-7.97-0.482-11.898-1.118c-3.445-0.558-5.061,0.659-5.177,4.051c-0.086,2.497-0.054,5-0.21,7.492
      		c-0.664,10.622-1.331,21.245-2.097,31.861c-0.315,4.384-2.168,7.425-6.451,9.704c-3.437,1.829-5.97,5.471-8.674,8.512
      		c-1.417,1.593-2.188,3.745-3.519,5.432c-3.384,4.294-4.894,4.12-8.609,0.336c-3.372-3.434-7.233-6.384-10.805-9.628
      		c-6.882-6.251-5.623-12.127,3.577-14.549c4.406-1.159,9.229-0.926,13.866-0.975c10.626-0.112,11.471,0.068,12.651-10.469
      		c1.118-9.981,0.264-20.189,0.071-30.295c-0.013-0.633-1.744-1.579-2.794-1.79c-5.807-1.166-11.63-2.282-17.487-3.153
      		c-4.654-0.691-9.359-1.058-14.051-1.479c-6.049-0.542-8.812-4.572-9.479-9.859c-0.646-5.141-3.558-8.033-7.3-11.088
      		c-3.377-2.756-6.04-6.503-8.595-10.111c-1.763-2.489-1.267-4.976,1.815-6.545c2.07-1.054,3.91-2.588,6.023-3.523
      		c8.136-3.604,9.979-4.289,14.495,5.51c2.222,4.821,2.316,10.633,3.295,16.01c0.236,1.301,0.114,2.662,0.227,3.989
      		c0.767,9.032,2.246,9.944,11.353,9.581c4.177-0.167,8.402,1.32,12.628,1.882c10.602,1.41,10.571,1.371,10.457-9.12
      		c-0.173-15.993-0.292-31.987-0.435-47.98c-0.009-1-0.003-2-0.034-2.999c-0.313-9.943-0.405-10.102-10.158-11.478
      		c-6.096-0.86-12.21-1.603-18.326-2.302c-6.803-0.777-10.274-3.877-12.587-10.343c-0.794-2.222-2.8-4.15-4.615-5.819
      		c-1.432-1.315-3.483-1.929-5.148-3.022c-7.017-4.61-7.618-11.119-1.999-17.23c2.383-2.593,3.842-6.05,6.284-8.569
      		c1.03-1.062,4.406-1.615,5.18-0.851c3.105,3.078,6.642,6.381,8.095,10.312c2.22,6.002,3.261,12.545,4.021,18.95
      		c0.654,5.514,3.229,8.592,8.527,9.237c5.44,0.661,10.929,1.216,16.393,1.187c1.393-0.008,3.974-2.399,3.978-3.703
      		c0.061-18.804-0.213-37.608-0.441-56.412c-0.047-3.835-1.855-5.325-5.909-5.414c-5.795-0.128-11.565-1.19-17.354-1.78
      		c-4.057-0.413-6.299-2.654-8.197-6.249c-1.59-3.013-4.153-5.866-6.963-7.816c-9.885-6.859-12.666-13.856-8.986-25.232
      		c0.436-1.348,2.098-2.965,3.437-3.234c5.363-1.077,11.101-4.142,15.973,0.914c1.596,1.658,2.89,4.096,3.264,6.352
      		c1.062,6.396,1.739,12.87,2.226,19.339c0.197,2.633,0.762,3.902,3.671,3.851c1.186-0.021,2.892,0.867,3.508,1.867
      		c3.565,5.794,5.959,6.811,12.891,5.393c0-11.297-0.127-22.431,0.118-33.558c0.044-1.979,1.195-4.349,2.6-5.797
      		c3.475-3.583,4.221-7.253,0.444-10.828c-4.278-4.048-3.049-8.852-2.938-13.497c0.029-1.266,1.616-3.053,2.919-3.636
      		c7.884-3.529,16.341,1.917,18.175,10.319c1.382,6.33-0.27,11.205-4.263,15.463c-7.077,7.548-9.368,16.119-7.21,26.183
      		c0.617,2.877,0.158,5.979,0.283,8.972c0.16,3.85,1.635,6.241,6.085,5.929c11.459-0.804,22.938-1.322,34.385-2.274
      		c1.486-0.124,3.679-1.895,4.059-3.305c1.248-4.617,2.603-9.441,2.475-14.148c-0.276-10.126-1.455-20.229-2.253-30.342
      		c-0.17-2.154-0.294-4.313-0.438-6.471c-0.208-3.118,1.022-4.864,4.271-5.641c1.855-0.444,3.425-2.093,5.123-3.199
      		c-1.345-1.725-2.344-4.336-4.094-5.021c-7.447-2.908-7.336-9.163-7.283-15.303c0.041-4.783,2.497-6.895,7.149-5.688
      		c3.018,0.782,6.059,2.7,8.317,4.902c3.068,2.99,5.484,6.676,8.023,10.178c2.54,3.504,1.67,11.566-2.788,14.409
      		c-7.268,4.632-7.242,11.067-6.898,18.227c0.488,10.146,0.493,20.314,0.94,30.462c0.3,6.822,0.634,7.135,7.229,7.165
      		c9.16,0.042,18.321-0.213,27.482-0.317c4.139-0.047,5.626-2.479,5.367-6.185c-0.924-13.238-1.867-26.477-2.977-39.702
      		c-0.415-4.95-1.367-9.615,5.609-11.021c2.794-0.563,2.275-2.653,0.455-4.908c-3.275-4.063-6.582-8.316-8.726-13.017
      		c-1.959-4.295,0.269-6.648,5.035-6.335c4.346,0.286,8.165,1.521,9.554,6.459c0.853,3.034,1.994,5.994,3.144,8.934
      		c1.655,4.232,1.178,8.254-1.36,11.883c-3.895,5.571-4.251,11.534-3.409,18.094c1.226,9.553,1.815,19.195,2.421,28.816
      		c0.182,2.893,1.27,3.772,3.978,3.551c6.138-0.504,12.281-1.051,18.433-1.236c3.293-0.099,6.768-0.014,9.875,0.928
      		c5.28,1.6,8.919-0.662,11.506-4.507c1.635-2.433,2.781-2.732,5.054-1.416c1.868,1.082,4.025,1.73,5.724,3.01
      		c0.878,0.661,1.785,2.76,1.387,3.383c-1.815,2.841-0.944,4.947,0.99,7.151c0.454,0.518,0.749,1.527,0.585,2.177
      		c-0.901,3.567-8.139,8.677-11.929,9.098c-9.631,1.069-17.628-1.926-24.508-8.681c-1.104-1.085-3.525-1.437-5.208-1.201
      		c-3.768,0.526-7.421,2.118-11.183,2.378c-4.176,0.289-5.15,1.722-4.602,5.798c0.752,5.584,0.742,11.278,0.93,16.93
      		c0.369,11.134,0.672,22.271,0.952,33.407c0.119,4.709,1.83,7.312,7.153,7.234c5.304-0.076,10.617,0.934,15.935,1.05
      		c3.317,0.072,4.517,1.352,4.048,4.477c-0.667,4.457,1.462,5.578,5.436,4.67c2.268-0.519,4.999-0.291,6.734-1.516
      		c1.812-1.279,3.835-3.983,3.694-5.914c-0.229-3.141-2.632-4.595-6.146-2.798C886.932,969.543,884.845,969.499,882.087,970.021z
      		 M776.013,1057.006c3.656,0,7.013-0.267,10.306,0.068c4,0.407,5.197-1.081,4.979-4.979c-0.199-3.59-1.024-8.751,0.903-10.521
      		c4.128-3.79,3.465-7.069,2.06-11.304c-0.551-1.66-0.215-3.629-0.214-5.458c0.008-12.448-0.042-24.899,0.102-37.346
      		c0.042-3.673-1.376-5.135-5.021-4.894c-11.95,0.792-23.918,1.355-35.86,2.247c-8.496,0.634-9.249,2.219-8.994,10.659
      		c0.473,15.604,0.362,31.224,0.582,46.837c0.052,3.632,0.265,7.283,0.755,10.878c0.429,3.137,2.261,5.12,5.758,4.955
      		C759.683,1057.756,767.998,1057.377,776.013,1057.006z M742.857,940.152c-0.121,0.011-0.242,0.021-0.363,0.032
      		c0.528,10.313,1.215,20.623,1.5,30.943c0.098,3.518,1.616,4.937,4.739,4.878c12.936-0.247,25.867-0.609,38.8-0.923
      		c4.302-0.104,6.046-1.947,5.705-6.552c-1.288-17.421-2.384-34.861-3.229-52.311c-0.229-4.727-2.08-6.512-6.623-6.29
      		c-11.975,0.585-23.956,0.985-35.929,1.602c-5.165,0.267-6.834,2.409-6.405,7.756C741.608,926.246,742.251,933.198,742.857,940.152z
      		 M834.777,1056.812c8.92,0.7,9.066,0.704,9.242-6.633c0.207-8.639,0.146-17.284,0.165-25.927
      		c0.023-11.426,0.132-22.855-0.03-34.279c-0.102-7.167-1.683-11.002-9.825-9.685c-8.187,1.324-16.498,1.884-24.759,2.744
      		c-6.192,0.644-8.062,2.482-5.694,8.113c1.854,4.409,0.823,8.29,0.747,12.457c-0.136,7.476,1.061,15.175-0.354,22.376
      		c-1.795,9.139-0.519,17.838,0.582,26.697c0.131,1.057,1.169,2.775,1.934,2.86c7.522,0.831,15.068,1.494,22.624,1.942
      		C831.668,1057.612,833.975,1056.922,834.777,1056.812z M824.365,906.976c0,0.069,0,0.14,0,0.209
      		c-3.653,0.209-7.355,0.111-10.951,0.681c-17.07,2.702-15.241,0.791-13.884,17.099c1.224,14.693,3.528,29.349,3.779,44.048
      		c0.103,5.999,2.999,5.258,6.21,5.799c1.61,0.271,3.333,0.138,4.973-0.069c6.923-0.875,13.842-1.789,20.746-2.801
      		c6.003-0.88,8.529-5.208,7.095-11.237c-0.757-3.183-1.176-6.513-1.223-9.783c-0.164-11.606-0.08-23.216-0.157-34.824
      		c-0.01-1.475-0.444-2.943-0.645-4.421c-0.475-3.5-2.054-5.265-5.977-4.782C831.057,907.297,827.691,906.976,824.365,906.976z
      		 M703.753,881.392c-1.947-4.271-3.414-7.486-4.88-10.701c-2.797,1.581-6.277,2.453-5.445,7.055
      		c0.664,3.669-0.209,8.401,5.497,8.075C700.486,885.731,701.908,883.165,703.753,881.392z M873.769,1065.589
      		c-0.041,0.374,0.315-0.607,0.117-1.46c-0.85-3.641-1.811-7.259-2.868-10.844c-0.168-0.569-1.118-1.381-1.578-1.305
      		c-1.641,0.272-4.393,0.592-4.595,1.416c-0.454,1.847-0.328,4.647,0.823,5.906C868.016,1061.867,871.197,1063.67,873.769,1065.589z
      		 M768.744,1144.146c2.543-1.726,5.756-3.015,5.672-4.031c-0.182-2.192,0.498-5.772-4.224-5.714
      		c-4.353,0.055-5.042,2.355-4.722,5.486C765.599,1141.146,767.223,1142.251,768.744,1144.146z M818.013,1141.047
      		c2.979,1.818,5.313,3.571,7.931,4.595c0.611,0.238,2.655-1.813,3.2-3.152c0.61-1.497,0.265-3.382,0.335-5.098
      		c-1.635,0.263-3.325,0.349-4.89,0.836C822.782,1138.79,821.085,1139.705,818.013,1141.047z M692.376,1034.793
      		c0.398-0.604,0.796-1.21,1.194-1.815c-1.502-3.003-3.004-6.008-4.507-9.013c-0.664-0.007-1.329-0.014-1.994-0.021
      		c-0.448,2.543-1.909,5.533-1.042,7.493C686.786,1033.156,690.159,1033.72,692.376,1034.793z M708.903,1116.02
      		c0.061,0.457,0.12,0.915,0.181,1.372c2.198,1.018,4.328,2.263,6.624,2.955c1.115,0.336,2.543-0.364,3.829-0.595
      		c-0.707-1.607-1.289-4.503-2.145-4.586C714.618,1114.896,711.741,1115.656,708.903,1116.02z M693.271,958.708
      		c1.594-4.846,0.688-7.435-2.721-8.481c-0.753-0.231-2.083,1.415-3.147,2.196C689.109,954.25,690.816,956.078,693.271,958.708z"/>
      <Link to={"/archive/038"} className={activeIndex === 38 ? "active" : "inactive"} onClick={() => handleOnClick(38) } >
      	<path className="clickable" fillRule="evenodd" clipRule="evenodd" fill="#000" d="M263.618,1138.654c2.953-1.803,6.317-2.883,6.343-4.04
      		c0.117-5.104-3.477-7.793-8.437-9.807c0.209,3.514,0.391,6.551,0.658,11.043c-3.777-3.896-7.152-6.271-7.019-11.27
      		c0.028-1.06-1.366-2.312-2.342-3.223c-7.752-7.234-15.691-14.274-23.304-21.651c-4.386-4.252-5.022-10.109-3.179-15.413
      		c4.368-12.567-0.613-21-10.911-27.335c-8.917-5.485-15.345-12.651-17.401-23.198c-0.223-1.143-1.126-2.572-0.721-3.353
      		c4.694-9.052-3.708-12.441-7.619-18.2c-1.827,1.502-3.524,2.897-4.776,3.926c-3.659-4.375-8.256-8.081-10.332-12.876
      		c-1.949-4.504-1.27-10.159-1.6-15.316c-0.02-0.304,1.521-1.152,1.918-0.945c0.855,0.446,1.8,1.305,2.07,2.188
      		c1.006,3.299,1.754,6.677,2.686,10.366c4.589-4.768,4.927-7.873,2.659-13.14c-0.678-1.573-0.609-4.058,0.179-5.572
      		c4.108-7.892,10.542-11.609,21.072-8.726c-4.934-9.299-0.357-16.492,0.953-24.174c0.983-5.764-0.627-11.96-0.982-17.971
      		c-0.077-1.299-0.195-3.034,0.521-3.863c2.701-3.125,0.993-5.035-1.404-6.974c-1.678-1.356-3.823-2.446-4.896-4.187
      		c-6.671-10.827-15.592-20.362-18.971-33.112c-0.799-3.014-2.458-5.798-3.707-8.692c-1.447-3.355-3.213-6.617-4.249-10.096
      		c-2.371-7.963-3.037-16.604-10.162-22.46c-1.051-0.863-1.645-2.556-1.979-3.976c-3.826-16.273-9.639-32.231-7.043-49.485
      		c0.706-4.691,0.107-9.577,0.107-14.355c1.994,0,3.413,0,5.424,0c-0.533-2.678-0.86-5.112-1.514-7.454
      		c-1.814-6.502-0.394-12.473,2.617-18.265c0.594-1.144,1.221-2.405,1.316-3.652c1.154-15.204,11.11-25.679,20.081-36.477
      		c2.641-3.179,8.559-3.673,11.111-6.881c9.093-11.424,21.49-18.748,32.477-27.808c1.026-0.848,2.011-1.804,3.167-2.427
      		c12.251-6.605,24.526-13.164,36.809-19.709c5.263-2.805,10.329-6.201,15.894-8.161c7.16-2.522,12.725-8.877,21.155-8.452
      		c1.787,0.09,3.564-2.319,5.544-2.919c8.487-2.573,16.773-6.309,26.065-4.766c1.14,0.189,2.82-0.614,3.705-1.502
      		c6.15-6.171,13.565-8.332,22.165-8.531c11.463-0.265,22.9-1.523,34.361-2.124c5.462-0.286,10.953-0.062,16.43-0.02
      		c2.331,0.018,4.66,0.216,6.99,0.232c12.625,0.092,25.259,0.449,37.874,0.136c11.469-0.286,22.945-1.058,34.368-2.144
      		c4.292-0.408,7.858-0.631,9.428,2.954c10.097-2.455,19.4-5.999,29.648-4.575c6.916,0.961,13.905,1.474,20.771,2.701
      		c5.847,1.045,11.568,2.806,17.332,4.296c0.792,0.205,1.503,0.973,2.268,0.999c15.625,0.541,29.758,9.454,45.798,8.023
      		c3.841-0.342,7.816,1.293,11.761,1.562c8.649,0.59,14.953-4.394,16.74-12.66c0.911-4.213,2.205-8.342,3.233-12.532
      		c2.274-9.269-2.124-17.605-3.597-26.37c-1.311-7.799-1.657-15.762-2.368-23.657c-0.311-3.446,1.517-4.686,4.814-4.712
      		c12.643-0.103,25.286-0.249,37.925-0.554c4.147-0.1,8.305-0.569,12.416-1.154c6.035-0.858,11.798-1.503,16.976,3.155
      		c0.905,0.814,4.181-0.688,6.194-1.521c1.741-0.721,3.141-2.648,4.875-2.935c6.53-1.084,13.155-1.581,19.706-2.555
      		c6.032-0.896,7.266-0.146,8.364,6.003c0.264,1.476,0.536,2.95,0.799,4.425c0.844,4.756,1.247,9.125-5.107,10.665
      		c-1.546,0.375-2.662,2.519-3.977,3.85c0.209,0.363,0.418,0.728,0.626,1.091c3.985-0.746,7.971-1.491,12.197-2.281
      		c2.164,9.943,4.145,19.845,6.52,29.652c1.405,5.805,3.435,11.459,5.298,17.554c0.473-0.99,1.357-2.845,2.243-4.701
      		c0.852,0.412,1.703,0.823,2.556,1.235c0,3.721,0.186,7.454-0.034,11.163c-0.785,13.243,1.275,26.593-2.067,39.768
      		c-1.326,5.224-1.713,6.294-6.73,4.181c-5.664-2.385-10.836-3.175-15.816,1.873c1.341,1.955,2.721,3.966,3.553,5.177
      		c5.488-0.508,10.496-0.971,15.43-1.426c0.958,5.194-1.302,6.541-5.222,6.218c-2.821-0.232-5.646-0.698-8.458-0.625
      		c-1.043,0.026-2.054,1.293-3.079,1.994c0.814,1.144,1.454,3.045,2.48,3.284c2.526,0.586,5.229,0.407,9.119,0.599
      		c-2.319,4.575-3.927,8.734-6.368,12.325c-3.27,4.806-7.197,9.161-10.817,13.729c-2.721,3.432-3.356,6.68,0.424,9.987
      		c1.298,1.136,2.51,2.936,2.771,4.588c1.277,8.048,2.361,16.135,3.221,24.238c0.432,4.065-2.766,3.502-5.176,3.101
      		c-3.543-0.591-5.541,0.282-5.108,5.069c2.899-0.562,5.712-1.108,9.028-1.752c1.149,8.482,0.902,16.773-2.749,24.675
      		c-0.634,1.372-2.143,2.824-3.552,3.265c-4.436,1.384-4.743,4.256-4.81,8.383c-0.055,3.291-1.951,6.753-3.687,9.76
      		c-1.725,2.991-4.442,5.389-6.42,8.256c-6.405,9.29-12.896,18.218-26.972,11.872c0.378,2.333,0.532,3.577,0.785,4.801
      		c0.876,4.245,0.379,7.402-5.135,7.064c-0.897-0.055-2.269,0.281-2.691,0.921c-5.428,8.188-14.327,12.235-22.03,17.304
      		c-6.072,3.994-14.293,4.568-21.258,7.404c-6.075,2.474-9.907,1.771-11.642-5.019c-0.023-0.093-2.448-0.076-2.944,0.54
      		c-2.365,2.942-1.193,7.811,2.488,9.134c3.202,1.15,6.745,1.354,10.567,2.055c-9.249,32.867-11.605,66.688-11.395,102.24
      		c-1.667-2.189-2.699-3.061-3.094-4.16c-0.974-2.717-2.348-3.192-4.799-1.651c-0.81,0.509-2.113,0.179-3.161,0.384
      		c-0.573,0.113-1.084,0.541-1.623,0.828c0.41,0.536,0.71,1.274,1.252,1.571c1.445,0.791,2.999,1.38,4.498,2.071
      		c1.238,0.571,2.463,1.174,3.901,1.861c1.2,5.153,3.093,11.012,3.812,17.012c0.701,5.863,0.405,11.881,0.064,17.804
      		c-0.066,1.13-2.383,2.13-3.881,3.372c0.972,0.839,2.038,1.76,3.867,3.339c-7.896,2.205-6.789,8.042-6.559,13.644
      		c0.134,3.261-0.194,6.39,4.643,6.392c0.744,0,2.064,2.67,2.094,4.126c0.043,2.127-0.184,5.504-1.453,6.182
      		c-4.057,2.169-3.003,5.007-0.856,6.733c8.527,6.858,8.068,17.314,11.381,26.278c3.074,8.316,5.842,17.001,10.531,24.389
      		c4.192,6.606,10.835,11.657,16.371,17.419c1.02,1.062,2.873,2.396,2.718,3.336c-1.03,6.238,3.836,8.123,7.235,11.592
      		c3.731,3.809,6.495,8.799,8.826,13.682c2.405,5.036,3.952,10.518,5.445,15.922c0.253,0.917-1.637,3.491-2.581,3.515
      		c-16.987,0.422-33.969,0.405-50.537-4.237c-1.277-0.357-2.488-0.946-3.758-1.338c-5.051-1.561-7.645-4.351-6.716-10.205
      		c0.855-5.399,0.554-10.973,1.021-16.446c0.228-2.671-0.215-4.164-3.116-5.094c-6.17-1.976-12.173-4.472-18.26-6.712
      		c-5.809-2.136-7.711-5.288-4.276-10.195c3.867-5.526,2.532-9.833,0.079-15.11c-2.103-4.523-4.33-9.407-1.38-14.771
      		c0.528-0.959-0.558-3.729-1.691-4.504c-3.281-2.243-4.522-5.035-4.326-8.881c0.091-1.794-0.242-3.69-0.774-5.421
      		c-3.479-11.329-5.039-23.211-11.472-33.685c-3.293-5.36-4.881-11.711-3.324-18.585c0.527-2.327-1.884-5.32-2.957-8.007
      		c-0.52-1.303-1.05-2.602-1.575-3.902c1.413-0.327,2.836-0.955,4.238-0.913c2.272,0.067,4.531,0.574,8.226,1.098
      		c-2.999-4.382-5.356-7.829-8.037-11.746c2.994-0.372,5.769-0.468,8.393-1.147c1.345-0.349,3.292-1.641,3.411-2.684
      		c0.174-1.534-0.614-3.871-1.797-4.659c-1.065-0.71-3.607-0.339-4.799,0.522c-2.239,1.619-3.977,3.936-6.648,6.719
      		c-1.199-3.51-2.242-6.182-3.026-8.929c-3.753-13.116-7.524-26.258-6.771-40.108c0.099-1.822,1.837-3.498,2.31-5.37
      		c0.877-3.467,1.379-7.028,2.036-10.551c-0.546-0.249-1.092-0.497-1.638-0.746c-1.317,1.47-2.631,2.944-3.953,4.409
      		c-1.433,1.586-3.04,2.9-3.637-0.455c-1.038-5.834-3.313-10.788-6.969-15.666c-3.1-4.137-4.985-9.754-5.694-14.95
      		c-0.725-5.314,0.321-10.962,1.17-16.372c0.9-5.752,0.836-10.383-3.626-15.4c-3.874-4.356-5.238-10.917-7.852-16.438
      		c-0.525-1.109-1.677-1.922-2.54-2.871c-0.481,1.094-1.154,2.147-1.389,3.291c-0.229,1.115-0.107,2.334,0.038,3.484
      		c0.356,2.822-0.338,4.101-3.652,4.237c-4.106,0.171-8.187,2.009-12.253,1.925c-12.543-0.26-24.692,4.711-37.341,2.58
      		c-1.227-0.206-2.599-0.036-3.818,0.283c-22.569,5.893-45.603,7.48-68.809,7.492c-7.464,0.004-14.928,0.01-22.391,0.021
      		c-0.332,0.001-0.75,0.206-0.983,0.069c-10.919-6.408-23.037,0.217-34.512-3.407c-9.447-2.984-9.991-1.871-13.694,7.108
      		c-1.822,4.416-3.183,9.082-4.205,13.754c-1.271,5.807-4.016,10.316-8.768,13.978c-5.188,3.999-10.632,7.685-12.468,14.961
      		c-0.831,3.292-4.452,5.88-7.266,9.352c-0.583-0.612-1.704-1.807-2.845-2.981c-0.654-0.674-1.34-1.316-2.012-1.974
      		c-0.676,1.002-1.899,1.985-1.933,3.008c-0.13,3.981,0.399,7.991,0.148,11.957c-0.268,4.232-2.049,4.917-5.344,2.149
      		c-1.884-1.582-3.054-4.074-5.032-5.464c-1.624-1.141-3.942-1.519-6-1.701c-0.822-0.072-2.238,1.15-2.5,2.044
      		c-0.179,0.608,1.074,1.775,1.86,2.505c2.417,2.242,7.264,3.207,5.8,7.636c-1.451,4.386-4.051,8.45-6.577,12.379
      		c-0.569,0.885-2.931,0.881-4.449,0.821c-1.744-0.069-3.468-0.655-6.064-1.197c0.879,2.254,1.996,3.748,1.881,5.14
      		c-0.183,2.197-0.532,5.475-1.96,6.296c-8.824,5.076-13.036,13.979-18.858,21.539c-0.697,0.905-1.114,2.55-1.933,2.753
      		c-6.921,1.715-10.167,6.753-12.401,12.911c-0.307,0.846-1.256,1.518-2.022,2.127c-6.959,5.539-8.172,14.139-3.1,21.694
      		c3.801,5.662,7.138,11.686,11.38,16.991c3.883,4.855,8.727,8.939,13.09,13.419c0.964,0.989,2.352,2.185,2.405,3.336
      		c0.214,4.711,2.484,7.81,6.116,10.644c2.108,1.646,3.126,4.642,4.784,6.92c3.198,4.397,6.495,8.721,9.765,13.064
      		c0.6,0.796,1.409,1.462,1.882,2.32c4.715,8.55,9.341,17.148,14.093,25.678c0.941,1.689,2.313,3.138,3.478,4.704
      		c3.523,4.732,7.336,9.281,10.484,14.252c3.633,5.733,6.936,11.715,9.9,17.822c2.586,5.327,4.623,10.937,6.581,16.534
      		c0.267,0.763-1.266,3.092-2.086,3.157c-15.277,1.213-30.578,2.5-45.781-0.8c-6.312-1.37-7.269-2.592-7.133-9.17
      		c0.021-1,0.072-1.999,0.068-2.999c-0.03-8.319-0.081-8.208-7.549-11.45C269.034,1142.17,266.413,1140.282,263.618,1138.654z
      		 M230.191,815.077c3.472-5.574,10.147-9.405,9.645-17.406c-11.801,6.395-12.962,8.578-9.516,17.521
      		c-3.879,0.621-7.871,1.028-10.084,5.099C224.86,822.752,226.708,821.739,230.191,815.077z M299.867,668.11
      		c-0.039-1.158,0.274-2.484-0.184-3.442c-1.364-2.852-3.467-4.933-6.982-3.893c-0.96,0.283-1.674,1.401-2.501,2.136
      		c2.03,1.34,3.997,2.792,6.114,3.976c1.092,0.611,2.45,0.751,3.688,1.104c0.86,3.042,1.668,5.702-1.348,8.55
      		c-2.615,2.468-4.078,6.138-6.152,9.208c-0.811,1.199-1.903,2.208-2.867,3.304c-0.85-1.205-2.269-2.326-2.459-3.627
      		c-1.23-8.385-2.001-16.841-3.362-25.201c-0.389-2.392-1.781-5.062-3.569-6.619c-1.077-0.937-3.896-0.257-5.744,0.311
      		c-0.567,0.174-0.951,2.49-0.665,3.637c1.449,5.801,3.324,11.499,4.693,17.315c0.86,3.651,1.092,7.451,1.605,11.183
      		c-0.697,0.218-1.394,0.436-2.09,0.653c-1.881-3.369-3.58-6.858-5.711-10.06c-1.311-1.968-3.087-4.649-5.004-4.978
      		c-3.998-0.687-11.147,7.58-10.926,12.092c2.012-0.933,3.987-1.707,5.824-2.731c3.229-1.8,5.712-1.448,7.081,2.24
      		c1.604,4.327,2.912,8.772,4.707,13.014c0.603,1.425,2.303,2.383,3.502,3.555c0.711-1.108,1.635-2.135,2.093-3.339
      		c1.301-3.417,3.502-4.158,6.075-1.664c2.424,2.35,4.913,3.776,8.485,2.86c1.229-0.314,2.721,0.389,4.403,0.682
      		c0-4.471,0.26-8.784-0.081-13.049c-0.281-3.519,1.199-4.03,4.056-3.372c2.676,0.616,5.364,1.18,8.598,1.888
      		c-1.338-3.922-2.431-7.125-3.757-11.01c3.412,0,6.065-0.203,8.674,0.053c3.108,0.306,3.976-0.796,3.892-3.883
      		c-0.098-3.588-2.014-2.96-4.287-2.667C310.41,667.014,305.133,667.532,299.867,668.11z M633.678,637.594
      		c-8.27,5.478-16.276,9.443-22.581,15.255c-10.843,9.995-20.68,21.086-30.826,31.824c-5.237,5.542-11.104,10.756-15.161,17.095
      		c-9.673,15.106-17.031,31.3-17.354,49.759c-0.151,8.645,0.282,17.304-0.039,25.938c-0.261,7.019,1.423,13.042,6.296,18.212
      		c2.813,2.983,4.748,7.163,8.059,9.241c9.286,5.828,19.396,10.019,30.119,12.707c13.555,3.396,26.965,7.709,41.215,6.104
      		c6.914-0.778,13.855-3.187,20.646-2.743c10.563,0.691,17.824-5.979,26.062-10.049c5.403-2.672,9.67-8.113,13.718-12.928
      		c3.998-4.754,7.75-9.964,10.375-15.555c2.576-5.488,3.973-11.631,5.191-17.628c1.142-5.619,2.301-11.505,1.801-17.127
      		c-0.933-10.483-3.871-20.509-7.295-30.638c-4.715-13.953-11.539-25.596-22.938-34.694c-0.648-0.517-1.167-1.197-1.814-1.715
      		c-9.822-7.854-19.216-16.352-29.639-23.307C642.331,652.554,634.336,649.338,633.678,637.594z M646.573,614.646
      		c-3.894,3.898-2.348,7.364-0.462,10.108c0.954,1.389,3.766,1.881,5.798,2.052c2.446,0.205,4.955-0.508,7.442-0.564
      		c6.13-0.141,12.262-0.163,18.394-0.178c10.259-0.024,20.519-0.007,30.997-0.007c-0.662-4.566-1.596-9.305-1.98-14.088
      		c-0.503-6.266-0.296-12.594-0.911-18.845c-0.549-5.571-2.11-11.043-2.658-16.615c-0.63-6.403-0.31-6.579,5.692-4.551
      		c1.448,0.489,2.893,2.121,3.549,3.583c1.191,2.652,1.789,5.572,2.811,8.966c0.632-2.547,1.503-4.408,1.431-6.231
      		c-0.07-1.771-1.354-3.467-1.627-5.269c-0.172-1.14,0.151-2.819,0.922-3.545c2.201-2.077,10.192,0.668,10.825,3.665
      		c2.507,11.864,5.117,23.711,7.278,35.64c1.514,8.355,2.339,9.38,10.778,9.221c1.832-0.034,3.667-0.019,5.495-0.128
      		c8.394-0.503,10.457-3.359,5.488-10.025c-4.689-6.294-4.769-12.739-4.597-19.707c0.082-3.328-0.245-6.668-0.106-9.99
      		c0.193-4.637-2.152-5.129-5.741-4.17c0.195,3.254,0.885,6.309,0.459,9.199c-1.388,9.406,0.351,17.831,6.448,25.287
      		c1.981,2.421,0.924,3.969-1.928,4.492c-7.275,1.337-10.852-0.933-12.091-8.189c-2.871-16.803-5.503-33.649-8.393-50.45
      		c-0.216-1.255-1.682-3.217-2.618-3.248c-2.319-0.074-3.781,1.1-3.521,4.126c0.564,6.566-3.646,10.705-11.269,11.676
      		c-7.664,0.976-10.709-1.078-12.28-8.308c-0.388-1.788-0.896-3.572-1.053-5.383c-0.382-4.421-2.62-3.589-5.408-2.075
      		c0.594,1.839,1.2,3.536,1.691,5.265c2.525,8.889,1.5,10.287-7.526,10.353c-1.5,0.011-2.999,0.017-4.498,0.032
      		c-12.321,0.122-12.32,0.122-14.855-12.208c-0.101-0.488-0.088-1.207-0.397-1.411c-1.395-0.92-2.887-1.693-4.344-2.52
      		c-0.528,1.29-1.524,2.593-1.49,3.868c0.079,2.978,0.77,5.933,0.962,8.913c0.063,0.986-0.623,2.892-0.992,2.898
      		c-3.581,0.063-7.169-0.188-10.754-0.351c0.854-2.45,1.662-4.917,2.589-7.34c0.276-0.724,0.88-1.321,1.432-2.119
      		c-8.81-0.705-9.454-0.127-9.19,7.732c0.056,1.663,0.098,3.342-0.071,4.992c-0.328,3.188,1.067,4.107,4.082,3.696
      		c3.789-0.517,7.63-0.665,11.425-1.146c3.835-0.484,5.698,1.653,6.182,4.909c0.682,4.603,0.904,9.278,1.234,13.929
      		c0.052,0.733-0.117,1.838-0.605,2.195c-2.977,2.18-13.113-2.573-13.875-6.285c-0.518-2.526-1.424-4.973-2.156-7.456
      		c-0.552,0.106-1.103,0.213-1.654,0.319c-0.373,3.419-0.557,6.872-1.166,10.249c-0.786,4.354,1.241,6.239,5.219,6.583
      		c4.471,0.386,8.973,0.447,13.424,0.971c5.664,0.667,6.454,0.173,6.062-5.731c-0.341-5.144-1-10.269-1.601-15.394
      		c-0.4-3.417,1.132-4.937,4.454-4.965c4.496-0.037,9.001-0.333,13.483-0.118c7.438,0.356,8.047,1.162,8.044,8.79
      		c0,2.166,0.032,4.335-0.082,6.496c-0.139,2.613,1.006,5.793-3.831,5.745c-7.175-0.074-9.007-0.91-9.326-5.916
      		c-0.127-1.986,0.322-4.038,0-5.975c-0.238-1.433-1.3-2.728-1.995-4.084c-0.925,1.197-2.229,2.27-2.674,3.625
      		c-0.5,1.521-0.284,3.295-0.275,4.959c0.051,10.102,0.058,10.12,9.987,11.638c3.077,0.471,7.383-0.255,8.949,1.504
      		c1.861,2.092,1.664,6.255,1.859,9.55c1.068,18.072,5.341,14.65-13.619,15.415c-9.877,0.399-9.853-0.111-12.253-9.7
      		c-0.32-1.277-1.363-2.373-2.071-3.552c-0.477,1.226-1.331,2.44-1.368,3.679c-0.246,8.26-0.497,8.635-8.67,9.233
      		c-2.148,0.158-4.329-0.141-6.481-0.005C651.717,622.69,647.783,621.096,646.573,614.646z M466.573,651.654
      		c0.72-0.122,1.438-0.244,2.158-0.366c0.078-0.946,0.449-1.984,0.185-2.822c-1.391-4.401-0.867-7.908,2.829-11.333
      		c2.424-2.248,2.033-4.833-2.274-5.753c-3.727-0.797-7.173-2.83-10.863-3.907c-1.802-0.526-3.977-0.465-5.769,0.061
      		c-0.575,0.169-1.14,3.348-0.521,3.974c1.208,1.222,3.399,1.403,4.915,2.416c1.457,0.973,2.575,2.453,3.842,3.71
      		c-1.404,0.802-2.772,2.196-4.22,2.285c-3.114,0.191-6.285-0.155-9.401-0.535c-2.679-0.327-4.005-1.859-3.767-4.8
      		c0.241-2.979,0.291-6.017-0.043-8.976c-0.104-0.923-1.73-1.673-2.659-2.502c-0.656,0.877-1.725,1.687-1.879,2.644
      		c-0.287,1.782-0.031,3.65-0.021,5.483c0.072,12.449-0.26,12.808-12.669,10.546c-1.421-0.26-3.336-2.136-3.616-3.543
      		c-0.937-4.714-1.09-9.58-1.874-14.333c-0.219-1.325-1.579-2.463-2.416-3.687c-0.798,1.209-2.248,2.402-2.277,3.63
      		c-0.095,3.967,0.379,7.945,0.528,11.923c0.047,1.261-0.06,3.585-0.396,3.642c-4.523,0.763-9.141,1.737-13.636,1.389
      		c-1.203-0.093-2.306-4.39-3.034-6.88c-0.707-2.418-0.189-5.488-4.322-5.707c-1.763,15.775-2.179,31.188,2.39,46.533
      		c1.362,4.575,4.149,6.158,7.948,7c3.123,0.691,6.285,0.063,7.514-3.321c1.365-3.759,1.434-7.603-2.034-11.253
      		c-0.471,2.14-0.602,3.845-1.245,5.329c-0.587,1.354-1.729,2.468-2.627,3.689c-1.503-1.178-4.239-2.284-4.311-3.543
      		c-0.458-7.938-0.372-15.911-0.323-23.872c0.006-0.962,0.621-2.449,1.369-2.794c6.672-3.091,14.546,1.358,15.029,8.653
      		c0.374,5.636,0.23,11.314,0.136,16.97c-0.092,5.494,3.502,8.454,7.536,10.89c0.937,0.565,3.002-0.405,4.34-1.081
      		c0.324-0.163,0.227-2.434-0.36-2.935c-6.417-5.486-5.98-12.964-6.172-20.288c-0.146-5.532,3.209-10.527,8.301-12.099
      		c3.354-1.036,5.603-0.631,6.216,3.716c1.113,7.897,2.891,15.699,4.163,23.578c0.777,4.82,3.572,6.48,7.779,6.398
      		c-1.509-5.122-3.237-9.979-4.329-14.976c-1.098-5.02-2.751-10.593,4.979-12.58c-2.241-0.742-4.196-0.937-5.437-1.979
      		c-0.697-0.585-0.541-3.672-0.219-3.767c6.413-1.896,12.904-3.52,18.539-4.98C465.364,645.572,465.968,648.613,466.573,651.654z
      		 M413.118,698.904c1.623-0.851,3.136-1.291,4.13-2.262c1.022-1.001,1.538-2.52,2.275-3.812c-1.494-0.234-3.273-1.1-4.426-0.579
      		c-3,1.354-5.636,3.5-8.597,4.969c-1.804,0.895-3.988,1.696-5.92,1.54c-6.091-0.492-12.205-1.157-18.188-2.359
      		c-3.187-0.64-7.754-1.827-8.704-4.048c-0.92-2.149,1.964-5.956,3.266-8.998c0.243-0.57,0.874-0.995,1.375-1.431
      		c3.578-3.123,4.896-7.128,5.725-11.772c2.176-12.2-2.62-23.654-3.288-35.534c-0.133-2.363-2.745-4.586-4.213-6.874
      		c-0.548,0.253-1.095,0.506-1.642,0.759c0,3.947-0.19,7.905,0.036,11.839c0.589,10.232,1.504,20.448,1.946,30.685
      		c0.093,2.146,1.561,5.742-2.933,6.073c-4.901,0.359-3.168-3.716-3.857-5.948c-1.178-3.812-1.864-7.775-2.758-11.675
      		c-0.568,0.056-1.137,0.111-1.706,0.167c-0.896,6.158-1.793,12.315-2.813,19.328c-3.503-2.574-6.444-4.734-9.385-6.895
      		c-0.404,0.213-0.809,0.426-1.213,0.639c0.938,3.012,1.014,7.591,3.008,8.69c3.456,1.906,8.1,1.658,12.757,2.394
      		c-0.101,1.005-0.248,2.953-0.496,4.888c-0.76,5.929,0.62,8.735,6.15,11.019c2.407,0.995,5.232,1.931,7.711,1.626
      		c9.334-1.147,18.139,1.155,27.029,3.239c1.605,0.376,3.307,0.488,4.96,0.458c1.029-0.019,2.733-0.313,2.917-0.88
      		c0.287-0.885-0.286-2.2-0.818-3.153C415.027,700.248,414.12,699.771,413.118,698.904z M485.285,646.508
      		c-0.56-0.171-1.119-0.342-1.679-0.513c-0.783,1.751-2.348,3.571-2.204,5.243c0.421,4.914,3.481,8.097,8.114,9.768
      		c4.413,1.592,8.24,1.197,11.973-2.147c3.851-3.45,4.702-7.728,5.187-12.436c0.127-1.229,1.33-2.35,2.04-3.519
      		c1.11,1.094,2.501,2.028,3.253,3.33c0.717,1.242,0.503,3.01,1.188,4.285c0.704,1.309,1.796,2.879,3.074,3.333
      		c6.576,2.333,13.241,4.432,19.947,6.363c0.932,0.269,2.263-0.85,3.409-1.326c-0.607-1.167-0.98-3.095-1.863-3.367
      		c-3.473-1.072-7.139-1.497-10.668-2.41c-2.54-0.658-5.242-1.296-7.404-2.675c-3.938-2.512-3.784-5.439,0.474-7.074
      		c2.345-0.9,5.434-0.912,7.883-0.231c3.04,0.846,4.415,0.195,4.998-2.715c1.04-5.195,2.262-10.367,3.001-15.606
      		c0.259-1.834-0.17-4.214-1.228-5.652c-0.926-1.261-3.141-1.912-4.853-2.057c-0.529-0.045-1.867,2.479-1.805,3.779
      		c0.166,3.47,0.887,6.915,1.393,10.368c0.329,2.248,1.396,5.291-2.603,4.609c-2.886-0.492-8.038,2.995-8.086-3.797
      		c-0.023-3.331,0.201-6.723-0.379-9.962c-0.261-1.459-2.217-2.615-3.404-3.908c-1.006,1.278-2.775,2.498-2.877,3.843
      		c-0.322,4.3,0.139,8.655-0.099,12.967c-0.051,0.917-1.775,2.479-2.722,2.474c-3.232-0.018-6.46-0.602-9.69-0.97
      		c0.091,3.457,0.182,6.915,0.273,10.371c0.009,0.333,0.033,0.666,0.031,0.999c-0.048,9.531,2.147,8.028-8.338,8.201
      		c-2.668,0.043-3.937-1.008-4.53-3.45C486.591,650.562,485.895,648.545,485.285,646.508z M549.405,674.877
      		c-0.293-0.898-0.587-1.796-0.881-2.696c-5.015,1.431-10.136,2.58-14.995,4.421c-2.083,0.789-3.622,3.017-5.407,4.591
      		c2.245,1.784,4.245,4.297,6.802,5.162c3.048,1.029,6.572,0.68,9.893,0.861c2.825,0.153,5.688,0,8.469,0.41
      		c1.171,0.173,2.179,1.446,3.261,2.22c-1.029,0.856-2.015,2.394-3.096,2.464c-13.83,0.899-28.128,4.724-40.271-5.869
      		c-3.855,6.523-2.955,8.514,4.204,9.572c3.456,0.511,6.915,1.439,10.371,1.434c10.105-0.019,20.227-0.16,30.308-0.79
      		c3.779-0.235,5.662-3.514,5.395-7.227c-0.259-3.602-2.354-5.467-6.303-5.556c-5.316-0.119-10.628-0.716-15.921-1.301
      		c-1.11-0.122-2.129-1.08-3.19-1.653c1.001-0.927,1.856-2.158,3.032-2.717C543.77,676.92,546.618,675.965,549.405,674.877z
      		 M243.764,684.934c0.111,0,0.223,0,0.334,0c0,3.157,0.258,6.345-0.115,9.458c-0.17,1.419-1.585,2.689-2.435,4.027
      		c-1.482-1.111-3.867-1.972-4.257-3.385c-0.82-2.969-0.546-6.231-0.809-9.366c-0.276-3.292-0.432-6.623-1.119-9.833
      		c-0.215-1.006-2.013-2.351-3.027-2.302c-0.892,0.043-2.376,1.783-2.43,2.833c-0.279,5.446-0.771,10.985-0.086,16.352
      		c1.183,9.271,1.786,9.455,10.823,9.542c0.332,0.003,0.664,0.028,0.997,0.044c6.41,0.306,7.494-0.205,8.077-6.614
      		c0.614-6.755,0.45-13.59,0.382-20.388c-0.014-1.379-0.944-3.797-1.752-3.931c-2.747-0.456-4.768,0.701-4.612,4.104
      		C243.877,678.62,243.764,681.78,243.764,684.934z M357.138,698.394c-2.971-2.035-4.781-3.845-6.962-4.648
      		c-4.386-1.619-7.036-4.611-7.545-9.025c-1.098-9.527-1.825-19.098-2.655-28.657c-0.381-4.389-0.396-8.83-1.105-13.161
      		c-0.235-1.439-2.12-2.608-3.25-3.901c-0.841,1.49-2.481,3.027-2.398,4.464c0.774,13.424,1.445,26.876,2.949,40.229
      		C337.452,695.061,346.01,701.046,357.138,698.394z M371.832,735.405c3.163,0.148,5.714,0.087,8.198,0.469
      		c1.067,0.165,1.994,1.248,2.985,1.914c-0.954,1.046-1.723,2.583-2.903,3.027c-1.982,0.746-4.258,0.672-6.342,1.211
      		c-0.686,0.178-1.157,1.183-1.727,1.81c0.709,0.509,1.49,1.517,2.111,1.427c1.571-0.229,3.095-0.888,4.589-1.494
      		c1.361-0.552,2.603-1.448,3.997-1.855c2.061-0.602,4.806,0.313,5.004-3.383c0.179-3.337-2.279-2.936-4.146-3.751
      		c-1.595-0.697-4.502-1.273-2.455-4.071c2.792-3.813,5.887-7.405,9.096-11.394c2.085,5.488,3.838,10.097,5.591,14.708
      		c4.869-5.523,4.886-11.278,4.509-17.046c-0.108-1.65-0.934-3.253-1.43-4.877c-1.741,0.834-3.507,1.621-5.208,2.53
      		c-0.561,0.3-0.895,1.001-1.433,1.372c-1.029,0.711-2.129,1.316-3.202,1.965c-0.485-1.289-1.506-2.645-1.352-3.852
      		c0.408-3.202-0.599-5.216-3.82-4.198c-1.298,0.41-2.593,3.071-2.659,4.755c-0.263,6.851-0.703,13.344-7.837,17.084
      		C372.422,732.267,372.259,734.336,371.832,735.405z M528.569,711.232c1.645,5.714,3.413,11.064,4.673,16.531
      		c0.754,3.272-0.695,5.377-4.51,5.491c-4.152,0.124-8.288,0.68-12.438,0.929c-7.492,0.45-10.264-1.609-11.613-9.035
      		c-0.89-4.897-1.197-9.899-2.087-14.796c-0.188-1.037-1.778-1.818-2.723-2.717c-0.489,1.139-1.374,2.272-1.385,3.416
      		c-0.027,2.475,0.213,4.989,0.668,7.427c0.935,5.017,1.676,10.14,3.369,14.911c0.583,1.646,3.645,3.051,5.761,3.386
      		c4.622,0.732,9.36,0.737,14.054,0.981c2.647,0.137,5.316,0.376,7.947,0.186c4.875-0.354,7.88-3.045,7.489-8.054
      		c-0.383-4.914-1.461-9.779-2.334-14.647C534.736,711.32,532.348,710.059,528.569,711.232z M411.168,858.883
      		c3.964-5.736,4.819-11.181,4.65-16.844c-0.093-3.114-0.375-5.82,3.877-6.661c1.353-0.269,2.766-2.699,3.123-4.361
      		c0.15-0.704-2.207-2.468-3.676-2.907c-7.473-2.244-7.497-2.146-4.745-8.896c0.104-0.257-0.067-0.627-0.119-1.013
      		c-3.292-0.289-6.7-0.165-9.865-0.981c-3.262-0.842-6.196-2.973-9.462-3.775c-1.327-0.326-3.625,1.011-4.563,2.272
      		c-0.538,0.725,0.513,3.942,1.276,4.111c5.111,1.133,10.342,1.712,15.52,2.558c1.301,0.212,3.604,0.624,3.661,1.139
      		c0.543,4.992,4.962,10.344-1.159,14.931c-0.999,0.748-1.742,2.735-1.563,4.002C408.854,847.633,409.991,852.752,411.168,858.883z
      		 M180.182,733.207c-5.39,7.612-5.005,10.274,0.75,15.7c5.154,4.857,11.035,5.051,17.207,3.186
      		c9.763-2.95,14.569-10.434,11.554-18.05c-0.629-1.589-2.699-3.339-4.327-3.593c-1.615-0.252-4.007,0.845-5.172,2.145
      		c-2.039,2.274-0.033,3.864,1.605,5.713c1.256,1.419,2.191,3.97,1.813,5.698c-0.265,1.214-2.95,2.602-4.606,2.671
      		c-4.283,0.179-8.643-0.041-12.869-0.729c-1.388-0.227-2.949-2.18-3.571-3.695C181.474,739.59,181.045,736.654,180.182,733.207z
      		 M239.833,862.225c-1.752-0.995-4.483-1.709-4.619-2.758c-0.844-6.537-4.396-3.431-7.732-2.815
      		c-2.267,0.419-4.774-0.672-7.168-0.635c-1.136,0.018-2.255,1.092-3.383,1.687c0.959,1.65,2.658,4.792,2.772,4.736
      		c5.863-2.906,7.765,1.858,10.569,5.116c2.892,3.362,6.408,4.199,10.77,2.562c4.348-1.632,6.467-4.199,5.901-8.942
      		c-0.254-2.132,0.127-4.338-0.098-6.478c-0.131-1.247-0.891-2.429-1.368-3.64c-1.438,0.84-3.641,1.364-4.148,2.59
      		C240.367,855.979,240.375,858.711,239.833,862.225z M228.542,742.543c4.373-3.197,8.509-5.104,11.019-8.29
      		c5.047-6.407,4.332-13.686,0.682-20.665c-0.487-0.93-1.968-1.342-2.988-1.994c-0.769,1.207-2.043,2.36-2.179,3.634
      		c-0.165,1.56,0.561,3.238,1.012,4.833c2.834,10.021,2.72,9.8-6.08,14.848c-3.329,1.91-6.074,4.951-8.747,7.795
      		c-0.502,0.533,0.238,3.447,1.025,3.776c4.098,1.712,8.346,3.145,12.656,4.214c1.244,0.308,3.898-0.643,4.117-1.482
      		c0.574-2.2,0.413-4.606-3.118-4.839C233.619,744.221,231.347,743.269,228.542,742.543z M514.136,748.493
      		c-0.156,2.758-0.607,5.539-0.406,8.271c0.388,5.287-3.681,3.48-6.159,4.072c-2.882,0.688-3.843-0.749-3.632-3.549
      		c0.14-1.842,0.762-4.19-2.577-4.142c-3.665,0.054-1.828,2.572-2.19,4.228c-0.249,1.14-1.16,2.135-1.775,3.195
      		c-1.17-0.808-3.073-1.412-3.367-2.464c-0.747-2.68-0.647-5.583-1.128-8.354c-0.442-2.554-1.197-5.053-1.814-7.575
      		c-1.087,2.55-2.851,5.024-3.145,7.664c-1.387,12.395-2.989,14.499,10.779,15.994c3.431,0.373,6.048,1.421,2.958,5.675
      		c1.606,0.354,2.792,0.614,3.977,0.876c-0.238-0.431-0.476-0.861-0.714-1.292c1.631-1.627,2.997-3.756,4.944-4.782
      		C515.082,763.572,518.097,753.901,514.136,748.493z M444.483,822.258c0.556-0.303,1.113-0.605,1.669-0.908
      		c-0.731-7.091-1.01-14.27-2.365-21.239c-0.807-4.147-2.074-6.866,3.41-8.862c4.839-1.76,5.652-7.444,2.684-11.626
      		c-0.493-0.694-1.48-1.037-2.239-1.543c-0.413,0.768-1.042,1.498-1.183,2.312c-0.164,0.955-0.008,2.003,0.192,2.974
      		c0.865,4.211-0.371,6.016-5.079,5.776c-4.695-0.239-9.014-0.233-11.153-5.486c-0.308-0.757-1.772-1.042-2.702-1.546
      		c-0.306,1.009-0.827,2.006-0.879,3.027c-0.229,4.527,2.611,6.859,6.523,7.457c4.902,0.75,5.745,3.367,5.793,7.854
      		c0.062,5.775,1.061,11.576,2.096,17.285C441.55,819.383,443.364,820.758,444.483,822.258z M487.517,695.947
      		c1.716-6.854,1.391-7.719-4.541-8.623c-2.74-0.418-3.084-1.708-3.123-3.968c-0.06-3.485-0.037-7.048-0.805-10.404
      		c-0.275-1.2-2.746-1.897-4.214-2.825c-0.539,1.26-1.648,2.587-1.51,3.767c0.385,3.283,1.344,6.494,1.961,9.756
      		c0.259,1.367,0.646,3.844,0.251,4.001c-1.545,0.616-3.493,0.96-5.079,0.561c-2.38-0.6-4.532-2.06-6.884-2.839
      		c-0.916-0.303-2.116,0.251-3.186,0.414c0.321,1.199,0.276,3.085,1.024,3.485c4.5,2.403,9.017,5.252,13.88,6.284
      		c2.199,0.467,5.213-2.907,8.099-4.687C484.568,692.318,485.978,694.054,487.517,695.947z M302.151,744.747
      		c-0.879,1.302-1.256,2.569-1.953,2.78c-5.658,1.71-11.372,3.232-17.078,4.783c-1.168,0.317-2.37,0.513-3.556,0.764
      		c0.529,1.689,0.86,3.486,1.684,5.016c0.336,0.625,1.937,1.178,2.629,0.908c7.881-3.063,15.723-6.235,23.5-9.553
      		c0.983-0.42,2.348-2.479,2.166-2.714c-2.581-3.322-4.833-7.631-8.318-9.312c-3.455-1.666-7.128,1.19-9.411,5.419
      		C295.356,743.491,298.514,744.075,302.151,744.747z M734.858,716.104c-3.737-1.201-6.47-2.08-9.862-3.169
      		c0,3.842,0.593,7.113-0.163,10.036c-0.786,3.041-2.874,5.743-4.647,9.073c9.166-0.272,8.415,6.338,8.707,12.256
      		c0.016,0.332,0.029,0.665,0.035,0.997c0.094,4.996-1.661,6.192-6.771,5.299c-2.132-0.371-4.429,0.202-6.649,0.347
      		c0.064,0.593,0.129,1.186,0.193,1.778c3.949,0,7.954,0.399,11.822-0.158c2.131-0.308,5.553-2.006,5.759-3.453
      		c0.744-5.226,0.388-10.609,0.363-15.934c-0.001-0.253-0.774-0.6-1.238-0.737c-2.396-0.712-5.342-1.151-4.232-4.704
      		C729.426,723.728,727.457,718.065,734.858,716.104z M655.987,1125.758c-0.436-0.254-0.87-0.508-1.306-0.762
      		c-1.001,1.143-2.242,2.151-2.958,3.451c-2.449,4.442-4.838,8.93-6.973,13.527c-0.688,1.48-1.219,3.749-0.549,4.942
      		c0.665,1.182,2.892,1.916,4.504,2.061c5.795,0.519,11.634,0.57,17.43,1.089c2.12,0.189,3.945,0.978,4.021-2.271
      		c0.061-2.597-0.549-4.04-3.6-3.852c-3.983,0.246-7.993,0.025-11.989,0.133c-2.721,0.074-3.806-1.959-2.14-3.242
      		C658.055,1136.5,654.944,1130.774,655.987,1125.758z M231.551,895.013c-8.94,4.662-12.226,12.165-10.982,23.302
      		c0.738,6.609,5.233,10.277,13.009,9.776c-7.899-7.918-6.372-16.337-2.608-25.109C231.892,900.833,231.374,898.066,231.551,895.013z
      		 M261.013,960.764c-0.309,0.115-0.618,0.231-0.926,0.348c0.654,1.499,1.308,2.998,1.962,4.496c1.351-0.919,2.752-1.774,4.037-2.779
      		c0.906-0.708,1.519-1.883,2.5-2.389c3.071-1.581,2.574-3.202,1.016-5.841c-2.313-3.916-4.083-8.188-5.746-12.441
      		c-1.321-3.381-1.936-7.037-3.283-10.405c-0.459-1.149-2.188-1.791-3.338-2.664c-0.368,1.27-1.207,2.616-1.017,3.798
      		c0.627,3.914,1.778,7.741,2.444,11.65C259.582,949.922,260.242,955.352,261.013,960.764z M182.686,820.389
      		c0.388,0.361,0.776,0.722,1.165,1.082c3.178-1.755,7.694-2.738,9.251-5.432c3.061-5.293,1.464-12.693,7.034-17.316
      		c0.312-0.26-1.353-2.903-2.098-4.438c-1.245,0.824-2.629,1.504-3.691,2.52c-0.845,0.809-1.262,2.907-1.926,2.933
      		c-7.355,0.278-7.012,5.399-7.054,10.508c-0.011,1.409-0.818,2.801-1.197,4.218C183.644,816.431,183.178,818.413,182.686,820.389z
      		 M350.085,730.635c-10.105,4.105-10.103,4.105-13.446-4.877c-0.231-0.622-0.45-1.701-0.793-1.752
      		c-1.562-0.227-3.165-0.161-4.753-0.201c0.991,4.252,0.666,9.753,3.258,12.487c4.805,5.069,14.512,3.084,18.894-2.581
      		c1.318-1.704,2.575-3.473,3.679-5.32c0.718-1.201,1.107-2.599,1.644-3.908c-1.553-0.482-3.093-1.322-4.662-1.375
      		C350.029,722.976,348.74,725.57,350.085,730.635z M571.078,655.624c0.394-0.225,0.786-0.448,1.179-0.672
      		c-0.602-1.612-0.962-4.465-1.844-4.636c-6.439-1.253-4.648-5.104-3.358-9.03c0.51-1.551,1.003-3.245,0.865-4.817
      		c-0.072-0.816-1.472-2.049-2.386-2.161c-3.239-0.396-7.543,5.933-6.696,9.011c0.421,1.529-0.203,3.391-0.521,5.07
      		c-0.746,3.927-1.688,7.821-2.313,11.766c-0.113,0.719,0.898,1.615,1.39,2.431c0.933-0.599,2.34-0.993,2.702-1.837
      		c0.906-2.105,1.28-4.432,1.975-6.637c0.837-2.66,1.973-4.321,4.733-1.508C568.004,653.83,569.64,654.631,571.078,655.624z
      		 M764.016,644.633c-1.754,2.445-3.083,4.296-4.412,6.148c-2.625-2.282-5.25-4.563-7.876-6.844c2.151-2.437,4.301-4.874,6.015-6.816
      		c-0.665-0.712-3.387-2.275-3.705-4.232c-0.831-5.08-0.41-5.149-6.006-4.364c0.899,1.081,2.591,2.186,2.562,3.241
      		c-0.129,4.642-0.261,9.388-1.333,13.872c-1.178,4.932,1.483,7.897,8.504,10.856c5.523,2.329,5.696-2.534,7.651-5.112
      		C767.956,648.034,768.145,645.434,764.016,644.633z M432.252,705.289c3.088,3.586,4.942,2.84,6.503-0.203
      		c2.899-5.65,7.446-7.696,13.625-6.237c1.447,0.343,3.11,1.033,4.353,0.613c1.134-0.383,1.834-2.053,2.726-3.156
      		c-1.612-1.177-3.066-2.895-4.868-3.417c-3.626-1.05-7.428-1.562-11.187-2.064c-1.831-0.244-4.798-0.666-5.373,0.216
      		c-1.145,1.758-1.811,4.629-1.18,6.551c0.898,2.737,0.66,4.36-1.715,5.786C434.163,703.963,433.239,704.631,432.252,705.289z
      		 M651.19,1094.12c-0.477,0.018-0.954,0.035-1.432,0.053c-1.693,2.208-3.528,4.324-5.039,6.649c-1.569,2.418-2.92,2.533-5.094,0.621
      		c-1.833-1.61-4.092-2.769-6.263-3.94c-0.514-0.277-1.906,0-2.058,0.367c-0.26,0.632-0.146,1.935,0.307,2.258
      		c3.064,2.179,6.052,4.655,9.459,6.119c5.019,2.157,9.906,4.074,10.966,10.698c3.483-4.868,5.71-9.141,0.916-13.781
      		c-0.459-0.444-0.332-1.494-0.478-2.263C652.047,1098.642,651.619,1096.381,651.19,1094.12z M203.915,1001.022
      		c-1.889-0.827-3.75-1.923-5.759-2.456c-3.178-0.842-3.878-2.363-2.589-5.392c1.323-3.106,2.202-6.403,3.533-10.389
      		c-3.114,0-5.599-0.314-7.933,0.14c-1.07,0.209-2.666,1.967-2.616,2.952c0.224,4.444,0.965,8.861,1.492,13.293
      		c0.426,3.584,2.817,5.02,6.022,5.554C199.301,1005.264,202.424,1005.47,203.915,1001.022z M518.741,847.899
      		c3.918-0.695,7.743-0.594,8.033-1.558c1.407-4.673,4.519-3.078,7.471-2.623c1.183-4.367,2.323-8.585,3.834-14.169
      		c-6.592,5.203-11.922,1.083-17.994-0.138C525.493,835.48,523.964,841.252,518.741,847.899z M254.307,810.422
      		c-4.987,6.192-4.8,9.677,0.625,14.797c1.435,1.354,3.11,2.798,3.78,4.542c1.4,3.646,2.078,7.562,3.335,11.272
      		c0.344,1.017,1.689,1.693,2.575,2.526c0.514-0.73,1.507-1.486,1.47-2.188C265.482,829.809,261.101,819.648,254.307,810.422z
      		 M268.575,1117.147c0.326,0.195,0.652,0.391,0.979,0.587c1.499-1.988,3.735-3.771,4.364-6.005
      		c2.144-7.62,3.695-15.389,1.883-23.395c-0.476-2.103-1.09-3.229-3.609-2.498c-2.179,0.632-3.557,1.062-2.84,3.943
      		c0.622,2.501,0.568,5.229,0.449,7.841C269.502,1104.135,268.997,1110.64,268.575,1117.147z M281.863,810.368
      		c0.984,1.429,1.997,3.564,3.623,5.015c1.028,0.917,2.971,0.811,4.503,1.164c0.226-1.379,0.667-2.763,0.632-4.134
      		c-0.071-2.79-0.92-5.653-0.516-8.345c0.951-6.328,0.167-8.698-5.686-11.114c-1.224-0.505-3.475,0.704-4.805,1.713
      		c-0.393,0.298,0.364,3.455,1.255,3.864c3.637,1.673,3.936,3.912,2.275,7.136C282.552,806.818,282.43,808.212,281.863,810.368z
      		 M712.196,696.165c5.368,7.989,21.236,11.381,27.912,2.71c0.522-0.68,0.95-2.128,0.595-2.619c-0.506-0.696-1.799-1.199-2.722-1.149
      		c-4.313,0.233-8.609,0.816-12.924,1.019C721.128,696.309,717.182,696.165,712.196,696.165z M597.875,940.055
      		c2.867,2.986,4.557,5.569,6.933,7.057c5.92,3.706,6.894,9.049,6.731,15.402c-0.119,4.702,0.799,9.43,1.255,14.145
      		c0.937-0.074,1.872-0.148,2.809-0.223c-0.153-3.218,0.312-6.626-0.599-9.613c-1.546-5.075-2.121-9.91-0.781-15.122
      		c0.479-1.863,0.279-4.947-0.913-6.004C609.516,942.337,605.288,939.221,597.875,940.055z M180.605,856.879
      		c-1.787,4.853-3.627,8.581-0.356,12.283c3.346,3.789,7.425,2.893,11.52,1.646c4.468-1.36,6.436-5.655,6.053-12.624
      		c-0.123-2.239-0.627-3.412-3.192-3.059c-2.521,0.348-3.194,1.677-2.242,3.762c2.204,4.821-1.081,7.086-4.152,10.009
      		C185.712,864.921,183.428,861.323,180.605,856.879z M735.892,646.163c-0.467,0.209-1.055,0.489-1.655,0.74
      		c-5.388,2.251-5.39,2.25-5.444-3.488c-0.017-1.822,0.174-3.68-0.134-5.453c-0.144-0.833-1.228-2.096-1.908-2.109
      		c-1.102-0.023-3.17,0.798-3.184,1.306c-0.116,4.49-1.606,10.638,0.704,13.074c2.976,3.135,9.06,3.393,13.874,4.624
      		c0.631,0.161,1.667-1.259,2.516-1.946c-0.589-0.709-1.232-1.381-1.756-2.135C737.952,649.401,737.07,647.977,735.892,646.163z
      		 M354.997,743.047c1.249,1.864,2.163,4.132,3.817,5.508c3.425,2.849,1.825,4.85-0.696,6.718c-1.725,1.276-4.239,1.763-5.491,3.328
      		c-1.583,1.98-2.471,4.637-3.17,7.139c-0.225,0.805,1.096,2.042,1.709,3.082c1.081-0.762,2.303-1.385,3.216-2.312
      		c2.009-2.042,3.719-4.395,5.825-6.322c1.68-1.537,4.621-2.268,5.379-4.048c0.538-1.263-1.635-3.675-2.579-5.582
      		c-0.144-0.29-0.303-0.646-0.261-0.943C363.494,744.299,359.534,743.466,354.997,743.047z M165.722,837.368
      		c3.612,3.089,6.225,5.276,8.78,7.528c1.231,1.085,2.193,2.561,3.563,3.379c1.858,1.109,3.397,0.557,2.929-1.937
      		c-0.947-5.042-1.996-10.075-3.315-15.027c-0.223-0.836-1.981-1.622-3.094-1.723c-0.641-0.059-1.601,1.2-2.07,2.057
      		c-0.509,0.929-0.615,2.08-0.958,3.361C167.05,832.696,165.348,834.232,165.722,837.368z M261.556,806.434
      		c-2.03-5.667-3.52-10.428,3.998-10.713c0.085-0.003,0.252-3.93,0.073-3.956c-3.192-0.476-7.329-1.953-9.436-0.523
      		c-2.727,1.853-4.302,5.942-5.32,9.381c-0.245,0.83,3.568,3.01,5.669,4.323C257.59,805.601,258.985,805.702,261.556,806.434z
      		 M596.559,630.944c-0.423-0.598-0.845-1.194-1.268-1.792c-1.619,0.944-3.859,1.496-4.746,2.902
      		c-3.23,5.116-3.024,5.164-6.524,0.422c-0.714-0.967-2.349-1.253-3.56-1.854c-0.33,1.192-1.192,2.543-0.892,3.547
      		c0.991,3.31,2.252,6.564,3.729,9.689c0.561,1.187,1.871,2.461,3.066,2.747c0.803,0.191,2.395-1.062,2.959-2.053
      		C591.866,640.091,594.169,635.493,596.559,630.944z M609.098,910.469c-6.11-0.607-10.724-1.067-15.338-1.526
      		c-0.101,0.639-0.2,1.277-0.3,1.916c2.316,0.757,4.633,1.515,7.827,2.559c-5.035,3.376-9.04,7.426-5.99,12.62
      		c1.957,3.334,6.715,3.636,11.464,1.543c-2.899-1.777-5.296-3.245-7.353-4.505C602.515,919.035,605.305,915.404,609.098,910.469z
      		 M697.451,802.94c7.354,6.886,25.598,4.978,31.231-2.179c0.629-0.798,0.281-2.363,0.387-3.572
      		c-1.093-0.051-2.463-0.556-3.234-0.082c-6.353,3.895-12.849,6.946-20.644,5.887C702.655,802.649,700.033,802.94,697.451,802.94z
      		 M315.389,1139.184c0,4.424,0.592,8.953-0.165,13.245c-0.777,4.409-2.87,8.588-4.47,13.101c6.473-1.237,10.931-6.966,11.066-13.662
      		C321.963,1144.826,319.596,1140.151,315.389,1139.184z M585.022,664.363c-2.583,0-4.598-0.265-6.484,0.111
      		c-0.954,0.189-2.545,1.925-2.376,2.306c3.602,8.087-0.332,10.648-7.702,11.042c-0.559,0.029-1.442,1.355-1.451,2.086
      		c-0.007,0.644,0.888,1.681,1.581,1.888c6.832,2.045,11.721-0.457,13.997-7.316C583.616,671.375,584.141,668.101,585.022,664.363z
      		 M381.83,749.805c-6.154,0.514-15.138,10.384-15.46,15.191c-0.097,1.441,0.046,3.153,0.755,4.336
      		c0.992,1.655,2.617,2.93,3.971,4.367c0.599-1.722,1.867-3.523,1.672-5.149c-0.549-4.575,0.95-7.836,4.281-10.975
      		C379.185,755.562,380.272,752.433,381.83,749.805z M620.162,1093.253c0.559,0.896,1.5,2.405,2.441,3.915
      		c1.165-1.39,2.695-2.62,3.395-4.215c0.7-1.598,0.759-3.56,0.769-5.364c0.021-3.807-0.584-7.661,4.051-9.735
      		c0.484-0.217-0.243-3.878-0.997-5.696c-0.238-0.576-3.402-0.744-3.518-0.442c-1.117,2.913-1.769,5.68-3.896,8.494
      		C620.221,1083.104,620.886,1088.153,620.162,1093.253z M595.334,1022.324c1.407,2.441,2.099,5.515,3.799,6.25
      		c5.569,2.407,10.769,0.296,16.011-2.29c3.634-1.792,2.511-4.392,2.824-7.033c0.128-1.088,1.641-1.944,2.081-3.078
      		c0.684-1.755,1.298-3.662,1.279-5.498c-0.007-0.62-2.025-1.22-3.124-1.829c-0.38,1.609-1.06,3.214-1.09,4.83
      		c-0.134,7.138-4.228,11.551-11.245,11.187C602.743,1024.7,599.675,1023.413,595.334,1022.324z M421.153,723.687
      		c-0.709,4.404,1.3,7.077,5.366,8.096c3.715,0.931,10.664-4.103,11.771-8.295c1.193-4.515-1.247-6.931-5.124-8.902
      		c-0.5,3.64,0.109,8.624-1.647,9.684C429.173,725.684,424.99,724.052,421.153,723.687z M229.983,754.919
      		c-0.274-0.302-0.548-0.604-0.823-0.905c-7.178,0-14.357,0.065-21.534-0.031c-2.99-0.04-4.029,0.768-2.672,3.802
      		c1.662,3.714,3.26,6.865,8.275,4.327c1.874-0.949,4.298-0.775,6.226-1.652C223.06,758.819,226.484,756.788,229.983,754.919z
      		 M231.456,1074.313c4.801-1.141,5.808-2.75,3.729-7.807c-1.567-3.816-3.397-7.524-5.105-11.283
      		c-1.827-4.021-4.451-4.248-7.275-2.132C225.749,1060.312,228.573,1067.241,231.456,1074.313z M243.698,900.23
      		c-0.552,4.18-1.46,8.074-1.441,11.964c0.013,2.64,0.87,5.55,2.231,7.808c0.816,1.353,3.33,1.682,5.08,2.469
      		c-0.207-2.017,0.104-4.296-0.749-5.987c-1.118-2.216-3.006-3.748-0.834-6.529C252.022,904.785,250.538,901.616,243.698,900.23z
      		 M610.459,876.348c1.999-1.383,5.881-2.879,5.835-4.242c-0.194-5.726-1.414-11.445-2.647-17.081
      		c-0.212-0.969-2.318-1.522-3.55-2.269c-0.405,1.034-1.202,2.093-1.139,3.097c0.156,2.46,0.582,4.923,1.136,7.331
      		c0.791,3.432,0.994,6.505-2.663,8.591c-0.64,0.364-1.256,2.195-1.054,2.366C607.316,874.934,608.549,875.38,610.459,876.348z
      		 M317.302,733.05c-0.067,0-0.135,0-0.202,0c0,1.324-0.289,2.731,0.068,3.951c0.518,1.765,1.502,3.392,2.287,5.078
      		c1.135-1.791,2.74-3.447,3.3-5.402c0.891-3.108,0.974-6.439,1.603-9.636c0.802-4.078-1.138-7.609-4.663-7.708
      		c-0.779-0.021-2.246,1.164-2.285,1.856C317.188,725.133,317.302,729.094,317.302,733.05z M185.093,831.535
      		c4.9,1.585,8.014,4.258,8.474,9.409c0.126,1.413,2.06,2.664,3.164,3.988c0.793-1.621,1.889-3.172,2.291-4.885
      		c0.364-1.554,0.359-3.423-0.171-4.906c-0.99-2.768-1.81-6.616-3.912-7.732C191.477,825.57,188.104,827.99,185.093,831.535z
      		 M256.738,865.718c7.554,6.46,8.236,6.451,14.569,1.082c0.253-0.216,0.507-0.432,0.768-0.639c2.401-1.907,6.364-3.492,4.734-7.118
      		c-0.85-1.89-4.515-2.524-6.931-3.68c-0.212-0.101-0.591,0.149-0.973,0.26c0.039,0.475-0.01,0.974,0.13,1.412
      		c2.051,6.41,0.612,8.239-6.209,8.128C260.8,865.13,258.768,865.521,256.738,865.718z M264.948,770.052
      		c-4.519,2.033-4.775,4.503-2.007,7.539c4.363,4.782,9.449,7.872,16.095,6.438c0.492-1.821,1.027-3.802,1.601-5.923
      		C274.587,776.688,266.545,779.712,264.948,770.052z M241.368,1099.381c0.384-0.25,2.35-1.18,2.251-1.53
      		c-0.406-1.447-1.12-3.344-2.297-3.941c-3.817-1.938-6.497-4.359-6.79-8.952c-0.064-1.008-1.294-1.943-1.987-2.913
      		c-1.099,1.046-2.844,1.914-3.188,3.167C227.588,1091.658,233.869,1099.463,241.368,1099.381z M408.434,725.804
      		c-5.575,1.552-6.24,3.348-4.128,8.142c2.976,6.754,8.408,7.709,14.72,6.786c1.38-0.202,2.648-1.169,3.968-1.784
      		c-1.24-0.66-2.437-1.788-3.727-1.907c-9.436-0.875-9.442-0.812-10.52-10.49C408.729,726.392,408.621,726.243,408.434,725.804z
      		 M608.396,648.762c-1.418-5.856-2.571-11.004-3.994-16.078c-0.203-0.724-1.668-1.375-2.62-1.495
      		c-0.501-0.063-1.688,1.12-1.659,1.696c0.251,5.099,0.363,10.254,1.316,15.238c0.271,1.419,3.106,2.464,4.934,3.322
      		C606.605,651.555,607.861,649.488,608.396,648.762z M593.47,948.271c1.508,1.346,2.605,2.558,3.926,3.431
      		c1.018,0.673,2.314,0.918,3.487,1.354c-0.006-1.494,0.558-3.344-0.115-4.421c-2.818-4.507-6.798-7.535-12.825-8.168
      		c0.969,4.668-1.924,9.825,3.04,14.197C591.968,952.13,592.611,950.478,593.47,948.271z M400.562,774.537
      		c0.043-7.21-0.622-14.484,7.662-18.152c0.911-0.403,1.729-3.536,1.169-4.199c-1.019-1.202-3.159-2.091-4.729-1.953
      		c-0.971,0.084-1.982,2.213-2.561,3.598c-0.665,1.592-0.389,3.747-1.361,5.01C396.104,764.862,395.873,767.412,400.562,774.537z
      		 M254.142,1098.859c-1.231,1.368-3.358,2.632-3.53,4.122c-0.569,4.943,3.54,11.043,6.911,10.626
      		c4.099-0.507,2.966-4.298,2.983-6.66C260.53,1103.636,261.316,1098.929,254.142,1098.859z M221.639,803.762
      		c-4.384,0.438-8.594,0.284-12.335,1.533c-1.38,0.462-1.925,4.193-2.281,6.521c-0.121,0.792,1.41,2.535,2.355,2.672
      		C214.095,815.171,221.159,808.921,221.639,803.762z M385.765,833.595c2.737,7.523,2.961,15.516,7.805,22.113
      		c0.422-6.015-0.425-11.829-0.601-17.666C392.82,833.132,390.73,831.979,385.765,833.595z M283.494,736.528
      		c-1.094-2.274-1.45-4.519-2.595-5.046c-1.777-0.816-4.091-0.462-6.178-0.604c-0.095,1.848-0.191,3.695-0.283,5.542
      		c-0.083,1.665-0.128,3.333-0.253,4.994c-0.089,1.185-0.284,2.36-0.432,3.54c1.494-0.104,3.649,0.375,4.356-0.425
      		C280.18,742.189,281.674,739.337,283.494,736.528z M452.566,739.31c0.477-0.429,0.955-0.857,1.432-1.286
      		c-0.743-2.531-0.95-6.83-2.332-7.248c-3.624-1.098-7.963-1.214-11.574-0.176c-1.191,0.343-1.024,5.407-1.722,9.865
      		C443.776,736.602,448.059,734.626,452.566,739.31z M621.403,846.134c0.505,0.188,1.01,0.375,1.515,0.563
      		c-0.309-6.464,2.919-10.019,8.589-11.715c0.833-0.249,1.816-1.166,2.042-1.968c0.262-0.936-0.197-2.99-0.404-2.998
      		c-2.2-0.087-4.637-0.396-6.563,0.414c-2.311,0.973-5.58,2.7-5.95,4.588C619.94,838.537,621.046,842.409,621.403,846.134z
      		 M607.395,1052.086c2.458-3.723,4.643-6.657,6.34-9.852c0.445-0.838-0.269-3.244-1.055-3.602c-2.091-0.95-4.348-4.925-6.665-1.416
      		c-1.79,2.709-2.55,6.379-2.798,9.693C603.113,1048.298,605.518,1049.874,607.395,1052.086z M688.964,556.659
      		c-0.331-6.884-5.108-5.917-8.168-5.628c-2.015,0.19-4.216,2.933-5.372,5.055c-0.377,0.691,2.893,4.591,3.452,4.372
      		C682.322,559.101,687.121,560.146,688.964,556.659z M205.567,869.393c0.621,0.187,1.241,0.374,1.861,0.561
      		c0.8-2.011,2.404-4.097,2.223-6.015c-0.317-3.366-1.409-6.774-2.823-9.869c-0.65-1.422-2.835-2.143-4.324-3.18
      		c-0.383,1.622-1.382,3.393-1.037,4.843C202.565,860.35,204.163,864.849,205.567,869.393z M328.309,679.815
      		c0.784-0.555,1.359-0.745,1.547-1.127c1.79-3.631-2.551-18.218-6.175-19.324c-1.438-0.438-3.581,1.43-5.402,2.248
      		c0.577,1.076,1.025,2.251,1.76,3.207c1.404,1.827,3.341,3.335,4.385,5.329C325.999,673.154,326.998,676.463,328.309,679.815z
      		 M247.139,984.604c2.722-3.287,5.028-5.896,7.096-8.683c0.596-0.804,1.011-2.792,0.633-3.076c-1.26-0.952-3.083-2.137-4.39-1.854
      		c-3.134,0.68-6.097,2.145-9.086,3.276C243.26,977.628,245.009,980.773,247.139,984.604z M625.025,1000.963
      		c2.258-3.979,4.293-7.279,6.021-10.732c0.847-1.692,1.124-3.669,1.659-5.518c-1.874-0.325-4-1.334-5.542-0.764
      		c-1.268,0.469-2.368,2.726-2.638,4.341c-1.54,9.25-1.448,9.266-8.606,11.196C619.149,1000.01,622.772,1000.597,625.025,1000.963z
      		 M711.079,657.163c-0.762-2.483-0.976-4.189-1.772-5.554c-1.346-2.305-0.301-7.279-4.385-6.354
      		c-2.435,0.552-4.181,4.327-6.118,6.746c-0.462,0.577-0.458,1.529-0.773,2.694C702.19,655.482,706.164,656.233,711.079,657.163z
      		 M586.249,884.37c-0.228,2.107-0.343,3.583-0.556,5.045c-0.237,1.634-0.507,3.267-0.86,4.88c-1.772,8.083-1.35,8.542,7.32,7.352
      		C589.948,896.166,593.59,888.993,586.249,884.37z M719.899,624.538c-0.043,0.6-0.085,1.199-0.128,1.799
      		c1.05,0.944,2.161,2.755,3.14,2.686c5.114-0.362,10.203-1.145,15.271-1.965c0.495-0.08,1.262-1.589,1.067-2.141
      		c-0.373-1.056-1.434-2.765-2.056-2.702C731.41,622.807,725.66,623.719,719.899,624.538z M570.839,841.769
      		c-4.73-1.261-8.961-2.409-13.203-3.51c-2.513-0.652-4.036,0.684-2.573,2.718c1.743,2.424,4.312,5.13,6.994,5.788
      		C565.688,847.656,569.61,846.462,570.839,841.769z M581.325,841.222c2.177-1.913,3.472-3.012,4.723-4.158
      		c1.851-1.694,3.671-3.422,5.5-5.14c0.661-0.62,1.312-1.249,1.969-1.874c-0.846-0.713-1.579-1.735-2.554-2.087
      		c-5.869-2.113-10.475,1.319-10.074,7.393C580.985,836.826,581.106,838.297,581.325,841.222z M556.848,830.951
      		c5.509,2.761,9.389,4.875,13.42,6.638c1.582,0.691,4.701,0.677,3.229-1.968c-1.795-3.224-4.823-6.29-8.069-7.947
      		C563.85,826.869,560.557,829.425,556.848,830.951z M496.545,796.221c-0.699,0.181-1.398,0.36-2.098,0.541
      		c0.617,6.616,1.161,13.241,1.946,19.838c0.091,0.768,1.512,1.378,2.315,2.061c0.449-0.747,1.297-1.504,1.281-2.241
      		c-0.121-5.267-0.228-10.551-0.779-15.783C499.047,799.088,497.473,797.688,496.545,796.221z M253.22,940.771
      		c-3.079-1.352-6.309-3.65-7.155-2.972c-2.392,1.915-4.172,4.902-5.353,7.809c-0.3,0.739,2.526,2.75,3.917,4.177
      		C247.202,947.085,249.775,944.386,253.22,940.771z M567.212,859.426c1.651,1.728,3.338,3.786,3.534,3.652
      		c3.146-2.14,6.102-4.559,9.101-6.914c-2.353-1.374-5.215-4.299-6.932-3.736C570.658,853.169,569.304,856.657,567.212,859.426z
      		 M468.031,797.72c-3.28,5.593-0.976,18.524,3.803,21.448C472.587,811.587,472.534,804.498,468.031,797.72z M456.795,853.504
      		c0.178-0.742,0.357-1.484,0.536-2.225c-3.899-2.043-7.72-4.267-11.747-6.015c-1.188-0.516-2.958,0.312-4.461,0.523
      		c0.666,1.979,0.942,5.376,2.068,5.685C447.579,852.681,452.237,852.91,456.795,853.504z M254.355,732.367
      		c-0.608-4.514-1.096-8.229-1.62-11.942c-0.114-0.812-0.141-1.765-0.587-2.374c-0.931-1.269-2.116-2.352-3.197-3.511
      		c-0.97,1.406-2.456,2.697-2.804,4.244C245.383,722.175,249.878,729.823,254.355,732.367z M596.901,832.553
      		c4.666,1.71,6.69,3.893,4.498,8.084c-0.305,0.583,0.635,1.816,0.999,2.748c0.79-0.572,2.218-1.095,2.268-1.726
      		c0.343-4.336,2.828-5.788,6.875-5.204C610.193,830.669,605.874,829.432,596.901,832.553z M465.889,729.347
      		c0-4.44-0.112-8.728,0.041-13.007c0.101-2.804-0.56-4.512-3.79-4.097c-3.731,0.479-1.401,2.885-1.421,4.492
      		c-0.048,3.801,3.823,7.811-1.467,11.508C461.885,728.68,463.735,728.988,465.889,729.347z M470.86,791.835
      		c-0.574-0.629-0.775-0.896-1.021-1.111c-2.792-2.442-8.396-3.229-5.4-9.159c0.054-0.107-1.363-1.363-2.084-1.353
      		c-0.99,0.014-2.819,0.72-2.822,1.139c-0.021,3.207-0.821,7.3,0.818,9.407C462.522,793.55,466.688,794.803,470.86,791.835z
      		 M726.01,683.302c-1.256-4.592-2.089-8.561-3.546-12.284c-0.34-0.869-2.838-1.283-4.282-1.166c-0.74,0.061-1.779,1.69-1.94,2.725
      		C715.475,677.508,718.133,680.609,726.01,683.302z M385.331,772.646c0.701-0.192,1.401-0.386,2.102-0.579
      		c2.752-4.778,0.979-9.527-0.691-14.091c-0.337-0.918-2.71-1.202-4.199-1.505c-0.348-0.07-1.416,1.104-1.316,1.489
      		C382.507,762.879,383.938,767.758,385.331,772.646z M282.313,899.613c-3.74-5.203-7.053-9.812-11.142-15.503
      		C270.459,898.686,272.336,901.252,282.313,899.613z M484.913,732.005c0.053-0.155,0.105-0.312,0.157-0.467
      		c1.161,0,2.582,0.431,3.43-0.085c2.073-1.259,3.88-2.955,5.795-4.475c-2.058-0.664-4.077-1.575-6.185-1.896
      		c-1.192-0.182-2.645,0.396-3.78,1.004c-2.102,1.127-4.057,2.526-6.073,3.813C480.476,730.601,482.695,731.303,484.913,732.005z
      		 M220.254,887.063c0.573,0.694,1.89,2.291,3.207,3.888c2.424-2.055,5.383-3.762,7.046-6.313c0.826-1.268-0.6-3.972-3.688-3.713
      		C223.574,881.196,220.312,881.016,220.254,887.063z M241.132,993.358c2.053-6.033,0.163-9.834-3.22-13.121
      		c-0.75-0.729-2.385-1.672-2.822-1.37c-0.924,0.637-1.708,1.975-1.877,3.124C232.344,987.894,237.75,989.319,241.132,993.358z
      		 M305.296,716.207c1.203,6.771,2.057,11.576,2.918,16.425c4.823-2.332,5.856-5.039,3.595-12.376
      		C311.326,718.689,308.209,717.934,305.296,716.207z M675.421,820.5c1.14,6.999,2.091,12.847,3.159,19.413
      		c4.397-6.037,4.706-11.548,1.394-17.055C679.305,821.747,677.312,821.433,675.421,820.5z M524.898,817.774
      		c1.934-7.529,0.189-17.162-3.404-19.803C519.756,809.352,520.528,814.252,524.898,817.774z M243.471,787.107
      		c0.258-0.525,0.517-1.052,0.774-1.577c-4.241-1.789-8.282-3.714-8.684-8.884c-0.021-0.273-1.855-0.742-2.677-0.514
      		c-0.714,0.197-1.521,1.171-1.717,1.945c-1.188,4.689,0.208,6.973,5.116,8.123C238.622,786.749,241.072,786.82,243.471,787.107z
      		 M338.455,759.825c-5.061,3.076-6.166,11.863-2.774,15.13c1.967,1.896,3.672,1.217,3.707-1.105
      		C339.456,769.331,338.828,764.804,338.455,759.825z M224.716,705.692c0.417-0.714,0.835-1.429,1.252-2.144
      		c-2.988-2.72-5.827-5.646-9.078-8.003c-0.742-0.538-3.163,0.587-4.298,1.55c-0.485,0.412-0.053,3.138,0.644,3.5
      		C216.944,702.524,220.867,704.037,224.716,705.692z M355.746,837.736c0.1-0.459,0.201-0.918,0.301-1.377
      		c-1.29-1.101-2.413-2.699-3.905-3.198c-3.01-1.009-3.523,1.569-3.909,3.654c-0.914,4.948-1.669,9.926-2.547,15.24
      		c5.133-0.751,4.092-4.781,4.062-7.885C349.709,840.211,349.24,836.32,355.746,837.736z M503.007,790.97
      		c0.077-0.509,0.154-1.019,0.231-1.528c-3.759-3.593-7.516-7.187-11.247-10.754c-2.321,3.176-0.621,6.678,1.91,8.781
      		C496.27,789.438,499.92,789.864,503.007,790.97z M447.699,760.257c0.133,0.589,0.448,1.978,0.763,3.366
      		c1.445-0.587,2.919-1.11,4.319-1.789c0.565-0.274,1.193-0.803,1.398-1.361c0.582-1.584,1.812-3.716,1.228-4.766
      		c-0.987-1.775-3.172-2.886-4.854-4.275c-0.857,1.833-1.787,3.638-2.535,5.515C447.727,757.681,447.842,758.577,447.699,760.257z
      		 M703.298,675.008c-7.821,1.212-9.847,4.833-6.588,10.61c0.421,0.746,1.768,1.429,2.611,1.35c0.86-0.081,2.173-1.014,2.346-1.787
      		C702.382,681.994,702.738,678.724,703.298,675.008z M691.717,1149.781c-6.774,2.572-6.824,2.453-8.042-3.58
      		c-0.378-1.869-1.375-3.684-2.425-5.305c-0.356-0.55-1.793-0.675-2.653-0.525c-0.556,0.097-1.288,0.996-1.385,1.622
      		c-1,6.452,6.377,12.592,12.684,10.549C690.406,1152.376,690.663,1151.428,691.717,1149.781z M487.562,835.182
      		c-12.583-1.694-11.952-4.535-9.457,9.259c0.137,0.755,0.572,1.456,0.549,1.398c4.016,0,7.37,0,10.725,0
      		c0.067-0.528,0.135-1.058,0.202-1.586c-1.427-0.241-3.038-1.023-4.244-0.614c-2.922,0.993-3.987-0.16-4.372-2.778
      		c-0.276-1.89-0.564-3.776-0.849-5.679C482.941,835.182,485.252,835.182,487.562,835.182z M216.674,1047.696
      		c-2.21-3.574-3.646-6.54-5.734-8.938c-0.761-0.872-3.265-0.866-4.595-0.327c-0.637,0.258-0.963,2.956-0.44,4.096
      		C207.625,1046.273,210.611,1048.351,216.674,1047.696z M545.354,650.313c0.574-0.353,1.148-0.704,1.723-1.057
      		c-0.806-4.159-1.397-8.379-2.551-12.439c-0.335-1.179-2.276-1.901-3.482-2.833c-0.354,1.213-1.161,2.501-0.972,3.624
      		c0.49,2.905,1.213,5.814,2.257,8.564C542.914,647.713,544.32,648.943,545.354,650.313z M407.974,775.256
      		c0.935,0.097,1.869,0.193,2.804,0.29c1.034-3.879,2.251-7.724,2.988-11.658c0.208-1.11-0.985-2.483-1.536-3.736
      		c-1.203,0.913-3.293,1.694-3.453,2.765C408.171,766.979,408.188,771.135,407.974,775.256z M510.328,822.35
      		c-0.851,2.166-2.323,5.543-3.468,9.025c-0.425,1.291-0.173,2.841-0.071,4.262c0.234,3.31,2.493,2.269,4.34,1.881
      		c2.027-0.425,3.083-1.159,2.21-3.734C511.905,829.55,511.036,825.126,510.328,822.35z M265.852,883.63
      		c-1.35-1.745-2.81-4.056-3.083-3.923c-2.888,1.402-5.7,3.051-8.225,5.023c-0.408,0.319,0.853,3.767,1.067,3.738
      		c2.175-0.295,4.378-0.812,6.395-1.683C263.282,886.235,264.255,884.981,265.852,883.63z M207.141,891.887
      		c2.711-1.888,4.815-2.938,6.312-4.55c0.905-0.977,1.441-3.961,1.164-4.102c-2.021-1.018-4.265-1.687-6.506-2.078
      		c-0.355-0.062-1.475,1.812-1.505,2.812C206.533,886.365,206.897,888.774,207.141,891.887z M681.958,648.808
      		c0-4.334,0.164-8.124-0.116-11.881c-0.069-0.935-1.493-1.871-2.461-2.581c-0.326-0.239-1.384,0.015-1.735,0.391
      		C675.231,637.321,677.794,646.644,681.958,648.808z M260.43,1057.249c-0.861-5.386-1.079-5.867-4.762-5.582
      		c-1.551,0.12-3.013,1.392-4.516,2.14c1.921,2.667,3.841,5.336,5.763,8.004C258.086,1060.291,259.258,1058.77,260.43,1057.249z
      		 M432.349,756.175c-0.777-0.052-1.555-0.104-2.331-0.155c-0.61,2.96-2.154,6.132-1.546,8.816c0.52,2.294,3.506,4.03,5.401,6.014
      		c0.431-0.306,0.861-0.612,1.291-0.918C434.225,765.347,433.287,760.76,432.349,756.175z M467.288,760.516
      		c0.662,0.047,1.325,0.095,1.987,0.143c0.534-4.629,1.162-9.252,1.51-13.895c0.059-0.785-1.01-1.743-1.734-2.436
      		c-0.177-0.168-1.5,0.333-1.542,0.635C466.756,750.146,464.618,755.368,467.288,760.516z M309.44,877.362
      		c-2.633,4.9-2.039,9.041,1.959,13.632C316.171,885.6,315.657,882.022,309.44,877.362z M207.614,902.005
      		c-5.006-0.845-3.609,3.636-4.329,6.222c-0.166,0.598,1.717,2.544,2.131,2.396c2.211-0.789,5.137-1.579,6.047-3.303
      		C212.076,906.16,212.324,901.661,207.614,902.005z M450.852,724.506c0.881-0.033,1.762-0.066,2.642-0.099
      		c0.377-3.713,1.019-7.429,0.966-11.135c-0.016-1.068-1.975-2.179-3.189-3.072c-0.148-0.109-1.779,1.05-1.742,1.549
      		C449.841,716.011,450.38,720.255,450.852,724.506z M215.371,948.996c2.119,2.331,3.207,3.527,4.294,4.725
      		c2.379-2.19,4.757-4.381,7.136-6.571c-1.413-1.139-3.273-3.5-4.152-3.179C220.268,944.842,218.304,946.851,215.371,948.996z
      		 M283.778,827.612c-2.394-2.487-3.651-4.501-5.456-5.34c-0.794-0.369-3.94,1.753-3.875,2.573c0.223,2.799-0.181,6.902,4.622,6.321
      		C280.331,831.014,281.422,829.453,283.778,827.612z M436.672,842.556c-5.924-1.938-11.045-0.725-11.164,2.121
      		c-0.068,1.663,1.896,3.411,2.946,5.121C430.994,847.56,433.534,845.32,436.672,842.556z M277.931,925.317
      		c-0.321-4.586-2.636-5.116-5.077-4.754c-1.5,0.223-2.854,1.431-4.274,2.196c1.397,2.337,2.794,4.675,4.191,7.011
      		C274.491,928.287,276.211,926.802,277.931,925.317z M283.223,859.48c2.384,3.104,4.179,5.962,6.536,8.234
      		c0.854,0.824,3.062,0.338,4.634,0.212c0.217-0.018,0.595-1.475,0.436-2.157C294.127,862.78,287.817,858.959,283.223,859.48z
      		 M641.284,839.166c-0.55,0.396-1.101,0.791-1.651,1.187c0.424,2.759-0.017,6.353,1.566,7.992c1.119,1.158,4.979-0.331,7.616-0.638
      		c0.248-0.633,0.495-1.266,0.743-1.898C646.8,843.596,644.043,841.381,641.284,839.166z M211.081,991.196
      		c0.646,0.14,1.292,0.28,1.938,0.419c0.671-2.493,1.576-4.956,1.912-7.495c0.157-1.185-0.484-3.29-1.3-3.609
      		c-1.389-0.543-3.337-0.332-4.757,0.264c-0.473,0.199-0.289,2.608,0.029,3.898C209.449,986.892,210.337,989.026,211.081,991.196z
      		 M281.73,1130.816c-2.409-0.307-3.311,0.785-2.127,3.723c0.798,1.98,0.82,4.379,1.954,6.08c0.761,1.145,2.859,1.399,4.359,2.053
      		c-0.123-1.208-0.228-2.418-0.371-3.623c-0.179-1.513-1.035-3.348-0.467-4.472C286.842,1131.082,284.738,1130.995,281.73,1130.816z
      		 M212.829,768.94c2.491,3.403-0.511,8.111,4.599,8.88c3.085,0.464,4.973-1.915,4.496-5.072
      		C221.158,767.686,217.094,769.848,212.829,768.94z M299.813,882.213c-2.45-2.065-3.686-3.974-4.881-3.948
      		c-3.181,0.066-5.786,1.826-5.782,5.371c0.001,1.167,2.134,3.459,2.45,3.32C294.069,885.87,296.334,884.317,299.813,882.213z
      		 M320.013,768.058c0-1.189,0.202-2.232-0.054-3.148c-0.283-1.01-0.986-1.902-1.506-2.846c-0.941,0.652-2.508,1.152-2.709,1.983
      		c-0.692,2.856-1,5.824-1.199,8.767c-0.029,0.435,1.308,1.353,2.041,1.384C321.953,774.424,319.163,770.159,320.013,768.058z
      		 M317.217,837.233c1.851-0.675,4.647-0.956,4.905-1.918c0.992-3.707,1.173-7.632,1.728-12.039
      		C318.868,826.82,315.516,830.588,317.217,837.233z M634.77,1057.11c0,2.839-0.345,6.846,0.161,10.744
      		c0.217,1.671,2.03,3.31,3.469,4.559c0.571,0.496,2.095-0.104,3.185-0.205c-0.449-1.868-0.582-3.893-1.414-5.572
      		C638.452,1063.164,636.363,1059.877,634.77,1057.11z M518.183,771.626c3.604,3.54-0.051,11.029,7.079,12.729
      		C527.639,777.542,524.546,773.701,518.183,771.626z M506.142,701.033c-0.203-3.604-0.285-6.469-0.562-9.315
      		c-0.125-1.284-0.673-3.625-0.967-3.615c-1.75,0.059-3.644,0.368-5.165,1.193c-0.945,0.514-1.317,2.083-2.463,4.082
      		C504.796,690.714,501.493,700.944,506.142,701.033z M199.609,884.813c-1.641-1.124-2.828-2.449-4.228-2.738
      		c-1.174-0.243-2.598,0.724-3.911,1.154c1.819,2.773-0.319,3.087-2.208,3.756c-0.798,0.283-1.89,1.064-1.96,1.715
      		c-0.078,0.715,0.716,1.956,1.407,2.208c0.818,0.299,2.059,0.02,2.892-0.428c1.862-0.999,3.606-2.229,5.356-3.427
      		C197.727,886.527,198.394,885.851,199.609,884.813z M293.067,855.823c1.611,0.967,3.664,3.091,4.749,2.677
      		c2.965-1.129,4.717-0.025,6.616,1.756c0.291,0.273,0.862,0.246,1.683,0.454c0.397-1.419,0.755-2.697,1.07-3.819
      		c-2.06-0.34-4.701-0.155-5.018-0.928C300.175,851.118,297.137,853.142,293.067,855.823z M657.011,843.98
      		c-0.875-9.749-5.197-13.311-11.481-10.193C649.182,837.029,652.684,840.139,657.011,843.98z M609.647,899.723
      		c-3.735-2.035-6.552-3.736-9.52-5.107c-1.245-0.575-2.813-0.451-4.234-0.645c0.405,1.619,0.285,3.824,1.314,4.749
      		C600.598,901.769,604.633,901.081,609.647,899.723z M319.635,806.699c-1.59-2.172-2.725-3.723-3.859-5.273
      		c-1.004,1.572-2.883,3.185-2.812,4.706c0.111,2.419,1.523,4.777,2.385,7.161C316.686,811.236,318.023,809.18,319.635,806.699z
      		 M479.219,817.739c2.775-2.46,6.659,1.772,7.46-3.338c0.308-1.958-1.105-6.01-1.54-5.982
      		C480.367,808.717,480.172,812.65,479.219,817.739z M263.502,1065.235c0.401,3.972,0.549,6.889,1.219,9.68
      		c0.063,0.263,4.758,0.214,5.012-0.502C271.155,1070.399,269.399,1067.475,263.502,1065.235z M276.341,1118.815
      		c2.141,2.48,3.597,4.167,5.053,5.852c1.057-2.467,2.494-4.864,2.966-7.44c0.167-0.91-2.016-2.254-3.125-3.4
      		C279.837,1115.252,278.439,1116.677,276.341,1118.815z M350.497,664.572c0.598-0.175,1.196-0.349,1.793-0.523
      		c0.21-2.271,0.411-4.543,0.631-6.813c0.284-2.935-1.109-3.743-3.879-3.461c-3.072,0.312-3.156,2.196-2.293,4.21
      		C347.736,660.292,349.225,662.386,350.497,664.572z M226.951,1008.034c-1.932,2.037-4.523,4.007-4.237,4.608
      		c0.933,1.963,2.916,3.425,4.496,5.08c1.249-1.502,3.395-2.953,3.483-4.52C230.774,1011.77,228.631,1010.209,226.951,1008.034z
      		 M536.367,743.366c-0.657-0.048-1.313-0.096-1.97-0.144c-0.763,3.972-1.638,7.927-2.185,11.928
      		c-0.099,0.725,1.002,1.917,1.833,2.349c0.482,0.25,2.204-0.561,2.218-0.937C536.424,752.168,536.367,747.767,536.367,743.366z
      		 M287.695,787.151c1.363,4.718,4.69,5.188,7.947,5.442c1.034,0.081,2.143-0.806,3.218-1.25c-0.876-1.397-1.522-3.714-2.676-4.003
      		C293.714,786.722,290.982,787.151,287.695,787.151z M296.94,1147.624c-1.206,3.113-7.325,3.8-3.421,8.637
      		c0.612,0.761,3.177,1.042,3.895,0.433C300.457,1154.107,301.39,1151.079,296.94,1147.624z M405.497,795.191
      		c-3.874-8.908-4.866-10.023-9.328-10.492C397.514,792.122,400.76,795.773,405.497,795.191z M616.451,916.564
      		c-0.622-0.067-1.243-0.135-1.865-0.203c-0.512,3.681-1.181,7.35-1.438,11.049c-0.072,1.042,1.066,2.168,1.649,3.256
      		c0.958-1.141,2.725-2.281,2.725-3.423C617.524,923.683,616.858,920.124,616.451,916.564z M331.23,693.479
      		c-3.981-1.161-7.249-0.82-8.418,2.655c-0.635,1.887,0.79,4.467,1.285,6.734c0.568,0.003,1.137,0.007,1.706,0.01
      		C327.557,699.842,329.31,696.805,331.23,693.479z M238.284,770.794c0.488-0.32,0.976-0.642,1.464-0.962
      		c-1.6-2.612-3.199-5.224-4.798-7.836c-1.288,0.918-3.619,1.767-3.687,2.768C230.928,769.747,234.995,769.914,238.284,770.794z
      		 M214.167,719.044c0,3.035-0.197,5.672,0.109,8.25c0.104,0.874,1.375,1.789,2.321,2.303c0.435,0.237,1.646-0.236,2.036-0.746
      		C221.533,725.062,219.804,720.52,214.167,719.044z M340.382,794.223c8.614-3.729,10.518-8.965,5.916-13.216
      		C344.493,785.038,342.704,789.038,340.382,794.223z M399.352,847.08c0.933-0.037,1.866-0.075,2.799-0.112
      		c0.202-3.165,0.655-6.348,0.45-9.487c-0.061-0.922-1.962-1.76-3.107-2.502c-0.085-0.055-1.31,1.016-1.273,1.506
      		C398.491,840.025,398.951,843.551,399.352,847.08z M302.053,708.017c-1.817-2.966-3.147-5.137-4.477-7.308
      		c-1.065,0.829-3.17,2.034-3.029,2.423c0.896,2.464-1.465,6.977,3.769,6.876C299.274,709.99,300.214,709.033,302.053,708.017z
      		 M172.274,774.573c0.698-4.806-0.765-6.813-4.004-6.49c-0.994,0.099-2.35,2.229-2.542,3.572c-0.446,3.127,1.712,4.473,4.51,4.309
      		C171.263,775.903,172.216,774.617,172.274,774.573z M205.39,773.162c-1.752-2.769-2.769-4.376-3.787-5.983
      		c-1.469,1.949-3.124,3.802-4.23,5.939c-0.19,0.368,1.966,2.778,3.008,2.758C201.828,775.847,203.246,774.41,205.39,773.162z
      		 M629.729,1116.618c1.92-1.4,2.979-2.174,4.037-2.947c-2.151-1.585-4.188-3.396-6.542-4.583c-0.532-0.268-2.16,1.64-3.284,2.545
      		C625.788,1113.226,627.638,1114.817,629.729,1116.618z M497.893,618.46c-2.258,2.003-4.765,3.67-4.559,4.136
      		c1.34,3.031,3.27,5.801,5.006,8.657c0.581-0.29,1.163-0.579,1.744-0.868C499.446,626.914,498.808,623.441,497.893,618.46z
      		 M259.615,736.379c-3.324,4.442-0.094,7.203,2.063,9.935c0.593,0.752,2.682,0.323,4.084,0.436
      		c-0.216-1.208-0.089-2.647-0.718-3.577C263.48,740.86,261.572,738.78,259.615,736.379z M628.201,885.511
      		c0.249,0.251,0.498,0.501,0.746,0.752c2.113-4.443,4.228-8.889,6.433-13.524c-5.88-1.095-5.943-1.024-6.133,1.792
      		C629.002,878.197,628.56,881.852,628.201,885.511z M542.031,713c0,1.64-0.278,2.998,0.061,4.177
      		c0.642,2.233,1.639,4.363,2.486,6.536c0.843-0.573,2.463-1.258,2.399-1.702c-0.388-2.722-1.034-5.431-1.904-8.042
      		C544.902,713.458,543.376,713.398,542.031,713z M337.249,883.422c-2.098-1.775-3.355-3.399-4.97-3.93
      		c-0.644-0.212-3.191,2.484-2.959,3.011c0.856,1.936,2.515,3.518,3.871,5.232C334.288,886.57,335.385,885.404,337.249,883.422z
      		 M360.086,642.33c-1.528-1.669-2.688-2.933-3.848-4.199c-1.057,1.396-3.121,2.941-2.942,4.156c0.285,1.947,2.057,3.675,3.2,5.495
      		C357.548,646.184,358.601,644.585,360.086,642.33z M663.525,610.435c-2.541-2.769-4.47-4.873-6.399-6.976
      		c-0.688,0.911-2.15,2.319-1.936,2.654c1.514,2.364,3.36,4.519,5.203,6.651C660.435,612.812,661.699,611.799,663.525,610.435z
      		 M225.961,1002.886c0.635,0.115,1.27,0.231,1.904,0.347c0.968-2.617,2.557-5.22,2.614-7.857c0.025-1.135-3.124-2.339-6.082-4.37
      		C225.072,996.126,225.517,999.506,225.961,1002.886z M714.631,782.437c0.514,0.198,1.027,0.397,1.542,0.596
      		c1.152-1.881,3.271-3.806,3.189-5.632c-0.085-1.884-2.312-3.672-3.604-5.5c-0.528,0.306-1.057,0.611-1.586,0.917
      		C714.325,776.023,714.479,779.23,714.631,782.437z M512.066,773.857c-0.637,0.229-1.273,0.456-1.91,0.685
      		c0,2.642-0.188,5.305,0.105,7.912c0.097,0.863,1.523,2.293,2.176,2.206c0.923-0.122,2.52-1.597,2.391-2.166
      		C514.16,779.559,513.033,776.726,512.066,773.857z M287.453,1104.19c-1.36,2.903-2.468,4.292-2.552,5.742
      		c-0.073,1.253,1.017,2.573,1.593,3.863c1.734-1.496,3.613-2.876,5.036-4.627C291.725,1108.929,289.657,1106.849,287.453,1104.19z
      		 M186.169,779.36c3.305-9.777,3.305-9.777-4.728-8.849C183.119,773.652,184.644,776.506,186.169,779.36z M693.839,615.022
      		c-3.151-2.896-5.749-5.283-8.876-8.154C684.064,614.363,686.264,616.381,693.839,615.022z M337.579,827.416
      		c0.231-0.362,0.462-0.725,0.692-1.087c-0.913-2.054-1.737-4.155-2.787-6.135c-0.376-0.709-1.412-1.669-1.942-1.556
      		c-0.813,0.174-1.96,1.109-2.055,1.84c-0.197,1.518-0.373,3.755,0.491,4.53C333.411,826.295,335.669,826.662,337.579,827.416z
      		 M223.266,800.549c-1.564-2.144-3.129-4.287-4.693-6.431c-0.844,1.476-2.391,2.946-2.401,4.426
      		C216.139,803.394,219.75,801.249,223.266,800.549z M670.159,650.257c0.637-0.192,1.273-0.386,1.91-0.579
      		c-0.366-2.852-0.496-5.77-1.244-8.518c-0.226-0.827-2.175-1.185-3.333-1.756c-0.259,1.041-0.982,2.242-0.689,3.094
      		C667.715,645.155,669.012,647.681,670.159,650.257z M514.669,707.375c-1.296,4.505-1.295,8.646,3.414,12.09
      		C519.501,714.284,518.062,710.576,514.669,707.375z M720.373,616.137c2.012-4.156,0.817-6.769-2.567-8.208
      		c-0.854-0.362-3.223,0.544-3.384,1.224C713.328,613.788,717.573,614.088,720.373,616.137z M419.079,789.822
      		c0.698-0.123,1.396-0.246,2.095-0.369c0-3.225,0.169-6.465-0.129-9.661c-0.067-0.726-1.697-1.652-2.714-1.813
      		c-0.619-0.098-2.156,1.255-2.059,1.681C417.043,783.084,418.105,786.443,419.079,789.822z M295.615,829.888
      		c-2.071,1.485-3.764,2.699-5.458,3.914c1.417,0.798,3.006,2.448,4.211,2.204c2.054-0.417,3.866-2.023,5.78-3.131
      		C298.854,832.022,297.56,831.169,295.615,829.888z M644.043,1087.962c0.497-0.855,0.994-1.711,1.49-2.566
      		c-1.742-0.782-3.492-2.157-5.228-2.139c-1.159,0.012-3.446,2.434-3.225,3.029c0.608,1.633,2.104,3.153,3.633,4.091
      		C641.162,1090.651,642.897,1088.825,644.043,1087.962z M359.717,785.832c2.466-2.161,4.331-3.797,6.197-5.432
      		c-1.083-0.815-2.646-2.554-3.164-2.284c-2.017,1.052-3.754,2.684-5.407,4.294C357.213,782.536,358.544,784.161,359.717,785.832z
      		 M268.215,714.1c0.728,1.508,1.015,3.292,1.885,3.648c1.652,0.675,3.779,1.019,5.43,0.54c0.929-0.27,1.302-2.457,1.926-3.779
      		c-1.512-0.399-3.009-1.036-4.539-1.128C271.499,713.296,270.045,713.793,268.215,714.1z M266.385,1084.58
      		c-0.551-4.082-2.286-6.737-6.685-6.135c-0.6,0.082-1.593,1.702-1.416,2.332C259.402,1084.751,262.357,1085.48,266.385,1084.58z
      		 M168.816,810.567c3.797-0.499,6.913-1.268,5.95-5.688c-0.164-0.753-1.816-1.772-2.686-1.688
      		C166.539,803.731,170.225,808.026,168.816,810.567z M214.01,945.536c-0.405-4.69-0.734-8.515-1.093-12.667
      		C208.583,935.624,208.914,942.3,214.01,945.536z M651.083,637.123c-1.703,0-2.877-0.35-3.592,0.074
      		c-1.426,0.845-3.031,1.898-3.72,3.285c-0.438,0.883,0.639,2.519,1.037,3.816c1.505-1.037,3.202-1.887,4.45-3.173
      		C650.131,640.226,650.388,638.731,651.083,637.123z M615.919,1071.12c0.713-0.171,1.425-0.341,2.138-0.511
      		c-0.223-2.83-0.446-5.661-0.669-8.491c-1.362,0.359-2.724,0.719-4.084,1.078C614.176,1065.838,615.047,1068.479,615.919,1071.12z
      		 M428.772,819.775c0.586-0.039,1.172-0.079,1.759-0.119c0.535-1.349,1.304-2.657,1.546-4.057c0.274-1.59,0.077-3.261,0.086-4.896
      		c-1.441,1.274-3.489,2.299-4.14,3.896C427.455,815.99,428.461,818.025,428.772,819.775z M257.584,667.506
      		c0.468-0.123,0.935-0.247,1.403-0.37c0-2.43,0.188-4.883-0.112-7.275c-0.093-0.742-1.512-1.318-2.325-1.97
      		c-0.763,1.064-2.359,2.372-2.132,3.145C255.085,663.305,256.477,665.365,257.584,667.506z M175.067,750.157
      		c-0.468-0.307-0.935-0.613-1.403-0.919c-2.434,0.724-6.37-0.566-5.854,4.164c0.107,0.987,2.368,1.739,3.637,2.599
      		C172.655,754.053,173.861,752.104,175.067,750.157z M315.454,907.871c2.047-2.579,3.875-4.779,5.487-7.129
      		c0.113-0.165-1.366-1.425-2.105-2.176c-1.882,2.238-3.802,4.447-5.554,6.782C313.171,905.496,314.364,906.622,315.454,907.871z
      		 M668.918,1117.604c-0.031-0.703-0.061-1.405-0.092-2.108c-1.242-0.652-2.665-2.03-3.683-1.777c-1.324,0.329-3.266,1.99-3.226,3.01
      		c0.048,1.228,1.812,3.22,2.994,3.343C666.148,1120.2,667.575,1118.503,668.918,1117.604z M622.047,641.331
      		c1.424-1.069,2.799-1.59,2.864-2.244c0.201-2.008,0.022-4.086-0.326-6.083c-0.058-0.327-2.501-0.607-2.53-0.513
      		c-0.474,1.574-0.915,3.204-0.99,4.838C621.015,638.396,621.565,639.488,622.047,641.331z M566.545,875.82
      		c-3.407,6.076-2.806,7.078,4.702,8.393C569.308,880.752,567.926,878.286,566.545,875.82z M370.959,786.42
      		c-0.552,0.079-1.103,0.158-1.654,0.237c-0.474,1.09-1.645,2.724-1.296,3.17c1.219,1.558,2.896,2.806,4.572,3.904
      		c0.274,0.181,2.127-1.378,2.076-1.469C373.524,790.258,372.225,788.349,370.959,786.42z M278.625,879.517
      		c-0.806,0.73-1.611,1.461-2.416,2.191c1.826,1.995,3.652,3.99,5.478,5.986c0.829-0.889,2.457-2.46,2.368-2.563
      		C282.366,883.156,280.475,881.354,278.625,879.517z M548.971,799.88c-0.497-0.129-0.994-0.259-1.491-0.389
      		c-3.034,2.597-0.959,6.445-1.517,9.631c-0.132,0.757,0.897,1.717,1.394,2.583c0.538-0.818,1.489-1.611,1.54-2.459
      		C549.083,806.134,548.971,803.004,548.971,799.88z M454.445,667.68c1.272,2.016,2.076,3.289,2.879,4.562
      		c1.418-1.269,2.836-2.537,4.253-3.804c-1.018-1.105-1.877-2.788-3.106-3.123C457.571,665.069,456.188,666.588,454.445,667.68z
      		 M584.527,877.667c2.295-9.164,2.295-9.164-4.123-8.25C581.858,872.326,583.193,874.996,584.527,877.667z M429.903,664.943
      		c-1.15,3.416,0.463,5.897,4.035,7.219c0.846,0.313,1.966-0.115,2.96-0.203c-0.457-1.179-0.597-2.72-1.439-3.463
      		C433.88,667.104,431.896,666.174,429.903,664.943z M360.669,841.971c0.915,3.706,1.688,6.836,2.575,10.43
      		C367.688,847.121,367.314,845.612,360.669,841.971z M579.261,892.891c0.122-0.745,0.244-1.489,0.366-2.234
      		c-2.196-0.795-4.393-1.592-6.589-2.388c-0.283,1.541-0.567,3.081-0.852,4.622C574.545,892.891,576.902,892.891,579.261,892.891z
      		 M609.447,1002.044c-2.84-1.646-4.252-2.743-5.85-3.213c-0.443-0.131-2.221,2.1-2.038,2.385c0.828,1.292,1.982,2.63,3.339,3.22
      		C605.568,1004.728,606.927,1003.433,609.447,1002.044z M685.227,655.021c4.801,0.344,6.274-1.36,5.351-4.616
      		c-0.271-0.958-1.839-1.548-2.812-2.307C686.979,650.239,686.195,652.38,685.227,655.021z M237.036,937.232
      		c-0.53-0.652-1.061-1.305-1.592-1.957c-1.427,0.977-3.493,1.674-4.079,3.017c-0.469,1.076,0.861,2.937,1.392,4.448
      		C234.183,940.904,235.609,939.067,237.036,937.232z M288.261,729.065c0.897-0.169,1.793-0.338,2.69-0.507
      		c-0.435-2.411-0.871-4.823-1.305-7.235c-0.901,0.438-2.601,0.941-2.572,1.306C287.248,724.792,287.821,726.924,288.261,729.065z
      		 M430.877,741.303c-1.263,1.981-2.596,3.151-2.424,4.025c0.251,1.28,1.581,2.35,2.451,3.508c0.952-1.227,2.307-2.341,2.693-3.726
      		C433.796,744.389,432.291,743.191,430.877,741.303z M287.973,763.975c0.314,2.841-0.786,6.104,3.476,6.512
      		c0.49,0.047,1.517-1.335,1.618-2.139C293.467,765.156,292.293,763.21,287.973,763.975z M455.878,831.01
      		c-5.207,0.188-5.997,2.819-6.119,5.69c-0.019,0.416,2.819,1.391,2.863,1.309C453.725,835.975,454.629,833.833,455.878,831.01z
      		 M153.417,792.302c1.684,2.555,2.816,4.272,3.949,5.989c0.882-1.078,2.398-2.084,2.531-3.248
      		C160.331,791.251,157.539,792.032,153.417,792.302z M249.234,1044.091c-2.74-1.691-3.993-2.72-5.428-3.188
      		c-0.399-0.13-2.062,1.658-1.897,2.121c0.535,1.501,1.5,2.954,2.665,4.034C244.808,1047.276,246.639,1045.773,249.234,1044.091z
      		 M623.312,1045.142c3.032-10.33,3.032-10.33-2.435-10.516C621.638,1037.907,622.376,1041.096,623.312,1045.142z M546.65,653.948
      		c-0.96,0.358-1.919,0.717-2.878,1.075c1.008,2.312,2.018,4.625,3.027,6.938c0.649-0.342,1.899-0.827,1.857-1.005
      		C548.094,658.595,547.35,656.277,546.65,653.948z M247.708,886.737c0.522-0.938,1.045-1.875,1.568-2.812
      		c-1.917-0.935-3.81-1.923-5.786-2.703c-0.175-0.069-0.89,1.229-1.354,1.892C243.994,884.321,245.852,885.529,247.708,886.737z
      		 M373.823,842.24c-1.292,2.251-2.308,3.33-2.417,4.495c-0.058,0.61,1.747,2.1,2.236,1.917c1.187-0.441,2.428-1.472,2.959-2.591
      		C376.831,845.575,375.364,844.285,373.823,842.24z M418.558,813.99c0.409-0.3,0.817-0.6,1.226-0.899
      		c-0.015-3.732-0.556-7.027-7.086-6.727C415.069,809.45,416.814,811.721,418.558,813.99z M737.111,769.089
      		c-0.768,0.465-1.535,0.931-2.303,1.396c-0.851,1.011-1.701,2.022-2.552,3.033c1.466,0.822,2.863,1.887,4.436,2.322
      		c0.476,0.132,2.093-1.533,2.032-2.282C738.604,772.035,737.695,770.575,737.111,769.089z M619.027,987.432
      		c-1.312-1.18-2.109-1.895-2.906-2.61c-0.963,1.301-2.423,2.506-2.702,3.939c-0.168,0.867,1.497,2.09,2.34,3.152
      		C616.715,990.603,617.671,989.291,619.027,987.432z M257.498,766.768c-2.041-1.386-3.122-2.683-4.155-2.646
      		c-0.827,0.03-2.351,1.814-2.214,2.58c0.204,1.146,1.431,2.467,2.548,2.936C254.219,769.864,255.502,768.325,257.498,766.768z
      		 M344.547,809.494c1.561-2.619,2.71-3.933,3.016-5.419c0.097-0.47-2.089-1.987-3.175-1.934c-0.807,0.041-2.036,1.795-2.139,2.874
      		C342.152,806.033,343.282,807.168,344.547,809.494z M265.458,902.878c-0.718-0.469-1.435-0.937-2.153-1.404
      		c-1.2,1.616-2.4,3.232-3.601,4.849c1.098,0.436,2.948,1.478,3.168,1.205C263.97,906.168,264.639,904.462,265.458,902.878z
      		 M614.084,890.052c-2.129-1.604-3.224-3.021-4.486-3.19c-1.008-0.136-2.22,1.25-3.34,1.96c1.358,0.886,2.637,1.979,4.116,2.558
      		C611.016,891.63,612.074,890.815,614.084,890.052z M329.002,853.021c-3.81,0-6.252,0-9.394,0c1.148,1.994,1.927,3.348,2.917,5.065
      		C324.523,856.523,326.181,855.227,329.002,853.021z M536.594,812.259c1.734-7.403,1.734-7.403-3.846-6.621
      		C534.119,807.998,535.356,810.128,536.594,812.259z M634.205,939.524c-2.014-2.027-3.288-3.31-4.562-4.594
      		c-0.992,1.301-1.985,2.601-3.508,4.594C629.103,939.524,630.972,939.524,634.205,939.524z M646.021,1126.167
      		c-0.468-0.579-0.935-1.159-1.402-1.738c-1.554,0.697-3.107,1.396-4.661,2.093c0.889,1.041,1.777,2.082,2.666,3.123
      		C643.756,1128.485,644.889,1127.326,646.021,1126.167z M629.314,1060.434c-2.368,0.313-3.76,0.497-5.911,0.781
      		c1.302,2.319,2.318,4.13,3.814,6.794C628.056,1064.981,628.569,1063.126,629.314,1060.434z M213.205,1024.781
      		c0.628,2.751,1.038,4.544,1.645,7.198c1.765-2.284,2.954-3.822,4.426-5.728C217.025,1025.707,215.504,1025.338,213.205,1024.781z"
      		/>
      </Link>
      	<path onClick={() => tryNextLevel()} className="clickable nextLevel" fillRule="evenodd" clipRule="evenodd" fill="#000" d="M656.359,327.182c2.077-6.253-2.145-10.894-5.109-16.624
      		c2.417-0.251,4.142-0.529,5.875-0.586c2.829-0.094,5.865,0.618,8.455-0.179c7.513-2.312,11.897,1.947,16.185,6.803
      		c3.523,3.991,7.253,7.833,10.423,12.09c1.488,2,2.323,4.757,2.678,7.281c1.193,8.475,6.255,14.009,12.902,18.494
      		c0.438-0.214,0.878-0.429,1.316-0.643c-1.307-2.58-2.582-5.178-3.933-7.735c-0.94-1.781-3.22-4.557-2.773-5.084
      		c2.455-2.896,5.755-7.563,8.419-7.347c9.253,0.751,14.932-4.344,20.379-10.093c1.193-1.26,0.951-4.388,0.52-6.48
      		c-0.236-1.145-2.222-2.671-3.471-2.721c-3.236-0.13-4.729-1.613-5.695-4.511c-1.091-3.266-2.619-6.386-4.353-10.509
      		c2.062-1.408,4.796-3.115,7.33-5.08c1.086-0.84,2.694-2.609,2.419-3.263c-0.537-1.274-2.151-2.78-3.463-2.929
      		c-7.944-0.903-8.427-1.229-7.371-9.32c0.338-2.585,1.347-5.125,2.323-7.577c3.646-9.153,7.015-18.318,6.537-28.456
      		c-0.075-1.596,1.396-3.265,2.152-4.9c1.177,1.243,2.673,2.31,3.47,3.761c2.208,4.017,4.086,8.214,6.171,12.301
      		c1.358,2.665,3.27,5.121,4.202,7.916c2.373,7.105,5.027,13.845,10.733,19.185c1.636,1.532,2.143,4.803,2.243,7.314
      		c0.139,3.453-0.602,6.94-0.966,10.413c-0.289,2.753,0.132,5.86-3.895,6.447c-3.585,0.523-7.28,2.022-9.27-2.887
      		c-0.37-0.912-2.017-1.306-3.072-1.939c-0.429,1.021-1.199,2.031-1.228,3.063c-0.131,4.856-0.059,9.718-0.059,14.531
      		c1.249,0,1.442,0.056,1.579-0.008c10.584-4.93,12.394-3.803,13.107,8.154c0.156,2.627,0.347,5.252,0.622,9.386
      		c2.877-3.522,4.894-6.004,6.925-8.473c2.962-3.6,6.044-3.209,7.801,1.041c1.508,3.646,2.867,7.246-1.704,9.999
      		c-0.768,0.462-0.844,2.074-1.24,3.153c1.68,0.187,3.42,0.71,5.014,0.424c0.845-0.152,1.402-1.74,2.146-2.632
      		c4.599-5.514,11.196-6.771,17.169-2.932c1.136,0.73,1.668,2.73,2.033,4.251c0.58,2.414,0.98,4.909,1.102,7.387
      		c0.168,3.444-0.5,7.085,4.649,7.598c0.985,0.098,2.189,1.658,2.638,2.8c1.162,2.963,3.149,4.063,6.183,3.614
      		c3.661-0.542,4.512,1.387,4.52,4.64c0.004,1.462,1.122,2.921,1.735,4.382c1.009-1.176,2.264-2.221,2.977-3.555
      		c1.527-2.861,5.979-3.829,8.55-1.812c0.952,0.748,2.23,1.142,3.417,1.508c0.772,0.239,1.961-0.197,2.434,0.24
      		c4.271,3.957,9.059,3.256,14.011,1.878c0.521-0.145,1.494,0.145,1.789,0.562c3.873,5.48,9.86,9.749,10.405,17.121
      		c0.092,1.233-1.511,3.567-2.608,3.753c-6.324,1.071-12.722,1.755-19.117,2.341c-2.464,0.226-4.995-0.321-7.472-0.153
      		c-0.761,0.052-1.438,1.307-2.154,2.011c0.751,0.44,1.483,1.229,2.255,1.268c6.809,0.344,13.629,0.828,20.437,0.713
      		c4.808-0.081,5.153,2.307,4.31,5.902c-1.195,5.103-5.995,9.579-11.273,10.325c-4.767,0.674-9.562,1.134-14.346,1.688
      		c-0.064,0.465-0.127,0.93-0.191,1.395c3.863,0.377,7.783,1.344,11.576,0.983c4.752-0.452,6.565,1.634,6.486,5.745
      		c-0.062,3.228-0.508,6.561-1.511,9.607c-0.426,1.292-2.782,2.804-4.131,2.688c-1.263-0.108-2.493-2.134-3.469-3.507
      		c-0.63-0.886-0.471-2.523-1.22-3.084c-0.842-0.632-2.421-0.792-3.46-0.463c-0.657,0.208-1.525,1.993-1.249,2.542
      		c1.855,3.69,0.947,5.588-3.21,5.989c-3.896,0.375-6.3-0.696-5.59-3.681c-0.956,0.631-2.429,2.237-4.176,2.633
      		c-4.857,1.1-9.804,1.93-14.759,2.427c-5.535,0.557-11.004,0.187-15.566-3.703c-0.496-0.423-1.118-0.855-1.735-0.953
      		c-2.375-0.375-4.77-0.616-7.158-0.906c1.621,1.492,3.053,3.303,4.905,4.406c3.163,1.881,3.276,3.315,0.646,5.912
      		c-2.299,2.27-3.896,5.243-6.163,7.552c-2.133,2.172-4.697,3.935-7.182,5.727c-0.332,0.239-1.892-0.418-1.89-0.651
      		c0.017-1.766,0.04-3.6,0.532-5.275c0.55-1.868,1.596-3.591,2.784-6.148c-8.046,2.848-9.656,5.37-8.624,12.125
      		c0.798,5.221-0.672,7.926-5.764,9.055c-1.272,0.282-3.273-1.347-4.453-2.568c-0.625-0.646-0.376-2.33-0.194-3.494
      		c0.11-0.708,1.239-1.325,1.228-1.972c-0.04-2.262-0.304-4.533-0.727-6.754c-0.038-0.202-2.647-0.239-2.685-0.065
      		c-0.41,1.963-0.588,3.979-0.741,5.988c-0.049,0.65,0.062,1.35,0.264,1.974c1.499,4.629,0.241,7.255-4.891,8.289
      		c-2.326,0.468-4.319,2.588-7.251,4.463c2.564,1.401,4.183,2.128,5.622,3.116c1.488,1.019,3.528,2.024,4.067,3.497
      		c2.161,5.902,4.686,11.871,5.488,18.022c1.246,9.544,1.082,19.266,1.771,28.895c0.181,2.538,1.049,5.115,2.049,7.483
      		c1.303,3.086-0.336,6.132-3.479,6.289c-5.579,0.276-11.206,0.443-16.703,1.33c-5.934,0.958-11.533,1.177-16.912-2.032
      		c-0.982-0.586-2.529-0.832-3.617-0.526c-3.879,1.089-7.604,3.301-11.509,3.622c-18.559,1.521-37.162,2.513-55.742,3.8
      		c-5.029,0.349-7.054-1.924-6.746-6.734c0.021-0.33-0.032-0.665-0.046-0.998c-0.41-10.047-0.409-10.047,9.136-11.388
      		c-0.162-0.624-0.18-1.646-0.491-1.745c-6.569-2.085-7.107-7.941-7.783-13.222c-2.479-19.389-2.538-38.812-0.163-58.25
      		c0.412-3.376,2.446-2.787,4.516-3.12c1.271-0.205,2.455-0.958,3.68-1.465c-1.037-0.612-2.038-1.668-3.115-1.753
      		c-3.133-0.25-3.159-2.091-2.96-4.483c1.135-13.662,6.833-25.87,11.569-38.473c3.334-8.868,8.888-15.5,15.275-22.079
      		c2.871-2.958,4.02-7.678-0.041-11.607c-8.996-8.708-14.981-19.395-20.236-30.622c-1.619-3.458-2.357-6.386-0.309-9.844
      		c0.506-0.855-0.156-2.402-0.281-3.631c-5.938,0.731-6.963-4.575-9.586-7.874c-0.707-0.89-1.351-2.021-1.51-3.118
      		c-0.27-1.853-0.76-5.078-0.049-5.419c2.594-1.247,5.951-3.662,8.349,0.226c2.239,3.633,4.026,7.552,5.926,11.388
      		c1.294,2.612,2.438,5.296,3.65,7.949C655.171,327.727,655.766,327.454,656.359,327.182z M747.341,390.492
      		c-0.62,2.575-0.753,5.147-1.842,7.215c-2.241,4.25-5.219,8.11-7.469,12.355c-1.935,3.646-3.279,7.604-5.163,12.085
      		c9.308-1.073,9.279-1.093,14.014-7.71c6.562-9.167,13.168-18.302,19.765-27.444c0.281-0.389,0.66-0.996,1.008-1.006
      		c6.759-0.2,6.563-5.976,8.142-10.232c0.467-1.26,0.257-2.773,0.356-4.17c-1.439,0.124-2.919,0.085-4.308,0.421
      		c-1.098,0.266-2.379,0.739-3.071,1.552c-5.573,6.544-13.722,6.89-21.15,8.917c-3.797,1.035-9.067-4.362-6.897-7.402
      		c3.704-5.188,8.44-9.736,13.262-13.963c1.332-1.169,4.494-0.262,6.817-0.288c1.562-0.018,3.123-0.003,4.807-0.003
      		c-1.632-7.319-5.12-9.237-12.577-7.45c-9.855,2.362-20.697,14.149-22.217,24.154c-1.325,8.724,0.498,12.006,8.109,13.498
      		C741.232,391.474,743.769,390.758,747.341,390.492z M715.422,484.5c0.352-0.12,0.703-0.241,1.056-0.362
      		c-0.201-2.312-0.049-4.736-0.738-6.891c-0.397-1.243-2.155-2.051-3.302-3.055c-0.433,1.244-1.246,2.497-1.216,3.728
      		c0.054,2.137,0.537,4.28,1.007,6.383c0.698,3.123-0.938,4.801-3.633,4.41c-4.322-0.627-7.175,1.367-10.188,3.875
      		c-1.2,0.999-3.929,1.982-4.617,1.396c-1.416-1.206-2.417-3.403-2.698-5.323c-0.503-3.431-0.122-6.989-0.575-10.433
      		c-0.186-1.412-1.565-3.766-2.426-3.777c-2.357-0.029-4.38,1.065-3.875,4.316c0.56,3.606,0.588,7.293,1.108,10.907
      		c1.198,8.317,4.062,10.254,12.434,8.214c2.354-0.573,4.454-2.122,6.77-2.95c1.239-0.442,2.995-0.869,3.99-0.33
      		c5.485,2.967,11.037,4.327,17.194,2.355c1.17-0.374,2.685,0.428,3.924,0.156c3.55-0.779,8.29-0.788,10.197-3.073
      		c1.834-2.197,1.276-6.951,0.717-10.412c-0.467-2.881-2.565-3.414-5.003-0.89c-2.523,2.613-5.204,5.306-8.332,7.027
      		c-2.54,1.397-6.116,2.391-8.704,1.668C716.957,491.006,716.417,486.937,715.422,484.5z M677.538,380.003
      		c0.317,5.558,0.579,10.144,0.859,15.045c-5.463-0.027-4.446,3.849-4.117,8.508c1.728-1.396,3.081-2.075,3.817-3.169
      		c1.779-2.643,3.051-5.815,7.283-3.885c0.312,0.143,1.75-1.21,1.68-1.342c-1.833-3.431-0.033-5.802,1.804-8.54
      		c0.645-0.959-0.696-3.25-1.42-6.049C684.715,385.681,684.715,385.681,677.538,380.003z M746.758,289.165
      		c0.379-0.555,0.759-1.11,1.138-1.665c-1.517-1.43-2.826-3.231-4.593-4.209c-3.332-1.842-7.131-2.882-10.337-4.892
      		c-2.136-1.34-3.633-3.739-5.32-5.745c-1.153-1.373-2.141-2.885-3.203-4.335c-0.474,1.938-1.306,3.876-1.298,5.811
      		c0.006,1.336,0.958,2.841,1.861,3.972C730.672,285.196,739.469,285.545,746.758,289.165z M696.742,517.455
      		c0.371-0.344,1.121-1.04,1.872-1.735c-0.798-0.528-1.56-1.443-2.4-1.523c-5.926-0.568-11.864-1.062-17.809-1.344
      		c-1.375-0.065-4.032,0.949-4,1.189c0.328,2.493,0.979,4.97,1.882,7.321c0.098,0.254,2.571,0.046,3.558-0.567
      		C684.767,517.739,689.792,515.47,696.742,517.455z M678.777,495.482c-8.408,4.264-12.59-3.297-17.846-6.382
      		c-0.746,3.193-0.557,6.036-1.88,7.319c-3.328,3.231-1.178,5,1.165,7.844c1.773-5.746,5.301-5.682,9.774-4.408
      		C673.26,500.787,676.873,500.767,678.777,495.482z M716.346,380.578c-8.349-1.336-10.03,0.007-11.761,9.155
      		c-0.245,1.299,0.465,2.78,0.732,4.177c1.919-0.504,4.802-0.399,5.575-1.626C713.14,388.725,714.54,384.631,716.346,380.578z
      		 M675.075,453.164c1.249-0.482,2.496-0.964,3.745-1.447c-0.344-0.668-0.676-1.91-1.033-1.917c-5.693-0.12-3.417-3.717-3.011-6.578
      		c0.232-1.638,0.682-3.467,0.182-4.917c-0.428-1.242-1.939-2.424-3.238-2.893c-0.496-0.179-2.382,1.614-2.514,2.65
      		c-0.392,3.095-0.374,6.266-0.247,9.396C669.107,451.15,670.722,453.491,675.075,453.164z M700.433,363.716
      		c-1.12-2.314-1.726-4.046-2.745-5.487c-0.605-0.856-1.791-1.544-2.839-1.795c-1.991-0.477-4.092-0.477-6.108-0.874
      		c-2.882-0.567-5.728-1.314-9.573-2.215C684.33,362.132,692.174,361.985,700.433,363.716z M659.928,534.244
      		c-0.514-6.328-0.78-12.069-1.549-17.743c-0.272-2.01-1.769-3.856-2.706-5.776c-0.538,1.963-1.535,3.927-1.53,5.889
      		C654.158,522.847,654.358,529.143,659.928,534.244z M727.312,505.284c-3.897,3.293-3.876,5.831-2.489,8.949
      		c1.656,3.723,2.791,7.677,4.221,11.504c0.294,0.788,0.917,2.085,1.338,2.065c1.021-0.048,2.42-0.48,2.89-1.239
      		c0.384-0.622-0.253-1.975-0.612-2.938c-0.847-2.267-2.005-4.438-2.658-6.755C728.993,513.302,728.304,509.644,727.312,505.284z
      		 M743.153,323.417c-1.84,2.413-3.049,5.14-5.144,6.521c-5.078,3.348-5.309,4.912-0.223,8.727c2.023-2.805,3.748-5.905,6.148-8.341
      		C746.932,327.281,746.391,325.305,743.153,323.417z M706.987,407.206c-1.517,1.387-3.082,3.022-4.865,4.371
      		c-1.018,0.769-2.557,0.858-3.545,1.648c-0.761,0.609-1.03,1.833-1.518,2.784c0.816,0.408,1.592,1.005,2.456,1.194
      		c9.077,1.987,10.086,0.991,8.129-7.982C707.408,408.13,706.948,407.089,706.987,407.206z M724.203,460.154
      		c3.661-1.414,7.224-2.67,10.654-4.218c0.676-0.305,1.351-1.911,1.115-2.611c-0.308-0.915-1.449-2.06-2.356-2.182
      		C725.823,450.095,723.894,452.165,724.203,460.154z M710.281,428.319c-3.248-2.517-10.285-1.367-11.646,2.162
      		c-0.269,0.696,1.069,2.895,1.55,2.859C704.167,433.038,708.645,433.412,710.281,428.319z M653.287,409.67
      		c1.562,3.816,2.633,6.428,3.703,9.04c2.616-1.897,5.232-3.794,7.849-5.691c-0.172-0.547-0.344-1.094-0.515-1.641
      		C661.059,410.873,657.792,410.367,653.287,409.67z M711.102,508.96c0,5.766-0.037,11.533,0.062,17.297
      		c0.009,0.511,0.985,1.471,1.365,1.403c0.611-0.108,1.538-0.872,1.575-1.411C714.507,520.384,715.424,514.427,711.102,508.96z
      		 M674.331,527.901c-0.499,6.72,4.248,9.33,13.257,7.099C682.83,532.453,678.672,530.226,674.331,527.901z M680.547,469.889
      		c3.386-2.552,3.941-6.539,1.091-9.015c-0.671-0.582-2.763-0.324-3.621,0.319c-0.928,0.695-1.298,2.26-1.681,3.514
      		c-0.316,1.039-0.297,2.181-0.426,3.276c0.946-0.646,1.895-1.291,2.973-2.026C679.408,467.2,679.97,468.524,680.547,469.889z
      		 M701.257,526.32c-0.345,0.977-1.049,1.93-0.817,2.504c0.76,1.877,1.499,4.075,2.968,5.232c0.847,0.666,3.087-0.438,4.698-0.745
      		c-1.121-1.652-2.094-3.437-3.418-4.907C703.862,527.488,702.508,527.05,701.257,526.32z M713.376,453.037
      		c-2.3-2.01-4.096-3.578-5.892-5.146c-1.182,2.415-2.365,4.829-4.021,8.208c3.03-0.729,4.611-1.053,6.156-1.501
      		C710.537,454.331,711.397,453.87,713.376,453.037z M717.985,450.237c0.398-0.193,0.796-0.385,1.194-0.578
      		c-0.667-2.399-0.944-5.03-2.169-7.099c-0.601-1.014-2.962-0.985-4.526-1.428c0.078,1.359-0.338,3.082,0.339,4.001
      		C714.239,447.058,716.23,448.561,717.985,450.237z M681.313,420.244c0.433-0.329,0.865-0.658,1.298-0.987
      		c-1.011-2.327-1.793-4.802-3.146-6.909c-0.535-0.832-2.345-0.845-3.573-1.23c-0.07,1.366-0.729,3.185-0.094,4.005
      		C677.312,417.081,679.436,418.567,681.313,420.244z M665.496,355.607c5.826-4.898,6.541-7.712,2.744-12.125
      		C667.288,347.69,666.487,351.227,665.496,355.607z M656.966,461.907c-0.509,2.41-1.079,4.295-1.234,6.216
      		c-0.068,0.853,0.701,1.774,1.09,2.665c0.951-0.471,2.277-0.69,2.786-1.455C661.551,466.413,660.374,464.148,656.966,461.907z
      		 M801.891,421.821c-0.572,0.271-1.145,0.543-1.718,0.816c-0.313,3.232-1.638,7.116,5.596,6.167
      		C804.315,426.186,803.104,424.003,801.891,421.821z M741.396,512.006c-0.762-1.378-1.445-2.614-2.128-3.85
      		c-0.568,1.078-1.688,2.212-1.585,3.222c0.171,1.703,1.116,3.328,1.736,4.986C740.024,515.028,740.631,513.692,741.396,512.006z
      		 M657.917,436.586c-2.305-0.502-3.948-0.86-6.483-1.413c1.245,2.71,2.113,4.602,3.304,7.194
      		C655.994,440.083,656.763,438.685,657.917,436.586z M665.637,472.707c0.432,0.67,0.863,1.341,1.295,2.011
      		c1.609-0.963,3.259-1.875,4.743-3.001c0.102-0.077-1.029-1.775-1.587-2.72C668.604,470.233,667.121,471.47,665.637,472.707z"/>
      <Link to={"/archive/039"} className={activeIndex === 39 ? "active" : "inactive"} onClick={() => handleOnClick(39) } >
      	<path className="clickable" fillRule="evenodd" clipRule="evenodd" fill="#000" d="M776.013,1057.006c-8.015,0.371-16.33,0.75-24.645,1.145
      		c-3.497,0.165-5.329-1.818-5.758-4.955c-0.49-3.595-0.703-7.246-0.755-10.878c-0.22-15.613-0.109-31.233-0.582-46.837
      		c-0.255-8.44,0.498-10.025,8.994-10.659c11.942-0.892,23.91-1.455,35.86-2.247c3.645-0.241,5.062,1.221,5.021,4.894
      		c-0.144,12.446-0.094,24.897-0.102,37.346c-0.001,1.829-0.337,3.798,0.214,5.458c1.405,4.234,2.068,7.514-2.06,11.304
      		c-1.928,1.77-1.103,6.931-0.903,10.521c0.218,3.897-0.979,5.386-4.979,4.979C783.025,1056.739,779.669,1057.006,776.013,1057.006z"
      		/>
      </Link>
      <Link to={"/archive/036"} className={activeIndex === 36 ? "active" : "inactive"} onClick={() => handleOnClick(36) } >
      	<path className="clickable" fillRule="evenodd" clipRule="evenodd" fill="#000" d="M742.857,940.152c-0.606-6.954-1.249-13.906-1.806-20.864
      		c-0.429-5.347,1.24-7.489,6.405-7.756c11.973-0.616,23.954-1.017,35.929-1.602c4.543-0.222,6.394,1.563,6.623,6.29
      		c0.846,17.449,1.941,34.89,3.229,52.311c0.341,4.604-1.403,6.447-5.705,6.552c-12.933,0.313-25.864,0.676-38.8,0.923
      		c-3.123,0.059-4.642-1.36-4.739-4.878c-0.285-10.32-0.972-20.63-1.5-30.943C742.615,940.174,742.736,940.163,742.857,940.152z"/>
      </Link>
      <Link to={"/archive/037"} className={activeIndex === 37 ? "active" : "inactive"} onClick={() => handleOnClick(37) } >
      	<path className="clickable" fillRule="evenodd" clipRule="evenodd" fill="#000" d="M834.777,1056.812c-0.803,0.11-3.109,0.801-5.368,0.667
      		c-7.556-0.448-15.102-1.111-22.624-1.942c-0.765-0.085-1.803-1.804-1.934-2.86c-1.101-8.859-2.377-17.559-0.582-26.697
      		c1.414-7.201,0.218-14.9,0.354-22.376c0.076-4.167,1.106-8.048-0.747-12.457c-2.367-5.631-0.498-7.47,5.694-8.113
      		c8.261-0.86,16.572-1.42,24.759-2.744c8.143-1.317,9.724,2.518,9.825,9.685c0.162,11.424,0.054,22.854,0.03,34.279
      		c-0.019,8.643,0.042,17.288-0.165,25.927C843.844,1057.516,843.697,1057.512,834.777,1056.812z"/>
      </Link>
      <Link to={"/archive/040"} className={activeIndex === 40 ? "active" : "inactive"} onClick={() => handleOnClick(40) } >
        <path className="clickable" fillRule="evenodd" clipRule="evenodd" fill="#000" d="M824.365,906.976c3.326,0,6.691,0.321,9.967-0.083
      		c3.923-0.482,5.502,1.282,5.977,4.782c0.2,1.478,0.635,2.946,0.645,4.421c0.077,11.608-0.007,23.218,0.157,34.824
      		c0.047,3.271,0.466,6.601,1.223,9.783c1.435,6.029-1.092,10.357-7.095,11.237c-6.904,1.012-13.823,1.926-20.746,2.801
      		c-1.64,0.207-3.362,0.341-4.973,0.069c-3.211-0.541-6.107,0.2-6.21-5.799c-0.251-14.699-2.556-29.354-3.779-44.048
      		c-1.357-16.308-3.187-14.396,13.884-17.099c3.596-0.569,7.298-0.472,10.951-0.681C824.365,907.115,824.365,907.045,824.365,906.976
      		z"/>
      </Link>
      	<path fillRule="evenodd" clipRule="evenodd" fill="#000" d="M873.886,1064.129c0.198,0.853-0.158,1.834-0.117,1.46
      		c-2.571-1.919-5.753-3.722-8.101-6.286c-1.151-1.259-1.277-4.06-0.823-5.906c0.202-0.824,2.954-1.144,4.595-1.416"/>
      	<path fillRule="evenodd" clipRule="evenodd" fill="#000" d="M774.416,1140.114c0.084,1.017-3.129,2.306-5.672,4.031
      		c-1.521-1.895-3.146-3-3.273-4.259"/>
      	<path fillRule="evenodd" clipRule="evenodd" fill="#000" d="M693.57,1032.978c-0.398,0.605-0.796,1.211-1.194,1.815
      		c-2.217-1.073-5.59-1.637-6.349-3.355c-0.867-1.96,0.594-4.95,1.042-7.493c0.665,0.007,1.33,0.014,1.994,0.021"/>
      	<path fillRule="evenodd" clipRule="evenodd" fill="#000" d="M690.55,950.227c3.409,1.047,4.314,3.636,2.721,8.481"/>
      	<path className="figure-outline" fillRule="evenodd" clipRule="evenodd" d="M633.678,637.594c0.658,11.745,8.653,14.96,15.834,19.752
      		c10.423,6.955,19.816,15.453,29.639,23.307c0.647,0.519,1.166,1.198,1.814,1.715c11.398,9.099,18.223,20.742,22.938,34.694
      		c3.424,10.129,6.362,20.154,7.295,30.638c0.5,5.622-0.659,11.508-1.801,17.127c-1.219,5.997-2.615,12.14-5.191,17.628
      		c-2.625,5.591-6.377,10.801-10.375,15.555c-4.048,4.814-8.314,10.256-13.718,12.928c-8.237,4.07-15.498,10.74-26.062,10.049
      		c-6.791-0.443-13.732,1.965-20.646,2.743c-14.25,1.604-27.66-2.708-41.215-6.104c-10.724-2.688-20.833-6.879-30.119-12.707
      		c-3.311-2.078-5.245-6.258-8.059-9.241c-4.873-5.17-6.557-11.193-6.296-18.212c0.321-8.635-0.112-17.294,0.039-25.938
      		c0.323-18.459,7.682-34.652,17.354-49.759c4.058-6.338,9.924-11.553,15.161-17.095c10.146-10.738,19.983-21.829,30.826-31.824
      		C617.401,647.037,625.408,643.072,633.678,637.594z M700.845,753.232c0.731-11.121-0.866-21.35-5.5-31.071
      		c-3.499-7.341-7.299-14.607-9.958-22.257c-1.834-5.279-4.166-6.955-9.396-5.149c-1.824,0.631-4.655,0.764-3.333-2.659
      		c2.117-5.48-0.532-8.711-4.575-11.705c-2.399-1.776-4.427-4.045-6.778-5.894c-2.382-1.874-4.856-3.673-7.468-5.204
      		c-7.021-4.116-14.158-8.036-21.188-12.14c-0.747-0.436-1.182-1.527-1.598-2.392c-2.114-4.404-3.565-4.652-7.372-1.389
      		c-6.681,5.728-13.704,11.089-20.053,17.161c-7.998,7.648-16.961,14.334-22.49,24.473c-4.56,8.36-9.051,16.765-14.834,24.545
      		c-6.301,8.474-7.663,19.129-9.519,29.56c-2.486,13.977,1.686,26.905,3.888,40.197c0.177,1.067,0.863,2.266,1.683,2.98
      		c7.157,6.24,13.042,14.312,23.304,16.138c2.102,0.373,4.074,1.432,6.133,2.089c19.361,6.175,39.007,10.551,59.321,5.81
      		c16.687-3.894,30.879-11.854,40.427-26.944C698.688,778.087,701.567,765.938,700.845,753.232z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M646.573,614.646c1.21,6.45,5.144,8.045,10.42,7.713
      		c2.152-0.136,4.333,0.163,6.481,0.005c8.173-0.599,8.424-0.974,8.67-9.233c0.037-1.239,0.892-2.454,1.368-3.679
      		c0.708,1.18,1.751,2.275,2.071,3.552c2.4,9.589,2.376,10.1,12.253,9.7c18.96-0.765,14.688,2.657,13.619-15.415
      		c-0.195-3.294,0.002-7.458-1.859-9.55c-1.566-1.759-5.872-1.034-8.949-1.504c-9.93-1.519-9.937-1.536-9.987-11.638
      		c-0.009-1.664-0.225-3.439,0.275-4.959c0.445-1.354,1.749-2.427,2.674-3.625c0.695,1.356,1.757,2.652,1.995,4.084
      		c0.322,1.937-0.127,3.989,0,5.975c0.319,5.005,2.151,5.841,9.326,5.916c4.837,0.049,3.692-3.131,3.831-5.745
      		c0.114-2.161,0.082-4.331,0.082-6.496c0.003-7.628-0.606-8.434-8.044-8.79c-4.482-0.215-8.987,0.081-13.483,0.118
      		c-3.322,0.028-4.854,1.549-4.454,4.965c0.601,5.125,1.26,10.25,1.601,15.394c0.392,5.904-0.398,6.398-6.062,5.731
      		c-4.451-0.524-8.953-0.585-13.424-0.971c-3.978-0.344-6.005-2.229-5.219-6.583c0.609-3.376,0.793-6.829,1.166-10.249
      		c0.552-0.106,1.103-0.213,1.654-0.319c0.732,2.483,1.639,4.93,2.156,7.456c0.762,3.711,10.898,8.465,13.875,6.285
      		c0.488-0.357,0.657-1.462,0.605-2.195c-0.33-4.651-0.553-9.326-1.234-13.929c-0.483-3.255-2.347-5.393-6.182-4.909
      		c-3.795,0.48-7.636,0.629-11.425,1.146c-3.015,0.411-4.41-0.508-4.082-3.696c0.169-1.649,0.127-3.329,0.071-4.992
      		c-0.264-7.859,0.381-8.438,9.19-7.732c-0.552,0.798-1.155,1.395-1.432,2.119c-0.927,2.423-1.734,4.89-2.589,7.34
      		c3.585,0.162,7.173,0.414,10.754,0.351c0.369-0.006,1.056-1.912,0.992-2.898c-0.192-2.98-0.883-5.936-0.962-8.913
      		c-0.034-1.275,0.962-2.578,1.49-3.868c1.457,0.826,2.949,1.6,4.344,2.52c0.31,0.204,0.297,0.923,0.397,1.411
      		c2.535,12.331,2.534,12.331,14.855,12.208c1.499-0.016,2.998-0.021,4.498-0.032c9.026-0.066,10.052-1.464,7.526-10.353
      		c-0.491-1.729-1.098-3.426-1.691-5.265c2.788-1.514,5.026-2.346,5.408,2.075c0.157,1.811,0.665,3.595,1.053,5.383
      		c1.571,7.229,4.616,9.283,12.28,8.308c7.622-0.972,11.833-5.11,11.269-11.676c-0.26-3.027,1.202-4.201,3.521-4.126
      		c0.937,0.03,2.402,1.992,2.618,3.248c2.89,16.801,5.521,33.646,8.393,50.45c1.239,7.256,4.815,9.526,12.091,8.189
      		c2.852-0.523,3.909-2.071,1.928-4.492c-6.098-7.457-7.836-15.881-6.448-25.287c0.426-2.89-0.264-5.945-0.459-9.199
      		c3.589-0.958,5.935-0.467,5.741,4.17c-0.139,3.322,0.188,6.663,0.106,9.99c-0.172,6.968-0.093,13.412,4.597,19.707
      		c4.969,6.666,2.905,9.522-5.488,10.025c-1.828,0.109-3.663,0.094-5.495,0.128c-8.439,0.159-9.265-0.865-10.778-9.221
      		c-2.161-11.928-4.771-23.776-7.278-35.64c-0.633-2.996-8.624-5.742-10.825-3.665c-0.771,0.726-1.094,2.406-0.922,3.545
      		c0.272,1.801,1.557,3.498,1.627,5.269c0.072,1.823-0.799,3.684-1.431,6.231c-1.021-3.394-1.619-6.314-2.811-8.966
      		c-0.656-1.462-2.101-3.093-3.549-3.583c-6.002-2.027-6.322-1.852-5.692,4.551c0.548,5.571,2.109,11.043,2.658,16.615
      		c0.615,6.25,0.408,12.579,0.911,18.845c0.385,4.783,1.318,9.522,1.98,14.088c-10.479,0-20.738-0.018-30.997,0.007
      		c-6.132,0.015-12.264,0.037-18.394,0.178c-2.487,0.057-4.996,0.77-7.442,0.564c-2.032-0.171-4.844-0.663-5.798-2.052
      		C644.226,622.01,642.68,618.543,646.573,614.646z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M466.573,651.654c-0.604-3.042-1.209-6.082-2.017-10.15
      		c-5.635,1.46-12.126,3.083-18.539,4.98c-0.322,0.095-0.479,3.182,0.219,3.767c1.241,1.042,3.196,1.237,5.437,1.979
      		c-7.73,1.986-6.077,7.56-4.979,12.58c1.092,4.997,2.82,9.854,4.329,14.976c-4.208,0.082-7.002-1.579-7.779-6.398
      		c-1.271-7.879-3.049-15.681-4.163-23.578c-0.613-4.347-2.862-4.752-6.216-3.716c-5.091,1.572-8.446,6.567-8.301,12.099
      		c0.191,7.323-0.245,14.802,6.172,20.288c0.587,0.501,0.685,2.772,0.36,2.935c-1.338,0.676-3.404,1.646-4.34,1.081
      		c-4.034-2.436-7.628-5.396-7.536-10.89c0.095-5.656,0.238-11.334-0.136-16.97c-0.483-7.295-8.357-11.745-15.029-8.653
      		c-0.748,0.346-1.363,1.833-1.369,2.794c-0.049,7.961-0.135,15.934,0.323,23.872c0.072,1.26,2.808,2.366,4.311,3.543
      		c0.897-1.221,2.04-2.334,2.627-3.689c0.643-1.483,0.774-3.189,1.245-5.329c3.468,3.65,3.399,7.494,2.034,11.253
      		c-1.229,3.384-4.391,4.012-7.514,3.321c-3.799-0.843-6.586-2.426-7.948-7c-4.569-15.345-4.153-30.758-2.39-46.533
      		c4.132,0.219,3.615,3.288,4.322,5.707c0.728,2.49,1.831,6.787,3.034,6.88c4.496,0.348,9.113-0.626,13.636-1.389
      		c0.337-0.057,0.443-2.381,0.396-3.642c-0.149-3.978-0.623-7.956-0.528-11.923c0.029-1.228,1.479-2.421,2.277-3.63
      		c0.837,1.224,2.197,2.361,2.416,3.687c0.784,4.753,0.937,9.619,1.874,14.333c0.279,1.407,2.194,3.284,3.616,3.543
      		c12.41,2.261,12.742,1.903,12.669-10.546c-0.011-1.833-0.267-3.701,0.021-5.483c0.154-0.957,1.223-1.767,1.879-2.644
      		c0.928,0.829,2.555,1.58,2.659,2.502c0.334,2.958,0.284,5.997,0.043,8.976c-0.238,2.941,1.088,4.473,3.767,4.8
      		c3.117,0.38,6.287,0.726,9.401,0.535c1.448-0.089,2.816-1.483,4.22-2.285c-1.267-1.258-2.385-2.738-3.842-3.71
      		c-1.516-1.013-3.707-1.193-4.915-2.416c-0.619-0.626-0.054-3.805,0.521-3.974c1.792-0.525,3.967-0.587,5.769-0.061
      		c3.69,1.077,7.136,3.11,10.863,3.907c4.307,0.92,4.699,3.505,2.274,5.753c-3.696,3.424-4.22,6.932-2.829,11.333
      		c0.265,0.838-0.106,1.876-0.185,2.822C468.011,651.41,467.292,651.532,466.573,651.654z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M413.118,698.904c1.002,0.867,1.91,1.344,2.328,2.093
      		c0.532,0.953,1.105,2.268,0.818,3.153c-0.184,0.566-1.888,0.861-2.917,0.88c-1.653,0.03-3.355-0.082-4.96-0.458
      		c-8.89-2.084-17.694-4.386-27.029-3.239c-2.479,0.305-5.304-0.631-7.711-1.626c-5.53-2.284-6.91-5.09-6.15-11.019
      		c0.248-1.936,0.396-3.883,0.496-4.888c-4.657-0.736-9.301-0.488-12.757-2.394c-1.994-1.1-2.069-5.678-3.008-8.69
      		c0.404-0.213,0.809-0.426,1.213-0.639c2.941,2.16,5.881,4.321,9.385,6.895c1.02-7.013,1.917-13.17,2.813-19.328
      		c0.568-0.056,1.138-0.111,1.706-0.167c0.894,3.9,1.58,7.863,2.758,11.675c0.688,2.231-1.044,6.307,3.857,5.948
      		c4.494-0.331,3.026-3.927,2.933-6.073c-0.441-10.237-1.356-20.452-1.946-30.685c-0.227-3.934-0.036-7.893-0.036-11.839
      		c0.547-0.253,1.094-0.506,1.642-0.759c1.469,2.288,4.081,4.511,4.213,6.874c0.668,11.879,5.463,23.334,3.288,35.534
      		c-0.828,4.644-2.147,8.649-5.725,11.772c-0.501,0.436-1.132,0.861-1.375,1.431c-1.302,3.042-4.186,6.849-3.266,8.998
      		c0.95,2.221,5.518,3.409,8.704,4.048c5.983,1.202,12.097,1.867,18.188,2.359c1.932,0.156,4.116-0.645,5.92-1.54
      		c2.961-1.469,5.596-3.615,8.597-4.969c1.153-0.521,2.932,0.345,4.426,0.579c-0.737,1.292-1.253,2.811-2.275,3.812
      		C416.254,697.614,414.74,698.054,413.118,698.904z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M299.864,668.116c5.269-0.584,10.546-1.103,15.803-1.782
      		c2.273-0.294,4.189-0.922,4.287,2.667c0.084,3.087-0.784,4.189-3.892,3.883c-2.609-0.256-5.262-0.053-8.674-0.053
      		c1.326,3.885,2.418,7.088,3.757,11.01c-3.234-0.708-5.922-1.272-8.598-1.888c-2.856-0.658-4.337-0.147-4.056,3.372
      		c0.341,4.265,0.081,8.578,0.081,13.049c-1.683-0.293-3.174-0.997-4.403-0.682c-3.572,0.916-6.061-0.511-8.485-2.86
      		c-2.573-2.494-4.774-1.753-6.075,1.664c-0.458,1.204-1.382,2.231-2.093,3.339c-1.199-1.172-2.899-2.13-3.502-3.555
      		c-1.794-4.242-3.102-8.688-4.707-13.014c-1.368-3.688-3.852-4.04-7.081-2.24c-1.837,1.024-3.812,1.798-5.824,2.731
      		c-0.221-4.512,6.928-12.779,10.926-12.092c1.917,0.329,3.693,3.01,5.004,4.978c2.132,3.202,3.83,6.691,5.711,10.06
      		c0.697-0.217,1.394-0.435,2.09-0.653c-0.514-3.732-0.746-7.531-1.605-11.183c-1.37-5.816-3.244-11.514-4.693-17.315
      		c-0.287-1.147,0.097-3.463,0.665-3.637c1.848-0.567,4.667-1.247,5.744-0.311c1.789,1.558,3.18,4.227,3.569,6.619
      		c1.361,8.36,2.132,16.816,3.362,25.201c0.191,1.301,1.609,2.422,2.459,3.627c0.964-1.097,2.056-2.105,2.867-3.304
      		c2.075-3.07,3.538-6.74,6.152-9.208c3.016-2.848,2.208-5.508,1.348-8.55L299.864,668.116z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M485.285,646.508c0.609,2.038,1.306,4.054,1.807,6.118
      		c0.593,2.441,1.862,3.493,4.53,3.45c10.486-0.173,8.291,1.33,8.338-8.201c0.002-0.333-0.022-0.666-0.031-0.999
      		c-0.092-3.457-0.183-6.915-0.273-10.371c3.23,0.368,6.458,0.952,9.69,0.97c0.946,0.005,2.671-1.558,2.722-2.474
      		c0.237-4.312-0.224-8.667,0.099-12.967c0.102-1.346,1.871-2.565,2.877-3.843c1.188,1.293,3.144,2.449,3.404,3.908
      		c0.58,3.24,0.355,6.632,0.379,9.962c0.048,6.792,5.2,3.306,8.086,3.797c3.999,0.682,2.932-2.362,2.603-4.609
      		c-0.506-3.454-1.227-6.898-1.393-10.368c-0.062-1.299,1.275-3.824,1.805-3.779c1.712,0.145,3.927,0.796,4.853,2.057
      		c1.058,1.438,1.486,3.818,1.228,5.652c-0.739,5.24-1.961,10.411-3.001,15.606c-0.583,2.91-1.958,3.561-4.998,2.715
      		c-2.449-0.681-5.538-0.669-7.883,0.231c-4.258,1.635-4.411,4.562-0.474,7.074c2.162,1.379,4.864,2.017,7.404,2.675
      		c3.529,0.913,7.195,1.338,10.668,2.41c0.883,0.272,1.256,2.2,1.863,3.367c-1.146,0.476-2.478,1.594-3.409,1.326
      		c-6.706-1.931-13.371-4.031-19.947-6.363c-1.278-0.454-2.37-2.024-3.074-3.333c-0.685-1.274-0.471-3.042-1.188-4.285
      		c-0.752-1.302-2.143-2.235-3.253-3.33c-0.71,1.169-1.913,2.29-2.04,3.519c-0.485,4.708-1.336,8.986-5.187,12.436
      		c-3.733,3.344-7.561,3.739-11.973,2.147c-4.633-1.671-7.693-4.854-8.114-9.768c-0.144-1.672,1.421-3.492,2.204-5.243
      		C484.166,646.166,484.726,646.337,485.285,646.508z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M549.405,674.877c-2.787,1.088-5.636,2.043-8.33,3.326
      		c-1.176,0.559-2.031,1.791-3.032,2.717c1.062,0.573,2.08,1.531,3.19,1.653c5.293,0.585,10.604,1.182,15.921,1.301
      		c3.948,0.089,6.044,1.954,6.303,5.556c0.268,3.712-1.615,6.991-5.395,7.227c-10.081,0.63-20.202,0.771-30.308,0.79
      		c-3.456,0.006-6.915-0.923-10.371-1.434c-7.159-1.058-8.06-3.048-4.204-9.572c12.143,10.593,26.44,6.769,40.271,5.869
      		c1.081-0.07,2.066-1.608,3.096-2.464c-1.082-0.773-2.09-2.047-3.261-2.22c-2.781-0.41-5.644-0.257-8.469-0.41
      		c-3.32-0.181-6.845,0.168-9.893-0.861c-2.557-0.864-4.557-3.378-6.802-5.162c1.785-1.574,3.324-3.802,5.407-4.591
      		c4.859-1.842,9.98-2.991,14.995-4.421C548.818,673.081,549.112,673.979,549.405,674.877z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M243.764,684.934c0-3.154,0.113-6.313-0.031-9.461
      		c-0.156-3.403,1.866-4.56,4.612-4.104c0.808,0.134,1.738,2.552,1.752,3.931c0.068,6.798,0.232,13.633-0.382,20.388
      		c-0.583,6.41-1.667,6.92-8.077,6.614c-0.333-0.016-0.665-0.041-0.997-0.044c-9.037-0.088-9.64-0.272-10.823-9.542
      		c-0.685-5.367-0.193-10.906,0.086-16.352c0.054-1.05,1.539-2.79,2.43-2.833c1.014-0.049,2.812,1.296,3.027,2.302
      		c0.687,3.21,0.843,6.541,1.119,9.833c0.263,3.134-0.011,6.396,0.809,9.366c0.391,1.413,2.775,2.274,4.257,3.385
      		c0.85-1.337,2.265-2.608,2.435-4.027c0.373-3.113,0.115-6.301,0.115-9.458C243.987,684.934,243.875,684.934,243.764,684.934z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M357.138,698.394c-11.128,2.652-19.686-3.333-20.966-14.699
      		c-1.503-13.354-2.174-26.805-2.949-40.229c-0.083-1.437,1.558-2.974,2.398-4.464c1.129,1.292,3.014,2.461,3.25,3.901
      		c0.71,4.332,0.725,8.772,1.105,13.161c0.83,9.559,1.557,19.129,2.655,28.657c0.509,4.415,3.159,7.406,7.545,9.025
      		C352.357,694.548,354.167,696.359,357.138,698.394z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M371.832,735.405c0.428-1.069,0.591-3.139,1.569-3.651
      		c7.134-3.74,7.574-10.233,7.837-17.084c0.065-1.684,1.36-4.345,2.659-4.755c3.221-1.018,4.228,0.997,3.82,4.198
      		c-0.154,1.207,0.866,2.563,1.352,3.852c1.072-0.649,2.172-1.254,3.202-1.965c0.538-0.371,0.872-1.072,1.433-1.372
      		c1.701-0.909,3.467-1.696,5.208-2.53c0.496,1.625,1.321,3.227,1.43,4.877c0.377,5.768,0.359,11.522-4.509,17.046
      		c-1.752-4.61-3.505-9.22-5.591-14.708c-3.209,3.989-6.304,7.581-9.096,11.394c-2.047,2.798,0.859,3.374,2.455,4.071
      		c1.867,0.815,4.325,0.414,4.146,3.751c-0.198,3.696-2.943,2.781-5.004,3.383c-1.394,0.407-2.636,1.304-3.997,1.855
      		c-1.494,0.606-3.019,1.266-4.589,1.494c-0.621,0.09-1.402-0.918-2.111-1.427c0.569-0.627,1.041-1.632,1.727-1.81
      		c2.083-0.539,4.36-0.465,6.342-1.211c1.18-0.444,1.949-1.981,2.903-3.027c-0.991-0.666-1.917-1.749-2.985-1.914
      		C377.546,735.492,374.994,735.554,371.832,735.405z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M528.569,711.232c3.778-1.173,6.167,0.088,6.87,4.006
      		c0.873,4.869,1.951,9.733,2.334,14.647c0.391,5.009-2.614,7.699-7.489,8.054c-2.631,0.19-5.3-0.049-7.947-0.186
      		c-4.693-0.244-9.432-0.249-14.054-0.981c-2.116-0.335-5.178-1.739-5.761-3.386c-1.692-4.771-2.434-9.894-3.369-14.911
      		c-0.455-2.438-0.695-4.952-0.668-7.427c0.012-1.143,0.896-2.277,1.385-3.416c0.944,0.899,2.534,1.681,2.723,2.717
      		c0.891,4.897,1.198,9.899,2.087,14.796c1.349,7.426,4.121,9.485,11.613,9.035c4.15-0.249,8.286-0.805,12.438-0.929
      		c3.814-0.114,5.264-2.219,4.51-5.491C531.982,722.296,530.214,716.946,528.569,711.232z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M411.168,858.883c-1.177-6.131-2.314-11.25-3.046-16.427
      		c-0.179-1.267,0.564-3.254,1.563-4.002c6.121-4.587,1.702-9.938,1.159-14.931c-0.057-0.515-2.36-0.927-3.661-1.139
      		c-5.178-0.846-10.409-1.425-15.52-2.558c-0.763-0.169-1.814-3.387-1.276-4.111c0.938-1.262,3.237-2.599,4.563-2.272
      		c3.266,0.803,6.2,2.934,9.462,3.775c3.165,0.816,6.572,0.692,9.865,0.981c0.052,0.386,0.224,0.756,0.119,1.013
      		c-2.752,6.751-2.728,6.652,4.745,8.896c1.47,0.439,3.827,2.203,3.676,2.907c-0.356,1.662-1.77,4.093-3.123,4.361
      		c-4.252,0.841-3.97,3.547-3.877,6.661C415.987,847.702,415.133,853.146,411.168,858.883z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M180.182,733.207c0.863,3.447,1.292,6.383,2.384,9.046
      		c0.622,1.516,2.184,3.469,3.571,3.695c4.227,0.688,8.586,0.907,12.869,0.729c1.656-0.069,4.341-1.457,4.606-2.671
      		c0.378-1.729-0.557-4.279-1.813-5.698c-1.639-1.849-3.644-3.438-1.605-5.713c1.165-1.3,3.558-2.396,5.172-2.145
      		c1.628,0.254,3.698,2.004,4.327,3.593c3.015,7.616-1.791,15.1-11.554,18.05c-6.172,1.865-12.053,1.672-17.207-3.186
      		C175.177,743.481,174.792,740.819,180.182,733.207z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M239.833,862.225c0.542-3.514,0.534-6.246,1.497-8.576
      		c0.507-1.226,2.71-1.75,4.148-2.59c0.478,1.211,1.237,2.393,1.368,3.64c0.225,2.14-0.157,4.346,0.098,6.478
      		c0.566,4.743-1.554,7.311-5.901,8.942c-4.362,1.637-7.878,0.8-10.77-2.562c-2.804-3.258-4.706-8.022-10.569-5.116
      		c-0.114,0.056-1.812-3.086-2.772-4.736c1.127-0.595,2.247-1.669,3.383-1.687c2.394-0.037,4.901,1.054,7.168,0.635
      		c3.337-0.615,6.888-3.722,7.732,2.815C235.35,860.516,238.081,861.229,239.833,862.225z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M228.542,742.543c2.805,0.726,5.077,1.678,7.4,1.831
      		c3.531,0.232,3.692,2.639,3.118,4.839c-0.219,0.84-2.873,1.79-4.117,1.482c-4.31-1.069-8.558-2.502-12.656-4.214
      		c-0.788-0.329-1.527-3.243-1.025-3.776c2.672-2.844,5.417-5.885,8.747-7.795c8.8-5.048,8.914-4.827,6.08-14.848
      		c-0.452-1.596-1.177-3.274-1.012-4.833c0.135-1.274,1.41-2.427,2.179-3.634c1.021,0.651,2.501,1.063,2.988,1.994
      		c3.649,6.979,4.365,14.258-0.682,20.665C237.051,737.439,232.915,739.346,228.542,742.543z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M514.136,748.493c3.961,5.408,0.946,15.079-4.25,17.817
      		c-1.947,1.026-3.313,3.155-4.944,4.782c0.238,0.431,0.476,0.861,0.714,1.292c-1.186-0.262-2.371-0.522-3.977-0.876
      		c3.09-4.254,0.474-5.302-2.958-5.675c-13.768-1.495-12.166-3.6-10.779-15.994c0.294-2.64,2.058-5.114,3.145-7.664
      		c0.617,2.522,1.372,5.021,1.814,7.575c0.48,2.771,0.381,5.675,1.128,8.354c0.294,1.052,2.197,1.656,3.367,2.464
      		c0.615-1.061,1.526-2.056,1.775-3.195c0.362-1.655-1.475-4.174,2.19-4.228c3.338-0.049,2.716,2.3,2.577,4.142
      		c-0.211,2.8,0.75,4.236,3.632,3.549c2.479-0.592,6.547,1.215,6.159-4.072C513.528,754.032,513.979,751.251,514.136,748.493z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M444.483,822.258c-1.119-1.5-2.933-2.875-3.231-4.524
      		c-1.035-5.709-2.033-11.51-2.096-17.285c-0.048-4.487-0.891-7.104-5.793-7.854c-3.912-0.598-6.751-2.93-6.523-7.457
      		c0.052-1.021,0.573-2.019,0.879-3.027c0.93,0.504,2.394,0.789,2.702,1.546c2.139,5.253,6.458,5.247,11.153,5.486
      		c4.708,0.239,5.944-1.565,5.079-5.776c-0.2-0.971-0.356-2.019-0.192-2.974c0.141-0.814,0.77-1.545,1.183-2.312
      		c0.759,0.506,1.746,0.849,2.239,1.543c2.968,4.182,2.155,9.866-2.684,11.626c-5.484,1.996-4.216,4.715-3.41,8.862
      		c1.355,6.97,1.633,14.148,2.365,21.239C445.596,821.652,445.039,821.955,444.483,822.258z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M487.517,695.947c-1.539-1.893-2.948-3.628-4.125-5.078
      		c-2.886,1.78-5.9,5.154-8.099,4.687c-4.864-1.032-9.38-3.881-13.88-6.284c-0.748-0.399-0.703-2.286-1.024-3.485
      		c1.07-0.163,2.27-0.717,3.186-0.414c2.352,0.779,4.503,2.24,6.884,2.839c1.586,0.399,3.534,0.056,5.079-0.561
      		c0.396-0.157,0.008-2.634-0.251-4.001c-0.617-3.262-1.576-6.474-1.961-9.756c-0.139-1.18,0.971-2.507,1.51-3.767
      		c1.468,0.927,3.939,1.625,4.214,2.825c0.768,3.356,0.745,6.919,0.805,10.404c0.038,2.26,0.382,3.55,3.123,3.968
      		C488.908,688.228,489.233,689.092,487.517,695.947z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M302.151,744.747c-3.638-0.672-6.795-1.256-10.337-1.909
      		c2.283-4.229,5.956-7.085,9.411-5.419c3.485,1.682,5.738,5.99,8.318,9.312c0.182,0.235-1.183,2.294-2.166,2.714
      		c-7.777,3.317-15.619,6.489-23.5,9.553c-0.693,0.27-2.293-0.283-2.629-0.908c-0.824-1.529-1.155-3.326-1.684-5.016
      		c1.186-0.251,2.387-0.446,3.556-0.764c5.706-1.551,11.419-3.073,17.078-4.783C300.895,747.316,301.272,746.049,302.151,744.747z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M734.858,716.104c-7.401,1.96-5.433,7.623-6.684,11.63
      		c-1.109,3.553,1.836,3.992,4.232,4.704c0.464,0.138,1.237,0.484,1.238,0.737c0.024,5.324,0.381,10.708-0.363,15.934
      		c-0.206,1.447-3.628,3.146-5.759,3.453c-3.868,0.558-7.873,0.158-11.822,0.158c-0.064-0.593-0.129-1.186-0.193-1.778
      		c2.221-0.145,4.518-0.718,6.649-0.347c5.109,0.894,6.864-0.303,6.771-5.299c-0.006-0.332-0.02-0.665-0.035-0.997
      		c-0.292-5.918,0.459-12.528-8.707-12.256c1.773-3.33,3.861-6.032,4.647-9.073c0.756-2.922,0.163-6.193,0.163-10.036
      		C728.389,714.024,731.121,714.903,734.858,716.104z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M655.987,1125.758c-1.043,5.017,2.067,10.742-3.56,15.076
      		c-1.666,1.283-0.581,3.316,2.14,3.242c3.996-0.107,8.006,0.113,11.989-0.133c3.051-0.188,3.66,1.255,3.6,3.852
      		c-0.075,3.249-1.9,2.461-4.021,2.271c-5.796-0.519-11.635-0.57-17.43-1.089c-1.612-0.145-3.839-0.879-4.504-2.061
      		c-0.67-1.193-0.139-3.462,0.549-4.942c2.135-4.598,4.523-9.085,6.973-13.527c0.716-1.3,1.957-2.309,2.958-3.451
      		C655.117,1125.25,655.552,1125.504,655.987,1125.758z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M231.551,895.013c-0.178,3.054,0.34,5.82-0.582,7.969
      		c-3.764,8.772-5.291,17.191,2.608,25.109c-7.775,0.501-12.271-3.167-13.009-9.776C219.325,907.178,222.611,899.675,231.551,895.013
      		z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M261.013,960.764c-0.771-5.412-1.431-10.842-2.35-16.228
      		c-0.667-3.909-1.817-7.736-2.444-11.65c-0.19-1.182,0.649-2.528,1.017-3.798c1.149,0.873,2.878,1.515,3.338,2.664
      		c1.347,3.368,1.961,7.024,3.283,10.405c1.663,4.253,3.433,8.525,5.746,12.441c1.558,2.639,2.056,4.26-1.016,5.841
      		c-0.981,0.506-1.594,1.681-2.5,2.389c-1.285,1.005-2.686,1.86-4.037,2.779c-0.654-1.498-1.309-2.997-1.962-4.496
      		C260.395,960.995,260.704,960.879,261.013,960.764z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M182.686,820.389c0.492-1.976,0.958-3.958,1.484-5.926
      		c0.379-1.417,1.186-2.809,1.197-4.218c0.042-5.108-0.302-10.229,7.054-10.508c0.664-0.025,1.081-2.124,1.926-2.933
      		c1.062-1.016,2.446-1.695,3.691-2.52c0.745,1.534,2.411,4.178,2.098,4.438c-5.57,4.623-3.973,12.023-7.034,17.316
      		c-1.557,2.693-6.073,3.677-9.251,5.432C183.462,821.11,183.074,820.75,182.686,820.389z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M350.085,730.635c-1.346-5.064-0.057-7.659,3.821-7.527
      		c1.568,0.053,3.109,0.893,4.662,1.375c-0.536,1.31-0.926,2.707-1.644,3.908c-1.104,1.848-2.361,3.616-3.679,5.32
      		c-4.382,5.665-14.089,7.65-18.894,2.581c-2.592-2.734-2.267-8.235-3.258-12.487c1.588,0.04,3.192-0.025,4.753,0.201
      		c0.343,0.051,0.562,1.13,0.793,1.752C339.982,734.74,339.98,734.74,350.085,730.635z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M571.078,655.624c-1.438-0.993-3.074-1.793-4.275-3.02
      		c-2.761-2.813-3.896-1.152-4.733,1.508c-0.694,2.205-1.068,4.532-1.975,6.637c-0.362,0.844-1.77,1.238-2.702,1.837
      		c-0.491-0.815-1.503-1.712-1.39-2.431c0.626-3.945,1.567-7.839,2.313-11.766c0.318-1.679,0.942-3.542,0.521-5.07
      		c-0.847-3.078,3.457-9.406,6.696-9.011c0.914,0.111,2.313,1.345,2.386,2.161c0.138,1.572-0.355,3.266-0.865,4.817
      		c-1.29,3.926-3.081,7.777,3.358,9.03c0.882,0.171,1.242,3.024,1.844,4.636C571.864,655.176,571.472,655.399,571.078,655.624z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M764.016,644.633c4.129,0.801,3.94,3.401,1.399,6.749
      		c-1.955,2.578-2.128,7.441-7.651,5.112c-7.021-2.958-9.682-5.924-8.504-10.856c1.072-4.484,1.204-9.23,1.333-13.872
      		c0.029-1.056-1.662-2.16-2.562-3.241c5.596-0.786,5.175-0.716,6.006,4.364c0.318,1.957,3.04,3.52,3.705,4.232
      		c-1.714,1.942-3.863,4.379-6.015,6.816c2.626,2.281,5.251,4.562,7.876,6.844C760.933,648.929,762.262,647.078,764.016,644.633z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M432.252,705.289c0.987-0.657,1.911-1.325,2.884-1.91
      		c2.375-1.426,2.613-3.049,1.715-5.786c-0.631-1.922,0.035-4.793,1.18-6.551c0.574-0.882,3.542-0.46,5.373-0.216
      		c3.759,0.503,7.561,1.015,11.187,2.064c1.802,0.521,3.255,2.24,4.868,3.417c-0.892,1.103-1.592,2.773-2.726,3.156
      		c-1.243,0.42-2.906-0.271-4.353-0.613c-6.178-1.459-10.726,0.587-13.625,6.237C437.194,708.129,435.34,708.875,432.252,705.289z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M651.19,1094.12c0.429,2.261,0.856,4.521,1.285,6.781
      		c0.146,0.769,0.019,1.818,0.478,2.263c4.794,4.641,2.567,8.913-0.916,13.781c-1.06-6.624-5.947-8.541-10.966-10.698
      		c-3.407-1.464-6.395-3.94-9.459-6.119c-0.453-0.323-0.566-1.626-0.307-2.258c0.151-0.367,1.544-0.645,2.058-0.367
      		c2.171,1.172,4.43,2.33,6.263,3.94c2.174,1.912,3.524,1.797,5.094-0.621c1.511-2.325,3.346-4.441,5.039-6.649
      		C650.236,1094.155,650.714,1094.138,651.19,1094.12z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M203.915,1001.022c-1.491,4.447-4.614,4.241-7.851,3.702
      		c-3.206-0.534-5.597-1.97-6.022-5.554c-0.527-4.432-1.268-8.849-1.492-13.293c-0.049-0.985,1.546-2.743,2.616-2.952
      		c2.334-0.454,4.819-0.14,7.933-0.14c-1.332,3.985-2.21,7.282-3.533,10.389c-1.289,3.028-0.589,4.55,2.589,5.392
      		C200.166,999.1,202.026,1000.195,203.915,1001.022z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M518.741,847.899c5.223-6.647,6.752-12.419,1.344-18.487
      		c6.072,1.221,11.402,5.341,17.994,0.138c-1.511,5.584-2.651,9.802-3.834,14.169c-2.952-0.455-6.063-2.05-7.471,2.623
      		C526.484,847.306,522.659,847.204,518.741,847.899z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M254.304,810.423c6.797,9.226,11.178,19.386,11.788,30.949
      		c0.037,0.701-0.956,1.457-1.47,2.188c-0.886-0.833-2.23-1.51-2.575-2.526c-1.257-3.71-1.935-7.626-3.335-11.272
      		c-0.67-1.744-2.345-3.188-3.78-4.542C249.506,820.099,249.319,816.614,254.304,810.423z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M268.575,1117.147c0.422-6.508,0.927-13.013,1.226-19.526
      		c0.119-2.611,0.173-5.34-0.449-7.841c-0.717-2.881,0.661-3.312,2.84-3.943c2.519-0.731,3.133,0.396,3.609,2.498
      		c1.812,8.006,0.261,15.774-1.883,23.395c-0.629,2.234-2.865,4.017-4.364,6.005C269.228,1117.538,268.901,1117.343,268.575,1117.147
      		z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M281.863,810.368c0.566-2.156,0.688-3.55,1.281-4.701
      		c1.661-3.224,1.361-5.463-2.275-7.136c-0.891-0.409-1.648-3.566-1.255-3.864c1.331-1.009,3.582-2.218,4.805-1.713
      		c5.853,2.416,6.637,4.786,5.686,11.114c-0.404,2.691,0.445,5.555,0.516,8.345c0.035,1.371-0.406,2.755-0.632,4.134
      		c-1.532-0.354-3.475-0.247-4.503-1.164C283.86,813.933,282.848,811.797,281.863,810.368z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M712.196,696.165c4.985,0,8.932,0.145,12.861-0.039
      		c4.314-0.202,8.61-0.785,12.924-1.019c0.923-0.05,2.216,0.453,2.722,1.149c0.355,0.49-0.072,1.939-0.595,2.619
      		C733.433,707.546,717.564,704.154,712.196,696.165z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M597.875,940.055c7.413-0.834,11.641,2.282,15.435,5.642
      		c1.192,1.057,1.393,4.141,0.913,6.004c-1.34,5.212-0.765,10.047,0.781,15.122c0.911,2.987,0.445,6.396,0.599,9.613
      		c-0.937,0.074-1.872,0.148-2.809,0.223c-0.456-4.715-1.374-9.442-1.255-14.145c0.162-6.354-0.812-11.696-6.731-15.402
      		C602.432,945.624,600.742,943.041,597.875,940.055z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M180.605,856.879c2.822,4.444,5.106,8.042,7.63,12.017
      		c3.071-2.923,6.355-5.188,4.152-10.009c-0.953-2.085-0.28-3.414,2.242-3.762c2.565-0.354,3.069,0.819,3.192,3.059
      		c0.382,6.969-1.585,11.264-6.053,12.624c-4.094,1.247-8.174,2.144-11.52-1.646C176.979,865.46,178.819,861.731,180.605,856.879z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M735.892,646.163c1.179,1.814,2.061,3.238,3.013,4.612
      		c0.523,0.754,1.167,1.426,1.756,2.135c-0.849,0.688-1.885,2.107-2.516,1.946c-4.814-1.23-10.898-1.488-13.874-4.624
      		c-2.311-2.436-0.82-8.584-0.704-13.074c0.014-0.508,2.082-1.329,3.184-1.306c0.681,0.014,1.765,1.276,1.908,2.109
      		c0.308,1.772,0.117,3.63,0.134,5.453c0.055,5.737,0.057,5.739,5.444,3.488C734.837,646.652,735.425,646.372,735.892,646.163z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M354.997,743.047c4.537,0.419,8.497,1.252,7.75,6.566
      		c-0.042,0.298,0.117,0.653,0.261,0.943c0.944,1.907,3.117,4.319,2.579,5.582c-0.759,1.78-3.7,2.511-5.379,4.048
      		c-2.106,1.928-3.816,4.28-5.825,6.322c-0.913,0.928-2.136,1.551-3.216,2.312c-0.613-1.04-1.934-2.277-1.709-3.082
      		c0.699-2.502,1.587-5.158,3.17-7.139c1.252-1.565,3.766-2.052,5.491-3.328c2.521-1.868,4.122-3.869,0.696-6.718
      		C357.16,747.179,356.246,744.911,354.997,743.047z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M165.722,837.368c-0.374-3.136,1.329-4.672,5.835-2.361
      		c0.343-1.281,0.448-2.433,0.958-3.361c0.469-0.856,1.429-2.115,2.07-2.057c1.113,0.101,2.871,0.887,3.094,1.723
      		c1.32,4.952,2.368,9.985,3.315,15.027c0.469,2.493-1.07,3.046-2.929,1.937c-1.371-0.818-2.333-2.294-3.563-3.379
      		C171.946,842.645,169.333,840.457,165.722,837.368z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M261.556,806.434c-2.571-0.731-3.966-0.833-5.015-1.488
      		c-2.101-1.313-5.914-3.493-5.669-4.323c1.018-3.438,2.593-7.528,5.32-9.381c2.106-1.43,6.244,0.048,9.436,0.523
      		c0.179,0.026,0.012,3.953-0.073,3.956C258.036,796.006,259.526,800.767,261.556,806.434z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M596.559,630.944c-2.39,4.548-4.692,9.147-7.234,13.608
      		c-0.564,0.99-2.156,2.244-2.959,2.053c-1.195-0.286-2.506-1.561-3.066-2.747c-1.478-3.125-2.738-6.379-3.729-9.689
      		c-0.301-1.004,0.562-2.355,0.892-3.547c1.211,0.601,2.846,0.887,3.56,1.854c3.5,4.742,3.294,4.694,6.524-0.422
      		c0.887-1.406,3.127-1.958,4.746-2.902C595.714,629.75,596.136,630.347,596.559,630.944z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M609.098,910.469c-3.793,4.936-6.583,8.566-9.689,12.606
      		c2.057,1.26,4.453,2.728,7.353,4.505c-4.749,2.093-9.507,1.791-11.464-1.543c-3.05-5.194,0.955-9.244,5.99-12.62
      		c-3.194-1.044-5.511-1.802-7.827-2.559c0.1-0.639,0.199-1.277,0.3-1.916C598.374,909.401,602.987,909.861,609.098,910.469z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M697.451,802.94c2.582,0,5.204-0.291,7.74,0.054
      		c7.795,1.06,14.291-1.992,20.644-5.887c0.771-0.474,2.142,0.031,3.234,0.082c-0.105,1.209,0.242,2.774-0.387,3.572
      		C723.049,807.918,704.806,809.826,697.451,802.94z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M315.394,1139.184c4.203,0.968,6.569,5.643,6.427,12.684
      		c-0.135,6.696-4.593,12.425-11.066,13.662c1.601-4.513,3.693-8.691,4.47-13.101
      		C315.981,1148.137,315.389,1143.607,315.394,1139.184z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M585.022,664.363c-0.882,3.738-1.406,7.012-2.436,10.117
      		c-2.276,6.859-7.165,9.362-13.997,7.316c-0.693-0.207-1.588-1.244-1.581-1.888c0.009-0.731,0.893-2.057,1.451-2.086
      		c7.37-0.394,11.304-2.955,7.702-11.042c-0.169-0.381,1.422-2.117,2.376-2.306C580.425,664.098,582.439,664.363,585.022,664.363z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M381.83,749.805c-1.558,2.628-2.645,5.757-4.781,7.771
      		c-3.331,3.139-4.83,6.399-4.281,10.975c0.194,1.626-1.073,3.428-1.672,5.149c-1.354-1.438-2.979-2.712-3.971-4.367
      		c-0.709-1.183-0.852-2.895-0.755-4.336C366.692,760.188,375.676,750.318,381.83,749.805z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M620.162,1093.253c0.724-5.1,0.059-10.149,2.245-13.044
      		c2.127-2.814,2.778-5.581,3.896-8.494c0.115-0.302,3.279-0.134,3.518,0.442c0.754,1.818,1.481,5.479,0.997,5.696
      		c-4.635,2.074-4.03,5.929-4.051,9.735c-0.01,1.805-0.068,3.767-0.769,5.364c-0.699,1.595-2.229,2.825-3.395,4.215
      		C621.662,1095.658,620.721,1094.149,620.162,1093.253z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M595.334,1022.324c4.341,1.089,7.409,2.376,10.535,2.538
      		c7.018,0.364,11.111-4.049,11.245-11.187c0.03-1.616,0.71-3.221,1.09-4.83c1.099,0.609,3.117,1.209,3.124,1.829
      		c0.019,1.836-0.596,3.743-1.279,5.498c-0.44,1.134-1.953,1.99-2.081,3.078c-0.313,2.642,0.81,5.241-2.824,7.033
      		c-5.242,2.586-10.441,4.697-16.011,2.29C597.433,1027.839,596.741,1024.766,595.334,1022.324z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M421.153,723.687c3.837,0.365,8.021,1.997,10.366,0.582
      		c1.757-1.06,1.147-6.043,1.647-9.684c3.877,1.972,6.317,4.387,5.124,8.902c-1.107,4.192-8.056,9.226-11.771,8.295
      		C422.453,730.764,420.444,728.091,421.153,723.687z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M229.983,754.919c-3.499,1.869-6.924,3.9-10.528,5.54
      		c-1.928,0.877-4.353,0.703-6.226,1.652c-5.015,2.538-6.613-0.613-8.275-4.327c-1.356-3.034-0.318-3.842,2.672-3.802
      		c7.177,0.097,14.356,0.031,21.534,0.031C229.435,754.315,229.709,754.617,229.983,754.919z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M231.456,1074.313c-2.883-7.072-5.707-14.001-8.651-21.222
      		c2.825-2.116,5.448-1.89,7.275,2.132c1.708,3.759,3.538,7.467,5.105,11.283C237.264,1071.563,236.257,1073.173,231.456,1074.313z"
      		/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M243.698,900.23c6.839,1.386,8.324,4.555,4.287,9.724
      		c-2.172,2.781-0.284,4.313,0.834,6.529c0.854,1.691,0.542,3.971,0.749,5.987c-1.75-0.787-4.264-1.116-5.08-2.469
      		c-1.361-2.258-2.219-5.168-2.231-7.808C242.238,908.305,243.146,904.41,243.698,900.23z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M610.459,876.348c-1.91-0.968-3.143-1.414-4.082-2.207
      		c-0.202-0.171,0.414-2.002,1.054-2.366c3.657-2.086,3.454-5.159,2.663-8.591c-0.554-2.408-0.979-4.871-1.136-7.331
      		c-0.063-1.004,0.733-2.062,1.139-3.097c1.231,0.746,3.338,1.3,3.55,2.269c1.233,5.636,2.453,11.355,2.647,17.081
      		C616.34,873.469,612.458,874.965,610.459,876.348z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M317.302,733.05c0-3.956-0.114-7.917,0.108-11.86
      		c0.039-0.692,1.506-1.877,2.285-1.856c3.525,0.099,5.465,3.629,4.663,7.708c-0.629,3.196-0.712,6.527-1.603,9.636
      		c-0.561,1.955-2.165,3.611-3.3,5.402c-0.785-1.687-1.77-3.313-2.287-5.078c-0.357-1.22-0.068-2.627-0.068-3.951
      		C317.167,733.05,317.235,733.05,317.302,733.05z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M185.093,831.535c3.012-3.545,6.384-5.965,9.845-4.126
      		c2.103,1.116,2.922,4.965,3.912,7.732c0.531,1.483,0.536,3.353,0.171,4.906c-0.401,1.713-1.497,3.264-2.291,4.885
      		c-1.104-1.324-3.038-2.575-3.164-3.988C193.107,835.793,189.993,833.12,185.093,831.535z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M256.738,865.718c2.029-0.197,4.062-0.588,6.088-0.555
      		c6.821,0.111,8.261-1.718,6.209-8.128c-0.14-0.438-0.091-0.938-0.13-1.412c0.382-0.11,0.761-0.36,0.973-0.26
      		c2.416,1.155,6.081,1.79,6.931,3.68c1.63,3.626-2.333,5.211-4.734,7.118c-0.26,0.207-0.514,0.423-0.768,0.639
      		C264.975,872.169,264.292,872.178,256.738,865.718z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M264.948,770.052c1.597,9.66,9.64,6.637,15.688,8.054
      		c-0.573,2.121-1.109,4.102-1.601,5.923c-6.646,1.435-11.732-1.655-16.095-6.438C260.172,774.555,260.429,772.085,264.948,770.052z"
      		/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M241.368,1099.381c-7.499,0.082-13.78-7.723-12.01-14.17
      		c0.344-1.253,2.089-2.121,3.188-3.167c0.693,0.97,1.922,1.905,1.987,2.913c0.292,4.593,2.972,7.014,6.79,8.952
      		c1.177,0.598,1.891,2.494,2.297,3.941C243.718,1098.201,241.752,1099.131,241.368,1099.381z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M408.434,725.804c0.188,0.439,0.295,0.588,0.312,0.746
      		c1.078,9.679,1.084,9.615,10.52,10.49c1.29,0.119,2.487,1.247,3.727,1.907c-1.32,0.615-2.588,1.582-3.968,1.784
      		c-6.312,0.923-11.744-0.032-14.72-6.786C402.193,729.151,402.859,727.355,408.434,725.804z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M608.396,648.762c-0.535,0.726-1.791,2.792-2.023,2.683
      		c-1.827-0.858-4.662-1.903-4.934-3.322c-0.953-4.984-1.065-10.139-1.316-15.238c-0.028-0.576,1.158-1.759,1.659-1.696
      		c0.952,0.12,2.417,0.771,2.62,1.495C605.825,637.758,606.979,642.906,608.396,648.762z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M593.47,948.271c-0.858,2.207-1.502,3.859-2.487,6.394
      		c-4.964-4.372-2.071-9.529-3.04-14.197c6.027,0.633,10.007,3.661,12.825,8.168c0.673,1.077,0.109,2.927,0.115,4.421
      		c-1.173-0.437-2.47-0.682-3.487-1.354C596.075,950.828,594.978,949.616,593.47,948.271z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M400.562,774.537c-4.689-7.125-4.458-9.675,0.18-15.697
      		c0.973-1.263,0.696-3.418,1.361-5.01c0.579-1.385,1.59-3.514,2.561-3.598c1.57-0.138,3.71,0.751,4.729,1.953
      		c0.561,0.663-0.258,3.796-1.169,4.199C399.939,760.053,400.604,767.327,400.562,774.537z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M254.142,1098.859c7.174,0.069,6.389,4.776,6.364,8.088
      		c-0.017,2.362,1.115,6.153-2.983,6.66c-3.372,0.417-7.48-5.683-6.911-10.626C250.783,1101.491,252.91,1100.228,254.142,1098.859z"
      		/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M221.639,803.762c-0.48,5.159-7.544,11.409-12.26,10.726
      		c-0.946-0.137-2.476-1.88-2.355-2.672c0.355-2.327,0.9-6.059,2.281-6.521C213.045,804.046,217.255,804.2,221.639,803.762z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M385.765,833.595c4.966-1.615,7.056-0.463,7.204,4.447
      		c0.176,5.837,1.022,11.651,0.601,17.666C388.726,849.11,388.501,841.118,385.765,833.595z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M283.494,736.528c-1.82,2.809-3.314,5.661-5.386,8.002
      		c-0.707,0.8-2.862,0.32-4.356,0.425c0.148-1.18,0.343-2.355,0.432-3.54c0.125-1.661,0.17-3.329,0.253-4.994
      		c0.092-1.847,0.188-3.694,0.283-5.542c2.087,0.142,4.401-0.213,6.178,0.604C282.044,732.01,282.4,734.254,283.494,736.528z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M452.566,739.31c-4.507-4.684-8.79-2.708-14.195,1.155
      		c0.697-4.458,0.53-9.522,1.722-9.865c3.611-1.038,7.95-0.922,11.574,0.176c1.381,0.418,1.588,4.717,2.332,7.248
      		C453.521,738.452,453.043,738.881,452.566,739.31z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M621.403,846.134c-0.357-3.725-1.463-7.597-0.772-11.115
      		c0.37-1.888,3.64-3.615,5.95-4.588c1.927-0.811,4.363-0.501,6.563-0.414c0.207,0.008,0.666,2.062,0.404,2.998
      		c-0.226,0.802-1.209,1.719-2.042,1.968c-5.67,1.696-8.897,5.251-8.589,11.715C622.413,846.509,621.908,846.321,621.403,846.134z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M607.395,1052.086c-1.877-2.212-4.281-3.788-4.178-5.176
      		c0.248-3.314,1.008-6.984,2.798-9.693c2.317-3.509,4.574,0.466,6.665,1.416c0.786,0.357,1.5,2.764,1.055,3.602
      		C612.037,1045.429,609.853,1048.363,607.395,1052.086z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M688.964,556.659c-1.843,3.488-6.642,2.442-10.088,3.798
      		c-0.56,0.22-3.829-3.68-3.452-4.372c1.156-2.123,3.357-4.865,5.372-5.055C683.855,550.741,688.633,549.775,688.964,556.659z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M205.567,869.393c-1.405-4.544-3.002-9.043-4.1-13.66
      		c-0.345-1.45,0.654-3.221,1.037-4.843c1.489,1.037,3.673,1.758,4.324,3.18c1.415,3.095,2.506,6.503,2.823,9.869
      		c0.181,1.918-1.423,4.004-2.223,6.015C206.808,869.767,206.188,869.579,205.567,869.393z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M328.309,679.815c-1.311-3.352-2.31-6.661-3.885-9.667
      		c-1.044-1.993-2.981-3.501-4.385-5.329c-0.734-0.955-1.183-2.13-1.76-3.207c1.821-0.818,3.963-2.686,5.402-2.248
      		c3.624,1.105,7.965,15.692,6.175,19.324C329.668,679.07,329.093,679.26,328.309,679.815z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M247.139,984.604c-2.13-3.831-3.879-6.977-5.748-10.336
      		c2.99-1.132,5.953-2.597,9.086-3.276c1.308-0.283,3.13,0.901,4.39,1.854c0.377,0.284-0.038,2.272-0.633,3.076
      		C252.167,978.708,249.861,981.317,247.139,984.604z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M625.025,1000.963c-2.253-0.366-5.876-0.953-9.106-1.477
      		c7.158-1.931,7.066-1.946,8.606-11.196c0.27-1.615,1.37-3.872,2.638-4.341c1.542-0.57,3.668,0.438,5.542,0.764
      		c-0.535,1.849-0.812,3.825-1.659,5.518C629.318,993.684,627.283,996.984,625.025,1000.963z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M711.079,657.163c-4.915-0.93-8.889-1.681-13.049-2.467
      		c0.315-1.165,0.312-2.117,0.773-2.694c1.938-2.419,3.684-6.194,6.118-6.746c4.084-0.926,3.039,4.048,4.385,6.354
      		C710.104,652.974,710.317,654.68,711.079,657.163z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M586.249,884.37c7.341,4.623,3.699,11.796,5.904,17.276
      		c-8.67,1.19-9.093,0.731-7.32-7.352c0.354-1.613,0.623-3.246,0.86-4.88C585.906,887.953,586.021,886.478,586.249,884.37z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M719.899,624.538c5.761-0.819,11.511-1.731,17.295-2.323
      		c0.622-0.063,1.683,1.646,2.056,2.702c0.194,0.552-0.572,2.061-1.067,2.141c-5.068,0.821-10.157,1.603-15.271,1.965
      		c-0.979,0.069-2.09-1.742-3.14-2.686C719.814,625.737,719.856,625.137,719.899,624.538z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M570.839,841.769c-1.229,4.693-5.151,5.888-8.782,4.996
      		c-2.683-0.658-5.251-3.364-6.994-5.788c-1.463-2.034,0.061-3.37,2.573-2.718C561.878,839.359,566.108,840.508,570.839,841.769z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M581.325,841.222c-0.219-2.925-0.34-4.396-0.437-5.866
      		c-0.4-6.073,4.205-9.506,10.074-7.393c0.975,0.352,1.708,1.374,2.554,2.087c-0.656,0.625-1.308,1.254-1.969,1.874
      		c-1.829,1.718-3.649,3.445-5.5,5.14C584.797,838.21,583.502,839.309,581.325,841.222z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M230.338,815.209c-3.465-8.96-2.304-11.144,9.498-17.538
      		c0.503,8.001-6.172,11.832-9.645,17.406L230.338,815.209z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M556.848,830.951c3.709-1.526,7.002-4.082,8.579-3.277
      		c3.246,1.657,6.274,4.724,8.069,7.947c1.473,2.645-1.646,2.659-3.229,1.968C566.236,835.826,562.356,833.712,556.848,830.951z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M496.545,796.221c0.928,1.468,2.502,2.867,2.665,4.415
      		c0.551,5.232,0.658,10.517,0.779,15.783c0.017,0.737-0.832,1.494-1.281,2.241c-0.804-0.683-2.225-1.293-2.315-2.061
      		c-0.785-6.597-1.329-13.222-1.946-19.838C495.147,796.581,495.846,796.401,496.545,796.221z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M253.22,940.771c-3.444,3.614-6.018,6.313-8.592,9.014
      		c-1.39-1.427-4.217-3.438-3.917-4.177c1.181-2.906,2.961-5.894,5.353-7.809C246.911,937.121,250.141,939.42,253.22,940.771z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M567.212,859.426c2.092-2.769,3.446-6.257,5.703-6.998
      		c1.717-0.562,4.579,2.362,6.932,3.736c-2.999,2.355-5.954,4.774-9.101,6.914C570.55,863.212,568.863,861.153,567.212,859.426z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M468.031,797.72c4.503,6.778,4.557,13.867,3.803,21.448
      		C467.055,816.244,464.75,803.312,468.031,797.72z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M456.795,853.504c-4.558-0.594-9.216-0.823-13.604-2.031
      		c-1.126-0.309-1.402-3.706-2.068-5.685c1.502-0.212,3.273-1.039,4.461-0.523c4.027,1.748,7.848,3.972,11.747,6.015
      		C457.152,852.02,456.973,852.762,456.795,853.504z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M254.355,732.367c-4.477-2.544-8.972-10.192-8.208-13.583
      		c0.349-1.546,1.834-2.837,2.804-4.244c1.081,1.159,2.266,2.242,3.197,3.511c0.447,0.609,0.473,1.562,0.587,2.374
      		C253.26,724.138,253.747,727.854,254.355,732.367z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M596.901,832.553c8.973-3.121,13.292-1.884,14.64,3.902
      		c-4.047-0.584-6.532,0.868-6.875,5.204c-0.05,0.631-1.478,1.153-2.268,1.726c-0.364-0.932-1.304-2.165-0.999-2.748
      		C603.592,836.445,601.567,834.263,596.901,832.553z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M465.889,729.347c-2.154-0.358-4.004-0.667-6.637-1.104
      		c5.291-3.697,1.419-7.707,1.467-11.508c0.02-1.606-2.31-4.013,1.421-4.492c3.23-0.415,3.89,1.293,3.79,4.097
      		C465.777,720.619,465.889,724.906,465.889,729.347z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M470.86,791.835c-4.172,2.968-8.338,1.715-10.509-1.077
      		c-1.64-2.107-0.84-6.2-0.818-9.407c0.003-0.419,1.832-1.125,2.822-1.139c0.721-0.011,2.138,1.245,2.084,1.353
      		c-2.995,5.93,2.609,6.717,5.4,9.159C470.085,790.938,470.286,791.206,470.86,791.835z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M726.01,683.302c-7.877-2.693-10.535-5.794-9.769-10.724
      		c0.161-1.035,1.2-2.665,1.94-2.725c1.444-0.117,3.942,0.297,4.282,1.166C723.921,674.741,724.754,678.709,726.01,683.302z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M385.331,772.646c-1.393-4.889-2.824-9.768-4.104-14.686
      		c-0.1-0.385,0.969-1.56,1.316-1.489c1.489,0.303,3.862,0.587,4.199,1.505c1.669,4.563,3.443,9.312,0.691,14.091
      		C386.732,772.261,386.032,772.454,385.331,772.646z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M282.313,899.613c-9.977,1.639-11.854-0.928-11.142-15.503
      		C275.26,889.801,278.573,894.41,282.313,899.613z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M484.913,732.005c-2.218-0.702-4.437-1.404-6.655-2.105
      		c2.016-1.287,3.971-2.687,6.073-3.813c1.135-0.608,2.588-1.186,3.78-1.004c2.108,0.321,4.127,1.232,6.185,1.896
      		c-1.915,1.52-3.722,3.216-5.795,4.475c-0.848,0.516-2.269,0.085-3.43,0.085C485.019,731.693,484.966,731.85,484.913,732.005z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M220.254,887.063c0.059-6.048,3.32-5.867,6.566-6.139
      		c3.088-0.259,4.513,2.445,3.688,3.713c-1.664,2.552-4.623,4.259-7.046,6.313C222.144,889.354,220.827,887.758,220.254,887.063z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M241.132,993.358c-3.382-4.039-8.788-5.465-7.918-11.367
      		c0.169-1.149,0.953-2.487,1.877-3.124c0.437-0.302,2.071,0.641,2.822,1.37C241.295,983.524,243.186,987.325,241.132,993.358z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M305.296,716.207c2.913,1.727,6.029,2.482,6.513,4.048
      		c2.262,7.337,1.229,10.044-3.595,12.376C307.353,727.783,306.499,722.978,305.296,716.207z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M675.421,820.5c1.891,0.933,3.884,1.247,4.553,2.358
      		c3.312,5.507,3.004,11.018-1.394,17.055C677.512,833.347,676.561,827.499,675.421,820.5z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M524.898,817.774c-4.37-3.522-5.143-8.423-3.404-19.803
      		C525.088,800.612,526.832,810.245,524.898,817.774z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M243.471,787.107c-2.399-0.287-4.849-0.358-7.188-0.906
      		c-4.908-1.15-6.305-3.434-5.116-8.123c0.196-0.774,1.003-1.748,1.717-1.945c0.822-0.229,2.656,0.24,2.677,0.514
      		c0.402,5.17,4.443,7.095,8.684,8.884C243.987,786.056,243.729,786.582,243.471,787.107z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M338.455,759.825c0.373,4.979,1.001,9.506,0.933,14.024
      		c-0.034,2.322-1.739,3.001-3.707,1.105C332.289,771.688,333.394,762.901,338.455,759.825z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M224.716,705.692c-3.849-1.656-7.772-3.168-11.479-5.097
      		c-0.697-0.362-1.129-3.088-0.644-3.5c1.135-0.963,3.556-2.088,4.298-1.55c3.251,2.357,6.09,5.283,9.078,8.003
      		C225.551,704.263,225.133,704.978,224.716,705.692z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M355.746,837.736c-6.506-1.416-6.037,2.475-5.998,6.435
      		c0.03,3.104,1.071,7.134-4.062,7.885c0.878-5.314,1.634-10.292,2.547-15.24c0.386-2.085,0.898-4.663,3.909-3.654
      		c1.492,0.499,2.615,2.098,3.905,3.198C355.947,836.818,355.846,837.277,355.746,837.736z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M503.007,790.97c-3.087-1.105-6.737-1.532-9.105-3.501
      		c-2.531-2.104-4.231-5.605-1.91-8.781c3.731,3.567,7.488,7.161,11.247,10.754C503.161,789.951,503.084,790.461,503.007,790.97z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M447.699,760.257c0.143-1.68,0.027-2.576,0.319-3.311
      		c0.748-1.877,1.677-3.682,2.535-5.515c1.682,1.39,3.867,2.5,4.854,4.275c0.584,1.05-0.646,3.182-1.228,4.766
      		c-0.205,0.559-0.833,1.087-1.398,1.361c-1.4,0.679-2.875,1.202-4.319,1.789C448.147,762.234,447.832,760.846,447.699,760.257z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M703.298,675.008c-0.56,3.716-0.916,6.986-1.631,10.174
      		c-0.173,0.772-1.485,1.706-2.346,1.787c-0.844,0.079-2.19-0.604-2.611-1.35C693.451,679.841,695.477,676.22,703.298,675.008z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M691.717,1149.781c-1.054,1.646-1.311,2.595-1.821,2.761
      		c-6.307,2.043-13.684-4.097-12.684-10.549c0.097-0.626,0.829-1.525,1.385-1.622c0.86-0.149,2.297-0.024,2.653,0.525
      		c1.05,1.621,2.047,3.436,2.425,5.305C684.893,1152.234,684.942,1152.354,691.717,1149.781z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M487.562,835.182c-2.311,0-4.622,0-7.445,0c0.285,1.902,0.573,3.789,0.849,5.679
      		c0.385,2.618,1.449,3.771,4.372,2.778c1.206-0.409,2.817,0.373,4.244,0.614c-0.067,0.528-0.135,1.058-0.202,1.586
      		c-3.355,0-6.709,0-10.725,0c0.023,0.058-0.412-0.644-0.549-1.398C475.611,830.646,474.98,833.487,487.562,835.182z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M216.674,1047.696c-6.063,0.654-9.048-1.423-10.769-5.169
      		c-0.523-1.14-0.197-3.838,0.44-4.096c1.331-0.539,3.834-0.545,4.595,0.327C213.028,1041.156,214.464,1044.122,216.674,1047.696z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M545.354,650.313c-1.034-1.37-2.44-2.6-3.025-4.14
      		c-1.044-2.75-1.767-5.66-2.257-8.564c-0.189-1.122,0.618-2.411,0.972-3.624c1.206,0.932,3.147,1.653,3.482,2.833
      		c1.153,4.06,1.745,8.28,2.551,12.439C546.503,649.609,545.929,649.96,545.354,650.313z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M407.974,775.256c0.215-4.121,0.197-8.277,0.804-12.34
      		c0.159-1.07,2.25-1.852,3.453-2.765c0.551,1.253,1.744,2.626,1.536,3.736c-0.737,3.935-1.954,7.779-2.988,11.658
      		C409.843,775.449,408.908,775.353,407.974,775.256z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M510.328,822.35c0.708,2.776,1.577,7.2,3.011,11.434
      		c0.873,2.575-0.183,3.31-2.21,3.734c-1.848,0.388-4.106,1.429-4.34-1.881c-0.102-1.421-0.354-2.971,0.071-4.262
      		C508.005,827.893,509.478,824.516,510.328,822.35z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M265.852,883.63c-1.597,1.352-2.57,2.605-3.846,3.156
      		c-2.016,0.871-4.22,1.388-6.395,1.683c-0.214,0.028-1.476-3.419-1.067-3.738c2.525-1.973,5.337-3.621,8.225-5.023
      		C263.042,879.574,264.502,881.885,265.852,883.63z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M207.141,891.887c-0.244-3.112-0.608-5.521-0.535-7.918
      		c0.03-1,1.15-2.874,1.505-2.812c2.241,0.392,4.485,1.061,6.506,2.078c0.277,0.141-0.259,3.125-1.164,4.102
      		C211.956,888.949,209.853,889.999,207.141,891.887z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M681.958,648.808c-4.164-2.165-6.727-11.487-4.312-14.071
      		c0.352-0.376,1.409-0.63,1.735-0.391c0.968,0.71,2.392,1.646,2.461,2.581C682.122,640.684,681.958,644.474,681.958,648.808z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M260.43,1057.249c-1.172,1.521-2.344,3.042-3.516,4.562
      		c-1.921-2.668-3.842-5.337-5.763-8.004c1.503-0.748,2.965-2.02,4.516-2.14C259.351,1051.382,259.568,1051.863,260.43,1057.249z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M432.349,756.175c0.938,4.585,1.876,9.172,2.815,13.757
      		c-0.43,0.306-0.86,0.612-1.291,0.918c-1.895-1.983-4.882-3.72-5.401-6.014c-0.607-2.685,0.937-5.856,1.546-8.816
      		C430.794,756.071,431.571,756.123,432.349,756.175z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M467.288,760.516c-2.669-5.147-0.531-10.369,0.22-15.553
      		c0.043-0.302,1.366-0.803,1.542-0.635c0.725,0.692,1.793,1.65,1.734,2.436c-0.348,4.643-0.976,9.266-1.51,13.895
      		C468.612,760.61,467.95,760.562,467.288,760.516z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M309.44,877.362c6.217,4.66,6.73,8.237,1.959,13.632
      		C307.402,886.403,306.807,882.263,309.44,877.362z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M207.614,902.005c4.709-0.344,4.461,4.155,3.85,5.314
      		c-0.91,1.724-3.836,2.514-6.047,3.303c-0.415,0.148-2.297-1.798-2.131-2.396C204.005,905.641,202.608,901.16,207.614,902.005z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M450.852,724.506c-0.472-4.25-1.011-8.495-1.323-12.757
      		c-0.037-0.499,1.594-1.658,1.742-1.549c1.214,0.894,3.173,2.004,3.189,3.072c0.053,3.707-0.589,7.422-0.966,11.135
      		C452.614,724.439,451.733,724.473,450.852,724.506z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M215.371,948.996c2.933-2.146,4.897-4.154,7.278-5.025
      		c0.879-0.321,2.739,2.04,4.152,3.179c-2.379,2.19-4.757,4.381-7.136,6.571C218.578,952.523,217.49,951.327,215.371,948.996z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M283.778,827.612c-2.356,1.841-3.447,3.401-4.708,3.555
      		c-4.803,0.581-4.399-3.522-4.622-6.321c-0.065-0.82,3.081-2.942,3.875-2.573C280.127,823.111,281.384,825.125,283.778,827.612z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M436.672,842.556c-3.138,2.765-5.678,5.004-8.218,7.242
      		c-1.05-1.71-3.014-3.458-2.946-5.121C425.627,841.831,430.748,840.617,436.672,842.556z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M277.931,925.317c-1.72,1.484-3.44,2.97-5.16,4.453
      		c-1.397-2.336-2.794-4.674-4.191-7.011c1.42-0.766,2.773-1.974,4.274-2.196C275.295,920.201,277.61,920.731,277.931,925.317z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M283.223,859.48c4.594-0.521,10.905,3.3,11.605,6.289
      		c0.16,0.683-0.218,2.14-0.436,2.157c-1.571,0.126-3.78,0.612-4.634-0.212C287.401,865.442,285.606,862.585,283.223,859.48z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M641.284,839.166c2.759,2.215,5.516,4.43,8.274,6.643
      		c-0.248,0.633-0.495,1.266-0.743,1.898c-2.638,0.307-6.497,1.796-7.616,0.638c-1.583-1.64-1.143-5.233-1.566-7.992
      		C640.184,839.957,640.734,839.562,641.284,839.166z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M211.081,991.196c-0.744-2.17-1.632-4.305-2.178-6.523
      		c-0.318-1.29-0.501-3.699-0.029-3.898c1.419-0.596,3.368-0.807,4.757-0.264c0.815,0.319,1.457,2.425,1.3,3.609
      		c-0.335,2.539-1.24,5.002-1.912,7.495C212.373,991.477,211.728,991.336,211.081,991.196z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M281.73,1130.816c3.008,0.179,5.112,0.266,3.348,3.761
      		c-0.568,1.124,0.288,2.959,0.467,4.472c0.144,1.205,0.249,2.415,0.371,3.623c-1.5-0.653-3.598-0.908-4.359-2.053
      		c-1.134-1.701-1.155-4.1-1.954-6.08C278.419,1131.602,279.322,1130.51,281.73,1130.816z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M212.829,768.94c4.266,0.907,8.329-1.255,9.094,3.808
      		c0.478,3.157-1.41,5.536-4.496,5.072C212.317,777.052,215.319,772.344,212.829,768.94z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M299.813,882.213c-3.479,2.104-5.744,3.657-8.213,4.743
      		c-0.316,0.139-2.449-2.153-2.45-3.32c-0.004-3.545,2.601-5.305,5.782-5.371C296.128,878.239,297.363,880.147,299.813,882.213z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M320.013,768.058c-0.851,2.102,1.94,6.366-3.427,6.14
      		c-0.733-0.031-2.07-0.949-2.041-1.384c0.199-2.942,0.507-5.91,1.199-8.767c0.201-0.831,1.768-1.331,2.709-1.983
      		c0.52,0.943,1.223,1.836,1.506,2.846C320.215,765.825,320.013,766.868,320.013,768.058z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M317.217,837.233c-1.702-6.646,1.65-10.413,6.632-13.957
      		c-0.555,4.407-0.735,8.332-1.728,12.039C321.864,836.277,319.068,836.559,317.217,837.233z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M634.77,1057.11c1.594,2.767,3.683,6.054,5.4,9.525
      		c0.832,1.68,0.965,3.704,1.414,5.572c-1.09,0.102-2.613,0.701-3.185,0.205c-1.438-1.249-3.252-2.888-3.469-4.559
      		C634.425,1063.956,634.77,1059.949,634.77,1057.11z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M518.183,771.626c6.363,2.075,9.456,5.916,7.079,12.729
      		C518.132,782.655,521.787,775.166,518.183,771.626z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M506.142,701.033c-4.649-0.089-1.346-10.319-9.156-7.655
      		c1.146-1.998,1.519-3.568,2.463-4.082c1.521-0.825,3.414-1.135,5.165-1.193c0.294-0.01,0.842,2.331,0.967,3.615
      		C505.857,694.564,505.939,697.429,506.142,701.033z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M199.609,884.813c-1.215,1.037-1.882,1.714-2.652,2.24
      		c-1.75,1.197-3.494,2.428-5.356,3.427c-0.833,0.447-2.074,0.727-2.892,0.428c-0.69-0.252-1.484-1.493-1.407-2.208
      		c0.07-0.65,1.162-1.432,1.96-1.715c1.888-0.669,4.026-0.982,2.208-3.756c1.313-0.431,2.737-1.397,3.911-1.154
      		C196.782,882.364,197.969,883.689,199.609,884.813z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M293.067,855.823c4.069-2.682,7.107-4.705,9.1,0.14
      		c0.317,0.772,2.958,0.588,5.018,0.928c-0.314,1.122-0.673,2.4-1.07,3.819c-0.821-0.208-1.393-0.181-1.683-0.454
      		c-1.899-1.781-3.651-2.885-6.616-1.756C296.731,858.914,294.679,856.79,293.067,855.823z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M657.011,843.98c-4.327-3.842-7.829-6.951-11.481-10.193
      		C651.813,830.67,656.136,834.231,657.011,843.98z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M609.647,899.723c-5.015,1.358-9.05,2.046-12.439-1.003
      		c-1.029-0.925-0.909-3.13-1.314-4.749c1.421,0.193,2.989,0.069,4.234,0.645C603.096,895.986,605.912,897.688,609.647,899.723z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M319.635,806.699c-1.612,2.48-2.95,4.537-4.287,6.594
      		c-0.862-2.384-2.273-4.742-2.385-7.161c-0.07-1.521,1.808-3.134,2.812-4.706C316.911,802.977,318.045,804.527,319.635,806.699z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M479.219,817.739c0.953-5.089,1.148-9.022,5.92-9.32
      		c0.435-0.027,1.848,4.024,1.54,5.982C485.878,819.512,481.994,815.279,479.219,817.739z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M263.502,1065.235c5.896,2.239,7.652,5.164,6.23,9.178
      		c-0.254,0.716-4.949,0.765-5.012,0.502C264.052,1072.124,263.904,1069.207,263.502,1065.235z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M276.341,1118.815c2.098-2.139,3.496-3.563,4.894-4.989
      		c1.109,1.146,3.292,2.49,3.125,3.4c-0.473,2.576-1.91,4.974-2.966,7.44C279.938,1122.982,278.482,1121.296,276.341,1118.815z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M350.497,664.572c-1.271-2.186-2.76-4.28-3.749-6.588
      		c-0.862-2.015-0.778-3.898,2.293-4.21c2.77-0.281,4.164,0.526,3.879,3.461c-0.22,2.27-0.421,4.542-0.631,6.813
      		C351.692,664.223,351.094,664.397,350.497,664.572z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M226.951,1008.034c1.681,2.175,3.823,3.735,3.742,5.169
      		c-0.088,1.566-2.235,3.018-3.483,4.52c-1.58-1.655-3.562-3.117-4.496-5.08C222.428,1012.041,225.019,1010.071,226.951,1008.034z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M536.367,743.366c0,4.4,0.057,8.802-0.104,13.196
      		c-0.014,0.376-1.735,1.187-2.218,0.937c-0.831-0.432-1.932-1.624-1.833-2.349c0.547-4.001,1.422-7.956,2.185-11.928
      		C535.054,743.271,535.71,743.318,536.367,743.366z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M287.695,787.151c3.288,0,6.02-0.43,8.489,0.189
      		c1.154,0.289,1.8,2.605,2.676,4.003c-1.075,0.444-2.184,1.331-3.218,1.25C292.385,792.339,289.058,791.869,287.695,787.151z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M296.94,1147.624c4.45,3.455,3.517,6.483,0.473,9.069
      		c-0.717,0.609-3.282,0.328-3.895-0.433C289.615,1151.424,295.734,1150.737,296.94,1147.624z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M405.497,795.191c-4.737,0.582-7.982-3.069-9.328-10.492
      		C400.631,785.168,401.623,786.283,405.497,795.191z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M616.451,916.564c0.407,3.56,1.073,7.118,1.071,10.679
      		c0,1.142-1.767,2.282-2.725,3.423c-0.583-1.088-1.722-2.214-1.649-3.256c0.257-3.699,0.926-7.368,1.438-11.049
      		C615.208,916.43,615.829,916.497,616.451,916.564z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M331.23,693.479c-1.921,3.327-3.673,6.363-5.427,9.399
      		c-0.568-0.003-1.138-0.007-1.706-0.01c-0.495-2.267-1.92-4.847-1.285-6.734C323.981,692.658,327.25,692.317,331.23,693.479z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M238.284,770.794c-3.289-0.88-7.356-1.047-7.021-6.03
      		c0.068-1.001,2.399-1.85,3.687-2.768c1.599,2.612,3.199,5.224,4.798,7.836C239.259,770.152,238.772,770.474,238.284,770.794z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M214.167,719.044c5.637,1.476,7.366,6.018,4.466,9.807
      		c-0.391,0.51-1.601,0.983-2.036,0.746c-0.946-0.514-2.217-1.429-2.321-2.303C213.97,724.717,214.167,722.079,214.167,719.044z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M340.382,794.223c2.321-5.185,4.111-9.185,5.916-13.216
      		C350.9,785.258,348.997,790.494,340.382,794.223z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M399.352,847.08c-0.4-3.529-0.86-7.055-1.131-10.596
      		c-0.037-0.49,1.188-1.561,1.273-1.506c1.145,0.742,3.046,1.58,3.107,2.502c0.205,3.14-0.248,6.322-0.45,9.487
      		C401.218,847.005,400.284,847.043,399.352,847.08z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M302.053,708.017c-1.839,1.017-2.779,1.973-3.736,1.992
      		c-5.234,0.101-2.873-4.412-3.769-6.876c-0.142-0.39,1.963-1.594,3.029-2.423C298.906,702.879,300.236,705.05,302.053,708.017z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M172.274,774.573c-0.059,0.044-1.011,1.33-2.036,1.391
      		c-2.797,0.164-4.956-1.182-4.51-4.309c0.191-1.343,1.547-3.474,2.542-3.572C171.51,767.76,172.973,769.768,172.274,774.573z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M205.39,773.162c-2.144,1.248-3.562,2.685-5.009,2.714
      		c-1.042,0.021-3.199-2.39-3.008-2.758c1.106-2.138,2.761-3.99,4.23-5.939C202.621,768.786,203.638,770.394,205.39,773.162z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M300.002,667.99c-1.238-0.353-2.596-0.492-3.688-1.104
      		c-2.117-1.184-4.084-2.635-6.114-3.976c0.827-0.734,1.541-1.853,2.501-2.136c3.516-1.04,5.619,1.041,6.982,3.893
      		c0.458,0.958,0.145,2.284,0.182,3.445C299.864,668.116,300.002,667.99,300.002,667.99z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M629.729,1116.618c-2.091-1.801-3.94-3.393-5.789-4.985
      		c1.124-0.905,2.752-2.812,3.284-2.545c2.354,1.187,4.391,2.998,6.542,4.583C632.707,1114.444,631.648,1115.218,629.729,1116.618z"
      		/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M497.893,618.46c0.915,4.981,1.553,8.453,2.191,11.925
      		c-0.581,0.289-1.163,0.578-1.744,0.868c-1.736-2.856-3.667-5.626-5.006-8.657C493.128,622.131,495.635,620.464,497.893,618.46z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M259.615,736.379c1.957,2.401,3.865,4.481,5.43,6.793
      		c0.628,0.93,0.501,2.369,0.718,3.577c-1.402-0.112-3.491,0.316-4.084-0.436C259.521,743.582,256.291,740.821,259.615,736.379z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M628.201,885.511c0.358-3.659,0.801-7.313,1.046-10.98
      		c0.189-2.816,0.253-2.887,6.133-1.792c-2.205,4.636-4.319,9.081-6.433,13.524C628.699,886.012,628.45,885.762,628.201,885.511z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M542.031,713c1.345,0.399,2.871,0.458,3.042,0.969
      		c0.87,2.611,1.517,5.32,1.904,8.042c0.063,0.444-1.557,1.129-2.399,1.702c-0.848-2.173-1.845-4.303-2.486-6.536
      		C541.753,715.997,542.031,714.64,542.031,713z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M337.249,883.422c-1.864,1.982-2.961,3.148-4.058,4.313
      		c-1.356-1.715-3.014-3.297-3.871-5.232c-0.232-0.526,2.315-3.223,2.959-3.011C333.894,880.022,335.151,881.646,337.249,883.422z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M360.086,642.33c-1.485,2.255-2.538,3.854-3.59,5.452
      		c-1.143-1.82-2.915-3.548-3.2-5.495c-0.179-1.215,1.885-2.759,2.942-4.156C357.398,639.396,358.558,640.661,360.086,642.33z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M663.525,610.435c-1.826,1.364-3.091,2.376-3.132,2.33
      		c-1.843-2.132-3.689-4.287-5.203-6.651c-0.215-0.335,1.248-1.743,1.936-2.654C659.056,605.562,660.984,607.666,663.525,610.435z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M225.961,1002.886c-0.445-3.38-0.89-6.76-1.564-11.881
      		c2.958,2.031,6.107,3.235,6.082,4.37c-0.058,2.638-1.646,5.24-2.614,7.857C227.231,1003.117,226.596,1003.001,225.961,1002.886z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M714.631,782.437c-0.152-3.206-0.306-6.413-0.458-9.619
      		c0.529-0.306,1.058-0.611,1.586-0.917c1.291,1.828,3.519,3.616,3.604,5.5c0.082,1.826-2.037,3.751-3.189,5.632
      		C715.658,782.834,715.145,782.635,714.631,782.437z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M512.066,773.857c0.967,2.868,2.094,5.701,2.762,8.637
      		c0.129,0.569-1.468,2.044-2.391,2.166c-0.652,0.087-2.079-1.343-2.176-2.206c-0.293-2.607-0.105-5.271-0.105-7.912
      		C510.793,774.313,511.43,774.086,512.066,773.857z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M287.453,1104.19c2.205,2.658,4.272,4.738,4.077,4.979
      		c-1.423,1.751-3.301,3.131-5.036,4.627c-0.576-1.29-1.666-2.61-1.593-3.863C284.985,1108.482,286.092,1107.094,287.453,1104.19z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M186.169,779.36c-1.525-2.854-3.05-5.708-4.728-8.849
      		C189.474,769.583,189.474,769.583,186.169,779.36z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M693.839,615.022c-7.575,1.358-9.774-0.659-8.876-8.154
      		C688.09,609.74,690.688,612.126,693.839,615.022z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M337.579,827.416c-1.91-0.754-4.168-1.121-5.601-2.407
      		c-0.864-0.775-0.688-3.013-0.491-4.53c0.095-0.73,1.242-1.666,2.055-1.84c0.53-0.113,1.566,0.847,1.942,1.556
      		c1.049,1.979,1.874,4.081,2.787,6.135C338.041,826.691,337.811,827.054,337.579,827.416z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M223.266,800.549c-3.516,0.7-7.127,2.845-7.095-2.005
      		c0.01-1.479,1.558-2.95,2.401-4.426C220.137,796.262,221.702,798.405,223.266,800.549z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M670.159,650.257c-1.147-2.576-2.444-5.102-3.356-7.759
      		c-0.293-0.853,0.431-2.054,0.689-3.094c1.158,0.572,3.107,0.929,3.333,1.756c0.748,2.748,0.878,5.666,1.244,8.518
      		C671.433,649.872,670.796,650.065,670.159,650.257z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M514.669,707.375c3.393,3.201,4.832,6.909,3.414,12.09
      		C513.374,716.021,513.373,711.88,514.669,707.375z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M720.373,616.137c-2.8-2.049-7.045-2.349-5.951-6.984
      		c0.161-0.68,2.53-1.586,3.384-1.224C721.19,609.368,722.385,611.981,720.373,616.137z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M419.079,789.822c-0.974-3.379-2.036-6.738-2.807-10.163
      		c-0.097-0.426,1.44-1.778,2.059-1.681c1.017,0.161,2.646,1.088,2.714,1.813c0.298,3.196,0.129,6.437,0.129,9.661
      		C420.475,789.576,419.777,789.699,419.079,789.822z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M295.615,829.888c1.944,1.281,3.239,2.135,4.534,2.987
      		c-1.914,1.107-3.726,2.714-5.78,3.131c-1.205,0.244-2.795-1.406-4.211-2.204C291.851,832.587,293.544,831.373,295.615,829.888z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M644.043,1087.962c-1.146,0.863-2.881,2.689-3.329,2.415
      		c-1.528-0.938-3.024-2.458-3.633-4.091c-0.222-0.596,2.065-3.018,3.225-3.029c1.735-0.019,3.485,1.356,5.228,2.139
      		C645.037,1086.251,644.54,1087.106,644.043,1087.962z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M359.717,785.832c-1.172-1.671-2.503-3.296-2.374-3.422
      		c1.652-1.61,3.39-3.242,5.407-4.294c0.518-0.27,2.081,1.469,3.164,2.284C364.047,782.035,362.183,783.671,359.717,785.832z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M268.215,714.1c1.83-0.307,3.284-0.803,4.703-0.719
      		c1.53,0.092,3.027,0.729,4.539,1.128c-0.624,1.322-0.997,3.509-1.926,3.779c-1.651,0.479-3.778,0.135-5.43-0.54
      		C269.23,717.392,268.943,715.607,268.215,714.1z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M266.385,1084.58c-4.027,0.9-6.982,0.171-8.101-3.803
      		c-0.177-0.63,0.816-2.25,1.416-2.332C264.099,1077.843,265.834,1080.498,266.385,1084.58z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M168.816,810.567c1.408-2.541-2.277-6.836,3.265-7.376
      		c0.869-0.085,2.521,0.935,2.686,1.688C175.729,809.3,172.613,810.068,168.816,810.567z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M214.01,945.536c-5.096-3.236-5.427-9.912-1.093-12.667
      		C213.275,937.021,213.605,940.846,214.01,945.536z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M651.083,637.123c-0.695,1.608-0.952,3.103-1.824,4.001
      		c-1.248,1.286-2.945,2.137-4.45,3.173c-0.398-1.297-1.476-2.933-1.037-3.816c0.688-1.386,2.294-2.44,3.72-3.285
      		C648.206,636.773,649.38,637.123,651.083,637.123z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M615.919,1071.12c-0.872-2.641-1.743-5.282-2.615-7.924
      		c1.36-0.359,2.722-0.719,4.084-1.078c0.223,2.83,0.446,5.661,0.669,8.491C617.344,1070.779,616.632,1070.949,615.919,1071.12z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M428.772,819.775c-0.311-1.75-1.317-3.785-0.749-5.177
      		c0.651-1.597,2.699-2.621,4.14-3.896c-0.009,1.636,0.188,3.307-0.086,4.896c-0.242,1.399-1.011,2.708-1.546,4.057
      		C429.944,819.696,429.358,819.736,428.772,819.775z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M257.584,667.506c-1.108-2.141-2.499-4.201-3.166-6.471
      		c-0.227-0.773,1.369-2.081,2.132-3.145c0.812,0.652,2.231,1.228,2.325,1.97c0.301,2.393,0.112,4.845,0.112,7.275
      		C258.52,667.259,258.052,667.383,257.584,667.506z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M175.067,750.157c-1.207,1.947-2.413,3.896-3.62,5.844
      		c-1.269-0.859-3.529-1.611-3.637-2.599c-0.517-4.73,3.42-3.44,5.854-4.164C174.132,749.544,174.6,749.851,175.067,750.157z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M315.454,907.871c-1.09-1.249-2.283-2.375-2.172-2.522
      		c1.752-2.335,3.672-4.544,5.554-6.782c0.739,0.751,2.218,2.011,2.105,2.176C319.33,903.092,317.501,905.292,315.454,907.871z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M230.191,815.077c-3.483,6.662-5.331,7.675-9.956,5.214
      		c2.214-4.07,6.206-4.478,10.094-5.09C230.338,815.209,230.191,815.077,230.191,815.077z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M668.918,1117.604c-1.343,0.898-2.77,2.596-4.006,2.467
      		c-1.182-0.123-2.946-2.115-2.994-3.343c-0.04-1.02,1.901-2.681,3.226-3.01c1.018-0.253,2.44,1.125,3.683,1.777
      		C668.857,1116.199,668.887,1116.901,668.918,1117.604z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M622.047,641.331c-0.481-1.842-1.032-2.935-0.982-4
      		c0.075-1.635,0.517-3.264,0.99-4.838c0.029-0.095,2.473,0.186,2.53,0.513c0.349,1.996,0.527,4.075,0.326,6.083
      		C624.846,639.74,623.471,640.261,622.047,641.331z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M566.545,875.82c1.381,2.466,2.763,4.932,4.702,8.393
      		C563.739,882.898,563.138,881.896,566.545,875.82z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M370.959,786.42c1.266,1.929,2.565,3.838,3.698,5.843
      		c0.051,0.091-1.802,1.649-2.076,1.469c-1.676-1.099-3.353-2.347-4.572-3.904c-0.349-0.446,0.822-2.08,1.296-3.17
      		C369.856,786.578,370.407,786.499,370.959,786.42z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M278.625,879.517c1.85,1.838,3.741,3.64,5.43,5.614
      		c0.088,0.104-1.54,1.675-2.368,2.563c-1.826-1.996-3.652-3.991-5.478-5.986C277.014,880.978,277.819,880.247,278.625,879.517z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M548.971,799.88c0,3.124,0.112,6.254-0.074,9.366
      		c-0.051,0.848-1.002,1.641-1.54,2.459c-0.496-0.866-1.525-1.826-1.394-2.583c0.558-3.186-1.518-7.034,1.517-9.631
      		C547.977,799.621,548.474,799.751,548.971,799.88z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M454.445,667.68c1.742-1.091,3.125-2.611,4.027-2.365
      		c1.229,0.335,2.088,2.017,3.106,3.123c-1.417,1.268-2.835,2.536-4.253,3.804C456.521,670.968,455.718,669.695,454.445,667.68z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M584.527,877.667c-1.334-2.671-2.669-5.341-4.123-8.25
      		C586.822,868.503,586.822,868.503,584.527,877.667z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M429.903,664.943c1.993,1.231,3.977,2.161,5.555,3.552
      		c0.843,0.743,0.982,2.284,1.439,3.463c-0.994,0.088-2.114,0.517-2.96,0.203C430.366,670.84,428.753,668.358,429.903,664.943z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M360.669,841.971c6.646,3.642,7.02,5.15,2.575,10.43
      		C362.357,848.807,361.584,845.677,360.669,841.971z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M579.261,892.891c-2.358,0-4.716,0-7.074,0c0.284-1.541,0.568-3.081,0.852-4.622
      		c2.196,0.796,4.393,1.593,6.589,2.388C579.505,891.401,579.383,892.146,579.261,892.891z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M609.447,1002.044c-2.521,1.389-3.879,2.684-4.549,2.392
      		c-1.356-0.59-2.511-1.928-3.339-3.22c-0.183-0.285,1.595-2.516,2.038-2.385C605.195,999.301,606.607,1000.397,609.447,1002.044z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M685.227,655.021c0.969-2.641,1.753-4.782,2.538-6.922
      		c0.974,0.759,2.541,1.349,2.812,2.307C691.501,653.66,690.027,655.365,685.227,655.021z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M237.036,937.232c-1.426,1.835-2.853,3.672-4.279,5.508
      		c-0.531-1.512-1.861-3.372-1.392-4.448c0.586-1.343,2.652-2.04,4.079-3.017C235.975,935.928,236.505,936.58,237.036,937.232z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M288.261,729.065c-0.439-2.142-1.013-4.273-1.187-6.437
      		c-0.029-0.364,1.671-0.867,2.572-1.306c0.435,2.412,0.87,4.824,1.305,7.235C290.054,728.728,289.158,728.896,288.261,729.065z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M430.877,741.303c1.414,1.889,2.919,3.086,2.719,3.808
      		c-0.386,1.385-1.741,2.499-2.693,3.726c-0.87-1.158-2.2-2.228-2.451-3.508C428.281,744.454,429.614,743.284,430.877,741.303z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M287.973,763.975c4.32-0.765,5.494,1.182,5.094,4.373
      		c-0.101,0.804-1.128,2.186-1.618,2.139C287.188,770.078,288.288,766.815,287.973,763.975z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M455.878,831.01c-1.249,2.823-2.153,4.965-3.255,6.999
      		c-0.044,0.082-2.882-0.893-2.863-1.309C449.881,833.829,450.671,831.197,455.878,831.01z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M153.417,792.302c4.122-0.27,6.914-1.051,6.48,2.741
      		c-0.133,1.164-1.649,2.17-2.531,3.248C156.233,796.574,155.101,794.856,153.417,792.302z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M249.234,1044.091c-2.595,1.683-4.426,3.186-4.661,2.968
      		c-1.165-1.08-2.13-2.533-2.665-4.034c-0.165-0.463,1.498-2.251,1.897-2.121C245.241,1041.371,246.494,1042.399,249.234,1044.091z"
      		/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M623.312,1045.142c-0.937-4.046-1.675-7.234-2.435-10.516
      		C626.345,1034.812,626.345,1034.812,623.312,1045.142z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M546.65,653.948c0.699,2.329,1.443,4.647,2.007,7.008
      		c0.042,0.178-1.208,0.663-1.857,1.005c-1.01-2.312-2.02-4.625-3.027-6.938C544.731,654.665,545.69,654.307,546.65,653.948z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M247.708,886.737c-1.857-1.208-3.714-2.416-5.571-3.624
      		c0.464-0.662,1.178-1.961,1.354-1.892c1.977,0.78,3.87,1.769,5.786,2.703C248.754,884.862,248.231,885.8,247.708,886.737z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M373.823,842.24c1.542,2.045,3.008,3.335,2.779,3.821
      		c-0.531,1.119-1.773,2.149-2.959,2.591c-0.489,0.183-2.294-1.307-2.236-1.917C371.515,845.57,372.531,844.491,373.823,842.24z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M418.558,813.99c-1.744-2.27-3.489-4.54-5.86-7.626
      		c6.53-0.301,7.071,2.994,7.086,6.727C419.375,813.391,418.967,813.69,418.558,813.99z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M737.111,769.089c0.584,1.486,1.492,2.946,1.613,4.47
      		c0.061,0.749-1.557,2.414-2.032,2.282c-1.572-0.436-2.97-1.5-4.436-2.322c0.851-1.011,1.701-2.022,2.552-3.033
      		C735.576,770.02,736.344,769.554,737.111,769.089z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M619.027,987.432c-1.356,1.859-2.312,3.171-3.269,4.481
      		c-0.843-1.062-2.508-2.285-2.34-3.152c0.279-1.434,1.739-2.639,2.702-3.939C616.918,985.537,617.715,986.252,619.027,987.432z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M257.498,766.768c-1.996,1.558-3.279,3.097-3.821,2.869
      		c-1.118-0.469-2.345-1.79-2.548-2.936c-0.136-0.766,1.387-2.55,2.214-2.58C254.376,764.085,255.458,765.382,257.498,766.768z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M344.547,809.494c-1.265-2.326-2.395-3.461-2.298-4.479
      		c0.104-1.079,1.332-2.833,2.139-2.874c1.086-0.054,3.271,1.464,3.175,1.934C347.257,805.562,346.107,806.875,344.547,809.494z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M265.458,902.878c-0.819,1.584-1.489,3.29-2.585,4.649
      		c-0.22,0.272-2.07-0.77-3.168-1.205c1.2-1.616,2.4-3.232,3.601-4.849C264.023,901.941,264.741,902.409,265.458,902.878z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M614.084,890.052c-2.01,0.764-3.068,1.578-3.71,1.327
      		c-1.479-0.578-2.758-1.672-4.116-2.558c1.12-0.71,2.332-2.096,3.34-1.96C610.86,887.03,611.955,888.448,614.084,890.052z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M329.002,853.021c-2.821,2.206-4.479,3.503-6.478,5.065
      		c-0.989-1.718-1.769-3.071-2.917-5.065C322.75,853.021,325.192,853.021,329.002,853.021z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M536.594,812.259c-1.237-2.131-2.475-4.261-3.846-6.621
      		C538.328,804.855,538.328,804.855,536.594,812.259z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M634.205,939.524c-3.233,0-5.103,0-8.07,0c1.522-1.993,2.516-3.293,3.508-4.594
      		C630.917,936.215,632.191,937.497,634.205,939.524z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M646.021,1126.167c-1.132,1.159-2.265,2.318-3.397,3.478
      		c-0.889-1.041-1.777-2.082-2.666-3.122c1.554-0.698,3.107-1.396,4.661-2.094C645.086,1125.008,645.553,1125.588,646.021,1126.167z"
      		/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M629.314,1060.434c-0.745,2.692-1.259,4.548-2.097,7.575
      		c-1.496-2.664-2.513-4.475-3.814-6.794C625.555,1060.931,626.946,1060.747,629.314,1060.434z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M213.205,1024.781c2.3,0.557,3.82,0.926,6.071,1.471
      		c-1.472,1.905-2.661,3.443-4.426,5.728C214.243,1029.325,213.833,1027.532,213.205,1024.781z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M747.341,390.492c-3.572,0.266-6.108,0.982-8.415,0.529
      		c-7.611-1.492-9.435-4.774-8.109-13.498c1.52-10.005,12.361-21.792,22.217-24.154c7.457-1.787,10.945,0.131,12.577,7.45
      		c-1.684,0-3.245-0.015-4.807,0.003c-2.323,0.026-5.485-0.881-6.817,0.288c-4.821,4.227-9.558,8.775-13.262,13.963
      		c-2.17,3.04,3.101,8.437,6.897,7.402c7.429-2.027,15.577-2.373,21.15-8.917c0.692-0.813,1.974-1.286,3.071-1.552
      		c1.389-0.336,2.868-0.298,4.308-0.421c-0.1,1.397,0.11,2.91-0.356,4.17c-1.578,4.256-1.383,10.032-8.142,10.232
      		c-0.348,0.01-0.727,0.618-1.008,1.006c-6.597,9.142-13.203,18.276-19.765,27.444c-4.734,6.617-4.706,6.637-14.014,7.71
      		c1.884-4.481,3.229-8.439,5.163-12.085c2.25-4.245,5.228-8.105,7.469-12.355C746.588,395.639,746.721,393.066,747.341,390.492z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M715.422,484.5c0.995,2.437,1.535,6.505,3.09,6.939
      		c2.588,0.723,6.164-0.271,8.704-1.668c3.128-1.722,5.809-4.415,8.332-7.027c2.438-2.524,4.536-1.991,5.003,0.89
      		c0.56,3.46,1.117,8.215-0.717,10.412c-1.907,2.285-6.647,2.293-10.197,3.073c-1.239,0.271-2.754-0.53-3.924-0.156
      		c-6.157,1.972-11.709,0.612-17.194-2.355c-0.995-0.539-2.751-0.112-3.99,0.33c-2.315,0.828-4.416,2.376-6.77,2.95
      		c-8.372,2.041-11.235,0.104-12.434-8.214c-0.521-3.614-0.549-7.301-1.108-10.907c-0.505-3.25,1.518-4.345,3.875-4.316
      		c0.86,0.011,2.24,2.365,2.426,3.777c0.453,3.444,0.072,7.002,0.575,10.433c0.281,1.92,1.282,4.117,2.698,5.323
      		c0.688,0.587,3.417-0.396,4.617-1.396c3.013-2.507,5.865-4.501,10.188-3.875c2.695,0.392,4.331-1.287,3.633-4.41
      		c-0.47-2.103-0.953-4.246-1.007-6.383c-0.03-1.231,0.783-2.484,1.216-3.728c1.146,1.004,2.904,1.812,3.302,3.055
      		c0.689,2.155,0.537,4.58,0.738,6.891C716.125,484.259,715.773,484.38,715.422,484.5z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M677.538,380.003c7.177,5.678,7.177,5.678,9.906,0.567
      		c0.724,2.799,2.064,5.09,1.42,6.049c-1.837,2.738-3.637,5.109-1.804,8.54c0.07,0.132-1.367,1.485-1.68,1.342
      		c-4.232-1.93-5.504,1.243-7.283,3.885c-0.736,1.094-2.09,1.773-3.817,3.169c-0.329-4.66-1.346-8.535,4.117-8.508
      		C678.117,390.147,677.855,385.561,677.538,380.003z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M746.758,289.165c-7.289-3.62-16.086-3.969-21.752-11.063
      		c-0.903-1.131-1.855-2.636-1.861-3.972c-0.008-1.935,0.824-3.873,1.298-5.811c1.062,1.45,2.05,2.962,3.203,4.335
      		c1.688,2.006,3.185,4.405,5.32,5.745c3.206,2.01,7.005,3.05,10.337,4.892c1.767,0.978,3.076,2.779,4.593,4.209
      		C747.517,288.055,747.137,288.61,746.758,289.165z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M696.742,517.455c-6.95-1.984-11.976,0.284-16.897,3.341
      		c-0.986,0.613-3.46,0.821-3.558,0.567c-0.902-2.352-1.554-4.829-1.882-7.321c-0.032-0.24,2.625-1.255,4-1.189
      		c5.944,0.281,11.883,0.775,17.809,1.344c0.841,0.08,1.603,0.995,2.4,1.523C697.863,516.415,697.113,517.111,696.742,517.455z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M678.777,495.482c-1.904,5.285-5.518,5.305-8.786,4.373
      		c-4.474-1.274-8.001-1.338-9.774,4.408c-2.343-2.843-4.493-4.612-1.165-7.844c1.323-1.284,1.134-4.126,1.88-7.319
      		C666.188,492.185,670.369,499.746,678.777,495.482z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M716.346,380.578c-1.806,4.053-3.206,8.146-5.453,11.707
      		c-0.773,1.227-3.656,1.122-5.575,1.626c-0.268-1.397-0.978-2.878-0.732-4.177C706.315,380.585,707.997,379.242,716.346,380.578z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M675.075,453.164c-4.354,0.328-5.968-2.014-6.116-5.706
      		c-0.127-3.13-0.145-6.301,0.247-9.396c0.132-1.036,2.018-2.829,2.514-2.65c1.299,0.469,2.811,1.651,3.238,2.893
      		c0.5,1.45,0.051,3.28-0.182,4.917c-0.406,2.861-2.683,6.458,3.011,6.578c0.357,0.007,0.689,1.249,1.033,1.917
      		C677.571,452.199,676.324,452.682,675.075,453.164z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M700.433,363.716c-8.259-1.73-16.103-1.583-21.266-10.371
      		c3.846,0.901,6.691,1.648,9.573,2.215c2.017,0.397,4.117,0.397,6.108,0.874c1.048,0.251,2.233,0.938,2.839,1.795
      		C698.707,359.67,699.312,361.402,700.433,363.716z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M659.928,534.244c-5.569-5.101-5.77-11.397-5.785-17.631
      		c-0.005-1.961,0.992-3.925,1.53-5.889c0.938,1.92,2.434,3.766,2.706,5.776C659.147,522.175,659.414,527.916,659.928,534.244z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M727.312,505.284c0.992,4.36,1.682,8.018,2.688,11.586
      		c0.653,2.317,1.812,4.488,2.658,6.755c0.359,0.964,0.996,2.316,0.612,2.938c-0.47,0.758-1.869,1.191-2.89,1.239
      		c-0.421,0.021-1.044-1.277-1.338-2.065c-1.43-3.828-2.564-7.781-4.221-11.504C723.436,511.115,723.414,508.578,727.312,505.284z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M743.153,323.417c3.237,1.888,3.778,3.864,0.782,6.907
      		c-2.4,2.436-4.125,5.536-6.147,8.342c-5.087-3.816-4.856-5.38,0.222-8.728C740.104,328.557,741.313,325.83,743.153,323.417z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M706.987,407.206c-0.039-0.117,0.421,0.924,0.657,2.015
      		c1.957,8.973,0.948,9.969-8.129,7.982c-0.864-0.189-1.64-0.786-2.456-1.194c0.487-0.951,0.757-2.175,1.518-2.784
      		c0.988-0.79,2.527-0.879,3.545-1.648C703.905,410.229,705.471,408.593,706.987,407.206z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M724.203,460.154c-0.31-7.989,1.62-10.06,9.413-9.012
      		c0.907,0.122,2.049,1.268,2.356,2.182c0.235,0.7-0.439,2.307-1.115,2.611C731.427,457.484,727.864,458.741,724.203,460.154z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M710.281,428.319c-1.637,5.093-6.114,4.719-10.096,5.021
      		c-0.48,0.036-1.818-2.163-1.55-2.859C699.996,426.952,707.033,425.802,710.281,428.319z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M653.287,409.67c4.505,0.697,7.771,1.202,11.037,1.708
      		c0.171,0.547,0.343,1.094,0.515,1.641c-2.616,1.897-5.232,3.794-7.849,5.691C655.92,416.098,654.85,413.487,653.287,409.67z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M711.102,508.96c4.322,5.466,3.405,11.423,3.003,17.289
      		c-0.037,0.539-0.964,1.302-1.575,1.411c-0.38,0.068-1.356-0.892-1.365-1.403C711.064,520.493,711.102,514.727,711.102,508.96z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M674.331,527.901c4.341,2.324,8.499,4.551,13.257,7.099
      		C678.579,537.231,673.832,534.621,674.331,527.901z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M680.547,469.889c-0.577-1.365-1.139-2.689-1.664-3.932
      		c-1.078,0.735-2.026,1.381-2.973,2.026c0.129-1.096,0.109-2.237,0.426-3.276c0.383-1.254,0.753-2.819,1.681-3.514
      		c0.858-0.643,2.95-0.901,3.621-0.319C684.488,463.35,683.933,467.337,680.547,469.889z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M701.257,526.32c1.251,0.73,2.605,1.168,3.431,2.084
      		c1.324,1.47,2.297,3.254,3.418,4.907c-1.611,0.308-3.852,1.411-4.698,0.745c-1.469-1.157-2.208-3.355-2.968-5.232
      		C700.208,528.25,700.912,527.297,701.257,526.32z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M713.376,453.037c-1.979,0.833-2.839,1.294-3.756,1.56
      		c-1.545,0.448-3.126,0.772-6.156,1.501c1.655-3.378,2.839-5.793,4.021-8.208C709.28,449.459,711.076,451.027,713.376,453.037z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M717.985,450.237c-1.755-1.676-3.746-3.179-5.162-5.104
      		c-0.677-0.92-0.261-2.643-0.339-4.001c1.564,0.443,3.926,0.414,4.526,1.428c1.225,2.069,1.502,4.7,2.169,7.099
      		C718.781,449.852,718.384,450.044,717.985,450.237z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M681.313,420.244c-1.878-1.677-4.002-3.164-5.516-5.122
      		c-0.635-0.82,0.023-2.639,0.094-4.005c1.229,0.386,3.038,0.398,3.573,1.23c1.354,2.107,2.136,4.582,3.146,6.909
      		C682.179,419.586,681.746,419.915,681.313,420.244z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M665.496,355.607c0.991-4.38,1.792-7.917,2.744-12.125
      		C672.037,347.895,671.322,350.708,665.496,355.607z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M656.966,461.907c3.408,2.241,4.585,4.506,2.642,7.426
      		c-0.509,0.765-1.835,0.984-2.786,1.455c-0.389-0.891-1.158-1.812-1.09-2.665C655.887,466.202,656.457,464.317,656.966,461.907z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M801.891,421.821c1.213,2.182,2.425,4.365,3.878,6.982
      		c-7.233,0.949-5.909-2.934-5.596-6.167C800.746,422.365,801.318,422.093,801.891,421.821z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M741.396,512.006c-0.765,1.687-1.371,3.022-1.977,4.358
      		c-0.62-1.658-1.565-3.283-1.736-4.986c-0.103-1.01,1.017-2.144,1.585-3.222C739.95,509.392,740.634,510.627,741.396,512.006z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M657.917,436.586c-1.154,2.098-1.923,3.496-3.18,5.782
      		c-1.19-2.593-2.059-4.484-3.304-7.194C653.969,435.727,655.612,436.084,657.917,436.586z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M665.637,472.707c1.484-1.237,2.967-2.474,4.451-3.71
      		c0.558,0.944,1.688,2.643,1.587,2.72c-1.484,1.126-3.134,2.039-4.743,3.001C666.5,474.048,666.068,473.377,665.637,472.707z"/>
      <Link to={"/archive/035"} className={activeIndex === 35 ? "active" : "inactive"} onClick={() => handleOnClick(35) } >
      	<path className="clickable" fillRule="evenodd" clipRule="evenodd" fill="#000" d="M700.845,753.232c0.723,12.705-2.157,24.854-9.306,36.15
      		c-9.548,15.09-23.74,23.051-40.427,26.944c-20.314,4.741-39.96,0.365-59.321-5.81c-2.059-0.657-4.031-1.716-6.133-2.089
      		c-10.262-1.825-16.146-9.897-23.304-16.138c-0.819-0.715-1.506-1.913-1.683-2.98c-2.202-13.292-6.374-26.221-3.888-40.197
      		c1.855-10.431,3.218-21.086,9.519-29.56c5.783-7.78,10.274-16.185,14.834-24.545c5.529-10.139,14.492-16.825,22.49-24.473
      		c6.349-6.072,13.372-11.434,20.053-17.161c3.807-3.263,5.258-3.015,7.372,1.389c0.416,0.865,0.851,1.957,1.598,2.392
      		c7.029,4.104,14.166,8.024,21.188,12.14c2.611,1.53,5.086,3.33,7.468,5.204c2.352,1.848,4.379,4.118,6.778,5.894
      		c4.043,2.994,6.692,6.225,4.575,11.705c-1.322,3.422,1.509,3.29,3.333,2.659c5.229-1.806,7.562-0.13,9.396,5.149
      		c2.659,7.649,6.459,14.916,9.958,22.257C699.979,731.883,701.576,742.111,700.845,753.232z M566.112,733.336
      		c23.225,0,46.157,0.002,69.09-0.001c4.483-0.001,8.968,0.002,13.45-0.045c3.478-0.036,6.957-0.074,10.429-0.247
      		c3.398-0.17,5.067-2.088,5.149-5.484c0.198-8.117-0.67-7.761,8.603-8.022c0.998-0.027,2.011-0.095,2.995,0.027
      		c3.419,0.423,5.536-0.234,4.679-4.474c-0.187-0.923,0.923-2.228,1.659-3.186c1.134-1.478,2.46-2.805,3.705-4.197
      		c-0.362-0.59-0.725-1.18-1.087-1.771c-3.172,1.339-6.354,2.654-9.512,4.025c-2.083,0.904-4.05,2.321-6.216,2.73
      		c-2.944,0.558-7.264-0.777-8.735,0.802c-1.733,1.861-1.047,5.972-1.423,9.105c-0.079,0.66-0.253,1.31-0.318,1.971
      		c-0.243,2.43-1.19,3.85-4.006,3.866c-7.583,0.044-15.165,0.487-22.745,0.422c-2.995-0.025-5.984-0.978-8.976-1.509
      		c1.746-3.063,3.293-6.27,5.314-9.14c1.212-1.722,3.254-2.837,4.756-4.387c1.191-1.229,2.129-2.704,3.299-4.226
      		c-3.948-1.549-6.342-0.603-8.26,2.361c-1.257,1.942-3.081,3.509-4.414,5.412c-2.115,3.02-3.872,2-5.142-0.494
      		c-1.192-2.344-1.654-5.063-2.881-7.383c-0.828-1.566-2.424-2.727-3.676-4.068c-0.718,1.488-2.089,3.001-2.032,4.459
      		c0.188,4.796,0.946,9.567,1.358,14.359c0.1,1.155,0.146,2.874-0.529,3.439c-2.658,2.224-17.719,2.742-19.849,0.188
      		c-1.405-1.686-1.8-5.22-1.098-7.434c1.158-3.649,3.151-0.432,4.378,0.446c3.33,2.383,5.586,1.063,6.442-2.149
      		c1.062-3.99,1.543-8.188,1.796-12.325c0.226-3.687-2.922-4.006-5.559-4.237c-2.306-0.202-3.96,0.222-4.042,3.496
      		c-0.055,2.153-1.502,5.533-3.149,6.147c-4.994,1.859-6.14,5.537-6.141,9.947c-0.003,5.752-3.39,7.405-8.288,7.554
      		c-1.495,0.046-3.024,0.144-4.485-0.101C567.337,728.661,566.041,730.129,566.112,733.336z M578.887,773.341
      		c-2.618,0.319-6.125,0.618-9.578,1.229c-1.437,0.255-2.755,1.175-4.127,1.791c1.49,0.972,2.996,2.806,4.467,2.776
      		c10.47-0.203,20.931-0.807,31.396-1.245c7.917-0.332,7.893-0.301,10.034,7.323c0.24,0.855,0.921,1.617,1.532,2.302
      		c0.071,0.079,1.47-0.632,1.46-0.951c-0.026-0.847-0.642-1.661-0.747-2.522c-0.276-2.258-1.294-4.991-0.409-6.705
      		c1.839-3.564,0.174-4.245-2.581-4.3c-5.652-0.113-11.312,0.134-16.965,0.017c-2.204-0.045-4.396-0.674-7.563-1.197
      		c5.542-4.888,8.188-9.75,4.358-15.889c-1.846-2.957-3.401-6.485-6.057-8.436c-2.669-1.96-6.543-2.281-9.889-3.321
      		c-0.168,2.946-0.666,5.921-0.418,8.831c0.295,3.445,1.1,6.867,1.933,10.238C576.464,766.234,577.54,769.104,578.887,773.341z
      		 M613.256,670.847c0.716,4.396,1.567,9.451,2.353,14.516c0.88,5.673,0.355,6.168-5.443,6.488c-4.244,0.234-7.146-0.465-6.49-5.838
      		c0.285-2.329-0.464-4.785-0.743-7.183c-0.611-0.19-1.222-0.38-1.832-0.571c-1.276,2.895-3.928,6.003-3.52,8.636
      		c0.681,4.39-0.306,5.201-4.322,5.083c-1.388-0.04-2.828,1.762-4.245,2.722c1.398,0.686,2.774,1.91,4.198,1.967
      		c7.481,0.298,15.062-0.355,22.434,0.624c5.338,0.708,6.203-1.47,5.886-5.504c-0.362-4.603,1.031-8.247,4.075-11.782
      		c2.254-2.617,3.471-6.127,5.105-9.159C624.54,670.847,619.19,670.847,613.256,670.847z M676.132,748.177
      		c2.241,5.816,4.036,11.062,6.271,16.115c2.547,5.761,0.159,11.793-5.761,14.013c-2.567,0.963-3.96,0.562-4.794-2.282
      		c-0.434-1.478-1.835-3.762-2.866-3.798c-6.787-0.241-13.595-0.039-20.387,0.231c-0.789,0.031-1.527,1.37-2.289,2.103
      		c0.874,0.841,1.631,2.167,2.646,2.408c1.561,0.371,3.328,0.179,4.952-0.098c5.317-0.902,9.766-0.043,14.252,3.628
      		c6.341,5.188,19.119,0.423,21.316-7.646C691.722,764.598,685.038,749.529,676.132,748.177z M619.434,750.475
      		c0.663,7.286,2.341,13.692,1.455,19.722c-0.74,5.037,1.448,5.269,4.62,5.612c3.291,0.357,6.627,0.506,9.931,0.385
      		c0.818-0.03,2.289-1.539,2.209-2.219c-0.117-0.995-1.194-2.25-2.175-2.674c-1.139-0.493-2.636-0.384-3.94-0.229
      		c-4.779,0.566-6.606-1.846-5.661-6.124c0.416-1.883,1.896-3.979,3.511-5.031c1.85-1.205,5.006-0.716,6.468-2.129
      		c1.868-1.806,2.608-4.777,3.835-7.246c-1.852-0.632-4.889-2.29-5.371-1.733C630.226,753.521,625.478,753.108,619.434,750.475z
      		 M632.313,696.074c5.538-0.646,9.984-1.268,14.449-1.652c3.02-0.259,3.339-1.657,2.763-4.323c-1.12-5.185-1.791-10.464-2.82-15.67
      		c-0.293-1.481-1.18-2.846-1.794-4.263c-1.599,1.039-4.138,1.76-4.614,3.177c-1.243,3.691-2.137,7.687-2.132,11.56
      		c0.005,4.224-1.18,6.554-5.489,7.313c-1.293,0.229-2.377,1.648-3.556,2.521C630.469,695.302,631.819,695.867,632.313,696.074z
      		 M636.388,807.374c-1.711-0.816-3.356-2.327-4.846-2.187c-5.945,0.562-10.708-1.136-14.618-6.306
      		c-0.238,3.748-0.716,7.643,0.912,10.195C620.151,812.709,632.547,811.342,636.388,807.374z M592.748,788.337
      		c-6.372,0.006-10.215,3.701-9.321,8.581c0.227,1.235,3.985,2.245,6.237,2.583c2.785,0.418,6.059-4.331,5.645-8.472
      		C595.185,789.791,593.162,788.745,592.748,788.337z M656.996,802.884c0.572-4.265,1.095-7.489,1.41-10.733
      		c0.227-2.326-0.107-4.405-3.338-4.016c-2.661,0.321-5.494-0.423-6.174,3.858C647.924,798.109,652.853,799.194,656.996,802.884z
      		 M631.837,783.621c-10.556-1.3-13.657,1.847-13.165,12.361c2.604-0.298,5.243-0.363,7.672-1.112c0.4-0.124-0.288-3.261-0.151-4.979
      		c0.065-0.825,0.768-1.699,1.387-2.359C628.651,786.388,629.883,785.394,631.837,783.621z M654.692,761.977
      		c0.509-0.08,1.018-0.161,1.526-0.241c0.21-2.397,1.458-5.938,0.38-6.917c-1.528-1.389-4.771-0.89-7.282-1.197
      		c-0.174,0.517-0.349,1.033-0.523,1.549C650.76,757.439,652.727,759.707,654.692,761.977z"/>
      </Link>
      	<path fillRule="evenodd" clipRule="evenodd" d="M566.112,733.336c-0.071-3.207,1.225-4.675,4.541-4.121
      		c1.461,0.244,2.99,0.146,4.485,0.101c4.898-0.148,8.285-1.802,8.288-7.554c0.001-4.41,1.146-8.088,6.141-9.947
      		c1.647-0.614,3.095-3.994,3.149-6.147c0.082-3.274,1.736-3.697,4.042-3.496c2.637,0.23,5.784,0.55,5.559,4.237
      		c-0.253,4.137-0.733,8.335-1.796,12.325c-0.856,3.212-3.112,4.533-6.442,2.149c-1.227-0.878-3.22-4.095-4.378-0.446
      		c-0.702,2.214-0.308,5.749,1.098,7.434c2.13,2.554,17.19,2.035,19.849-0.188c0.676-0.565,0.629-2.284,0.529-3.439
      		c-0.412-4.792-1.171-9.563-1.358-14.359c-0.057-1.458,1.314-2.972,2.032-4.459c1.252,1.341,2.848,2.501,3.676,4.068
      		c1.227,2.32,1.688,5.039,2.881,7.383c1.27,2.495,3.026,3.514,5.142,0.494c1.333-1.902,3.157-3.47,4.414-5.412
      		c1.918-2.963,4.312-3.91,8.26-2.361c-1.17,1.522-2.107,2.997-3.299,4.226c-1.502,1.55-3.544,2.665-4.756,4.387
      		c-2.021,2.871-3.568,6.077-5.314,9.14c2.991,0.531,5.98,1.483,8.976,1.509c7.58,0.065,15.162-0.378,22.745-0.422
      		c2.815-0.017,3.763-1.437,4.006-3.866c0.065-0.661,0.239-1.311,0.318-1.971c0.376-3.133-0.311-7.244,1.423-9.105
      		c1.472-1.58,5.791-0.245,8.735-0.802c2.166-0.409,4.133-1.826,6.216-2.73c3.157-1.371,6.34-2.686,9.512-4.025
      		c0.362,0.591,0.725,1.181,1.087,1.771c-1.245,1.392-2.571,2.719-3.705,4.197c-0.736,0.958-1.846,2.263-1.659,3.186
      		c0.857,4.24-1.26,4.897-4.679,4.474c-0.984-0.122-1.997-0.055-2.995-0.027c-9.272,0.261-8.404-0.095-8.603,8.022
      		c-0.082,3.396-1.751,5.314-5.149,5.484c-3.472,0.173-6.951,0.211-10.429,0.247c-4.482,0.047-8.967,0.044-13.45,0.045
      		C612.27,733.338,589.337,733.336,566.112,733.336z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M578.887,773.341c-1.347-4.237-2.423-7.106-3.154-10.06
      		c-0.833-3.371-1.638-6.793-1.933-10.238c-0.248-2.91,0.25-5.885,0.418-8.831c3.346,1.04,7.22,1.361,9.889,3.321
      		c2.655,1.95,4.211,5.479,6.057,8.436c3.83,6.139,1.184,11.001-4.358,15.889c3.167,0.523,5.359,1.152,7.563,1.197
      		c5.652,0.117,11.312-0.13,16.965-0.017c2.755,0.055,4.42,0.735,2.581,4.3c-0.885,1.714,0.133,4.447,0.409,6.705
      		c0.105,0.861,0.721,1.676,0.747,2.522c0.01,0.319-1.389,1.03-1.46,0.951c-0.611-0.685-1.292-1.446-1.532-2.302
      		c-2.142-7.624-2.117-7.655-10.034-7.323c-10.465,0.438-20.926,1.042-31.396,1.245c-1.471,0.029-2.977-1.805-4.467-2.776
      		c1.372-0.616,2.69-1.536,4.127-1.791C572.762,773.959,576.269,773.66,578.887,773.341z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M613.256,670.847c5.935,0,11.284,0,17.455,0
      		c-1.635,3.032-2.852,6.542-5.105,9.159c-3.044,3.535-4.438,7.179-4.075,11.782c0.317,4.035-0.548,6.212-5.886,5.504
      		c-7.372-0.979-14.952-0.326-22.434-0.624c-1.424-0.057-2.8-1.281-4.198-1.967c1.417-0.96,2.857-2.762,4.245-2.722
      		c4.017,0.117,5.003-0.693,4.322-5.083c-0.408-2.633,2.243-5.741,3.52-8.636c0.61,0.19,1.221,0.381,1.832,0.571
      		c0.279,2.398,1.028,4.854,0.743,7.183c-0.656,5.373,2.246,6.072,6.49,5.838c5.799-0.32,6.323-0.815,5.443-6.488
      		C614.823,680.297,613.972,675.243,613.256,670.847z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M676.132,748.177c8.906,1.353,15.59,16.421,13.342,24.674
      		c-2.197,8.069-14.976,12.835-21.316,7.646c-4.486-3.671-8.935-4.53-14.252-3.628c-1.624,0.276-3.392,0.469-4.952,0.098
      		c-1.016-0.241-1.772-1.567-2.646-2.408c0.762-0.732,1.5-2.071,2.289-2.103c6.792-0.271,13.6-0.473,20.387-0.231
      		c1.031,0.036,2.433,2.32,2.866,3.798c0.834,2.844,2.227,3.245,4.794,2.282c5.92-2.22,8.308-8.252,5.761-14.013
      		C680.168,759.239,678.373,753.993,676.132,748.177z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M619.434,750.475c6.044,2.634,10.792,3.047,14.881-1.667
      		c0.482-0.557,3.52,1.102,5.371,1.733c-1.227,2.469-1.967,5.44-3.835,7.246c-1.462,1.413-4.618,0.924-6.468,2.129
      		c-1.614,1.052-3.095,3.148-3.511,5.031c-0.945,4.278,0.882,6.69,5.661,6.124c1.305-0.154,2.802-0.264,3.94,0.229
      		c0.98,0.424,2.058,1.679,2.175,2.674c0.08,0.68-1.391,2.188-2.209,2.219c-3.304,0.121-6.64-0.027-9.931-0.385
      		c-3.172-0.344-5.36-0.575-4.62-5.612C621.774,764.167,620.097,757.761,619.434,750.475z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M632.313,696.074c-0.494-0.207-1.845-0.771-3.193-1.337
      		c1.179-0.873,2.263-2.292,3.556-2.521c4.31-0.76,5.494-3.089,5.489-7.313c-0.005-3.874,0.889-7.869,2.132-11.56
      		c0.477-1.417,3.016-2.139,4.614-3.177c0.614,1.417,1.501,2.782,1.794,4.263c1.029,5.206,1.7,10.486,2.82,15.67
      		c0.576,2.666,0.257,4.063-2.763,4.323C642.298,694.806,637.852,695.427,632.313,696.074z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M636.388,807.374c-3.841,3.968-16.236,5.335-18.552,1.703
      		c-1.628-2.553-1.15-6.447-0.912-10.195c3.91,5.17,8.673,6.868,14.618,6.306C633.031,805.047,634.677,806.558,636.388,807.374z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M592.748,788.337c0.414,0.408,2.437,1.454,2.561,2.692
      		c0.414,4.141-2.859,8.89-5.645,8.472c-2.252-0.338-6.011-1.348-6.237-2.583C582.533,792.038,586.376,788.343,592.748,788.337z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M656.996,802.884c-4.144-3.689-9.072-4.774-8.102-10.891
      		c0.68-4.281,3.513-3.537,6.174-3.858c3.23-0.39,3.564,1.689,3.338,4.016C658.091,795.395,657.568,798.619,656.996,802.884z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M631.837,783.621c-1.954,1.772-3.186,2.767-4.258,3.91
      		c-0.619,0.66-1.321,1.534-1.387,2.359c-0.137,1.719,0.552,4.855,0.151,4.979c-2.429,0.749-5.068,0.814-7.672,1.112
      		C618.18,785.468,621.281,782.321,631.837,783.621z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M654.692,761.977c-1.966-2.27-3.933-4.537-5.899-6.807
      		c0.175-0.516,0.35-1.032,0.523-1.549c2.511,0.308,5.754-0.191,7.282,1.197c1.078,0.979-0.17,4.52-0.38,6.917
      		C655.71,761.815,655.201,761.896,654.692,761.977z"/>
      </g>
    </svg>
    {renderConfirmation()}
    </div>
  )
}
