import React from "react";
import Monster from "./components/Monster";
import NavMini from './components/NavMini';

function Home(){

  return(
    <div className="app">
      <NavMini />
      <div className="frow">
        <div className="frow centered">
          <div className="col-md-1-1">
            <div className="figure">
              <Monster/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
