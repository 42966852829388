import React from "react";

export default function IconPDF() {

  return (
  <div className="svg-icon">
   <svg version="1.1" id="gallery" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 60 60" xmlSpace="preserve">
    <g id="Pdf">
      <path d="M42.27,53.49V50.36h6.19V19h-6a3.74,3.74,0,0,1-3.41-3.39c0-2,0-3.94,0-6h-25V50.32h6.75v3.15c-2.33,0-4.62.09-6.9,0-1.52-.09-3-2-3-3.52V8.47c0-1.32.16-1.54,1.46-1.77a11.64,11.64,0,0,1,2-.19q13.24,0,26.49,0A1.84,1.84,0,0,1,42.06,7q4.57,4.37,9.09,8.81a1.64,1.64,0,0,1,.45,1.07q0,16.77,0,33.55a7.39,7.39,0,0,1,0,.94c-.2,1.66-.74,2.14-2.38,2.14ZM47,15.86,42.28,11v4.91Z"/>
      <path d="M31.38,52.59,20.8,41.78h7.83c0-.37,0-.65,0-.93,0-3.13.07-6.27.08-9.41a2.45,2.45,0,0,1,1.14-2.28,2.3,2.3,0,0,1,3.51,2.13c0,3.14,0,6.27,0,9.41v1.06h8.38Z"/>
    </g>  
  </svg>
  </div>
  )
}
