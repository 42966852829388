import React from "react"
import NavMini from './components/NavMini';

function Guide(){

  return(
      <div>
        <h1>Guide</h1>
        <NavMini />
      </div>
  )
}

export default Guide
