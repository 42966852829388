import React, {useState} from "react";
import { Link, useRouteMatch } from "react-router-dom";
import ModalConfirm from './ModalConfirm';

export default function Monster() {
  const curPathID = parseInt(useRouteMatch().params.id, 10);
  const [activeIndex, setActiveIndex] = useState(curPathID);
  const [tOpenModalClickTimestamp, setTOpenModalClick] = useState(0);

  const handleOnClick = index => {
    setActiveIndex(index);
  }

  const tryNextLevel = () => {
     setTOpenModalClick(Date.now());
  }

  const renderConfirmation = () => {
    return <ModalConfirm tForwardOpen={tOpenModalClickTimestamp} curMap={"monster"} />
  }

  return(
  <div className="frow centered">
    <svg version="1.1" id="monster" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="auto" height="1445px" viewBox="0 0 1000 1445" enableBackground="new 0 0 1000 1445" xmlSpace="preserve">
    <g>
    	<path className="figure-outline" fillRule="evenodd" clipRule="evenodd" d="M668.709,915.506c0,7.333-0.013,14.666,0.006,21.998
    		c0.01,4.163,0.094,8.209-2.302,12.057c-1.012,1.625-0.438,4.202-0.706,6.331c-0.661,5.231-1.43,10.449-2.081,15.681
    		c-0.348,2.798-0.722,5.611-0.772,8.424c-0.143,7.83-0.115,15.663-0.171,23.495c-0.008,1.164,0.234,2.481-0.215,3.467
    		c-4.583,10.062-1.995,20.729-2.692,31.105c-0.312,4.646,0.2,9.347-0.13,13.989c-0.243,3.418-1.247,6.781-1.91,10.169
    		c-0.35,1.78-0.972,3.558-0.999,5.343c-0.119,7.886-0.593,15.713-2.565,23.393c-0.287,1.119-0.403,2.314-0.389,3.472
    		c0.27,20.597-3.907,40.957-3.134,61.598c0.454,12.134,0.341,24.293,0.284,36.439c-0.01,2.197-0.88,4.423-1.543,6.577
    		c-0.849,2.76-2.793,5.307-0.668,8.406c0.559,0.813,0.215,2.273,0.22,3.437c0.025,6.499,0.257,13.009-0.033,19.495
    		c-0.197,4.434-1.139,8.833-1.73,13.25c-0.396,2.96-1.021,5.917-1.1,8.887c-0.544,20.654-0.576,41.33-1.589,61.959
    		c-0.59,12.01,2.606,22.588,8.594,32.629c0.84,1.407,1.821,2.919,3.124,3.83c6.122,4.282,12.015,9.137,18.689,12.319
    		c7.014,3.345,12.155,8.652,17.769,13.521c3.126,2.712,5.608,6.154,8.644,8.983c4.686,4.369,6.659,9.505,6.774,15.974
    		c0.112,6.318-1.795,11.05-7.14,14.308c-3.799,2.316-7.764,4.363-11.521,6.742c-6.21,3.932-11.435,0.07-16.866-1.968
    		c-2.436-0.914-4.663-2.391-7.109-3.261c-0.683-0.242-2.115,0.533-2.672,1.253c-1.229,1.593-1.853,4.643-3.207,4.947
    		c-2.904,0.653-6.15,0.188-9.191-0.263c-5.194-0.769-10.312-2.396-15.514-2.678c-4.185-0.226-8.503,1.683-12.363-1.494
    		c-0.372-0.306-1.283-0.078-1.908,0.062c-10.12,2.279-20.187,3.282-30.234-0.521c-1.641-0.621-3.612-0.36-5.429-0.523
    		c-2.98-0.269-6.078-0.14-8.903-0.951c-2.44-0.702-5.341-1.951-6.678-3.895c-2.771-4.03-4.675-8.664-6.869-13.083
    		c-1.841-3.705-3.353-7.599-5.469-11.135c-1.24-2.072-3.062-4.284-5.167-5.266c-8.626-4.024-11.173-11.495-11.328-19.922
    		c-0.232-12.651,0.45-25.318,0.329-37.975c-0.15-15.781-0.859-31.557-1.061-47.338c-0.126-9.968,0.419-19.944,0.282-29.911
    		c-0.049-3.546-1.238-7.074-1.879-10.615c-1.237-6.846-3.623-13.265-6.002-19.812c-3.006-8.274-3.619-17.42-5.236-26.194
    		c-0.562-3.056-1.115-6.117-1.539-9.194c-0.453-3.289-1.049-6.598-1.063-9.9c-0.073-17.498,0.161-34.998-0.015-52.494
    		c-0.08-7.938,0.077-15.984-3.418-23.509c-0.656-1.411-0.536-3.285-0.432-4.924c0.199-3.112-1.452-4.895-4.171-5.242
    		c-2.694-0.346-5.002-0.856-7.399,2.674c-4.338,6.386-4.073,13.595-5.908,20.4c-0.275,1.023,0.332,2.258,0.38,3.401
    		c0.103,2.455,0.323,4.934,0.105,7.367c-0.471,5.255-0.985,10.521-1.889,15.713c-0.434,2.494-1.222,5.38-2.881,7.085
    		c-1.758,1.808-2.239,3.491-2.239,5.696c0.002,14.328-0.598,28.691,0.27,42.967c0.358,5.889-1.201,10.434-3.274,15.536
    		c-3.351,8.243-7.544,16.141-9.067,25.198c-1.058,6.289-3.87,12.371-6.486,18.285c-2.326,5.257-3.95,10.424-3.821,16.276
    		c0.099,4.447-1.081,8.911-1.146,13.376c-0.055,3.77,1.039,7.557,0.98,11.326c-0.113,7.258-0.672,14.51-1.065,21.763
    		c-0.404,7.45-1.055,14.896-1.165,22.351c-0.062,4.283,0.104,8.771,1.332,12.812c1.085,3.567,0.308,6.542-0.617,9.666
    		c-1.225,4.14-2.796,8.177-4.08,12.3c-0.437,1.402-0.769,2.969-0.613,4.401c0.808,7.456-2.872,12.664-8.12,17.269
    		c-2.983,2.617-5.776,5.46-8.571,8.285c-5.666,5.727-12.155,10.077-19.931,12.432c-3.764,1.14-7.495,2.398-11.198,3.723
    		c-4.104,1.469-7.537-0.062-8.882-4.103c-1.199-3.605-2.143-4.033-5.178-1.628c-5.552,4.398-11.747,5.698-18.6,4.549
    		c-0.328-0.055-0.792,0.04-0.971-0.147c-4.315-4.508-6.421-0.317-8.909,2.26c-1.269,1.313-2.444,2.718-3.706,4.039
    		c-4.104,4.295-7.812,5.012-13.063,2.413c-2.097-1.037-4.128-2.097-3.497-5.225c0.484-2.401-1.116-2.979-3.049-2.108
    		c-2.435,1.098-5.14,1.085-7.104,3.917c-2.349,3.389-6.899,3.883-10.523,2.377c-6.558-2.725-13.24-2.696-20.074-2.654
    		c-2.964,0.02-5.963-0.629-8.891-1.24c-3.59-0.75-6.396-4.037-6.725-7.67c-0.238-2.648-0.098-5.342-0.468-7.967
    		c-1.346-9.549,3.805-15.662,10.683-21.041c8.497-6.647,16.867-13.337,26.746-18.075c4.173-2.001,7.377-6.141,10.825-9.508
    		c9.49-9.269,16.883-20.086,22.271-32.148c1.353-3.025,1.252-6.845,1.265-10.301c0.094-25.831,0.133-51.663-0.014-77.493
    		c-0.035-6.149-0.697-12.31-1.312-18.44c-1.151-11.5-2.964-22.955-3.596-34.479c-0.629-11.465-0.135-22.99-0.152-34.489
    		c-0.015-10.493-0.047-20.986-0.095-31.479c-0.006-1.327,0.138-2.758-0.291-3.962c-1.87-5.252-2.086-10.513-1.845-16.107
    		c0.408-9.465-0.33-18.979-0.554-28.474c-0.086-3.652,0.002-7.311-0.14-10.96c-0.167-4.307,0.302-8.845-0.904-12.863
    		c-3.587-11.947-2.615-24.084-2.578-36.211c0.004-1.322,0.468-2.875-0.061-3.932c-5.5-11.016-2.909-22.812-3.279-34.264
    		c-0.462-14.306-0.183-28.636-0.208-42.955c-0.023-12.998,0.224-26.003-0.18-38.988c-0.129-4.177-1.542-8.46-3.098-12.408
    		c-1.692-4.295-4.731-5.843-8.856-3.821c-6.71,3.288-12.806,0.279-18.976-0.873c-6.58-1.229-10.644-6.309-14.148-11.759
    		c-1.789-2.78-4.316-4.514-7.942-4.113c-6.475,0.716-12.55-0.884-18.463-3.38c-6.181-2.608-10.954-6.37-11.445-13.682
    		c-0.161-2.395-1.595-2.776-3.635-3.151c-6.168-1.135-12.484-1.996-18.344-4.082c-3.735-1.329-6.972-4.404-10.062-7.119
    		c-5.242-4.605-10.189-9.547-15.266-14.342c-0.961-0.907-2.271-1.614-2.862-2.714c-1.963-3.655-5.33-5.089-9.006-5.47
    		c-5.342-0.553-9.412-3.3-12.988-6.82c-6.729-6.625-14.113-12.529-20.18-19.909c-4.882-5.938-8.401-11.998-7.77-19.828
    		c0.315-3.911-0.887-5.77-5-6.978c-10.681-3.139-15.827-18.813-9.349-27.683c2.61-3.574,6.813-4.468,10.95-5.355
    		c10.367-2.226,20.799-4.235,31.024-7.002c13.907-3.763,27.649-8.14,41.456-12.273c9.829-2.943,19.631-5.977,29.482-8.841
    		c2.381-0.692,4.979-0.637,7.358-1.332c10.031-2.932,19.928-6.373,30.051-8.929c7.632-1.928,15.114-5.156,23.316-4.18
    		c1.289,0.153,2.873,0.218,3.921-0.379c8.306-4.728,17.566-5.419,26.705-6.903c4.144-0.673,5.84-4.577,6.992-8.313
    		c0.926-3.001,1.654-6.064,2.435-9.109c0.247-0.965,0.634-1.989,0.522-2.936c-0.655-5.592-1.206-11.213-2.252-16.737
    		c-0.83-4.384-2.013-8.758-3.586-12.93c-2.331-6.188-5.075-12.228-7.843-18.238c-0.357-0.775-1.862-1.188-2.914-1.449
    		c-4.665-1.155-9.371-2.144-14.04-3.285c-5.642-1.38-11.338-2.609-16.866-4.36c-4.705-1.49-9.365-3.314-13.759-5.555
    		c-5.912-3.015-11.577-6.522-17.297-9.905c-10.588-6.261-21.12-12.617-31.724-18.85c-1.847-1.086-3.936-1.759-5.914-2.621
    		c-4.175-1.819-4.814-3.576-5.029-8.004c-0.18-3.71-1.547-7.355-2.303-11.047c-0.926-4.521-2.144-9.027-2.539-13.6
    		c-0.472-5.461-0.247-10.985-0.23-16.482c0.004-1.479-0.073-3.192,0.602-4.398c4.232-7.576,2.742-15.878,3.388-23.919
    		c0.33-4.102,1.103-8.169,1.708-12.247c0.097-0.65,0.581-1.297,0.521-1.91c-0.994-10.125,1.954-19.758,3.625-29.549
    		c0.279-1.639,0.493-3.29,0.77-4.93c0.798-4.716,1.271-9.521,2.503-14.123c1.748-6.531,4.056-12.914,6.135-19.356
    		c0.252-0.781,0.857-1.555,0.819-2.309c-0.273-5.464,3.026-9.333,5.753-13.506c2.363-3.615,4.719-7.26,6.726-11.077
    		c11.78-22.405,28.806-40.234,48.909-55.138c5.725-4.243,12.73-6.866,19.356-9.755c7.136-3.11,14.474-5.775,21.801-8.419
    		c6.38-2.302,12.809-4.508,19.32-6.394c13.239-3.832,22.969-11.54,28.307-24.47c0.947-2.295,2.234-4.457,3.461-6.626
    		c2.548-4.503,6.598-6.732,11.604-6.193c3.901,0.42,5.192-0.992,5.227-4.56c0.01-0.961,0.559-1.907,0.8-2.876
    		c0.809-3.246,0.352-5.979-1.952-8.783c-2.903-3.535-5.483-7.472-7.45-11.594c-1.306-2.737-1.999-6.164-1.741-9.178
    		c0.808-9.451-4.242-16.198-9.881-22.602c-1.313-1.491-4.232-2.358-6.338-2.234c-13.84,0.814-22.52-7.278-30.307-17.023
    		c-2.065-2.584-3.828-5.407-5.832-8.043c-4.383-5.767-3.669-12.587-3.871-19.144c-0.209-6.795-0.304-13.678,3.134-19.927
    		c1.665-3.026,3.201-6.174,5.248-8.931c8.618-11.6,19.532-20.358,32.971-25.847c1.072-0.438,2.211-1.014,3.319-1.016
    		c8.546-0.016,13.975-5.455,19.398-10.941c2.211-2.235,4.531-4.38,6.545-6.781c0.53-0.633,0.563-2.613,0.047-3.045
    		c-4.013-3.354-8.55-6.021-13.864-6.245c-5.854-0.246-11.441-1.119-17.144-2.672c-4.833-1.316-10.232-0.578-15.382-0.703
    		c-1.829-0.045-3.747,0.386-5.463-0.045c-1.196-0.3-2.585-1.521-3.01-2.658c-0.315-0.846,0.458-2.608,1.294-3.293
    		c8.708-7.125,18.42-11.778,29.976-11.694c8.499,0.062,17,0.132,25.497,0.027c8.962-0.111,16.715,2.974,23.061,9.079
    		c3.082,2.966,5.825,3.061,9.402,1.497c6.688-2.925,13.486-5.599,20.221-8.418c2.582-1.08,5.031-2.561,7.691-3.348
    		c5.191-1.536,10.435-3.382,15.761-3.809c3.772-0.303,7.757,1.361,11.572,2.416c6.888,1.903,13.694,4.104,20.592,5.973
    		c2.207,0.598,4.603,0.811,6.894,0.723c5.672-0.219,10.637,1.352,14.338,5.675c2.576,3.01,4.99,3.006,8.277,1.267
    		c4.687-2.481,9.468-4.942,14.476-6.612c4.029-1.344,8.63-0.973,12.664-2.308c6.482-2.145,13.073-1.829,19.673-2.147
    		c4.277-0.206,7.842,1.516,10.996,4.039c1.104,0.884,2.2,2.849,1.952,4.038c-0.237,1.138-2.091,2.315-3.469,2.774
    		c-7.397,2.463-15.002,4.361-22.264,7.157c-4.255,1.639-8.107,4.432-11.949,6.992c-3.433,2.288-3.667,5.701-0.594,8.234
    		c3.135,2.583,6.187,4.91,6.284,9.604c0.022,1.062,2.109,2.389,3.502,3.062c7.33,3.547,14.838,6.736,22.081,10.444
    		c3.185,1.63,6.331,3.793,8.727,6.415c6.487,7.1,12.713,14.484,17.207,23.074c1.037,1.982,1.61,4.369,1.773,6.615
    		c0.531,7.277,0.783,14.575,1.11,21.866c0.037,0.818,0.025,1.697-0.226,2.461c-3.585,10.888-9.256,20.232-19.894,25.574
    		c-4.898,2.459-9.97,4.602-15.06,6.647c-6.951,2.794-13.912,2.859-20.839-0.366c-5.121-2.385-6.629-1.375-6.753,4.15
    		c-0.146,6.495,0.108,13.035-0.59,19.472c-1.091,10.056-7.03,17.282-15.057,22.955c-2.032,1.438-4.522,2.399-6.176,4.166
    		c-3.794,4.052-5.245,8.316-3.614,14.479c1.565,5.914,3.259,8.972,9.796,9.248c6.025,0.256,8.616,5.444,8.751,10.533
    		c0.173,6.528,3.051,10.888,7.752,14.695c3.749,3.037,7.437,6.155,11.076,9.323c6.059,5.272,13.567,7.253,21.102,8.355
    		c7.931,1.16,15.081,4.133,22.119,7.472c15.45,7.33,30.84,14.801,46.06,22.597c4.986,2.554,9.623,5.98,13.938,9.585
    		c2.866,2.394,5.23,5.597,7.188,8.819c3.086,5.079,6.06,10.309,8.304,15.793c2.241,5.475,3.534,11.338,5.224,17.037
    		c0.235,0.797,0.227,1.684,0.552,2.433c4.604,10.579,6.205,21.629,5.355,33.094c-0.083,1.13-0.098,2.583,0.512,3.393
    		c2.996,3.98,2.209,8.532,2.392,12.98c0.498,12.126,1.428,24.243,1.572,36.371c0.156,13.129-0.402,26.267-0.581,39.401
    		c-0.018,1.291,0.707,2.604,0.628,3.879c-0.181,2.932,0.306,6.557-1.221,8.598c-2.138,2.859-2.276,5.514-2.189,8.623
    		c0.142,5.068-0.802,9.8-3.388,14.369c-2.016,3.561-3.014,7.695-4.472,11.573c-6.161,16.392-17.16,28.75-32.167,37.412
    		c-5.223,3.015-11.196,4.732-16.834,7.026c-2.302,0.937-4.679,1.709-6.926,2.761c-9.154,4.287-18.96,2.635-28.516,3.19
    		c-3.653,0.213-7.334,0.192-10.992,0.034c-3.553-0.154-5.548,1.678-6.842,4.662c-4.229,9.763-8.199,19.608-9.863,30.216
    		c-0.229,1.452-0.725,2.895-1.302,4.255c-3.012,7.094-3.141,14.14,0.115,21.193c0.626,1.356,1.087,2.788,1.693,4.153
    		c1.708,3.843,4.612,6.103,8.878,6.223c9.489,0.267,18.984,0.369,28.473,0.679c3.471,0.113,6.943,0.509,10.388,0.974
    		c5.588,0.756,11.169,1.588,16.723,2.559c3.261,0.569,6.602,1.108,9.669,2.281c15.512,5.93,31.041,11.832,46.377,18.192
    		c13.939,5.781,27.678,12.059,41.424,18.293c4.197,1.904,8.074,4.501,12.193,6.591c8.581,4.354,17.271,8.491,25.828,12.893
    		c6.052,3.112,11.962,6.502,17.924,9.789c1.724,0.95,3.378,2.025,5.114,2.949c2.64,1.404,4.426,3.555,3.303,6.46
    		c-2.375,6.152-4.947,12.259-7.912,18.146c-3.885,7.71-8.776,14.562-17.854,16.981c-3.501,0.933-5.074,3.716-6.648,6.875
    		c-5.674,11.386-13.936,20.27-26.354,24.354c-2.933,0.965-6.287,0.571-9.347,1.255c-1.744,0.39-3.768,1.315-4.817,2.662
    		c-2.226,2.854-3.71,6.275-5.832,9.224c-5.039,7.004-10.557,13.415-20.403,12.867c-0.332-0.019-0.679,0.056-0.998-0.013
    		c-10.887-2.34-17.206,4.009-22.226,12.131c-2.832,4.582-6.399,7.944-11.143,10.349c-15.624,7.918-12.877,8.999-30.595,1.533
    		c-3.155-1.329-5.653-1.307-8.124,1.252c-1.369,1.418-2.954,2.826-4.72,3.625c-3.982,1.804-5.392,4.474-5.156,8.926
    		c0.412,7.81,0.115,15.657,0.115,23.489C668.763,915.506,668.736,915.506,668.709,915.506z M609.935,165.03
    		c-3.917,0.657-7.841,1.281-11.728,2.082c-0.176,0.036-0.346,1.848,0.03,2.528c2.738,4.952,5.286,10.245,12.405,9.59
    		c0.445-0.041,1.08,0.172,1.369,0.495c2.746,3.077,6.347,2.49,9.788,2.213c1.903-0.153,3.765-0.83,5.645-1.271
    		c-1.31-0.997-2.966-1.764-3.829-3.058c-0.854-1.282-1.605-3.36-1.134-4.611c0.585-1.553,2.407-2.866,4.489-1.329
    		c0.531,0.392,1.041,1.121,1.577,1.136c1.229,0.035,2.885,0.146,3.592-0.557c0.797-0.791,1.082-2.487,0.912-3.694
    		c-0.082-0.586-1.678-1.253-2.645-1.357c-1.797-0.194-3.444-0.225-3.078-2.728c0.435-2.969-1.462-2.053-2.752-1.492
    		c-4.504,1.958-8.321,1.298-11.486-2.578c-1.15-1.409-2.615-2.616-3.518-4.16c-1.156-1.979-1.904-1.848-3.188-0.171
    		c-0.482,0.63-1.585,0.784-2.404,1.156c-0.274-0.896-0.998-1.993-0.71-2.636c0.369-0.819,1.48-1.454,2.409-1.832
    		c0.932-0.379,2.711-0.038,2.939-0.544c0.925-2.055,1.396-4.312,2.034-6.496c-0.416-0.11-0.868-0.389-1.243-0.308
    		c-6.095,1.308-10.039,5.771-13.577,10.261c-1.472,1.868-1.06,5.262-1.303,7.979c-0.018,0.198,1.606,0.544,2.468,0.821
    		c1.219-5.31,5.037-3.647,8.628-3.548c3.202,0.09,4.061,1.57,4.183,4.236L609.935,165.03z M635.511,102.432
    		c0.662,0.665,1.314,1.339,1.988,1.991c1.071,1.038,2.157,2.061,3.236,3.089c0.315-0.353,0.96-0.927,0.895-1.024
    		c-0.908-1.354-1.726-2.887-2.97-3.854c-0.695-0.54-2.14-0.117-3.247-0.128c1.481-3.119-0.93-3.818-2.971-4.414
    		c-1.714-0.5-3.733-1.81-4.892,1.316c-0.409,1.104-2.419,1.712-3.808,2.277c-0.278,0.113-1.562-1.067-1.497-1.522
    		c0.152-1.042,0.691-2.136,1.371-2.964c0.742-0.903,1.82-1.532,2.751-2.28c-1.295-0.635-2.582-1.287-3.888-1.898
    		c-1.041-0.487-2.109-0.914-3.158-1.386c-7.647-3.434-15.494-5.9-24.026-4.587c-1.188,0.184-2.764,0.697-3.335,1.588
    		c-4.632,7.22-3.227,15.352-2.648,23.156c0.135,1.813,3.392,4.026,5.654,4.871c2.173,0.812,4.925,0.174,7.408-0.018
    		c0.701-0.054,1.351-0.774,2.023-1.189c-0.541-0.531-1.185-0.994-1.601-1.61c-0.633-0.937-1.108-1.979-1.65-2.978
    		c1.144-0.268,2.573-1.104,3.378-0.698c2.547,1.283,3.994,0.069,5.681-1.524c0.709-0.671,2.459-1.299,2.94-0.925
    		c0.906,0.706,1.607,2.133,1.671,3.3c0.041,0.751-1.089,1.74-1.909,2.315c-0.782,0.548-2.207,0.43-2.692,1.089
    		c-0.645,0.875-0.651,2.22-0.933,3.362c1.021,0.134,2.168,0.617,3.033,0.31c1.026-0.364,1.764-1.477,2.71-2.146
    		c1.76-1.242,3.02-1.833,3.831,1.231c0.529,1.997,2.041,4.458,3.787,5.282c7.014,3.309,11.604,8.301,13.287,15.937
    		c0.135,0.61,1.067,1.045,1.629,1.562c0.536-0.717,1.404-1.371,1.552-2.16c0.639-3.421-0.373-6.126-3.547-8.041
    		c-0.649-0.392-0.489-2.124-0.703-3.238c1.204,0.018,2.762-0.438,3.544,0.145c2.04,1.521,3.991,3.314,5.48,5.361
    		c0.855,1.177,0.92,3.046,1.013,4.623c0.074,1.284-0.655,2.656-0.43,3.878c0.16,0.86,1.33,2.151,2.038,2.145
    		c1.033-0.01,2.965-1.056,2.939-1.584c-0.168-3.457,1.689-3.319,4.163-2.97c0.928,0.131,1.977-0.603,2.971-0.941
    		c-0.78-0.836-1.415-2.122-2.365-2.426c-3.419-1.093-5.806-2.911-6.723-6.589c-0.231-0.929-1.511-2.008-2.497-2.251
    		c-2.173-0.535-3.308-1.752-3.024-3.822c0.112-0.819,1.259-1.958,2.1-2.125c2.17-0.433,2.947,1.052,3.83,2.944
    		c1.274,2.729,3.197,5.186,5.071,7.58c0.421,0.537,1.786,0.334,2.717,0.472c-0.064-0.867,0.041-1.794-0.231-2.591
    		c-0.416-1.218-0.844-2.593-1.706-3.469c-2.657-2.703-1.731-8.659-7.598-8.427c0.088-0.921,0.217-1.84,0.251-2.763
    		c0.031-0.855,0.292-2.115-0.145-2.476c-0.596-0.491-1.784-0.477-2.664-0.336c-0.556,0.088-0.999,0.817-1.516,1.231
    		c-2.049,1.64-3.835,1.943-3.821-1.398c0.01-2.381-1.502-2.354-3.012-3.105c-1.219-0.605-1.852-2.39-2.743-3.65
    		C633.507,103.463,634.509,102.947,635.511,102.432z M515.215,53.815c-0.146-0.955-0.767-2.37-0.359-2.792
    		c3.468-3.593-0.137-6.56-0.873-9.672c-0.194-0.824-2.323-1.756-3.479-1.655c-1.64,0.143-3.45,0.916-4.661,2.005
    		c-0.445,0.4,0.386,2.588,1.021,3.746c1.304,2.372,2.651,4.792,4.423,6.803c0.829,0.941,2.685,0.98,4.074,1.429
    		c-0.832,1.195-1.402,2.973-2.543,3.488c-5.246,2.373-10.353-1.207-10.23-6.946c0.01-0.492,0.186-0.979,0.267-1.471
    		c0.486-2.936-1.611-3.446-3.65-3.459c-2.165-0.013-2.744,1.695-2.393,3.531c0.201,1.054,0.991,1.979,1.361,3.019
    		c0.857,2.41,2.86,5.38,2.109,7.152c-0.95,2.242-4.025,3.657-7.271,2.103c-0.677-0.323-2.33,0.781-3.193,1.59
    		c-1.671,1.563-2.82,3.713-5.732,3.275c-0.683-0.103-1.644,0.88-2.306,1.536c-5.159,5.121-10.278,10.281-15.405,15.435
    		c-1.284,1.29-2.322,3.161-3.87,3.792c-5.267,2.146-8.217,6.297-10.98,10.918c-2.618,4.38-5.752,8.449-8.543,12.73
    		c-0.571,0.876-0.909,2.096-0.874,3.141c0.147,4.462-1.416,6.252-5.957,6.327c-2.997,0.049-6,0.027-8.994-0.095
    		c-4.751-0.194-6.805,3.668-9.635,6.334c-0.307,0.289-0.303,1.489,0.001,1.75c2.292,1.967,1.209,3.404-0.127,5.456
    		c-0.833,1.279-0.241,3.503-0.237,5.303c0.002,1.441,0.177,2.886,0.111,4.321c-0.198,4.285-0.863,8.588-0.62,12.843
    		c0.269,4.703,4.79,5.98,8.951,3.799c3.452-1.812,5.691-4.277,7.328-7.501c2.548-5.021,1.374-10.299,0.589-15.476
    		c-0.1-0.655-1.612-1.006-2.281-1.684c-1.025-1.04-1.887-2.242-2.818-3.376c1.228-0.937,2.323-2.337,3.706-2.73
    		c5.96-1.696,10.351,1.985,14.634,5.102c2.136,1.553,4.102,4.002,4.955,6.459c0.772,2.222,0.025,4.925,2.523,6.611
    		c0.321,0.217,0.279,1.783-0.111,2.114c-3.255,2.755-2.208,6.458-2.178,9.825c0.059,6.439-6.413,9.065-8.134,14.427
    		c-0.099,0.305-0.912,0.333-1.333,0.588c-4.474,2.704-8.988,5.348-13.351,8.222c-0.798,0.525-1.611,2.279-1.311,3.011
    		c1.593,3.873,3.857,7.485,5.283,11.408c1.753,4.826,5.08,9.224,4.002,14.927c-0.987,5.219,1.59,9.139,5.141,12.784
    		c2.426,2.49,4.25,3.414,6.986,0.529c0.839-0.885,3.296-1.651,3.831-1.168c2.788,2.522,6.062,5.098,7.455,8.381
    		c1.08,2.543,1.521,5.353,2.945,8.049c2.687,5.09,4.188,10.793,6.55,16.076c0.583,1.303,2.506,2.617,3.953,2.797
    		c3.237,0.4,6.103,1.237,9.211,2.362c4.585,1.659,10.427,1.307,15.328,0.127c3.429-0.826,7.377-2.892,9.199-6.959
    		c0.404-0.901,1.927-1.303,2.935-1.934c0.377,1.183,0.88,2.344,1.085,3.556c0.134,0.783-0.552,1.89-0.207,2.423
    		c1.393,2.156,2.771,4.463,4.679,6.104c1.729,1.487,3.228-0.098,4.601-1.511c1.806-1.857,3.642-2.004,5.368,0.449
    		c0.461,0.655,2.311,1.207,2.78,0.845c3.446-2.663,6.821-4.788,11.159-1.8c0.303,0.209,0.953-0.108,1.445-0.146
    		c5.433-0.423,10.508-5.334,11.56-10.993c0.229-1.23,0.747-2.645,1.61-3.481c4.422-4.288,6.371-9.657,7.249-15.552
    		c0.636-4.264,3.487-5.591,7.51-4.004c3.228,1.272,6.698,2.061,9.949-0.346c2.938-2.174,6.01-4.176,8.849-6.47
    		c1.229-0.993,2.157-2.446,2.988-3.831c3.402-5.666,3.505-11.95,3.158-18.272c-0.08-1.45-0.25-3.036-0.897-4.291
    		c-2.58-5.002-2.655-4.962,1.371-9.188c2.939-3.083,3.45-6.76,1.178-10.319c-1.867-2.926-4.058-5.644-5.946-8.557
    		c-0.768-1.183-1.382-2.593-1.612-3.976c-1.037-6.231,0.756-11.445,5.959-15.282c2.074-1.53,4.057-4.118,6.268-4.337
    		c4.633-0.46,8.088-4.842,13.097-3.548c0.357,0.093,0.899-0.995,1.473-1.188c1.253-0.423,2.581-0.623,3.879-0.914
    		c-0.175,1.188-0.229,2.409-0.546,3.558c-1.134,4.115-2.593,8.158-3.452,12.326c-0.474,2.3,0.689,4.089,3.481,4.488
    		c7.943,1.136,15.924-6.471,14.109-14.245c-1.058-4.533-3.522-8.777-5.644-13.007c-1.388-2.765-3.485-3.419-5.918-0.829
    		c-1.531,1.63-2.663,1.688-3.393-0.884c-1.531-5.398-11.673-10.556-16.947-8.774c-0.157,0.054-0.308,0.129-0.464,0.188
    		c-7.2,2.688-7.279,2.757-12.388-3.217c-1.193-1.396-1.7-3.4-2.432-5.162c-0.625-1.504-1.193-3.04-1.647-4.602
    		c-0.49-1.684-1.187-3.426-1.13-5.12c0.088-2.621,1.014-5.222,1.034-7.835c0.01-1.311-0.857-3.179-1.921-3.837
    		c-2.478-1.535-5.334-2.449-8.002-3.69c-3.332-1.552-6.238-3.649-10.4-3.585c-3.314,0.052-5.682-4.809-4.321-7.637
    		c2.778-5.776,5.883-11.389,10.264-16.155c3.916-4.262,3.942-4.235-0.048-8.28c-0.702-0.711-1.298-1.905-2.104-2.064
    		c-2.33-0.462-4.889-1.048-7.051-0.459c-0.884,0.24-1.264,3.365-1.331,5.197c-0.044,1.192,1.214,2.399,1.298,3.64
    		c0.087,1.308-0.108,3.131-0.942,3.88c-1.068,0.96-2.987,1.588-4.366,1.351c-0.812-0.14-1.612-2.178-1.834-3.462
    		c-0.306-1.779-0.348-3.709,0.006-5.473c0.83-4.145-1.831-6.172-4.64-7.985c-2.849-1.839-4.877-0.571-4.503,2.914
    		c0.236,2.196,1.412,4.276,1.956,6.46c0.328,1.315,0.252,2.731,0.357,4.102c-2.08,0.155-4.201,0.598-6.218,0.308
    		c-0.694-0.101-1.89-2.466-1.556-3.049c2.324-4.052-1.002-7.735-0.524-11.646c0.121-0.987-1.088-2.331-1.984-3.207
    		c-1.556-1.521-3.25-1.574-3.27,1.052c-0.021,2.729,0.593,5.467,0.986,8.192c0.322,2.232,1.164,4.503,0.913,6.656
    		c-0.122,1.049-2.103,2.361-3.441,2.659c-1.411,0.313-3.691,0.058-4.423-0.87c-0.691-0.877-0.425-3.451,0.414-4.394
    		c1.778-1.998,2.009-4.006,1.736-6.422c-0.204-1.809-0.066-3.655-0.098-5.484c-0.034-1.986-5.406-6.761-7.262-6.29
    		c-0.619,0.156-1.548,1.153-1.476,1.641c0.47,3.203,1.197,6.368,1.794,9.555c0.312,1.662,1.117,3.582,0.569,4.925
    		c-0.335,0.821-2.8,0.912-4.334,1.054C517.163,54.458,516.178,54.019,515.215,53.815z M624.089,538.146c0-0.004,0-0.008,0-0.013
    		c4.166,0,8.331-0.004,12.497,0.004c1.162,0.002,2.764-0.442,3.409,0.146c2.956,2.694,6.708,2.215,10.04,3.26
    		c2.562,0.804,4.315,4.181,6.428,6.414c-1.866,1.649-3.53,3.655-5.65,4.859c-2.621,1.488-6.284,1.627-8.299,3.583
    		c-2.947,2.86-6.195,2.44-9.533,2.89c-1.042,0.141-2.764,1.128-2.797,1.795c-0.119,2.435-0.229,5.148,0.712,7.295
    		c1.736,3.964,5.749,6.754,5.934,11.682c0.028,0.752,2.213,1.424,3.399,2.132c0.605-1.202,1.741-2.417,1.711-3.604
    		c-0.078-3.066,0.302-6.203-2.148-8.964c-1.13-1.274-1.688-4.117-1.047-5.628c0.772-1.816,3.099-2.973,4.752-4.415
    		c0.462,1.282,1.087,2.531,1.354,3.853c0.942,4.658,1.677,9.359,2.667,14.007c0.444,2.087-2.459,10.052-4.281,10.893
    		c-0.438,0.203-0.958,0.279-1.447,0.312c-2.367,0.158-4.586,0.146-6.261-2.111c-1.301-1.753-2.971-1.646-4.8-0.338
    		c-2.502,1.79-5.018,3.822-7.854,4.798c-2.134,0.734-5.097,0.668-7.144-0.255c-6.853-3.09-6.759-3.299-11.916,1.687
    		c-5.38,5.202-7.377,5.253-12.941,0.823c-2.23-1.774-2.63-4.046-1.65-6.206c0.663-1.461,2.564-2.361,3.912-3.513
    		c0.422,1.391,0.819,2.791,1.275,4.171c0.377,1.136,0.823,2.249,1.239,3.372c2.489-1.391,6.188-2.223,7.153-4.316
    		c1.145-2.482,0.99-6.767-0.51-8.974c-2.076-3.056-1.186-4.501,0.96-6.111c1.034-0.776,2.528-0.94,3.814-1.382
    		c0.387,1.225,1.056,2.438,1.104,3.676c0.138,3.522,0.61,6.897,3.727,9.071c3.595,2.508,7.416,3.785,11.583,1.097
    		c0.96-0.62,2.002-1.171,3.079-1.542c3.355-1.154,4.939-3.781,3.133-6.608c-2.865-4.482-4.083-10.061-9.521-12.945
    		c-3.23-1.715-2.954-4.916-0.381-7.549c1.855-1.899,3.971-3.137,6.67-2.49c7.34,1.76,13.647-3.263,20.748-3.108
    		c0.576,0.013,1.175-1.002,1.764-1.539c-0.718-0.524-1.414-1.463-2.157-1.501c-2.988-0.153-6,0.123-8.984-0.06
    		c-1.917-0.118-3.969-0.413-5.672-1.229c-6.865-3.287-14.037-1.136-21.06-1.445c-0.386-0.017-0.758,0.649-1.204,0.817
    		c-1.76,0.662-3.529,1.311-5.329,1.848c-1.089,0.324-2.456,0.109-3.33,0.691c-4.821,3.208-9.658,6.058-15.841,5.474
    		c-1.323-0.125-2.768,0.938-4.145,1.482c-6.055,2.397-11.87,5.541-18.827,4.452c-2.928-0.458-5.993-0.196-8.984-0.035
    		c-1.62,0.087-3.221,0.625-4.818,1.008c-2.661,0.638-5.296,1.771-7.973,1.873c-4.479,0.171-8.952-1.571-13.504,0.039
    		c-0.952,0.338-2.561,0.101-3.331-0.529c-5.448-4.452-11.953-1.628-17.877-2.693c-2.178-0.392-6.381,4.036-5.503,5.158
    		c2.139,2.733,2.554,7.712,8.001,6.62c0.716-0.144,2.265,0.71,2.347,1.262c0.14,0.945-0.351,2.189-0.975,3.002
    		c-1.522,1.981-3.522,1.773-5.845,1.314c-2.513-0.497-4.086,1.118-4.209,3.734c-0.117,2.481,1.604,3.247,3.607,3.001
    		c1.698-0.208,3.614-0.631,4.914-1.633c2.08-1.603,3.69-1.356,5.19,0.403c1.896,2.225,0.424,3.736-1.498,4.736
    		c-1.854,0.964-3.857,1.672-5.851,2.323c-0.923,0.302-2.006,0.082-2.966,0.312c-2.158,0.516-3.932,1.836-3.17,4.135
    		c0.288,0.872,2.584,1.428,3.994,1.495c1.967,0.093,3.993-0.732,5.934-0.543c1.357,0.133,2.614,1.304,3.917,2.012
    		c-1.16,0.68-2.262,1.503-3.493,2.011c-2.554,1.053-5.306,1.702-7.704,3.015c-0.908,0.497-1.78,2.657-1.417,3.548
    		c0.773,1.894,2.325,1.381,4.126,0.568c4.127-1.862,8.407-4.343,12.74-4.593c9.747-0.562,19.563,0.094,29.353,0.222
    		c1.824,0.023,3.659-0.241,5.476-0.136c7.954,0.467,16.258-2.57,23.796,2.386c0.736,0.483,1.946,0.327,2.938,0.33
    		c13.497,0.032,26.994,0.069,40.491,0.032c3.007-0.008,5.846-0.212,8.4,2.131c0.935,0.856,3.126,0.648,4.688,0.459
    		c6.205-0.753,12.365-1.995,18.588-2.469c5.633-0.428,11.326-0.031,16.99-0.131c1.619-0.028,3.314-0.285,4.828-0.84
    		c4.701-1.723,9.233-3.966,14.012-5.409c8.862-2.676,17.611-5.345,23.472-13.272c0.36-0.487,1.15-0.632,1.638-1.055
    		c1.975-1.714,4.352-3.174,5.771-5.274c3.987-5.896,8.824-11.302,10.318-18.703c0.625-3.101,2.826-5.853,3.946-8.896
    		c0.617-1.676,0.48-3.629,0.684-5.458c-1.279,1.04-2.785,1.899-3.791,3.157c-2.179,2.723-3.872,6.109-8.074,5.74
    		c-1.463-0.128-2.854-1.072-4.279-1.645c1.013-0.653,2.091-1.227,3.026-1.977c2.146-1.72,4.882-2.99,4.877-6.422
    		c-0.001-0.747,0.52-1.539,0.93-2.234c2.815-4.775,5.308-9.559,4.812-15.456c-0.333-3.963,0.015-7.983,0.081-11.978
    		c0.138-8.159,0.125-8.113-7.66-11.505c-0.895-0.389-1.887-1.585-1.976-2.492c-0.084-0.848,0.755-2.214,1.565-2.65
    		c3.616-1.945,12.721,3.214,12.389,7.266c-0.383,4.67,2.881,9.251,0.161,13.883c2.444,5.442-1.082,9.985-2.8,14.495
    		c-1.445,3.796,1.061,5.936,1.547,8.85c0.885-0.229,2.465-0.33,2.535-0.709c0.326-1.778,0.105-3.649,0.333-5.456
    		c0.263-2.094,0.736-4.166,1.215-6.227c0.55-2.363,1.71-4.697,1.73-7.052c0.117-13.374-0.043-26.751-0.077-40.127
    		c-0.042-16.831-0.008-33.661-0.136-50.491c-0.016-2.084-0.8-4.168-1.276-6.24c-0.509-2.213-1.373-4.389-1.551-6.623
    		c-0.33-4.136,0.156-8.367-0.47-12.44c-1.189-7.743-6.102-14.585-5.748-22.786c0.036-0.829-1-1.666-1.398-2.562
    		c-0.52-1.171-1.378-2.455-1.255-3.601c0.458-4.248-1.758-7.299-4.052-10.396c-0.775-1.049-2.011-2.137-2.079-3.264
    		c-0.301-4.979-2.709-8.632-6.357-11.759c-3.76-3.221-7.106-7.012-11.166-9.773c-5.765-3.922-12.166-6.897-18.023-10.697
    		c-8.729-5.662-18.407-9.08-28.034-12.678c-3.226-1.206-6.802-1.871-9.612-3.71c-7.132-4.665-15.155-6.33-23.236-8.016
    		c-0.971-0.203-1.995-0.278-2.899-0.646c-10.589-4.306-19.234-11.575-27.967-18.652c-2.368-1.919-4.052-2.463-6.414-0.24
    		c-2.119,1.994-4.636,3.204-7.742,2.24c-0.475-0.147-1.135,0.3-1.709,0.472c0.521,0.484,0.977,1.251,1.576,1.388
    		c0.94,0.215,2.083-0.284,2.951,0.033c1.763,0.646,4.428,1.323,4.807,2.569c0.405,1.335-1.212,3.466-2.3,4.999
    		c-0.59,0.831-1.858,1.323-2.92,1.65c-3.805,1.176-3.589,1.014-1.744,4.858c0.829,1.728,0.893,4.053,0.619,6.011
    		c-0.233,1.672-1.377,3.246-2.256,4.784c-0.377,0.659-1.202,1.665-1.656,1.583c-4.279-0.769-6.804,2.736-10.229,3.911
    		c-2.229,0.765-2.979,1.93-2.863,3.939c0.151,2.633-0.228,4.624-3.542,4.723c-0.844,0.025-1.725,0.848-2.48,1.44
    		c-3.389,2.66-4.095,2.402-5.954-1.619c-0.553-1.194-1.704-2.395-2.879-2.904c-0.619-0.269-2.597,0.843-2.69,1.51
    		c-0.148,1.062,0.521,2.479,1.278,3.378c1.526,1.811,1.749,3.25-0.591,4.328c-4.116,1.896-6.429,7.081-11.893,6.514
    		c-0.119-0.012-0.245,0.233-0.394,0.286c-1.592,0.563-3.188,1.112-4.784,1.665c-0.486-1.642-0.9-3.31-1.486-4.916
    		c-0.397-1.092-1.035-2.097-1.566-3.141c-0.654,1.02-1.646,1.964-1.893,3.074c-0.47,2.111,0.588,4.646-3.093,5.198
    		c-2.036,0.306-3.759,2.741-5.792,3.029c-3.745,0.53-7.614,0.244-11.431,0.192c-6.507-0.09-7.303-1.323-6.18-7.702
    		c0.284-1.612-0.904-3.483-1.416-5.235c-0.717,1.345-1.467,2.673-2.129,4.044c-0.208,0.432-0.073,1.026-0.277,1.462
    		c-1.849,3.946-4.781,7.262-8.919,8.062c-1.818,0.352-4.421-2.236-6.319-3.89c-0.95-0.828-1.464-2.527-1.566-3.878
    		c-0.175-2.307,0.313-4.662,0.151-6.971c-0.078-1.116-0.998-2.173-1.537-3.256c-0.672,0.803-1.547,1.509-1.979,2.425
    		c-2.401,5.071-5.932,8.562-11.758,9.425c-4.224,0.625-6.596-1.636-5.109-5.641c0.992-2.673,0.564-4.036-2.214-3.812
    		c-3.044,0.246-5.188-1.484-6.04-3.575c-1.613-3.962-1.735-8.673-7.009-10.043c-0.223-0.058-0.141-0.968-0.363-1.396
    		c-1.396-2.693-6.419-4.649-9.136-3.598c-3.608,1.396-5.699-0.146-5.598-4.047c0.072-2.797-0.073-5.602,0.067-8.393
    		c0.072-1.435,0.241-3.027,0.921-4.236c1.675-2.98,0.308-3.653-2.304-3.332c-2.971,0.366-4.921-0.243-4.917-3.737
    		c0-0.565-0.497-1.272-0.969-1.675c-2.902-2.482-0.984-5.051,0.192-7.177c2.153-3.891,2.017-3.854-1.296-6.424
    		c-2.071-1.605-2.697-0.961-4.319,0.341c-4.236,3.399-8.344,7.503-13.247,9.404c-7.801,3.025-15.761,5.663-23.394,9.148
    		c-2.357,1.076-4.673,2.278-7.105,3.146c-8.473,3.021-17.008,5.87-25.477,8.902c-2.171,0.777-4.268,1.851-6.259,3.024
    		c-4.611,2.718-9.681,4.98-13.579,8.515c-6.871,6.228-13.242,13.06-19.379,20.027c-5.5,6.244-13.386,10.313-16.778,18.474
    		c-0.803,1.931-1.557,4.058-2.929,5.542c-4.273,4.622-6.625,10.031-7.745,16.126c-0.162,0.882-0.815,1.896-1.548,2.415
    		c-5.429,3.842-4.974,10.435-6.967,15.721c-1.958,5.19-4.211,10.746-3.347,16.711c0.079,0.547-0.553,1.168-0.749,1.787
    		c-0.728,2.302-1.508,4.594-2.085,6.935c-0.662,2.688-1.027,5.449-1.701,8.134c-1.325,5.281-3.772,10.495-3.958,15.801
    		c-0.36,10.267-0.988,20.451-2.48,30.617c-1.156,7.883-1.562,15.884-2.921,23.726c-0.872,5.028-1.443,9.796,0.333,14.717
    		c0.46,1.275-0.096,2.895-0.068,4.354c0.094,5.017-0.662,10.112,2.506,14.71c1.212,1.758,1.781,4.086,2.181,6.24
    		c0.373,2.011,0.825,3.521,3.165,3.749c0.795,0.077,1.861,0.197,2.294,0.72c3.527,4.261,7.925,7.303,12.761,9.892
    		c6.093,3.261,12,6.872,18.095,10.13c4.461,2.385,9.099,4.438,13.651,6.65c6.277,3.05,11.667,8.174,19.268,8.164
    		c0.722-0.001,1.41,0.829,2.173,1.114c1.651,0.616,3.325,1.185,5.019,1.67c1.886,0.541,3.82,0.908,5.703,1.457
    		c5.926,1.727,11.192,5.514,17.966,4.652c3.453-0.439,7.121,0.653,10.671,1.19c3.055,0.463,6.061,1.271,9.122,1.654
    		c3.918,0.49,7.891,0.559,11.802,1.091c3.063,0.416,6.047,1.408,9.107,1.871c2.61,0.396,5.287,0.333,7.922,0.594
    		c6.552,0.649,12.824,3.188,19.624,2.456c2.869-0.309,5.864,0.781,8.819,0.998c4.758,0.349,9.561,0.939,14.288,0.614
    		c4.971-0.342,9.747-1.393,14.721,0.63c2.099,0.854,4.904,0.235,7.345-0.067c4.902-0.607,9.805-1.31,14.645-2.281
    		c2-0.401,4.11-1.277,5.641-2.566c0.776-0.653,0.375-2.703,0.506-4.122c-1.164,0.055-2.342,0.026-3.489,0.197
    		c-0.628,0.094-1.253,0.829-1.8,0.748c-1.968-0.295-5.265-0.358-5.591-1.334c-0.955-2.86,2.146-3.521,4.064-4.662
    		c0.27-0.16,0.682-0.282,0.96-0.197c5.521,1.672,6.149-2.756,7.592-6.105c0.747-1.736-0.104-2.748-1.804-2.363
    		c-2.046,0.462-3.935,1.628-5.981,2.077c-1.292,0.283-2.91,0.14-4.074-0.436c-0.748-0.371-1.57-2.295-1.246-2.782
    		c0.714-1.072,2.03-2.074,3.283-2.395c3.688-0.941,7.482-1.454,11.191-2.325c0.938-0.22,1.711-1.146,2.56-1.749
    		c-0.997-0.645-1.952-1.733-3.002-1.837c-1.786-0.174-3.637,0.434-5.451,0.385c-0.788-0.021-1.755-0.71-2.235-1.384
    		c-0.201-0.281,0.497-1.717,1.057-1.936c2.25-0.88,4.648-1.378,6.903-2.246c1.075-0.414,1.953-1.341,2.921-2.036
    		c-0.88-1.036-1.672-2.84-2.658-2.953c-2.195-0.253-4.482,0.449-6.742,0.568c-6.093,0.321-12.362,1.503-18.228,0.462
    		c-3.91-0.694-7.385-4.441-10.811-7.132c-5.412-4.251-10.609-8.776-15.922-13.155c-1.257-1.037-2.505-2.163-3.938-2.896
    		c-7.124-3.64-14.267-7.25-21.486-10.694c-5.3-2.528-10.684-4.896-16.113-7.132c-5.908-2.434-12.818-1.794-18.221-5.849
    		c-6.467,1.262-12.121-2.26-18.232-3.092c-0.163-0.021-0.333,0.002-0.499,0.015c-11.943,0.945-24.248-2.227-35.804,3.14
    		c-4.673-1.162-8.231,1.764-12.215,3.242c-5.463,2.029-10.379,4.732-14.152,9.321c-0.779,0.947-2.112,2.062-3.175,2.046
    		c-4.388-0.065-8.95,0.119-13.11-1.021c-5.48-1.501-11.334-1.399-16.535-4.657c-2.197-1.376-3.424-2.104-3.02-4.536
    		c0.322-1.938,0.93-3.826,1.296-5.759c0.543-2.873,1.729-5.883,1.294-8.621c-0.642-4.043-0.272-7.396,2.078-10.783
    		c0.733-1.057,0.906-3.611,0.177-4.38c-3.024-3.186-1.771-6.936-1.961-10.468c-0.137-2.546,1.473-3.503,3.878-3.352
    		c2.646,0.167,5.303,0.218,7.935,0.507c0.875,0.097,2.148,0.594,2.396,1.234c0.24,0.619-0.408,1.862-1.025,2.462
    		c-0.797,0.776-1.933,1.636-2.953,1.674c-4.38,0.167-5.309,1.431-1.912,4.696c0.396,0.381,0.247,1.279,0.48,1.888
    		c1.223,3.187,3.209,5.278,6.96,5.288c2.82,0.007,5.673,0.178,8.438,0.684c0.921,0.169,1.61,1.604,2.405,2.459
    		c-0.833,0.684-1.684,1.97-2.497,1.947c-3.604-0.101-7.276-0.225-10.78-0.994c-6.107-1.341-7.588-0.441-7.568,5.782
    		c0.005,1.556,0.526,3.11,0.81,4.666c1.356-0.946,2.684-1.939,4.079-2.823c1.028-0.652,2.146-1.162,3.224-1.735
    		c0.298,1.036,0.786,2.06,0.853,3.109c0.137,2.156,0.146,4.332,0.02,6.488c-0.087,1.491,0.019,3.525-0.862,4.326
    		c-1.68,1.526-3.237,0.097-4.68-1.246c-0.65-0.605-1.941-0.523-2.941-0.753c-0.117,1.104-0.729,2.526-0.262,3.257
    		c1.622,2.538,3.633,4.737,7.107,4.704c4.331-0.042,8.674,0.24,12.989-0.011c1.404-0.081,3.182-1.065,4.026-2.203
    		c4.353-5.861,11.479-6.592,17.349-9.535c7.04-3.529,15.7-1.554,22.65-5.964c0.607-0.385,1.627-0.151,2.455-0.151
    		c7.832-0.002,15.665-0.043,23.495,0.06c1.414,0.019,2.832,0.601,4.228,0.995c1.855,0.524,3.658,1.337,5.545,1.635
    		c2.921,0.462,6.58-0.382,8.684,1.096c3.068,2.156,5.898,2.599,9.291,2.289c0.914-0.084,1.934,0.296,2.814,0.675
    		c4.062,1.745,8.024,4.818,12.151,5.044c5.293,0.288,8.855,3.401,13.233,5.115c3.62,1.418,7.208,3.328,10.972,3.847
    		c3.231,0.446,5.671,1.641,7.936,3.644c8.692,7.689,17.358,15.41,26.038,23.114c2.43,2.156,5.03,3.776,8.53,3.209
    		c3.725-0.604,7.469-1.428,11.216-1.491c5.205-0.089,10.439,0.251,15.629,0.75c7.711,0.74,15.389,1.823,23.092,2.658
    		c1.689,0.184,3.426-0.064,5.142-0.113c-1.182-1.481-2.3-3.021-3.584-4.407c-0.377-0.407-1.218-0.499-1.855-0.528
    		c-2.771-0.129-3.348-1.865-2.94-4.122c0.461-2.554,2.286-2.947,4.545-2.583c4.838,0.78,7.112,3.304,6.997,8.349
    		c-0.048,2.055,0.46,3.264,2.591,3.352c4.451,0.183,8.9,1.14,13.275-0.977c1.7-0.822,3.879-0.842,5.848-0.888
    		c4.329-0.1,8.669,0.146,12.994-0.015c1.937-0.071,3.937-0.55,5.756-1.242c4.501-1.712,8.273-5.424,13.63-4.812
    		c0.552,0.062,1.187-0.511,1.768-0.817c4.085-2.156,7.649-5.591,12.813-5.098c0.579,0.056,1.446-0.279,1.779-0.729
    		c1.909-2.581,4.586-2.227,7.261-2.174C616.427,538.202,620.259,538.146,624.089,538.146z M655.724,686.715
    		c0.001-0.09,0.001-0.18,0.002-0.269c-1.5,0-3.047,0.238-4.491-0.047c-3.552-0.703-6.04,0.492-8.043,3.466
    		c-3.132,4.65-6.792,8.97-9.644,13.777c-3.394,5.721-8.038,9.981-13.413,13.647c-1.897,1.295-3.875,2.532-5.55,4.084
    		c-3.655,3.388-7.003,7.114-10.731,10.413c-3.702,3.274-4.21,8.671-8.445,11.763c-1.183,0.863-0.771,5.008,0.059,7.215
    		c0.824,2.195,3.193,3.317,5.84,1.724c2.086-1.256,4.315-2.277,6.378-3.568c1.638-1.024,3.458-2.261,4.623-0.053
    		c0.473,0.896-0.434,3.305-1.438,4.052c-3.59,2.675-7.395,4.796-12.203,5.113c-6.316,0.417-9.729-2.562-10.302-8.907
    		c-0.517-5.727,1.142-10.033,5.787-13.293c0.678-0.476,1.481-0.985,1.818-1.679c0.437-0.897,0.463-1.994,0.666-3.006
    		c-1.105-0.089-2.612-0.676-3.24-0.171c-1.757,1.412-3.076,3.353-4.722,4.924c-0.679,0.648-1.75,0.886-2.643,1.311
    		c0.14-0.971,0.167-1.977,0.454-2.902c0.264-0.853,0.807-1.618,1.227-2.423c-1.013-0.372-2.025-1.062-3.039-1.062
    		c-4.997-0.007-9.998,0.088-14.989,0.331c-1.437,0.07-2.838,0.774-4.266,1.15c-2.015,0.529-4.034,1.441-6.06,1.467
    		c-13.83,0.174-27.667-0.016-41.492,0.292c-5.353,0.119-10.676,1.294-16.025,1.855c-3.456,0.362-7.292,1.533-10.335,0.503
    		c-6.789-2.299-13.674-2.359-20.589-2.546c-7.827-0.213-15.666,0.022-23.497-0.086c-1.765-0.024-3.528-0.634-5.279-1.032
    		c-2.689-0.612-5.339-1.592-8.058-1.845c-5.025-0.469-10.351,1.103-14.84-2.611c-0.552-0.456-1.61-0.474-2.415-0.419
    		c-3.899,0.27-7.412-1.651-10.923-2.164c-5.691-0.831-11.035-2.581-16.54-3.865c-1.719-0.401-3.369-1.096-5.055-1.642
    		c-1.388-0.45-2.778-1.24-4.173-1.253c-4.7-0.043-8.615-2.489-11.661-5.246c-4.112-3.723-7.336-8.528-10.495-13.173
    		c-3.008-4.423-8.947-7.536-7.072-14.417c0.092-0.34-0.359-0.948-0.708-1.268c-2.14-1.963-2.271-3.749-0.037-5.865
    		c1.017-0.964,1.539-2.513,2.102-3.872c0.381-0.921,0.441-1.974,0.646-2.968c-1.119,0.027-2.34-0.222-3.335,0.145
    		c-1.664,0.611-3.14,2.21-4.773,2.333c-3.626,0.272-7.27-0.25-10.368,2.473c-0.693,0.609-2.229,0.256-3.375,0.359
    		c-1.488,0.134-3.565-0.296-4.366,0.521c-4.587,4.678-12.116,0.312-16.372,5.56c-4.395-0.516-8.129,1.774-12.104,2.668
    		c-8.088,1.817-15.341,7.042-24.089,6.122c-0.705-0.073-1.465,0.71-2.244,0.955c-2.104,0.66-4.226,1.27-6.354,1.85
    		c-1.431,0.391-2.903,0.635-4.325,1.052c-2.661,0.781-5.259,2.136-7.957,2.357c-4.793,0.394-8.344,1.696-9.398,7.035
    		c-0.254,1.285-1.919,2.236-2.714,3.481c-2.027,3.175-4.483,6.237-5.678,9.72c-0.749,2.183-0.244,5.464,0.958,7.515
    		c2.78,4.745,6.211,9.132,9.624,13.471c1.168,1.486,3.035,2.442,4.641,3.557c1.089,0.756,2.395,1.227,3.406,2.063
    		c6.627,5.485,13.258,10.97,19.772,16.588c4.483,3.866,8.624,7.952,13.696,11.314c4.755,3.153,7.675,5.618,12.417,0.737
    		c1.043-1.073,3.641-0.66,5.532-0.83c0.134-0.012,0.596,1.118,0.479,1.217c-1.568,1.336-3.157,2.664-4.861,3.816
    		c-0.883,0.598-2.003,0.842-3.016,1.246c0.754,1.011,1.316,2.298,2.299,2.976c2.136,1.472,4.547,2.535,6.731,3.944
    		c2.627,1.694,4.928,4.062,7.744,5.254c3.85,1.629,7.35,3.447,10.027,6.738c0.563,0.691,1.745,1.358,2.541,1.255
    		c0.93-0.12,1.749-1.097,2.616-1.702c-0.578-0.674-1.041-1.525-1.756-1.985c-1.726-1.109-3.596-1.991-5.358-3.048
    		c-2.08-1.247-1.917-3.187-0.749-4.73c1.354-1.789,2.966-1.188,4.734,0.035c1.483,1.026,3.663,2.165,5.18,1.796
    		c3.339-0.811,6.452-2.543,9.659-3.896c0.964-0.407,2.231-1.406,2.844-1.101c2.269,1.133,4.439,2.559,6.363,4.206
    		c0.426,0.364,0.04,2.434-0.608,3.078c-0.633,0.629-2.086,0.593-3.166,0.545c-0.536-0.023-0.988-0.9-1.574-1.085
    		c-1.054-0.331-2.18-0.431-3.274-0.627c0.291,1.171,0.271,2.593,0.957,3.445c0.782,0.97,2.574,1.18,3.236,2.178
    		c0.779,1.175,0.816,2.842,1.176,4.296c-1.504,0.056-3.123,0.479-4.477,0.055c-1.139-0.357-1.895-1.8-2.955-2.578
    		c-0.851-0.624-1.903-0.973-2.867-1.443c0.432,1.098,0.595,2.445,1.343,3.247c2.596,2.782,5.573,5.225,8.021,8.121
    		c0.849,1.005,0.577,2.956,0.817,4.476c-1.504,0.188-3.799,1.097-4.386,0.451c-4.568-5.019-11.465-5.45-16.798-8.943
    		c-5.104-3.344-10.851-5.707-15.957-9.048c-4.859-3.18-9.184-7.17-13.925-10.549c-0.863-0.615-2.802-0.375-3.846,0.198
    		c-0.5,0.274-0.339,2.331,0.007,3.428c0.431,1.364,1.442,2.532,2.063,3.852c2.534,5.38,3.974,11.517,11.153,13.148
    		c3.092,0.703,3.395,3.324,1.307,5.769c-2.182,2.554-3.861,0.941-5.417-0.585c-4.692-4.604-9.331-9.266-13.916-13.978
    		c-2.449-2.518-2.798-8.986-0.804-12.03c2.674-4.082,2.243-4.595-1.77-7.244c-6.076-4.011-12.305-8.015-17.559-12.984
    		c-5.346-5.057-13.229-7.205-16.557-14.509c-0.17-0.374-0.86-0.482-1.25-0.788c-7.026-5.505-13.892-11.152-17.889-19.459
    		c-0.326-0.678-1.063-1.387-1.763-1.617c-3.303-1.091-6.622-2.188-10.013-2.924c-1.33-0.288-3.062-0.003-4.271,0.651
    		c-6.631,3.584-10.513,3.326-15.82-1.729c-1.642-1.564-2.644-3.791-4.247-5.41c-1.143-1.153-2.761-2.419-4.238-2.513
    		c-1.854-0.118-3.909,0.646-5.649,1.496c-4.163,2.033-9.346,0.854-13.041,4.245c-0.092,0.085-0.324,0.009-0.49,0.022
    		c-8.188,0.67-8.188,0.671-8.431,8.832c-0.01,0.333-0.013,0.666-0.021,1c-0.166,7.109,3.268,11.182,10.229,11.229
    		c3.915,0.026,5.013,1.458,4.47,5.044c-1.385,9.148,2.812,17.003,7.608,23.788c6.224,8.806,15.16,15.243,24.34,21.017
    		c1.079,0.679,2.537,1.098,3.792,1.041c0.906-0.041,1.768-1.071,2.649-1.66c-0.695-0.749-1.283-1.657-2.109-2.211
    		c-1.586-1.062-3.674-1.584-4.913-2.923c-8.052-8.698-17.676-15.782-24.084-26.251c-3.515-5.742-5.658-10.082-1.607-16.008
    		c1.547-2.265,0.432-3.031-2.267-3.623c-3.648-0.801-7.149-2.397-10.608-3.895c-1.942-0.841-1.824-2.546-1.737-4.608
    		c0.219-5.186,4.469-7.195,7.435-10.02c1.987-1.893,7.65-0.522,7.099,0.394c-1.152,1.914-3.206,3.3-4.956,4.825
    		c-0.318,0.277-1.008,0.079-1.441,0.282c-2.312,1.079-2.689,3.419-0.896,5.299c3.116,3.266,9.763,4.074,13.464,1.49
    		c2.453-1.714,3.183-0.461,3.633,1.758c0.694,3.417-1.167,5.644-3.409,7.797c-2.266,2.175-4.306,4.413-0.941,7.475
    		c0.71,0.646,0.984,2.123,0.934,3.192c-0.232,4.954,2.656,8.732,5.52,11.902c3.7,4.094,8.572,7.116,12.849,10.707
    		c2.413,2.026,4.438,4.561,6.996,6.357c7.8,5.479,15.729,10.782,23.702,16.009c3.48,2.282,6.323,6.07,11.338,5.204
    		c0.583-0.102,1.315,0.878,2.028,1.264c2.523,1.36,5.069,2.681,7.606,4.017c0.675-1.562,1.303-3.146,2.058-4.669
    		c0.19-0.384,0.845-0.508,1.187-0.85c1.47-1.472,2.963-2.929,4.334-4.49c1.754-1.997,3.312-4.176,5.146-6.092
    		c0.553-0.577,1.987-0.982,2.583-0.66c0.59,0.318,0.953,1.697,0.837,2.531c-0.175,1.26-0.605,2.638-1.36,3.638
    		c-2.476,3.274-5.004,6.533-7.781,9.55c-3.095,3.363-3.194,3.358,1.013,4.617c0.614,0.184,1.206,0.486,1.765,0.807
    		c2.801,1.605,5.542,3.326,8.412,4.794c0.755,0.386,1.897,0.017,2.862-0.005c-0.157-0.857-0.027-1.973-0.524-2.522
    		c-1.609-1.779-0.584-2.731,0.614-4.16c2.523-3.009,5.791-2.044,8.833-1.956c1.283,0.037,3.381,0.774,3.618,1.614
    		c0.379,1.347-0.155,3.221-0.884,4.556c-1.509,2.766-4.321,2.605-7.046,2.618c-0.781,0.003-1.558,0.831-2.337,1.281
    		c0.553,0.569,1.003,1.347,1.673,1.675c8.019,3.925,16.056,7.81,24.118,11.642c2.519,1.196,5.06,2.465,7.73,3.154
    		c0.988,0.255,2.964-0.75,3.479-1.718c1.503-2.827,2.201-6.096,3.798-8.854c0.981-1.696,2.831-3.396,4.647-3.977
    		c2.433-0.779,3.224,1.352,3.716,3.536c0.505,2.238,2.467,2.614,3.754,1.098c2.703-3.182,5.988-0.815,8.931-1.333
    		c0.557-0.098,1.988,1.433,1.963,2.175c-0.037,1.062-0.874,2.268-1.703,3.065c-0.447,0.43-1.589,0.164-2.417,0.168
    		c-4.295,0.018-4.338,0.034-2.342,3.959c1.02,2.003,0.74,3.584-1.283,4.581c-1.876,0.924-3.051,0.12-3.917-1.735
    		c-0.901-1.93-1.84-4.005-3.324-5.433c-0.793-0.763-3.909-0.679-4.03-0.318c-0.823,2.431-1.299,5.021-1.545,7.594
    		c-0.232,2.424,1.866,2.545,3.558,3.325c2.92,1.348,5.393,3.761,9.052,2.866c0.465-0.114,1.126,0.595,1.703,0.91
    		c4.64,2.529,8.762,6.349,14.734,5.424c2.31-0.357,2.592,1.424,1.672,2.848c-0.681,1.054-2.142,2.013-3.384,2.214
    		c-3.116,0.506-5.802-0.597-8.79-1.968c-7.848-3.6-16.173-6.148-24.244-9.278c-2.51-0.974-4.828-2.448-7.215-3.729
    		c-5.1-2.734-10.164-5.537-15.286-8.229c-5.685-2.986-11.312-6.115-17.16-8.749c-5.839-2.63-11.938-4.68-17.89-7.07
    		c-4.241-1.702-8.438-3.518-12.63-5.337c-3.41-1.48-7.031-2.663-10.114-4.661c-4.225-2.738-8.008-6.153-12.04-9.2
    		c-0.552-0.417-1.446-0.382-2.182-0.557c-0.069,0.754-0.496,1.844-0.152,2.21c3.552,3.783,6.29,8.51,12.347,9.269
    		c1.644,0.206,3.69,2.38,4.376,4.115c2.399,6.07,5.511,10.893,12.881,11.098c0.893,0.024,1.732,0.891,2.654,1.186
    		c2.004,0.643,4.017,1.462,6.081,1.669c2.634,0.265,5.323-0.067,7.987-0.026c4.54,0.071,6.472,2.188,6.635,6.775
    		c0.056,1.581,0.139,3.592,1.073,4.63c2.862,3.181,5.796,6.472,10.702,6.58c1.209,0.027,2.396,0.926,3.6,1.405
    		c4.027,1.604,7.694,3.04,11.987-0.389c1.745-1.393,5.736-0.783,8.345,0.049c1.365,0.435,2.842,3.383,2.725,5.091
    		c-0.411,5.999,3.964,8.817,7.758,11.144c7.581,4.649,15.823,2.992,23.564-0.103c5.932-2.371,11.559-5.503,17.314-8.311
    		c0.441-0.216,1.039-0.453,1.205-0.836c1.32-3.048,3.148-6.036,3.677-9.231c0.833-5.034,3.464-8.379,7.557-10.967
    		c1.545-0.977,2.88-2.404,4.783-0.505c2.101,2.097,0.495,3.625-0.645,5.2c-2.291,3.166-4.725,6.232-6.948,9.443
    		c-0.646,0.934-0.78,2.222-1.149,3.347c1.244,0.201,2.488,0.58,3.73,0.57c3.492-0.026,7.045-0.652,10.45-0.168
    		c2.012,0.286,4.258,1.887,5.49,3.563c0.716,0.975,0.22,3.799-0.748,4.875c-3.326,3.698-8.059,4.877-12.774,5.746
    		c-0.872,0.161-1.912-0.389-2.833-0.726c-3.693-1.352-7.287-3.088-11.08-4.014c-2.68-0.653-5.644-0.446-8.435-0.166
    		c-0.872,0.088-2.082,1.562-2.3,2.582c-0.986,4.604,2.432,8.546,7.362,8.961c1.271,0.107,2.666,0.379,3.729,1.025
    		c5.886,3.574,14.965,1.969,18.86-3.463c2.791-3.892,3.771-3.921,7.281-0.509c3.647,3.545,7.087,7.059,11.906,9.498
    		c7.188,3.639,14.076,3.004,20.959,0.711c5.48-1.826,8.146-1.104,10.163,4.617c2.014,5.711,8.144,10.039,12.758,8.475
    		c5.212-1.767,10.633-3.497,15.182-6.448c3.305-2.145,4.444-1.684,6.743,0.938c5.993,6.836,13.424,10.421,22.839,8.789
    		c2.804-0.486,4.742-1.309,4.609-4.62c-0.145-3.597,2.055-4.781,5.173-4.151c1.644,0.332,3.388,1.184,4.628,2.306
    		c3.664,3.318,3.575,3.417,5.503-1.044c1.926-4.455,4.79-4.793,8.25-1.109c0.978,1.041,2.312,1.753,3.507,2.583
    		c4.824,3.354,9.98,4.182,15.892,3.399c3.681-0.487,6.773-1.491,9.538-3.678c4.259-3.367,5.091-3.439,9.277,0.007
    		c4.663,3.839,16.562,4.991,22.054,2.103c2.867-1.507,5.629-3.232,8.562-4.593c7.061-3.275,12.171,1.695,17.56,4.668
    		c3.165,1.746,5.785,1.988,8.708,0.054c0.818-0.543,1.592-1.213,2.487-1.569c5.329-2.125,10.211-4.391,13.729-9.604
    		c3.101-4.595,6.754-5.127,10.435-1.319c0.998,1.032,2.825,1.546,4.341,1.729c2.299,0.276,4.689-0.2,6.984,0.089
    		c7.726,0.974,13.556-2.553,18.818-7.565c2.854-2.719,2.924-3.435-0.185-5.72c-1.208-0.889-2.978-1.012-4.486-1.496
    		c-2.921-0.938-6.103-1.424-8.71-2.914c-8.826-5.044-13.896-13.551-18.923-21.937c-0.624-1.042,0.559-3.167,0.903-4.791
    		c1.356,0.619,3.207,0.876,3.974,1.926c2.015,2.762,3.74,5.778,5.257,8.854c5.163,10.463,15.606,12.423,25.181,15.733
    		c0.821,0.284,2.102-0.643,3.131-1.095c3.576-1.569,7.002-3.711,10.733-4.649c5.943-1.495,11.767-3.147,17.314-5.781
    		c6.989-3.318,14.147-6.299,21.018-9.842c6.681-3.445,13.038-7.513,19.642-11.115c2.84-1.549,6.116-2.326,8.874-3.983
    		c2.508-1.508,4.892-3.492,6.678-5.776c0.612-0.782-0.764-3.105-1.205-4.731c-0.174-0.64-0.241-1.309-0.419-1.947
    		c-1.095-3.929-2.153-7.87-3.366-11.763c-0.209-0.672-1.017-1.263-1.683-1.672c-3.819-2.341-5.782-9.7-3.42-13.344
    		c0.398-0.616,1.249-0.948,1.91-1.383c1.412-0.931,3.138-1.582,4.2-2.81c3.143-3.634,4.78-4.021,8.825-1.464
    		c0.56,0.354,1.107,0.739,1.706,1.012c4.872,2.221,5.536,6.176,1.641,9.984c-1.19,1.165-2.321,2.428-3.661,3.395
    		c-4.399,3.176-3.732,7.787-2.565,11.78c0.802,2.744,2.773,5.062,2.428,8.316c-0.174,1.63,1.396,2.521,2.682,1.157
    		c2.415-2.563,5.92-3.664,8.294-5.784c5.933-5.3,12.146-10.2,18.24-15.28c8.639-7.202,16.352-15.508,24.565-23.229
    		c1.738-1.634,2.441-3.052,2.048-5.592c-0.786-5.067,1.09-8.298,5.103-10.264c2.522-1.235,5.369,0.052,4.938,2.634
    		c-0.693,4.158-1.097,9.149-3.616,12.067c-7.028,8.144-14.937,15.557-22.779,22.957c-5.179,4.887-10.701,9.442-16.335,13.803
    		c-5.728,4.434-11.744,8.499-17.707,12.62c-2.911,2.013-5.981,3.795-8.988,5.668c-2.031,1.265-4.081,2.499-6.115,3.758
    		c-3.428,2.123-6.779,4.381-10.295,6.346c-3.25,1.816-6.69,3.288-10.008,4.989c-0.934,0.478-2.201,1.055-2.503,1.882
    		c-1.216,3.339-3.736,4.613-6.903,4.384c-3.029-0.219-5.003,1.099-6.933,3.11c-1.109,1.157-2.602,2.834-3.875,2.795
    		c-5.589-0.173-9.505,4.294-14.794,4.867c-2.044,0.222-3.996,1.38-5.967,2.168c-3.743,1.498-7.464,3.053-11.207,4.551
    		c-1.146,0.458-2.336,0.807-3.506,1.205c0.776,1.13,1.328,2.594,2.387,3.307c1.295,0.873,3.011,1.13,4.551,1.631
    		c1.526,0.497,3.234,0.688,4.558,1.506c3.143,1.94,6.275,1.772,9.498,0.529c1.077-0.415,2.003-1.254,3.093-1.602
    		c8.775-2.798,15.286-8.207,20.022-16.147c1.734-2.908,4.591-5.208,7.154-7.545c2.219-2.022,4.729-2.896,7.716-1
    		c1.164,0.739,2.854,0.66,4.309,0.93c3.691,0.684,6.437-1.293,9.539-2.883c4.177-2.142,11.151-8.403,12.817-12.824
    		c1.899-5.042,8.097-8.847,13.642-8.463c0.947,0.065,2.052,0.008,2.858-0.419c3.424-1.811,6.998-3.463,10.085-5.756
    		c8.188-6.081,15.989-12.505,17.84-23.551c0.475-2.832,2.431-4.77,5.955-4.8c2.562-0.022,5.584-0.345,7.591-1.717
    		c9.396-6.426,14.716-15.604,16.351-26.822c0.163-1.119-0.862-2.857-1.861-3.628c-7.584-5.853-16.153-9.877-25.103-13.185
    		c-1.812-0.669-3.386-1.956-5.127-2.847c-6.569-3.363-14.19-4.863-19.239-10.93c-0.394-0.473-1.106-0.707-1.706-0.974
    		c-3.705-1.646-7.471-3.169-11.121-4.928c-4.476-2.156-8.763-4.724-13.305-6.72c-7.409-3.257-15.148-5.679-22.549-9.032
    		c-6.167-2.795-13.143-3.325-19.436-6.163c-6.051-2.729-12.504-4.556-18.741-6.881c-5.2-1.939-9.866-5.424-15.776-5.304
    		c-0.785,0.016-1.576-0.46-2.371-0.685c-5.477-1.555-10.872-3.647-16.457-4.493C666.128,686.112,660.88,686.715,655.724,686.715z
    		 M605.973,1417.058c0.085-0.298,0.171-0.595,0.256-0.892c1.167,0,2.333-0.021,3.499,0.003c5.13,0.103,5.13,0.107,2.804-4.391
    		c-1.111-2.149-0.333-6.629,1.428-7.251c3.274-1.155,3.432,1.755,4.816,3.536c2.307,2.968,5.279,5.442,8.135,7.938
    		c0.496,0.433,1.795-0.054,2.724-0.115c-0.189-0.866-0.157-1.884-0.62-2.562c-0.641-0.938-1.636-1.642-2.513-2.406
    		c-1.876-1.634-3.647-3.501-1.33-5.821c2.204-2.206,3.84-0.237,5.391,1.364c1.621,1.674,3.269,3.331,4.999,4.892
    		c5.725,5.164,12.175,8.609,20.174,7.987c1.183-0.093,2.927-0.472,3.311-1.257c0.4-0.819-0.202-2.57-0.937-3.423
    		c-2.253-2.617-4.841-4.942-7.174-7.495c-1.178-1.288-2.454-2.698-2.994-4.289c-0.399-1.176,0.318-2.731,0.538-4.119
    		c1.18,0.606,2.494,1.042,3.514,1.85c4.048,3.208,7.786,6.854,12.055,9.719c4.015,2.695,8.504,4.711,12.881,6.827
    		c1.973,0.954,4.154,1.92,6.284,2.027c6.527,0.331,10.997-4.009,15.41-7.87c5.24-4.585,5.265-11.134,0.658-16.237
    		c-4.932-5.463-10.697-10.21-14.273-16.847c-0.601-1.114-1.73-2.087-2.837-2.762c-4.71-2.872-9.298-6.086-14.328-8.25
    		c-4.497-1.936-7.406-5.408-10.809-8.474c-0.856-0.771-1.884-1.388-2.611-2.26c-4.011-4.808-9.167-9.15-11.542-14.678
    		c-1.985-4.619-1.229-10.527-3.829-15.389c0.641-18.251-1.676-36.417,0.268-54.799c2.278-21.561-0.257-43.366,2.088-65.136
    		c1.863-17.3,0.32-34.952,0.614-52.442c0.139-8.299,0.935-16.595,1.63-24.875c0.327-3.89,1.557-7.736,1.602-11.611
    		c0.064-5.58-1.282-11.157,2.355-16.346c1.129-1.61,0.973-4.346-1.877-5.293c-3.182-1.059-5.745,0.169-6.781,2.83
    		c-0.441,1.13-1.571,1.991-2.389,2.975c-0.902-1.063-2.64-2.189-2.564-3.18c0.352-4.694-2.541-5.119-5.968-5.17
    		c-8.825-0.132-17.677,0.112-26.468-0.506c-10.662-0.75-21.542,2.327-32.015-1.774c-0.371-0.145-1.094,0.055-1.388,0.354
    		c-2.237,2.279-4.544,0.963-6.959,0.387c-3.062-0.73-6.192-1.401-9.321-1.571c-7.469-0.405-14.954-0.592-22.434-0.621
    		c-1.058-0.004-2.986,1.33-3.016,2.103c-0.084,2.194,0.507,4.441,1.022,6.623c0.402,1.701,1.2,3.308,1.626,5.005
    		c0.831,3.312,1.618,6.641,2.223,9.999c0.215,1.195-0.146,2.496-0.253,3.748c-0.121,1.433-0.287,2.862-0.372,4.298
    		c-0.146,2.462-0.217,4.928-0.369,7.39c-0.133,2.148-0.516,4.297-0.471,6.439c0.141,6.808,0.511,13.609,0.631,20.417
    		c0.07,3.978-1.21,8.318,0.001,11.851c1.475,4.302,1.299,8.443,1.588,12.704c0.223,3.293,0.442,6.627,1.14,9.839
    		c1.437,6.617,3.192,13.164,4.812,19.742c0.038,0.157-0.034,0.344,0.012,0.496c0.603,2.014,1.177,4.038,1.838,6.032
    		c1.332,4.017,3.35,7.925,3.938,12.042c1.148,8.039,1.676,16.181,2.139,24.298c0.339,5.929,0.35,11.892,0.154,17.828
    		c-0.14,4.279-1.092,8.532-1.239,12.812c-0.102,2.947,0.816,5.921,0.815,8.883c-0.003,5.978-0.343,11.955-0.499,17.934
    		c-0.065,2.475-0.746,5.25,0.119,7.373c2.458,6.035,1.827,11.98,0.809,18.07c-0.207,1.233,0.182,2.562,0.263,3.849
    		c0.165,2.641,1.218,5.689,0.259,7.834c-1.713,3.833-1.763,7.608-1.578,11.506c0.099,2.08,1.329,4.25,0.976,6.183
    		c-1.328,7.251,2.89,11.955,8.184,14.461c5.867,2.776,9.064,7.795,10.188,12.613c1.675,7.181,8.479,10.641,10.072,17.364
    		c0.056,0.238,0.486,0.469,0.785,0.556c4.499,1.313,8.518,4.348,13.646,3.466c1.73-0.298,3.657,0.036,5.403,0.456
    		C598.213,1414.793,602.081,1415.973,605.973,1417.058z M422.438,1410.318c6.196,1.29,12.079-2.852,16.708-6.683
    		c12.855-10.641,22.757-23.649,24.903-41.069c0.095-0.768,0.692-1.461,0.943-2.226c0.692-2.113,1.87-4.24,1.894-6.372
    		c0.167-14.994-0.009-29.991,0.152-44.985c0.05-4.567-0.941-9.328,1.742-13.665c0.411-0.665-0.584-2.088-0.643-3.176
    		c-0.283-5.218-1.645-10.768-0.316-15.561c1.068-3.855,2.052-7.54,2.185-11.518c0.163-4.874-1.254-10.052,2.606-14.266
    		c0.201-0.219,0.339-0.666,0.262-0.937c-0.254-0.884-0.621-1.734-0.944-2.598c-0.683,0.316-1.349,0.675-2.051,0.939
    		c-1.745,0.659-3.474,1.682-5.269,1.827c-4.307,0.35-8.646,0.342-12.973,0.363c-8.137,0.039-16.287-0.285-24.407,0.078
    		c-6.63,0.296-12.737-1.779-19.049-3.008c-1.949-0.379-4.036-0.226-5.908-0.808c-5.44-1.691-10.851-3.514-16.158-5.579
    		c-1.021-0.397-2.023-2.102-2.094-3.26c-0.049-0.81,1.549-2.479,2.336-2.434c2.165,0.123,4.32,0.852,6.427,1.512
    		c4.462,1.398,8.876,2.955,13.341,4.345c1.858,0.578,3.802,0.875,5.697,1.338c2.216,0.54,4.394,1.366,6.64,1.628
    		c3.275,0.383,6.598,0.354,9.899,0.533c7.426,0.404,15.035,2.018,22.225,0.905c7.002-1.083,13.757-1.642,20.729-0.916
    		c1.003,0.104,2.732-0.251,3.021-0.892c1.677-3.721,3.851-7.506,4.255-11.435c0.423-4.1,1.246-7.631,3.409-11.151
    		c1.383-2.251,1.681-5.167,2.479-7.781c0.33-1.084,0.706-2.155,1.043-3.237c0.719-2.302,1.442-4.604,2.133-6.914
    		c0.373-1.252,0.674-2.525,1.02-3.786c0.595-2.169,1.683-4.327,1.721-6.505c0.277-16.154,0.33-32.312,0.513-48.468
    		c0.014-1.264,0.411-2.541,0.75-3.778c0.585-2.135,1.498-4.205,1.851-6.372c0.556-3.41,0.373-6.975,1.209-10.297
    		c1.11-4.41,2.957-8.631,4.35-12.977c0.284-0.888-0.133-1.977-0.068-2.962c0.356-5.405,0.276-10.901,1.293-16.185
    		c0.946-4.918,1.323-5.098-3.66-5.109c-14.997-0.035-29.994-0.069-44.991,0.035c-3.314,0.023-6.756,0.169-9.902,1.078
    		c-3.227,0.933-6.106,2.658-9.059-0.478c-0.394-0.419-1.754-0.44-2.328-0.09c-3.551,2.16-7.445,2.347-11.384,2.146
    		c-4.265-0.218-8.52-0.636-12.778-0.977c-3.783-0.302-7.671-1.319-11.325-0.771c-6.373,0.957-12.589,0.4-18.786-0.595
    		c-3.303-0.53-4.369,0.56-4.211,3.553c0.39,7.4-0.876,14.891,2.498,22.122c0.846,1.812-2.301,5.211-1.876,7.567
    		c0.476,2.639,3.567,4.662,1.766,8.067c-2.413,4.563-2.593,9.551-2.422,14.602c0.14,4.161-0.055,8.333,0.042,12.496
    		c0.274,11.725,0.407,23.459,1.049,35.164c0.248,4.528,1.967,8.956,2.568,13.489c1.005,7.573,2.234,15.187,2.335,22.797
    		c0.288,21.824,0.054,43.655,0.029,65.484c-0.01,9.098,0.208,18.204-0.143,27.289c-0.1,2.586-1.985,5.061-2.787,7.669
    		c-1.489,4.849-2.9,9.685-6.192,13.735c-1.134,1.395-1.86,3.113-2.859,4.627c-1.541,2.337-2.832,4.959-4.8,6.873
    		c-5.948,5.787-12.154,11.31-18.304,16.888c-0.938,0.852-2.055,1.544-3.176,2.151c-3.121,1.688-6.615,2.873-9.365,5.021
    		c-5.42,4.236-9.617,9.955-16.122,12.925c-3.93,1.795-3.672,6.405-5.747,9.382c-2.22,3.183-1.19,6.398-1.612,9.595
    		c-0.681,5.153,4.191,4.298,6.655,5.879c1.065,0.683,3.057,0.07,4.593-0.17c1.602-0.249,3.202-0.645,4.729-1.185
    		c1.765-0.625,3.728-1.143,5.115-2.295c3.702-3.075,6.637-7.64,10.812-9.433c5.247-2.254,6.011-9.049,11.87-10.022
    		c0.261-0.043,0.313-0.908,0.603-1.285c2.563-3.342,5.045-6.761,7.854-9.886c0.479-0.533,3-0.231,3.53,0.474
    		c1.41,1.874,0.481,6.733-1.628,7.594c-5.761,2.348-7.172,8.596-11.515,12.408c-3.993,3.504-8.755,5.872-12.571,9.584
    		c-2.486,2.417-4.402,4.641-3.148,7.195c0.897,1.828,5.25,2.339,7.649,0.932c4.556-2.673,9.217-5.18,13.662-8.025
    		c3.562-2.281,8.011-4.279,10.011-7.629c2.776-4.653,7.179-7.571,10.102-11.892c1.096-1.621,2.927-2.522,4.67-0.772
    		c1.719,1.728,1.373,3.613-0.295,5.271c-2.008,1.994-4.107,3.9-6.049,5.958c-3.19,3.382-6.663,6.6-9.24,10.412
    		c-1.082,1.601-1.11,5.266,0.02,6.714c2.007,2.572,4.029,0.013,5.672-1.599c3.209-3.146,6.067-6.784,9.688-9.349
    		c5.055-3.58,8.533-7.927,10.865-13.66c1.056-2.596,3.678-4.633,5.848-6.645c0.592-0.549,2.178-0.271,3.184,0.035
    		c0.265,0.08,0.282,1.736,0.052,2.568c-1.841,6.635-6.715,11.36-10.604,16.712c-0.689,0.949-1.784,2.771-1.521,3.037
    		c0.976,0.991,2.769,2.381,3.665,2.046c4.693-1.758,10.165-1.563,13.888-5.91c3.771-4.404,8.092-8.335,11.945-12.674
    		c1.422-1.601,2.904-2.043,4.185-0.742c0.663,0.675,0.648,2.842,0.057,3.774c-1.84,2.9-4.216,5.455-6.143,8.307
    		c-0.748,1.105-1.483,2.909-1.066,3.913c0.404,0.973,2.24,1.559,3.554,1.883C418.699,1410.562,420.092,1410.318,422.438,1410.318z
    		 M543.546,719.104c3.997,0,7.995-0.004,11.992,0.001s8.054,0.461,11.978-0.071c6.287-0.854,12.491-2.333,18.714-3.624
    		c0.774-0.161,1.42-0.865,2.183-1.178c1.05-0.432,2.163-0.703,3.232-1.09c3.789-1.368,7.725-2.444,11.312-4.224
    		c3.778-1.874,6.959-5.185,10.862-6.525c8.339-2.864,10.632-13.895,6.762-20.372c-2.862-4.79-3.414-9.916-5.167-14.779
    		c-2.523-7.005-2.224-14.361,1.382-21.292c0.738-1.422,0.785-3.204,1.144-4.822c1.193-5.401,2.353-10.812,3.611-16.197
    		c0.179-0.765,0.843-1.413,1.266-2.125c1.265-2.13,2.932-4.137,3.654-6.438c0.595-1.893,0.46-4.272-0.178-6.156
    		c-0.228-0.675-2.87-0.591-4.422-0.735c-0.814-0.075-1.653,0.164-2.479,0.156c-12.481-0.127-24.962-0.26-37.443-0.423
    		c-1.822-0.024-3.966,0.363-5.403-0.438c-4.006-2.234-8.079-2.08-12.459-2c-9.8,0.179-19.655,0.207-29.406-0.647
    		c-9.845-0.863-19.07,2.047-28.592,3.084c-3.195,0.348-6.6-0.794-9.714-0.215c-4.968,0.924-8.756,0.061-11.752-4.201
    		c-1.45-2.062-3.757-2.624-5.478-0.424c-1.635,2.09-3.515,2.055-5.751,1.932c-6.904-0.382-13.814-0.728-20.726-0.882
    		c-2.938-0.065-5.925,0.792-8.82,0.512c-6.577-0.635-13.094-1.898-19.671-2.545c-3.927-0.386-7.947,0.446-11.441-2.425
    		c-0.938-0.771-2.847-0.364-4.312-0.491c-4.925-0.428-9.862-0.744-14.77-1.322c-2.425-0.286-4.782-1.11-7.191-1.587
    		c-4.978-0.985-10.313,1.114-15.157-1.914c-0.695-0.435-2.945,0.938-3.948,1.982c-0.454,0.472,0,2.567,0.701,3.27
    		c2.837,2.845,4.303,6.21,4.854,10.111c0.159,1.132,0.495,2.315,1.056,3.301c4.261,7.493,4.09,16.167,6.292,24.206
    		c1.295,4.725,0.446,9.83-1.278,15.031c-1.863,5.616-3.812,10.916-6.431,16.252c-1.529,3.116-2.488,7.807-0.979,12.291
    		c1.519,4.516,4.293,8.028,6.839,11.788c0.406,0.601,1.355,0.857,2.084,1.212c6.794,3.306,13.653,6.482,20.37,9.936
    		c4.402,2.264,8.292,5.699,13.75,5.188c0.764-0.071,1.704,0.226,2.348,0.667c3.219,2.206,6.806,2.43,10.528,2.301
    		c1.3-0.045,3.014-0.15,3.853,0.57c3.595,3.093,7.801,2.369,11.908,2.392c4.497,0.025,8.996-0.094,13.489,0.036
    		c1.598,0.046,3.187,0.608,4.768,0.983c2.683,0.637,5.342,1.799,8.036,1.879c8.32,0.247,16.662,0.325,24.979,0.018
    		c8.356-0.309,16.662,0.797,25.05,0.17C527.521,718.632,535.551,719.099,543.546,719.104z M490.754,267.915
    		c-4.167,0.121-8.3,0.628-11.921-2.37c-0.952-0.789-2.883-0.374-4.357-0.562c-1.134-0.145-2.428-0.07-3.358-0.604
    		c-7.22-4.132-10.069-11.496-13.418-18.477c2.057-4.54-1.772-7.698-2.781-11.508c-0.782-2.951-3.357-3.507-6.018-3.003
    		c-2.501,0.473-4.684,1.218-4.934,4.595c-0.253,3.422-1.342,6.773-1.914,10.181c-0.858,5.122-6.231,8.643-5.028,14.562
    		c0.195,0.965-1.774,2.509-1.471,3.29c2.821,7.256,5.929,14.399,8.934,21.584c0.556,1.329,0.983,2.713,1.538,4.043
    		c0.861,2.067,1.473,4.329,2.74,6.119c2.774,3.916,5.782,7.684,8.906,11.33c2.786,3.254,3.172,8.772,8.761,9.447
    		c0.232,0.027,0.364,0.558,0.622,0.745c3.316,2.414,7,4.452,9.871,7.31c2.731,2.719,6.753,1.487,8.863,3.112
    		c4.495,3.46,9.142,2.167,13.791,2.501c3.128,0.225,6.904-0.591,9.259,0.876c6.396,3.986,12.658,1.019,18.772,0.381
    		c4.538-0.474,8.446-3.965,13.35-4.799c9.246-1.574,12.45-11.755,20.425-15.414c0.454-0.209,0.518-1.186,0.852-1.752
    		c2.328-3.947,4.27-8.218,7.147-11.717c2.553-3.104,4.047-6.138,4.256-10.148c0.147-2.812-0.94-6.146,2.171-8.316
    		c0.276-0.192,0.234-1.396-0.082-1.8c-3.411-4.364-1.904-9.511-2.456-14.336c-0.272-2.383-1.016-4.72-1.642-7.052
    		c-1.634-6.084-3.692-12.142,0.263-18.172c0.438-0.668,0.309-1.707,0.442-2.574c-1.101-0.062-2.815-0.597-3.196-0.103
    		c-1.652,2.143-3.049,4.517-4.279,6.939c-0.996,1.963-1.489,4.179-2.455,6.161c-0.794,1.63-2.229,2.97-2.908,4.631
    		c-3.192,7.8-8.402,13.466-16.031,17.303c-5.729,2.881-11.026,5.199-17.192,1.321c-1.263-0.794-3.243-0.854-4.819-0.659
    		c-3.54,0.438-6.338-0.268-8.926-3.009c-2.333-2.471-5.966-4.483-8.971-2.401C499.392,268.457,495.183,268.227,490.754,267.915z
    		 M472.737,1081.086c0,0.021,0,0.043,0,0.064c8.327,0,16.654,0.019,24.981-0.007c4.423-0.014,7.165-2.77,7.71-7.244
    		c0.208-1.71,1.075-3.337,1.624-5.008c0.45-1.372,1.174-2.736,1.253-4.133c0.239-4.227-0.844-8.658,2.681-12.228
    		c0.536-0.543,0.297-1.918,0.301-2.908c0.024-5.826-0.018-11.653,0.043-17.479c0.013-1.297-0.032-2.833,0.61-3.833
    		c1.32-2.054,2.185-4.156-0.018-5.716c-2.169-1.538-4.779-2.577-7.339-3.384c-1.09-0.343-2.551,0.744-3.826,0.701
    		c-8.247-0.279-16.486-0.864-24.734-1.018c-11.154-0.208-22.314-0.162-33.472-0.131c-4.021,0.011-5.436,1.355-5.453,4.797
    		c-0.036,7.143-0.008,14.285-0.061,21.428c-0.043,5.768,0.8,11.637-2.31,17.041c-0.61,1.062-0.528,2.592-0.552,3.91
    		c-0.069,3.993-0.036,7.989-0.037,11.984c-0.003,6.24,2.445,7.66,8.127,4.904c1.709-0.829,3.643-1.625,5.49-1.664
    		C456.08,1080.986,464.41,1081.086,472.737,1081.086z M434.221,980.284c0-9.165,0.025-18.331-0.01-27.497
    		c-0.019-4.832-0.171-4.91-5.129-4.996c-10.467-0.18-20.935-0.373-31.4-0.591c-3.424-0.071-6.849-0.177-10.267-0.371
    		c-3.446-0.195-6.88-0.603-10.326-0.761c-2.582-0.118-5.381,0.65-7.722-0.102c-5.023-1.614-6.43-1.027-6.395,4.197
    		c0.03,4.464,0.51,8.927,0.514,13.391c0.003,3.645-0.764,7.311-0.548,10.929c0.344,5.757,1.297,11.474,1.848,17.221
    		c0.474,4.937,0.618,9.907,1.194,14.83c0.148,1.271,1.095,2.889,2.163,3.517c1.947,1.146,4.223,2.186,6.428,2.345
    		c4.112,0.297,8.273-0.19,12.412-0.136c13.988,0.184,27.975,0.509,41.963,0.679c4.581,0.056,5.251-0.7,5.266-5.16
    		C434.242,998.613,434.221,989.449,434.221,980.284z M440.074,981.499c-0.008,0-0.015,0-0.021,0c0,8.16,0,16.321-0.001,24.482
    		c0,7.071-0.002,7.027,6.82,6.962c8.326-0.08,16.653-0.117,24.979-0.075c1.458,0.008,2.905,0.564,4.373,0.747
    		c2.93,0.365,5.883,0.985,8.804,0.876c3.606-0.135,7.548-2.069,10.692-1.104c3.915,1.201,7.545,1.542,11.521,1.447
    		c4.282-0.102,4.789-0.583,4.505-4.66c-0.417-5.967-2.296-12.154,1.814-17.733c0.521-0.706,0.415-1.929,0.461-2.919
    		c0.279-5.988-0.104-11.812-1.652-17.742c-1.124-4.306-0.946-9.249-0.123-13.68c0.83-4.475,1.339-4.621-3.477-4.625
    		c-11.325-0.01-22.651,0.073-33.976,0.028c-1.967-0.008-4.526,0.235-5.779-0.84c-2.944-2.529-6.153-2.066-9.431-2.096
    		c-4.828-0.044-9.657-0.056-14.483-0.164c-4.352-0.099-4.98,0.355-5.009,4.645C440.033,963.865,440.074,972.682,440.074,981.499z
    		 M635.412,1021.859c0,0.011,0,0.022,0,0.033c4.665,0,9.334-0.11,13.994,0.045c2.517,0.084,4.203-0.672,4.949-3.152
    		c0.573-1.904,1.53-3.778,1.672-5.713c0.462-6.298,0.569-12.62,0.944-18.926c0.116-1.942,0.651-3.86,0.993-5.79
    		c0.365-2.063,0.896-4.115,1.053-6.195c0.211-2.807-0.117-5.667,0.237-8.447c0.523-4.101,1.49-8.145,2.152-12.23
    		c0.118-0.729-0.15-1.647-0.535-2.306c-1.549-2.646-3.75-3.255-6.801-2.593c-10.424,2.261-20.997,2.93-31.67,2.849
    		c-9.195-0.07-18.388-0.67-27.596-0.144c-5.331,0.305-5.744,0.813-5.04,5.867c0.905,6.495,1.435,13.046,1.99,19.585
    		c0.107,1.265-0.333,2.602-0.65,3.873c-0.597,2.387-1.658,4.719-1.86,7.13c-0.376,4.464-0.329,8.969-0.356,13.456
    		c-0.058,9.67,0.014,9.526,9.728,9.671c7.013,0.105,14.315-1.702,20.874,2.608c0.854,0.562,2.268,0.354,3.424,0.364
    		C627.08,1021.882,631.246,1021.859,635.412,1021.859z M622.875,953.482c10.188-1.323,22.204,2.168,33.476-3.091
    		c0.415-0.193,0.99-0.024,1.487-0.065c4.027-0.335,4.754-1.22,4.328-5.377c-0.202-1.971-1.03-4.045-0.65-5.881
    		c2.42-11.694,0.794-23.509,1.359-35.249c0.217-4.514-1.578-9.338,1.854-13.484c0.198-0.24-0.766-2.078-1.444-2.271
    		c-1.215-0.344-2.637,0.143-3.951,0.03c-5.475-0.466-11.362,1.634-16.269-2.712c-0.554-0.49-2.737-0.232-3.127,0.36
    		c-2.79,4.245-7.957,5.136-11.383,8.387c-3.852,3.653-8.952,4.424-13.704,5.693c-4.404,1.177-8.405-0.308-11.539-4.028
    		c-0.864-1.026-2.915-1.69-4.231-1.456c-0.768,0.137-1.262,2.352-1.694,3.69c-0.495,1.536-0.746,3.149-1.175,4.71
    		c-0.508,1.848-1.51,3.66-1.571,5.512c-0.251,7.613,0.819,15.344-2.631,22.603c-0.396,0.833-0.189,1.971-0.194,2.968
    		c-0.028,5.163,0.16,10.338-0.117,15.487c-0.182,3.376,0.994,4.321,4.277,4.242C604.463,953.347,612.959,953.482,622.875,953.482z
    		 M517.367,1053.342c-1.511,7.7,4.469,12.981,3.065,19.672c-0.078,0.37,0.478,0.848,0.668,1.305c0.73,1.755,1.783,3.468,2.064,5.297
    		c0.429,2.785,1.793,4.226,4.51,4.383c7.234,0.42,14.391,0.172,21.682,1.837c7.322,1.673,15.212,0.815,22.846,1.205
    		c1.941,0.099,3.964,0.454,5.759,1.169c2.785,1.11,4.335,0.181,4.585-2.524c0.271-2.929,0.027-5.905,0.023-8.86
    		c-0.016-13.997-0.069-27.994,0.016-41.991c0.011-1.758,0.34-3.781,1.259-5.208c1.68-2.61,0.824-4.65-1.127-6.073
    		c-1.448-1.057-3.543-1.554-5.393-1.682c-18.336-1.266-36.904,2.495-55.087-2.8c-3.909-1.139-4.61,0.371-4.829,4.511
    		c-0.318,6.004,1.203,12.268-2.277,17.965c-0.649,1.062-0.271,2.956,0.059,4.366
    		C515.846,1048.717,516.799,1051.452,517.367,1053.342z M558.278,1015.204c5.872,0.71,14.419,1.655,22.936,2.821
    		c2.894,0.396,4.03-0.559,3.996-3.395c-0.057-4.663-0.188-9.337,0.04-13.99c0.347-7.087,1.03-14.156,1.448-21.24
    		c0.157-2.659,0.052-5.342-0.077-8.006c-0.107-2.214-0.833-4.467-0.568-6.615c0.573-4.652-0.048-5.467-4.979-5.501
    		c-2.461-0.017-4.947-0.149-7.373-0.535c-4.06-0.646-8.064-2.112-12.118-2.215c-11.123-0.281-22.261-0.012-33.393-0.019
    		c-1.47-0.002-2.982-0.188-4.402-0.563c-4.483-1.183-6.529-0.057-6.545,4.374c-0.03,8.283,0.321,16.567,0.441,24.852
    		c0.028,1.981,0.521,4.572-0.497,5.838c-3.204,3.981-0.987,7.897-0.665,11.839c0.163,1.98,0.639,3.936,0.807,5.916
    		c0.439,5.186,1.105,6.255,6.289,6.369C534.241,1015.368,544.873,1015.204,558.278,1015.204z M363.404,928.107
    		c-0.151,0.026-0.304,0.053-0.455,0.08c0,1.332,0.002,2.664-0.001,3.996c-0.002,1.332-0.021,2.665-0.012,3.998
    		c0.036,4.927,0.184,4.936,5.011,5.113c20.688,0.761,41.514-1.508,62.042,2.801c2.975,0.624,4.305-0.567,4.274-3.696
    		c-0.05-5.094-0.838-10.341,2.649-14.874c0.5-0.65,0.203-1.943,0.208-2.939c0.022-4.828-0.149-9.666,0.112-14.48
    		c0.11-2.021,0.828-4.148,1.79-5.949c1.773-3.32,1.679-5.601-1.004-8.486c-1.475-1.586-3.037-3.093-4.451-4.73
    		c-1.946-2.254-3.592-5.314-6.764-1.22c-0.426,0.551-1.873,0.406-2.849,0.417c-4.164,0.041-8.329,0.075-12.491-0.011
    		c-1.298-0.027-2.878-0.045-3.833-0.744c-4.887-3.577-11.067-5.03-15.379-9.627c-0.895-0.953-3.431-1.282-4.733-0.745
    		c-2.662,1.1-4.817,3.652-7.515,4.392c-4.366,1.195-9.006,1.671-13.43-0.376c-0.955-0.441-1.97-0.755-2.957-1.127
    		c-0.249,1.083-0.684,2.16-0.716,3.25c-0.205,6.986-0.233,13.979-0.511,20.961c-0.123,3.103-1.147,6.215-0.957,9.271
    		C361.741,918.312,362.714,923.2,363.404,928.107z M588.844,1056.507c-0.06,0-0.12,0-0.18,0c0,7.983-0.008,15.967,0.003,23.95
    		c0.008,6.346,1.235,7.641,7.54,7.558c10.179-0.135,20.244-2.015,30.546,0.887c6.234,1.756,13.203,0.965,19.847,1.183
    		c3.7,0.12,4.297-1.1,2.673-4.516c-0.519-1.09-1.256-3.017-0.797-3.484c3.74-3.809,2.238-8.466,2.403-12.828
    		c0.208-5.488-1.198-11.613,0.801-16.311c2.396-5.631,1.77-10.973,2.255-16.483c0.166-1.882,0.63-3.825,1.395-5.547
    		c1.19-2.677,0.738-3.626-2.165-3.584c-9.318,0.132-18.638,0.313-27.957,0.334c-1.951,0.005-3.91-0.566-5.854-0.933
    		c-3.372-0.635-6.71-1.644-10.106-1.896c-4.474-0.334-8.991-0.092-13.489-0.093c-6.254-0.002-6.793,0.411-6.885,6.784
    		C588.754,1039.853,588.844,1048.181,588.844,1056.507z M404.902,1083.477c0,0.208,0.001,0.417,0.001,0.625
    		c6.319,0,12.642-0.098,18.959,0.041c3.2,0.069,4.535-1.148,4.438-4.388c-0.147-4.907-0.062-9.821-0.041-14.732
    		c0.013-2.992-0.087-6,0.177-8.974c0.83-9.351,1.819-18.688,2.72-28.033c0.144-1.487,0.032-3.004,0.235-4.479
    		c0.65-4.705,0.688-4.763-3.877-4.793c-6.82-0.046-13.645,0.146-20.46-0.039c-3.252-0.088-6.484-1.161-9.729-1.183
    		c-8.218-0.055-16.438,0.351-24.657,0.332c-3.985-0.009-5.234,0.788-5.057,4.716c0.226,4.967,0.688,9.923,1.02,14.885
    		c0.377,5.622,0.835,11.241,1.05,16.87c0.175,4.591-0.246,9.218,0.137,13.784c0.311,3.696,1.368,7.34,2.233,10.974
    		c0.165,0.691,0.887,1.629,1.507,1.771c4.28,0.976,8.571,2.216,12.918,2.517C392.59,1083.793,398.758,1083.477,404.902,1083.477z
    		 M576.815,954.174c0.07-0.223,0.14-0.446,0.21-0.669c1.497,0,2.995,0.011,4.492-0.003c2.829-0.025,5.717-2.208,6.003-4.803
    		c0.34-3.087,0.546-6.199,0.598-9.305c0.171-10.316,0.115-20.639,0.452-30.949c0.081-2.479,1.108-5.02,2.118-7.351
    		c1.163-2.686,0.522-4.25-2.438-4.181c-1.587,0.037-3.166,0.724-4.734,1.168c-2.132,0.604-4.216,1.605-6.379,1.811
    		c-3.301,0.312-6.658-0.027-9.981,0.13c-2.619,0.124-4.834-0.101-6.56-2.512c-1.361-1.904-3.214-2.62-5.608-1.063
    		c-2.131,1.385-4.517,3.146-6.873,3.278c-7.814,0.438-15.904,2.14-23.115-2.828c-1.261-0.868-2.865-2.265-4.006-2.017
    		c-1.373,0.298-2.784,1.905-3.505,3.296c-0.688,1.326-0.733,3.111-0.661,4.677c0.128,2.795,0.744,5.571,0.819,8.364
    		c0.12,4.472,0.054,8.954-0.093,13.427c-0.222,6.764-0.509,13.527-0.908,20.282c-0.217,3.687,0.97,5.178,4.665,5.271
    		c1.83,0.046,3.66,0.114,5.49,0.118c7.934,0.016,15.868-0.08,23.8,0.047c4.96,0.08,9.957,0.176,14.858,0.846
    		C569.309,951.734,573.035,953.152,576.815,954.174z M501.703,947.455c0,0.05,0.001,0.1,0.001,0.149c2.99,0,5.985-0.111,8.969,0.033
    		c2.729,0.132,3.408-0.738,2.092-3.191c-0.502-0.937-1.312-2.618-0.977-2.952c3.288-3.274,0.964-6.381,0.159-9.653
    		c-0.401-1.635,0.038-3.511,0.273-5.254c0.129-0.953,1.189-2.208,0.866-2.727c-2.663-4.275-0.778-8.173,0.48-12.257
    		c0.392-1.269-0.441-2.895-0.624-4.367c-0.174-1.402-0.504-2.863-0.285-4.221c0.59-3.657-1.982-5.417-4.253-7.164
    		c-2.412-1.856-3.219,1.183-4.812,1.967c-3.774,1.855-7.804,4.863-11.592,4.672c-7.7-0.388-15.973-1.045-20.963-8.673
    		c-1.383-2.113-2.509-3.077-5.028-0.845c-2.098,1.859-5.012,2.952-7.73,3.913c-3.245,1.148-6.763,1.546-9.971,2.771
    		c-1.451,0.554-3.111,2.128-3.527,3.571c-2.349,8.143-3.025,16.543-2.431,24.97c0.135,1.915-0.264,3.165-0.836,4.888
    		c-0.963,2.897-1.068,6.437-0.294,9.38c0.311,1.177,3.87,1.896,6.021,2.11c4.753,0.475,9.553,0.477,14.331,0.713
    		c1.76,0.087,3.542,0.146,5.264,0.479c2.192,0.424,4.326,1.566,6.5,1.608C482.79,947.56,492.248,947.455,501.703,947.455z
    		 M377.805,139.025c-0.143,12.545,0.56,14.301,7.336,22.138c2.153,2.491,2.304,6.88,6.918,7.018
    		c0.265,0.008,0.426,1.183,0.758,1.742c0.491,0.828,0.949,1.769,1.672,2.35c2.416,1.943,5.021,3.654,7.403,5.636
    		c1.838,1.529,3.73,2.093,5.073-0.091c1.649-2.681,2.843-1.787,5.155-0.723c3.932,1.811,8.4,3.696,12.642,0.974
    		c3.703-2.378,9.113-2.31,10.978-7.423c0.07-0.191,0.592-0.293,0.909-0.31c4.406-0.229,3.925-1.737,1.335-4.3
    		c-0.697-0.689-0.506-2.362-0.492-3.581c0.002-0.158,1.684-0.693,2.083-0.39c3.347,2.539,4.307-0.63,5.748-2.361
    		c1.757-2.111,0.336-3.481-1.169-5.062c-0.71-0.747-0.552-2.321-0.786-3.521c0.98-0.189,2.09-0.769,2.916-0.494
    		c2.201,0.73,3.188-0.331,2.5-2.05c-1.257-3.139-3.002-6.105-4.784-8.996c-0.501-0.813-1.841-1.111-2.796-1.646
    		c-0.418,0.984-1.124,1.95-1.192,2.958c-0.135,1.982,0.173,3.99,0.137,5.985c-0.045,2.47,0.709,5.801-0.579,7.236
    		c-3.335,3.718-7.604,6.598-11.495,9.816c-0.704,0.582-1.39,1.186-2.084,1.778c0.742,0.485,1.542,0.904,2.215,1.473
    		c1.009,0.853,2.175,1.677,2.773,2.784c0.266,0.49-0.693,1.643-1.097,2.494c-1.353-0.756-3.603-1.264-3.895-2.314
    		c-1.01-3.639-3.639-3.218-6.244-3.247c-2.938-0.032-2.665,2.155-3.159,4.107c-0.209,0.825-1.812,1.419-2.892,1.815
    		c-0.266,0.098-1.504-1.245-1.386-1.492c2.022-4.229-1.735-5.461-3.812-7.524c-4.863-4.832-10.771-9.103-9.582-17.244
    		c0.392-2.681,1.146-5.308,1.703-7.965c0.403-1.919,0.882-3.834,1.093-5.776c0.196-1.811-0.197-3.723,0.229-5.452
    		c0.206-0.837,2.012-2.118,2.553-1.895c3.062,1.266,5.152,0.176,6.938-2.179c1.653-2.181,3.897-4.174,4.745-6.641
    		c1.039-3.024,2.139-3.203,4.653-1.913c1.252,0.643,3.665,1.304,4.165,0.736c2.231-2.538,4.89-1.482,7.449-1.62
    		c1.381-0.075,2.743-0.505,4.113-0.774c-0.503-1.121-0.907-2.301-1.528-3.352c-1.243-2.103-1.232-4.554,1.358-4.5
    		c4.732,0.097,5.703-3.146,7.919-6.098c2.812-3.743,1.725-7.434,0.82-10.778c-0.303-1.116-4.923-1.119-7.586-1.514
    		c-0.97-0.144-1.99,0.081-2.988,0.091c-5.188,0.052-6.936,1.328-8.804,6.167c-0.371,0.962-1.236,1.732-1.873,2.592
    		c-0.886-0.823-2.222-1.493-2.564-2.502c-0.797-2.343-2.415-3.688-4.472-2.75c-5.649,2.576-11.425,5.119-16.512,8.606
    		c-4.253,2.915-7.63,7.119-11.333,10.814c-0.754,0.752-1.233,1.779-1.84,2.68c0.993,0.365,2.036,0.636,2.964,1.124
    		c0.754,0.397,1.37,1.057,2.048,1.6c-0.666,0.608-1.251,1.561-2.019,1.742c-1.079,0.255-2.337-0.282-3.438-0.068
    		c-0.995,0.194-2.393,0.712-2.736,1.479C380.847,125.393,375.861,131.882,377.805,139.025z M549.619,729.04
    		c0.007-0.124,0.014-0.248,0.021-0.371c7.958-0.481,15.93-0.819,23.871-1.496c4.91-0.418,9.855-2.252,14.642-1.83
    		c6.194,0.546,10.675-3.846,16.365-4.144c6.739-5.9,16.029-8.353,21.103-16.689c3.446-5.663,8.284-10.473,12.423-15.724
    		c1.889-2.396,1.185-3.961-1.776-4.288c-0.976-0.108-1.983-0.059-2.927-0.287c-2.448-0.593-3.507,0.426-3.39,2.803
    		c0.244,4.98-2.734,8.641-5.387,12.232c-6.61,8.952-16.483,13.014-26.477,16.746c-3.448,1.287-6.507,3.867-10.557,3.257
    		c-0.262-0.04-0.651,0.226-0.859,0.458c-2.021,2.249-4.669,2.2-7.377,2.182c-1.123-0.008-2.635,0.064-3.307,0.75
    		c-2.733,2.787-6.058,2.13-9.347,2.129c-36.64-0.012-73.279,0.015-109.919-0.009c-1.462-0.001-3.246,0.057-4.323-0.703
    		c-3.272-2.305-6.922-2.653-10.523-2.099c-7.373,1.136-13.642-3.794-20.824-3.406c-0.406,0.021-0.81-0.591-1.269-0.737
    		c-1.967-0.627-3.927-1.319-5.942-1.73c-1.446-0.296-3.097,0.158-4.447-0.315c-4.881-1.71-9.665-3.693-14.503-5.53
    		c-3.495-1.326-8.009-1.642-10.315-4.072c-4.44-4.678-11.168-6.549-14.065-13.363c-2.31-5.435-6.494-9.969-4.526-16.521
    		c0.37-1.231,0.237-2.66,0.117-3.978c-0.084-0.912-0.544-1.79-0.835-2.684c-0.988,0.368-2.338,0.446-2.9,1.153
    		c-2.433,3.05-4.489,6.403-6.967,9.412c-1.897,2.307-1.535,4.185,0.226,6.185c1.422,1.613,3.065,3.084,4.222,4.87
    		c3.378,5.215,6.402,10.576,10.674,15.276c2.163,2.38,4.523,3.621,7.095,4.744c4.274,1.865,8.714,3.377,13.144,4.857
    		c2.607,0.871,5.322,1.43,8.004,2.061c1.593,0.374,3.222,0.586,4.824,0.921c1.435,0.3,2.853,0.673,4.281,1.004
    		c2.843,0.657,5.67,1.4,8.536,1.931c2.431,0.449,5.177,0.07,7.314,1.064c6.972,3.241,14.65,3.532,21.901,4.606
    		c10.112,1.499,20.601,0.431,30.926,0.556c1.785,0.022,3.556,0.594,5.347,0.78c2.736,0.283,5.48,0.507,8.228,0.646
    		c1.294,0.065,2.608-0.241,3.903-0.176c3.43,0.173,6.858,0.692,10.279,0.626c3.134-0.062,6.253-1.009,9.385-1.044
    		C525.662,728.961,537.641,729.04,549.619,729.04z M637.773,148.533c1.483,2.176,2.627,3.993,3.941,5.678
    		c0.298,0.382,1.188,0.301,1.805,0.433c-0.033-0.689-0.056-1.38-0.101-2.068c-0.067-1.018-0.149-2.034-0.225-3.051
    		c1.178,0.251,2.807,0.122,3.42,0.85c0.809,0.957,1.55,3.223,1.037,3.812c-1.657,1.907-3.737,3.605-5.963,4.818
    		c-2.388,1.301-2.66-1.944-4.116-2.502c-1.116-0.427-2.829-0.016-3.972,0.594c-0.795,0.424-1.072,1.816-1.581,2.776
    		c1.15,0.392,2.309,0.765,3.45,1.18c1.059,0.386,2.861,0.619,3.027,1.259c1.258,4.858,2.478,3.453,4.238,0.272
    		c0.417-0.753,2.237-1.527,2.82-1.209c0.949,0.52,1.802,1.88,1.944,2.982c0.096,0.743-0.921,1.968-1.746,2.435
    		c-3.314,1.875-6.798,3.45-10.107,5.331c-0.996,0.566-1.633,1.764-2.435,2.673c1.445,0.459,2.87,1.181,4.342,1.29
    		c1.056,0.079,2.478-0.354,3.212-1.085c4.211-4.199,10.916-6.622,10.4-14.346c-0.046-0.682,1.287-1.578,2.127-2.162
    		c0.862-0.598,1.906-0.934,2.871-1.385c-0.718-0.987-1.468-1.955-2.147-2.969c-0.946-1.412-1.841-2.858-2.758-4.29
    		c1.221-0.555,2.467-1.059,3.652-1.679c0.997-0.521,2.243-0.984,2.767-1.845c0.352-0.58-0.267-2.578-0.613-2.621
    		c-2.315-0.283-5.024-1.711-6.66,1.352c-1.408,2.636-3.281,2.097-4.771,0.391c-1.24-1.42-2.195-3.438-4.248-1.938
    		C639.982,144.532,639.225,146.438,637.773,148.533z M234.923,703.648c-1.683,0.358-3.033,0.281-3.898,0.891
    		c-5.122,3.61-11.398,3.393-17.039,5.267c-4.8,1.595-9.874,2.348-14.792,3.606c-1.943,0.497-2.618,1.635-0.521,2.935
    		c2.173,1.347,4.396,2.629,6.678,3.78c1.499,0.757,3.14,1.229,4.717,1.829c0.504-1.595,1.375-3.175,1.438-4.786
    		c0.135-3.467,2.074-3.951,4.959-3.966c2.861-0.015,4.567,0.707,4.188,3.92c-0.292,2.477,0.37,4.365,3.195,4.591
    		c2.363,0.188,4.181-0.06,5.67-2.755c2.084-3.772,2.729-8.515,6.842-11.12c0.785-0.497,1.04-1.831,1.539-2.779
    		C236.795,704.537,235.691,704.013,234.923,703.648z M594.995,66.526c-0.833-1.238-1.261-2.346-2.062-2.983
    		c-3.238-2.575-6.585-5.014-9.905-7.485c-1.926-1.434-4.92-0.282-7.025,2.737c-1.474,2.113,0.02,3.102,1.487,4.406
    		c3.592,3.192,9.202,3.546,11.655,8.344c0.134,0.263,1.451,0.337,1.721,0.043C592.273,70.054,593.521,68.371,594.995,66.526z
    		 M403.809,146.279c1.355,3.439,2.525,6.591,3.881,9.662c0.21,0.477,1.771,0.928,1.854,0.807c1.197-1.746,2.407-3.524,3.231-5.458
    		c0.233-0.547-0.984-1.576-1.152-2.458c-0.44-2.308-1.182-4.729-0.859-6.969c0.457-3.178,1.828-6.215,2.664-9.351
    		c0.292-1.098,0.155-2.311,0.215-3.471c-1.268,0.473-2.975,0.592-3.702,1.498c-1.145,1.424-1.724,3.344-2.361,5.118
    		c-0.436,1.211-0.416,2.588-0.863,3.792C405.895,141.661,404.878,143.8,403.809,146.279z M588.617,76.213
    		c-0.625-0.673-1.691-2.847-3.27-3.321c-4.299-1.293-8.324-2.804-11.861-5.693c-1.629-1.331-3.117-0.207-2.783,1.683
    		c0.255,1.443,1.305,3.111,2.508,3.952c3.767,2.633,7.707,5.04,11.714,7.298C587.313,81.478,588.524,80.512,588.617,76.213z
    		 M472.666,58.62c-1.175-1.226-2.14-2.606-3.456-3.413c-0.49-0.301-2.1,0.495-2.691,1.211c-3.404,4.109-6.696,8.315-9.916,12.572
    		c-0.493,0.653-0.844,2.069-0.483,2.546c0.437,0.577,1.697,0.691,2.603,0.695c0.567,0.002,1.238-0.464,1.688-0.899
    		c3.68-3.564,7.328-7.162,10.958-10.778C471.809,60.116,472.079,59.509,472.666,58.62z M588.086,51.757
    		c0.639,2.068,0.638,3.589,1.371,4.148c2.061,1.573,4.348,2.921,6.716,3.973c0.645,0.286,2.106-0.583,2.796-1.302
    		c0.522-0.546,0.771-1.819,0.517-2.552c-0.637-1.829-1.666-3.519-2.472-5.294c-1.197-2.643-3.571-2.39-5.556-1.804
    		C590.105,49.326,589.091,50.869,588.086,51.757z M477.467,65.026c-0.355-0.572-0.733-1.573-1-1.544
    		c-1.2,0.131-2.71,0.193-3.492,0.933c-3.858,3.646-7.56,7.462-11.214,11.315c-0.606,0.64-1.014,1.779-0.954,2.649
    		c0.057,0.812,0.857,1.571,1.331,2.354c0.653-0.421,1.453-0.719,1.927-1.288c0.822-0.988,1.274-2.318,2.178-3.2
    		c3.286-3.208,6.708-6.275,10.044-9.433C476.741,66.382,477.002,65.745,477.467,65.026z M452.414,66.687
    		c0.899-0.648,1.861-1.068,2.427-1.797c2.535-3.264,5.037-6.562,7.366-9.973c0.602-0.882,0.533-2.222,0.773-3.351
    		c-1.452,0.266-3.507,0.017-4.248,0.887c-2.876,3.377-5.367,7.088-7.915,10.734c-0.409,0.585-0.715,1.629-0.462,2.184
    		C450.624,65.958,451.617,66.215,452.414,66.687z M497.4,53.069c-3.148-1.929-5.336-4.341-7.032-4.034
    		c-2.053,0.372-4.188,2.761-5.351,4.808c-0.951,1.675,2.169,5.021,4.135,4.315C491.751,57.227,493.978,55.256,497.4,53.069z
    		 M567.413,74.477c-0.816,0.656-1.75,1.119-2.163,1.869c-0.188,0.34,0.496,1.541,1.061,1.854c4.23,2.345,8.505,4.616,12.82,6.803
    		c1.836,0.93,3.399,3.63,5.945,1.208c-1.548-1.512-3.094-3.025-4.646-4.531c-0.115-0.111-0.283-0.234-0.431-0.239
    		c-4.284-0.134-7.188-2.621-9.873-5.608C569.542,75.18,568.458,74.978,567.413,74.477z M455.411,48.334
    		c-0.376-0.822-0.649-2.098-1.042-2.136c-0.827-0.079-2.05,0.228-2.526,0.834c-2.435,3.101-4.792,6.276-6.93,9.586
    		c-0.63,0.975-0.456,2.469-0.65,3.726c1.529-0.56,3.531-0.696,4.488-1.768c2.039-2.282,3.554-5.038,5.243-7.625
    		C454.52,50.146,454.916,49.258,455.411,48.334z M609.996,51.659c-1.418-1.993-2.778-3.304-3.38-4.899
    		c-0.852-2.26-1.938-2.959-4.029-1.685c-1.87,1.14-3.083,2.657-2.004,4.935c0.561,1.181,1.351,2.291,2.212,3.281
    		c0.748,0.86,2.191,2.311,2.552,2.11C606.957,54.504,608.289,53.108,609.996,51.659z M415.753,48.148
    		c0.558,0.679,1.72,2.094,2.883,3.51c1.195-1.11,2.506-2.123,3.555-3.358c1.046-1.232,2.125-2.591,2.62-4.083
    		c0.309-0.93-0.112-2.896-0.772-3.213C421.347,39.718,415.874,43.784,415.753,48.148z M437.741,44.899
    		c-0.9-1.296-1.171-2.136-1.628-2.253c-1.025-0.265-2.627-0.652-3.141-0.139c-2.541,2.546-4.999,5.229-7.094,8.146
    		c-1.275,1.776,0.574,3.381,1.848,2.528C431.272,50.809,434.369,47.763,437.741,44.899z M449.628,43.195
    		c-2.137-0.276-4.313-1.087-4.754-0.528c-2.875,3.641-5.435,7.539-7.906,11.474c-0.278,0.442,0.514,1.739,1.08,2.413
    		c0.159,0.19,1.824-0.344,1.876-0.687c0.693-4.536,5.262-6.05,7.285-9.516C447.685,45.537,448.358,44.837,449.628,43.195z
    		 M451.724,81.224c0.286-0.098,1.474-0.505,2.662-0.912c-0.53-1.062-1.071-2.118-1.589-3.185c-0.571-1.178-0.953-2.489-1.717-3.521
    		c-1.516-2.048-5.123-2.069-5.917-0.17C443.952,76.334,447.44,81.228,451.724,81.224z M444.301,295.461
    		c-1.978-1.562-3.09-3.173-4.312-3.262c-3.169-0.231-6.624,5.063-5.407,7.995c0.292,0.704,1.771,1.687,1.967,1.542
    		C438.982,299.941,441.281,297.961,444.301,295.461z M449.125,304.828c-0.179-0.313-0.607-1.941-1.28-2.05
    		c-1.085-0.175-2.711,0.315-3.43,1.126c-1.4,1.577-2.53,3.482-3.4,5.419c-0.879,1.957,0.797,3.501,3.013,2.995
    		C446.375,311.782,449.202,308.098,449.125,304.828z M463.907,322.699c-1.167-0.619-2.113-1.57-2.938-1.474
    		c-2.271,0.267-5.836-0.641-5.782,2.924c0.024,1.699,2.508,3.362,3.877,5.042C460.555,327.194,462.044,325.197,463.907,322.699z
    		 M471.156,331.089c-1.491-1.621-2.206-3.087-3.251-3.38c-1.767-0.497-4.38,2.867-3.889,5.273c0.246,1.205,1.777,2.148,2.727,3.21
    		C468.095,334.629,469.446,333.066,471.156,331.089z M457.551,314.481c-1.976-1.592-3.647-3.321-3.941-3.112
    		c-1.73,1.231-3.238,2.841-4.515,4.551c-0.135,0.181,2.174,2.839,2.576,2.655C453.522,317.729,455.1,316.282,457.551,314.481z
    		 M426.635,258.836c-0.185,0.032-2.004-0.183-2.609,0.574c-0.962,1.204-1.934,3.416-1.432,4.553c0.905,2.054,2.219,0.306,3.456-0.47
    		c1.16-0.728,2.719-0.786,4.023-1.328c1.035-0.43,1.951-1.145,2.919-1.732c-0.924-0.532-1.797-1.214-2.788-1.548
    		C429.466,258.636,428.576,258.836,426.635,258.836z M577.963,308.6c-1.647-1.875-2.741-4.069-4.463-4.816
    		c-1.745-0.757-3.385,1.244-2.604,2.956c0.919,2.02,2.846,3.579,4.335,5.338C575.951,311.161,576.671,310.244,577.963,308.6z
    		 M432.911,282.793c-0.194-0.276-0.39-0.554-0.584-0.83c-1.697,1.231-3.472,2.375-5.044,3.749c-0.457,0.399-0.338,1.458-0.483,2.215
    		c0.885,0.134,1.861,0.575,2.636,0.343c2.148-0.643,4.238-1.51,6.293-2.418c0.499-0.222,1.177-1.079,1.067-1.412
    		c-0.209-0.637-0.834-1.348-1.455-1.583C434.627,282.584,433.729,282.793,432.911,282.793z M520.642,335.8
    		c-2.463,1.278-6.062-0.362-6.159,3.419c-0.026,1.015,2.512,3.12,2.776,2.956c1.956-1.212,3.635-2.871,5.399-4.39
    		C521.957,337.095,521.256,336.404,520.642,335.8z M485.729,60.862c-2.899-2.94-5.049-5.222-7.356-7.331
    		c-0.285-0.261-1.7,0.186-2.062,0.687c-0.357,0.496-0.364,1.783,0.027,2.191c1.912,1.989,3.959,3.862,6.098,5.604
    		C482.738,62.26,483.811,61.562,485.729,60.862z M584.546,277.127c3.033,0.606,4.837-1.179,6.132-3.656
    		c0.234-0.447,0.028-1.359-0.312-1.785c-0.212-0.264-1.392-0.238-1.574,0.034c-1.711,2.557-4.276,1.822-6.667,2.016
    		c-0.74,0.06-1.405,1.049-2.104,1.612c0.651,0.594,1.214,1.396,1.979,1.718C582.698,277.358,583.616,277.127,584.546,277.127z
    		 M484.209,337.847c0.175,0.081,0.351,0.163,0.526,0.245c0,0.993-0.2,2.046,0.073,2.956c0.171,0.566,1.06,0.917,1.626,1.364
    		c1.261-1.642,3.231-3.175,3.504-4.967c0.152-0.999-2.52-2.428-3.919-3.663C485.416,335.137,484.812,336.492,484.209,337.847z
    		 M509.439,340.842c-0.902-1.834-1.566-3.423-2.494-4.84c-0.16-0.244-2.07,0.177-2.094,0.402c-0.154,1.488-0.016,3.007,0.032,4.516
    		c0.006,0.163-0.021,0.368,0.064,0.482c0.758,1.017,1.537,2.018,2.31,3.023C507.942,343.302,508.626,342.179,509.439,340.842z
    		 M562.97,321.287c-1.569-1.302-2.834-2.842-3.283-2.633c-1.42,0.662-2.948,1.787-3.572,3.133c-0.268,0.577,1.588,2.14,2.479,3.255
    		C559.846,323.968,561.099,322.893,562.97,321.287z M619.438,46.307c-1.943-1.626-2.978-3.139-4.185-3.293
    		c-0.92-0.117-2.06,1.488-3.102,2.327c0.951,1.116,1.719,2.743,2.917,3.174C615.894,48.811,617.313,47.449,619.438,46.307z
    		 M595.432,83.37c0.652-0.154,1.135-0.127,1.241-0.314c1.241-2.174,2.46-4.363,3.543-6.617c0.066-0.138-1.061-0.85-1.633-1.294
    		c-1.503,2.019-3.099,3.981-4.416,6.115C593.961,81.594,595.015,82.706,595.432,83.37z M624.917,46.606
    		c1.854-1.105,3.316-1.977,4.778-2.849c-1.704-0.577-3.385-1.472-5.121-1.597c-0.841-0.06-1.785,1.322-2.683,2.052
    		C622.81,44.939,623.729,45.667,624.917,46.606z M542.964,334.899c-1.857-1.01-2.889-2.045-3.599-1.858
    		c-1.521,0.4-2.875,1.432-4.299,2.205c0.494,0.898,1.281,2.627,1.438,2.571C538.388,337.151,540.177,336.222,542.964,334.899z
    		 M383.255,857.146c-1.542,1.152-2.714,2.028-3.886,2.905c1.248,0.627,2.508,1.758,3.741,1.73c1.092-0.025,2.154-1.34,3.229-2.085
    		C385.441,858.954,384.544,858.212,383.255,857.146z M583.432,269.282c1.819-1.024,3.11-1.751,4.401-2.478
    		c-1.231-0.671-2.44-1.821-3.701-1.881c-1.349-0.064-2.745,0.899-4.121,1.415C581.062,267.244,582.115,268.148,583.432,269.282z
    		 M587.517,282.404c-1.841-1.086-3.029-2.138-3.352-1.919c-1.527,1.042-2.839,2.412-4.126,3.761
    		c-0.054,0.057,1.153,1.635,1.339,1.55C583.031,285.044,584.587,284.076,587.517,282.404z M767.694,794.313
    		c1.193-1.964,2.296-2.983,2.116-3.662c-0.309-1.161-1.42-2.109-2.193-3.147c-0.561,0.688-1.62,1.408-1.575,2.053
    		C766.128,790.8,766.838,792,767.694,794.313z M432.374,269.742c-1.982-1.147-2.939-2.162-3.766-2.064
    		c-1.061,0.125-2.017,1.145-3.017,1.778c0.796,0.655,1.56,1.788,2.397,1.847C429.056,271.377,430.183,270.581,432.374,269.742z
    		 M566.245,319.084c0.947-1.778,1.826-2.681,1.759-3.506c-0.07-0.867-1.046-1.661-1.626-2.487c-0.623,0.804-1.64,1.555-1.755,2.426
    		C564.515,316.33,565.365,317.27,566.245,319.084z M411.325,47.558c-1.729-1.355-2.569-2.532-3.312-2.473
    		c-1.06,0.085-2.047,1.074-3.065,1.677c0.69,0.756,1.305,2.022,2.09,2.14C408.051,49.053,409.201,48.283,411.325,47.558z
    		 M451.1,90.585c1.029-1.645,1.787-2.816,2.463-4.033c0.038-0.067-0.825-0.777-0.879-0.731c-1.044,0.888-2.062,1.815-2.971,2.838
    		C449.631,88.75,450.341,89.545,451.1,90.585z M431.428,249.588c-0.751,0.88-1.317,1.543-1.883,2.206
    		c0.668,0.565,1.473,1.693,1.973,1.571c0.86-0.21,1.529-1.199,2.278-1.86C433.093,250.936,432.39,250.367,431.428,249.588z
    		 M495.618,602.022c-1.16-0.852-1.892-1.809-2.574-1.775c-0.743,0.036-1.44,0.994-2.157,1.55c0.628,0.632,1.206,1.697,1.897,1.776
    		C493.501,603.654,494.326,602.78,495.618,602.022z M580.178,296.031c-1.496-0.567-2.335-1.154-3.126-1.097
    		c-0.595,0.043-1.129,0.914-1.689,1.416c0.707,0.569,1.386,1.534,2.131,1.589C578.146,297.987,578.873,297.014,580.178,296.031z
    		 M434.656,277.19c-0.692-0.8-1.185-1.369-1.677-1.938c-0.596,0.639-1.448,1.2-1.678,1.952c-0.112,0.37,0.869,1.44,1.465,1.541
    		C433.229,278.823,433.861,277.886,434.656,277.19z M538.65,266.363c-1.082-0.537-1.704-1.086-2.308-1.065
    		c-0.427,0.015-1.255,0.983-1.16,1.202c0.267,0.611,0.89,1.411,1.429,1.457C537.111,267.999,537.686,267.163,538.65,266.363z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M624.089,538.146c-3.83,0-7.662,0.057-11.492-0.019
    		c-2.675-0.053-5.352-0.407-7.261,2.174c-0.333,0.45-1.2,0.785-1.779,0.729c-5.164-0.493-8.729,2.941-12.813,5.098
    		c-0.581,0.307-1.216,0.88-1.768,0.817c-5.356-0.611-9.129,3.101-13.63,4.812c-1.819,0.692-3.819,1.171-5.756,1.242
    		c-4.325,0.16-8.665-0.085-12.994,0.015c-1.969,0.046-4.147,0.065-5.848,0.888c-4.375,2.116-8.824,1.159-13.275,0.977
    		c-2.131-0.088-2.639-1.297-2.591-3.352c0.115-5.045-2.159-7.568-6.997-8.349c-2.259-0.364-4.084,0.029-4.545,2.583
    		c-0.407,2.257,0.17,3.993,2.94,4.122c0.638,0.029,1.479,0.121,1.855,0.528c1.284,1.386,2.402,2.926,3.584,4.407
    		c-1.716,0.049-3.452,0.297-5.142,0.113c-7.703-0.835-15.381-1.918-23.092-2.658c-5.189-0.499-10.424-0.839-15.629-0.75
    		c-3.747,0.063-7.491,0.887-11.216,1.491c-3.5,0.567-6.101-1.053-8.53-3.209c-8.68-7.704-17.346-15.425-26.038-23.114
    		c-2.265-2.003-4.704-3.197-7.936-3.644c-3.764-0.519-7.352-2.429-10.972-3.847c-4.378-1.714-7.94-4.827-13.233-5.115
    		c-4.127-0.226-8.089-3.299-12.151-5.044c-0.881-0.379-1.9-0.759-2.814-0.675c-3.393,0.31-6.223-0.133-9.291-2.289
    		c-2.104-1.478-5.763-0.634-8.684-1.096c-1.887-0.298-3.689-1.11-5.545-1.635c-1.396-0.395-2.813-0.977-4.228-0.995
    		c-7.83-0.103-15.663-0.062-23.495-0.06c-0.828,0-1.848-0.233-2.455,0.151c-6.95,4.41-15.61,2.435-22.65,5.964
    		c-5.87,2.943-12.996,3.674-17.349,9.535c-0.845,1.138-2.622,2.122-4.026,2.203c-4.315,0.251-8.658-0.031-12.989,0.011
    		c-3.474,0.033-5.485-2.166-7.107-4.704c-0.467-0.73,0.145-2.152,0.262-3.257c1,0.229,2.291,0.147,2.941,0.753
    		c1.443,1.343,3,2.772,4.68,1.246c0.881-0.801,0.774-2.835,0.862-4.326c0.126-2.156,0.117-4.332-0.02-6.488
    		c-0.067-1.05-0.555-2.073-0.853-3.109c-1.078,0.573-2.196,1.083-3.224,1.735c-1.396,0.884-2.723,1.877-4.079,2.823
    		c-0.284-1.556-0.805-3.11-0.81-4.666c-0.02-6.224,1.461-7.123,7.568-5.782c3.504,0.77,7.176,0.894,10.78,0.994
    		c0.813,0.022,1.664-1.264,2.497-1.947c-0.795-0.855-1.484-2.29-2.405-2.459c-2.765-0.506-5.618-0.677-8.438-0.684
    		c-3.751-0.01-5.737-2.102-6.96-5.288c-0.233-0.608-0.084-1.507-0.48-1.888c-3.397-3.266-2.468-4.529,1.912-4.696
    		c1.02-0.038,2.156-0.897,2.953-1.674c0.617-0.6,1.265-1.843,1.025-2.462c-0.249-0.641-1.521-1.138-2.396-1.234
    		c-2.632-0.289-5.289-0.34-7.935-0.507c-2.406-0.151-4.015,0.806-3.878,3.352c0.189,3.532-1.063,7.282,1.961,10.468
    		c0.729,0.769,0.557,3.323-0.177,4.38c-2.351,3.387-2.72,6.74-2.078,10.783c0.435,2.738-0.75,5.748-1.294,8.621
    		c-0.366,1.933-0.974,3.82-1.296,5.759c-0.405,2.433,0.823,3.16,3.02,4.536c5.201,3.258,11.055,3.156,16.535,4.657
    		c4.16,1.14,8.723,0.955,13.11,1.021c1.062,0.016,2.396-1.099,3.175-2.046c3.773-4.589,8.689-7.292,14.152-9.321
    		c3.983-1.479,7.542-4.404,12.215-3.242c11.556-5.366,23.86-2.194,35.804-3.14c0.166-0.013,0.336-0.036,0.499-0.015
    		c6.111,0.832,11.766,4.354,18.232,3.092c5.402,4.055,12.312,3.415,18.221,5.849c5.43,2.236,10.813,4.604,16.113,7.132
    		c7.22,3.444,14.362,7.055,21.486,10.694c1.434,0.732,2.682,1.858,3.938,2.896c5.312,4.379,10.51,8.904,15.922,13.155
    		c3.426,2.69,6.9,6.438,10.811,7.132c5.865,1.041,12.135-0.141,18.228-0.462c2.26-0.119,4.547-0.821,6.742-0.568
    		c0.986,0.113,1.778,1.917,2.658,2.953c-0.968,0.695-1.846,1.622-2.921,2.036c-2.255,0.868-4.653,1.366-6.903,2.246
    		c-0.56,0.219-1.258,1.654-1.057,1.936c0.48,0.674,1.447,1.363,2.235,1.384c1.814,0.049,3.665-0.559,5.451-0.385
    		c1.05,0.104,2.005,1.192,3.002,1.837c-0.849,0.603-1.621,1.529-2.56,1.749c-3.709,0.871-7.504,1.384-11.191,2.325
    		c-1.253,0.32-2.569,1.322-3.283,2.395c-0.324,0.487,0.498,2.411,1.246,2.782c1.164,0.575,2.782,0.719,4.074,0.436
    		c2.047-0.449,3.936-1.615,5.981-2.077c1.7-0.385,2.551,0.627,1.804,2.363c-1.442,3.35-2.071,7.777-7.592,6.105
    		c-0.278-0.085-0.69,0.037-0.96,0.197c-1.918,1.142-5.02,1.802-4.064,4.662c0.326,0.976,3.623,1.039,5.591,1.334
    		c0.547,0.081,1.172-0.654,1.8-0.748c1.147-0.171,2.325-0.143,3.489-0.197c-0.131,1.419,0.271,3.469-0.506,4.122
    		c-1.53,1.289-3.641,2.165-5.641,2.566c-4.84,0.972-9.742,1.674-14.645,2.281c-2.44,0.303-5.246,0.922-7.345,0.067
    		c-4.974-2.022-9.75-0.972-14.721-0.63c-4.728,0.325-9.53-0.266-14.288-0.614c-2.955-0.217-5.95-1.307-8.819-0.998
    		c-6.8,0.731-13.072-1.807-19.624-2.456c-2.635-0.261-5.312-0.198-7.922-0.594c-3.061-0.463-6.044-1.455-9.107-1.871
    		c-3.911-0.532-7.884-0.601-11.802-1.091c-3.062-0.383-6.067-1.191-9.122-1.654c-3.55-0.537-7.218-1.63-10.671-1.19
    		c-6.773,0.861-12.04-2.926-17.966-4.652c-1.883-0.549-3.817-0.916-5.703-1.457c-1.693-0.485-3.367-1.054-5.019-1.67
    		c-0.763-0.285-1.451-1.115-2.173-1.114c-7.601,0.01-12.99-5.114-19.268-8.164c-4.553-2.213-9.19-4.266-13.651-6.65
    		c-6.095-3.258-12.001-6.869-18.095-10.13c-4.836-2.589-9.234-5.631-12.761-9.892c-0.433-0.522-1.499-0.643-2.294-0.72
    		c-2.34-0.228-2.792-1.738-3.165-3.749c-0.4-2.154-0.969-4.482-2.181-6.24c-3.168-4.598-2.413-9.693-2.506-14.71
    		c-0.027-1.459,0.529-3.078,0.068-4.354c-1.776-4.921-1.205-9.688-0.333-14.717c1.359-7.842,1.765-15.843,2.921-23.726
    		c1.492-10.166,2.12-20.351,2.48-30.617c0.186-5.306,2.633-10.52,3.958-15.801c0.673-2.685,1.039-5.446,1.701-8.134
    		c0.577-2.341,1.357-4.633,2.085-6.935c0.196-0.619,0.828-1.24,0.749-1.787c-0.864-5.965,1.389-11.521,3.347-16.711
    		c1.993-5.286,1.538-11.879,6.967-15.721c0.733-0.519,1.387-1.533,1.548-2.415c1.12-6.095,3.471-11.504,7.745-16.126
    		c1.373-1.484,2.126-3.611,2.929-5.542c3.393-8.16,11.278-12.229,16.778-18.474c6.137-6.968,12.508-13.8,19.379-20.027
    		c3.898-3.534,8.968-5.797,13.579-8.515c1.991-1.174,4.088-2.247,6.259-3.024c8.469-3.032,17.004-5.881,25.477-8.902
    		c2.433-0.868,4.748-2.07,7.105-3.146c7.633-3.485,15.593-6.123,23.394-9.148c4.903-1.901,9.011-6.005,13.247-9.404
    		c1.622-1.302,2.248-1.946,4.319-0.341c3.312,2.569,3.449,2.533,1.296,6.424c-1.177,2.126-3.095,4.694-0.192,7.177
    		c0.472,0.402,0.969,1.109,0.969,1.675c-0.004,3.494,1.946,4.104,4.917,3.737c2.611-0.321,3.979,0.352,2.304,3.332
    		c-0.68,1.209-0.849,2.802-0.921,4.236c-0.141,2.791,0.005,5.596-0.067,8.393c-0.102,3.901,1.989,5.443,5.598,4.047
    		c2.717-1.052,7.74,0.904,9.136,3.598c0.223,0.429,0.141,1.339,0.363,1.396c5.273,1.37,5.396,6.081,7.009,10.043
    		c0.852,2.091,2.996,3.821,6.04,3.575c2.778-0.225,3.206,1.139,2.214,3.812c-1.486,4.005,0.886,6.266,5.109,5.641
    		c5.826-0.863,9.356-4.354,11.758-9.425c0.433-0.916,1.308-1.622,1.979-2.425c0.539,1.083,1.459,2.14,1.537,3.256
    		c0.162,2.309-0.326,4.664-0.151,6.971c0.103,1.351,0.616,3.05,1.566,3.878c1.898,1.653,4.501,4.241,6.319,3.89
    		c4.138-0.801,7.07-4.116,8.919-8.062c0.204-0.436,0.069-1.03,0.277-1.462c0.662-1.371,1.412-2.699,2.129-4.044
    		c0.512,1.752,1.7,3.623,1.416,5.235c-1.123,6.379-0.327,7.612,6.18,7.702c3.816,0.052,7.686,0.338,11.431-0.192
    		c2.033-0.288,3.756-2.724,5.792-3.029c3.681-0.552,2.623-3.087,3.093-5.198c0.247-1.11,1.238-2.055,1.893-3.074
    		c0.531,1.044,1.169,2.049,1.566,3.141c0.586,1.606,1,3.274,1.486,4.916c1.596-0.553,3.192-1.102,4.784-1.665
    		c0.148-0.053,0.274-0.298,0.394-0.286c5.464,0.567,7.776-4.618,11.893-6.514c2.34-1.078,2.117-2.518,0.591-4.328
    		c-0.758-0.899-1.427-2.315-1.278-3.378c0.094-0.667,2.071-1.778,2.69-1.51c1.175,0.51,2.326,1.71,2.879,2.904
    		c1.859,4.021,2.565,4.279,5.954,1.619c0.756-0.593,1.637-1.415,2.48-1.44c3.314-0.099,3.693-2.09,3.542-4.723
    		c-0.115-2.01,0.635-3.175,2.863-3.939c3.426-1.175,5.95-4.68,10.229-3.911c0.454,0.082,1.279-0.924,1.656-1.583
    		c0.879-1.538,2.022-3.112,2.256-4.784c0.273-1.958,0.21-4.283-0.619-6.011c-1.845-3.845-2.061-3.683,1.744-4.858
    		c1.062-0.327,2.33-0.819,2.92-1.65c1.088-1.533,2.705-3.664,2.3-4.999c-0.379-1.246-3.044-1.923-4.807-2.569
    		c-0.868-0.317-2.011,0.182-2.951-0.033c-0.6-0.137-1.056-0.903-1.576-1.388c0.574-0.172,1.234-0.619,1.709-0.472
    		c3.106,0.964,5.623-0.246,7.742-2.24c2.362-2.223,4.046-1.679,6.414,0.24c8.732,7.077,17.378,14.347,27.967,18.652
    		c0.904,0.368,1.929,0.443,2.899,0.646c8.081,1.686,16.104,3.351,23.236,8.016c2.811,1.839,6.387,2.504,9.612,3.71
    		c9.627,3.598,19.306,7.016,28.034,12.678c5.857,3.8,12.259,6.775,18.023,10.697c4.06,2.762,7.406,6.553,11.166,9.773
    		c3.648,3.127,6.057,6.78,6.357,11.759c0.068,1.127,1.304,2.215,2.079,3.264c2.294,3.098,4.51,6.148,4.052,10.396
    		c-0.123,1.146,0.735,2.43,1.255,3.601c0.398,0.896,1.435,1.732,1.398,2.562c-0.354,8.201,4.559,15.043,5.748,22.786
    		c0.626,4.073,0.14,8.305,0.47,12.44c0.178,2.234,1.042,4.41,1.551,6.623c0.477,2.072,1.261,4.156,1.276,6.24
    		c0.128,16.83,0.094,33.66,0.136,50.491c0.034,13.376,0.194,26.753,0.077,40.127c-0.021,2.354-1.181,4.688-1.73,7.052
    		c-0.479,2.061-0.952,4.133-1.215,6.227c-0.228,1.807-0.007,3.678-0.333,5.456c-0.07,0.379-1.65,0.479-2.535,0.709
    		c-0.486-2.914-2.992-5.054-1.547-8.85c1.718-4.51,5.244-9.053,2.8-14.495c2.72-4.632-0.544-9.213-0.161-13.883
    		c0.332-4.052-8.772-9.211-12.389-7.266c-0.811,0.437-1.649,1.803-1.565,2.65c0.089,0.907,1.081,2.104,1.976,2.492
    		c7.785,3.392,7.798,3.346,7.66,11.505c-0.066,3.994-0.414,8.015-0.081,11.978c0.496,5.897-1.996,10.681-4.812,15.456
    		c-0.41,0.695-0.931,1.487-0.93,2.234c0.005,3.432-2.73,4.702-4.877,6.422c-0.936,0.75-2.014,1.323-3.026,1.977
    		c1.425,0.572,2.816,1.517,4.279,1.645c4.202,0.369,5.896-3.018,8.074-5.74c1.006-1.258,2.512-2.117,3.791-3.157
    		c-0.203,1.829-0.066,3.782-0.684,5.458c-1.12,3.044-3.321,5.796-3.946,8.896c-1.494,7.401-6.331,12.808-10.318,18.703
    		c-1.42,2.101-3.797,3.561-5.771,5.274c-0.487,0.423-1.277,0.567-1.638,1.055c-5.86,7.928-14.609,10.597-23.472,13.272
    		c-4.778,1.443-9.311,3.687-14.012,5.409c-1.514,0.555-3.209,0.812-4.828,0.84c-5.664,0.1-11.357-0.297-16.99,0.131
    		c-6.223,0.474-12.383,1.716-18.588,2.469c-1.562,0.189-3.753,0.397-4.688-0.459c-2.555-2.343-5.394-2.139-8.4-2.131
    		c-13.497,0.037-26.994,0-40.491-0.032c-0.991-0.003-2.201,0.153-2.938-0.33c-7.538-4.956-15.842-1.919-23.796-2.386
    		c-1.816-0.105-3.651,0.159-5.476,0.136c-9.789-0.128-19.605-0.783-29.353-0.222c-4.333,0.25-8.613,2.73-12.74,4.593
    		c-1.801,0.812-3.353,1.325-4.126-0.568c-0.363-0.891,0.509-3.051,1.417-3.548c2.398-1.312,5.15-1.962,7.704-3.015
    		c1.231-0.508,2.333-1.331,3.493-2.011c-1.303-0.708-2.56-1.879-3.917-2.012c-1.94-0.189-3.967,0.636-5.934,0.543
    		c-1.41-0.067-3.706-0.623-3.994-1.495c-0.762-2.299,1.012-3.619,3.17-4.135c0.96-0.229,2.043-0.01,2.966-0.312
    		c1.993-0.651,3.997-1.359,5.851-2.323c1.922-1,3.394-2.512,1.498-4.736c-1.5-1.76-3.11-2.006-5.19-0.403
    		c-1.3,1.002-3.216,1.425-4.914,1.633c-2.003,0.246-3.725-0.52-3.607-3.001c0.123-2.616,1.696-4.231,4.209-3.734
    		c2.322,0.459,4.322,0.667,5.845-1.314c0.624-0.812,1.114-2.057,0.975-3.002c-0.082-0.552-1.631-1.405-2.347-1.262
    		c-5.447,1.092-5.862-3.887-8.001-6.62c-0.878-1.122,3.325-5.55,5.503-5.158c5.924,1.065,12.429-1.759,17.877,2.693
    		c0.771,0.63,2.379,0.867,3.331,0.529c4.552-1.61,9.024,0.132,13.504-0.039c2.677-0.102,5.312-1.235,7.973-1.873
    		c1.598-0.383,3.198-0.921,4.818-1.008c2.991-0.161,6.057-0.423,8.984,0.035c6.957,1.089,12.772-2.055,18.827-4.452
    		c1.377-0.545,2.821-1.607,4.145-1.482c6.183,0.584,11.02-2.266,15.841-5.474c0.874-0.582,2.241-0.367,3.33-0.691
    		c1.8-0.537,3.569-1.186,5.329-1.848c0.446-0.168,0.818-0.834,1.204-0.817c7.022,0.31,14.194-1.842,21.06,1.445
    		c1.703,0.815,3.755,1.11,5.672,1.229c2.984,0.183,5.996-0.094,8.984,0.06c0.743,0.038,1.439,0.977,2.157,1.501
    		c-0.589,0.537-1.188,1.552-1.764,1.539c-7.101-0.154-13.408,4.868-20.748,3.108c-2.699-0.646-4.814,0.591-6.67,2.49
    		c-2.573,2.633-2.85,5.834,0.381,7.549c5.438,2.885,6.655,8.463,9.521,12.945c1.807,2.827,0.223,5.454-3.133,6.608
    		c-1.077,0.371-2.119,0.922-3.079,1.542c-4.167,2.688-7.988,1.411-11.583-1.097c-3.116-2.174-3.589-5.549-3.727-9.071
    		c-0.049-1.238-0.718-2.451-1.104-3.676c-1.286,0.441-2.78,0.605-3.814,1.382c-2.146,1.61-3.036,3.056-0.96,6.111
    		c1.5,2.207,1.654,6.491,0.51,8.974c-0.966,2.094-4.664,2.926-7.153,4.316c-0.416-1.123-0.862-2.236-1.239-3.372
    		c-0.456-1.38-0.854-2.78-1.275-4.171c-1.348,1.151-3.249,2.052-3.912,3.513c-0.979,2.16-0.58,4.432,1.65,6.206
    		c5.564,4.43,7.562,4.379,12.941-0.823c5.157-4.985,5.063-4.776,11.916-1.687c2.047,0.923,5.01,0.989,7.144,0.255
    		c2.837-0.976,5.353-3.008,7.854-4.798c1.829-1.309,3.499-1.415,4.8,0.338c1.675,2.257,3.894,2.27,6.261,2.111
    		c0.489-0.033,1.01-0.109,1.447-0.312c1.822-0.841,4.726-8.806,4.281-10.893c-0.99-4.647-1.725-9.349-2.667-14.007
    		c-0.268-1.321-0.893-2.57-1.354-3.853c-1.653,1.442-3.979,2.599-4.752,4.415c-0.642,1.511-0.083,4.354,1.047,5.628
    		c2.45,2.761,2.07,5.897,2.148,8.964c0.03,1.187-1.105,2.401-1.711,3.604c-1.187-0.708-3.371-1.38-3.399-2.132
    		c-0.185-4.928-4.197-7.718-5.934-11.682c-0.94-2.146-0.831-4.86-0.712-7.295c0.033-0.667,1.755-1.654,2.797-1.795
    		c3.338-0.449,6.586-0.029,9.533-2.89c2.015-1.956,5.678-2.095,8.299-3.583c2.12-1.204,3.784-3.21,5.65-4.859
    		c-2.112-2.233-3.866-5.61-6.428-6.414c-3.332-1.045-7.084-0.565-10.04-3.26c-0.646-0.589-2.247-0.145-3.409-0.146
    		c-4.166-0.008-8.331-0.004-12.497-0.004C624.089,538.138,624.089,538.142,624.089,538.146z M653.368,526.65
    		c-2.463,2.906-2.598,5.696-0.18,8.863c2.795,3.659,5.26,7.578,7.747,11.461c0.676,1.057,1.237,2.375,1.301,3.602
    		c0.254,4.93,3.914,7.861,6.545,11.337c1.266,1.673,3.586,1.682,4.623-0.232c0.527-0.972-0.191-3-0.936-4.179
    		c-0.839-1.329-3.167-2.094-3.392-3.361c-0.576-3.244-2.144-5.44-4.733-7.25c-0.711-0.497-1.47-1.702-1.362-2.464
    		c0.6-4.242-1.902-6.782-4.575-9.385c-0.765-0.745-1.525-2.015-1.465-2.992c0.18-2.893-1.59-4.142-3.702-5.275
    		c0.529-5.401,1.869-10.83-1.597-15.914c-0.745-1.094-0.624-2.884-0.634-4.355c-0.065-9.654-0.084-19.311-0.039-28.966
    		c0.007-1.276,0.032-3.003,0.799-3.742c2.403-2.314,1.807-5.13,2.104-7.901c0.685-6.382-2.283-13.333,2.86-19.063
    		c0.261-0.29,0.018-0.985,0.128-1.465c2.112-9.205,3.789-18.544,6.532-27.559c1.734-5.704,4.812-10.999,5.53-17.082
    		c0.087-0.736,0.64-1.682,1.262-2.05c5.55-3.287,4.242-9.944,7.055-14.66c1.351-2.265,0.942-5.552,4.034-7.259
    		c0.712-0.394,0.485-2.486,0.691-3.798c-1.344-0.116-2.911-0.716-3.972-0.224c-1.244,0.578-2.374,1.953-2.978,3.25
    		c-1.57,3.375-2.68,6.965-4.239,10.345c-2.744,5.944-5.751,11.769-8.488,17.716c-0.504,1.095-0.137,2.559-0.374,3.813
    		c-0.38,2.004-0.517,4.215-1.496,5.911c-1.911,3.31-4.355,6.265-3.528,10.523c0.189,0.974-0.517,2.154-0.911,3.202
    		c-0.655,1.737-1.438,3.427-2.08,5.168c-0.506,1.368-0.528,3.073-1.385,4.123c-2.209,2.709-1.626,5.524-1.094,8.5
    		c0.234,1.312,0.239,2.77-0.077,4.056c-1.015,4.122-2.936,8.145-3.226,12.296c-0.702,10.028-0.84,20.107-0.902,30.168
    		c-0.061,9.809,0.195,19.62,0.432,29.428C647.758,525.865,648.385,526.333,653.368,526.65z M309.373,451.89
    		c6.975-0.019,8.526-1.53,8.864-8.599c0.131-2.758,1.356-2.888,3.574-1.728c1.498,0.784,3.279,1.057,4.956,1.465
    		c2.552,0.621,5.125,1.153,7.686,1.74c1.76,0.402,3.508,0.858,5.271,1.247c3.198,0.705,6.38,1.556,9.614,2.014
    		c3.249,0.461,6.607,0.938,9.812,0.549c1.354-0.164,2.439-2.529,3.647-3.895c-1.386-0.526-2.756-1.098-4.165-1.554
    		c-0.444-0.144-0.993,0.076-1.488,0.039c-7.029-0.535-13.68-3.048-20.662-4.082c-3.551-0.525-7.163-1.975-10.99-1.935
    		c-2.782,0.029-5.529,0.412-7.836-2.144c-0.781-0.865-3.472-0.981-4.574-0.293c-0.988,0.617-1.177,2.787-1.362,4.315
    		c-0.111,0.918,0.924,2.111,0.623,2.843c-0.604,1.466-1.515,3.425-2.764,3.865c-1.994,0.705-2.495-1.146-2.898-2.985
    		c-1.045-4.757,2.346-7.626,5.068-9.821c3.51-2.83,4.716-5.633,3.98-9.83c-0.439-2.513,0.591-3.933,3.404-3.006
    		c3.938,1.296,7.534,0.484,10.748-1.934c2.417-1.818,3.054-1.382,3.717,1.621c1.084,4.905,7.204,9.075,11.415,8.565
    		c1.922-0.232,3.503-1.057,2.929-3.069c-0.35-1.224-1.897-2.146-3.011-3.082c-0.291-0.244-0.969-0.04-1.468-0.023
    		c-3.312,0.111-4.744-1.771-4.134-4.728c1.626-7.873,0.444-15.916,2.34-23.911c1.42-5.99,0.06-12.608,5.979-16.946
    		c0.074-4.723,3.049-5.032,6.79-4.836c1.015,0.053,2.437-1.407,3.047-2.516c0.312-0.566-0.486-2.3-1.25-2.813
    		c-6.398-4.307-16.043,0.288-17.023,7.935c-0.181,1.404-0.954,2.726-1.383,4.107c-0.521,1.682-1.152,3.36-1.408,5.088
    		c-0.656,4.429,1.403,9.332-2.59,13.228c-0.502,0.49-0.027,2.634,0.632,3.555c3.293,4.603-1.833,8.058-1.542,12.22
    		c0.013,0.17-1.159,0.63-1.761,0.6c-2.942-0.151-5.491,0.397-7.606,2.756c-0.538,0.6-2.19,0.51-3.238,0.292
    		c-5.424-1.127-10.585-3.802-16.418-2.485c-0.76,0.171-2.115-0.382-2.489-1.016c-0.307-0.519,0.185-2.131,0.761-2.439
    		c2.244-1.202,4.6-2.366,7.054-2.917c0.845-0.189,2.565,1.228,3.086,2.3c1.848,3.805,2.136,3.974,6.154,2.028
    		c0.767-0.371,2.089-0.698,2.591-0.312c1.986,1.527,3.966,2.196,5.483-0.104c0.233-0.354-2.127-2.672-3.521-3.789
    		c-2.84-2.276-5.296-4.683-4.471-8.731c0.738-3.624,3.098-5.178,6.662-6.338c2.55-0.831,4.495-3.695,6.493-5.853
    		c0.3-0.323-0.334-2.044-0.98-2.604c-1.573-1.364-3.311-2.626-5.171-3.547c-1.447-0.718-3.162-1.004-4.792-1.214
    		c-1.459-0.188-3.998,0.465-4.251-0.085c-0.687-1.495-1.05-3.729-0.392-5.13c1.374-2.924,3.568-5.451,5.259-8.242
    		c0.71-1.172,1.757-3.286,1.319-3.772c-0.946-1.049-2.761-1.796-4.205-1.772c-1.266,0.021-2.646,1.097-3.741,1.992
    		c-4.499,3.673-5.717,8.764-5.525,14.227c0.089,2.537,0.603,5.142,1.42,7.547c0.679,1.995,2.226,3.68,3.105,5.629
    		c0.398,0.882,0.275,2.101,0.101,3.117c-0.326,1.903-1.095,3.751-1.252,5.657c-0.207,2.523-1.31,3.757-3.806,3.374
    		c-5.694-0.873-9.074,3.008-12.768,6.17c-0.653,0.559-0.953,1.718-1.099,2.65c-0.98,6.252,2.715,9.58,9.665,8.781
    		c3.471-0.398,5.522,0.776,5.856,3.727c0.314,2.768-0.634,4.646-3.85,5.3c-3.551,0.723-5.718,3.107-5.887,6.994
    		c-0.057,1.307-0.27,2.65-0.674,3.893C297.951,445.646,302.541,451.907,309.373,451.89z M583.056,454.995
    		c-1.275,0.058-2.466,0.291-3.596,0.121c-1.813-0.272-4.979-0.537-5.128-1.289c-0.564-2.851,2.624-1.443,3.774-2.487
    		c1.136-1.031,1.861-2.516,2.767-3.801c-1.286-0.471-2.545-1.231-3.862-1.351c-2.479-0.224-5,0.049-7.488-0.117
    		c-2.669-0.179-4.519,0.166-4.735,3.504c-0.059,0.898-1.86,2.377-2.815,2.341c-1.065-0.039-2.253-1.363-3.061-2.386
    		c-0.664-0.842-0.64-2.219-1.287-3.086c-2.914-3.905-9.606-4.642-13.672-1.797c-2.088,1.46-4.357,2.7-6.672,3.764
    		c-0.556,0.256-1.732-0.43-2.423-0.948c-2.882-2.167-4.352-1.985-5.427,1.271c-0.627,1.898-0.981,3.643-3.429,3.315
    		c-2.654-0.355-2.511-2.489-2.884-4.51c-0.972-5.264-2.188-10.485-3.409-15.7c-0.175-0.745-1-1.315-1.374-2.044
    		c-0.447-0.872-1.05-1.834-1.029-2.743c0.074-3.227-0.451-6.092-2.277-8.954c-0.791-1.24-0.405-4.099,0.562-5.377
    		c1.245-1.646,7.48,1.545,7.594,3.803c0.142,2.825-0.296,5.723,0.189,8.47c0.262,1.478,1.691,3.152,3.053,3.887
    		c0.892,0.481,3.644-0.346,3.772-0.962c0.562-2.685,0.549-5.507,0.572-8.279c0.008-0.986-0.475-1.978-0.734-2.966
    		c1.092-0.16,2.18-0.409,3.275-0.461c2.125-0.1,5.057,0.786,6.229-0.29c3.342-3.063,7.152-2.524,10.824-2.324
    		c3.003,0.164,5.222-0.669,7.032-2.813c0.667-0.791,0.938-1.917,1.39-2.891c-1.146-0.32-2.352-1.052-3.425-0.891
    		c-4.886,0.733-9.805,1.443-14.58,2.671c-8.319,2.139-15.925-0.879-23.683-2.821c-0.924-0.231-1.718-1.478-2.317-2.415
    		c-2.521-3.943-3.81-4.142-7.176-0.882c-1.195,1.157-2.325,2.381-3.501,3.558c-1.842,1.844-1.822,3.466,1.048,3.497
    		c3.38,0.036,3.21,2.07,3.193,4.316c-0.021,3.134-0.518,6.363,2.271,8.839c0.512,0.454,0.578,1.515,0.673,2.316
    		c0.552,4.691,2.605,9.052,3.792,13.538c1.003,3.794,1.976,7.448,2.146,11.374c0.174,3.985,3.377,6.968,7.398,6.969
    		c5.076,0.002,10.152-0.055,15.229-0.068c7.663-0.02,15.326-0.056,22.989-0.004c1.286,0.009,3.137-0.032,3.753,0.733
    		c2.325,2.889,5.348,2.306,8.283,2.197c5.956-0.221,11.906-0.735,17.862-0.79c3.952-0.035,7.906,0.556,11.864,0.793
    		c2.876,0.172,4.491-1.383,4.468-4.146c-0.017-1.909-0.382-3.943-1.124-5.695c-2.511-5.927-5.23-11.607-4.764-18.47
    		c0.256-3.762-1.29-7.69-2.281-11.476c-0.664-2.539-2.696-2.82-4.769-1.774c-1.938,0.979-1.491,2.44-0.729,4.214
    		c0.938,2.183,1.67,4.585,1.842,6.939c0.344,4.74-0.662,9.599,2.676,13.86c1.374,1.755,2.113,4.091,2.795,6.271
    		c0.382,1.22,0.055,2.661,0.048,4.003c-1.012-0.552-2.354-0.86-2.974-1.702c-1.969-2.676-4.703-2.729-6.447-0.805
    		C590.309,455.398,586.808,455.444,583.056,454.995z M449.818,566.574c-0.245-0.073-0.491-0.146-0.736-0.22
    		c0-2.664,0.07-5.331-0.048-7.989c-0.03-0.695-0.508-1.763-1.025-1.94c-0.572-0.195-1.62,0.241-2.126,0.74
    		c-2.718,2.685-4.37,5.643-3.397,9.807c0.668,2.86,1.071,6.055,0.467,8.856c-0.508,2.354-2.337,4.673-4.146,6.42
    		c-1.699,1.64-4.525,0.089-4.555-2.384c-0.015-1.224,0.756-2.467,1.215-3.685c1.063-2.824-1.006-7.901-3.792-8.674
    		c-3.829-1.062-7.7-2.294-11.622-2.621c-5.831-0.485-11.883,1.162-17.253-2.719c-1.399-1.012-3.422-1.162-5.157-1.708
    		c1.9-1.438,3.758-2.942,5.74-4.259c0.378-0.251,1.256,0.389,1.894,0.362c2.208-0.089,3.107-1.656,1.688-3.219
    		c-0.928-1.021-2.744-2.351-3.688-2.05c-4.197,1.337-8.431,2.861-12.189,5.096c-1.194,0.709-1.42,3.879-1.188,5.815
    		c0.12,1.004,2.149,1.874,3.428,2.612c0.978,0.564,2.126,0.836,3.204,1.223c6.191,2.221,11.936,5.615,19.088,4.855
    		c3.403-0.361,7.154,1.117,10.496,2.412c1.073,0.416,1.785,2.914,1.779,4.456c-0.003,0.701-2.24,2.104-3.317,1.976
    		c-7.113-0.854-14.188-2.027-21.268-3.142c-0.441-0.07-0.979-0.436-1.206-0.82c-1.468-2.489-3.722-2.285-6.141-2.089
    		c-4.858,0.394-8.915-2.145-12.273-4.938c-4.089-3.4-3.876-10.51-1.037-15.967c0.742-1.427,0.11-3.568,0.11-5.382
    		c-1.784,0.379-3.807,0.349-5.305,1.22c-3.377,1.967-2.471,5.535-2.363,8.596c0.198,5.678-2.479,9.167-7.369,11.791
    		c-3.153,1.692-4.428,0.243-6.15-1.844c-1.909-2.314-1.615-4.023,0.572-5.929c5.501-4.79,4.771-11.193,3.908-17.385
    		c-0.241-1.73-1.842-3.521-3.264-4.782c-2.436-2.159-6.408-1.338-8.226,1.362c-0.733,1.089-1.328,2.297-2.189,3.271
    		c-1.624,1.833-1.266,3.442,0.601,4.568c1.354,0.817,2.988,1.284,4.56,1.598c2.795,0.559,3.332,2.551,2.145,4.402
    		c-1.639,2.557-3.454,5.191-7.443,4.775c-4.123-0.431-8.324-0.361-12.473-0.149c-1.169,0.06-2.745,1.165-3.231,2.211
    		c-0.298,0.643,0.898,2.377,1.832,3.073c0.817,0.61,2.202,0.582,3.343,0.616c3.492,0.108,6.988,0.091,10.481,0.161
    		c1.953,0.04,3.411,0.645,2.503,3.012c-1.259,3.283-0.113,5.036,3.229,5.79c0.866,0.195,1.601,1.062,2.347,1.679
    		c2.688,2.222,5.585,1.936,8.271,0.303c5.251-3.193,7.049-3.27,11.044,1.179c2.14,2.383,4.36,3.11,7.431,2.826
    		c3.271-0.303,6.277,0.131,9.446,2.02c3.528,2.104,7.911,3.319,12.043,3.74c5.764,0.588,11.646-0.078,17.464,0.132
    		c1.927,0.07,4.068,0.818,5.674,1.904c4.561,3.083,12.655,1.692,14.005-2.983C447.351,578.653,448.455,572.576,449.818,566.574z
    		 M345.946,342.164c3.887-0.635,7.844,1.125,12.145,2.393c5.347,1.576,9.671,4.85,12.41,8.354
    		c3.862,4.943,8.709,9.144,11.542,15.267c4.107,8.878,7.896,17.521,7.856,27.468c-0.01,2.639-0.642,6.024,0.702,7.751
    		c2.265,2.909,2.107,5.792,2.128,8.895c0.036,5.329,0.09,10.659-0.01,15.985c-0.037,1.973-0.401,3.954-0.767,5.903
    		c-0.627,3.355-1.409,6.683-2.047,10.036c-0.216,1.134,0.048,2.396-0.319,3.458c-1.908,5.53-3.554,11.207-6.091,16.445
    		c-1.551,3.202-3.006,6.129-2.681,9.751c0.07,0.774-0.224,1.69-0.625,2.382c-2.2,3.801-4.666,7.459-6.671,11.356
    		c-1.091,2.121-1.743,4.583-2.047,6.961c-0.277,2.169,1.334,2.511,2.954,1.562c0.96-0.562,1.767-1.501,2.443-2.417
    		c5.071-6.868,10.018-13.792,10.06-22.901c0.005-0.899,0.719-1.81,1.151-2.691c2.077-4.234,5.308-8.018,4.743-13.262
    		c-0.102-0.936,0.977-1.972,1.43-2.996c1.945-4.396,4.884-8.233,4.589-13.65c-0.488-8.962,0.265-17.988-0.153-26.957
    		c-0.347-7.456,1.893-15.346-2.926-22.258c-0.345-0.493-0.188-1.311-0.368-1.947c-0.704-2.473-1.586-4.904-2.127-7.41
    		c-0.297-1.376-0.44-3.057,0.116-4.235c0.3-0.636,2.267-0.819,3.408-0.666c6.356,0.848,12.036,0.521,14.297-6.971
    		c0.089-0.295,0.371-0.558,0.614-0.777c2.687-2.42,1.752-5.066,0.472-7.713c-0.503-1.042-1.225-1.988-1.642-3.059
    		c-0.792-2.034-1.837-3.451-4.363-3.207c-3.128,0.303-4.95,2.349-5.607,4.938c-0.327,1.289,1.133,3.051,1.83,4.577
    		c0.688,1.506,2.217,3.118,1.989,4.446c-0.44,2.576-2.92,2.077-4.926,1.935c-2.507-0.179-5.312-1.624-7.07,1.72
    		c-0.14,0.265-2.304,0.058-2.713-0.513c-2.017-2.816-3.847-5.779-5.527-8.81c-0.365-0.66-0.009-1.882,0.358-2.688
    		c0.108-0.238,1.655-0.234,2.098,0.16c3.017,2.684,4.852,0.826,7.157-1.266c2.913-2.645,2.552-5.978,1.75-8.782
    		c-0.742-2.598-2.692-5.588-4.969-6.815c-2.663-1.436-4.718,1.433-6.267,3.642c-1.124,1.602-2.441,3.167-3.129,4.957
    		c-1.521,3.965-1.4,3.842-4.543,1.102c-5.527-4.819-8.84-12.777-17.779-12.903c-2.987-3.378-6.967-2.384-10.721-2.474
    		c-2.651-0.062-5.354-0.001-7.933-0.51c-2.448-0.483-4.796-0.479-4.951,2.264c-0.136,2.392,1.624,4.271,4.545,4.184
    		C341.593,342.12,343.425,342.164,345.946,342.164z M543.994,529.053c0,0.009,0,0.017,0,0.025c8.16,0,16.323,0.124,24.477-0.092
    		c1.931-0.051,3.79-1.429,5.756-1.854c1.568-0.34,3.307-0.446,4.869-0.142c2.327,0.454,4.522,1.68,6.854,2.008
    		c4.33,0.61,8.595,0.645,12.877,2.443c2.808,1.18,7.292,0.936,9.934-0.577c6.104-3.494,7.779-11.574,4.987-17.994
    		c-0.84-1.93-1.36-4.081-1.579-6.179c-0.304-2.92-0.234-5.577-2.065-8.425c-1.534-2.383-3.085-5.805-1.223-9.392
    		c0.475-0.912-1.148-2.914-1.808-4.415c-1.536,0.958-3.175,1.793-4.547,2.945c-0.434,0.364-0.178,1.577-0.197,2.402
    		c-0.207,8.974,4.982,17.065,4.289,26.152c-0.074,0.969,0.997,2.036,1.562,3.043c2.401,4.271,0.877,6.994-4.053,7.202
    		c-1.164,0.049-2.333,0.085-3.496,0.041c-2.169-0.082-2.859-0.618-1.825-3.083c1.032-2.459,1.447-5.35,1.408-8.04
    		c-0.048-3.241-2.886-3.744-5.44-3.893c-2.285-0.134-6.043,3.366-6.255,5.642c-0.274,2.953,1.742,3.026,3.89,3.155
    		c0.591,0.035,1.331,0.879,1.609,1.521c0.154,0.354-0.534,1.552-0.799,1.537c-3.364-0.179-7.406,0.573-9.934-1.028
    		c-4.934-3.129-4.619-3.49-9.778-1.198c-2.78,1.234-5.936,2.211-8.938,2.259c-14.317,0.229-28.642,0.136-42.963,0.065
    		c-2.306-0.012-4.656-0.289-6.892-0.833c-1.107-0.271-2.442-1.219-2.912-2.218c-2.326-4.95-3.752-10.022-3.528-15.721
    		c0.205-5.241,1.941-10.98-2.55-15.674c-0.705-0.736-0.309-2.557-0.365-3.879c-0.057-1.327,0.303-2.799-0.166-3.944
    		c-0.415-1.016-1.704-2.294-2.629-2.312c-0.954-0.018-2.74,1.325-2.761,2.11c-0.088,3.264,0.178,6.567,0.659,9.806
    		c0.374,2.519,1.845,4.971,1.81,7.438c-0.114,7.999,1.228,15.769,3.337,23.381c0.536,1.936,2.597,4.263,4.455,4.858
    		c4.493,1.438,9.248,2.429,13.954,2.743C530.649,529.384,537.333,529.053,543.994,529.053z M472.758,392.866
    		c0,1.83,0.034,3.661-0.01,5.49c-0.051,2.179,0.302,4.269,2.964,4.275c2.645,0.007,3.102-2.113,3.052-4.265
    		c-0.116-4.902,0.879-9.876-2.156-14.481c-1.576-2.394-1.4-5.339,1.736-6.77c3.199-1.459,4.861,1.117,5.618,3.358
    		c1.294,3.833,2.869,3.689,5.792,1.668c4.13-2.855,5.583-1.988,7.021,2.997c0.629,2.179,1.908,4.205,3.092,6.181
    		c3.216,5.363,9.689,6.342,14.576,2.335c1.856-1.521,3.096-2.879,1.016-5.33c-0.709-0.836-0.628-3.736-0.312-3.837
    		c4.115-1.321,8.338-2.309,12.534-3.38c3.354,4,7.913,2.373,12.091,2.667c1.379,0.098,2.928,0.506,4.033,1.288
    		c3.651,2.585,6.801,2.426,10.076-0.835c2.323-2.313,2.885-8.08,0.765-10.496c-0.741-0.845-2.215-1.047-3.354-1.542
    		c-0.389,0.979-0.933,1.929-1.126,2.944c-0.215,1.129,0.332,2.668-0.247,3.397c-0.862,1.088-2.422,1.624-3.684,2.395
    		c-0.271-1.071-0.488-2.158-0.822-3.209c-0.395-1.241-0.602-2.772-1.46-3.539c-0.442-0.395-2.394,0.294-3.198,1.014
    		c-4.503,4.024-6.205,4.322-11.212,0.823c-1.86-1.299-3.394-1.592-5.508-0.746c-2.853,1.141-5.812,2.116-8.811,2.772
    		c-4.355,0.953-6.782,3.401-6.895,7.146c-0.06,2.005-0.587,3.996-0.903,5.993c-1.442-1.622-3.175-3.072-4.263-4.905
    		c-1.763-2.971-0.746-4.777,2.624-5.724c1.045-0.293,2.313-0.693,2.922-1.477c1.826-2.356,3.544-4.834,4.987-7.439
    		c1.355-2.445-3.228-9.645-5.937-9.572c-0.46,0.012-1.188,0.169-1.325,0.472c-1.742,3.832-7.534,4.494-7.379,9.992
    		c0.088,3.109-2.905,2.495-4.996,2.567c-2.264,0.079-3.372-1.019-3.403-3.3c-0.027-1.993,0.194-4.094-0.373-5.938
    		c-0.341-1.107-1.876-2.166-3.091-2.535c-0.503-0.153-2.154,1.48-2.277,2.433c-0.443,3.43-2.171,4.229-5.456,4.175
    		c-4.621-0.077-11.132,8.486-8.92,12.059C472.778,385.601,472.932,389.062,472.758,392.866z M699.037,508.071
    		c-0.852-3.224-1.074-6.103-2.375-8.361c-1.057-1.835-3.215-3.388-5.233-4.221c-2.123-0.875-4.932-1.526-6.938-0.828
    		c-1.856,0.646-4.429,2.13-3.238,5.704c0.933,2.799-0.901,4.601-4.05,4.819c-3.959,0.274-7.922,0.619-11.85,1.173
    		c-2.176,0.307-5.471,0.309-4.942,3.403c0.212,1.241,3.603,2.05,5.638,2.808c0.635,0.235,1.578-0.318,2.369-0.551
    		c3.724-1.094,7.419-2.292,11.173-3.269c2.211-0.576,4.812-0.307,6.645-1.406c0.912-0.547,0.219-3.409,0.773-5.048
    		c0.344-1.015,1.555-2.076,2.595-2.388c0.644-0.192,2.472,1.025,2.444,1.537c-0.229,4.205-0.126,8.609-1.439,12.51
    		c-0.893,2.648-0.789,4.187,0.166,6.753c1.172,3.151,1.76,7.421,0.516,10.308c-1.16,2.69-1.785,3.89,0.351,6.165
    		c1.881,2.005,0.994,3.679-1.869,3.938c-0.662,0.06-1.501-0.15-1.957,0.181c-1.245,0.903-2.329,2.028-3.478,3.065
    		c1.205,0.833,2.345,1.792,3.634,2.462c1.229,0.639,2.615,0.975,3.932,1.443c-0.554,1.462-0.721,3.349-1.751,4.287
    		c-1.589,1.446-3.662,2.697-5.729,3.183c-1.913,0.449-3.661,0.468-3.929,2.845c-0.272,2.416-0.236,4.959,2.531,5.839
    		c2.212,0.703,3.211,1.846,3.704,4.074c0.206,0.933,1.734,2.177,2.659,2.175c0.917-0.002,1.996-1.27,2.702-2.208
    		c0.764-1.015,1.245-2.264,1.724-3.461c0.395-0.985,0.604-2.043,0.895-3.069c-0.894-0.061-1.829,0.021-2.67-0.224
    		c-0.787-0.229-1.476-0.801-2.207-1.222c0.616-0.597,1.19-1.645,1.857-1.708c2.444-0.233,4.946-0.378,7.379-0.118
    		c4.877,0.522,6.92,4.568,3.934,8.507c-2.557,3.373-1.443,7.038-1.978,10.536c-0.317,2.071,2.189,3.152,3.814,1.739
    		c0.935-0.812,1.969-2.223,1.926-3.323c-0.209-5.27,2.441-10.252,1.111-15.676c-1.008-4.109-3.173-6.945-7.175-7.841
    		c-1.21-0.271-2.355-0.826-3.531-1.252c0.658-1.297,1.168-2.7,2.003-3.871c2.389-3.352,5.198-6.434,7.31-9.942
    		c3.254-5.409-0.208-11.085-6.527-11.381c-1.319-0.062-3.607-0.691-3.725-1.361c-0.443-2.531-0.563-5.263-0.077-7.778
    		C696.754,513.909,698.079,510.939,699.037,508.071z M606.143,390.025c0-0.066,0-0.132,0.001-0.198c2.831,0,5.669,0.112,8.493-0.024
    		c5.253-0.254,9.499-4.213,9.674-9.362c0.163-4.811-0.114-9.64-0.306-14.457c-0.037-0.943-0.336-2.052-0.908-2.769
    		c-2.841-3.557-2.08-7.041-0.221-10.693c0.651-1.279,1.148-2.839,1.059-4.229c-0.068-1.064-1.095-2.843-1.827-2.912
    		c-1.207-0.114-2.858,0.592-3.692,1.52c-0.784,0.871-0.965,2.463-1.047,3.761c-0.558,8.799-0.206,17.511,2.36,26.055
    		c0.373,1.243-0.086,2.959-0.688,4.2c-0.485,0.999-1.769,1.61-2.697,2.394c-0.469-1.112-1.277-2.204-1.349-3.341
    		c-0.34-5.431-2.275-7.902-6.623-8.066c-4.795-0.181-7.892,2.717-8.234,7.816c-0.124,1.837,0.176,3.938-2.349,4.249
    		c-2.396,0.294-3.034-1.411-3.351-3.424c-0.449-2.856-2.111-3.552-4.685-2.364c-2.916,1.347-5.758,2.929-8.787,3.944
    		c-5.797,1.942-13.916-2.106-15.056-8.042c-1.053-5.482-0.863-11.205-1.205-16.823c-0.11-1.813,0.097-3.692-0.316-5.429
    		c-0.637-2.676-1.56-5.306-2.623-7.845c-0.364-0.87-1.797-2.128-2.252-1.951c-0.989,0.386-1.736,1.527-2.423,2.475
    		c-0.212,0.292,0.024,0.978,0.167,1.448c2.207,7.31,1.729,14.812,1.672,22.29c-0.008,0.981,0.14,1.989,0.383,2.942
    		c0.839,3.301,1.301,6.807,2.783,9.8c1.129,2.28,3.373,5.206,5.497,5.556c5.119,0.842,10.462,0.257,15.709,0.406
    		c4.128,0.117,8.681-1.835,12.061,2.496c0.508,0.651,2.161,0.521,3.29,0.555C601.148,390.077,603.646,390.025,606.143,390.025z
    		 M394.543,496.158c-1.033,0.429-3.408,0.697-4.22,1.967c-0.702,1.099-0.205,4.136,0.804,4.856c1.224,0.873,3.805,0.791,5.333,0.097
    		c3.224-1.464,6.144-3.595,9.188-5.453c2.928-1.786,5.065-1.085,4.647,1.9c-0.639,4.56,2.121,6.23,5.235,7.957
    		c2.536,1.405,5.01,3.73,7.661,3.968c7.086,0.636,14.255,0.504,21.389,0.426c10.809-0.118,21.615-0.408,32.421-0.718
    		c2.405-0.068,4.002,0.382,4.995,2.962c1.512,3.925,7.04,6.733,10.349,5.71c3.519-1.089,7.571-8.535,6.662-12.243
    		c-1.114-4.546-4.977-6.101-9.01-4.013c-1.952,1.011-4.312,1.54-6.524,1.676c-4.144,0.254-8.314,0.036-12.475,0.064
    		c-4.274,0.029-8.434-0.184-12.632,1.787c-2.16,1.015-5.398-0.132-8.123-0.495c-2.091-0.278-4.129-1.164-6.211-1.244
    		c-4.984-0.191-10.064-0.631-14.949,0.091c-4.683,0.693-7.416-3.317-11.525-3.535c-0.296-0.017-0.676-0.708-0.811-1.148
    		c-1.048-3.431-1.925-6.92-3.14-10.288c-0.319-0.883-2.036-2.176-2.432-1.971c-2.702,1.401-5.418,2.917-7.748,4.855
    		C401.034,495.357,398.793,497.171,394.543,496.158z M422.225,373.216c0.01,0,0.021,0,0.03,0c0-1.165,0.037-2.333-0.009-3.497
    		c-0.078-1.988,0.196-4.123-0.455-5.914c-0.465-1.28-2.2-2.1-3.369-3.125c-0.746,1.141-1.652,2.21-2.199,3.438
    		c-0.717,1.611-1.185,3.338-1.692,5.034c-0.278,0.933-0.926,2.376-0.543,2.788c3.427,3.693-0.032,6.837-0.884,9.967
    		c-1.224,4.502-4.127,4.923-7.32,1.575c-0.229-0.241-0.459-0.482-0.681-0.731c-1.263-1.425-2.943-3.107-4.526-1.329
    		c-1.038,1.166-1.475,3.786-0.921,5.292c2.544,6.92,5.333,7.718,12.01,3.93c1.678-0.952,3.324-1.975,4.902-3.082
    		c1.509-1.06,2.348-0.639,3.07,0.923c0.589,1.271,1.235,3.33,2.143,3.498c4.13,0.767,8.559,2.352,12.374-0.727
    		c2.168-1.75,3.729-1.303,5.756,0.083c5.082,3.477,8.51,2.52,12.569-2.186c2.371-2.748,2.323-5.656,2.548-8.792
    		c0.641-8.951-2.636-18.238,2.431-26.91c0.431-0.737-2.118-3.216-3.282-4.886c-0.938,1.101-2.369,2.061-2.727,3.324
    		c-1.471,5.199-2.649,10.482-3.842,15.756c-0.166,0.732,0.23,1.582,0.306,2.383c0.42,4.426,0.908,8.849,1.197,13.283
    		c0.181,2.772-1.393,3.979-4.086,3.581c-2.745-0.404-2.975-2.037-2.146-4.366c0.622-1.75,1.058-4.102-1.492-4.413
    		c-3.077-0.376-6.312-0.248-9.361,0.308c-1.306,0.238-2.312,2.108-3.455,3.236c1.162,0.663,2.373,1.256,3.469,2.015
    		c0.709,0.49,1.249,1.223,1.865,1.847c-0.758,0.534-1.451,1.381-2.283,1.552c-4.388,0.903-9.141-2.854-9.376-7.36
    		C422.132,377.551,422.225,375.381,422.225,373.216z M503.562,473.659c0.106-0.289,0.213-0.578,0.319-0.868
    		c0.666,0,1.333-0.014,1.998,0.003c2.477,0.06,5.547,0.671,5.497-3.115c-0.046-3.481-3.008-3.299-5.537-3.486
    		c-1.781-0.133-3.554-0.482-5.311-0.834c-2.055-0.412-4.07-1.203-6.136-1.346c-3.627-0.249-7.313,0.253-10.381-2.54
    		c-0.695-0.633-2.545-0.918-3.264-0.447c-3.456,2.264-6.803-0.489-10.198,0.023c-0.737,0.111-1.671-1.077-2.515-1.667
    		c0.741-0.526,1.41-1.279,2.235-1.54c3.475-1.101,7.045-1.912,10.486-3.102c3.358-1.161,4.862-3.567,3.213-5.379
    		c-2.853-3.135-2.373-6.692-2.604-10.319c-0.12-1.89-0.865-3.794-1.601-5.578c-2.056-4.988-4.957-9.684-4.021-15.507
    		c0.365-2.27,0.029-4.652,0.01-6.983c-0.018-2.088-0.853-3.939-3.072-3.566c-1.05,0.177-2.295,2.043-2.599,3.343
    		c-2.23,9.543,4.032,18.212,3.07,27.694c-0.071,0.698,1.199,1.485,1.739,2.303c4.182,6.323,2.859,9.581-4.62,11.284
    		c-4.194,0.955-6.409,4.325-8.855,7.318c-0.538,0.659-0.456,2.54,0.107,3.248c1.7,2.136,3.216,4.699,6.71,4.398
    		c1.957-0.169,3.979,0.126,5.944,0.406c5.333,0.76,10.65,1.628,15.975,2.444c1.466,0.225,2.992,0.236,4.391,0.668
    		C497.584,471.452,500.56,472.599,503.562,473.659z M638.879,366.012c0-4.995,0.174-10-0.128-14.977
    		c-0.071-1.184-1.737-2.27-2.669-3.399c-0.98,1.114-2.156,2.119-2.87,3.384c-0.439,0.778-0.21,1.954-0.222,2.952
    		c-0.109,9.081-0.254,18.161-0.285,27.242c-0.016,4.678,4.487,9.867,8.745,11.714c3.952,1.715,7.494-0.019,11.208-0.147
    		c8.227-0.286,13.34-8.741,9.801-16.138c-0.312-0.652-1.089-1.244-1.786-1.521c-1.832-0.728-3.761-1.81-5.609-1.735
    		c-2.145,0.085-4.454,0.931-6.263,2.105c-0.73,0.475-0.842,2.934-0.322,4.074c0.933,2.051,2.856,2.373,5.108,1.653
    		c0.808-0.259,2.741,0.458,2.814,0.938c0.202,1.308,0.137,3.092-0.628,4.032c-2.307,2.835-8.077,3.101-11.517,1.4
    		c-2.289-1.132-6.566-0.893-7.092-2.32c-0.771-2.096,1.381-5.1,1.625-7.777C639.135,373.694,638.877,369.842,638.879,366.012z
    		 M450.666,437.104c-4.666-0.091-8.354,3.553-7.291,7.456c0.424,1.558,2.481,2.646,3.724,4.015c0.623,0.687,1.233,1.471,1.512,2.321
    		c0.07,0.213-1.239,1.315-1.784,1.225c-4.536-0.753-9.611,1.959-13.715-2.407c-0.746-0.794-3.492,0.29-5.315,0.508
    		c0.546,1.521,0.652,3.76,1.726,4.417c2.448,1.497,5.297,2.688,8.12,3.134c3.351,0.528,6.853,0.003,10.275,0.196
    		c3.222,0.183,5.103-1.359,7.032-3.767c1.755-2.188,4.179-3.998,6.621-5.445c4.573-2.711,7.899-5.544,2.49-11.159
    		c-1.483-1.541-2.118-3.891-3.186-5.846c-0.651-1.194-1.156-2.666-2.172-3.387c-0.568-0.402-2.823,0.274-2.96,0.811
    		c-0.368,1.449-0.434,3.28,0.175,4.591c0.659,1.42,2.526,2.259,3.268,3.667c0.46,0.874,0.106,2.504-0.44,3.48
    		c-0.474,0.845-1.737,1.246-2.651,1.844c-0.417-0.816-1.164-1.621-1.192-2.45C454.793,437.126,452.68,437.042,450.666,437.104z
    		 M589.016,577.377c0.14-0.65,0.54-1.38,0.372-1.939c-0.776-2.589-0.967-6.156-2.758-7.456c-2.896-2.103-4.135,2.116-6.203,3.432
    		c-0.544,0.345-1.237,0.908-1.313,1.448c-0.206,1.455-0.5,3.093-0.026,4.39c0.403,1.102,1.753,1.936,2.819,2.699
    		c1.86,1.334,1.749,3.259,0.33,4.189c-2.772,1.819-5.907,3.119-8.977,4.439c-1.846,0.794-3.845,1.239-5.786,1.8
    		c-0.737,0.213-1.507,0.312-2.263,0.462c-0.155-0.782-0.62-1.669-0.399-2.325c0.406-1.203,1.443-2.202,1.808-3.41
    		c0.597-1.978,1.491-4.178,1.117-6.057c-0.5-2.51-2.006-4.849-3.279-7.15c-0.292-0.529-1.653-1.051-2.072-0.806
    		c-0.89,0.521-1.922,1.472-2.081,2.388c-0.175,1.001,0.535,2.176,0.922,3.252c2.121,5.902,0.188,9.594-5.849,11.854
    		c-3.177,1.189-6.25,2.736-9.187,4.438c-0.898,0.521-1.188,2.093-1.756,3.184c1.019,0.358,2.208,1.25,3.024,0.986
    		c3.837-1.24,7.748-2.469,11.275-4.365c2.07-1.113,4.276-2.647,5.598-0.486c1.758,2.877,3.935,2.296,6.387,2.423
    		c3.038,0.157,5.193-1.536,7.756-2.622C585.202,589.292,589.177,584.929,589.016,577.377z M700.875,432.011
    		c-0.085-0.289-0.17-0.578-0.254-0.868c-1.33,0-2.701-0.206-3.977,0.055c-1.442,0.296-2.798,1.011-4.191,1.543
    		c1.037,1.332,1.819,3.232,3.18,3.841c1.466,0.655,3.681,0.609,5.207-0.015c5.678-2.322,11.862-0.618,14.605,4.677
    		c0.544,1.051,0.174,2.839-0.312,4.075c-0.385,0.979-1.836,1.505-2.362,2.477c-0.484,0.896-0.369,2.116-0.519,3.194
    		c1.214,0.147,2.635,0.779,3.608,0.363c6.961-2.977,7.391-4.667,3.137-10.898c-0.188-0.274-0.37-0.553-0.549-0.833
    		c-2.046-3.223-3.135-5.969-0.373-9.917c2.017-2.882,2.039-7.209-0.826-10.599c-2.167-2.565-0.965-7.13,2.308-8.398
    		c3.852-1.494,1.933-4.822,2.509-7.322c0.091-0.395-1.221-1.111-1.885-1.68c-1.307,1.582-2.631,3.151-3.902,4.762
    		c-0.172,0.218-0.121,0.65-0.1,0.978c0.171,2.587-1.419,3.076-3.503,2.93c-1.325-0.093-2.703-0.06-3.955-0.43
    		c-3.127-0.925-4.352,0.392-4.516,3.365c-0.045,0.809-0.563,1.615-0.513,2.399c0.085,1.315,0.451,2.612,0.699,3.918
    		c1.012-0.547,2.203-0.906,3.001-1.675c2.214-2.131,4.593-2.29,5.755-0.18c1.103,2.004-0.151,9.996-2.229,10.966
    		C707.752,430.218,704.24,430.957,700.875,432.011z M410.321,429.942c0.096,0.992-0.166,2.255,0.347,2.931
    		c3.282,4.332,2.478,9.464,3.09,14.323c0.143,1.135,0.516,2.252,0.877,3.347c1.521,4.611,3.017,9.233,4.675,13.796
    		c0.321,0.887,1.302,2.141,2.018,2.168c2.576,0.098,5.447-4.984,3.662-6.71c-3.097-2.993-2.845-6.489-2.867-10.146
    		c-0.007-1.112-0.459-2.229-0.759-3.331c-0.622-2.286-1.355-4.546-1.895-6.852c-0.336-1.433,0.057-3.234-0.68-4.333
    		c-1.554-2.321-0.635-3.895,0.586-5.858c1.863-2.999,4.213-4.507,7.838-4.092c3.637,0.416,5.61-2.186,6.711-4.939
    		c1.274-3.192-1.906-4.672-3.762-6.338c-1.642-1.474-4.167-0.91-4.421,1.104c-0.44,3.498-2.712,4.046-5.255,4.898
    		c-1.689,0.566-3.373,1.36-4.824,2.387C413.015,424.17,409.906,425.855,410.321,429.942z M632.951,463.859
    		c4.077,1.173,6.727-1.609,9.139-5.074c5.027-7.219,3.218-13.606-1.158-19.972c-0.592-0.86-2.967-1.057-4.285-0.701
    		c-1.308,0.354-2.542,1.626-3.42,2.789c-1.535,2.035-4.248,4.693-3.795,6.367c0.698,2.576,4.027,2.598,6.832,1.818
    		c1.538-0.428,3.067,0.594,1.902,2.154c-2.223,2.975-2.435,8.898-8.76,6.71c-0.564-0.195-1.442,0.745-2.226,0.899
    		c-1.062,0.209-2.185,0.309-3.258,0.206c-2.613-0.247-5.213-0.629-7.816-0.974c-2.231-0.296-3.601,0.691-3.807,2.883
    		c-0.244,2.599,1.576,2.908,3.598,2.9C621.355,463.846,626.813,463.859,632.951,463.859z M270.293,457.124
    		c0.372,0.043,0.743,0.086,1.115,0.128c-2.094,6.648,2.729,9.545,6.975,11.734c2.57,1.325,6.934,1.382,9.514,0.076
    		c8.673-4.391,8.568-5.363,1.314-12.191c-0.242-0.228-0.405-0.649-0.672-0.719c-1.348-0.346-3.094-1.255-3.987-0.736
    		c-1.213,0.703-2.014,2.439-2.525,3.896c-0.266,0.758,0.787,1.919,0.64,2.798c-0.097,0.577-1.46,1.524-1.932,1.362
    		c-3.026-1.039-4.184-5.848-2.437-8.563c0.827-1.286,1.122-2.929,1.572-4.436c0.371-1.237,0.208-2.946,0.995-3.688
    		c2.134-2.007,2.266-4.424,1.891-6.879c-0.207-1.354-1.264-2.578-1.938-3.861c-1.241,1.165-2.893,2.115-3.636,3.539
    		c-1.492,2.86-2.513,5.97-3.683,8.994C272.4,451.415,271.359,454.274,270.293,457.124z M624.868,505.899
    		c-1.015-6.126-1.987-12.259-3.094-18.368c-0.155-0.852-0.823-1.815-1.543-2.28c-0.398-0.258-1.615,0.201-2.089,0.695
    		c-3.52,3.675-3.852,9.533-1.086,14.185c0.631,1.062,1.045,2.439,1.033,3.667c-0.06,6.5,0.602,12.811,2.58,19.096
    		c0.81,2.573-0.312,5.747-0.515,8.651c-0.062,0.884-0.222,2.448,0.061,2.545c0.957,0.33,2.335,0.545,3.115,0.076
    		c2.531-1.523,4.749-7.195,3.415-9.685C623.589,518.59,624.1,512.309,624.868,505.899z M320.402,490.656c0,0.042,0,0.083,0,0.125
    		c2.991,0,5.983,0.038,8.974-0.014c1.931-0.034,4.475,0.377,4.376-2.589c-0.095-2.825-1.936-3.923-4.83-3.562
    		c-1.408,0.176-3.188,0.162-4.301-0.548c-5.629-3.589-11.309-2.739-17.142-0.803c-0.851,0.282-1.903,0.079-2.843-0.051
    		c-3.375-0.467-6.737-1.321-10.111-1.353c-1.396-0.014-3.776,1.485-4.011,2.641c-0.503,2.487,1.978,2.487,3.772,2.696
    		c5.001,0.584,10.331-0.471,14.715,3.203c0.613,0.515,1.93,0.235,2.921,0.244C314.749,490.672,317.575,490.656,320.402,490.656z
    		 M716.195,475.309c-0.072,0-0.145,0-0.217,0c0,1.829-0.108,3.666,0.037,5.483c0.097,1.198,0.557,2.367,0.854,3.55
    		c1.053-0.838,2.283-1.526,3.125-2.539c3.379-4.06,1.567-8.952,1.899-13.482c0.261-3.565-4.956-10.021-8.456-10.271
    		c-4.303-0.308-8.698-0.399-12.947,0.186c-1.754,0.241-3.457,2.319-4.659,3.948c-0.26,0.352,1.677,2.411,2.699,3.6
    		c0.966,1.122,1.948,2.29,3.134,3.145c0.939,0.678,2.408,1.494,3.258,1.188c0.936-0.338,1.833-1.812,2.05-2.926
    		c0.897-4.605,0.749-4.353,5.21-3.515c3.2,0.602,4.141,2.243,4.029,5.144C716.128,470.979,716.195,473.145,716.195,475.309z
    		 M321.17,558.82c2.655,0,5.316,0.104,7.963-0.045c1.25-0.07,2.663-0.339,3.675-1.011c4.092-2.719,7.124-6.537,8.672-11.093
    		c0.853-2.509,0.484-5.574,0.059-8.293c-0.142-0.905-2.11-1.842-3.374-2.084c-0.444-0.085-1.565,1.788-1.857,2.903
    		c-0.325,1.239-0.023,2.636-0.054,3.962c-0.14,6.188-3.847,9.877-9.936,9.894c-3.65,0.011-7.301-0.027-10.95,0.004
    		c-2.138,0.018-4.306,0.444-5.769-1.888c-0.29-0.463-2-0.667-2.435-0.285c-1.189,1.045-2.048,2.467-3.036,3.738
    		c0.749,0.545,1.499,1.088,2.247,1.634C310.884,559.552,316.093,558.809,321.17,558.82z M460.088,385.686
    		c0.768,1.267,1.551,3.055,2.817,4.381c0.44,0.46,2.778,0.104,3.037-0.447c0.767-1.63,0.932-3.543,1.312-5.35
    		c0.028-0.136-0.181-0.314-0.163-0.459c0.932-7.671-3.156-15.224-0.488-22.966c0.628-1.823-2.39-6.706-3.595-6.126
    		c-1.865,0.897-4.311,2.74-4.543,4.449c-0.549,4.034-0.586,8.384,0.375,12.312C459.944,375.996,461.947,380.247,460.088,385.686z
    		 M618.608,429.061c0-2.485,0.367-5.051-0.147-7.426c-0.278-1.286-1.905-2.631-3.239-3.168c-0.543-0.219-2.734,1.593-2.782,2.551
    		c-0.249,4.956-1.145,10.278,0.277,14.818c1.753,5.595,3.785,11.011,4.274,16.908c0.051,0.609,0.974,1.587,1.548,1.629
    		c1.561,0.113,4.199,0.288,4.516-0.415c0.709-1.576,0.883-3.939,0.191-5.505C620.504,442.239,618,436.052,618.608,429.061z
    		 M420.541,324.254c-3.128-0.375-4.204,1.383-4.181,4.441c0.041,5.223,0.036,10.449-0.142,15.668
    		c-0.065,1.936-0.279,4.049-1.124,5.73c-1.12,2.231-0.784,4.004,0.964,5.258c1.223,0.877,3.6,1.823,4.37,1.256
    		c1.11-0.817,1.723-2.99,1.688-4.566c-0.169-7.844,0.473-15.533,2.824-23.07C425.995,325.595,424.651,324.234,420.541,324.254z
    		 M490.386,424.055c0.092,0.001,0.185,0.001,0.276,0.002c0-4.475,0.046-8.95-0.021-13.424c-0.031-2.091-1.078-3.838-3.295-3.26
    		c-1.116,0.291-2.581,2.131-2.597,3.287c-0.108,7.902,0.122,15.81,0.252,23.714c0.054,3.313-0.1,6.655,0.33,9.921
    		c0.136,1.036,1.85,1.865,2.841,2.789c0.738-1.058,2.082-2.098,2.114-3.177C490.484,437.295,490.386,430.674,490.386,424.055z
    		 M495.87,452.204c1.29-0.158,1.993-0.12,2.605-0.34c3.641-1.306,6.889-3.799,11.181-3.261c0.466,0.059,1.962-2.942,1.818-4.41
    		c-0.206-2.12-2.091-1.933-3.93-1.455c-1.332,0.346-2.835,0.031-4.262,0.012c0.482-1.222,1.264-2.417,1.377-3.673
    		c0.179-1.974-0.093-3.988-0.174-5.985c-1.62,0.414-3.287,0.705-4.843,1.29c-0.897,0.337-1.599,1.176-2.419,1.745
    		c-3.809,2.646-4.162,3.676-1.957,7.698c0.972,1.774,1.72,3.177-0.25,4.769c-0.767,0.618-1.149,1.712-1.706,2.589
    		C494.335,451.592,495.359,452,495.87,452.204z M549.227,501.549c0.293,0.579,0.586,1.158,0.878,1.738
    		c1.861-0.901,3.956-1.519,5.506-2.797c1.123-0.928,1.411-2.804,2.272-4.127c1.76-2.702-1.012-3.432-2.146-4.701
    		c-0.312-0.351-2.619,0.212-2.853,0.809c-0.988,2.523-2.485,2.185-4.348,1.314c-2.068-0.968-3.454-0.4-4.229,1.836
    		c-0.392,1.132-1.104,2.154-1.671,3.226c-0.661-1.162-1.17-2.455-2.026-3.447c-0.778-0.902-1.954-2.058-2.92-2.026
    		c-0.859,0.028-2.094,1.399-2.404,2.41c-0.472,1.538-0.506,3.299-0.342,4.927c0.418,4.127,5.219,6.116,8.621,3.814
    		C545.321,503.337,547.332,502.528,549.227,501.549z M292.078,538.335c1.63-0.442,3.175-0.597,4.443-1.271
    		c1.787-0.95,1.858-4.168,0.146-4.373c-4.084-0.489-6.822-4.221-11.488-3.664c-4.324,0.517-9.249,1.549-13.063-2.595
    		c-0.425-0.462-3.696,0.806-4.759,2.038c-1.475,1.709,0.271,2.56,1.827,3.578c3.465,2.269,6.998,3.226,11.175,3.066
    		c2.513-0.095,5.087,1.151,7.615,1.858C289.384,537.367,290.756,537.892,292.078,538.335z M455.123,568.811
    		c-0.331,5.7,1.238,10.806-2.643,15.117c-0.792,0.88-0.548,2.9-0.392,4.353c0.107,1,0.971,2.767,1.352,2.729
    		c1.16-0.115,2.571-0.68,3.281-1.557c0.756-0.933,0.812-2.427,1.192-3.67c0.705-2.3,1.465-4.584,2.123-6.897
    		c0.355-1.246,0.735-2.532,0.779-3.812c0.166-4.812,0.248-9.627,0.256-14.441c0.004-1.938,0.284-4.445-2.569-4.52
    		c-2.436-0.063-3.335,1.866-3.368,4.096C455.088,563.361,455.123,566.516,455.123,568.811z M659.304,572.363
    		c0.159-0.021,0.318-0.042,0.478-0.062c0-3.662,0.204-7.342-0.124-10.975c-0.1-1.092-1.583-2.712-2.616-2.866
    		c-0.877-0.132-2.814,1.419-2.907,2.362c-0.437,4.416-0.834,8.921-0.416,13.315c0.502,5.284,1.807,10.495,2.859,15.72
    		c0.145,0.72,0.784,1.826,1.287,1.879c0.827,0.088,2.161-0.3,2.505-0.919c0.585-1.052,0.868-2.469,0.746-3.679
    		C660.621,582.202,659.925,577.286,659.304,572.363z M308.257,476.436c3.152-1.528,6.418-2.876,9.37-4.725
    		c0.653-0.41,0.796-3.141,0.141-3.854c-1.971-2.146-4.233-4.235-6.786-5.579c-3.305-1.739-6.974-0.328-9.032,2.078
    		c-1.334,1.56-1.291,5.107-0.599,7.379C302.177,474.446,304.681,476.198,308.257,476.436z M533.645,595.399
    		c0.094-0.206,0.188-0.412,0.28-0.618c1.163-0.058,2.646,0.321,3.431-0.253c2.706-1.983,5.696-3.957,7.453-6.665
    		c0.66-1.017-1.372-4.372-2.855-6.066c-3.293-3.761-4.441-3.609-8.059,0.044c-1.403,1.417-3.345,2.709-3.923,4.434
    		c-0.73,2.18-0.709,4.828-0.21,7.099C529.963,594.289,532.287,594.737,533.645,595.399z M678.662,446.784
    		c-0.026-0.179-0.052-0.358-0.078-0.538c-2.494,0-5.026-0.254-7.467,0.111c-1.155,0.173-2.135,1.527-3.193,2.348
    		c1.079,1.07,2.1,2.974,3.248,3.055c3.439,0.241,7.092,0.352,10.36-0.562c4.847-1.354,9.592-2.521,14.65-1.997
    		c1.644,0.17,3.328,0.361,4.948,0.168c0.97-0.116,1.853-0.96,2.774-1.477c-0.59-0.86-1.066-2.341-1.789-2.473
    		c-3.045-0.555-6.201-1.158-9.246-0.904C688.104,444.915,683.396,445.994,678.662,446.784z M417.123,550.539
    		c2.208-0.726,3.704-0.947,4.901-1.666c2.645-1.587,5.189-3.356,7.661-5.203c0.7-0.522,1.311-1.522,1.442-2.383
    		c0.45-2.931-2.772-5.968-6.352-6.172c-4.576-0.262-5.372,0.342-5.47,4.687c-0.049,2.126,0.021,4.143-2.819,4.244
    		c-2.734,0.098-3.674,1.911-2.901,3.964C414.041,549.216,416.063,549.83,417.123,550.539z M568.355,422.045
    		c0.108,0.315,0.217,0.631,0.326,0.946c3.161-1.088,6.298-2.259,9.496-3.223c1.228-0.37,2.6-0.252,3.901-0.387
    		c1.471-0.152,3.174,0.059,4.334-0.631c1.03-0.613,1.902-2.219,1.966-3.432c0.033-0.634-1.824-1.948-2.83-1.951
    		c-3.89-0.011-7.89-0.748-11.453,2.037c-1.233,0.963-3.425,0.795-5.195,0.944c-1.475,0.125-3.212-0.525-4.393,0.059
    		c-1.137,0.562-2.256,2.23-2.335,3.479c-0.052,0.816,1.636,2.092,2.784,2.526C565.906,422.773,567.209,422.203,568.355,422.045z
    		 M459.16,494.008c1.457-0.408,2.874-0.766,4.265-1.205c2.123-0.672,4.281-1.276,6.327-2.142c1.942-0.821,3.828-1.834,5.614-2.96
    		c1.909-1.203,2.107-2.989,0.736-4.693c-1.411-1.754-2.683-0.475-4.292,0.162c-4.617,1.827-9.43,3.16-14.175,4.654
    		c-0.274,0.086-0.67-0.247-0.961-0.179c-1.469,0.341-2.922,0.751-4.381,1.136c1.002,1.364,1.81,2.963,3.071,4.015
    		C456.286,493.562,457.806,493.609,459.16,494.008z M350.153,405.3c1.327,2.085,1.9,4.062,3.046,4.483
    		c1.139,0.418,2.975-0.506,4.291-1.23c4.481-2.466,5.289-7.665,8.105-11.4c1.407-1.867-0.124-4.435-2.549-4.739
    		c-3.722-0.468-6.579,0.285-6.101,4.775c0.231,2.177-0.258,3.678-2.852,4.396C352.662,401.982,351.634,403.836,350.153,405.3z
    		 M700.694,379.884c-0.097,0.34-0.192,0.681-0.289,1.021c-1.484-0.11-2.988-0.415-4.446-0.268c-1.055,0.106-2.396,0.553-2.971,1.323
    		c-0.577,0.774-0.872,2.622-0.383,3.139c0.872,0.919,2.404,1.584,3.702,1.663c5.541,0.339,11.245-0.993,16.491,2.181
    		c0.616,0.373,2.853-0.741,3.311-1.672c0.364-0.741-0.577-3.018-1.391-3.297C710.119,382.392,705.383,381.205,700.694,379.884z
    		 M327.324,538.188c0-0.497,0.142-1.05-0.026-1.482c-0.599-1.547-1.19-4.247-1.992-4.323c-1.621-0.153-3.617,0.814-5.017,1.894
    		c-1.668,1.285-3.32,2.977-4.147,4.857c-0.775,1.76-0.944,4.133-0.428,5.968c0.258,0.915,2.621,1.662,4.072,1.74
    		C324.908,547.115,327.316,544.213,327.324,538.188z M632.985,326.32c-0.094-3.15-5.478-5.514-7.943-4.409
    		c-4.274,1.916-8.697,3.499-13.037,5.272c-0.857,0.351-2.091,0.783-2.3,1.446c-0.214,0.681,0.432,1.852,1.023,2.524
    		c1.69,1.921,3.433,2.389,6.134,1.165c4.375-1.981,9.111-3.161,13.675-4.74C631.481,327.252,632.336,326.661,632.985,326.32z
    		 M703.91,481.558c0.733,0.413,1.656,0.635,1.765,1.063c0.175,0.688-0.001,1.625-0.378,2.246c-0.407,0.672-1.411,0.985-1.803,1.66
    		c-0.652,1.121-1.024,2.405-1.515,3.621c1.5-0.072,3.133,0.194,4.46-0.322c1.135-0.441,2.426-1.64,2.741-2.759
    		c0.494-1.757,1.025-4.438,0.115-5.466c-1.968-2.224-4.832-3.658-7.362-5.367c-0.132-0.09-0.566,0.269-0.86,0.418
    		c-1.608,0.817-3.337,1.466-4.797,2.495c-1.76,1.241-4.63,2.054-3.409,5.171c0.686,1.749,4.355,1.874,6.782,0.327
    		c0.672-0.429,1.065-1.281,1.721-1.753C702.13,482.346,703.037,482.004,703.91,481.558z M364.232,313.978
    		c-1.463-0.368-2.768-1.163-3.63-0.827c-2.432,0.948-4.739,2.265-6.986,3.618c-2.227,1.342-4.397,2.809-6.422,4.434
    		c-0.599,0.48-0.618,1.683-0.902,2.554c1.021,0.273,2.2,1.005,3.032,0.722c1.68-0.571,3.111-1.831,4.758-2.553
    		c1.583-0.694,3.002-0.629,2.88,1.771c-0.058,1.123,0.025,2.253,0.044,3.38c1.294-0.537,2.732-0.866,3.855-1.648
    		C364.755,322.715,361.705,318.099,364.232,313.978z M609.174,357.486c0.087-7.83-0.202-8.864-4.674-11.412
    		c-1.152-0.656-2.965-0.154-4.475-0.185c0.158,1.476-0.24,3.687,0.569,4.299c3.33,2.516,2.848,5.463,2.204,8.896
    		c-0.326,1.738,0.285,3.857,1.081,5.512c0.389,0.806,2.222,1.358,3.309,1.232c0.744-0.087,1.758-1.479,1.896-2.4
    		C609.378,361.487,609.174,359.472,609.174,357.486z M439.623,470.896c0.679,1.561,1.005,3.554,2.178,4.505
    		c0.633,0.514,3.031-0.392,4.07-1.3c1.95-1.706,2.748-0.355,4.052,0.743c2.313,1.951,4.765,1.521,6.877-0.776
    		c2.275-2.475,0.4-4.03-1.348-5.176c-1.473-0.964-3.354-1.48-5.124-1.768c-1.847-0.3-3.803,0.104-5.665-0.143
    		C441.048,466.503,440.144,467.143,439.623,470.896z M692.559,401.74c-0.001,0.049-0.001,0.099-0.001,0.147
    		c2.817,0,5.643,0.114,8.449-0.062c0.976-0.062,1.906-0.838,2.857-1.288c-0.656-0.798-1.164-1.827-1.992-2.355
    		c-5.683-3.631-12.159-2.414-18.353-2.629c-0.992-0.034-2.848,1.992-2.856,3.077c-0.008,1.028,1.778,2.791,2.963,2.967
    		C686.546,402.031,689.574,401.74,692.559,401.74z M364.384,525.84c0.107,0.374,0.215,0.747,0.321,1.121
    		c3.026-0.98,6.105-1.833,9.043-3.03c0.769-0.313,1.101-1.698,1.634-2.591c-0.854-0.398-1.71-1.143-2.562-1.139
    		c-6.258,0.029-12.659-0.837-18.515,2.301c-1.049,0.562-1.896,1.502-2.836,2.268c1.179,0.691,2.358,1.964,3.537,1.963
    		C358.132,526.729,361.258,526.175,364.384,525.84z M650.468,424.249c-4.223,0.789-7.764,1.362-11.26,2.141
    		c-1.825,0.406-3.571,1.167-5.35,1.779c-1.163,0.4-2.32,0.819-3.48,1.23c0.69,1.197,1.104,2.931,2.137,3.462
    		c1.472,0.759,3.574,1.229,5.096,0.777c4.525-1.346,8.922-3.146,13.312-4.909c0.874-0.352,1.495-1.331,2.233-2.021
    		C652.189,425.824,651.224,424.94,650.468,424.249z M392.339,313.224c7.808-0.123,13.006-3.878,18.384-7.104
    		c1.671-1.002,0.979-2.426-0.592-2.851c-1.379-0.373-3.197-0.676-4.344-0.083c-4.328,2.234-8.728,3.864-13.642,4.32
    		c-0.829,0.077-1.964,2.519-1.957,3.863C390.192,312.201,392.086,313.023,392.339,313.224z M330.86,470.033
    		c-3.36-0.826-6.29,0.108-8.393,3.3c-1.951,2.962-0.949,5.523,2.594,5.316c3.194-0.187,6.375-1.379,9.453-2.439
    		c1.346-0.464,3.563-2.002,3.423-2.541c-0.587-2.255-1.992-4.242-4.861-4.067C332.419,469.641,331.772,469.85,330.86,470.033z
    		 M443.708,427.287c-0.896-1.614-1.684-4.472-2.715-4.563c-1.718-0.151-3.844,1.179-5.311,2.448c-0.935,0.809-0.949,2.649-1.448,4
    		c-0.646,1.746-1.407,3.45-2.029,5.204c-0.297,0.84-0.82,2.1-0.462,2.548c0.47,0.589,1.75,0.87,2.608,0.752
    		C438.859,437.054,443.006,432.188,443.708,427.287z M532.152,396.881c0.003,0,0.007,0,0.011,0c0,1.491-0.298,3.066,0.088,4.45
    		c0.347,1.244,1.652,3.324,2.17,3.221c1.995-0.401,3.954-1.365,5.661-2.514c0.388-0.261,0.079-2.412-0.549-3.15
    		c-1.324-1.561-0.941-2.58,0.302-3.806c1.582-1.559,1.847-3.563-0.239-4.487c-1.812-0.803-4.2-0.94-6.135-0.499
    		c-0.726,0.165-0.96,2.778-1.279,4.305C532.015,395.194,532.152,396.052,532.152,396.881z M673.305,437.055c0,0.021,0,0.043,0,0.065
    		c-2.486,0-4.974,0.008-7.46-0.003c-2.159-0.009-2.865,1.292-2.919,3.194c-0.064,2.243,1.273,2.785,3.211,2.736
    		c3.811-0.096,7.629-0.021,11.431-0.244c1.426-0.083,2.956-0.569,4.171-1.312c0.81-0.495,1.692-1.875,1.535-2.657
    		c-0.147-0.731-1.58-1.61-2.517-1.7C678.293,436.897,675.791,437.055,673.305,437.055z M393.16,324.281
    		c-2.311,0-4.759-0.463-6.881,0.17c-1.308,0.391-2.116,2.453-3.148,3.765c1.145,0.697,2.214,1.782,3.446,2.018
    		c3.495,0.668,7.048,1.09,10.595,1.411c2.547,0.23,4.129-1.293,4.446-3.679c0.318-2.389-1.026-3.698-3.504-3.692
    		C396.463,324.278,394.812,324.274,393.16,324.281z M674.778,344.192c-1.485-0.934-2.27-1.719-3.194-1.96
    		c-6.172-1.607-10.982,3.675-16.938,3.557c-0.414-0.008-1.413,2.328-1.141,3.292c0.225,0.794,2.026,1.775,2.965,1.628
    		c4.56-0.716,9.118-1.589,13.558-2.832C671.581,347.441,672.793,345.786,674.778,344.192z M607.057,555.321
    		c-0.882-1.311-2.033-3.525-2.348-3.412c-2.498,0.897-5.561,1.697-6.997,3.614c-1.142,1.524-1.771,3.066-3.7,4.076
    		c-2.492,1.305-2.216,3.973,0.451,4.738c1.576,0.453,3.693,0.356,5.13-0.364c1.854-0.931,3.415-2.604,4.829-4.201
    		C605.468,558.592,606.076,557.024,607.057,555.321z M579.705,355.529c0.549-3.065-1.317-5.311-3.976-5.854
    		c-2.713-0.554-4.261,1.656-4.984,4.385c-0.406,1.534-1.847,2.807-2.194,4.34c-0.29,1.278-0.114,3.232,0.696,4.007
    		c0.628,0.6,2.632,0.056,3.883-0.383c1.174-0.411,2.124-1.876,3.239-1.957C579.359,359.849,579.975,358.204,579.705,355.529z
    		 M401.837,529.27c-0.256-0.39-0.838-2.155-1.994-2.696c-0.677-0.317-2.486,1.116-3.403,2.114c-2.226,2.421-4.345,4.964-6.26,7.634
    		c-0.755,1.052-0.855,2.574-1.253,3.882c1.423,0.309,2.89,0.998,4.262,0.846C395.922,540.745,401.856,533.229,401.837,529.27z
    		 M446.057,348.128c0.016,0,0.032,0,0.049,0c0-0.827,0.005-1.655-0.001-2.483c-0.03-4.303-2.04-5.971-5.913-4.049
    		c-2.107,1.046-3.582,3.588-5.038,5.668c-1.595,2.278-0.845,3.396,2.004,3.958c1.85,0.365,3.816,0.767,5.4,1.708
    		c2.493,1.482,3.305,0.608,3.486-1.821C446.118,350.12,446.057,349.122,446.057,348.128z M686.68,529.957
    		c-0.353-3.306-1.882-4.364-4.209-3.704c-1.718,0.486-3.223,1.681-4.906,2.349c-3.343,1.324-3.444,3.787-2.278,6.518
    		c0.707,1.656,1.608,3.448,2.941,4.551c1.036,0.855,2.876,0.738,4.359,1.054c-0.142-1.28,0.146-2.867-0.507-3.784
    		c-1.854-2.604-1.765-4.097,1.588-5.015C684.873,531.595,685.87,530.508,686.68,529.957z M579.867,331.037c-0.024,0-0.05,0-0.074,0
    		c0-2.154,0.252-4.352-0.108-6.443c-0.175-1.017-1.392-2.415-2.328-2.586c-0.973-0.177-3.102,0.872-3.133,1.469
    		c-0.233,4.43-0.284,8.902,0.151,13.308c0.085,0.864,2.529,1.496,3.885,2.234c0.536-1.17,1.314-2.295,1.544-3.523
    		C580.074,334.056,579.867,332.527,579.867,331.037z M679.441,421.594c3.85,0.199,5.328-0.978,4.938-2.923
    		c-0.301-1.506-9.007-5.413-10.388-4.652c-0.286,0.158-0.562,0.354-0.799,0.577c-2.053,1.929-1.929,7.937,0.273,9.624
    		c1.518,1.162,2.955,1.316,4.151-0.646C678.206,422.609,679.174,421.876,679.441,421.594z M725.981,422.75
    		c-0.865,2.587,3.151,6.474,5.504,5.672c0.842-0.287,1.967-2.473,1.757-2.706c-2.898-3.217-1.966-7.174-2.517-10.857
    		c-0.163-1.093-1.612-1.993-2.473-2.981c-1.005,0.949-2.315,1.74-2.917,2.899c-0.464,0.895-0.106,2.257,0.007,3.398
    		C725.494,419.706,725.763,421.226,725.981,422.75z M684.245,476.639c-1.125-1.458-2.045-3.893-3.492-4.263
    		c-2.979-0.762-3.009,2.022-3.422,4.165c-0.27,1.399-1.184,2.665-1.545,4.059c-0.438,1.695-1.032,3.531-0.774,5.183
    		c0.343,2.19,3.521,2.977,4.545,1.171C681.276,483.922,682.484,480.6,684.245,476.639z M588.561,505.452
    		c1.847,0,2.948,0.335,3.443-0.07c1.017-0.832,2.42-2.122,2.336-3.11c-0.089-1.049-1.55-2.426-2.708-2.848
    		c-2.364-0.861-4.972-1.74-7.393-1.544c-2.01,0.162-3.897,1.853-5.839,2.866c1.332,1.008,2.533,2.333,4.026,2.949
    		C584.685,504.627,587.146,505.066,588.561,505.452z M331.934,526.989c1.43-0.925,3.894-1.629,4.321-2.978
    		c0.954-3.009-1.468-3.733-4.05-4.114c-1.396-0.206-2.669-1.209-4.016-1.812c-1.783-0.799-5.625,0.766-5.862,2.818
    		c-0.12,1.041,1.286,2.695,2.42,3.347C326.679,525.358,328.939,525.895,331.934,526.989z M534.58,567.079
    		c-0.084,0.21-0.168,0.42-0.253,0.631c-0.498-0.028-0.996-0.063-1.494-0.084c-2.705-0.109-4.237,1.6-4.153,3.878
    		c0.029,0.798,2.636,1.706,4.183,2.192c2.148,0.675,4.392,1.236,6.625,1.383c1.298,0.085,2.654-0.738,3.984-1.152
    		c-0.408-1.271-0.439-3.186-1.297-3.688C539.829,568.867,537.136,568.091,534.58,567.079z M265.433,512.753
    		c-1.191-2.665-2.225-5.314-3.556-7.805c-0.585-1.095-1.777-1.865-2.694-2.783c-0.888,1.006-2.207,1.873-2.585,3.043
    		c-1.276,3.951,1.549,6.524,3.706,9.182c0.856,1.055,2.228,1.691,3.365,2.519C264.204,515.646,264.739,514.385,265.433,512.753z
    		 M414.103,478.365c-0.601,0.438-2.944,1.263-3.5,2.755c-0.863,2.319,1.218,3.229,3.426,3.762c1.325,0.32,2.4,1.596,3.649,2.339
    		c2.152,1.279,3.873,0.533,4.494-1.873C423.261,481.135,420.955,478.711,414.103,478.365z M464.163,529.973
    		c3.188-1.772,6.159-3.18,8.827-5.025c1.115-0.772,1.588-2.475,2.353-3.755c-1.64-0.179-3.366-0.766-4.9-0.443
    		c-2.491,0.523-5.007,1.376-7.221,2.611c-1.311,0.731-2.062,2.464-3.064,3.746C461.375,527.979,462.593,528.85,464.163,529.973z
    		 M712.854,529.662c0.737,0.781,1.38,2.061,2.201,2.188c1.104,0.171,3.072-0.218,3.4-0.941c1.393-3.072,2.488-6.313,3.344-9.584
    		c0.454-1.734,0.377-3.827-2.194-4.149c-2.672-0.335-3.198,1.323-3.509,3.608c-0.271,2.003-1.228,3.917-1.928,5.854
    		C713.843,527.539,713.407,528.4,712.854,529.662z M296.118,552.97c4.015,0.967,6.05-2.697,8.696-5.016
    		c0.723-0.633,0.625-2.203,0.904-3.344c-1.203,0.073-2.458-0.047-3.598,0.26c-2.141,0.575-4.176,1.797-6.328,2.009
    		c-2.231,0.219-4.013,0.401-4.172,3.054C291.442,552.898,293.583,552.919,296.118,552.97z M544.986,482.509
    		c-0.116-0.013-0.232-0.026-0.348-0.04c0.04-0.822,0.303-1.714,0.073-2.452c-0.434-1.397-1.149-2.708-1.748-4.054
    		c-1.415,0.929-3.313,1.566-4.104,2.874c-0.713,1.179-0.54,3.174-0.162,4.657c0.466,1.828,1.508,3.521,2.389,5.226
    		c0.271,0.524,0.915,1.32,1.24,1.255c0.683-0.138,1.699-0.669,1.815-1.205C544.588,486.719,544.73,484.602,544.986,482.509z
    		 M530.484,366.744c1.518-1.432,3.036-2.863,4.555-4.295c-1.883-1.835-3.496-4.195-5.732-5.341
    		c-1.785-0.914-4.178,2.021-3.819,4.571C525.819,364.045,526.619,366.313,530.484,366.744z M550.204,398.492
    		c3.09-0.112,5.688-3.356,5.632-5.78c-0.047-2.021-0.985-3.104-3.078-2.944c-2.368,0.181-6.097,3.272-5.474,4.823
    		C547.941,396.227,549.444,397.523,550.204,398.492z M577.144,432.816c-2.97-2.508-4.901-4.338-7.104-5.742
    		c-0.272-0.174-2.566,1.756-2.912,2.994c-0.642,2.293,1.823,5.666,3.799,5.381C572.673,435.196,574.293,434.073,577.144,432.816z
    		 M604.363,399.592c-2.081-1.618-3.632-3.674-4.695-3.447c-1.527,0.326-3.699,2.274-3.753,3.604
    		c-0.058,1.42,2.027,2.928,3.177,4.396C600.629,402.818,602.166,401.49,604.363,399.592z M603.349,318.668
    		c-0.904-0.438-1.95-1.388-2.952-1.343c-2.651,0.118-5.25,6.531-3.468,8.587c0.509,0.587,2.643,0.787,2.817,0.477
    		C601.02,324.142,601.981,321.719,603.349,318.668z M591.703,467.736c-1.762,2.416-3.319,3.671-3.47,5.077
    		c-0.096,0.895,1.853,2.76,2.963,2.823c1.132,0.065,3.311-1.524,3.328-2.422C594.551,471.799,593.154,470.354,591.703,467.736z
    		 M541.646,426.944c-0.479,0.034-0.96,0.068-1.439,0.104c-0.679,1.271-1.708,2.478-1.956,3.827
    		c-0.387,2.109,0.752,3.799,3.012,3.445c1.321-0.206,3.617-2.028,3.489-2.357C544.057,430.159,542.746,428.593,541.646,426.944z
    		 M593.303,398.671c-2.067-1.491-3.324-3.055-4.688-3.154c-0.942-0.069-2.693,1.762-2.915,2.959
    		c-0.412,2.23,1.084,3.983,3.438,3.608C590.368,401.888,591.381,400.315,593.303,398.671z M452.738,396.157
    		c-0.654,0.318-1.308,0.637-1.962,0.955c-0.52,0.993-1.57,2.08-1.416,2.953c0.171,0.96,1.676,2.548,2.201,2.404
    		c1.178-0.32,2.866-1.477,2.952-2.42C454.627,398.816,453.393,397.46,452.738,396.157z"/>
      <Link to={"/archive/001"} className={activeIndex === 1 ? "active" : "inactive"} onClick={() => handleOnClick(1) } >
    	<path className="clickable" fillRule="evenodd" clipRule="evenodd" fill="black" d="M655.724,686.715c5.156,0,10.404-0.603,15.444,0.161
    		c5.585,0.846,10.98,2.938,16.457,4.493c0.795,0.225,1.586,0.7,2.371,0.685c5.91-0.12,10.576,3.364,15.776,5.304
    		c6.237,2.325,12.69,4.152,18.741,6.881c6.293,2.838,13.269,3.368,19.436,6.163c7.4,3.354,15.14,5.775,22.549,9.032
    		c4.542,1.996,8.829,4.563,13.305,6.72c3.65,1.759,7.416,3.281,11.121,4.928c0.6,0.267,1.312,0.501,1.706,0.974
    		c5.049,6.066,12.67,7.566,19.239,10.93c1.741,0.891,3.315,2.178,5.127,2.847c8.949,3.308,17.519,7.332,25.103,13.185
    		c0.999,0.771,2.024,2.509,1.861,3.628c-1.635,11.219-6.954,20.396-16.351,26.822c-2.007,1.372-5.029,1.694-7.591,1.717
    		c-3.524,0.03-5.48,1.968-5.955,4.8c-1.851,11.046-9.652,17.47-17.84,23.551c-3.087,2.293-6.661,3.945-10.085,5.756
    		c-0.807,0.427-1.911,0.484-2.858,0.419c-5.545-0.384-11.742,3.421-13.642,8.463c-1.666,4.421-8.641,10.683-12.817,12.824
    		c-3.103,1.59-5.848,3.566-9.539,2.883c-1.455-0.27-3.145-0.19-4.309-0.93c-2.986-1.896-5.497-1.022-7.716,1
    		c-2.563,2.337-5.42,4.637-7.154,7.545c-4.736,7.94-11.247,13.35-20.022,16.147c-1.09,0.348-2.016,1.187-3.093,1.602
    		c-3.223,1.243-6.355,1.411-9.498-0.529c-1.323-0.817-3.031-1.009-4.558-1.506c-1.54-0.501-3.256-0.758-4.551-1.631
    		c-1.059-0.713-1.61-2.177-2.387-3.307c1.17-0.398,2.36-0.747,3.506-1.205c3.743-1.498,7.464-3.053,11.207-4.551
    		c1.971-0.788,3.923-1.946,5.967-2.168c5.289-0.573,9.205-5.04,14.794-4.867c1.273,0.039,2.766-1.638,3.875-2.795
    		c1.93-2.012,3.903-3.329,6.933-3.11c3.167,0.229,5.688-1.045,6.903-4.384c0.302-0.827,1.569-1.404,2.503-1.882
    		c3.317-1.701,6.758-3.173,10.008-4.989c3.516-1.965,6.867-4.223,10.295-6.346c2.034-1.259,4.084-2.493,6.115-3.758
    		c3.007-1.873,6.077-3.655,8.988-5.668c5.963-4.121,11.979-8.187,17.707-12.62c5.634-4.36,11.156-8.916,16.335-13.803
    		c7.843-7.4,15.751-14.813,22.779-22.957c2.52-2.918,2.923-7.909,3.616-12.067c0.431-2.582-2.416-3.869-4.938-2.634
    		c-4.013,1.966-5.889,5.196-5.103,10.264c0.394,2.54-0.31,3.958-2.048,5.592c-8.214,7.722-15.927,16.027-24.565,23.229
    		c-6.094,5.08-12.308,9.98-18.24,15.28c-2.374,2.12-5.879,3.221-8.294,5.784c-1.285,1.364-2.855,0.473-2.682-1.157
    		c0.346-3.254-1.626-5.572-2.428-8.316c-1.167-3.993-1.834-8.604,2.565-11.78c1.34-0.967,2.471-2.229,3.661-3.395
    		c3.896-3.809,3.231-7.764-1.641-9.984c-0.599-0.272-1.146-0.658-1.706-1.012c-4.045-2.557-5.683-2.17-8.825,1.464
    		c-1.062,1.228-2.788,1.879-4.2,2.81c-0.661,0.435-1.512,0.767-1.91,1.383c-2.362,3.644-0.399,11.003,3.42,13.344
    		c0.666,0.409,1.474,1,1.683,1.672c1.213,3.893,2.271,7.834,3.366,11.763c0.178,0.639,0.245,1.308,0.419,1.947
    		c0.441,1.626,1.817,3.949,1.205,4.731c-1.786,2.284-4.17,4.269-6.678,5.776c-2.758,1.657-6.034,2.435-8.874,3.983
    		c-6.604,3.603-12.961,7.67-19.642,11.115c-6.87,3.543-14.028,6.523-21.018,9.842c-5.548,2.634-11.371,4.286-17.314,5.781
    		c-3.731,0.938-7.157,3.08-10.733,4.649c-1.029,0.452-2.31,1.379-3.131,1.095c-9.574-3.311-20.018-5.271-25.181-15.733
    		c-1.517-3.075-3.242-6.092-5.257-8.854c-0.767-1.05-2.617-1.307-3.974-1.926c-0.345,1.624-1.527,3.749-0.903,4.791
    		c5.026,8.386,10.097,16.893,18.923,21.937c2.607,1.49,5.789,1.977,8.71,2.914c1.509,0.484,3.278,0.607,4.486,1.496
    		c3.108,2.285,3.039,3.001,0.185,5.72c-5.263,5.013-11.093,8.539-18.818,7.565c-2.295-0.289-4.686,0.188-6.984-0.089
    		c-1.516-0.183-3.343-0.696-4.341-1.729c-3.681-3.808-7.334-3.275-10.435,1.319c-3.519,5.214-8.4,7.479-13.729,9.604
    		c-0.896,0.356-1.669,1.026-2.487,1.569c-2.923,1.935-5.543,1.692-8.708-0.054c-5.389-2.973-10.499-7.943-17.56-4.668
    		c-2.933,1.36-5.694,3.086-8.562,4.593c-5.492,2.889-17.391,1.736-22.054-2.103c-4.187-3.446-5.019-3.374-9.277-0.007
    		c-2.765,2.187-5.857,3.19-9.538,3.678c-5.911,0.782-11.067-0.046-15.892-3.399c-1.194-0.83-2.529-1.542-3.507-2.583
    		c-3.46-3.684-6.324-3.346-8.25,1.109c-1.928,4.461-1.839,4.362-5.503,1.044c-1.24-1.122-2.984-1.974-4.628-2.306
    		c-3.118-0.63-5.317,0.555-5.173,4.151c0.133,3.312-1.806,4.134-4.609,4.62c-9.415,1.632-16.846-1.953-22.839-8.789
    		c-2.299-2.622-3.438-3.083-6.743-0.938c-4.549,2.951-9.97,4.682-15.182,6.448c-4.614,1.564-10.744-2.764-12.758-8.475
    		c-2.018-5.722-4.683-6.443-10.163-4.617c-6.883,2.293-13.771,2.928-20.959-0.711c-4.819-2.439-8.259-5.953-11.906-9.498
    		c-3.511-3.412-4.49-3.383-7.281,0.509c-3.896,5.432-12.975,7.037-18.86,3.463c-1.063-0.646-2.459-0.918-3.729-1.025
    		c-4.931-0.415-8.349-4.356-7.362-8.961c0.218-1.02,1.428-2.494,2.3-2.582c2.791-0.28,5.755-0.487,8.435,0.166
    		c3.793,0.926,7.387,2.662,11.08,4.014c0.921,0.337,1.961,0.887,2.833,0.726c4.716-0.869,9.448-2.048,12.774-5.746
    		c0.968-1.076,1.464-3.9,0.748-4.875c-1.232-1.677-3.479-3.277-5.49-3.563c-3.405-0.484-6.958,0.142-10.45,0.168
    		c-1.242,0.01-2.486-0.369-3.73-0.57c0.369-1.125,0.504-2.413,1.149-3.347c2.224-3.211,4.657-6.277,6.948-9.443
    		c1.14-1.575,2.745-3.104,0.645-5.2c-1.903-1.899-3.238-0.472-4.783,0.505c-4.093,2.588-6.724,5.933-7.557,10.967
    		c-0.528,3.195-2.356,6.184-3.677,9.231c-0.166,0.383-0.764,0.62-1.205,0.836c-5.756,2.808-11.383,5.939-17.314,8.311
    		c-7.741,3.095-15.983,4.752-23.564,0.103c-3.794-2.326-8.169-5.145-7.758-11.144c0.117-1.708-1.359-4.656-2.725-5.091
    		c-2.608-0.832-6.6-1.441-8.345-0.049c-4.293,3.429-7.96,1.992-11.987,0.389c-1.204-0.479-2.391-1.378-3.6-1.405
    		c-4.906-0.108-7.84-3.399-10.702-6.58c-0.934-1.038-1.017-3.049-1.073-4.63c-0.163-4.587-2.095-6.704-6.635-6.775
    		c-2.664-0.041-5.353,0.291-7.987,0.026c-2.063-0.207-4.076-1.026-6.081-1.669c-0.922-0.295-1.761-1.161-2.654-1.186
    		c-7.37-0.205-10.481-5.027-12.881-11.098c-0.686-1.735-2.732-3.909-4.376-4.115c-6.057-0.759-8.795-5.485-12.347-9.269
    		c-0.344-0.366,0.083-1.456,0.152-2.21c0.736,0.175,1.629,0.14,2.182,0.557c4.032,3.047,7.815,6.462,12.04,9.2
    		c3.083,1.998,6.704,3.181,10.114,4.661c4.192,1.819,8.389,3.635,12.63,5.337c5.951,2.391,12.05,4.44,17.89,7.07
    		c5.848,2.634,11.476,5.763,17.16,8.749c5.122,2.691,10.187,5.494,15.286,8.229c2.387,1.28,4.705,2.755,7.215,3.729
    		c8.071,3.13,16.396,5.679,24.244,9.278c2.988,1.371,5.674,2.474,8.79,1.968c1.242-0.201,2.703-1.16,3.384-2.214
    		c0.92-1.424,0.638-3.205-1.672-2.848c-5.973,0.925-10.095-2.895-14.734-5.424c-0.577-0.315-1.238-1.024-1.703-0.91
    		c-3.659,0.895-6.132-1.519-9.052-2.866c-1.691-0.78-3.79-0.901-3.558-3.325c0.246-2.572,0.722-5.163,1.545-7.594
    		c0.121-0.36,3.237-0.444,4.03,0.318c1.484,1.428,2.423,3.503,3.324,5.433c0.866,1.855,2.041,2.659,3.917,1.735
    		c2.023-0.997,2.303-2.578,1.283-4.581c-1.996-3.925-1.953-3.941,2.342-3.959c0.828-0.004,1.97,0.262,2.417-0.168
    		c0.829-0.798,1.666-2.003,1.703-3.065c0.025-0.742-1.406-2.272-1.963-2.175c-2.942,0.518-6.228-1.849-8.931,1.333
    		c-1.287,1.517-3.249,1.141-3.754-1.098c-0.492-2.185-1.283-4.315-3.716-3.536c-1.816,0.581-3.666,2.28-4.647,3.977
    		c-1.597,2.758-2.295,6.026-3.798,8.854c-0.515,0.968-2.49,1.973-3.479,1.718c-2.671-0.689-5.212-1.958-7.73-3.154
    		c-8.062-3.832-16.1-7.717-24.118-11.642c-0.67-0.328-1.121-1.105-1.673-1.675c0.779-0.45,1.556-1.278,2.337-1.281
    		c2.725-0.013,5.537,0.147,7.046-2.618c0.729-1.335,1.263-3.209,0.884-4.556c-0.237-0.84-2.335-1.577-3.618-1.614
    		c-3.042-0.088-6.31-1.053-8.833,1.956c-1.198,1.429-2.223,2.381-0.614,4.16c0.497,0.55,0.367,1.665,0.524,2.522
    		c-0.964,0.021-2.106,0.391-2.862,0.005c-2.87-1.468-5.61-3.188-8.412-4.794c-0.56-0.32-1.151-0.623-1.765-0.807
    		c-4.207-1.259-4.108-1.254-1.013-4.617c2.777-3.017,5.305-6.275,7.781-9.55c0.755-1,1.185-2.378,1.36-3.638
    		c0.116-0.834-0.248-2.213-0.837-2.531c-0.596-0.322-2.029,0.083-2.583,0.66c-1.835,1.916-3.392,4.095-5.146,6.092
    		c-1.371,1.562-2.864,3.019-4.334,4.49c-0.342,0.342-0.997,0.466-1.187,0.85c-0.755,1.522-1.383,3.107-2.058,4.669
    		c-2.537-1.336-5.083-2.656-7.606-4.017c-0.713-0.386-1.445-1.365-2.028-1.264c-5.015,0.866-7.857-2.922-11.338-5.204
    		c-7.973-5.227-15.901-10.529-23.702-16.009c-2.558-1.797-4.583-4.331-6.996-6.357c-4.277-3.591-9.149-6.613-12.849-10.707
    		c-2.864-3.17-5.752-6.948-5.52-11.902c0.05-1.069-0.224-2.547-0.934-3.192c-3.364-3.062-1.324-5.3,0.941-7.475
    		c2.243-2.153,4.104-4.38,3.409-7.797c-0.451-2.219-1.18-3.472-3.633-1.758c-3.701,2.584-10.348,1.775-13.464-1.49
    		c-1.793-1.88-1.416-4.22,0.896-5.299c0.433-0.203,1.123-0.005,1.441-0.282c1.75-1.525,3.804-2.911,4.956-4.825
    		c0.551-0.916-5.112-2.286-7.099-0.394c-2.966,2.824-7.216,4.834-7.435,10.02c-0.087,2.062-0.206,3.768,1.737,4.608
    		c3.459,1.497,6.96,3.094,10.608,3.895c2.699,0.592,3.814,1.358,2.267,3.623c-4.051,5.926-1.908,10.266,1.607,16.008
    		c6.409,10.469,16.032,17.553,24.084,26.251c1.239,1.339,3.327,1.86,4.913,2.923c0.826,0.554,1.414,1.462,2.109,2.211
    		c-0.881,0.589-1.743,1.619-2.649,1.66c-1.255,0.057-2.713-0.362-3.792-1.041c-9.181-5.773-18.116-12.211-24.34-21.017
    		c-4.796-6.785-8.993-14.64-7.608-23.788c0.543-3.586-0.555-5.018-4.47-5.044c-6.962-0.047-10.396-4.119-10.229-11.229
    		c0.008-0.334,0.011-0.667,0.021-1c0.244-8.161,0.244-8.162,8.431-8.832c0.166-0.014,0.398,0.062,0.49-0.022
    		c3.694-3.392,8.877-2.212,13.041-4.245c1.74-0.85,3.795-1.614,5.649-1.496c1.477,0.094,3.095,1.359,4.238,2.513
    		c1.603,1.619,2.604,3.846,4.247,5.41c5.308,5.056,9.189,5.313,15.82,1.729c1.209-0.654,2.941-0.939,4.271-0.651
    		c3.391,0.735,6.71,1.833,10.013,2.924c0.699,0.23,1.437,0.939,1.763,1.617c3.997,8.307,10.862,13.954,17.889,19.459
    		c0.39,0.306,1.08,0.414,1.25,0.788c3.328,7.304,11.211,9.452,16.557,14.509c5.253,4.97,11.483,8.974,17.559,12.984
    		c4.013,2.649,4.443,3.162,1.77,7.244c-1.995,3.044-1.646,9.513,0.804,12.03c4.585,4.712,9.224,9.373,13.916,13.978
    		c1.556,1.526,3.235,3.139,5.417,0.585c2.088-2.444,1.785-5.065-1.307-5.769c-7.18-1.632-8.619-7.769-11.153-13.148
    		c-0.621-1.319-1.633-2.487-2.063-3.852c-0.346-1.097-0.507-3.153-0.007-3.428c1.044-0.573,2.982-0.813,3.846-0.198
    		c4.741,3.379,9.065,7.369,13.925,10.549c5.106,3.341,10.854,5.704,15.957,9.048c5.333,3.493,12.229,3.925,16.798,8.943
    		c0.587,0.646,2.882-0.263,4.386-0.451c-0.24-1.52,0.031-3.471-0.817-4.476c-2.447-2.896-5.425-5.339-8.021-8.121
    		c-0.748-0.802-0.911-2.149-1.343-3.247c0.964,0.471,2.017,0.819,2.867,1.443c1.061,0.778,1.816,2.221,2.955,2.578
    		c1.354,0.425,2.973,0.001,4.477-0.055c-0.359-1.454-0.396-3.121-1.176-4.296c-0.662-0.998-2.454-1.208-3.236-2.178
    		c-0.687-0.853-0.666-2.274-0.957-3.445c1.095,0.196,2.221,0.296,3.274,0.627c0.586,0.185,1.038,1.062,1.574,1.085
    		c1.08,0.048,2.533,0.084,3.166-0.545c0.648-0.645,1.034-2.714,0.608-3.078c-1.924-1.647-4.095-3.073-6.363-4.206
    		c-0.612-0.306-1.88,0.693-2.844,1.101c-3.207,1.354-6.32,3.086-9.659,3.896c-1.517,0.369-3.696-0.77-5.18-1.796
    		c-1.769-1.224-3.38-1.824-4.734-0.035c-1.168,1.544-1.331,3.483,0.749,4.73c1.763,1.057,3.633,1.938,5.358,3.048
    		c0.715,0.46,1.178,1.312,1.756,1.985c-0.867,0.605-1.687,1.582-2.616,1.702c-0.796,0.104-1.978-0.563-2.541-1.255
    		c-2.678-3.291-6.178-5.109-10.027-6.738c-2.816-1.192-5.117-3.56-7.744-5.254c-2.185-1.409-4.596-2.473-6.731-3.944
    		c-0.982-0.678-1.545-1.965-2.299-2.976c1.013-0.404,2.133-0.648,3.016-1.246c1.704-1.152,3.293-2.48,4.861-3.816
    		c0.116-0.099-0.346-1.229-0.479-1.217c-1.892,0.17-4.489-0.243-5.532,0.83c-4.741,4.881-7.661,2.416-12.417-0.737
    		c-5.072-3.362-9.212-7.448-13.696-11.314c-6.514-5.618-13.145-11.103-19.772-16.588c-1.011-0.837-2.317-1.308-3.406-2.063
    		c-1.606-1.114-3.473-2.07-4.641-3.557c-3.412-4.339-6.843-8.726-9.624-13.471c-1.201-2.051-1.707-5.332-0.958-7.515
    		c1.194-3.482,3.651-6.545,5.678-9.72c0.795-1.245,2.46-2.196,2.714-3.481c1.054-5.339,4.605-6.642,9.398-7.035
    		c2.698-0.222,5.295-1.576,7.957-2.357c1.422-0.417,2.894-0.661,4.325-1.052c2.128-0.58,4.25-1.189,6.354-1.85
    		c0.779-0.245,1.539-1.028,2.244-0.955c8.748,0.92,16.001-4.305,24.089-6.122c3.975-0.894,7.709-3.184,12.104-2.668
    		c4.256-5.248,11.785-0.882,16.372-5.56c0.801-0.816,2.878-0.387,4.366-0.521c1.146-0.104,2.682,0.25,3.375-0.359
    		c3.099-2.723,6.742-2.2,10.368-2.473c1.634-0.123,3.109-1.722,4.773-2.333c0.995-0.366,2.216-0.117,3.335-0.145
    		c-0.204,0.994-0.265,2.047-0.646,2.968c-0.562,1.359-1.085,2.908-2.102,3.872c-2.233,2.116-2.103,3.902,0.037,5.865
    		c0.349,0.319,0.8,0.928,0.708,1.268c-1.875,6.881,4.064,9.994,7.072,14.417c3.159,4.645,6.383,9.45,10.495,13.173
    		c3.046,2.757,6.961,5.203,11.661,5.246c1.395,0.013,2.785,0.803,4.173,1.253c1.686,0.546,3.336,1.24,5.055,1.642
    		c5.505,1.284,10.849,3.034,16.54,3.865c3.511,0.513,7.023,2.434,10.923,2.164c0.805-0.055,1.863-0.037,2.415,0.419
    		c4.489,3.714,9.814,2.143,14.84,2.611c2.719,0.253,5.368,1.232,8.058,1.845c1.751,0.398,3.515,1.008,5.279,1.032
    		c7.831,0.108,15.67-0.127,23.497,0.086c6.915,0.187,13.8,0.247,20.589,2.546c3.043,1.03,6.879-0.141,10.335-0.503
    		c5.35-0.562,10.673-1.736,16.025-1.855c13.825-0.308,27.662-0.118,41.492-0.292c2.025-0.025,4.045-0.938,6.06-1.467
    		c1.428-0.376,2.829-1.08,4.266-1.15c4.991-0.243,9.992-0.338,14.989-0.331c1.014,0.001,2.026,0.69,3.039,1.062
    		c-0.42,0.805-0.963,1.57-1.227,2.423c-0.287,0.926-0.314,1.932-0.454,2.902c0.893-0.425,1.964-0.662,2.643-1.311
    		c1.646-1.571,2.965-3.512,4.722-4.924c0.628-0.505,2.135,0.082,3.24,0.171c-0.203,1.012-0.229,2.108-0.666,3.006
    		c-0.337,0.693-1.141,1.203-1.818,1.679c-4.646,3.26-6.304,7.566-5.787,13.293c0.572,6.345,3.985,9.324,10.302,8.907
    		c4.809-0.317,8.613-2.438,12.203-5.113c1.004-0.747,1.91-3.156,1.438-4.052c-1.165-2.208-2.985-0.972-4.623,0.053
    		c-2.062,1.291-4.292,2.312-6.378,3.568c-2.646,1.594-5.016,0.472-5.84-1.724c-0.83-2.207-1.241-6.352-0.059-7.215
    		c4.235-3.092,4.743-8.488,8.445-11.763c3.729-3.299,7.076-7.025,10.731-10.413c1.675-1.552,3.652-2.789,5.55-4.084
    		c5.375-3.666,10.02-7.927,13.413-13.647c2.852-4.808,6.512-9.127,9.644-13.777c2.003-2.974,4.491-4.169,8.043-3.466
    		c1.444,0.285,2.991,0.047,4.491,0.047C655.725,686.535,655.725,686.625,655.724,686.715z M496.509,756.385
    		c0.056,0,0.11,0.001,0.166,0.001c0-2.166-0.019-4.332,0.005-6.498c0.023-2.178-0.382-4.248-3.011-4.309
    		c-2.668-0.062-3.09,2.02-3.1,4.186c-0.014,3.166,0.197,6.354-0.116,9.49c-0.289,2.896,3.523,5.63,0.31,8.615
    		c-0.027,0.025,0.849,0.886,1.152,1.439c0.433,0.788,0.725,1.653,1.077,2.486c-1.033,0.323-2.064,0.917-3.101,0.928
    		c-10.303,0.108-20.607,0.155-30.911,0.13c-1.804-0.004-3.964,0.135-5.343-0.752c-4.049-2.604-8.408-2.634-12.927-2.791
    		c-2.6-0.09-5.161-1.146-7.749-1.729c-1.773-0.4-3.556-0.998-5.346-1.047c-4.771-0.129-9.669,0.762-13.874-2.567
    		c-0.56-0.443-1.618-0.215-2.426-0.388c-4.469-0.953-8.943-1.881-13.38-2.965c-0.612-0.15-1.024-1.121-1.529-1.712
    		c0.633-0.391,1.353-0.69,1.882-1.191c1.114-1.056,2.966-2.167,3.038-3.345c0.282-4.663,2.667-8.226,5.016-11.953
    		c1.115-1.771,2.034-3.993-0.886-5.425c-1.659-0.812-3.138-2.011-4.824-2.744c-0.943-0.411-2.519-0.715-3.164-0.223
    		c-2.315,1.765-4.882,3.373-5.078,6.937c-0.1,1.815-1.154,3.599-1.888,5.351c-0.576,1.377-1.532,2.626-1.918,4.041
    		c-0.263,0.961-0.202,2.542,0.395,3.057c0.589,0.507,2.21,0.352,3.062-0.104c1.288-0.688,2.576-2.389,3.532-0.139
    		c0.821,1.934,0.495,4.219-2.231,4.535c-2.101,0.244-4.331,0.174-6.396-0.265c-2.85-0.604-5.619-1.628-8.374-2.609
    		c-0.66-0.234-1.129-1.007-1.686-1.532c0.537-0.541,1.054-1.527,1.612-1.553c5.282-0.233,5.789-4.599,6.452-8.111
    		c0.358-1.898-1.135-4.29-2.212-6.226c-0.314-0.564-2.587-0.853-3.091-0.365c-3.219,3.106-7.767,5.273-7.778,10.802
    		c-0.01,4.17-1.959,5.148-5.82,3.269c-1.769-0.861-3.64-1.519-5.375-2.436c-2.011-1.062-4.1-2.12-5.737-3.631
    		c-0.341-0.314,1.148-2.606,1.785-3.992c0.067-0.146,0.051-0.33,0.107-0.483c2.748-7.448,7.529-10.248,15.448-9.17
    		c1.098,0.149,2.375,0.12,3.365-0.303c2.153-0.92,2.938-2.756,2-4.929c-1.443-3.344-4.489-4.186-8.001-2.718
    		c-1.807,0.755-3.864,0.885-5.733,1.517c-1.485,0.503-3.012,1.134-4.251,2.065c-4.469,3.361-6.63,8.289-8.526,13.322
    		c-1.099,2.914-2.648,3.421-5.254,1.723c-2.675-1.744-5.341-3.599-8.243-4.878c-8.556-3.771-10.852-7.04-10.663-15.995
    		c0.014-0.648-0.077-1.326-0.259-1.949c-2.492-8.535-0.926-13.17,6.154-18.905c1.795-1.454,3.323-3.266,4.836-5.03
    		c2.948-3.439,1.589-7.947-2.789-8.734c-2.586-0.466-5.774-1.12-7.853-0.054c-3.232,1.658-6.388,4.287-8.385,7.295
    		c-1.852,2.79,1.852,4.426,3.348,6.473c0.683,0.935,1.618,2.812,1.271,3.188c-2.323,2.529-1.663,5.594-2.16,8.492
    		c-0.453,2.64-2.751,5.299-2.378,7.656c0.421,2.666,3.234,4.843,2.746,8.156c-0.134,0.906,0.104,2.266,0.713,2.82
    		c4.6,4.184,9.016,8.687,14.124,12.154c4.271,2.898,9.458,4.415,14.128,6.766c2.99,1.506,5.605,4.413,8.688,4.942
    		c7.104,1.219,12.759,6.776,20.306,6.354c0.713-0.04,1.438,0.778,2.21,1.046c1.847,0.641,3.716,1.222,5.594,1.764
    		c1.402,0.405,2.879,0.587,4.241,1.088c4.348,1.599,8.555,4.248,13.007,4.728c5.769,0.621,11.583,0.254,17.006,2.942
    		c0.93,0.461,2.31-0.091,3.449,0.062c8.953,1.198,17.902,2.429,26.841,3.732c0.942,0.138,1.78,0.887,2.713,1.225
    		c1.231,0.446,2.504,1.068,3.768,1.091c5.824,0.106,11.654,0.146,17.476-0.037c2.407-0.075,4.55-0.274,6.352,1.853
    		c0.543,0.641,2.602,1.006,3.035,0.57c3.607-3.624,8.103-2.624,12.295-2.408c4.792,0.247,8.322-1.892,11.551-4.907
    		c2.745-2.563,3.342-6.914,1.39-8.936c-1.806-1.868-5.274-1.474-8.373,0.952c-2.906,2.274-4.945,1.523-5.451-2.257
    		C496.26,761.056,496.509,758.703,496.509,756.385z M402.124,849.918c-0.383,0.033-0.765,0.065-1.147,0.099
    		c1.324,5.229,2.557,10.484,4.062,15.661c0.247,0.851,1.593,1.604,2.59,1.976c3.347,1.249,6.958,1.925,10.115,3.517
    		c6.047,3.05,12.493,4.524,19.07,5.299c3.624,0.427,7.253,0.123,10.434,2.456c0.692,0.508,1.931,0.362,2.917,0.36
    		c6.994-0.011,14.044,0.475,20.966-0.249c7.13-0.745,13.454,3.343,20.719,2.15c4.946-0.812,10.245,0.757,15.396,0.897
    		c6.807,0.186,13.622,0,20.434,0.002c12.798,0.003,25.597,0.023,38.395,0.045c3.47,0.006,6.946,0.441,10.012-2.038
    		c0.874-0.707,2.561-0.52,3.878-0.549c7.984-0.174,15.974-0.462,23.953-0.313c2.08,0.039,4.745,0.943,6.071,2.415
    		c2.63,2.918,5.471,3.765,9.219,3.541c4.627-0.275,4.665-0.071,4.867-4.703c0.22-5.008,0.839-5.712,5.748-7.367
    		c2.487-0.839,4.936-1.84,7.298-2.98c0.748-0.361,1.615-1.394,1.651-2.155c0.162-3.489,0.222-7.007-0.086-10.478
    		c-0.077-0.869-1.819-2.374-2.505-2.228c-1.124,0.241-2.596,1.446-2.866,2.513c-0.477,1.882,0.031,3.992-0.249,5.953
    		c-0.166,1.159-0.783,2.583-1.675,3.237c-1.668,1.226-3.629,2.122-5.578,2.867c-0.971,0.371-2.468,0.506-3.194-0.007
    		c-0.534-0.377-0.346-1.999-0.203-3.027c0.081-0.585,0.824-1.046,1.085-1.644c1.173-2.689,0.271-4.921-2.401-6.114
    		c-3.577-1.598-7.596-0.734-8.836,1.593c-2.047,3.84-4.187,7.654-6.648,11.231c-0.646,0.94-2.595,1.235-3.989,1.367
    		c-5.739,0.543-11.548,0.567-17.231,1.438c-5.418,0.83-11.188-2.712-16.354,1.459c-0.645,0.521-2.24-0.168-3.398-0.244
    		c-1.959-0.129-3.925-0.341-5.881-0.292c-3.789,0.097-7.577,0.545-11.36,0.498c-7.744-0.097-15.484-0.578-23.228-0.605
    		c-5.579-0.021-11.158,0.523-16.742,0.661c-10.479,0.259-20.809-1.216-31.104-2.956c-2.88-0.487-3.85-2.454-2.549-5.036
    		c0.658-1.306,1.939-2.626,1.89-3.899c-0.186-4.805,3.414-7.942,4.754-12.012c0.668-2.032,1.237-4.6,0.53-6.408
    		c-0.494-1.262-3.169-2.06-4.979-2.328c-2.72-0.403-2.791,1.924-3.422,3.884c-0.346,1.074-2.111,2.434-3.112,2.34
    		c-0.941-0.09-1.82-1.856-2.52-3.011c-0.298-0.492-0.099-1.306-0.067-1.97c0.103-2.197-0.337-4.295-2.933-4.342
    		c-2.747-0.05-3.799,2.262-3.553,4.476c0.263,2.355-0.07,4.077-1.637,5.896c-0.719,0.835-0.511,2.499-0.648,3.794
    		c-0.141,1.318,0.318,3.07-0.369,3.895c-2.717,3.258-2.7,6.976-2.627,10.831c0.058,2.982-1.544,4.137-4.495,3.77
    		c-5.062-0.63-10.254-0.647-15.181-1.812c-7.557-1.787-15.526-2.972-21.99-7.54c-2.682-1.895-5.287-5.531-5.673-8.654
    		c-0.557-4.504-1.622-9.646,2.035-13.878c0.753-0.87,0.937-2.592,0.738-3.817c-0.18-1.108-1.271-2.941-1.903-2.919
    		c-1.233,0.045-3.182,0.911-3.508,1.883C403.634,840.729,399.55,844.522,402.124,849.918z M553.912,797.694
    		c-0.614,0.269-1.229,0.537-1.843,0.806c1.271,2.881,2.543,5.762,3.815,8.643c0.671,1.519,1.542,2.98,1.979,4.562
    		c1.846,6.681,5.129,12.092,10.616,16.756c4.638,3.943,10.195,4.274,15.279,6.206c2.859,1.088,7.13,1.04,9.752-0.374
    		c4.943-2.666,9.961-2.188,15.055-2.479c1.798-0.103,4.176,0.229,5.257-0.763c2.714-2.49,5.802-1.833,8.862-2.319
    		c5.768-0.916,11.546-2.076,17.122-3.781c5.036-1.54,9.315-5.348,15.002-5.11c0.44,0.018,0.99-0.266,1.333-0.579
    		c4.095-3.75,9.811-2.903,14.545-5.106c3.477-1.618,7.036-3.418,11.364-3.76c4.118-0.324,7.957-3.519,12.041-5.144
    		c5.682-2.263,11.521-4.128,17.204-6.386c2.671-1.062,5.262-2.449,7.662-4.033c4.508-2.974,8.716-6.414,13.292-9.271
    		c8.097-5.054,17.1-8.419,24.737-14.486c5.595-4.444,12.036-7.79,16.963-13.206c1.327-1.459,3.783-3.078,5.45-2.828
    		c6.241,0.938,9.265-3.207,12.815-6.876c1.794-1.853,1.737-3.791,0.146-5.26c-1.635-1.511-3.726-2.873-5.846-3.439
    		c-4.127-1.101-8.401-1.682-12.64-2.307c-1.791-0.265-3.696-0.375-5.458-0.055c-2.311,0.422-4.105,3.757-2.75,4.776
    		c2.148,1.615,4.537,3.305,7.072,3.894c2.201,0.511,2.809,1.359,2.741,3.229c-0.071,1.973-1.235,2.65-3.034,2.145
    		c-2.04-0.573-3.188-0.408-2.925,2.22c0.363,3.637-2.441,5.172-5.005,6.736c-1.241,0.757-2.886,1.062-3.829,2.062
    		c-4.569,4.845-9.816,8.386-16.184,10.622c-3.401,1.195-6.294,3.829-9.435,5.785c-4.217,2.625-8.187,5.923-12.748,7.651
    		c-5.473,2.075-10.464,4.805-15.461,7.713c-1.93,1.124-4.406,1.295-6.37,2.379c-4.941,2.729-9.633,5.975-15.586,6.1
    		c-0.272,0.005-0.646,0.3-0.787,0.56c-1.282,2.348-3.442,2.122-5.657,2.233c-1.345,0.067-2.672,0.892-3.967,1.468
    		c-3.244,1.444-6.487,4.2-9.692,4.146c-6.199-0.104-10.833,3.214-16.129,5.137c-4.085,1.483-8.69,1.435-12.396,4.132
    		c-0.22,0.16-0.672-0.046-0.981,0.041c-2.14,0.604-4.269,1.246-6.404,1.863c-1.261,0.364-2.579,1.121-3.784,0.977
    		c-3.481-0.417-6.531,0.327-9.466,2.192c-0.92,0.585-2.215,0.664-3.359,0.797c-6.195,0.721-12.438,1.141-18.588,2.133
    		c-6.965,1.124-13.569,1.693-20.1-2.229c-4.294-2.578-6.765-6.159-8.724-10.43c-0.858-1.87-1.813-3.697-2.632-5.584
    		c-1.015-2.337-2.381-4.643-2.79-7.096c-0.681-4.075,0.259-8.68-1.296-12.279c-1.666-3.855-2.295-7.483-2.398-11.5
    		c-0.04-1.522-0.792-3.173-1.657-4.474c-0.537-0.806-1.897-1.064-2.889-1.568c-0.391,0.988-1.059,1.961-1.117,2.97
    		c-0.146,2.49-0.081,4.999,0.028,7.494c0.095,2.132,0.062,4.343,0.638,6.362C551.533,792.484,552.836,795.057,553.912,797.694z
    		 M507.241,837.762c0,0.033,0,0.066,0,0.1c5.997,0,11.994,0.057,17.989-0.048c1.175-0.021,2.339-0.666,3.508-1.023
    		c-0.671-1.064-1.133-2.4-2.067-3.123c-1.103-0.851-2.688-1.046-3.922-1.769c-0.707-0.414-1.665-1.376-1.591-1.978
    		c0.318-2.569,5.858-4.478,7.953-2.807c5.292,4.22,11.357,5.522,17.937,4.765c3.257-0.375,7.561-5.919,6.877-8.648
    		c-0.525-2.099-5.157-6.176-7.136-6.281c-3.003-0.16-7.356,2.515-8.509,5.113c-0.472,1.063-1.184,2.02-1.787,3.024
    		c-0.839-0.725-1.838-1.329-2.49-2.195c-2.63-3.495-6.361-4.553-9.87-2.26c-4.201,2.745-8.231,5.598-13.618,5.495
    		c-0.7-0.014-1.429,1.753-2.155,2.687c-0.822,1.058-1.646,3.007-2.487,3.017c-7.193,0.087-14.406,0.396-21.551-1.078
    		c-4.196-0.866-8.494-1.243-12.745-1.846c-2.799-0.396-5.927-0.148-8.316-1.357c-3.695-1.868-7.437-1.262-11.177-1.707
    		c-4.07-0.484-8.082-1.465-12.116-2.242c-0.971-0.187-1.931-0.43-2.897-0.637c-5.72-1.228-11.565-2.06-17.111-3.83
    		c-3.164-1.01-6.047-2.559-9.522-2.108c-0.714,0.093-1.785-0.285-2.234-0.826c-1.859-2.236-4.307-2.139-6.836-2.188
    		c-1.452-0.027-3.303-0.03-4.263-0.863c-2.245-1.951-4.622-2.301-7.389-2.055c-1.097,0.097-2.507-0.076-3.332-0.705
    		c-4.271-3.258-9.98-1.966-14.503-5.029c-2.854-1.934-5.336-3.339-6.088-6.958c-0.745-3.586-2.625-7.058,0.086-10.622
    		c0.317-0.417,0.253-1.412-0.02-1.941c-1.714-3.326-1.512-6.553-0.036-9.919c0.43-0.979,0.346-2.39,0.008-3.445
    		c-0.35-1.09-1.295-1.989-1.98-2.973c-1.146,0.806-2.748,1.376-3.319,2.479c-0.628,1.213-0.213,2.931-0.419,4.404
    		c-0.671,4.776,1.651,10.073-2.544,14.293c-0.456,0.458-0.374,2.271,0.123,2.721c2.73,2.471,2.281,5.644,2.551,8.78
    		c0.126,1.462,1.125,3.016,2.113,4.207c3.659,4.41,9.221,5.415,14.171,7.411c3.884,1.565,8.302,2.003,11.919,3.975
    		c3.63,1.979,7.297,1.639,10.996,2.272c5.354,0.917,10.353,3.677,16.017,3.266c0.259-0.02,0.521,0.433,0.824,0.523
    		c2.298,0.688,4.579,1.467,6.918,1.978c5.94,1.298,12.23,1.689,17.749,3.971c3.567,1.475,6.805,1.999,10.448,2.102
    		c3.438,0.098,6.885,0.503,10.286,1.039c2.708,0.427,5.322,1.573,8.031,1.824c3.636,0.336,7.324,0.062,10.987,0.171
    		c1.113,0.033,2.69,0.084,3.238,0.775c2.026,2.553,4.672,2.093,7.309,2.08C496.578,837.743,501.91,837.762,507.241,837.762z
    		 M686.348,720.045c0.733,3.843-0.202,7.081-4.868,8.571c-2.268,0.725-4.688,1.8-6.355,3.42
    		c-5.562,5.408-13.172,6.829-19.604,10.521c-5.575,3.201-11.6,5.648-17.523,8.199c-3.718,1.601-7.571,2.926-11.445,4.112
    		c-7.267,2.225-14.727,3.892-21.854,6.484c-6.129,2.229-12.116,4.691-18.722,4.924c-3.15,0.11-6.464-0.577-9.423,0.186
    		c-4.17,1.074-7.615,1.699-12.258,0.381c-4.758-1.35-8.393-3.945-12.427-6.139c-0.96-0.521-1.494-2.121-1.891-3.334
    		c-1.225-3.744-3.829-7.066-2.592-11.583c0.38-1.387-1.404-3.614-2.607-5.133c-2.261-2.857-3.689-2.961-6.008-0.228
    		c-3.07,3.618-5.922,7.438-8.647,11.325c-1.826,2.604-0.921,5.165,2.188,5.565c2.654,0.343,5.673,0.17,8.112-0.812
    		c2.248-0.905,3.349-0.787,4.402,1.215c0.542,1.031,1.159,2.024,1.675,3.067c3.392,6.86,9.533,10.257,16.512,11.396
    		c6.143,1.003,12.541,0.465,18.828,0.544c0.792,0.01,1.878-0.105,2.336-0.609c2.354-2.589,5.41-2.097,8.361-2.202
    		c3.477-0.125,6.884-0.17,10.027-2.295c1.354-0.915,3.49-0.685,5.275-0.947c1.13-0.166,2.438,0.034,3.372-0.474
    		c5.189-2.818,10.248-5.899,16.511-5.55c0.583,0.032,1.182-0.545,1.801-0.763c2.084-0.732,4.179-1.435,6.271-2.14
    		c0.771-0.261,1.602-0.397,2.314-0.766c4.264-2.198,7.978-5.747,13.332-5.246c0.548,0.051,1.37-0.381,1.703-0.848
    		c2.537-3.55,6.471-4.832,10.174-6.567c5.163-2.42,10.354-4.827,15.306-7.639c3.899-2.214,7.486-4.988,11.155-7.593
    		c2.303-1.635,4.415-3.548,6.771-5.096c2.522-1.656,4.561-3.508,6.076-6.269c1.099-2,2.238-3.553-0.052-5.017
    		c-2.444-1.563-3.571-6.494-7.973-3.488c-2.503,1.709-4.402,1.131-5.767-1.37c-1.864-3.416-3.47-6.979-5.456-10.319
    		c-0.765-1.287-2.146-2.408-3.509-3.022c-0.44-0.199-1.828,1.367-2.593,2.284c-2.298,2.756-0.436,4.682,1.215,6.938
    		C682.019,708.555,687.498,712.487,686.348,720.045z M644.863,792.484c0-2.145,0.127-3.656-0.025-5.139
    		c-0.365-3.574-3.906-6.204-7.692-6.256c-4.443-0.061-4.003,2.943-4.064,5.808c-0.021,0.986-0.317,1.964-0.45,2.951
    		c-0.571,4.269-4.039,9.531-7.905,11.216c-1.49,0.649-3.691,0.935-5.008,0.271c-0.896-0.451-0.802-2.842-1.177-4.358
    		c-0.529-2.139-0.802-5.96-1.678-6.106c-3.241-0.542-8.264-1.184-9.748,0.601c-3.938,4.73-9.787,4.521-14.332,7.312
    		c-1.158,0.712-3.369-0.293-5.097-0.509c0.84-1.633,1.353-3.61,2.608-4.806c1.385-1.318,3.612-1.713,5.157-2.914
    		c0.831-0.646,1.002-2.142,1.471-3.254c-1.147-0.412-2.428-1.36-3.414-1.117c-1.691,0.417-3.51,1.268-4.724,2.485
    		c-3.783,3.794-7.326,7.828-10.906,11.819c-0.395,0.44-0.78,1.411-0.564,1.788c1.054,1.844,1.991,4.051,3.628,5.146
    		c0.908,0.608,3.125-0.649,4.715-1.181c0.916-0.305,1.758-1.21,2.631-1.208c7.599,0.018,13.576-4.343,20.121-7.191
    		c1.079-0.47,2.433-0.311,3.659-0.441c-0.466,1.444-0.496,3.559-1.483,4.201c-2.434,1.584-5.262,2.607-8.011,3.648
    		c-6.248,2.365-12.547,4.597-18.817,6.904c-0.932,0.343-2.021,0.608-2.67,1.27c-0.606,0.617-1.131,1.823-0.931,2.563
    		c0.167,0.618,1.633,1.495,2.185,1.3c3.295-1.169,7.125,0.864,10.243-2.585c1.868-2.067,5.44-3.41,8.324-3.557
    		c4.711-0.237,8.005-3.223,11.931-4.864c2.271-0.95,3.609-1.679,5.642,0.268c0.987,0.946,3.142,1.706,4.29,1.275
    		c5.662-2.12,11.097-4.84,16.743-7.007C643.84,799.158,646.155,796.665,644.863,792.484z M674.876,792.849
    		c4.817,1.886,8.539-0.427,11.871-4.187c0.773-0.873,2.478-0.856,3.452-1.629c1.761-1.394,3.533-2.906,4.802-4.719
    		c0.495-0.708-0.094-2.526-0.722-3.491c-0.237-0.364-2.106,0.013-3.012,0.479c-1.457,0.752-3.086,1.596-4.021,2.859
    		c-3.949,5.34-9.534,5.481-15.274,5.117c-5.058-0.321-8.198-7.112-5.409-11.353c0.88-1.338,1.466-2.864,2.249-4.27
    		c1.578-2.83,2.817-5.968,4.903-8.363c2.333-2.68,5.538-4.58,8.172-7.025c0.839-0.778,1.636-2.356,1.398-3.306
    		c-0.201-0.803-2.059-1.874-2.944-1.688c-2.152,0.452-4.635,1.078-6.137,2.518c-2.862,2.744-5.858,3.968-9.817,3.865
    		c-1.726-0.045-3.603,1.159-5.207,2.131c-2.533,1.534-3.338,4.28-1.594,6.395c1.934,2.343,3.561-0.112,5.284-1.388
    		c1.038-0.77,2.829-0.521,4.282-0.73c-0.448,1.294-0.646,2.769-1.409,3.839c-0.948,1.328-2.449,2.245-3.549,3.484
    		c-0.852,0.959-1.864,2.059-2.095,3.234c-1.063,5.436,0.151,10.492,3.274,15.042C666.13,793.679,670.117,794.062,674.876,792.849z
    		 M498.034,820.166c2.256-0.306,4.619-0.291,6.737-1.005c2.234-0.753,4.364-2.018,6.307-3.389
    		c2.842-2.007,5.926-18.104,4.131-21.101c-1.138-1.9-2.617-2.073-3.793-0.029c-1.983,3.449-3.908,6.938-3.071,11.196
    		c0.734,3.735-1.616,6.005-4.271,7.754c-2.79,1.838-14.176,0.251-16.674-2.046c-0.847-0.778-2.109-1.698-2.178-2.628
    		c-0.31-4.179-1.921-8.609,1.133-12.413c1.767-2.2,1.456-3.953-0.582-5.521c-2.301-1.77-3.847-0.225-4.768,1.647
    		c-1.845,3.751-3.807,7.537-4.873,11.534c-0.416,1.56,2.079,3.704,2.153,5.624c0.169,4.406,4.067,4.414,6.459,5.841
    		C488.747,818.021,492.696,821.279,498.034,820.166z M468.104,765.718c0.166,0.504,0.333,1.009,0.499,1.513
    		c2.487-0.628,5.711-0.48,7.332-2.021c6.402-6.081,8.767-7.678,0.847-14.242c-3.824-3.168-7.865-3.104-10.963-1.698
    		c-3.212,1.456-5.82,5.214-7.565,8.554c-1.001,1.915-0.211,4.692-2.48,6.586c-0.678,0.566-0.613,3.109,0.078,3.912
    		c0.649,0.752,2.689,0.798,3.922,0.454C462.613,767.981,465.335,766.764,468.104,765.718z M677.981,713.138
    		c0.123-4.031-6.704-10.114-9.387-8.659c-4.034,2.188-9.576,2.89-10.891,8.498c-0.552,2.354-0.784,4.883-0.531,7.266
    		c0.075,0.709,2.565,0.928,3.455,1.848c0.997,1.031,1.803,2.55,2.009,3.956c0.203,1.394-0.689,2.921-0.634,4.371
    		c0.038,0.993,0.69,2.174,1.45,2.827c0.316,0.271,1.71-0.48,2.467-0.986c2.896-1.938,3.019-4.904,2.784-7.97
    		c-0.225-2.938,0.309-4.909,3.934-5.593C675.613,718.134,677.68,715.701,677.981,713.138z M282.256,704.089
    		c-0.986,0.544-3.051,1.507-4.896,2.786c-0.772,0.535-1.08,1.741-1.599,2.642c1.002,0.428,1.973,1.038,3.018,1.22
    		c0.751,0.131,1.595-0.348,2.409-0.49c3.573-0.63,4.778,0.075,4.679,3.661c-0.084,3.02,1.352,4.846,3.149,6.927
    		c6.038,6.99,14.038,11.182,21.884,15.546c1.1,0.611,4.067-0.041,4.629-1.006c1.086-1.864,0.058-3.455-2.371-4.396
    		c-4.641-1.797-9.377-3.538-12.751-7.644c-1.56-1.897-3.493-3.612-5.562-4.931c-2.268-1.443-3.019-3.231-3.056-5.785
    		C291.719,707.783,288.179,704.251,282.256,704.089z M425.24,756.231c-0.402,3.098,0.962,4.546,4.495,4.564
    		c3.268,0.018,6.552,0.576,9.792,1.117c4.172,0.697,6.514-1.944,8.697-4.664c1.634-2.034,2.48-4.625,0.596-6.883
    		c-2.145-2.571-3.986-6.223-8.315-5.076c-3.28,0.868-6.403,2.312-9.648,3.333C426.052,750.136,425.202,751.15,425.24,756.231z
    		 M404.79,803.556c0,1.765-0.335,3.525,0.12,5.05c0.292,0.978,1.657,2.016,2.714,2.252c0.715,0.16,2.239-0.847,2.521-1.644
    		c1.044-2.945,2.128-5.977,2.456-9.055c0.743-6.971,0.734-7.145,7.774-6.951c4.819,0.132,8.438-3.469,8.372-8.332
    		c-0.042-3.135-1.751-4.22-4.5-2.812c-1.309,0.671-2.491,1.675-3.863,2.122c-4.432,1.447-9.355,2.087-10.664,7.816
    		c-0.129,0.565-0.798,1.069-1.315,1.474C405.041,796.103,403.924,799.534,404.79,803.556z M520.422,863.982
    		c4.943,1.124,8.142,0.807,10.493-2.199c1.646-2.104,1.723-4.356-0.229-6.242c-1.049-1.013-3.63-2.297-3.69-2.208
    		c-2.848,4.164-6.878,5.685-11.708,5.784c-0.479,0.01-0.929,0.883-1.413,1.332c-1.988,1.845-3.802,1.724-5.482-0.453
    		c-0.709-0.917-1.467-1.808-2.289-2.623c-1.777-1.762-3.013-1.512-4.053,0.898c-1.331,3.085-1.468,6.746,1.259,8.055
    		c2.771,1.331,6.653,0.994,9.908,0.485C516.087,866.362,518.761,864.67,520.422,863.982z M439.418,813.497
    		c0.245,0.08,0.491,0.16,0.737,0.239c0,0.667,0.03,1.334-0.006,1.999c-0.117,2.165,0.07,4.085,2.917,4.201
    		c2.561,0.104,2.96-1.617,3.103-3.589c0.216-2.979,0.519-5.953,0.643-8.937c0.167-3.979,1.436-5.309,5.44-5.507
    		c1.809-0.089,3.782,0.144,5.37-0.517c1.342-0.558,2.818-2.06,3.126-3.398c0.213-0.928-1.26-3.025-2.329-3.317
    		c-3.278-0.893-6.727-1.483-10.104-1.463c-0.874,0.005-1.723,2.688-2.604,4.128c-0.259,0.423-0.702,0.761-0.867,1.21
    		C443.014,803.522,441.221,808.512,439.418,813.497z M817.491,762.945c-0.676-1.397-0.989-3.164-1.811-3.445
    		c-0.911-0.312-2.386,0.578-3.428,1.238c-0.803,0.51-1.174,1.823-1.991,2.156c-1.238,0.504-2.696,0.466-4.061,0.659
    		c0.297-1.24,0.428-2.554,0.934-3.702c0.656-1.488,2.211-2.828,2.252-4.265c0.034-1.195-1.56-2.437-2.432-3.657
    		c-0.649,0.907-1.321,1.798-1.941,2.724c-0.981,1.465-1.607,3.408-2.945,4.35c-5.484,3.856-2.25,8.263,2.562,10.415
    		c4.083,1.825,4.396,2.162,1.945,5.744c-0.46,0.674-1.251,1.361-1.259,2.05c-0.014,1.197,0.463,2.4,0.734,3.601
    		c0.967-0.461,2.368-0.663,2.826-1.429c1.835-3.07,3.379-6.315,5.028-9.497C815.077,767.626,816.242,765.363,817.491,762.945z
    		 M747.994,812.873c0.043-2.485-7.256-8.414-9.133-7.796c-0.716,0.235-1.512,0.805-1.872,1.446
    		c-2.081,3.703-3.837,7.606-6.129,11.168c-1.188,1.847-3.084,3.435-5.012,4.511c-0.747,0.417-2.543-0.763-3.716-1.46
    		c-1.409-0.837-2.571-1.096-3.014,0.808c-0.815,3.516,2.15,5.1,4.203,6.724c0.813,0.643,3.466-0.116,4.643-1.045
    		c2.576-2.032,4.803-4.526,7.064-6.933c3.016-3.208,6.198-5.992,10.912-6.284C746.721,813.963,747.453,813.188,747.994,812.873z
    		 M324.837,763.392c-0.623-0.588-1.749-2.548-3.206-2.837c-2.885-0.572-5.978-0.484-8.938-0.194
    		c-5.337,0.523-6.639,4.585-4.669,9.27c1.828,4.347,3.878,6.652,8.662,6.07c0.493-0.06,1.04,0.764,1.634,0.992
    		c1.553,0.596,3.12,1.262,4.74,1.498c0.388,0.056,1.578-2,1.424-2.164c-1.171-1.239-2.548-2.301-3.94-3.306
    		c-0.515-0.371-1.297-0.35-1.867-0.667c-1.732-0.964-3.585-1.82-5.054-3.104c-0.644-0.563-0.926-2.303-0.524-3.058
    		c0.322-0.604,2.039-0.924,2.933-0.665c1.567,0.452,2.925,1.58,4.465,2.183C323.336,768.52,324.697,767.612,324.837,763.392z
    		 M353.456,711.085c-2.132,0.86-3.592,1.671-5.156,2.042c-7.479,1.776-13.141,5.165-12.147,14.502
    		c0.108,1.02,0.706,1.987,1.076,2.979c1.002-0.462,2.412-0.661,2.919-1.444c0.937-1.447,1.588-3.188,1.928-4.894
    		c0.931-4.673,2.689-5.657,6.586-3.123c2.483,1.614,4.306,0.803,6.248-0.845c2.042-1.732,2.755-3.922,1.647-6.227
    		C355.928,712.768,354.354,711.912,353.456,711.085z M256.221,729.667c-0.353,2.334,0.265,4.279,3.557,4.27
    		c0.567-0.002,1.347,0.417,1.668,0.888c2.158,3.162,4.007,1.76,6.063-0.161c1.327-1.24,2.919-2.204,4.428-3.242
    		c2.082-1.433,2.512-2.948,0.52-4.851c-0.958-0.914-1.561-2.645-2.614-2.926c-2.917-0.781-6.243-1.916-8.918-1.166
    		C258.403,723.186,255.847,725.679,256.221,729.667z M722.086,769.334c-0.083-5.104-3.107-9.077-5.177-13.423
    		c-1.317-2.765-2.475-5.616-3.97-8.28c-0.93-1.657-2.118-3.398-3.649-4.403c-0.905-0.594-2.923-0.183-4.009,0.481
    		c-0.545,0.333-0.546,2.455-0.065,3.396c1.341,2.627,3.177,4.997,4.608,7.584c3.037,5.486,5.942,11.046,8.901,16.576
    		c0.234,0.438,0.365,0.995,0.717,1.291c0.453,0.382,1.176,0.867,1.606,0.734c0.455-0.139,0.878-0.916,0.997-1.481
    		C722.214,771.017,722.1,770.162,722.086,769.334z M576.837,859.214c-0.021,0.001-0.043,0.001-0.064,0.001
    		c0,2.643-0.522,5.433,0.168,7.88c0.539,1.912,2.585,3.399,3.961,5.076c1.569-1.516,3.154-3.015,4.687-4.567
    		c0.168-0.17-0.027-0.653,0.048-0.968c1.58-6.614-3.659-12.083-3.223-18.544c0.016-0.239-2.685-1.19-3.003-0.828
    		c-1.121,1.274-2.196,2.904-2.469,4.536C576.538,854.21,576.837,856.737,576.837,859.214z M565.534,761.094
    		c1.604-0.333,2.998-0.224,3.8-0.86c3.234-2.568,6.594-5.105,9.245-8.218c0.985-1.158,0.772-4.156-0.004-5.787
    		c-0.946-1.985-2.858-3.721-4.743-4.976c-1.31-0.872-3.434-1.279-4.891-0.854c-0.74,0.215-1.004,2.71-1.137,4.205
    		c-0.164,1.85-0.066,3.691,2.57,3.981c0.973,0.106,1.871,0.878,2.805,1.347c-0.799,0.953-1.442,2.131-2.429,2.812
    		c-2.015,1.391-4.32,2.368-6.289,3.812c-0.932,0.685-1.363,2.052-2.021,3.109C563.562,760.184,564.683,760.701,565.534,761.094z
    		 M300.371,749.689c1.166-0.961,2.91-1.711,3.02-2.651c0.159-1.372-0.417-3.515-1.443-4.226c-6.13-4.241-11.72-3.43-16.973,2.371
    		c-2.582,2.851-4.869,5.98-7.132,9.098c-0.478,0.658-0.68,2.297-0.281,2.641c0.733,0.633,2.143,1.068,3.017,0.789
    		c0.909-0.29,1.499-1.541,2.262-2.339c1.142-1.193,2.346-2.327,3.46-3.544c0.923-1.008,1.949-1.995,2.59-3.176
    		c1.702-3.13,3.23-3.541,6.281-1.629C296.664,747.959,298.312,748.646,300.371,749.689z M622.577,746.46
    		c3.522-0.606,8.501-2.785,9.521-5.542c0.683-1.848,0.639-5.014-0.517-6.273c-2.381-2.597-5.669-4.396-8.711-6.323
    		c-0.678-0.43-2.039-0.31-2.78,0.104c-0.392,0.219-0.491,1.872-0.094,2.437c1.766,2.508,1.119,4.266-0.646,6.64
    		c-1.185,1.595-1.372,4.429-0.964,6.503C618.594,745.058,621.106,745.656,622.577,746.46z M446.031,853.15
    		c0-0.259,0.205-1.31-0.047-2.236c-0.4-1.474-0.95-3.947-1.715-4.057c-1.417-0.202-3.146,0.81-4.547,1.648
    		c-3.881,2.326-5.343,6.457-7.031,10.301c-0.372,0.848,0.182,2.622,0.922,3.318c1.202,1.131,3.051,2.605,4.381,2.393
    		C443.592,863.626,446.146,859.94,446.031,853.15z M357.012,849.336c4.172,0.259,9.487-2.773,11.152-6.36
    		c1.48-3.19,0.548-5.228-2.862-5.683c-2.275-0.305-4.881-0.856-6.853-0.072c-2.915,1.158-5.939,2.949-7.923,5.308
    		C348.3,845.175,352.083,849.031,357.012,849.336z M562.281,863.437c-0.145-0.909,0.032-2.179-0.521-2.815
    		c-1.666-1.918-3.404-3.998-5.576-5.123c-0.7-0.361-3.03,1.868-4.257,3.233c-1.296,1.443-2.123,3.299-3.228,4.924
    		c-3.089,4.54-2.314,6.287,3.152,7.002c0.819,0.107,1.641,0.316,2.458,0.307C557.784,870.92,562.292,866.671,562.281,863.437z
    		 M740.413,730.375c-0.609,1.254-1.57,2.285-1.38,3.017c0.608,2.345,1.532,4.629,2.541,6.843c1.339,2.939,4.135,5.834,4.037,8.67
    		c-0.164,4.787,3.142,7.073,5.209,10.254c0.483,0.745,2.148,0.722,3.271,1.051c-0.013-1.202,0.277-2.507-0.09-3.589
    		c-1.643-4.834-3.297-9.679-5.293-14.372c-1.399-3.29-3.267-6.396-5.12-9.467C743.057,731.9,741.848,731.428,740.413,730.375z
    		 M709.129,726.843c-1.667,0.927-3.541,1.627-4.974,2.846c-2.241,1.906-3.942,4.218-7.308,4.754
    		c-1.495,0.238-2.994,2.492-3.759,4.165c-0.266,0.58,1.491,2.492,2.679,3.194c0.878,0.519,2.398,0.298,3.515-0.015
    		c1.72-0.482,3.416-2.05,4.985-1.88c4.709,0.507,6.408-1.276,5.898-5.845c-0.091-0.813,0.81-1.724,1.207-2.61
    		c0.422-0.943,1.364-2.249,1.046-2.788C711.941,727.854,710.565,727.573,709.129,726.843z M728.098,759.267c0.008,0,0.015,0,0.021,0
    		c0,1.485-0.012,2.97,0.002,4.455c0.023,2.376,1.429,3.128,3.568,3.008c2.959-0.168,7.29-3.872,8.411-6.928
    		c1.386-3.777-1.371-5.766-3.647-7.27c-1.607-1.062-4.371-1.099-6.346-0.571c-0.986,0.264-1.488,2.779-1.96,4.351
    		C727.875,757.22,728.098,758.276,728.098,759.267z M390.113,791.646c-0.131-1.521-0.278-2.511-0.289-3.503
    		c-0.022-2.12-0.284-4.346-2.873-4.424c-2.335-0.07-5.074-0.346-6.074,2.979c-0.451,1.5-2.019,2.706-2.318,4.198
    		c-0.312,1.559-0.278,3.783,0.627,4.85c1.336,1.576,3.531,3.208,5.443,3.313C387.062,799.194,390.168,793.984,390.113,791.646z
    		 M730.622,727.921c0,0.005,0,0.01,0,0.015c0.331,0,0.662,0.003,0.993,0c6.117-0.057,10.807-5.614,9.339-11.355
    		c-0.197-0.771-2.317-1.885-2.894-1.585c-2.689,1.4-7.494,0.065-7.448,5.46c0.004,0.548-2.528,0.92-3.607,1.738
    		c-0.884,0.67-1.907,1.91-1.859,2.847c0.049,0.991,1.106,2.275,2.065,2.779C728.117,728.294,729.468,727.921,730.622,727.921z
    		 M690.561,816.725c-0.713,1.077-1.885,2.072-1.722,2.741c0.56,2.295,1.38,4.587,2.496,6.668c1.054,1.961,2.616,3.646,3.937,5.467
    		c1.407,1.94,2.565,4.142,4.29,5.732c0.773,0.713,3.513,0.735,3.865,0.158c0.71-1.16,1.066-3.414,0.386-4.396
    		c-3.449-4.974-7.262-9.695-10.99-14.472C692.346,818.013,691.618,817.599,690.561,816.725z M234.318,768.366
    		c-2.86,1.05-5.967,1.857-8.738,3.307c-2.446,1.279-2.374,3.359-0.302,5.264c1.998,1.837,3.509,1.313,4.828-0.842
    		c0.513-0.839,1.112-1.712,1.885-2.276c0.548-0.4,1.455-0.309,2.202-0.437c-0.115,0.698,0.005,1.596-0.387,2.058
    		c-1.398,1.647-2.667,3.625-0.706,5.182c2.163,1.717,3.501-0.607,4.784-2.087c0.218-0.251,0.416-0.519,0.611-0.788
    		c1.458-2.018,4.989-3.631,2.811-6.545C240.145,769.646,237.174,769.443,234.318,768.366z M417.971,742.192
    		c-1.049-1.32-1.769-3.011-2.965-3.475c-0.9-0.35-2.582,0.646-3.621,1.434c-4.561,3.453-2.934,9.84-6.359,13.989
    		c-0.458,0.555,0.897,3.551,2.109,4.32c1.865,1.185,2.744-0.728,3.502-2.329c1.13-2.384,2.479-4.664,3.714-6.999
    		C415.479,747.002,416.581,744.858,417.971,742.192z M677.705,834.113c-6.046,0.706-6.662,1.337-6.245,6.848
    		c0.087,1.15-0.123,2.478,0.386,3.403c0.636,1.157,2.07,2.936,2.817,2.781c2.763-0.57,6.101-1.163,7.829-3.033
    		c1.365-1.478,1.415-5.099,0.651-7.278C682.662,835.461,679.605,834.989,677.705,834.113z M654.736,732.979
    		c-1.134-2.3-1.917-3.842-2.659-5.403c-1.455-3.061-2.693-6.246-4.397-9.16c-1.127-1.927-2.729-3.7-4.484-5.073
    		c-0.703-0.55-2.391,0.159-3.629,0.296c0.192,1.032,0.131,2.21,0.626,3.068c1.38,2.392,3.249,4.526,4.442,6.995
    		c1.402,2.904,2.534,5.992,3.328,9.116c0.508,1.998,0.46,4.459,3.161,3.916C652.453,736.466,653.423,734.418,654.736,732.979z
    		 M645.321,737.707c-0.773-1.649-1.186-2.717-1.749-3.696c-2.464-4.274-5.088-8.462-7.399-12.815
    		c-0.803-1.511-1.528-3.051-3.103-1.617c-1.324,1.206-4.487,2.851-1.96,5.096c4.877,4.333,5.303,10.613,7.955,15.895
    		c0.377,0.749,2.016,1.726,2.142,1.607C642.695,740.779,644.006,739.194,645.321,737.707z M714.205,772.525
    		c-1.682-1.502-2.608-2.491-3.693-3.255c-1.147-0.808-2.557-1.259-3.657-2.115c-2.887-2.249-4.699-1.673-5.545,1.931
    		c-0.224,0.957-0.165,2.023-0.562,2.89c-1.181,2.577-0.369,4.816,1.756,6.002c1.5,0.838,4.089,1.246,5.436,0.494
    		C710.19,777.213,711.837,774.873,714.205,772.525z M220.51,750.473c-1.295-0.361-2.249-0.982-2.933-0.764
    		c-7.437,2.38-8.489,10.924-14.162,15.103c-0.047,0.035,1.666,2.718,2.812,3.922c0.269,0.282,2.265-0.351,2.299-0.684
    		c0.557-5.378,5.692-7.448,8.106-11.445C217.729,754.789,218.897,753.018,220.51,750.473z M250.059,709.753
    		c-1.536,0.591-2.575,1.072-3.66,1.395c-4.056,1.203-4.884,5.088-6.467,8.16c-0.736,1.429-0.854,4.339,0.054,5.199
    		c1.702,1.612,3.461-0.011,4.94-1.575c2.413-2.551,3.501-6.342,7.35-7.656c0.569-0.193,0.93-2.521,0.542-3.538
    		C252.47,710.823,250.958,710.353,250.059,709.753z M209.118,745.613c-0.673-0.998-1.121-2.424-1.948-2.696
    		c-1.029-0.339-2.899-0.161-3.49,0.552c-2.934,3.544-5.516,7.378-8.329,11.027c-1.287,1.669-1.964,3.486,0.052,4.601
    		c0.763,0.422,2.867-0.746,3.845-1.711c2.915-2.879,5.602-5.992,8.311-9.072C208.173,747.615,208.516,746.679,209.118,745.613z
    		 M796.939,786.191c-0.555-1.554-0.896-2.832-1.453-4.009c-1.741-3.679-3.436-7.393-5.438-10.928
    		c-0.559-0.985-2.752-2.208-3.11-1.93c-1.129,0.88-2.664,3.323-2.421,3.571c4.178,4.262,5.327,9.956,7.655,15.089
    		c0.38,0.837,2.473,1.754,3.112,1.409C796.173,788.914,796.436,787.276,796.939,786.191z M662.168,690.102
    		c-4.559,1.103-8.121,1.727-11.513,2.862c-2.478,0.829-3.534,4.144-2.212,6.242c1.577,2.506,3.545,2.565,5.834,0.659
    		c0.969-0.807,2.508-0.915,3.51-1.697c1.917-1.499,3.841-3.094,5.309-4.999C663.526,692.61,662.425,690.872,662.168,690.102z
    		 M256.172,783.135c-0.306-0.54-0.497-1.227-0.947-1.611c-1.914-1.639-7.856-0.007-7.841,2.337c0.024,3.614-1.811,5.809-4.125,8.104
    		c-0.81,0.804-0.782,2.452-1.137,3.714c1.476,0.043,3.547,0.728,4.319,0.011c2.518-2.336,4.627-5.133,6.762-7.855
    		C254.306,786.429,255.13,784.807,256.172,783.135z M262.477,787.034c-0.331,0.034-0.668,0.037-0.99,0.108
    		c-3.103,0.69-9.223,9.662-8.431,12.683c0.232,0.886,1.379,1.923,2.284,2.134c0.95,0.222,2.972-0.322,3.047-0.775
    		c0.652-3.958,3.691-6.104,6.012-8.866C266.179,790.198,264.838,787.126,262.477,787.034z M498.283,798.403
    		c-3.153,0.381-4.687,1.371-4.698,3.841c-0.013,2.803,2.131,3.412,4.379,3.615c2.148,0.194,4.582-0.4,4.196-2.786
    		C501.883,801.353,499.646,799.948,498.283,798.403z M601.705,749.109c1.829-2.123,4.014-3.557,3.969-4.916
    		c-0.046-1.36-2.287-3.637-3.627-3.681c-1.285-0.043-3.528,2.146-3.718,3.57C598.156,745.384,600.172,746.976,601.705,749.109z
    		 M677.418,779.987c1.711-1.917,3.656-3.272,3.447-4.042c-0.389-1.434-1.845-2.987-3.238-3.556
    		c-0.647-0.265-3.127,1.683-3.106,2.591C674.552,776.377,676.02,777.742,677.418,779.987z M293.287,766.338
    		c1.458,1.143,2.37,1.858,3.283,2.574c0.495-0.939,1.41-1.904,1.356-2.811c-0.047-0.776-1.16-2.01-1.884-2.071
    		C295.365,763.973,294.58,765.188,293.287,766.338z"/>
    </Link>
    <Link to="/archive/011" className={activeIndex === 11 ? "active" : "inactive"} onClick={() => handleOnClick(11)} >
    	<path className="clickable" fillRule="evenodd" clipRule="evenodd" fill="black" d="M605.973,1417.058c-3.892-1.085-7.76-2.265-11.685-3.21
    		c-1.746-0.42-3.673-0.754-5.403-0.456c-5.129,0.882-9.147-2.152-13.646-3.466c-0.299-0.087-0.729-0.317-0.785-0.556
    		c-1.593-6.724-8.397-10.184-10.072-17.364c-1.123-4.818-4.32-9.837-10.188-12.613c-5.294-2.506-9.512-7.21-8.184-14.461
    		c0.354-1.933-0.877-4.103-0.976-6.183c-0.185-3.897-0.135-7.673,1.578-11.506c0.959-2.145-0.094-5.193-0.259-7.834
    		c-0.081-1.286-0.47-2.615-0.263-3.849c1.019-6.09,1.649-12.035-0.809-18.07c-0.865-2.123-0.185-4.898-0.119-7.373
    		c0.156-5.979,0.496-11.956,0.499-17.934c0.001-2.962-0.917-5.936-0.815-8.883c0.147-4.279,1.1-8.532,1.239-12.812
    		c0.195-5.937,0.185-11.899-0.154-17.828c-0.463-8.117-0.99-16.259-2.139-24.298c-0.588-4.117-2.605-8.025-3.938-12.042
    		c-0.661-1.994-1.235-4.019-1.838-6.032c-0.046-0.152,0.026-0.339-0.012-0.496c-1.619-6.578-3.375-13.125-4.812-19.742
    		c-0.697-3.212-0.917-6.546-1.14-9.839c-0.289-4.261-0.113-8.402-1.588-12.704c-1.211-3.532,0.069-7.873-0.001-11.851
    		c-0.12-6.808-0.49-13.609-0.631-20.417c-0.045-2.143,0.338-4.291,0.471-6.439c0.152-2.462,0.224-4.928,0.369-7.39
    		c0.085-1.436,0.251-2.865,0.372-4.298c0.107-1.252,0.468-2.553,0.253-3.748c-0.604-3.358-1.392-6.688-2.223-9.999
    		c-0.426-1.697-1.224-3.304-1.626-5.005c-0.516-2.182-1.106-4.429-1.022-6.623c0.029-0.772,1.958-2.106,3.016-2.103
    		c7.479,0.029,14.965,0.216,22.434,0.621c3.129,0.17,6.26,0.841,9.321,1.571c2.415,0.576,4.722,1.893,6.959-0.387
    		c0.294-0.3,1.017-0.499,1.388-0.354c10.473,4.102,21.353,1.024,32.015,1.774c8.791,0.618,17.643,0.374,26.468,0.506
    		c3.427,0.051,6.319,0.476,5.968,5.17c-0.075,0.99,1.662,2.116,2.564,3.18c0.817-0.983,1.947-1.845,2.389-2.975
    		c1.036-2.661,3.6-3.889,6.781-2.83c2.85,0.947,3.006,3.683,1.877,5.293c-3.638,5.188-2.291,10.766-2.355,16.346
    		c-0.045,3.875-1.274,7.722-1.602,11.611c-0.695,8.28-1.491,16.576-1.63,24.875c-0.294,17.49,1.249,35.143-0.614,52.442
    		c-2.345,21.77,0.19,43.575-2.088,65.136c-1.943,18.382,0.373,36.548-0.268,54.799c2.601,4.861,1.844,10.77,3.829,15.389
    		c2.375,5.527,7.531,9.87,11.542,14.678c0.728,0.872,1.755,1.488,2.611,2.26c3.402,3.065,6.312,6.538,10.809,8.474
    		c5.03,2.164,9.618,5.378,14.328,8.25c1.106,0.675,2.236,1.647,2.837,2.762c3.576,6.637,9.342,11.384,14.273,16.847
    		c4.606,5.104,4.582,11.652-0.658,16.237c-4.413,3.861-8.883,8.201-15.41,7.87c-2.13-0.107-4.312-1.073-6.284-2.027
    		c-4.377-2.116-8.866-4.132-12.881-6.827c-4.269-2.865-8.007-6.511-12.055-9.719c-1.02-0.808-2.334-1.243-3.514-1.85
    		c-0.22,1.388-0.938,2.943-0.538,4.119c0.54,1.591,1.816,3.001,2.994,4.289c2.333,2.553,4.921,4.878,7.174,7.495
    		c0.734,0.853,1.337,2.604,0.937,3.423c-0.384,0.785-2.128,1.164-3.311,1.257c-7.999,0.622-14.449-2.823-20.174-7.987
    		c-1.73-1.561-3.378-3.218-4.999-4.892c-1.551-1.602-3.187-3.57-5.391-1.364c-2.317,2.32-0.546,4.188,1.33,5.821
    		c0.877,0.765,1.872,1.469,2.513,2.406c0.463,0.678,0.431,1.695,0.62,2.562c-0.929,0.062-2.228,0.548-2.724,0.115
    		c-2.855-2.495-5.828-4.97-8.135-7.938c-1.385-1.781-1.542-4.691-4.816-3.536c-1.761,0.622-2.539,5.102-1.428,7.251
    		c2.326,4.498,2.326,4.493-2.804,4.391c-1.166-0.023-2.332-0.003-3.499-0.003C606.144,1416.463,606.058,1416.76,605.973,1417.058z
    		 M578.07,1199.482c-0.011,0.169-0.021,0.339-0.031,0.509c-4.995,0-9.994,0.107-14.983-0.073c-1.307-0.047-3.157-0.622-3.765-1.583
    		c-2.06-3.263-0.145-11.141,3.509-11.871c6.006-1.201,8.356-4.692,9.399-9.985c0.029-0.146,0.216-0.254,0.293-0.398
    		c1.119-2.087,0.438-3.046-1.874-3.229c-5.053-0.399-7.043,1.211-8.791,6.465c-0.39,1.168-2.137,2.362-3.414,2.565
    		c-0.686,0.108-2.313-1.799-2.433-2.912c-0.202-1.885,0.628-3.856,0.68-5.801c0.026-0.999-0.429-2.622-1.119-2.922
    		c-2.819-1.226-6.938,1.207-8.782,4.868c-1.329,2.639-0.518,5.202,2.69,6.931c2.742,1.478,3.724,3.584,3.599,6.515
    		c-0.099,2.328-0.181,4.665-0.087,6.99c0.102,2.537-0.647,4.021-3.438,4.524c-0.991,0.179-2.604,2.29-2.369,2.804
    		c0.552,1.207,1.945,2.594,3.183,2.801c2.424,0.405,4.974,0.004,7.471,0.024c23.298,0.196,46.595,0.407,69.892,0.624
    		c3.651,0.034,6.959-1.873,6.467-4.01c-0.816-3.547-3.913-2.47-6.195-2.391c-5.057,0.177-9.991-0.23-15.156,1.527
    		c-4.922,1.674-10.435-1.312-16.066-0.632C590.664,1201.557,584.308,1200.025,578.07,1199.482z M595.888,1377.893
    		c-3.319-0.293-8,0.076-12.129-1.26c-5.65-1.828-10.788-5.177-16.318-7.453c-4.849-1.996-6.299-6.442-7.155-10.52
    		c-0.55-2.613,1.544-5.903,2.797-8.738c1.205-2.729,2.636-5.335,6.502-5.115c1.295,0.073,2.684-1.109,3.993-1.786
    		c1.544-0.798,3.978-1.336,2.32-3.814c-1.226-1.834-2.955-3.194-5.475-1.528c-1.189,0.786-2.638,1.558-3.995,1.607
    		c-1.431,0.052-2.893-0.766-4.341-1.204c0.984-0.931,1.929-1.91,2.968-2.776c0.625-0.521,1.868-0.756,2-1.316
    		c0.25-1.059,0.338-2.771-0.285-3.305c-2.091-1.796-7.007-0.266-9.611,2.697c-3.125,3.557-3.184,4.836-0.372,8.127
    		c3.05,3.57,3.298,4.134-0.273,7.303c-3.465,3.074-4.336,6.806-3.306,10.726c0.886,3.373,2.595,6.737,4.691,9.545
    		c5.142,6.883,11.577,11.882,20.812,11.757c0.776-0.011,1.755,0.241,2.31,0.739c3.631,3.265,7.634,2.218,11.782,1.431
    		c4.874-0.926,9.938-2.424,14.722-1.893c8.18,0.908,15.285-3.532,23.19-3.496c0.926,0.004,2.045-2.193,2.64-3.559
    		c0.121-0.277-1.757-2.08-2.643-2.026c-3.604,0.221-7.673-1.665-10.657,2.231c-0.572,0.748-2.438,0.604-3.72,0.686
    		c-1.652,0.105-3.849-0.666-4.882,0.137C606.987,1378.557,601.799,1376.639,595.888,1377.893z M618.982,1134.006
    		c-0.046-0.006-0.091-0.011-0.137-0.017c0-2.164,0.109-4.335-0.033-6.49c-0.121-1.838,0.294-4.892-2.536-4.153
    		c-1.502,0.393-2.38,3.426-3.354,5.359c-0.188,0.371,0.604,1.151,0.744,1.787c0.804,3.655,2.512,7.336-0.803,10.789
    		c-0.65,0.679-0.693,2.211-0.578,3.304c0.353,3.338-0.593,4.309-4.214,3.841c-7.177-0.927-14.438-1.388-21.677-1.622
    		c-13.448-0.435-26.906-0.576-40.36-0.812c-1.151-0.021-2.58-0.2-3.369,0.384c-0.574,0.425-0.579,2.849-0.371,2.915
    		c3.569,1.137,7.163,2.405,10.852,2.896c3.394,0.45,6.906-0.044,10.365-0.03c12.981,0.051,25.964,0.074,38.943,0.257
    		c3.792,0.054,8.145-0.479,11.244,1.15c6.125,3.222,11.646,0.619,17.372-0.187c0.991-0.14,1.746-1.952,2.611-2.992
    		c-1.236-0.668-2.431-1.782-3.718-1.904c-2.292-0.218-4.646,0.104-6.963,0.31c-4.665,0.413-5.142-0.063-4.746-4.836
    		C618.529,1140.641,618.742,1137.322,618.982,1134.006z M590.555,1318.741c0-0.102,0-0.203,0-0.304
    		c5.151,0,10.304-0.01,15.455,0.002c7.966,0.018,15.935,0.156,23.896-0.03c1.297-0.03,2.561-1.473,3.84-2.263
    		c-1.079-1.272-2.046-2.678-3.296-3.752c-0.475-0.408-1.603-0.05-2.433-0.05c-23.455-0.004-46.91-0.033-70.365,0.036
    		c-1.747,0.005-3.688,0.371-5.17,1.216c-1.033,0.589-1.436,2.283-2.12,3.483c1.259,0.554,2.513,1.575,3.777,1.588
    		C566.276,1318.789,578.416,1318.741,590.555,1318.741z M570.373,1256.166c0-0.03,0-0.061,0-0.091
    		c-5.664,0-11.336-0.155-16.984,0.132c-1.119,0.058-2.599,1.652-3.033,2.855c-0.217,0.6,1.484,2.698,2.436,2.781
    		c5.772,0.504,11.579,0.818,17.375,0.814c5.808-0.003,11.616-0.444,17.424-0.711c0.992-0.045,2.19,0.155,2.937-0.319
    		c4.976-3.167,10.556-2.505,15.974-2.619c6.993-0.149,13.993,0.01,20.99-0.008c2.294-0.006,4.08-1.16,4.088-3.473
    		c0.01-2.501-2.216-2.498-4.083-2.493c-10.661,0.025-21.322,0.053-31.983,0.138c-1.12,0.009-2.65,0.015-3.284,0.687
    		c-2.544,2.7-5.713,2.314-8.859,2.309C579.036,1256.16,574.705,1256.166,570.373,1256.166z M602.465,1233.32
    		c0.286,0.098,0.572,0.196,0.858,0.294c0,0.665,0.046,1.333-0.009,1.993c-0.167,2.036,0.769,4.689-2.201,5.396
    		c-3.018,0.72-5.802,0.315-8.171-2.214c-2.284-2.438,0.112-3.394,1.052-4.935c0.402-0.661,0.572-1.561,0.571-2.352
    		c-0.005-1.89,0.749-4.674-2.077-4.667c-1.265,0.003-2.737,2.311-3.726,3.826c-1.033,1.585-1.441,3.566-2.398,5.215
    		c-2.175,3.743-0.972,7.029,3.115,8.312c1.247,0.393,2.484,0.953,3.582,1.659c5.154,3.315,9.213,0.272,13.685-1.881
    		c2.963-1.427,2.234-3.605,2.544-5.8c0.443-3.137,2.972-4.548,6.145-3.615c5.776,1.698,12.198-1.17,14.191-6.634
    		c0.412-1.129,0.033-3.259-0.791-3.924c-0.802-0.647-2.844-0.402-3.974,0.173c-1.243,0.633-2.002,2.159-3.096,3.161
    		c-0.682,0.623-1.593,0.996-2.4,1.48c-0.438-0.813-0.898-1.617-1.312-2.444c-0.468-0.935-0.893-1.892-1.337-2.839
    		c-0.902,0.644-1.865,1.22-2.684,1.956c-0.473,0.426-0.71,1.111-1.057,1.679c-1.362,2.232-2.93,3.242-3.942-0.068
    		c-0.964-3.146-2.862-2.24-3.854-0.741C603.835,1228.385,603.33,1230.972,602.465,1233.32z M613.469,1285.873
    		c0.665,0.035,1.382,0.244,1.983,0.066c1.04-0.31,2.008-0.859,3.006-1.308c-0.853-0.837-1.588-1.871-2.587-2.458
    		c-1.123-0.658-2.59-0.735-3.708-1.4c-0.914-0.542-1.537-1.576-2.288-2.392c0.84-0.552,1.633-1.47,2.531-1.588
    		c2.927-0.385,3.158-2.266,2.396-4.334c-0.855-2.32-2.81-1.954-4.582-1.075c-1.177,0.583-2.161,1.744-3.376,2.026
    		c-2.862,0.663-3.469,2.382-3.638,5.066c-0.111,1.772-1.239,3.573-2.212,5.179c-2.009,3.313-4.332,6.44-6.258,9.797
    		c-0.435,0.757-0.1,2.347,0.438,3.206c3.557,5.696,9.746,7.425,15.427,9.87c0.51,0.22,1.457,0.055,1.898-0.305
    		c2.736-2.228,5.829-2.654,9.268-2.821c1.255-0.062,2.385-2.679,3.571-4.13c-1.412-0.605-2.809-1.657-4.239-1.703
    		c-1.738-0.057-3.657,0.428-5.229,1.216c-5.466,2.736-12.361,0.699-14.732-4.655c-0.266-0.598-0.12-1.81,0.318-2.217
    		C604.857,1288.747,608.324,1285.6,613.469,1285.873z M558.949,1289.874c0,3.666-0.589,7.468,0.22,10.946
    		c0.489,2.109,2.834,4.392,4.916,5.338c1.728,0.785,3.177-2.969,2.334-6.434c-0.078-0.322-0.275-0.622-0.316-0.945
    		c-0.438-3.445-1.097-6.885-1.215-10.342c-0.152-4.48-0.041-4.174,4.275-3.115c3.438,0.844,7.23,1.704,10.434-0.94
    		c1.909-1.576,3.558-3.466,5.438-5.079c0.71-0.609,1.745-0.838,2.632-1.24c-0.216,0.856-0.21,1.874-0.691,2.539
    		c-1.193,1.648-2.076,3.628-0.118,4.71c1.069,0.591,3.42-0.247,4.706-1.141c2.056-1.428,3.001-3.409,0.987-5.921
    		c-0.736-0.919-0.833-2.49-0.895-3.779c-0.118-2.467-1.252-3.706-3.72-3.645c-2.266,0.058-4.492-0.435-5.374,2.937
    		c-1.164,4.448-4.27,6.21-8.868,6.276c-3.228,0.047-6.082-0.417-8.916-2.39c-3.27-2.276-5.704-0.724-5.811,3.226
    		C558.887,1283.873,558.951,1286.874,558.949,1289.874z M562.744,1131.903c0.001-0.052,0.002-0.104,0.003-0.157
    		c1.661,0,3.481,0.421,4.935-0.129c1.238-0.469,2.697-1.938,2.919-3.169c0.404-2.245-1.625-2.306-3.344-2.577
    		c-0.751-0.118-1.365-1.107-2.041-1.7c0.866-0.628,1.658-1.5,2.626-1.798c0.647-0.199,1.575,0.354,2.326,0.685
    		c1.519,0.671,3.209,1.057,3.412-1.069c0.082-0.861-1.462-2.55-2.465-2.727c-4.213-0.739-8.037-0.331-11.138,3.521
    		c-3.24,4.025-3.532,3.79-7.754,0.482c-2.345-1.838-4.524-1.156-5.136,1.708c-0.238,1.116-0.354,2.371-0.097,3.462
    		c0.927,3.921,2.213,7.763,3.003,11.707c0.39,1.948,0.937,3.138,3.065,2.981c2.062-0.151,2.859-1.365,2.787-3.347
    		c-0.055-1.494,0.05-2.994-0.021-4.485c-0.135-2.83,1.145-3.979,3.939-3.566C560.745,1131.87,561.75,1131.849,562.744,1131.903z
    		 M586.55,1360.282c4.913,0.888,8.171-1.538,11.271-4.897c3.475-3.765,3.633-8.271,0.017-11.753c-1.791-1.725-3.5-3.537-5.326-5.224
    		c-3.087-2.851-4.748-2.84-7.687-0.098c-2.496,2.331-2.341,3.04,0.742,4.394c1.491,0.654,2.934,1.467,4.288,2.37
    		c2.63,1.754,1.843,4.462,1.472,6.82c-0.437,2.776-2.836,2.233-4.792,2.198c-1.99-0.035-3.99-0.245-5.966-0.1
    		c-1.159,0.085-2.278,0.711-3.416,1.095c0.714,1.341,1.123,3.421,2.206,3.867C581.563,1359.864,584.132,1359.892,586.55,1360.282z
    		 M585.601,1182.488c0.345-3.129,0.095-6.134-2.884-7.934c-1.26-0.762-3.437-1.652-4.242-1.098c-0.991,0.682-1.661,3.01-1.29,4.283
    		c1.456,5.006,3.338,9.891,5.156,14.785c0.334,0.898,1.003,1.744,1.703,2.422c1.497,1.447,3.243,1.891,4.781,0.146
    		c3.752-4.255,7.403-8.598,11.088-12.91c0.101-0.118,0.177-0.315,0.162-0.465c-0.168-1.695-0.355-3.389-0.538-5.082
    		c-1.468,0.711-3.252,1.107-4.338,2.199c-2.208,2.22-3.981,4.865-6.062,7.222c-0.578,0.653-1.608,0.906-2.431,1.344
    		c-0.436-0.977-0.983-1.922-1.266-2.94C585.275,1183.863,585.533,1183.149,585.601,1182.488z M616.682,1363.009
    		c3.398,0.111,14.705-6.862,15.799-9.578c0.219-0.544,0.319-1.463,0.017-1.84c-2.861-3.553-3.263-8.647-8.146-11.12
    		c-2.593-1.312-3.606-0.438-5.033,0.814c-1.769,1.555-0.76,2.865,0.58,4.143c1.798,1.715,3.627,3.429,5.171,5.36
    		c0.474,0.593,0.386,2.577-0.007,2.769c-2.598,1.265-5.283,2.582-8.082,3.071c-1,0.175-2.243-2.288-3.652-2.834
    		c-1.301-0.505-3.243-0.515-4.377,0.152c-0.65,0.383-0.848,2.987-0.223,3.824C610.768,1360.502,612.834,1363.609,616.682,1363.009z
    		 M573.858,1226.844c0.089,0.006,1.29,0.301,2.402,0.102c1.031-0.186,2.485-0.69,2.789-1.443c0.269-0.665-0.609-2.032-1.302-2.8
    		c-1.78-1.974-10.394-3.758-12.507-2.746c-3.692,1.77-7.065,10.681-5.773,14.647c0.439,1.352,0.5,3.08,0.064,4.418
    		c-1.391,4.267-0.609,8.16,2.668,10.785c0.564,0.452,1.782,0.908,2.129,0.636c0.652-0.513,1.262-1.563,1.24-2.374
    		c-0.122-4.613-0.41-9.224-0.693-13.832C564.494,1228.015,566.49,1226.103,573.858,1226.844z M587.629,1143.637
    		c1.712-1.058,4.246-1.72,4.979-3.247c2.007-4.186,5.412-6.786,8.816-9.519c0.387-0.311,0.959-0.662,1.019-1.06
    		c0.183-1.198,0.591-3.225,0.118-3.485c-1.076-0.592-2.912-0.838-3.917-0.282c-1.534,0.849-2.544,2.593-3.934,3.771
    		c-1.228,1.042-2.67,1.83-4.018,2.729c-0.657-1.39-1.219-2.837-2.001-4.153c-0.703-1.183-1.459-2.54-2.563-3.194
    		c-0.574-0.341-2.58,0.439-2.827,1.118c-0.45,1.236-0.652,3.354,0.07,4.079c2.291,2.299,1.907,3.873,0.156,6.399
    		C580.938,1140.528,582.62,1143.025,587.629,1143.637z M630.494,1325.899c-1.557-1.322-2.852-3.077-4.527-3.595
    		c-0.978-0.301-2.757,1.184-3.815,2.229c-0.75,0.741-0.763,2.182-1.386,3.125c-0.236,0.357-1.809,0.364-1.846,0.244
    		c-1.266-4.097-3.223-3.043-5.692-1.048c-0.733,0.593-2.258,0.11-3.365,0.359c-1.534,0.344-3.018,0.913-4.522,1.387
    		c0.695,1.448,1.185,3.05,2.13,4.311c1.565,2.087,3.218,4.697,6.01,1.015c0.419-0.552,2.233-0.575,3.074-0.167
    		c1.608,0.781,2.535,3.214,4.981,1.104c1.429-1.23,3.729-1.507,5.029-2.814C628.121,1330.485,629.029,1328.277,630.494,1325.899z
    		 M627.607,1180.076c-0.435-0.991-0.603-2.253-1.271-2.633c-0.604-0.344-1.965,0.007-2.625,0.521
    		c-1.555,1.213-2.979,2.629-4.299,4.103c-2.977,3.321-2.951,3.364-5.414-0.291c-0.641-0.951-1.436-1.908-1.706-2.977
    		c-0.579-2.283-1.661-3.89-4.111-2.76c-2.412,1.111-2.678,3.639-1.336,5.48c1.997,2.74,2.468,5.563,2.501,8.812
    		c0.035,3.53,2.584,4.822,5.302,2.703c3.966-3.092,7.705-6.483,11.42-9.881C626.843,1182.446,627.088,1181.16,627.607,1180.076z
    		 M595.837,1333.662c3.748,0.569,5.106-2.12,6.219-4.957c0.472-1.203,1.087-2.775,0.67-3.777c-0.373-0.895-2.172-1.8-3.163-1.634
    		c-0.753,0.126-1.202,1.94-1.83,2.977c-0.489,0.808-1.047,1.573-1.573,2.357c-0.659-0.609-1.649-1.099-1.92-1.849
    		c-1.28-3.543-2.976-3.453-5.484-1.097c-0.793,0.745-2.391,1.294-3.381,1.031c-2.158-0.574-4.201-0.239-4.147,1.885
    		c0.038,1.495,1.911,3.911,3.331,4.218C588.209,1333.603,592.063,1333.443,595.837,1333.662z"/>
    </Link>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M422.438,1410.318c-2.347,0-3.739,0.244-4.995-0.066
    		c-1.313-0.324-3.149-0.91-3.554-1.883c-0.417-1.004,0.318-2.808,1.066-3.913c1.927-2.852,4.303-5.406,6.143-8.307
    		c0.592-0.933,0.606-3.1-0.057-3.774c-1.28-1.301-2.763-0.858-4.185,0.742c-3.854,4.339-8.174,8.27-11.945,12.674
    		c-3.723,4.347-9.194,4.152-13.888,5.91c-0.896,0.335-2.689-1.055-3.665-2.046c-0.263-0.267,0.832-2.088,1.521-3.037
    		c3.89-5.352,8.764-10.077,10.604-16.712c0.23-0.832,0.213-2.488-0.052-2.568c-1.006-0.306-2.592-0.584-3.184-0.035
    		c-2.17,2.012-4.792,4.049-5.848,6.645c-2.332,5.733-5.811,10.08-10.865,13.66c-3.62,2.564-6.479,6.202-9.688,9.349
    		c-1.643,1.611-3.665,4.171-5.672,1.599c-1.13-1.448-1.102-5.113-0.02-6.714c2.577-3.812,6.05-7.03,9.24-10.412
    		c1.941-2.058,4.041-3.964,6.049-5.958c1.668-1.658,2.014-3.544,0.295-5.271c-1.743-1.75-3.574-0.849-4.67,0.772
    		c-2.923,4.32-7.325,7.238-10.102,11.892c-2,3.35-6.449,5.348-10.011,7.629c-4.445,2.846-9.106,5.353-13.662,8.025
    		c-2.399,1.407-6.752,0.896-7.649-0.932c-1.254-2.555,0.662-4.778,3.148-7.195c3.816-3.712,8.578-6.08,12.571-9.584
    		c4.343-3.812,5.754-10.061,11.515-12.408c2.109-0.86,3.038-5.72,1.628-7.594c-0.53-0.705-3.051-1.007-3.53-0.474
    		c-2.809,3.125-5.29,6.544-7.854,9.886c-0.289,0.377-0.342,1.242-0.603,1.285c-5.859,0.974-6.623,7.769-11.87,10.022
    		c-4.176,1.793-7.11,6.357-10.812,9.433c-1.388,1.152-3.351,1.67-5.115,2.295c-1.527,0.54-3.128,0.936-4.729,1.185
    		c-1.536,0.24-3.527,0.853-4.593,0.17c-2.464-1.581-7.336-0.726-6.655-5.879c0.422-3.196-0.607-6.412,1.612-9.595
    		c2.075-2.977,1.817-7.587,5.747-9.382c6.505-2.97,10.702-8.688,16.122-12.925c2.75-2.148,6.244-3.333,9.365-5.021
    		c1.121-0.607,2.237-1.3,3.176-2.151c6.149-5.578,12.355-11.101,18.304-16.888c1.968-1.914,3.259-4.536,4.8-6.873
    		c0.999-1.514,1.726-3.232,2.859-4.627c3.292-4.051,4.703-8.887,6.192-13.735c0.802-2.608,2.688-5.083,2.787-7.669
    		c0.351-9.085,0.133-18.191,0.143-27.289c0.024-21.829,0.259-43.66-0.029-65.484c-0.101-7.61-1.33-15.224-2.335-22.797
    		c-0.602-4.533-2.32-8.961-2.568-13.489c-0.642-11.705-0.774-23.439-1.049-35.164c-0.097-4.163,0.098-8.335-0.042-12.496
    		c-0.171-5.051,0.009-10.038,2.422-14.602c1.802-3.405-1.29-5.429-1.766-8.067c-0.425-2.356,2.722-5.756,1.876-7.567
    		c-3.374-7.231-2.108-14.722-2.498-22.122c-0.158-2.993,0.908-4.083,4.211-3.553c6.197,0.995,12.413,1.552,18.786,0.595
    		c3.654-0.549,7.542,0.469,11.325,0.771c4.259,0.341,8.514,0.759,12.778,0.977c3.938,0.201,7.833,0.015,11.384-2.146
    		c0.574-0.351,1.935-0.329,2.328,0.09c2.952,3.136,5.832,1.41,9.059,0.478c3.146-0.909,6.588-1.055,9.902-1.078
    		c14.997-0.104,29.994-0.07,44.991-0.035c4.983,0.012,4.606,0.191,3.66,5.109c-1.017,5.283-0.937,10.779-1.293,16.185
    		c-0.064,0.985,0.353,2.074,0.068,2.962c-1.393,4.346-3.239,8.566-4.35,12.977c-0.836,3.322-0.653,6.887-1.209,10.297
    		c-0.353,2.167-1.266,4.237-1.851,6.372c-0.339,1.237-0.736,2.515-0.75,3.778c-0.183,16.156-0.235,32.313-0.513,48.468
    		c-0.038,2.178-1.126,4.336-1.721,6.505c-0.346,1.261-0.646,2.534-1.02,3.786c-0.69,2.311-1.414,4.612-2.133,6.914
    		c-0.337,1.082-0.713,2.153-1.043,3.237c-0.798,2.614-1.096,5.53-2.479,7.781c-2.163,3.521-2.986,7.052-3.409,11.151
    		c-0.404,3.929-2.578,7.714-4.255,11.435c-0.288,0.641-2.018,0.996-3.021,0.892c-6.973-0.726-13.728-0.167-20.729,0.916
    		c-7.189,1.112-14.799-0.501-22.225-0.905c-3.302-0.18-6.624-0.15-9.899-0.533c-2.246-0.262-4.424-1.088-6.64-1.628
    		c-1.896-0.463-3.839-0.76-5.697-1.338c-4.465-1.39-8.879-2.946-13.341-4.345c-2.106-0.66-4.262-1.389-6.427-1.512
    		c-0.787-0.045-2.385,1.624-2.336,2.434c0.07,1.158,1.072,2.862,2.094,3.26c5.308,2.065,10.718,3.888,16.158,5.579
    		c1.872,0.582,3.959,0.429,5.908,0.808c6.312,1.229,12.419,3.304,19.049,3.008c8.12-0.363,16.271-0.039,24.407-0.078
    		c4.326-0.021,8.666-0.014,12.973-0.363c1.795-0.146,3.523-1.168,5.269-1.827c0.702-0.265,1.368-0.623,2.051-0.939
    		c0.323,0.863,0.69,1.714,0.944,2.598c0.077,0.271-0.061,0.718-0.262,0.937c-3.86,4.214-2.443,9.392-2.606,14.266
    		c-0.133,3.978-1.116,7.662-2.185,11.518c-1.328,4.793,0.033,10.343,0.316,15.561c0.059,1.088,1.054,2.511,0.643,3.176
    		c-2.684,4.337-1.692,9.098-1.742,13.665c-0.161,14.994,0.015,29.991-0.152,44.985c-0.023,2.132-1.201,4.259-1.894,6.372
    		c-0.251,0.765-0.849,1.458-0.943,2.226c-2.146,17.42-12.048,30.429-24.903,41.069
    		C434.518,1407.467,428.635,1411.608,422.438,1410.318z M414.699,1137.433c-1.998,0-4.006,0.122-5.989-0.044
    		c-1.255-0.105-3.269-0.309-3.571-1.038c-1.343-3.229-2.333-6.632-3.12-10.048c-0.208-0.904,0.757-2.082,1.191-3.132
    		c2.005-4.838,6.244-3.46,9.897-3.453c8.858,0.017,8.858,0.071,9.552-8.426c0.014-0.166,0.06-0.328,0.077-0.494
    		c0.274-2.668-0.944-3.225-3.31-2.472c-1.957,0.623-3.989,1.005-5.962,1.585c-0.888,0.261-1.887,0.556-2.533,1.159
    		c-3.532,3.295-5.33,3.442-8.903,0.988c-1.223-0.839-3.505-1.905-4.171-1.395c-1.191,0.915-1.784,2.893-2.133,4.531
    		c-0.301,1.411-0.478,3.402,0.274,4.384c2.762,3.604,1.486,6.483,0.231,10.404c-1.415,4.418-3.304,4.964-7.033,4.457
    		c-2.84-0.386-6.584-0.619-6.612,3.186c-0.027,3.715,3.774,2.605,6.288,2.874c3.935,0.422,7.857,0.968,11.77,1.566
    		c2.077,0.317,4.101,1.051,6.182,1.259c6.3,0.628,13.008-1.825,18.811,2.63c0.576,0.441,1.608,0.386,2.432,0.395
    		c11.635,0.123,23.271,0.15,34.904,0.383c2.078,0.042,4.334,0.525,6.173,1.462c4.695,2.394,9.306,0.528,13.927,0.109
    		c0.978-0.089,2.364-1.162,2.645-2.066c0.354-1.144,0.133-2.83-0.537-3.802c-0.484-0.704-2.771-1.202-3.11-0.808
    		c-2.668,3.097-6.403,0.948-9.428,2.113c-0.562,0.217-2.076-0.669-2.298-1.331c-0.212-0.632,0.492-2.144,1.135-2.396
    		c4.39-1.72,4.371-5.316,4.357-9.114c-0.039-10.023-0.879-10.594-9.418-5.593c-1.087,0.638-2.279,2.198-2.258,3.309
    		c0.021,1.059,1.549,2.101,2.443,3.126c0.77,0.882,2.362,1.94,2.199,2.545c-0.29,1.072-1.511,2.411-2.564,2.647
    		c-2.063,0.464-4.282,0.331-6.434,0.289c-2.278-0.046-4.355-0.152-4.812,2.953c-0.1,0.685-2.021,1.835-2.254,1.657
    		c-3.624-2.768-7.733-1.348-11.618-1.461c-2.711-0.078-3.881-0.761-3.543-3.834c0.355-3.237,0.173-6.582-0.195-9.833
    		c-0.272-2.411-0.913-5.124,1.564-6.194c1.624-0.7,4.229-0.24,5.95,0.605c5.502,2.707,7.622,2.569,11.887-1.708
    		c0.823-0.825,1.604-1.703,2.495-2.446c2.074-1.729,1.788-3.319-0.112-4.947c-2.101-1.801-3.518-1.17-4.694,1.107
    		c-0.528,1.021-1.477,1.819-2.038,2.828c-1.214,2.178-2.961,2.382-4.454,0.758c-5.095-5.543-11.487-3.516-17.553-3.155
    		c-0.578,0.034-1.744,2.39-1.47,2.73c4.538,5.658,1.811,12.169,2.175,18.252c0.178,2.974-4.007,6.522-5.204,5.217
    		C422.612,1136.141,418.518,1137.66,414.699,1137.433z M458.123,1206.701c0.044-0.311,0.088-0.621,0.131-0.932
    		c4.664,0,9.329,0.077,13.99-0.028c2.906-0.065,4.307-2.025,3.543-4.608c-0.767-2.592-4.902-3.807-7.455-2.67
    		c-1.812,0.807-4.147,1.348-5.948,0.871c-0.87-0.23-1.382-3.062-1.47-4.746c-0.207-3.986-0.063-7.991-0.062-11.989
    		c0.001-5.759,0.001-5.759,5.4-7.378c3.096-0.928,3.831-0.309,3.673,2.912c-0.217,4.43-0.469,8.864-0.456,13.295
    		c0.004,1.258,0.878,2.513,1.354,3.769c1.575-1.083,3.363-1.968,4.634-3.335c0.612-0.659,0.31-2.226,0.314-3.382
    		c0.021-5.663,0.118-11.33-0.052-16.989c-0.071-2.357-0.74-4.696-1.137-7.044c-1.938,1.152-4.038,2.104-5.771,3.509
    		c-2.022,1.639-3.612,2.827-5.845,0.239c-1.63-1.889-3.357-1.337-5.098,0.396c-1.872,1.863-2.953,3.724-2.813,6.518
    		c0.24,4.818,0.068,9.658,0.067,14.488c0,11.505-0.011,11.57-11.357,9.674c-3.502-0.585-7.087-2.736-10.357-2.262
    		c-10.494,1.52-20.197-2.691-30.362-3.302c-4.814-0.288-6.396-4.004-5.051-8.857c0.647-2.337,0.625-4.923,0.541-7.384
    		c-0.146-4.319,1.253-6.312,5.561-6.006c3.119,0.221,3.369-1.457,3.437-3.536c0.069-2.127-0.452-4.373-3.165-4.012
    		c-2.54,0.338-4.998,1.376-7.46,2.2c-7.341,2.461-9.051,6.996-5.765,13.977c1.003,2.132,1.305,4.773,1.268,7.172
    		c-0.059,3.662-1.55,4.207-4.867,2.475c-1.852-0.967-3.859-2.029-5.858-2.168c-1.126-0.078-2.378,1.63-3.572,2.534
    		c0.958,1.189,1.851,3.329,2.886,3.4c3.986,0.272,7.455,1.891,11.136,2.925c5.351,1.503,10.568,5.002,16.654,3.434
    		c0.689-0.178,1.764,0.166,2.333,0.651c2.951,2.517,6.427,2.325,9.942,2.255c2.492-0.05,5.011-0.075,7.479,0.218
    		C438.41,1204.153,448.265,1205.451,458.123,1206.701z M395.803,1342.052c0,0.077-0.001,0.154-0.001,0.231
    		c4.161,0,8.337-0.217,12.481,0.051c6.301,0.407,10.585,4.863,7.867,8.655c-4.265,5.948-1.002,9.504,2.111,13.673
    		c0.486,0.652,0.62,1.59,1.155,2.179c0.728,0.801,1.592,1.592,2.559,2.041c5.693,2.648,11.428,5.211,17.162,7.772
    		c3.469,1.55,7.026,2.338,10.312-0.244c1.026-0.807,1.5-2.316,2.228-3.505c-1.307-0.427-2.594-0.935-3.926-1.261
    		c-2.172-0.531-4.379-0.913-6.559-1.414c-1.733-0.398-3.515-0.711-5.158-1.358c-3.699-1.457-7.574-2.696-10.923-4.762
    		c-4.565-2.817-2.841-7.795-2.804-11.92c0.053-5.812,5.288-8.704,10.569-6.241c1.627,0.758,3.291,1.906,4.978,1.981
    		c5.82,0.258,11.66,0.228,17.487,0.087c2.62-0.062,5.47-1.148,5.183-4.141c-0.278-2.912-3.213-2.077-5.314-1.936
    		c-0.166,0.011-0.331,0.063-0.495,0.061c-6.657-0.094-13.319-0.081-19.966-0.391c-1.184-0.055-2.495-1.285-3.352-2.305
    		c-0.304-0.361,0.526-2.471,0.914-2.494c3.814-0.223,6.002-4.75,10.408-3.699c1.166,0.278,3.935-1.302,3.996-2.169
    		c0.186-2.609-1.89-3.665-4.382-3.394c-1.985,0.217-4.728,0.94-4.628-1.846c0.099-2.772,3.047-1.647,4.894-1.684
    		c1.801-0.035,3.482-0.241,3.512-2.526c0.03-2.282-1.395-2.944-3.527-3.281c-4.972-0.786-8.495,1.345-11.852,4.69
    		c-1.811,1.806-3.028,2.862-0.549,5.083c1.784,1.6,1.507,3.563-0.637,4.959c-2.278,1.483-4.519,3.041-6.888,4.364
    		c-1.489,0.832-3.179,1.781-4.792,1.807c-2.447,0.039-5.104-0.22-7.322-1.169c-5.431-2.322-11.103-1.483-16.665-1.757
    		c-2.829-0.139-5.477,0.436-7.844-1.98c-0.781-0.799-3.235-0.808-4.383-0.183c-1.449,0.79-3.235,2.606-3.22,3.962
    		c0.015,1.268,2.043,2.734,3.473,3.682c0.846,0.561,2.247,0.354,3.4,0.366C388.805,1342.076,392.304,1342.052,395.803,1342.052z
    		 M451.013,1304.066c0.017-0.145,0.033-0.289,0.05-0.434c2.83,0,5.699,0.276,8.471-0.122c1.293-0.186,3.137-1.483,3.435-2.618
    		c0.612-2.33-1.402-3.146-3.375-3.2c-3.657-0.102-7.321-0.058-10.979,0.019c-5.035,0.105-5.459-0.247-5.566-5.149
    		c-0.019-0.832-0.018-1.665-0.015-2.497c0.024-6.821,0.338-7.322,6.941-7.143c2.824,0.077,4.668-1.016,6.533-2.826
    		c1.46-1.418,1.355-2.544-0.087-3.699c-1.275-1.021-2.026-3.24-4.458-1.446c-4.795,3.536-4.866,3.439-9.913,0.239
    		c-3.455-2.189-4.752-1.578-4.85,2.479c-0.108,4.49-0.071,8.986-0.019,13.479c0.028,2.354-0.896,4.133-3.312,4.092
    		c-4.629-0.076-9.294-0.256-13.859-0.95c-3.018-0.46-3.951-2.858-2.927-5.694c0.832-2.303,1.673-4.604,2.586-6.876
    		c0.232-0.578,0.644-1.287,1.158-1.516c2.736-1.216,5.473-2.492,8.324-3.37c2.85-0.878,1.998-3.151,2.13-4.937
    		c0.087-1.169-0.607-2.396-0.952-3.597c-1.22,0.904-2.606,1.652-3.628,2.742c-2.693,2.872-3.035,2.928-5.791-0.08
    		c-2.974-3.245-4.344-3.219-6.471,0.565c-0.758,1.348-0.835,3.134-1.008,4.742c-0.407,3.775-0.233,7.677-1.128,11.321
    		c-1.185,4.828-2.896,5.174-7.095,2.619c-1.189-0.725-2.726-1.285-4.084-1.246c-3.514,0.103-6.729,0.369-9.69-2.548
    		c-0.939-0.926-5.107-0.361-6.009,0.851c-1.662,2.236,0.668,3.939,2.842,4.588c3.563,1.062,6.937,2.524,10.703,3.291
    		c5.107,1.038,9.983,3.154,15.393,2.707c0.435-0.036,1.085,0.225,1.331,0.566c2.567,3.555,6.335,2.104,9.673,2.347
    		c1.306,0.095,3.04-0.673,3.878-0.071c3.273,2.349,6.625,2.484,10.519,2.156C443.453,1302.539,447.259,1303.605,451.013,1304.066z
    		 M395.637,1371.949c4.273-0.367,7.411,0.714,9.953-2.383c2.823-3.439,2.016-7.173,1.77-10.883
    		c-0.045-0.672-1.717-1.799-2.539-1.717c-1.059,0.105-2.262,1.036-2.939,1.954c-0.507,0.687-0.419,1.923-0.354,2.898
    		c0.203,3.051-1.339,4.357-4.184,4.144c-5.945-0.447-11.908-0.829-17.809-1.641c-2.517-0.346-5.188-1.282-7.247-2.725
    		c-1.811-1.269-3.735-2.696-5.134-0.993c-1.121,1.366-1.05,4.001-0.918,6.035c0.168,2.596,2.445,2.077,4.235,2.21
    		c4.281,0.32,8.57,0.613,12.827,1.152C387.83,1370.575,392.329,1371.418,395.637,1371.949z M397.766,1327.926
    		c-1.234-0.332-1.887-0.728-2.17-0.547c-1.551,0.994-3.011,2.129-4.502,3.215c1.299,1.008,2.484,2.596,3.925,2.877
    		c1.849,0.361,3.91-0.272,5.864-0.574c6.592-1.017,11.4-5.633,16.896-8.768c1.982-1.132,3.014-4.256,4-6.668
    		c0.487-1.193,0.372-3.705-0.323-4.093c-1.581-0.882-3.75-1.238-5.557-0.971c-0.958,0.142-1.947,1.925-2.405,3.159
    		c-0.71,1.912-0.315,4.869-1.57,5.812c-2.522,1.896-3.738-2.095-5.662-2.412c-2.128-0.351-5.165,0.123-6.62,1.495
    		c-2.032,1.916,0.073,3.275,2.099,4.225c1.965,0.922,1.385,2.064-0.331,2.601C400.016,1327.711,398.516,1327.801,397.766,1327.926z
    		 M398.453,1214.243c-2.829-0.044-4.117,1.234-3.336,3.743c1.661,5.333,3.335,10.683,5.429,15.854
    		c1.049,2.59,5.594,2.563,6.558,0.064c1.887-4.89,3.623-9.752,8.094-13.069c0.521-0.387,0.43-2.211-0.004-3.042
    		c-0.259-0.494-2.053-0.801-2.613-0.41c-1.731,1.205-3.286,2.704-4.753,4.239c-1.93,2.019-4.22,2.356-5.327,0.392
    		c-0.608-1.079-0.706-2.534-0.746-3.828C401.68,1215.794,400.643,1214.438,398.453,1214.243z M391.817,1322.167
    		c1.402-0.979,2.918-1.831,4.189-2.959c6.272-5.564,6.25-5.59,2.986-12.962c-1.461-3.301-3.464-3.921-6.003-1.605
    		c-2.318,2.114-4.445,4.445-6.582,6.75c-1.331,1.436-3.021,2.869-1.268,5.119C386.849,1318.702,387.156,1322.606,391.817,1322.167z
    		 M419.685,1227.758c-0.114,0-0.229-0.001-0.343-0.002c0,3.148-0.009,6.296,0.003,9.443c0.007,1.945,0.303,3.764,2.775,3.902
    		c2.427,0.136,3.101-1.406,3.125-3.5c0.021-1.812,0.047-3.625,0.151-5.433c0.074-1.292-0.209-3.113,0.513-3.774
    		c3.516-3.219,1.143-7.479,2.109-11.135c0.341-1.287-2.113-3.401-3.482-4.971c-0.243-0.279-1.8,0.012-2.108,0.47
    		c-1.067,1.59-2.395,3.3-2.625,5.093C419.385,1221.105,419.685,1224.451,419.685,1227.758z M395.708,1273.952
    		c-0.065,0.002-0.132,0.003-0.197,0.005c0.126,2.642-0.138,5.389,0.533,7.885c0.371,1.381,2.306,2.341,3.536,3.491
    		c0.724-1.272,1.663-2.475,2.102-3.838c0.336-1.045,0.062-2.295,0.017-3.452c-0.095-2.368,0.532-3.483,3.233-4.48
    		c2.808-1.037,4.952-4.035,7.148-6.397c0.367-0.395-0.176-2.292-0.837-2.821c-0.837-0.67-2.337-1.072-3.343-0.806
    		c-0.966,0.255-1.78,1.429-2.46,2.348c-1.544,2.09-2.821,2.522-4.523,0.043c-0.588-0.855-2.337-1.712-3.132-1.419
    		c-0.934,0.344-1.82,1.878-1.987,3.005C395.486,1269.619,395.708,1271.802,395.708,1273.952z M442.897,1173.974
    		c0.232-2.789-2.54-7.273-3.883-6.473c-1.936,1.154-3.916,3.193-4.563,5.265c-0.61,1.953-0.109,4.786,0.965,6.604
    		c1.499,2.538,2.006,4.62,0.897,7.418c-1.068,2.696,0.776,6.876,2.047,6.169c1.902-1.059,3.194-3.279,4.596-5.12
    		c0.306-0.402-0.053-1.288-0.054-1.951C442.893,1181.915,442.897,1177.944,442.897,1173.974z M454.603,1235.792
    		c6.711-0.586,10.044-4.297,9.128-10.979c-0.212-1.548-1.439-3.729-2.702-4.166c-1.562-0.542-3.903-0.014-5.443,0.866
    		c-1.808,1.033-3.045,3.021-4.655,4.451c-2.559,2.272-2.37,5.288-1.322,7.786C450.1,1234.924,452.866,1235.142,454.603,1235.792z
    		 M416.554,1187.977c4.221-0.006,11.119-5.538,11.647-8.226c0.505-2.569-3.541-7.39-5.416-6.253
    		c-3.185,1.932-6.282,4.201-8.835,6.882c-0.994,1.043-0.978,3.803-0.372,5.376C414.067,1187.026,416.134,1187.689,416.554,1187.977z
    		 M387.794,1356.998c0-0.035,0-0.07,0-0.106c1.818,0,3.705,0.29,5.429-0.113c0.961-0.226,2.382-1.687,2.296-2.441
    		c-0.133-1.156-1.303-3.028-2.182-3.117c-3.431-0.348-6.96-0.398-10.365,0.071c-1.088,0.15-1.905,2.257-2.848,3.469
    		c1.069,0.746,2.047,1.829,3.231,2.15C384.741,1357.286,386.307,1356.998,387.794,1356.998z"/>
    <Link to="/archive/012" className={activeIndex === 12 ? "active" : "inactive"} onClick={() => handleOnClick(12)}>
    	<path className="clickable" fillRule="evenodd" clipRule="evenodd" fill="black" d="M543.546,719.102c-7.995-0.003-16.024-0.47-23.975,0.125
    		c-8.388,0.627-16.693-0.479-25.05-0.17c-8.316,0.308-16.658,0.229-24.979-0.018c-2.694-0.08-5.354-1.242-8.036-1.879
    		c-1.581-0.375-3.17-0.938-4.768-0.983c-4.493-0.13-8.992-0.011-13.489-0.036c-4.107-0.022-8.313,0.701-11.908-2.392
    		c-0.839-0.721-2.553-0.615-3.853-0.57c-3.723,0.129-7.31-0.095-10.528-2.301c-0.644-0.441-1.584-0.738-2.348-0.667
    		c-5.458,0.512-9.348-2.924-13.75-5.188c-6.717-3.453-13.576-6.63-20.37-9.936c-0.729-0.354-1.678-0.611-2.084-1.212
    		c-2.546-3.76-5.32-7.272-6.839-11.788c-1.509-4.484-0.55-9.175,0.979-12.291c2.618-5.336,4.567-10.636,6.431-16.252
    		c1.725-5.201,2.573-10.307,1.278-15.031c-2.202-8.039-2.031-16.713-6.292-24.206c-0.561-0.985-0.896-2.169-1.056-3.301
    		c-0.551-3.901-2.017-7.267-4.854-10.111c-0.701-0.702-1.155-2.798-0.701-3.27c1.003-1.044,3.253-2.417,3.948-1.982
    		c4.844,3.028,10.18,0.929,15.157,1.914c2.409,0.477,4.767,1.301,7.191,1.587c4.907,0.578,9.845,0.895,14.77,1.322
    		c1.465,0.127,3.373-0.279,4.312,0.491c3.494,2.871,7.515,2.039,11.441,2.425c6.577,0.646,13.094,1.91,19.671,2.545
    		c2.896,0.28,5.883-0.577,8.82-0.512c6.911,0.154,13.821,0.5,20.726,0.882c2.236,0.123,4.116,0.158,5.751-1.932
    		c1.721-2.2,4.027-1.638,5.478,0.424c2.996,4.262,6.784,5.125,11.752,4.201c3.114-0.579,6.519,0.562,9.714,0.215
    		c9.521-1.037,18.747-3.947,28.592-3.084c9.751,0.854,19.606,0.826,29.406,0.647c4.38-0.08,8.453-0.234,12.459,2
    		c1.438,0.802,3.581,0.414,5.403,0.438c12.481,0.163,24.962,0.296,37.443,0.423c0.826,0.008,1.665-0.231,2.479-0.156
    		c1.552,0.145,4.194,0.061,4.422,0.735c0.638,1.884,0.772,4.264,0.178,6.156c-0.723,2.302-2.39,4.309-3.654,6.438
    		c-0.423,0.712-1.087,1.36-1.266,2.125c-1.259,5.386-2.418,10.796-3.611,16.197c-0.358,1.618-0.405,3.4-1.144,4.822
    		c-3.605,6.931-3.905,14.287-1.382,21.292c1.753,4.863,2.305,9.989,5.167,14.779c3.87,6.478,1.577,17.508-6.762,20.372
    		c-3.903,1.341-7.084,4.651-10.862,6.525c-3.587,1.779-7.522,2.855-11.312,4.224c-1.069,0.387-2.183,0.658-3.232,1.09
    		c-0.763,0.312-1.408,1.017-2.183,1.178c-6.223,1.291-12.427,2.771-18.714,3.624c-3.924,0.532-7.98,0.076-11.978,0.071
    		S547.543,719.104,543.546,719.102z M470.137,681.203c-0.356,0.072-0.714,0.145-1.07,0.217c1.03,4.583,2.187,9.143,3.062,13.755
    		c1.383,7.293,5.18,11.888,12.988,12.372c0.477,0.029,1.129,0.148,1.378,0.474c2.104,2.743,5.095,2.258,7.826,1.946
    		c4.23-0.483,8.396-1.523,12.621-2.077c4.89-0.642,9.97-3.028,14.432,1.656c0.668,0.7,3.457,0.412,4.392-0.41
    		c1.486-1.308,2.201-3.474,3.314-5.23c0.545-0.858,1.661-2.389,1.829-2.3c3.576,1.877,8-1.74,11.404,1.84
    		c0.578,0.607,2.926,0.205,4.002-0.463c2.667-1.657,4.376-4.085,3.567-7.503c-0.324-1.37-0.839-2.694-1.267-4.04
    		c-1.287,0.571-2.597,1.096-3.854,1.725c-2.542,1.27-5.014,2.695-7.621,3.805c-0.449,0.191-2.04-0.831-2.064-1.343
    		c-0.204-4.245-3.393-3.94-5.766-3.276c-2.162,0.604-4.365,2.227-5.804,4.004c-2.654,3.279-5.265,5.673-9.96,4.998
    		c-2.534-0.363-5.827-0.563-7.734,0.717c-8.067,5.418-15.265,0.001-22.786-1.151c-0.378-0.058-0.78-0.618-0.985-1.036
    		c-3.824-7.798-8.069-15.484-6.463-24.717c0.226-1.299,0.689-3.066,0.072-3.891c-1.164-1.557-3.033-2.584-4.61-3.832
    		c-0.496,1.438-1.379,2.869-1.408,4.316C469.568,674.901,469.942,678.054,470.137,681.203z M439.937,641.735
    		c5.282,1.542,7.795-2.7,10.668-5.623c1.098-1.116,1.2-3.804,0.865-5.609c-0.66-3.55-3.462-4.144-6.09-1.575
    		c-0.831,0.813-1.493,2.021-2.482,2.403c-1.86,0.721-3.902,0.972-5.869,1.418c-0.018-1.468,0.252-3.016-0.126-4.385
    		c-0.483-1.753-1.193-3.654-2.417-4.884c-0.468-0.471-2.713,0.562-3.988,1.22c-1.895,0.979-3.702,2.153-5.445,3.388
    		c-2.686,1.902-4.612,1.665-5.601-1.721c-0.546-1.867-1.794-3.201-3.6-2.265c-1.168,0.606-2.356,2.363-2.422,3.66
    		c-0.138,2.771,0.157,5.646,0.8,8.354c1.26,5.303-0.962,8.649-6.238,8.84c-0.662,0.024-1.512-0.151-1.953,0.186
    		c-3.271,2.499-6.052,1.942-9.757,0.142c-5.282-2.567-8.251-8.341-5.549-13.617c0.601-1.172,1.843-2.305,1.818-3.434
    		c-0.032-1.442-0.703-3.349-1.771-4.11c-0.635-0.453-2.938,0.557-3.851,1.517c-5.127,5.399-3.295,11.724-1.593,17.7
    		c1.672,5.874,7.324,7.864,12.224,9.8c2.008,0.793,5.097-1.26,7.726-1.884c1.273-0.302,2.633-0.477,3.931-0.389
    		c2.965,0.201,5.142-0.954,6.767-3.364c2.659-3.943,5.366-7.856,8.006-11.813c1.874-2.811,3.08-2.841,5.528-0.05
    		C432.142,638.631,434.438,643.205,439.937,641.735z M481.998,650.666c3.981,1.11,7.39-0.88,11.183-2.625
    		c5.33-2.453,8.261-6.867,6.838-12.76c-0.688-2.854,3.812-8.385,6.628-7.93c0.688,0.111,1.51,1.229,1.716,2.027
    		c0.279,1.084-0.333,2.464,0.093,3.428c0.517,1.171,2.012,3.039,2.569,2.874c2.933-0.871,6.303-1.695,8.312-3.729
    		c1.31-1.325,0.637-4.688,0.612-7.135c-0.002-0.246-1.733-0.803-2.516-0.613c-0.627,0.152-0.899,1.507-1.565,1.815
    		c-1.067,0.495-2.331,0.567-3.512,0.817c0.229-1.104,0.105-2.535,0.762-3.248c1.402-1.525,2.513-2.954,0.634-4.622
    		c-1.794-1.591-3.795-1.212-5.238,0.619c-2.5,3.17-6.026,4.026-9.688,4.36c-1.918,0.175-3.954-0.729-5.838-0.457
    		c-1.74,0.252-4.103,0.992-4.838,2.286c-1.155,2.031,0.211,3.637,2.692,4.189c0.95,0.212,2.14,1.102,2.49,1.975
    		c2.504,6.243-1.633,12.684-8.365,13.15c-1.988,0.138-4.003,0.173-5.987,0.02c-8.329-0.647-11.188-5.699-7.357-12.839
    		c0.464-0.866,1.228-1.918,1.048-2.699c-0.261-1.138-1.041-2.487-2.002-3.021c-0.688-0.381-2.773,0.178-2.97,0.75
    		c-1.359,3.969-2.627,8.003-3.454,12.11c-0.847,4.202,1.804,6.54,5.101,8.719C473.217,650.688,477.28,651.21,481.998,650.666z
    		 M409.364,689.652c0.343,0.073,0.686,0.147,1.029,0.222c-0.686,5.396-0.641,10.441,5.733,12.486
    		c0.138,0.045,0.208,0.301,0.347,0.345c4.397,1.422,8.797,2.838,13.208,4.218c0.274,0.086,0.702-0.035,0.956-0.21
    		c4.431-3.055,9.434-2.636,14.429-2.518c3.575,0.085,10.089-4.641,10.339-8.025c0.293-3.963,4.729-8.134-0.285-12.103
    		c-0.585-0.463-0.141-2.227-0.17-3.388c-0.034-1.328,0.44-3.038-0.211-3.896c-1.061-1.398-2.894-3.162-4.331-3.102
    		c-2.235,0.093-2.962,2.155-2.252,4.542c0.51,1.709,0.963,3.582,0.784,5.314c-0.243,2.36-1.092,4.595,1.911,5.889
    		c0.406,0.176,0.751,1.796,0.472,2.061c-2.799,2.655-4.774,6.585-9.314,6.74c-3.122,0.106-6.275-0.398-9.019,1.847
    		c-0.538,0.44-1.883,0.012-2.822-0.179c-2.375-0.481-4.708-1.247-7.102-1.544c-2.898-0.358-6.583-3.01-6.468-5.76
    		c0.217-5.161-2.128-11.177,4.225-14.739c0.707-0.396,1.153-2.597,0.703-3.359c-0.498-0.843-2.349-1.594-3.287-1.312
    		c-1.65,0.496-3.849,1.479-4.416,2.842C411.993,680.424,410.802,685.089,409.364,689.652z M573.78,635.023
    		c0.098-1.026,0.509-2.704,0.324-4.313c-0.133-1.162-0.774-2.686-1.685-3.258c-2.54-1.597-8.331,0.501-9.919,3.515
    		c-1.745,3.312,0.317,4.864,3.111,5.922c0.152,0.058,0.356,0.143,0.411,0.269c0.484,1.126,0.94,2.264,1.405,3.398
    		c-1.271,0.488-2.851,1.69-3.743,1.304c-1.437-0.622-2.318-2.416-3.624-3.482c-1.3-1.062-2.792-2.403-4.28-2.512
    		c-0.831-0.061-2.405,2.029-2.646,3.335c-0.92,4.99-0.771,5-6.255,5.761c-0.796,0.111-1.827,0.267-2.28,0.8
    		c-2.021,2.375-4.673,2.174-7.344,2.152c-3.491-0.028-6.986-0.06-10.477,0.03c-5.637,0.146-11.215,0.281-13.337-6.627
    		c-0.089-0.291-0.368-0.625-0.642-0.738c-1.025-0.428-2.251-1.271-3.08-0.994c-0.7,0.233-1.489,1.922-1.302,2.756
    		c0.48,2.146,0.922,4.787,2.388,6.125c3.35,3.057,7.221,5.535,12.191,5.342c7.575-0.293,15.341,1.114,22.484-2.743
    		c0.413-0.224,0.999-0.315,1.46-0.229c3.466,0.646,6.003-1.01,8.522-3.084c1.623-1.336,3.313-2.538,5.855-1.385
    		c7.343,3.332,13.21-0.751,12.804-8.727C574.091,636.977,573.954,636.319,573.78,635.023z M580.672,674.138
    		c0.036,0.459,0.072,0.918,0.108,1.377c5.859-1.238,11.731-2.419,17.56-3.789c0.695-0.164,1.615-1.373,1.589-2.064
    		c-0.029-0.76-0.927-1.954-1.638-2.115c-1.194-0.271-2.538,0.19-3.822,0.231c-7.598,0.238-15.195,0.47-22.794,0.623
    		c-0.775,0.016-1.61-0.402-2.334-0.778c-8.227-4.274-15.061-3.09-21.002,3.736c-0.493,0.567-1.143,1.75-0.923,2.035
    		c0.518,0.674,1.483,1.292,2.324,1.376c2.703,0.272,5.189-0.039,7.053-2.569c1.568-2.132,3.32-2.598,6.379-1.538
    		C568.752,672.597,574.003,676.796,580.672,674.138z M584.084,707.119c0.004,0.044,0.008,0.089,0.011,0.133
    		c3.134-0.2,7.02,0.69,9.238-0.838c4.057-2.794,8.126-6.278,7.113-12.588c-0.669-4.169-3.145-7.891-6.226-7.617
    		c-2.746,0.244-6.416,5.405-6.223,8.65c0.12,2.006,0.853,3.104,3.097,2.828c0.769-0.095,1.601,0.33,2.402,0.519
    		c-0.481,0.832-0.776,2.095-1.486,2.404c-1.294,0.565-2.833,0.749-4.273,0.766c-3.322,0.038-6.647-0.081-9.969-0.212
    		c-2.146-0.084-3.569,0.566-3.683,2.967c-0.12,2.541,1.402,3.053,3.514,3C579.76,707.077,581.923,707.119,584.084,707.119z
    		 M460.777,637.568c0-1.326-0.057-2.656,0.014-3.979c0.098-1.854-0.262-3.42-2.453-3.562c-2.456-0.16-3.059,1.488-3.09,3.547
    		c-0.039,2.488,0.225,5.036-0.238,7.443c-0.29,1.503-1.365,3.495-2.629,4.062c-6.615,2.968-12.918,7.431-20.844,5.5
    		c-0.158-0.038-0.368-0.091-0.49-0.022c-0.82,0.46-2.044,0.787-2.312,1.478c-0.253,0.651,0.276,1.908,0.856,2.53
    		c2.116,2.271,4.628,2.812,7.683,1.747c3.088-1.078,6.296-1.882,9.509-2.521C454.183,652.317,460.661,644.869,460.777,637.568z
    		 M585.354,641.578c0.003,3.879,2.99,8.018,5.211,7.386c4.529-1.288,9.228-1.933,13.074-5.384c6.151-5.52,6.419-6.226-0.226-11.965
    		c-1.118-0.966-3.691-1.64-4.718-1.014c-1.825,1.113-3.148,3.232-4.224,5.184c-0.147,0.269,2.325,1.977,3.579,3.025
    		c0.574,0.48,1.579,0.973,1.585,1.47c0.007,0.57-0.876,1.648-1.372,1.648c-3.189,0-6.587,1.269-9.435-1.397
    		c-0.6-0.561-1.873-1.136-2.395-0.862C585.762,640.021,585.533,641.222,585.354,641.578z M565.779,699.332
    		c-0.253,0.089-0.506,0.177-0.759,0.265c0,1.821-0.24,3.69,0.103,5.445c0.168,0.865,1.466,2.175,2.157,2.118
    		c1.045-0.086,2.823-1.138,2.871-1.87c0.316-4.939,1.471-10.312-0.022-14.742c-1.466-4.353-2.592-8.284-2.092-12.831
    		c0.093-0.837-1.558-2.528-2.54-2.642c-1.064-0.123-3.256,1.098-3.285,1.802c-0.172,4.102-0.222,8.282,0.413,12.321
    		C563.17,692.656,564.69,695.96,565.779,699.332z M571.798,660.197c2.96-0.48,5.232-0.692,7.416-1.249
    		c1.979-0.505,3.897-1.308,5.776-2.131c1.228-0.537,2.811-1.094,3.352-2.111c0.446-0.841-0.183-3.289-0.716-3.415
    		c-1.39-0.328-3.025,0.084-4.495,0.462c-2.292,0.591-4.495,1.838-6.795,2.007c-3.263,0.241-6.584-0.427-9.869-0.334
    		c-1.428,0.04-2.829,1.007-4.242,1.553c0.866,1.263,1.469,3.156,2.655,3.653C567.205,659.604,569.856,659.799,571.798,660.197z
    		 M536.752,629.963c0,0.016,0,0.031,0,0.047c1.493,0,3.172,0.43,4.433-0.112c1.474-0.634,3.315-1.934,3.649-3.28
    		c0.354-1.423-0.684-3.362-1.577-4.785c-0.186-0.296-2.597,0.408-3.644,1.137c-1.965,1.368-3.146,0.43-4.85-0.574
    		c-1.232-0.726-3.515-0.851-4.708-0.164c-1.487,0.856-2.337,2.822-3.462,4.309c1.562,1.142,2.979,2.697,4.727,3.304
    		C532.948,630.407,534.928,629.963,536.752,629.963z M422.394,661.864c-0.049-4.145-1.994-5.022-11.534-5.205
    		c-2.299-0.044-4.645,2.3-4.783,4.794c-0.018,0.324-0.034,0.683,0.078,0.976c0.64,1.665,1.741,3.39,3.571,2.048
    		c2.174-1.593,3.244-0.632,5.063,0.552C418.51,667.45,422.438,665.626,422.394,661.864z M487.646,680.379
    		c0.32-0.164,0.64-0.329,0.959-0.494c-1.294-2.488-2.481-5.045-3.992-7.395c-0.233-0.363-2.223-0.125-2.924,0.433
    		c-2.774,2.212-2.967,5.389-2.442,8.577c0.173,1.05,0.846,2.278,1.681,2.895c1.201,0.886,3.019,2.05,4.098,1.686
    		c1.159-0.392,1.856-2.365,2.577-3.742C487.874,681.819,487.646,681.04,487.646,680.379z M484.292,666.402
    		c3.626-0.2,6.278-0.614,6.846-4.689c0.095-0.679,1.237-1.16,1.504-1.879c0.296-0.795,0.51-2.076,0.087-2.564
    		c-0.499-0.578-2.136-1.031-2.555-0.679c-2.339,1.971-4.475,3.65-7.241,0.617c-0.176-0.192-1.8,0.366-2.021,0.887
    		c-0.796,1.865-2.138,4.152-1.611,5.723C479.728,665.094,482.538,665.571,484.292,666.402z M522.599,673.701
    		c-1.626-0.815-2.99-1.85-4.507-2.173c-1.942-0.414-3.562,0.286-3.944,2.662c-0.386,2.4-1.144,4.757-1.351,7.166
    		c-0.122,1.428,0.508,2.921,0.8,4.385c1.473-0.301,3.796-0.131,4.268-0.985C519.658,681.515,520.854,677.941,522.599,673.701z
    		 M579.984,617.531c-0.25-0.457-0.466-1.339-1.027-1.798c-2.515-2.055-12.868-0.172-14.207,2.706
    		c-0.464,0.996-0.349,2.83,0.305,3.604c0.562,0.666,2.281,0.554,3.462,0.46c2.531-0.2,5.104-1.109,7.551-0.814
    		C579.447,622.098,579.558,620.074,579.984,617.531z M541.365,714.086c2.174-0.907,4.088-1.764,6.045-2.507
    		c1.514-0.574,3.106-0.943,4.619-1.521c1.272-0.486,2.963-0.846,3.562-1.818c0.467-0.76-0.191-3.239-0.819-3.434
    		c-1.361-0.421-3.076-0.062-4.551,0.333c-2.191,0.587-4.333,1.403-6.434,2.271c-1.352,0.558-2.743,1.207-3.832,2.146
    		c-0.755,0.649-1.558,2-1.355,2.783C538.787,713.064,540.281,713.453,541.365,714.086z M407.539,631.192
    		c0.353-3.256-1.403-4.423-4.453-4.405c-3.614,0.02-4.573,1.026-4.479,4.92c0.09,3.732,0.927,4.266,4.98,4.36
    		C407.233,636.152,407.705,634.063,407.539,631.192z M459.523,618.613c-2.743-1.568-4.325-3.088-6.082-3.328
    		c-2.371-0.323-4.563,1.529-4.052,3.809c0.4,1.786,2.731,4.406,4.146,4.369C455.221,623.419,456.842,620.934,459.523,618.613z
    		 M429.967,686.672c2.081,0.144,4.21-0.332,4.253-3.247c0.048-3.332-2.638-3.392-4.965-3.531c-2.288-0.137-3.949,0.816-3.999,3.365
    		C425.195,686.266,427.262,686.715,429.967,686.672z M600.419,620.192c-0.695-0.491-2.268-2.246-2.929-1.952
    		c-2.049,0.911-3.752,2.602-5.589,3.99c1.288,1.026,2.44,2.581,3.896,2.955C597.981,625.746,600.409,623.314,600.419,620.192z
    		 M509.487,655.867c-1.349,1.031-2.698,2.062-4.048,3.093c1.711,1.278,3.261,2.967,5.178,3.723c1.959,0.771,3.517-0.509,3.701-2.726
    		C514.58,656.802,512.271,656.743,509.487,655.867z M478.752,637.101c-0.164,2.031,1.068,3.866,3.052,3.173
    		c1.637-0.573,3.337-2.661,3.826-4.409c0.694-2.486-1.75-2.769-3.589-2.887C479.284,632.802,478.752,634.66,478.752,637.101z"/>
    </Link>
        <path onClick={() => tryNextLevel()} className="clickable nextLevel" fillRule="evenodd" clipRule="evenodd" fill="black" d="M515.215,53.815c0.963,0.203,1.948,0.643,2.886,0.556
    		c1.534-0.142,3.999-0.232,4.334-1.054c0.548-1.343-0.258-3.263-0.569-4.925c-0.597-3.187-1.324-6.352-1.794-9.555
    		c-0.072-0.487,0.856-1.484,1.476-1.641c1.855-0.471,7.228,4.304,7.262,6.29c0.031,1.829-0.106,3.676,0.098,5.484
    		c0.272,2.416,0.042,4.424-1.736,6.422c-0.839,0.942-1.105,3.517-0.414,4.394c0.731,0.928,3.012,1.184,4.423,0.87
    		c1.339-0.298,3.319-1.61,3.441-2.659c0.251-2.153-0.591-4.424-0.913-6.656c-0.394-2.726-1.007-5.464-0.986-8.192
    		c0.02-2.626,1.714-2.572,3.27-1.052c0.896,0.876,2.105,2.22,1.984,3.207c-0.478,3.911,2.849,7.595,0.524,11.646
    		c-0.334,0.583,0.861,2.948,1.556,3.049c2.017,0.29,4.138-0.152,6.218-0.308c-0.105-1.37-0.029-2.786-0.357-4.102
    		c-0.544-2.184-1.72-4.264-1.956-6.46c-0.374-3.485,1.654-4.753,4.503-2.914c2.809,1.813,5.47,3.841,4.64,7.985
    		c-0.354,1.764-0.312,3.693-0.006,5.473c0.222,1.284,1.022,3.322,1.834,3.462c1.379,0.237,3.298-0.391,4.366-1.351
    		c0.834-0.749,1.029-2.572,0.942-3.88c-0.084-1.24-1.342-2.447-1.298-3.64c0.067-1.832,0.447-4.957,1.331-5.197
    		c2.162-0.589,4.721-0.003,7.051,0.459c0.806,0.159,1.401,1.354,2.104,2.064c3.99,4.045,3.964,4.019,0.048,8.28
    		c-4.381,4.767-7.485,10.379-10.264,16.155c-1.36,2.828,1.007,7.688,4.321,7.637c4.162-0.064,7.068,2.033,10.4,3.585
    		c2.668,1.241,5.524,2.155,8.002,3.69c1.063,0.658,1.931,2.526,1.921,3.837c-0.021,2.613-0.946,5.214-1.034,7.835
    		c-0.057,1.694,0.64,3.437,1.13,5.12c0.454,1.562,1.022,3.098,1.647,4.602c0.731,1.762,1.238,3.767,2.432,5.162
    		c5.108,5.974,5.188,5.905,12.388,3.217c0.156-0.059,0.307-0.134,0.464-0.188c5.274-1.781,15.416,3.376,16.947,8.774
    		c0.729,2.572,1.861,2.514,3.393,0.884c2.433-2.59,4.53-1.936,5.918,0.829c2.121,4.229,4.586,8.474,5.644,13.007
    		c1.814,7.774-6.166,15.381-14.109,14.245c-2.792-0.399-3.955-2.188-3.481-4.488c0.859-4.168,2.318-8.211,3.452-12.326
    		c0.316-1.148,0.371-2.37,0.546-3.558c-1.298,0.291-2.626,0.491-3.879,0.914c-0.573,0.193-1.115,1.281-1.473,1.188
    		c-5.009-1.294-8.464,3.088-13.097,3.548c-2.211,0.219-4.193,2.807-6.268,4.337c-5.203,3.837-6.996,9.051-5.959,15.282
    		c0.23,1.383,0.845,2.793,1.612,3.976c1.889,2.913,4.079,5.631,5.946,8.557c2.272,3.56,1.762,7.236-1.178,10.319
    		c-4.026,4.226-3.951,4.186-1.371,9.188c0.647,1.255,0.817,2.841,0.897,4.291c0.347,6.322,0.244,12.606-3.158,18.272
    		c-0.831,1.385-1.76,2.838-2.988,3.831c-2.839,2.294-5.911,4.296-8.849,6.47c-3.251,2.406-6.722,1.618-9.949,0.346
    		c-4.022-1.587-6.874-0.26-7.51,4.004c-0.878,5.895-2.827,11.264-7.249,15.552c-0.863,0.837-1.382,2.251-1.61,3.481
    		c-1.052,5.659-6.127,10.57-11.56,10.993c-0.492,0.038-1.143,0.355-1.445,0.146c-4.338-2.988-7.713-0.863-11.159,1.8
    		c-0.47,0.362-2.319-0.189-2.78-0.845c-1.727-2.453-3.562-2.307-5.368-0.449c-1.373,1.413-2.872,2.998-4.601,1.511
    		c-1.908-1.641-3.286-3.947-4.679-6.104c-0.345-0.533,0.341-1.64,0.207-2.423c-0.205-1.212-0.708-2.373-1.085-3.556
    		c-1.008,0.631-2.53,1.032-2.935,1.934c-1.822,4.067-5.771,6.133-9.199,6.959c-4.901,1.18-10.743,1.532-15.328-0.127
    		c-3.108-1.125-5.974-1.962-9.211-2.362c-1.447-0.18-3.37-1.494-3.953-2.797c-2.362-5.283-3.863-10.986-6.55-16.076
    		c-1.424-2.696-1.865-5.506-2.945-8.049c-1.394-3.283-4.667-5.858-7.455-8.381c-0.535-0.483-2.992,0.283-3.831,1.168
    		c-2.736,2.885-4.561,1.961-6.986-0.529c-3.551-3.646-6.128-7.565-5.141-12.784c1.078-5.703-2.249-10.101-4.002-14.927
    		c-1.426-3.923-3.69-7.535-5.283-11.408c-0.301-0.731,0.513-2.485,1.311-3.011c4.362-2.874,8.877-5.518,13.351-8.222
    		c0.421-0.255,1.234-0.283,1.333-0.588c1.721-5.361,8.192-7.987,8.134-14.427c-0.03-3.367-1.077-7.07,2.178-9.825
    		c0.391-0.331,0.433-1.897,0.111-2.114c-2.498-1.687-1.751-4.39-2.523-6.611c-0.854-2.457-2.819-4.906-4.955-6.459
    		c-4.283-3.116-8.674-6.798-14.634-5.102c-1.383,0.394-2.479,1.794-3.706,2.73c0.932,1.134,1.793,2.336,2.818,3.376
    		c0.669,0.678,2.182,1.028,2.281,1.684c0.785,5.177,1.959,10.455-0.589,15.476c-1.637,3.224-3.876,5.689-7.328,7.501
    		c-4.161,2.182-8.683,0.904-8.951-3.799c-0.243-4.255,0.422-8.558,0.62-12.843c0.065-1.436-0.109-2.88-0.111-4.321
    		c-0.004-1.8-0.596-4.023,0.237-5.303c1.336-2.052,2.419-3.489,0.127-5.456c-0.304-0.261-0.308-1.461-0.001-1.75
    		c2.83-2.666,4.884-6.528,9.635-6.334c2.994,0.122,5.997,0.144,8.994,0.095c4.541-0.075,6.104-1.865,5.957-6.327
    		c-0.035-1.045,0.303-2.265,0.874-3.141c2.791-4.281,5.925-8.351,8.543-12.73c2.764-4.621,5.714-8.771,10.98-10.918
    		c1.548-0.631,2.586-2.502,3.87-3.792c5.127-5.153,10.246-10.313,15.405-15.435c0.662-0.656,1.623-1.639,2.306-1.536
    		c2.912,0.438,4.062-1.712,5.732-3.275c0.863-0.809,2.517-1.913,3.193-1.59c3.245,1.555,6.32,0.14,7.271-2.103
    		c0.751-1.772-1.252-4.742-2.109-7.152c-0.37-1.039-1.16-1.965-1.361-3.019c-0.352-1.836,0.228-3.544,2.393-3.531
    		c2.039,0.013,4.137,0.523,3.65,3.459c-0.081,0.492-0.257,0.979-0.267,1.471c-0.122,5.739,4.984,9.319,10.23,6.946
    		c1.141-0.516,1.711-2.293,2.543-3.488L515.215,53.815z M558.79,126.219c4.758-0.315,8.314-3.34,12.301-5.413
    		c2.726-1.418,5.435-3.002,8.34-3.896c2.546-0.783,3.323-2.092,2.574-4.363c-0.798-2.419-2.478-1.587-4.026-0.771
    		c-4.605,2.427-9.172,4.934-13.843,7.229c-0.999,0.49-2.931,0.756-3.474,0.195c-1.807-1.863-4.047-3.984-4.492-6.317
    		c-0.553-2.896,2.953-1.611,4.496-2.518c0.144-0.084,0.304-0.173,0.396-0.302c3.957-5.544,11.11-4.883,16.088-8.36
    		c1.02-0.713,1.879-2.614,1.727-3.832c-0.258-2.062-2.101-2.407-3.877-1.593c-2.192,1.006-4.431,1.998-6.427,3.329
    		c-5.691,3.793-11.444,6.996-18.702,4.994c-0.675-0.187-1.777,0.648-2.432,1.271c-0.169,0.162,0.434,1.535,0.982,1.977
    		c1.967,1.583,1.262,2.759,0.102,4.598c-1.875,2.97-4.774,5.436-4.869,9.482c-0.025,1.065-1.824,2.054-2.727,3.145
    		c-0.97,1.173-2.574,2.393-2.632,3.649c-0.274,5.985-0.181,11.992-0.053,17.988c0.022,1.053,0.64,2.236,1.311,3.105
    		c2.264,2.934,2.26,5.566-0.109,8.545c-0.796,1.002-1.086,3.243-0.512,4.348c2.093,4.028,3.6,8.425,7.333,11.521
    		c3.046,2.526,0.56,5.944,0.639,8.947c0.008,0.268-1.945,1.07-2.271,0.79c-5.053-4.338-8.587-1.573-12.282,2.165
    		c-4.347,4.398-10.412,5.014-15.718,3.934c-3.908-0.796-8.558-2.36-11.195-6.521c-1.416-2.232-3.569-2.536-5.323-0.308
    		c-1.659,2.106-2.797,1.756-4.259-0.149c-0.545-0.712-1.65-0.996-2.506-1.467c-3.329-1.832-3.59-3.383-0.72-6.029
    		c1.169-1.077,2.93-1.51,4.102-2.584c1.591-1.458,3.708-3.072,4.105-4.938c0.677-3.173,0.947-6.83,0.004-9.852
    		c-1.244-3.989,0.294-6.481,2.75-8.826c2.925-2.793,3.52-5.9,1.981-9.493c-1.011-2.361-1.278-4.559-0.481-7.179
    		c0.936-3.08,0.34-5.942-2.188-8.664c-2.931-3.155-6.625-5.961-6.72-11.236c-0.028-1.614-2.866-3.331-4.642-4.714
    		c-1.362-1.062-3.02-1.814-4.646-2.453c-3.343-1.312-6.735-2.509-10.141-3.651c-2.425-0.813-4.874-1.613-7.373-2.115
    		c-1.114-0.224-2.574-0.009-3.516,0.582c-0.812,0.51-1.615,1.917-1.477,2.775c0.108,0.669,1.606,1.294,2.592,1.612
    		c8.467,2.742,16.986,5.282,23.09,12.48c2.687,3.167,5.91,5.884,8.534,9.096c0.785,0.961,0.369,2.903,0.504,4.396
    		c-1.106-0.598-2.402-0.994-3.286-1.828c-4.083-3.854-8.265-7.639-12.013-11.807c-5.793-6.442-14.779-4.932-21.45-9.061
    		c-0.223-0.138-0.712-0.057-0.964,0.102c-2.145,1.345-4.194,1.684-6.472,0.106c-0.5-0.346-2.145,0.094-2.539,0.668
    		c-0.579,0.841-1.061,2.555-0.606,3.149c1.443,1.888,2.909,4.204,6.016,3.32c1.09-0.311,2.411-0.927,3.314-0.583
    		c5.917,2.254,12.266,3.152,17.687,7.019c8.581,6.12,12.387,14.231,12.08,24.514c-0.025,0.855-0.798,1.689-1.224,2.533
    		c-0.849-0.618-1.922-1.075-2.506-1.884c-1.825-2.53-3.479-5.186-5.176-7.808c-2.021-3.122-4.412-2.511-6.716-0.518
    		c-2.389,2.066-4.564,4.377-6.885,6.526c-1.719,1.594-1.732,3.086,0.03,4.62c2.51,2.185,4.948,4.454,7.53,6.549
    		c3.214,2.607,6.657,2.346,10.246,0.651c1.904-0.898,3.937-1.622,5.987-2.077c1.147-0.254,2.452,0.203,3.686,0.34
    		c-0.371,1.158-0.809,2.301-1.096,3.479c-0.321,1.318-1.188,3.197-0.632,3.918c2.558,3.313,0.338,5.21-1.728,7.136
    		c-1.572,1.466-3.357,2.708-5.077,4.012c-2.72,2.062-3.437,5.006-1.587,7.785c1.964,2.95,3.677,5.75,7.704,7.11
    		c3.359,1.135,6.464,2.083,10.001,2.116c1.66,0.016,3.318,0.926,4.967,1.463c3.761,1.226,6.56,4.501,11.075,4.336
    		c6.894-0.253,12.479-3.709,17.995-6.982c2.429-1.441,4.021-2.216,6.682-1.202c1.169,0.446,2.999,0.07,4.181-0.572
    		c8.922-4.852,9.497-10.276,1.907-16.748c-0.505-0.432-1.262-0.67-1.585-1.193c-1.454-2.355-2.982-4.699-4.082-7.222
    		c-0.427-0.979-0.104-3.278,0.427-3.473c1.144-0.419,3.008-0.338,3.938,0.374c4.868,3.729,8.935,1.176,13.364-0.99
    		c3.88-1.896,2.167-5.105,2.523-7.833c0.192-1.473,0.188-3.025-0.066-4.483c-0.176-1.01-0.717-2.146-1.457-2.837
    		c-4.229-3.953-5.154-3.836-9.305,0.384c-0.84,0.854-2.044,1.35-3.081,2.01c-0.295-1.342-1.261-3.048-0.766-3.962
    		c1.788-3.298,3.77-6.598,6.261-9.374c1.818-2.025,3.052-3.84,2.544-6.565L558.79,126.219z M507.153,244.223
    		c5.131-0.539,10.646,1.717,15.395-1.975c0.442-0.344,1.311-0.299,1.947-0.206c4.237,0.617,7.313-1.312,9.97-4.335
    		c0.758-0.863,1.612-1.984,2.61-2.232c3.605-0.897,3.824-3.439,3.983-6.485c0.308-5.894-0.258-6.377-5.715-4.25
    		c-0.847,0.33-2.012-0.155-3.029-0.264c0.289-1.178,0.215-2.791,0.965-3.417c0.847-0.708,2.436-0.714,3.7-0.703
    		c4.143,0.035,8.294,0.427,12.422,0.236c1.668-0.077,3.424-1.101,4.862-2.094c0.73-0.505,0.846-1.899,1.239-2.892
    		c-1.015-0.401-2.016-1.101-3.046-1.146c-2.477-0.109-5.065-0.324-7.428,0.249c-4.553,1.106-7.252-1.303-9.659-4.395
    		c-2.302-2.956-4.348-6.11-6.602-9.104c-0.434-0.575-1.304-1.13-1.988-1.15c-2.489-0.073-5.338-0.67-7.409,0.301
    		c-5.739,2.688-10.02,0.027-14.479-2.81c-2.53-1.61-3.791-0.942-5.244,1.986c-0.621,1.252-2.209,3.162-3.009,3.005
    		c-5.36-1.057-8.863,2.683-12.102,5.318c-3.493,2.843-6.574,4.664-11.029,3.956c-1.301-0.207-2.831-0.491-3.932-0.005
    		c-1.076,0.475-2.432,1.913-2.396,2.881c0.039,1.04,1.428,2.202,2.481,2.958c0.507,0.364,1.6-0.055,2.421-0.168
    		c2.279-0.315,3.718,0.745,3.671,2.946c-0.157,7.332,3.892,12.387,8.866,16.965c2.059,1.894,3.722,3.765,2.685,6.903
    		c-0.24,0.724,0.159,2.197,0.718,2.506c0.64,0.354,1.796-0.158,2.708-0.377c2.985-0.715,5.929-1.694,8.951-2.133
    		C502.125,243.94,504.66,244.223,507.153,244.223z M521.436,123.192c0.037-0.148,0.074-0.297,0.11-0.444
    		c1.662,0,3.423,0.341,4.958-0.1c1.456-0.417,2.676-1.654,4-2.531c-0.758-1.05-1.492-2.117-2.279-3.144
    		c-0.688-0.896-1.798-1.652-2.051-2.642c-0.243-0.951,0.373-2.123,0.611-3.198c0.82,0.298,1.623,0.669,2.466,0.874
    		c0.972,0.237,2.575,0.784,2.866,0.403c1.175-1.537,2.522-3.325,2.697-5.128c0.147-1.514-0.896-3.588-2.098-4.606
    		c-0.639-0.54-3.035,0.261-4.1,1.122c-1.797,1.454-2.977,0.901-4.869,0.135c-3.267-1.322-6.831-1.96-10.319-2.638
    		c-0.569-0.11-1.385,1.05-2.087,1.623c0.604,0.66,1.093,1.546,1.839,1.932c1.426,0.736,3.081,1.034,4.492,1.79
    		c2.691,1.443,3.226,3.148,0.371,5.153c-1.626,1.142-3.12,2.482-4.602,3.813c-2.613,2.35-2.641,4.281,0.456,5.806
    		C516.155,122.524,518.905,122.635,521.436,123.192z M522.773,98.821c4.225-1.271,9.252-2.306,13.839-4.335
    		c3.026-1.338,6.851-1.103,9.081-4.447c1.208-1.812,1.693-3.237,0.16-4.809c-1.539-1.577-3.519-1.768-4.618,0.248
    		c-3.237,5.932-9.979,5.044-14.899,7.094c-4.207,1.752-10.36,1.692-15.339-1.579c-1.358-0.893-3.979-0.205-5.892,0.271
    		c-0.669,0.167-1.47,2.006-1.26,2.844c0.185,0.74,1.626,1.428,2.623,1.66c3.852,0.895,7.744,1.613,11.628,2.361
    		C519.389,98.379,520.701,98.52,522.773,98.821z M463.207,123.714c-0.818-0.446-1.553-1.121-2.371-1.246
    		c-3.216-0.493-6.449-0.943-9.692-1.13c-1.246-0.071-2.567,0.517-3.798,0.96c-2.007,0.725-3.13,2.011-1.863,4.176
    		c1.205,2.06,2.998,2.978,4.809,0.953c2.163-2.418,3.746-3.023,5.555,0.281c0.357,0.653,1.838,0.603,2.571,1.172
    		c0.537,0.417,1.165,1.604,0.95,1.952c-0.982,1.595-1.724,3.235,0.498,4.036c1.496,0.539,3.599,0.708,4.946,0.051
    		c2.305-1.122,4.377-2.87,6.242-4.672c0.744-0.72,0.679-2.277,0.982-3.453c-1.187,0.091-2.408,0.046-3.546,0.332
    		c-0.533,0.134-1.046,0.836-1.33,1.399c-1.474,2.924-3.391,1.184-5.005,0.381c-2.322-1.155-0.521-2.387,0.181-3.555
    		C462.585,124.937,462.792,124.497,463.207,123.714z M570.643,131.311c-0.268,0.072-0.864,0.058-1.189,0.348
    		c-2.339,2.084-4.807,4.078-6.774,6.478c-0.413,0.505,0.814,2.825,1.786,3.821c0.592,0.605,2.569,0.787,3.18,0.274
    		c2.01-1.69,3.848-3.658,5.435-5.759C574.74,134.274,573.389,131.422,570.643,131.311z M446.732,182.687
    		c-1.116-1.228-2.106-3.322-3.373-3.505c-2.858-0.414-3.258,1.957-3.211,4.297c0.043,2.201-0.077,4.532,3.057,4.512
    		C446.536,187.968,445.847,185.359,446.732,182.687z M473.001,197.146c2.962-0.596,6.04,0.529,5.499-3.074
    		c-0.188-1.243-2.543-3.354-2.786-3.207c-1.982,1.196-3.699,2.833-5.497,4.335C471.35,195.991,472.481,196.782,473.001,197.146z
    		 M468.53,223.247c-2.501-1.421-3.955-2.932-5.145-2.746c-1.411,0.22-2.594,1.9-3.876,2.948c1.508,1.104,2.891,2.634,4.576,3.126
    		C464.874,226.806,466.248,225.03,468.53,223.247z M457.31,208.52c-0.554,0.124-1.106,0.248-1.66,0.371
    		c-0.201,1.895-0.647,3.809-0.5,5.676c0.072,0.907,1.256,1.726,1.937,2.585c1.209-1.432,3.303-2.821,3.372-4.307
    		C460.523,211.456,458.444,209.965,457.31,208.52z M525.43,254.221c1.837-2.308,3.186-3.826,4.197-5.544
    		c0.103-0.174-2.062-2.393-2.778-2.198c-1.83,0.495-4.683,0.689-4.198,3.712C522.837,251.349,524.081,252.336,525.43,254.221z
    		 M480.635,167.033c-1.958-1.76-2.952-3.405-4.151-3.571c-2.288-0.316-3.495,1.32-3.574,3.665c-0.071,2.077,1.07,3.32,2.948,2.916
    		C477.287,169.734,478.504,168.432,480.635,167.033z M525.516,135.654c1.381-1.257,3.459-2.566,3.276-3.096
    		c-0.498-1.443-1.951-2.557-3.025-3.802c-1.269,1.288-2.536,2.575-3.805,3.862C522.963,133.474,523.963,134.328,525.516,135.654z
    		 M551.489,197.249c-2.183-1.808-3.491-3.693-4.555-3.563c-1.247,0.152-2.28,2.056-3.406,3.2c1.039,1.009,1.966,2.627,3.149,2.845
    		C547.737,199.925,549.08,198.573,551.489,197.249z M528.277,156.254c0.482,0.009,0.966,0.018,1.448,0.026
    		c0.779-1.38,2.156-2.767,2.14-4.137c-0.013-1.003-1.781-2.707-2.898-2.822c-2.323-0.239-2.994,1.803-2.639,3.711
    		C526.544,154.187,527.6,155.185,528.277,156.254z M514.615,165.896c1.357-2.409,2.736-3.815,2.713-5.197
    		c-0.014-0.861-2.248-2.45-3.303-2.312c-1.056,0.138-2.576,1.995-2.613,3.141C511.377,162.631,512.977,163.787,514.615,165.896z
    		 M546.921,233.69c-1.385,2.343-2.804,3.67-2.646,4.771c0.167,1.164,1.836,2.113,2.849,3.156c0.943-1.121,2.512-2.174,2.643-3.384
    		C549.883,237.163,548.401,235.918,546.921,233.69z M581.255,199.592c-2.302-1.365-3.665-2.821-4.706-2.619
    		c-1.159,0.225-2.758,1.928-2.806,3.047c-0.046,1.057,1.863,3.208,2.574,3.075C577.754,202.825,578.961,201.333,581.255,199.592z
    		 M564.627,206.911c-1.297,2.326-2.654,3.66-2.479,4.745c0.19,1.175,1.812,2.116,2.81,3.16c0.954-1.096,2.59-2.144,2.669-3.3
    		C567.703,210.396,566.184,209.163,564.627,206.911z M591.348,129.895c-1.267,2.29-2.389,3.494-2.544,4.812
    		c-0.19,1.619,0.509,3.7,2.704,3.027c1.233-0.378,2.729-2.051,2.853-3.269C594.473,133.363,592.884,132.089,591.348,129.895z
    		 M515.753,63.809c-2.051-1.542-3.162-2.977-4.424-3.124c-0.892-0.104-2.932,1.748-2.796,2.354c0.293,1.308,1.491,3.06,2.632,3.334
    		C512.169,66.614,513.598,65.096,515.753,63.809z M581.692,169.916c-2.344-1.371-3.631-2.623-5.021-2.752
    		c-0.858-0.08-1.877,1.551-2.823,2.413c1.186,1.141,2.213,2.653,3.628,3.251C578.097,173.09,579.505,171.488,581.692,169.916z
    		 M536.213,73.086c-2.13-1.694-3.44-2.738-4.752-3.781c-0.79,1.051-2.429,2.404-2.186,3.087c0.434,1.216,1.872,2.391,3.154,2.857
    		C533.108,75.495,534.342,74.213,536.213,73.086z M569.112,188.737c-2.039-1.245-3.404-2.578-3.931-2.304
    		c-1.43,0.746-2.505,2.173-3.721,3.329c0.781,0.701,1.777,2.124,2.304,1.968C565.306,191.271,566.66,190.184,569.112,188.737z
    		 M450.009,200.207c-1.155-1.369-1.855-2.2-2.557-3.03c-1.36,1.271-2.722,2.543-4.082,3.814c1.025,0.859,2.174,2.517,3.046,2.382
    		C447.573,203.194,448.515,201.617,450.009,200.207z M468.655,175.513c0.015-0.493,0.03-0.986,0.045-1.479
    		c-1.361-0.52-2.718-1.423-4.084-1.438c-1.14-0.013-2.292,1.016-3.438,1.582c0.765,1.066,1.562,3.044,2.288,3.019
    		C465.213,177.134,466.928,176.134,468.655,175.513z M578.488,217.713c0.778-2.036,1.752-3.332,1.543-4.396
    		c-0.201-1.02-1.825-2.65-2.477-2.5c-1.272,0.293-2.303,1.639-3.433,2.549C575.328,214.567,576.535,215.769,578.488,217.713z
    		 M564.814,177.77c1.396-2.327,2.521-3.594,2.853-5.043c0.14-0.611-1.396-1.604-2.171-2.424c-1.147,1.236-2.506,2.354-3.307,3.785
    		C561.964,174.491,563.374,175.809,564.814,177.77z M577.257,180.386c-1.686,1.776-3.218,3.155-3.104,3.309
    		c1.077,1.444,2.434,2.68,3.71,3.976c0.65-0.781,1.944-1.686,1.822-2.317C579.413,183.939,578.436,182.661,577.257,180.386z
    		 M456.188,196.007c-0.618,1.915-1.154,2.854-1.182,3.809c-0.062,2.156,1.013,3.609,3.312,3.192
    		c0.852-0.154,2.242-1.815,2.168-1.919C459.396,199.559,458.099,198.176,456.188,196.007z M462.016,186.855
    		c-1.755-1.164-2.809-2.394-3.885-2.415c-0.931-0.018-1.888,1.338-2.833,2.086c1.192,0.921,2.288,2.069,3.629,2.635
    		C459.38,189.352,460.456,188.062,462.016,186.855z M498.263,68.002c-1.017,1.976-2.016,3.094-2.038,4.232
    		c-0.017,0.857,1.218,1.74,1.895,2.611c0.878-1.214,1.992-2.339,2.507-3.692C500.81,70.673,499.535,69.638,498.263,68.002z
    		 M569.459,158.185c1.325,1.189,2.18,1.956,3.034,2.724c0.71-0.97,1.946-1.925,1.973-2.913c0.024-0.886-1.282-1.807-2.003-2.713
    		C571.625,156.093,570.786,156.902,569.459,158.185z M586.615,135.711c-2.279-0.935-3.432-1.858-4.332-1.656
    		c-1.012,0.227-1.797,1.47-2.682,2.271c0.882,0.777,1.809,2.225,2.632,2.168C583.362,138.415,584.412,137.195,586.615,135.711z
    		 M517.438,76.747c1.197-1.805,2.171-2.808,2.482-3.985c0.115-0.433-1.632-1.974-2.019-1.809c-1.211,0.515-2.177,1.602-3.238,2.467
    		C515.397,74.301,516.132,75.181,517.438,76.747z M491.846,86.377c-1.163-0.895-1.945-1.496-2.728-2.098
    		c-0.663,0.917-1.895,1.872-1.835,2.739c0.064,0.942,1.33,1.802,2.072,2.696C490.072,88.755,490.788,87.794,491.846,86.377z
    		 M550.854,74.022c-1.129-1.287-1.892-2.156-2.653-3.024c-0.662,0.759-1.937,1.604-1.846,2.258c0.152,1.1,1.175,2.078,1.831,3.107
    		C548.924,75.716,549.662,75.068,550.854,74.022z M487.587,192.312c-1.722-1.199-2.806-2.061-4.02-2.661
    		c-0.146-0.073-1.569,1.606-1.405,1.881c0.576,0.967,1.476,1.916,2.479,2.372C485.103,194.113,486.092,193.161,487.587,192.312z
    		 M583.424,150.451c1.028-1.354,2.108-2.154,2.134-2.985c0.025-0.846-0.989-1.722-1.549-2.585c-0.883,0.782-2.153,1.425-2.504,2.398
    		C581.29,147.877,582.483,148.982,583.424,150.451z M557.546,204.042c-1.124-0.822-2.055-1.92-2.52-1.749
    		c-0.812,0.298-1.339,1.376-1.985,2.127c0.709,0.536,1.419,1.486,2.128,1.486C555.839,205.906,556.51,204.907,557.546,204.042z"/>
      <Link to="/archive/010" className={activeIndex === 10 ? "active" : "inactive"} onClick={() => handleOnClick(10)}>
    	<path className="clickable" fillRule="evenodd" clipRule="evenodd" fill="black" d="M490.754,267.915c4.429,0.312,8.638,0.542,12.805-2.344
    		c3.005-2.082,6.638-0.069,8.971,2.401c2.588,2.741,5.386,3.446,8.926,3.009c1.576-0.194,3.557-0.135,4.819,0.659
    		c6.166,3.878,11.464,1.56,17.192-1.321c7.629-3.837,12.839-9.503,16.031-17.303c0.68-1.661,2.114-3.001,2.908-4.631
    		c0.966-1.982,1.459-4.198,2.455-6.161c1.23-2.423,2.627-4.797,4.279-6.939c0.381-0.494,2.096,0.041,3.196,0.103
    		c-0.134,0.867-0.005,1.906-0.442,2.574c-3.955,6.03-1.896,12.088-0.263,18.172c0.626,2.332,1.369,4.669,1.642,7.052
    		c0.552,4.825-0.955,9.972,2.456,14.336c0.316,0.404,0.358,1.607,0.082,1.8c-3.111,2.171-2.023,5.505-2.171,8.316
    		c-0.209,4.011-1.703,7.045-4.256,10.148c-2.878,3.499-4.819,7.77-7.147,11.717c-0.334,0.566-0.397,1.543-0.852,1.752
    		c-7.975,3.659-11.179,13.84-20.425,15.414c-4.903,0.834-8.812,4.325-13.35,4.799c-6.114,0.638-12.376,3.605-18.772-0.381
    		c-2.354-1.467-6.131-0.651-9.259-0.876c-4.649-0.334-9.296,0.959-13.791-2.501c-2.11-1.625-6.132-0.394-8.863-3.112
    		c-2.871-2.857-6.555-4.896-9.871-7.31c-0.258-0.188-0.39-0.718-0.622-0.745c-5.589-0.675-5.975-6.193-8.761-9.447
    		c-3.124-3.646-6.132-7.414-8.906-11.33c-1.268-1.79-1.879-4.052-2.74-6.119c-0.555-1.33-0.982-2.714-1.538-4.043
    		c-3.005-7.185-6.112-14.328-8.934-21.584c-0.304-0.781,1.666-2.325,1.471-3.29c-1.203-5.919,4.17-9.439,5.028-14.562
    		c0.572-3.407,1.661-6.759,1.914-10.181c0.25-3.377,2.433-4.122,4.934-4.595c2.66-0.504,5.235,0.052,6.018,3.003
    		c1.009,3.81,4.838,6.968,2.781,11.508c3.349,6.98,6.198,14.345,13.418,18.477c0.931,0.533,2.225,0.459,3.358,0.604
    		c1.475,0.188,3.405-0.228,4.357,0.562C482.454,268.543,486.587,268.036,490.754,267.915z M532.122,293.608
    		c0-3.22,0.216-5.575-0.097-7.858c-0.143-1.04-1.268-2.645-2.06-2.715c-0.935-0.082-2.557,1.018-2.892,1.957
    		c-1.11,3.121-1.751,6.332,0.394,9.473c1.7,2.488,1.051,5.744-1.367,7.266c-0.603,0.379-2.31,0.252-2.464-0.101
    		c-1.306-2.977-3.343-6.084-3.268-9.104c0.104-4.147-1.618-7.347-3.31-10.633c-0.521-1.014-2.573-1.239-3.924-1.825
    		c-0.511,1.384-1.767,3.021-1.398,4.107c1.396,4.109,3.358,8.022,5.01,12.05c0.363,0.888,0.199,1.979,0.489,2.911
    		c0.465,1.497,0.792,3.2,1.742,4.349c1.74,2.103,1.357,3.38-0.725,4.856c-2.563,1.819-5.266,3.616-7.293,5.956
    		c-1.322,1.525-2.427,4.178-2.057,6.007c0.531,2.628,2.957,4.325,5.988,4.322c1.827-0.001,3.783-0.557,5.455-0.075
    		c4.168,1.201,6.519-1.348,8.09-4.144c1.46-2.598,1.333-5.762-1.065-8.304c-1.612-1.709-1.896-3.808,0.076-5.218
    		C532.565,303.229,533.014,298.246,532.122,293.608z M495.876,313.545c7.397-0.916,10.685-4.816,9.364-11.635
    		c-0.184-0.949-0.677-1.841-1.035-2.755c-1.604-4.095-3.022-8.277-4.912-12.234c-0.725-1.519-2.527-2.522-3.839-3.761
    		c-0.641,1.329-1.307,2.647-1.914,3.992c-0.829,1.834-1.342,3.883-2.487,5.484c-1.331,1.862-3.287,3.265-4.815,5.005
    		c-2.536,2.889-2.997,9.913-0.458,11.427C488.919,310.939,492.491,312.086,495.876,313.545z M558.299,292.896
    		c0.496-0.301,2.881-0.958,3.081-2.025c0.48-2.562-2.048-1.66-3.485-2.064c-0.649-0.183-1.117-1.009-1.668-1.542
    		c0.541-0.552,1.006-1.225,1.645-1.617c0.536-0.329,1.495-0.123,1.876-0.524c0.812-0.855,1.993-2.048,1.857-2.921
    		c-0.136-0.872-1.609-1.994-2.646-2.181c-2.904-0.523-5.561,0.038-7.4,2.808c-1.479,2.227-2.923,4.077-5.648,0.809
    		c-1.796-2.153-4.002-1.333-4.753,1.42c-0.382,1.398-0.06,2.973-0.281,4.434c-0.842,5.563,2.04,11.032,0.334,16.665
    		c-0.275,0.907,0.567,2.788,1.431,3.284c2.496,1.437,3.987-0.742,5.351-2.318c0.704-0.814,1.435-2.346,1.123-3.159
    		c-0.917-2.395-2.434-4.552-3.559-6.878c-1.62-3.35-0.645-5.168,3.047-5.206C551.396,291.85,554.194,292.437,558.299,292.896z
    		 M464.114,288.996c-0.031,0.329-0.068,0.658-0.095,0.988c-0.331,4.142-0.322,4.18,3.652,3.313c2.206-0.48,1.812,0.908,1.949,2.257
    		c0.164,1.618,0.705,3.198,1.083,4.795c1.307-1.113,2.875-2.042,3.839-3.399c0.787-1.107,1.29-2.771,1.156-4.112
    		c-0.398-4.028,1.145-6.943,4.05-9.585c0.927-0.843,1.659-2.98,1.231-4.002c-0.812-1.938-2.705-2.913-4.909-1.482
    		c-1.623,1.055-2.758,1.025-3.138-1.194c-0.69-4.027-2.582-2.235-3.749-0.518C466.539,279.952,463.853,283.923,464.114,288.996z
    		 M442.33,267.204c1.567,2.383,2.688,5.409,4.863,6.917c1.392,0.965,4.626,0.258,6.529-0.702c4.333-2.184,5.573-10.184,2.512-14.122
    		c-1.287-1.656-2.326-3.864-5.094-3.149C448.366,256.864,443.144,263.768,442.33,267.204z M454.549,284.43
    		c0.174,0.077,0.348,0.154,0.522,0.232c0,0.497-0.169,1.081,0.031,1.477c0.602,1.188,1.347,2.303,2.036,3.446
    		c1.151-1.019,2.391-1.96,3.406-3.1c0.349-0.391,0.204-1.282,0.174-1.94c-0.159-3.539,0.986-6.419,3.767-8.772
    		c1.058-0.896,2.11-2.437,2.153-3.716c0.044-1.314-1.15-2.669-1.803-4.006c-1.192,0.622-2.627,1.011-3.514,1.929
    		c-0.988,1.024-1.564,2.49-2.167,3.833C457.575,277.331,456.078,280.888,454.549,284.43z"/>
    </Link>
    <Link to="/archive/007" className={activeIndex === 7 ? "active" : "inactive"} onClick={() => handleOnClick(7)}>
    	<path className="clickable" fillRule="evenodd" clipRule="evenodd" fill="black" d="M472.737,1081.086c-8.327,0-16.657-0.1-24.98,0.078
    		c-1.848,0.039-3.781,0.835-5.49,1.664c-5.682,2.756-8.13,1.336-8.127-4.904c0.001-3.995-0.032-7.991,0.037-11.984
    		c0.023-1.318-0.059-2.849,0.552-3.91c3.109-5.404,2.267-11.273,2.31-17.041c0.053-7.143,0.024-14.285,0.061-21.428
    		c0.018-3.441,1.432-4.786,5.453-4.797c11.157-0.031,22.317-0.077,33.472,0.131c8.248,0.153,16.487,0.738,24.734,1.018
    		c1.275,0.043,2.736-1.044,3.826-0.701c2.56,0.807,5.17,1.846,7.339,3.384c2.202,1.56,1.338,3.662,0.018,5.716
    		c-0.643,1-0.598,2.536-0.61,3.833c-0.061,5.826-0.019,11.653-0.043,17.479c-0.004,0.99,0.235,2.365-0.301,2.908
    		c-3.524,3.569-2.441,8.001-2.681,12.228c-0.079,1.396-0.803,2.761-1.253,4.133c-0.549,1.671-1.416,3.298-1.624,5.008
    		c-0.545,4.475-3.287,7.23-7.71,7.244c-8.327,0.025-16.654,0.007-24.981,0.007C472.737,1081.129,472.737,1081.107,472.737,1081.086z
    		 M463.95,1059.42c0,3.844,0.021,6.672-0.007,9.498c-0.021,2.318,1.138,3.379,3.396,3.217c2.116-0.15,3.29-0.83,2.865-3.466
    		c-0.472-2.925-0.294-5.958-0.352-8.945c-0.069-3.605,0.533-7.239-2.285-10.374c-1.435-1.596-0.447-3.09,1.615-3.641
    		c5.004-1.334,7.629-5.643,10.905-9.042c0.561-0.581-0.125-3.054-0.958-4.047c-0.557-0.664-2.776-0.783-3.564-0.207
    		c-1.211,0.887-1.723,2.668-2.73,3.907c-1.029,1.264-2.299,2.33-3.464,3.483c-0.842-1.235-2.089-2.369-2.428-3.729
    		c-0.445-1.79-0.775-3.633-2.948-2.883c-1.474,0.508-3.564,2.063-3.637,3.257c-0.208,3.419-0.66,7.662,1.05,10.175
    		C464.484,1051.145,463.786,1055.734,463.95,1059.42z M492.646,1056.729c0.317,0.095,0.635,0.189,0.952,0.283
    		c0,0.829-0.089,1.669,0.017,2.484c0.329,2.556-1.351,6.075,2.82,6.902c2.221,0.44,3.953-2.529,4.272-6.851
    		c0.013-0.165,0.016-0.331,0.016-0.497c0.001-6.013,0.061-12.025-0.03-18.037c-0.034-2.271-0.973-4.665-0.492-6.774
    		c0.533-2.339,0.601-3.958-1.823-4.747c-1.919-0.624-4.653,1.535-4.665,4.008c-0.01,2.023,0.912,4.038,1.301,6.08
    		c0.383,2.012,1.154,4.154,0.798,6.061C495.105,1049.402,493.738,1053.04,492.646,1056.729z M477.261,1052.849
    		c2.005,2.033,3.129,4.157,4.592,4.423c2.149,0.391,4.097-1.078,4.167-3.746c0.064-2.411-2.482-5.775-4.186-5.141
    		C480.302,1048.955,479.25,1050.816,477.261,1052.849z"/>
    </Link>
    <Link to="/archive/004" className={activeIndex === 4 ? "active" : "inactive"} onClick={() => handleOnClick(4)}>
    	<path className="clickable" fillRule="evenodd" clipRule="evenodd" fill="black" d="M434.221,980.284c0,9.165,0.021,18.329-0.009,27.494
    		c-0.015,4.46-0.685,5.216-5.266,5.16c-13.988-0.17-27.975-0.495-41.963-0.679c-4.139-0.055-8.3,0.433-12.412,0.136
    		c-2.205-0.159-4.48-1.198-6.428-2.345c-1.068-0.628-2.015-2.245-2.163-3.517c-0.576-4.923-0.721-9.894-1.194-14.83
    		c-0.551-5.747-1.504-11.464-1.848-17.221c-0.216-3.618,0.551-7.284,0.548-10.929c-0.004-4.464-0.483-8.927-0.514-13.391
    		c-0.035-5.225,1.371-5.812,6.395-4.197c2.341,0.752,5.14-0.017,7.722,0.102c3.446,0.158,6.88,0.565,10.326,0.761
    		c3.418,0.194,6.843,0.3,10.267,0.371c10.466,0.218,20.934,0.411,31.4,0.591c4.958,0.086,5.11,0.164,5.129,4.996
    		C434.246,961.953,434.221,971.119,434.221,980.284z M374.837,992.038c0.014,0,0.026,0,0.04,0c0-2.158-0.038-4.317,0.008-6.475
    		c0.089-4.194,1.142-5.337,5.26-5.51c2.319-0.098,4.698,0.315,6.949-0.08c1.123-0.197,2.378-1.545,2.846-2.674
    		c0.202-0.486-1.356-1.84-2.281-2.597c-0.444-0.364-1.354-0.107-1.907-0.404c-0.647-0.349-1.136-0.994-1.692-1.511
    		c0.65-0.618,1.204-1.535,1.975-1.789c1.228-0.403,2.811-0.004,3.853-0.612c1.126-0.657,1.804-2.083,2.677-3.173
    		c-1.146-0.681-2.231-1.792-3.448-1.963c-4.578-0.646-8.842,0.328-12.635,3.15c-1.646,1.225-3.321,2.333-4.961-0.344
    		c-0.339-0.553-2.487-0.649-3.283-0.143c-2.042,1.302-2.955,3.432-1.124,5.484c2.593,2.907,3.26,5.986,2.046,9.637
    		c-0.242,0.728,0.088,1.634,0.096,2.458c0.026,2.565-0.135,5.145,0.078,7.693c0.212,2.546,0.595,5.119,1.325,7.554
    		c0.2,0.668,1.917,1.177,2.931,1.165c0.427-0.005,1.136-1.533,1.193-2.402C374.943,997.024,374.837,994.528,374.837,992.038z
    		 M399.185,993.261c-0.115,0.715-0.423,1.333-0.233,1.68c0.716,1.312,1.419,3.391,2.449,3.601c1.683,0.344,3.811-0.223,5.335-1.115
    		c0.487-0.285,0.099-3.109-0.655-4.278c-2.643-4.094-5.195-8.039-4.462-13.328c0.142-1.021-1.313-2.497-2.366-3.35
    		c-0.498-0.404-2.195-0.185-2.65,0.349c-4.079,4.773-8.094,9.61-11.864,14.627c-0.515,0.685,0.025,3.064,0.791,3.564
    		c0.82,0.535,2.861,0.214,3.706-0.51c1.864-1.595,3.222-3.765,4.966-5.522c0.852-0.857,2.105-1.315,3.178-1.955
    		c0.485,1.161,1.068,2.294,1.426,3.493C399.084,991.458,399.086,992.484,399.185,993.261z M426.443,977.732
    		c-0.503-0.897-0.894-2.547-1.794-2.901c-0.731-0.288-2.53,0.713-3.05,1.609c-1.021,1.765-1.433,3.873-2.2,5.798
    		c-0.339,0.851-0.931,1.6-1.407,2.395c-0.748-0.54-1.72-0.932-2.195-1.651c-0.906-1.368-1.59-2.897-2.254-4.408
    		c-0.768-1.744-1.793-2.896-3.813-1.879c-1.861,0.937-2.788,2.294-1.659,4.439c2.422,4.601,4.861,9.173,5.555,14.48
    		c0.12,0.921,1.642,2.232,2.599,2.308c0.937,0.072,2.219-1.02,2.916-1.932c0.979-1.278,1.704-2.808,2.278-4.327
    		C423.107,987.196,424.67,982.68,426.443,977.732z"/>
    </Link>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M440.074,981.499c0-8.817-0.041-17.634,0.018-26.451
    		c0.028-4.289,0.657-4.743,5.009-4.645c4.826,0.108,9.655,0.12,14.483,0.164c3.277,0.029,6.486-0.434,9.431,2.096
    		c1.253,1.075,3.812,0.832,5.779,0.84c11.324,0.045,22.65-0.038,33.976-0.028c4.815,0.004,4.307,0.15,3.477,4.625
    		c-0.823,4.431-1.001,9.374,0.123,13.68c1.548,5.93,1.932,11.754,1.652,17.742c-0.046,0.99,0.06,2.213-0.461,2.919
    		c-4.11,5.579-2.231,11.767-1.814,17.733c0.284,4.077-0.223,4.559-4.505,4.66c-3.976,0.095-7.605-0.246-11.521-1.447
    		c-3.145-0.965-7.086,0.97-10.692,1.104c-2.921,0.109-5.874-0.511-8.804-0.876c-1.468-0.183-2.915-0.739-4.373-0.747
    		c-8.326-0.042-16.653-0.005-24.979,0.075c-6.822,0.065-6.82,0.109-6.82-6.962c0.001-8.161,0.001-16.322,0.001-24.482
    		C440.06,981.499,440.066,981.499,440.074,981.499z M475.682,997.986c1.665,0,3.33,0.029,4.994-0.006
    		c3.964-0.082,8.481-6.366,6.897-10.021c-1.931-4.452-4.26-8.74-6.575-13.014c-0.948-1.751-5.312-2.553-6.803-1.325
    		c-2.356,1.938-0.388,3.375,0.648,5.051c2.164,3.498,4.193,7.087,6.097,10.732c0.326,0.625-0.204,2.465-0.405,2.476
    		c-1.617,0.085-3.369,0.164-4.814-0.417c-0.534-0.215-0.509-2.209-0.449-3.377c0.038-0.749,0.993-1.556,0.854-2.187
    		c-0.273-1.237-1.023-2.37-1.577-3.547c-0.7,0.649-1.592,1.185-2.068,1.97c-1.854,3.056-3.8,6.091-5.277,9.331
    		c-1.101,2.415,0.275,4.157,2.986,4.319C472.014,998.082,473.85,997.993,475.682,997.986z M455.115,994.452c0.019,0,0.036,0,0.055,0
    		c0,2.82-0.116,5.646,0.034,8.459c0.131,2.45,1.933,2.963,3.319,1.438c1.282-1.411,2.262-3.627,2.324-5.523
    		c0.218-6.603-0.411-13.246,0.019-19.826c0.47-7.204,0.112-7.884-7.303-7.835c-2.434,0.016-4.247,0.292-4.559,3.247
    		c-0.092,0.876-0.963,1.681-1.509,2.497c-2.681,4.015-1.798,6.034,3.032,6.234c3.337,0.138,4.919,1.353,4.621,4.833
    		C454.966,990.119,455.115,992.292,455.115,994.452z M504.651,986.428c-0.672-0.337-1.621-0.812-2.57-1.287
    		c0.499-0.741,0.839-1.72,1.525-2.18c2.166-1.447,2.378-3.46,0.888-5.075c-1.705-1.849-3.534-0.835-5.036,0.905
    		c-1.081,1.252-2.31,2.411-3.612,3.432c-2.617,2.049-2.093,3.522,0.348,5.419c4.403,3.42,4.312,4.112,0.571,8.133
    		c-2.035,2.188-4.138,4.351-5.853,6.777c-0.563,0.797-0.002,2.389,0.045,3.616c1.158-0.235,2.731-0.078,3.395-0.78
    		c2.015-2.134,3.677-4.6,5.477-6.936c0.684-0.889,1.248-1.916,2.078-2.636c2.479-2.15,5.145-4.092,7.556-6.312
    		c0.676-0.622,1.278-2.27,0.928-2.83c-0.47-0.752-1.874-1.117-2.925-1.241C506.734,985.347,505.923,985.946,504.651,986.428z"/>
    <Link to="/archive/005" className={activeIndex === 5 ? "active" : "inactive"} onClick={() => handleOnClick(5)}>
    	<path className="clickable" fillRule="evenodd" clipRule="evenodd" fill="black" d="M635.412,1021.859c-4.166,0-8.332,0.022-12.498-0.015
    		c-1.156-0.011-2.569,0.197-3.424-0.364c-6.559-4.311-13.861-2.503-20.874-2.608c-9.714-0.145-9.785-0.001-9.728-9.671
    		c0.027-4.487-0.02-8.992,0.356-13.456c0.202-2.411,1.264-4.743,1.86-7.13c0.317-1.271,0.758-2.608,0.65-3.873
    		c-0.556-6.539-1.085-13.09-1.99-19.585c-0.704-5.054-0.291-5.562,5.04-5.867c9.208-0.526,18.4,0.073,27.596,0.144
    		c10.673,0.081,21.246-0.588,31.67-2.849c3.051-0.662,5.252-0.054,6.801,2.593c0.385,0.658,0.653,1.577,0.535,2.306
    		c-0.662,4.086-1.629,8.13-2.152,12.23c-0.354,2.78-0.026,5.641-0.237,8.447c-0.156,2.08-0.688,4.132-1.053,6.195
    		c-0.342,1.93-0.877,3.848-0.993,5.79c-0.375,6.306-0.482,12.628-0.944,18.926c-0.142,1.935-1.099,3.809-1.672,5.713
    		c-0.746,2.48-2.433,3.236-4.949,3.152c-4.66-0.155-9.329-0.045-13.994-0.045C635.412,1021.882,635.412,1021.87,635.412,1021.859z
    		 M635.449,1007.683c2.497-0.942,4.652-1.521,6.579-2.532c3.782-1.987,2.907-5.974,3.456-9.269c0.056-0.331-2.003-1.614-2.581-1.353
    		c-3.246,1.473-6.313,3.335-9.502,4.945c-0.873,0.44-1.952,0.473-2.937,0.691c-0.073-1.092-0.638-2.588-0.132-3.201
    		c1.771-2.146,3.652-4.466,6.019-5.742c1.193-0.645,3.503,0.899,5.343,1.283c0.876,0.183,1.875,0.103,2.73-0.151
    		c0.255-0.076,0.508-1.506,0.262-1.71c-2.908-2.408-2.495-5.82-3.228-8.98c-0.31-1.333-2.958-3.065-4.418-2.955
    		c-1.887,0.144-2.061,2.274-1.812,4.403c0.164,1.399-0.981,3.774-2.194,4.36c-3.775,1.825-5.71,5.173-8.204,8.152
    		c-3.853,4.602-3.69,5.825,2.049,8.478C629.638,1005.379,632.49,1006.454,635.449,1007.683z M604.01,988.351
    		c0-2.434,0.206-5.938-0.105-9.396c-0.118-1.312-1.426-2.519-2.191-3.773c-0.679,1.213-1.962,2.447-1.921,3.635
    		c0.119,3.388-0.112,6.537-2.266,9.331c-0.26,0.337-0.19,0.965-0.191,1.458c-0.013,4.653-0.194,9.319,0.123,13.95
    		c0.08,1.165,1.59,2.822,2.772,3.188c2.067,0.64,2.816-0.998,2.912-2.937C603.378,999.008,603.676,994.212,604.01,988.351z
    		 M614.937,982.512c0.219-0.082,0.438-0.164,0.657-0.246c-0.705-1.685-1.235-3.489-2.243-4.969
    		c-0.248-0.364-2.727,0.011-3.001,0.598c-0.649,1.387-1.087,3.238-0.707,4.66c0.66,2.468,2.557,4.557,1.303,7.502
    		c-0.247,0.581,1.371,1.957,2.13,2.967c0.735-0.894,2.029-1.746,2.101-2.69C615.371,987.747,615.052,985.122,614.937,982.512z
    		 M615.196,1000.856c-0.152-1.369-0.008-2.179-0.342-2.647c-0.837-1.179-1.891-2.203-2.857-3.289
    		c-0.917,1.168-1.975,2.255-2.7,3.532c-0.458,0.806-0.3,1.938-0.629,2.847c-0.685,1.893-1.758,4.251,0.706,5.038
    		c1.175,0.375,3.298-1.375,4.609-2.578C614.823,1002.989,614.966,1001.459,615.196,1000.856z"/>
    </Link>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M622.875,953.482c-9.916,0-18.412-0.136-26.9,0.068
    		c-3.283,0.079-4.459-0.866-4.277-4.242c0.277-5.149,0.089-10.324,0.117-15.487c0.005-0.997-0.201-2.135,0.194-2.968
    		c3.45-7.259,2.38-14.989,2.631-22.603c0.062-1.852,1.063-3.664,1.571-5.512c0.429-1.561,0.68-3.174,1.175-4.71
    		c0.433-1.339,0.927-3.554,1.694-3.69c1.316-0.234,3.367,0.43,4.231,1.456c3.134,3.721,7.135,5.205,11.539,4.028
    		c4.752-1.27,9.853-2.04,13.704-5.693c3.426-3.251,8.593-4.142,11.383-8.387c0.39-0.593,2.573-0.851,3.127-0.36
    		c4.906,4.346,10.794,2.246,16.269,2.712c1.314,0.112,2.736-0.374,3.951-0.03c0.679,0.192,1.643,2.03,1.444,2.271
    		c-3.432,4.146-1.637,8.971-1.854,13.484c-0.565,11.74,1.061,23.555-1.359,35.249c-0.38,1.836,0.448,3.91,0.65,5.881
    		c0.426,4.157-0.301,5.042-4.328,5.377c-0.497,0.041-1.072-0.128-1.487,0.065C645.079,955.65,633.062,952.159,622.875,953.482z
    		 M616.53,913.157c3.728,0.164,6.995-0.997,8.798-4.353c0.536-0.996-0.345-2.962-0.985-4.286c-0.284-0.588-2.21-1.112-2.328-0.94
    		c-1.846,2.706-4.172,2.737-6.941,1.735c-0.484-0.176-1.652,0.501-1.967,1.078c-1.427,2.609-2.564,2.873-4.756,0.478
    		c-2.402-2.627-4.223-1.908-5.108,1.615c-0.319,1.272-0.47,2.627-0.444,3.939c0.128,6.722,0.251,13.447,0.593,20.159
    		c0.07,1.366,0.823,2.899,1.728,3.956c0.719,0.841,2.149,1.073,3.265,1.576c0.35-1.026,0.99-2.049,0.998-3.078
    		c0.043-4.977-0.135-9.955-0.138-14.933C609.241,915.062,611.511,912.924,616.53,913.157z M634.443,929.499
    		c0,1.285-0.329,2.357,0.065,2.854c0.941,1.183,2.224,2.094,3.368,3.114c0.685-1.295,1.43-2.564,2.012-3.903
    		c0.163-0.376-0.089-0.982-0.258-1.443c-1.643-4.496-1.014-6.049,5.024-8.12c2.172-0.746,4.491-1.295,6.416-2.47
    		c2.699-1.648,3.971-5.486,2.466-6.726c-2.45-2.017-4.264-0.08-5.981,1.478c-2.426,2.199-4.8,3.624-7.531,0.38
    		c-1.879-2.23-3.914-1.615-5.378,0.363c-0.896,1.211-1.605,2.956-1.525,4.414C633.312,922.971,634.016,926.476,634.443,929.499z
    		 M625.355,925.135c-1.586-1.931-2.46-4.003-3.854-4.45c-1.936-0.62-3.247,0.998-3.286,3.181c-0.042,2.394,0.82,4.451,3.39,4.459
    		C622.65,928.327,623.701,926.609,625.355,925.135z"/>
    <Link to="/archive/008" className={activeIndex === 8 ? "active" : "inactive"} onClick={() => handleOnClick(8)}>
    	<path className="clickable" fillRule="evenodd" clipRule="evenodd" fill="black" d="M517.367,1053.342c-0.568-1.89-1.521-4.625-2.178-7.43
    		c-0.33-1.41-0.708-3.305-0.059-4.366c3.48-5.697,1.959-11.961,2.277-17.965c0.219-4.14,0.92-5.649,4.829-4.511
    		c18.183,5.295,36.751,1.534,55.087,2.8c1.85,0.128,3.944,0.625,5.393,1.682c1.951,1.423,2.807,3.463,1.127,6.073
    		c-0.919,1.427-1.248,3.45-1.259,5.208c-0.085,13.997-0.031,27.994-0.016,41.991c0.004,2.955,0.248,5.932-0.023,8.86
    		c-0.25,2.705-1.8,3.635-4.585,2.524c-1.795-0.715-3.817-1.07-5.759-1.169c-7.634-0.39-15.523,0.468-22.846-1.205
    		c-7.291-1.665-14.447-1.417-21.682-1.837c-2.717-0.157-4.081-1.598-4.51-4.383c-0.281-1.829-1.334-3.542-2.064-5.297
    		c-0.19-0.457-0.746-0.935-0.668-1.305C521.836,1066.323,515.856,1061.042,517.367,1053.342z M538.043,1058.941
    		c0-1.647-0.146-3.328,0.041-4.973c0.188-1.656,0.705-3.275,1.078-4.91c1.657,0.409,3.295,0.984,4.977,1.188
    		c2.34,0.281,4.855,0.825,7.031,0.253c2.229-0.586,2.488-3.611,0.418-4.594c-3.654-1.733-3.079-3.045-0.428-5.194
    		c0.897-0.729,0.991-2.447,1.452-3.714c-1.483-0.505-3.168-1.725-4.414-1.376c-3.45,0.966-6.075,3.109-7.159,6.899
    		c-0.304,1.062-1.968,2.531-2.845,2.429c-1.072-0.127-2.246-1.563-2.862-2.71c-0.504-0.936,0.059-2.43-0.419-3.396
    		c-0.505-1.023-1.818-2.501-2.531-2.375c-1.157,0.204-2.99,1.544-2.999,2.419c-0.034,3.485-1.52,7.361,1.957,10.276
    		c0.679,0.569,1.216,1.774,1.177,2.658c-0.235,5.284-0.716,10.557-0.979,15.839c-0.07,1.428,0.325,2.879,0.506,4.319
    		c1.333-0.714,2.841-1.224,3.967-2.182C539.564,1066.773,537.375,1062.595,538.043,1058.941z M553.016,1066.337
    		c-0.067,5.258,0.415,5.686,4.498,5.77c2.637,0.055,5.274,0.269,7.903,0.511c6.823,0.63,9.996-2.764,8.817-9.409
    		c-0.164-0.925,0.218-1.943,0.072-2.876c-0.262-1.673-0.549-3.386-1.17-4.945c-0.526-1.324-1.619-2.41-2.317-3.682
    		c-1.411-2.566-2.464-5.385-4.204-7.692c-0.733-0.974-2.833-0.917-4.312-1.328c-0.144,1.34-0.737,2.841-0.33,3.984
    		c0.698,1.957,2.199,3.613,3.047,5.535c1.565,3.548,2.93,7.186,4.316,10.81c0.324,0.85,0.4,1.795,0.591,2.696
    		c-1.063,0.065-2.403,0.571-3.138,0.111c-1.858-1.164-3.395-1.943-5.276-0.067c-0.354,0.354-1.458-0.046-2.217-0.099
    		c0.267-0.831,0.327-1.831,0.842-2.459c1.146-1.403,2.578-2.569,3.787-3.927c1.806-2.026-0.006-3.531-1.224-4.55
    		c-0.482-0.403-2.719,0.478-3.456,1.358c-1.771,2.117-3.191,4.542-4.625,6.919C553.807,1064.347,553.246,1065.849,553.016,1066.337z
    		 M551.654,1059.883c-2.473-1.675-3.799-3.269-5.014-3.188c-1.073,0.072-2.957,2.166-2.805,3.089
    		c0.227,1.369,1.762,3.153,3.072,3.498C547.826,1063.523,549.299,1061.659,551.654,1059.883z"/>
    </Link>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M558.278,1015.204c-13.405,0-24.037,0.164-34.66-0.07
    		c-5.184-0.114-5.85-1.184-6.289-6.369c-0.168-1.98-0.644-3.936-0.807-5.916c-0.322-3.941-2.539-7.857,0.665-11.839
    		c1.019-1.266,0.525-3.856,0.497-5.838c-0.12-8.284-0.472-16.568-0.441-24.852c0.016-4.431,2.062-5.557,6.545-4.374
    		c1.42,0.375,2.933,0.562,4.402,0.563c11.132,0.007,22.27-0.263,33.393,0.019c4.054,0.103,8.059,1.568,12.118,2.215
    		c2.426,0.386,4.912,0.519,7.373,0.535c4.931,0.034,5.552,0.849,4.979,5.501c-0.265,2.148,0.461,4.401,0.568,6.615
    		c0.129,2.664,0.234,5.347,0.077,8.006c-0.418,7.084-1.102,14.153-1.448,21.24c-0.228,4.653-0.097,9.327-0.04,13.99
    		c0.034,2.836-1.103,3.791-3.996,3.395C572.697,1016.859,564.15,1015.914,558.278,1015.204z M524.077,992.096
    		c-0.318,0.086-0.636,0.173-0.954,0.26c0.12,2.483-0.235,5.126,0.522,7.397c0.525,1.576,2.581,2.642,3.953,3.936
    		c0.722-1.677,2.081-3.37,2.04-5.028c-0.11-4.447-0.899-8.872-1.26-13.319c-0.19-2.34,0.729-4.106,3.471-3.285
    		c2.953,0.885,5.89,1.937,8.666,3.264c2.048,0.979,3.109,0.276,4.055-1.363c0.917-1.59,0.809-2.665-1.199-3.396
    		c-2.288-0.831-3.009-2.362-0.405-3.688c1.769-0.899,2.896-1.76,1.726-3.742c-1.353-2.29-2.769-1.307-4.515-0.199
    		c-4.528,2.873-9.255,5.043-14.558,1.61c-0.526-0.341-1.416-0.66-1.883-0.442c-1.055,0.49-2.612,1.194-2.776,2.041
    		c-0.364,1.879-0.294,3.969,0.138,5.848C521.884,985.405,523.062,988.731,524.077,992.096z M562.522,999.668
    		c1.136,1.201,2.313,3.42,3.924,3.797c1.01,0.236,2.735-2.233,3.991-3.623c0.367-0.404,0.318-1.272,0.301-1.924
    		c-0.188-6.729-0.262-13.469-0.753-20.177c-0.088-1.206-1.748-2.687-3.031-3.267c-0.741-0.334-2.891,0.622-3.188,1.441
    		c-1.643,4.512-2.87,9.083-0.882,13.967c0.741,1.822,0.582,4.054,0.619,6.103C563.52,996.932,563.02,997.887,562.522,999.668z
    		 M555.832,990.475c-0.005,0-0.01,0-0.014,0c0-3.475,0.308-6.991-0.156-10.403c-0.179-1.317-1.97-3.187-3.267-3.409
    		c-2.12-0.363-2.261,1.709-2.31,3.481c-0.096,3.462,0.476,7.255-0.75,10.297c-1.626,4.031-0.953,7.442,0.613,10.907
    		c0.531,1.176,2.205,1.836,3.357,2.731c0.842-1.226,2.271-2.389,2.409-3.688C556.065,997.118,555.832,993.783,555.832,990.475z"/>
    <Link to="/archive/002" className={activeIndex === 2 ? "active" : "inactive"} onClick={() => handleOnClick(2)}>
    	<path className="clickable" fillRule="evenodd" clipRule="evenodd" fill="black" d="M363.404,928.107c-0.69-4.907-1.663-9.796-1.97-14.727
    		c-0.19-3.057,0.834-6.169,0.957-9.271c0.277-6.982,0.306-13.975,0.511-20.961c0.032-1.09,0.467-2.167,0.716-3.25
    		c0.987,0.372,2.002,0.686,2.957,1.127c4.424,2.047,9.063,1.571,13.43,0.376c2.697-0.739,4.853-3.292,7.515-4.392
    		c1.303-0.537,3.839-0.208,4.733,0.745c4.312,4.597,10.492,6.05,15.379,9.627c0.955,0.699,2.535,0.717,3.833,0.744
    		c4.162,0.086,8.327,0.052,12.491,0.011c0.976-0.011,2.423,0.134,2.849-0.417c3.172-4.095,4.817-1.034,6.764,1.22
    		c1.414,1.638,2.977,3.145,4.451,4.73c2.683,2.886,2.777,5.166,1.004,8.486c-0.962,1.801-1.68,3.929-1.79,5.949
    		c-0.262,4.814-0.09,9.652-0.112,14.48c-0.005,0.996,0.292,2.289-0.208,2.939c-3.487,4.533-2.699,9.78-2.649,14.874
    		c0.03,3.129-1.3,4.32-4.274,3.696c-20.528-4.309-41.354-2.04-62.042-2.801c-4.827-0.178-4.975-0.187-5.011-5.113
    		c-0.01-1.333,0.01-2.666,0.012-3.998c0.003-1.332,0.001-2.664,0.001-3.996C363.101,928.16,363.253,928.134,363.404,928.107z
    		 M392.709,921.028c0.046-10.786-0.843-9.05,9.386-9.174c0.995-0.012,2.057,0.219,2.971-0.055c1.385-0.412,2.807-0.985,3.94-1.849
    		c0.653-0.497,1.174-1.795,0.995-2.554c-0.137-0.581-1.437-0.946-2.267-1.292c-1.123-0.466-2.292-0.82-3.441-1.224
    		c0.806-0.941,1.788-1.787,2.366-2.853c0.585-1.078,1.058-2.388,0.998-3.565c-0.026-0.516-1.757-1.556-2.275-1.334
    		c-3.162,1.352-7.013,2.301-9.079,4.715c-2.896,3.382-2.233,3.82-4.747,0.431c-0.684-0.923-2.177-2.048-3.001-1.849
    		c-0.92,0.223-2.064,1.812-2.127,2.866c-0.204,3.452-0.085,6.938,0.143,10.396c0.248,3.787,1.055,7.526-1.14,11.135
    		c-0.499,0.821-0.211,3.043,0.447,3.457c1.396,0.88,3.296,1.351,4.946,1.253c0.686-0.04,1.608-1.903,1.805-3.044
    		C392.934,924.717,392.709,922.854,392.709,921.028z M366.22,911.041c0.029,0,0.058,0,0.087,0c0,4.811-0.282,9.648,0.167,14.418
    		c0.141,1.495,2.183,3.895,3.468,3.972c2.595,0.154,1.875-2.541,1.894-4.219c0.069-5.973,0.029-11.946,0.019-17.92
    		c-0.004-2.077,0.151-4.758,2.782-4.267c5.731,1.07,7.739-3.573,10.881-6.39c0.838-0.752,1.052-2.676,0.833-3.938
    		c-0.119-0.686-1.868-1.679-2.667-1.51c-1.024,0.216-2.165,1.29-2.675,2.291c-2.173,4.265-2.107,4.095-6.462,1.773
    		c-1.851-0.987-4.577-1.319-6.529-0.721c-1.021,0.312-1.599,3.261-1.72,5.063C366.042,903.395,366.22,907.224,366.22,911.041z
    		 M428.801,919.975c-0.756-1.918-1.47-3.855-2.277-5.752c-1.457-3.423-2.857-6.878-4.525-10.198
    		c-0.572-1.14-1.871-1.915-2.839-2.857c-0.876,1.016-2.415,1.972-2.512,3.056c-0.378,4.265,0.7,8.126,3.889,11.361
    		c0.918,0.931,1.436,3.959,1.138,4.115c-2.355,1.232-4.954,2.002-7.477,2.915c-0.167-1.051-0.727-2.247-0.403-3.114
    		c0.438-1.177,2.117-2.033,2.25-3.148c0.21-1.757-0.424-3.615-0.7-5.43c-1.27,0.601-3.059,0.866-3.706,1.869
    		c-1.669,2.583-2.896,5.465-4.155,8.293c-0.311,0.697-0.15,1.652-0.062,2.474c0.526,4.945,5.238,7.014,9.436,4.396
    		c1.258-0.785,3.075-0.851,4.65-0.92C427.105,926.788,428.188,925.752,428.801,919.975z"/>
    </Link>
    <Link to="/archive/009" className={activeIndex === 9 ? "active" : "inactive"} onClick={() => handleOnClick(9)}>
    	<path className="clickable" fillRule="evenodd" clipRule="evenodd" fill="black" d="M588.844,1056.507c0-8.326-0.09-16.654,0.029-24.979
    		c0.092-6.373,0.631-6.786,6.885-6.784c4.498,0.001,9.016-0.241,13.489,0.093c3.396,0.253,6.734,1.262,10.106,1.896
    		c1.943,0.366,3.902,0.938,5.854,0.933c9.319-0.021,18.639-0.202,27.957-0.334c2.903-0.042,3.355,0.907,2.165,3.584
    		c-0.765,1.722-1.229,3.665-1.395,5.547c-0.485,5.511,0.142,10.853-2.255,16.483c-1.999,4.697-0.593,10.822-0.801,16.311
    		c-0.165,4.362,1.337,9.02-2.403,12.828c-0.459,0.468,0.278,2.395,0.797,3.484c1.624,3.416,1.027,4.636-2.673,4.516
    		c-6.644-0.218-13.612,0.573-19.847-1.183c-10.302-2.901-20.367-1.021-30.546-0.887c-6.305,0.083-7.532-1.212-7.54-7.558
    		c-0.011-7.983-0.003-15.967-0.003-23.95C588.724,1056.507,588.784,1056.507,588.844,1056.507z M604.796,1040.448
    		c-3.445,0.021-9.455,4.5-10.466,7.668c-1.245,3.903-0.468,5.27,3.529,6.203c3.866,0.902,5.725,3.277,5.107,7.199
    		c-0.513,3.264-1.346,6.502-2.332,9.659c-0.981,3.144,1.096,4.743,3.039,6.117c0.601,0.426,2.552-0.597,3.596-1.354
    		c2.123-1.541,2.312-3.26,0.403-5.429c-0.781-0.889-0.631-2.694-0.691-4.093c-0.073-1.712,0.116-3.434,0.122-5.152
    		c0.01-3.626-1.427-7.024,1.142-10.901c1.207-1.822-1.367-6.178-2.319-9.358C605.834,1040.699,605.092,1040.585,604.796,1040.448z
    		 M646.26,1051.945c-0.272-1.09-0.944-3.149-1.282-5.263c-0.712-4.449-2.776-5.768-6.673-3.583c-2.087,1.17-4.221,2.835-5.463,4.82
    		c-3.167,5.062-0.837,9.017,5.031,9.148c3.575,0.08,4.805,1.986,3.104,5.178c-0.928,1.739-2.413,3.173-3.469,4.855
    		c-1.738,2.771,0.884,4.613,1.76,6.772c0.236,0.583,2.146,1.203,2.421,0.931c1.218-1.204,2.815-2.637,3.011-4.15
    		C645.457,1064.768,645.732,1058.817,646.26,1051.945z M619.172,1066.329c3.831,0.041,5.243-1.037,4.757-3.306
    		c-0.233-1.09-2.253-2.86-2.488-2.717c-1.812,1.101-3.384,2.595-5.029,3.968C617.574,1065.141,618.737,1066.006,619.172,1066.329z"
    		/>
    </Link>
    <Link to="/archive/006" className={activeIndex === 6 ? "active" : "inactive"} onClick={() => handleOnClick(6)}>
    	<path className="clickable" fillRule="evenodd" clipRule="evenodd" fill="black" d="M404.902,1083.477c-6.145,0-12.312,0.316-18.428-0.107
    		c-4.347-0.301-8.638-1.541-12.918-2.517c-0.62-0.142-1.342-1.079-1.507-1.771c-0.865-3.634-1.923-7.277-2.233-10.974
    		c-0.383-4.566,0.038-9.193-0.137-13.784c-0.215-5.629-0.673-11.248-1.05-16.87c-0.332-4.962-0.794-9.918-1.02-14.885
    		c-0.178-3.928,1.071-4.725,5.057-4.716c8.219,0.019,16.439-0.387,24.657-0.332c3.245,0.021,6.478,1.095,9.729,1.183
    		c6.815,0.186,13.64-0.007,20.46,0.039c4.564,0.03,4.527,0.088,3.877,4.793c-0.203,1.476-0.092,2.992-0.235,4.479
    		c-0.9,9.345-1.89,18.683-2.72,28.033c-0.264,2.974-0.164,5.981-0.177,8.974c-0.021,4.911-0.106,9.825,0.041,14.732
    		c0.098,3.239-1.237,4.457-4.438,4.388c-6.317-0.139-12.64-0.041-18.959-0.041C404.903,1083.894,404.902,1083.685,404.902,1083.477z
    		 M372.143,1060.203c0,1.413,0.015,1.747-0.003,2.078c-0.099,1.848-0.4,3.999,2.159,4.249c2.09,0.204,3.31-1.076,3.562-3.293
    		c0.104-0.923,0.468-2.057,1.12-2.636c2.485-2.205,2.219-4.745,0.949-7.252c-1.249-2.467-1.156-4.579,0.082-7.065
    		c0.629-1.263,0.112-3.116,0.047-4.699c-0.056-1.364-0.688-3.675-0.223-3.911c1.495-0.761,3.566-1.328,5.049-0.845
    		c0.995,0.324,1.683,2.499,1.945,3.947c0.379,2.1-0.565,4.879,0.486,6.336c3.585,4.97-0.716,8.536-1.561,12.687
    		c-0.258,1.264-1.195,3.27-0.749,3.639c1.559,1.291,3.525,2.312,5.485,2.722c0.436,0.091,2.085-2.72,2.14-4.23
    		c0.237-6.622,0.267-13.264,0.017-19.885c-0.126-3.326,0.759-5.88,3.486-7.7c2.159-1.441,3.133-3.383,2.857-5.928
    		c-0.229-2.118-1.414-3.264-3.33-1.901c-2.303,1.637-4.468,1.586-7.054,0.961c-1.212-0.293-3.208,0.186-4.002,1.073
    		c-2.819,3.15-4.654,2.009-6.748-0.755c-0.781-1.03-2.291-1.508-3.469-2.237c-0.753,1.344-2.099,2.664-2.154,4.036
    		c-0.233,5.718-0.868,11.6,0.167,17.136C373.347,1051.772,373.793,1056.447,372.143,1060.203z M407.566,1059.204
    		c-0.006,0-0.013,0-0.019,0c0-2.494-0.191-5.014,0.126-7.467c0.086-0.672,1.752-1.693,2.601-1.61
    		c2.706,0.266,5.366,0.986,8.048,1.509c1.763,0.343,3.397,0.371,3.786-1.956c0.344-2.06,0.017-3.516-2.532-3.903
    		c-0.988-0.15-1.812-1.377-2.71-2.112c0.896-0.566,1.798-1.126,2.69-1.698c1-0.642,2.447-1.097,2.84-2.005
    		c0.289-0.67-0.59-2.541-1.326-2.799c-1.175-0.411-2.739-0.161-3.995,0.249c-1.176,0.383-2.089,1.719-3.255,1.953
    		c-1.871,0.376-2.956,0.967-3.227,3.049c-0.365,2.814-1.671,2.596-3.701,1.079c-3.115-2.326-4.559-1.692-5.188,2.207
    		c-0.364,2.25-0.016,4.607-0.3,6.877c-0.51,4.069,1.594,8.516-2.064,12.228c-0.77,0.78-0.51,3.339,0.197,4.533
    		c0.442,0.747,2.639,0.753,3.992,0.601c2.679-0.3,4.111-1.932,4.046-4.745C407.529,1063.197,407.566,1061.2,407.566,1059.204z"/>
    </Link>
    <Link to="/archive/003" className={activeIndex === 3 ? "active" : "inactive"} onClick={() => handleOnClick(3)}>
    	<path className="clickable" fillRule="evenodd" clipRule="evenodd" fill="black" d="M576.815,954.174c-3.78-1.021-7.507-2.439-11.355-2.965
    		c-4.901-0.67-9.898-0.766-14.858-0.846c-7.932-0.127-15.866-0.031-23.8-0.047c-1.83-0.004-3.66-0.072-5.49-0.118
    		c-3.695-0.094-4.882-1.585-4.665-5.271c0.399-6.755,0.687-13.519,0.908-20.282c0.146-4.473,0.213-8.955,0.093-13.427
    		c-0.075-2.793-0.691-5.569-0.819-8.364c-0.072-1.565-0.026-3.351,0.661-4.677c0.721-1.391,2.132-2.998,3.505-3.296
    		c1.141-0.248,2.745,1.148,4.006,2.017c7.211,4.968,15.301,3.267,23.115,2.828c2.356-0.132,4.742-1.894,6.873-3.278
    		c2.395-1.557,4.247-0.841,5.608,1.063c1.726,2.411,3.94,2.636,6.56,2.512c3.323-0.157,6.681,0.183,9.981-0.13
    		c2.163-0.205,4.247-1.206,6.379-1.811c1.568-0.444,3.147-1.131,4.734-1.168c2.96-0.069,3.601,1.495,2.438,4.181
    		c-1.01,2.331-2.037,4.872-2.118,7.351c-0.337,10.311-0.281,20.633-0.452,30.949c-0.052,3.105-0.258,6.218-0.598,9.305
    		c-0.286,2.595-3.174,4.777-6.003,4.803c-1.497,0.014-2.995,0.003-4.492,0.003C576.955,953.728,576.886,953.951,576.815,954.174z
    		 M572.868,924.491c0.086-0.215,0.173-0.43,0.259-0.645c0.832,0,1.707,0.166,2.485-0.037c1.323-0.345,2.587-0.922,3.876-1.402
    		c-0.92-1.254-1.723-2.626-2.813-3.708c-0.489-0.486-1.596-0.286-2.35-0.583c-1.108-0.437-2.151-1.039-3.222-1.57
    		c0.721-0.836,1.387-1.729,2.182-2.485c0.466-0.443,1.336-0.535,1.671-1.025c0.595-0.871,1.241-1.932,1.222-2.9
    		c-0.009-0.458-1.506-1.266-2.329-1.266c-4.229,0.002-8.761-0.705-11.353,4.057c-0.943,1.733-2.045,2.344-3.911,0.649
    		c-1.004-0.912-2.955-1.515-4.181-1.176c-0.753,0.208-1.243,2.44-1.321,3.795c-0.18,3.11,0.507,6.369-2.564,8.648
    		c-0.39,0.289-0.548,1.325-0.369,1.869c0.984,3.004,1.268,5.896-0.023,8.948c-0.268,0.634,0.669,2.303,1.458,2.713
    		c0.82,0.428,2.306,0.222,3.191-0.268c1.43-0.787,2.865-7.376,1.869-8.96c-2.091-3.323,0.78-4.833,2.229-6.838
    		c1.607-2.225,3.611-1.673,5.783-0.757C567.331,922.679,570.126,923.523,572.868,924.491z M530.685,924.71
    		c-0.203-0.091-0.407-0.182-0.61-0.272c-0.354-2.124-0.832-4.238-1.004-6.378c-0.069-0.859,0.289-2.13,0.905-2.55
    		c0.492-0.336,1.997,0.044,2.488,0.603c2.734,3.107,6.632,2.268,9.278,1.361c1.987-0.681,4.336-4.831,3.919-6.915
    		c-0.583-2.909-6.358-0.202-7.606-4.313c-0.038-0.124-1.231,0.05-1.865,0.184c-0.296,0.063-0.605,0.313-0.8,0.563
    		c-2.388,3.079-4.51,2.594-6.907-0.152c-0.656-0.752-2.623-1.117-3.521-0.688c-0.886,0.425-1.505,2.04-1.662,3.211
    		c-0.265,1.962,0.133,4.01-0.104,5.979c-0.874,7.271,2.228,14.916-2.129,21.938c-0.383,0.616,1.219,2.688,2.225,3.784
    		c0.247,0.27,2.404-0.418,2.615-1.032C527.642,934.974,529.125,929.829,530.685,924.71z M566.46,935.801
    		c2.293,0.163,4.3-0.325,4.261-3.259c-0.03-2.311-1.228-3.705-3.715-3.51c-2.354,0.185-4.748,0.44-4.936,3.582
    		C561.881,935.799,564.111,935.846,566.46,935.801z"/>
      </Link>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M501.703,947.455c-9.455,0-18.913,0.104-28.366-0.078
    		c-2.174-0.042-4.308-1.185-6.5-1.608c-1.722-0.333-3.504-0.393-5.264-0.479c-4.778-0.236-9.578-0.238-14.331-0.713
    		c-2.151-0.215-5.711-0.934-6.021-2.11c-0.774-2.943-0.669-6.482,0.294-9.38c0.572-1.723,0.971-2.973,0.836-4.888
    		c-0.595-8.427,0.082-16.827,2.431-24.97c0.416-1.443,2.076-3.018,3.527-3.571c3.208-1.226,6.726-1.623,9.971-2.771
    		c2.719-0.961,5.633-2.054,7.73-3.913c2.52-2.232,3.646-1.269,5.028,0.845c4.99,7.628,13.263,8.285,20.963,8.673
    		c3.788,0.191,7.817-2.816,11.592-4.672c1.594-0.784,2.4-3.823,4.812-1.967c2.271,1.747,4.843,3.507,4.253,7.164
    		c-0.219,1.357,0.111,2.818,0.285,4.221c0.183,1.473,1.016,3.099,0.624,4.367c-1.259,4.084-3.144,7.981-0.48,12.257
    		c0.323,0.519-0.737,1.773-0.866,2.727c-0.235,1.743-0.675,3.619-0.273,5.254c0.805,3.272,3.129,6.379-0.159,9.653
    		c-0.335,0.334,0.475,2.016,0.977,2.952c1.316,2.453,0.638,3.323-2.092,3.191c-2.983-0.145-5.979-0.033-8.969-0.033
    		C501.704,947.555,501.703,947.505,501.703,947.455z M490.668,925.351c-0.005,0-0.01,0-0.015,0c0-4.978,0.103-9.959-0.068-14.932
    		c-0.046-1.333-0.846-3.728-1.438-3.771c-2.876-0.216-6.13-0.53-8.616,0.594c-2.733,1.234-5.026,3.812-4.705,7.544
    		c0.178,2.063,0.854,3.254,3.17,3.658c5.318,0.929,5.833,1.771,5.8,7.197c-0.029,4.644,0,9.288,0.024,13.933
    		c0.011,2.137-0.362,4.834,2.753,4.844c3.324,0.01,3.105-2.761,3.1-5.128C490.658,934.643,490.668,929.997,490.668,925.351z
    		 M460.632,932.115c0.122,0.033,0.245,0.065,0.367,0.098c0,0.499-0.009,0.998,0.002,1.497c0.048,2.289-0.476,5.134,3.088,5.021
    		c3.177-0.1,2.711-2.736,2.751-4.863c0.053-2.777-0.263-5.453,2.122-7.856c0.868-0.876,0.508-3.861-0.312-5.308
    		c-1.168-2.062-1.129-3.405,0.023-5.413c0.797-1.39,1.27-3.72,0.646-5.045c-3.296-6.996-6.847-7.449-12.173-1.921
    		c-0.23,0.238-0.52,0.425-0.731,0.677c-2.828,3.356-1.698,7.429,2.473,8.944c4.392,1.596,5.489,5.071,3.175,9.081
    		C461.21,928.506,461.087,930.407,460.632,932.115z M505.392,921.561c-0.007,0-0.014,0-0.021,0c0-3.648,0.117-7.302-0.069-10.94
    		c-0.059-1.143-0.935-2.243-1.436-3.362c-1.3,0.829-2.931,1.411-3.793,2.566c-0.679,0.91-0.436,2.525-0.555,3.832
    		c-0.641,7.055,1.711,14.416-2.8,21.102c-1.457,2.159,1.127,4.719,3.771,3.881c2.879-0.912,4.872-2.828,4.896-6.135
    		C505.413,928.856,505.392,925.208,505.392,921.561z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M377.805,139.025c-1.943-7.144,3.042-13.633,6.164-20.607
    		c0.344-0.768,1.741-1.285,2.736-1.479c1.101-0.214,2.358,0.323,3.438,0.068c0.768-0.182,1.353-1.134,2.019-1.742
    		c-0.678-0.543-1.294-1.202-2.048-1.6c-0.928-0.488-1.971-0.759-2.964-1.124c0.606-0.9,1.086-1.928,1.84-2.68
    		c3.703-3.695,7.08-7.899,11.333-10.814c5.087-3.487,10.862-6.03,16.512-8.606c2.057-0.938,3.675,0.407,4.472,2.75
    		c0.343,1.009,1.679,1.679,2.564,2.502c0.637-0.859,1.502-1.63,1.873-2.592c1.868-4.839,3.616-6.115,8.804-6.167
    		c0.998-0.01,2.019-0.234,2.988-0.091c2.663,0.395,7.283,0.397,7.586,1.514c0.904,3.345,1.992,7.035-0.82,10.778
    		c-2.216,2.951-3.187,6.194-7.919,6.098c-2.591-0.054-2.602,2.397-1.358,4.5c0.621,1.051,1.025,2.23,1.528,3.352
    		c-1.37,0.27-2.732,0.699-4.113,0.774c-2.56,0.138-5.218-0.918-7.449,1.62c-0.5,0.567-2.913-0.094-4.165-0.736
    		c-2.515-1.29-3.614-1.111-4.653,1.913c-0.848,2.467-3.092,4.46-4.745,6.641c-1.785,2.354-3.876,3.444-6.938,2.179
    		c-0.541-0.224-2.347,1.058-2.553,1.895c-0.426,1.729-0.032,3.642-0.229,5.452c-0.211,1.942-0.689,3.857-1.093,5.776
    		c-0.558,2.657-1.312,5.284-1.703,7.965c-1.189,8.142,4.719,12.412,9.582,17.244c2.077,2.063,5.835,3.295,3.812,7.524
    		c-0.118,0.247,1.12,1.59,1.386,1.492c1.08-0.396,2.683-0.99,2.892-1.815c0.494-1.952,0.221-4.14,3.159-4.107
    		c2.605,0.029,5.234-0.392,6.244,3.247c0.292,1.051,2.542,1.559,3.895,2.314c0.403-0.852,1.362-2.004,1.097-2.494
    		c-0.599-1.107-1.765-1.932-2.773-2.784c-0.673-0.568-1.473-0.987-2.215-1.473c0.694-0.593,1.38-1.196,2.084-1.778
    		c3.892-3.219,8.16-6.099,11.495-9.816c1.288-1.436,0.534-4.767,0.579-7.236c0.036-1.995-0.271-4.003-0.137-5.985
    		c0.068-1.008,0.774-1.974,1.192-2.958c0.955,0.534,2.295,0.832,2.796,1.646c1.782,2.891,3.527,5.857,4.784,8.996
    		c0.688,1.719-0.299,2.78-2.5,2.05c-0.826-0.274-1.936,0.305-2.916,0.494c0.234,1.199,0.076,2.773,0.786,3.521
    		c1.505,1.58,2.926,2.95,1.169,5.062c-1.441,1.731-2.401,4.9-5.748,2.361c-0.399-0.304-2.081,0.231-2.083,0.39
    		c-0.014,1.219-0.205,2.892,0.492,3.581c2.59,2.562,3.071,4.071-1.335,4.3c-0.317,0.017-0.839,0.118-0.909,0.31
    		c-1.864,5.113-7.274,5.045-10.978,7.423c-4.241,2.723-8.71,0.837-12.642-0.974c-2.312-1.064-3.506-1.958-5.155,0.723
    		c-1.343,2.184-3.235,1.62-5.073,0.091c-2.382-1.981-4.987-3.692-7.403-5.636c-0.723-0.581-1.181-1.521-1.672-2.35
    		c-0.332-0.56-0.493-1.734-0.758-1.742c-4.614-0.138-4.765-4.526-6.918-7.018C378.364,153.326,377.662,151.57,377.805,139.025z
    		 M441.301,92.23c-2.197-1.122-3.425-2.286-4.431-2.118c-1.101,0.185-2.688,1.528-2.786,2.496c-0.097,0.961,1.717,3.086,2.133,2.953
    		C437.706,95.085,438.958,93.866,441.301,92.23z M410.957,103.262c1.227-2.034,2.264-3.188,2.561-4.508
    		c0.132-0.583-1.241-1.504-1.931-2.271c-1.188,1.182-2.487,2.284-3.438,3.633C408.008,100.314,409.521,101.68,410.957,103.262z
    		 M426.667,104.688c-1.745-1.352-3.038-2.601-3.221-2.455c-1.42,1.13-2.626,2.528-3.897,3.844c0.84,0.635,1.933,1.965,2.471,1.77
    		C423.442,107.329,424.624,106.15,426.667,104.688z M408.292,168.195c-2.142-1.607-3.603-2.702-5.062-3.798
    		c-0.581,0.86-1.585,1.688-1.657,2.589c-0.171,2.132,1.123,3.481,3.274,3.355C405.729,170.29,406.559,169.318,408.292,168.195z
    		 M393.541,145.719c-1.451,2.176-2.532,3.485-3.158,4.986c-0.13,0.311,1.426,1.326,2.205,2.018c0.949-1.162,2.15-2.214,2.729-3.538
    		C395.562,148.624,394.496,147.491,393.541,145.719z M390.76,127.786c-1.743-1.22-2.855-2.576-3.64-2.408
    		c-1.123,0.241-2.269,1.46-2.843,2.556c-0.188,0.359,1.46,2.401,2.115,2.327C387.63,130.12,388.756,129.003,390.76,127.786z
    		 M404.666,104.586c-1.364,1.752-2.828,2.814-2.753,3.753c0.084,1.034,1.546,2.678,2.473,2.723c0.95,0.047,2.639-1.418,2.788-2.405
    		C407.319,107.695,405.925,106.501,404.666,104.586z M384.34,144.488c1.18-1.608,2.321-2.487,2.389-3.442
    		c0.071-1.021-0.87-2.112-1.369-3.174c-1.018,1.071-2.269,2.021-2.932,3.279C382.185,141.612,383.334,142.808,384.34,144.488z
    		 M398.555,121.584c-0.013-0.407-0.025-0.814-0.038-1.222c-1.024-0.442-2.159-1.386-3.044-1.191c-0.929,0.205-1.618,1.5-2.412,2.318
    		c0.848,0.601,1.71,1.7,2.539,1.676C396.599,123.137,397.571,122.154,398.555,121.584z M384.857,148.65
    		c-0.96,1.567-1.973,2.564-1.771,3.021c0.522,1.174,1.583,2.108,2.428,3.138c0.441-0.754,1.327-1.576,1.224-2.247
    		C386.567,151.447,385.762,150.43,384.857,148.65z M397.879,159.392c-1.584-0.659-2.466-1.372-3.146-1.214
    		c-0.712,0.166-1.229,1.168-1.83,1.805c0.651,0.578,1.406,1.708,1.931,1.609C395.721,161.426,396.461,160.482,397.879,159.392z
    		 M408.306,120.365c-1.101-0.617-1.896-1.398-2.332-1.232c-0.7,0.266-1.163,1.157-1.726,1.785c0.543,0.456,1.206,1.374,1.604,1.271
    		C406.618,121.989,407.235,121.213,408.306,120.365z M394.367,136.754c-0.754,1.116-1.471,1.795-1.631,2.586
    		c-0.072,0.354,1.274,1.434,1.458,1.322c0.716-0.431,1.444-1.129,1.711-1.889C396.03,138.416,395.117,137.695,394.367,136.754z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M549.619,729.04c-11.979,0-23.957-0.079-35.934,0.053
    		c-3.132,0.035-6.251,0.982-9.385,1.044c-3.421,0.066-6.85-0.453-10.279-0.626c-1.295-0.065-2.609,0.241-3.903,0.176
    		c-2.747-0.14-5.491-0.363-8.228-0.646c-1.791-0.187-3.562-0.758-5.347-0.78c-10.325-0.125-20.813,0.943-30.926-0.556
    		c-7.251-1.074-14.93-1.365-21.901-4.606c-2.138-0.994-4.884-0.615-7.314-1.064c-2.866-0.53-5.693-1.273-8.536-1.931
    		c-1.429-0.331-2.847-0.704-4.281-1.004c-1.603-0.335-3.231-0.547-4.824-0.921c-2.682-0.631-5.396-1.189-8.004-2.061
    		c-4.43-1.48-8.869-2.992-13.144-4.857c-2.571-1.123-4.932-2.364-7.095-4.744c-4.271-4.7-7.296-10.062-10.674-15.276
    		c-1.156-1.786-2.8-3.257-4.222-4.87c-1.761-2-2.123-3.878-0.226-6.185c2.478-3.009,4.534-6.362,6.967-9.412
    		c0.562-0.707,1.912-0.785,2.9-1.153c0.291,0.894,0.751,1.771,0.835,2.684c0.12,1.317,0.253,2.746-0.117,3.978
    		c-1.968,6.553,2.217,11.087,4.526,16.521c2.897,6.814,9.625,8.686,14.065,13.363c2.307,2.431,6.82,2.746,10.315,4.072
    		c4.838,1.837,9.622,3.82,14.503,5.53c1.351,0.474,3.001,0.02,4.447,0.315c2.016,0.411,3.976,1.104,5.942,1.73
    		c0.459,0.146,0.862,0.759,1.269,0.737c7.183-0.388,13.451,4.542,20.824,3.406c3.602-0.555,7.251-0.206,10.523,2.099
    		c1.077,0.76,2.861,0.702,4.323,0.703c36.64,0.023,73.279-0.003,109.919,0.009c3.289,0.001,6.613,0.658,9.347-2.129
    		c0.672-0.686,2.184-0.758,3.307-0.75c2.708,0.019,5.355,0.067,7.377-2.182c0.208-0.232,0.598-0.498,0.859-0.458
    		c4.05,0.61,7.108-1.97,10.557-3.257c9.993-3.732,19.866-7.794,26.477-16.746c2.652-3.592,5.631-7.252,5.387-12.232
    		c-0.117-2.377,0.941-3.396,3.39-2.803c0.943,0.229,1.951,0.179,2.927,0.287c2.961,0.327,3.665,1.892,1.776,4.288
    		c-4.139,5.251-8.977,10.061-12.423,15.724c-5.073,8.337-14.363,10.789-21.103,16.689c-5.69,0.298-10.171,4.689-16.365,4.144
    		c-4.786-0.422-9.731,1.412-14.642,1.83c-7.941,0.677-15.913,1.015-23.871,1.496C549.633,728.792,549.626,728.916,549.619,729.04z"
    		/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M635.511,102.432c-1.002,0.516-2.004,1.031-3.006,1.547
    		c0.892,1.261,1.524,3.045,2.743,3.65c1.51,0.752,3.021,0.725,3.012,3.105c-0.014,3.342,1.772,3.038,3.821,1.398
    		c0.517-0.414,0.96-1.144,1.516-1.231c0.88-0.141,2.068-0.155,2.664,0.336c0.437,0.36,0.176,1.62,0.145,2.476
    		c-0.034,0.923-0.163,1.842-0.251,2.763c5.866-0.232,4.94,5.724,7.598,8.427c0.862,0.876,1.29,2.251,1.706,3.469
    		c0.272,0.797,0.167,1.724,0.231,2.591c-0.931-0.138-2.296,0.065-2.717-0.472c-1.874-2.395-3.797-4.852-5.071-7.58
    		c-0.883-1.893-1.66-3.377-3.83-2.944c-0.841,0.167-1.987,1.306-2.1,2.125c-0.283,2.07,0.852,3.287,3.024,3.822
    		c0.986,0.243,2.266,1.322,2.497,2.251c0.917,3.678,3.304,5.496,6.723,6.589c0.95,0.304,1.585,1.59,2.365,2.426
    		c-0.994,0.339-2.043,1.072-2.971,0.941c-2.474-0.35-4.331-0.487-4.163,2.97c0.025,0.528-1.906,1.574-2.939,1.584
    		c-0.708,0.007-1.878-1.284-2.038-2.145c-0.226-1.222,0.504-2.594,0.43-3.878c-0.093-1.577-0.157-3.446-1.013-4.623
    		c-1.489-2.047-3.44-3.84-5.48-5.361c-0.782-0.583-2.34-0.127-3.544-0.145c0.214,1.114,0.054,2.847,0.703,3.238
    		c3.174,1.915,4.186,4.62,3.547,8.041c-0.147,0.789-1.016,1.443-1.552,2.16c-0.562-0.517-1.494-0.951-1.629-1.562
    		c-1.683-7.636-6.273-12.628-13.287-15.937c-1.746-0.824-3.258-3.285-3.787-5.282c-0.812-3.064-2.071-2.474-3.831-1.231
    		c-0.946,0.669-1.684,1.781-2.71,2.146c-0.865,0.308-2.012-0.176-3.033-0.31c0.281-1.143,0.288-2.487,0.933-3.362
    		c0.485-0.659,1.91-0.541,2.692-1.089c0.82-0.575,1.95-1.564,1.909-2.315c-0.063-1.167-0.765-2.594-1.671-3.3
    		c-0.481-0.374-2.231,0.254-2.94,0.925c-1.687,1.594-3.134,2.808-5.681,1.524c-0.805-0.405-2.234,0.431-3.378,0.698
    		c0.542,0.998,1.018,2.041,1.65,2.978c0.416,0.616,1.06,1.079,1.601,1.61c-0.673,0.415-1.322,1.136-2.023,1.189
    		c-2.483,0.191-5.235,0.829-7.408,0.018c-2.263-0.845-5.52-3.058-5.654-4.871c-0.578-7.805-1.983-15.937,2.648-23.156
    		c0.571-0.891,2.147-1.404,3.335-1.588c8.532-1.313,16.379,1.153,24.026,4.587c1.049,0.472,2.117,0.898,3.158,1.386
    		c1.306,0.611,2.593,1.264,3.888,1.898c-0.931,0.748-2.009,1.377-2.751,2.28c-0.68,0.828-1.219,1.922-1.371,2.964
    		c-0.065,0.455,1.219,1.636,1.497,1.522c1.389-0.565,3.398-1.173,3.808-2.277c1.158-3.126,3.178-1.816,4.892-1.316
    		c2.041,0.596,4.452,1.295,2.971,4.414L635.511,102.432z M606.079,96.828c-4.254-0.758-4.358-0.323-2.898,3.6
    		c0.521,1.399-0.307,3.261-0.309,4.912c-0.001,0.845,0.461,1.69,0.714,2.535c0.854-0.562,1.773-1.049,2.52-1.729
    		c0.23-0.21-0.009-0.942-0.045-1.436c-0.202-2.755,1.786-3.142,3.596-2.706c1.753,0.423,3.307,1.673,4.948,2.56
    		c0.523-1.728,1.024-3.462,1.594-5.174c0.089-0.269,0.629-0.389,0.716-0.656c0.281-0.868,0.885-2.051,0.542-2.588
    		c-0.38-0.598-1.704-0.94-2.547-0.834C611.949,95.688,609.021,96.304,606.079,96.828z M634.477,121.537
    		c-1.366-1.712-2.47-3.096-3.574-4.479c-1.227,1.125-2.452,2.25-3.679,3.375c1.413,1.479,2.825,2.96,4.238,4.439
    		C632.323,123.919,633.185,122.967,634.477,121.537z M633.044,114.007c0.438-0.176,1.724-0.691,3.009-1.207
    		c-0.812-0.937-1.628-1.871-2.434-2.812c-0.214-0.25-0.366-0.713-0.604-0.755c-1.127-0.199-2.608-0.784-3.332-0.296
    		c-1.071,0.721-1.607,2.233-2.372,3.409c1.083,0.505,2.148,1.056,3.259,1.49C631.009,114.008,631.549,113.918,633.044,114.007z
    		 M597.64,97.235c1.29-1.53,2.719-2.889,2.565-3.1c-0.98-1.352-2.308-2.451-3.521-3.634c-0.762,0.897-2.296,2.002-2.122,2.648
    		C594.903,94.419,596.193,95.433,597.64,97.235z M594.787,106.598c-1.113,1.062-1.855,1.771-2.598,2.479
    		c0.842,0.793,1.582,1.965,2.567,2.229c0.543,0.146,2.034-1.175,2.11-1.934C596.939,108.644,595.739,107.787,594.787,106.598z
    		 M619.896,106.385c-0.716,0.975-1.635,1.873-1.493,2.095c0.543,0.852,1.427,1.486,2.184,2.202c0.406-0.617,1.235-1.331,1.121-1.828
    		C621.527,108.069,620.713,107.432,619.896,106.385z M592.791,102.657c0.706-0.84,1.568-1.421,1.551-1.975
    		c-0.016-0.515-1.187-1.517-1.469-1.398c-0.755,0.317-1.306,1.12-1.938,1.73C591.479,101.494,592.021,101.975,592.791,102.657z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M609.809,165.159c-0.122-2.666-0.98-4.146-4.183-4.236
    		c-3.591-0.1-7.409-1.762-8.628,3.548c-0.861-0.277-2.485-0.623-2.468-0.821c0.243-2.718-0.169-6.111,1.303-7.979
    		c3.538-4.49,7.482-8.953,13.577-10.261c0.375-0.081,0.827,0.197,1.243,0.308c-0.639,2.184-1.109,4.441-2.034,6.496
    		c-0.229,0.506-2.008,0.165-2.939,0.544c-0.929,0.378-2.04,1.013-2.409,1.832c-0.288,0.643,0.436,1.74,0.71,2.636
    		c0.819-0.372,1.922-0.526,2.404-1.156c1.284-1.677,2.032-1.809,3.188,0.171c0.902,1.544,2.367,2.751,3.518,4.16
    		c3.165,3.876,6.982,4.536,11.486,2.578c1.29-0.561,3.187-1.477,2.752,1.492c-0.366,2.503,1.281,2.533,3.078,2.728
    		c0.967,0.104,2.562,0.771,2.645,1.357c0.17,1.207-0.115,2.903-0.912,3.694c-0.707,0.702-2.363,0.592-3.592,0.557
    		c-0.536-0.015-1.046-0.744-1.577-1.136c-2.082-1.537-3.904-0.224-4.489,1.329c-0.472,1.251,0.28,3.329,1.134,4.611
    		c0.863,1.294,2.52,2.061,3.829,3.058c-1.88,0.44-3.741,1.117-5.645,1.271c-3.441,0.277-7.042,0.864-9.788-2.213
    		c-0.289-0.323-0.924-0.536-1.369-0.495c-7.119,0.655-9.667-4.638-12.405-9.59c-0.376-0.681-0.206-2.492-0.03-2.528
    		c3.887-0.801,7.811-1.425,11.728-2.082c1.735,3.972,3.419,7.968,5.252,11.895c0.322,0.691,1.264,1.096,1.92,1.632
    		c0.415-0.619,1.333-1.5,1.168-1.816c-1.454-2.785,0.695-3.661,2.276-5.09c1.695-1.53,1.732-3.327-0.808-4.071
    		C616.476,166.623,613.124,165.953,609.809,165.159z M606.389,174.549c1.034-1.33,1.663-2.14,2.292-2.948
    		c-0.844-0.605-1.634-1.547-2.552-1.695c-0.571-0.092-1.98,1.179-1.886,1.555C604.477,172.393,605.358,173.163,606.389,174.549z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M637.773,148.533c1.451-2.096,2.209-4.001,3.611-5.024
    		c2.053-1.5,3.008,0.519,4.248,1.938c1.49,1.706,3.363,2.245,4.771-0.391c1.636-3.062,4.345-1.635,6.66-1.352
    		c0.347,0.043,0.965,2.041,0.613,2.621c-0.523,0.86-1.77,1.323-2.767,1.845c-1.186,0.62-2.432,1.124-3.652,1.679
    		c0.917,1.432,1.812,2.878,2.758,4.29c0.68,1.014,1.43,1.981,2.147,2.969c-0.965,0.451-2.009,0.787-2.871,1.385
    		c-0.84,0.584-2.173,1.48-2.127,2.162c0.516,7.724-6.189,10.146-10.4,14.346c-0.734,0.731-2.156,1.164-3.212,1.085
    		c-1.472-0.109-2.896-0.831-4.342-1.29c0.802-0.909,1.438-2.106,2.435-2.673c3.31-1.881,6.793-3.456,10.107-5.331
    		c0.825-0.467,1.842-1.691,1.746-2.435c-0.143-1.103-0.995-2.463-1.944-2.982c-0.583-0.318-2.403,0.456-2.82,1.209
    		c-1.761,3.181-2.98,4.586-4.238-0.272c-0.166-0.64-1.969-0.873-3.027-1.259c-1.142-0.415-2.3-0.788-3.45-1.18
    		c0.509-0.96,0.786-2.353,1.581-2.776c1.143-0.609,2.855-1.021,3.972-0.594c1.456,0.558,1.729,3.803,4.116,2.502
    		c2.226-1.213,4.306-2.911,5.963-4.818c0.513-0.59-0.229-2.855-1.037-3.812c-0.613-0.728-2.242-0.599-3.42-0.85
    		c0.075,1.017,0.157,2.033,0.225,3.051c0.045,0.688,0.067,1.379,0.101,2.068c-0.617-0.132-1.507-0.051-1.805-0.433
    		C640.4,152.526,639.257,150.709,637.773,148.533z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M234.923,703.648c0.768,0.364,1.872,0.889,2.976,1.412
    		c-0.5,0.948-0.754,2.282-1.539,2.779c-4.113,2.605-4.758,7.348-6.842,11.12c-1.489,2.695-3.307,2.942-5.67,2.755
    		c-2.826-0.226-3.487-2.114-3.195-4.591c0.379-3.213-1.327-3.935-4.188-3.92c-2.886,0.015-4.824,0.499-4.959,3.966
    		c-0.063,1.611-0.933,3.191-1.438,4.786c-1.577-0.6-3.219-1.072-4.717-1.829c-2.282-1.151-4.504-2.434-6.678-3.78
    		c-2.098-1.3-1.422-2.438,0.521-2.935c4.918-1.259,9.993-2.012,14.792-3.606c5.64-1.874,11.917-1.656,17.039-5.267
    		C231.89,703.93,233.24,704.007,234.923,703.648z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M594.995,66.526c-1.474,1.845-2.722,3.527-4.13,5.062
    		c-0.27,0.294-1.587,0.22-1.721-0.043c-2.453-4.798-8.063-5.151-11.655-8.344c-1.468-1.305-2.961-2.293-1.487-4.406
    		c2.105-3.02,5.1-4.171,7.025-2.737c3.32,2.472,6.667,4.91,9.905,7.485C593.734,64.181,594.162,65.288,594.995,66.526z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M403.809,146.279c1.069-2.479,2.086-4.618,2.907-6.829
    		c0.447-1.204,0.428-2.581,0.863-3.792c0.638-1.774,1.217-3.694,2.361-5.118c0.728-0.906,2.435-1.025,3.702-1.498
    		c-0.06,1.16,0.077,2.373-0.215,3.471c-0.836,3.136-2.207,6.173-2.664,9.351c-0.322,2.24,0.419,4.661,0.859,6.969
    		c0.168,0.882,1.386,1.911,1.152,2.458c-0.824,1.934-2.034,3.712-3.231,5.458c-0.083,0.121-1.645-0.33-1.854-0.807
    		C406.334,152.87,405.164,149.719,403.809,146.279z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M588.617,76.213c-0.093,4.299-1.304,5.265-3.692,3.918
    		c-4.007-2.258-7.947-4.665-11.714-7.298c-1.203-0.841-2.253-2.509-2.508-3.952c-0.334-1.89,1.154-3.014,2.783-1.683
    		c3.537,2.89,7.562,4.4,11.861,5.693C586.926,73.366,587.992,75.54,588.617,76.213z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M472.666,58.62c-0.587,0.889-0.857,1.496-1.298,1.934
    		c-3.63,3.616-7.278,7.214-10.958,10.778c-0.45,0.436-1.121,0.901-1.688,0.899c-0.905-0.004-2.166-0.118-2.603-0.695
    		c-0.36-0.477-0.01-1.893,0.483-2.546c3.22-4.257,6.512-8.463,9.916-12.572c0.592-0.716,2.201-1.512,2.691-1.211
    		C470.526,56.014,471.491,57.395,472.666,58.62z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M515.361,53.679c-1.39-0.448-3.245-0.487-4.074-1.429
    		c-1.771-2.011-3.119-4.431-4.423-6.803c-0.636-1.158-1.467-3.346-1.021-3.746c1.211-1.089,3.021-1.862,4.661-2.005
    		c1.155-0.101,3.284,0.831,3.479,1.655c0.736,3.112,4.341,6.079,0.873,9.672c-0.407,0.422,0.214,1.837,0.359,2.792L515.361,53.679z"
    		/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M588.086,51.757c1.005-0.888,2.02-2.431,3.372-2.83
    		c1.984-0.586,4.358-0.839,5.556,1.804c0.806,1.775,1.835,3.465,2.472,5.294c0.255,0.732,0.006,2.006-0.517,2.552
    		c-0.689,0.719-2.151,1.588-2.796,1.302c-2.368-1.052-4.655-2.399-6.716-3.973C588.724,55.346,588.725,53.825,588.086,51.757z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M477.467,65.026c-0.465,0.719-0.726,1.355-1.181,1.786
    		c-3.336,3.157-6.758,6.225-10.044,9.433c-0.903,0.882-1.355,2.212-2.178,3.2c-0.474,0.569-1.273,0.867-1.927,1.288
    		c-0.474-0.782-1.274-1.542-1.331-2.354c-0.06-0.87,0.348-2.01,0.954-2.649c3.654-3.854,7.355-7.67,11.214-11.315
    		c0.782-0.739,2.292-0.802,3.492-0.933C476.733,63.453,477.111,64.454,477.467,65.026z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M452.414,66.687c-0.797-0.472-1.79-0.729-2.059-1.315
    		c-0.253-0.555,0.053-1.599,0.462-2.184c2.548-3.646,5.039-7.357,7.915-10.734c0.741-0.87,2.796-0.621,4.248-0.887
    		c-0.24,1.129-0.172,2.469-0.773,3.351c-2.329,3.411-4.831,6.709-7.366,9.973C454.275,65.618,453.313,66.038,452.414,66.687z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M497.4,53.069c-3.423,2.187-5.649,4.157-8.248,5.089
    		c-1.966,0.705-5.086-2.641-4.135-4.315c1.163-2.047,3.298-4.436,5.351-4.808C492.064,48.729,494.252,51.141,497.4,53.069z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M567.413,74.477c1.045,0.501,2.129,0.703,2.713,1.354
    		c2.686,2.987,5.589,5.475,9.873,5.608c0.147,0.005,0.315,0.128,0.431,0.239c1.553,1.506,3.099,3.02,4.646,4.531
    		c-2.546,2.422-4.109-0.278-5.945-1.208c-4.315-2.187-8.59-4.458-12.82-6.803c-0.564-0.312-1.248-1.514-1.061-1.854
    		C565.663,75.596,566.597,75.133,567.413,74.477z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M455.411,48.334c-0.495,0.924-0.892,1.812-1.417,2.617
    		c-1.689,2.587-3.204,5.343-5.243,7.625c-0.957,1.071-2.959,1.208-4.488,1.768c0.194-1.257,0.021-2.751,0.65-3.726
    		c2.138-3.31,4.495-6.485,6.93-9.586c0.477-0.606,1.699-0.913,2.526-0.834C454.762,46.236,455.035,47.512,455.411,48.334z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M609.996,51.659c-1.707,1.449-3.039,2.845-4.649,3.742
    		c-0.36,0.2-1.804-1.25-2.552-2.11c-0.861-0.99-1.651-2.101-2.212-3.281c-1.079-2.277,0.134-3.795,2.004-4.935
    		c2.092-1.274,3.178-0.575,4.029,1.685C607.218,48.355,608.578,49.666,609.996,51.659z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M415.753,48.148c0.121-4.364,5.594-8.431,8.285-7.145
    		c0.66,0.316,1.081,2.283,0.772,3.213c-0.495,1.492-1.574,2.851-2.62,4.083c-1.049,1.235-2.359,2.248-3.555,3.358
    		C417.473,50.242,416.311,48.827,415.753,48.148z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M437.741,44.899c-3.372,2.863-6.469,5.909-10.015,8.283
    		c-1.273,0.853-3.123-0.752-1.848-2.528c2.095-2.917,4.553-5.601,7.094-8.146c0.514-0.514,2.115-0.126,3.141,0.139
    		C436.57,42.764,436.841,43.604,437.741,44.899z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M449.628,43.195c-1.27,1.642-1.943,2.342-2.419,3.156
    		c-2.023,3.466-6.592,4.979-7.285,9.516c-0.052,0.343-1.717,0.877-1.876,0.687c-0.566-0.674-1.358-1.971-1.08-2.413
    		c2.472-3.935,5.031-7.833,7.906-11.474C445.314,42.108,447.491,42.919,449.628,43.195z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M451.724,81.224c-4.283,0.004-7.771-4.89-6.561-7.787
    		c0.794-1.899,4.401-1.878,5.917,0.17c0.764,1.031,1.146,2.343,1.717,3.521c0.518,1.066,1.059,2.123,1.589,3.185
    		C453.197,80.719,452.01,81.126,451.724,81.224z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M444.301,295.461c-3.02,2.5-5.318,4.48-7.753,6.275
    		c-0.196,0.145-1.675-0.838-1.967-1.542c-1.217-2.932,2.238-8.227,5.407-7.995C441.211,292.288,442.323,293.899,444.301,295.461z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M449.125,304.828c0.077,3.27-2.75,6.954-5.098,7.49
    		c-2.216,0.506-3.892-1.038-3.013-2.995c0.87-1.937,2-3.842,3.4-5.419c0.719-0.811,2.345-1.301,3.43-1.126
    		C448.518,302.887,448.946,304.515,449.125,304.828z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M463.907,322.699c-1.863,2.498-3.353,4.495-4.843,6.492
    		c-1.369-1.68-3.853-3.343-3.877-5.042c-0.054-3.564,3.512-2.657,5.782-2.924C461.794,321.129,462.74,322.08,463.907,322.699z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M471.156,331.089c-1.71,1.978-3.062,3.54-4.413,5.104
    		c-0.949-1.062-2.48-2.005-2.727-3.21c-0.491-2.406,2.122-5.771,3.889-5.273C468.95,328.002,469.665,329.468,471.156,331.089z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M457.551,314.481c-2.451,1.801-4.028,3.247-5.88,4.094
    		c-0.402,0.184-2.711-2.475-2.576-2.655c1.276-1.71,2.784-3.319,4.515-4.551C453.903,311.16,455.575,312.89,457.551,314.481z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M426.635,258.836c1.941,0,2.831-0.2,3.569,0.049
    		c0.991,0.334,1.864,1.016,2.788,1.548c-0.968,0.588-1.884,1.303-2.919,1.732c-1.305,0.542-2.863,0.601-4.023,1.328
    		c-1.237,0.775-2.551,2.523-3.456,0.47c-0.502-1.137,0.47-3.349,1.432-4.553C424.631,258.653,426.45,258.868,426.635,258.836z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M577.963,308.6c-1.292,1.645-2.012,2.562-2.732,3.478
    		c-1.489-1.759-3.416-3.318-4.335-5.338c-0.78-1.712,0.859-3.713,2.604-2.956C575.222,304.53,576.315,306.725,577.963,308.6z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M432.911,282.793c0.818,0,1.716-0.209,2.43,0.063
    		c0.621,0.235,1.246,0.946,1.455,1.583c0.109,0.333-0.568,1.19-1.067,1.412c-2.055,0.908-4.145,1.775-6.293,2.418
    		c-0.774,0.232-1.751-0.209-2.636-0.343c0.146-0.757,0.026-1.815,0.483-2.215c1.572-1.374,3.347-2.518,5.044-3.749
    		C432.521,282.239,432.717,282.517,432.911,282.793z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M520.642,335.8c0.614,0.604,1.315,1.295,2.017,1.985
    		c-1.765,1.519-3.443,3.178-5.399,4.39c-0.265,0.164-2.803-1.941-2.776-2.956C514.58,335.438,518.179,337.078,520.642,335.8z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M485.729,60.862c-1.919,0.7-2.991,1.397-3.293,1.151
    		c-2.139-1.742-4.186-3.615-6.098-5.604c-0.392-0.408-0.385-1.695-0.027-2.191c0.361-0.501,1.776-0.947,2.062-0.687
    		C480.681,55.641,482.83,57.922,485.729,60.862z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M584.546,277.127c-0.93,0-1.848,0.231-2.546-0.062
    		c-0.766-0.321-1.328-1.124-1.979-1.718c0.698-0.563,1.363-1.553,2.104-1.612c2.391-0.193,4.956,0.541,6.667-2.016
    		c0.183-0.272,1.362-0.298,1.574-0.034c0.341,0.426,0.547,1.338,0.312,1.785C589.383,275.948,587.579,277.733,584.546,277.127z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M484.209,337.847c0.604-1.354,1.207-2.71,1.811-4.064
    		c1.399,1.235,4.071,2.664,3.919,3.663c-0.272,1.792-2.243,3.325-3.504,4.967c-0.566-0.447-1.455-0.798-1.626-1.364
    		c-0.273-0.91-0.073-1.963-0.073-2.956C484.56,338.01,484.384,337.928,484.209,337.847z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M509.439,340.842c-0.813,1.337-1.497,2.46-2.182,3.584
    		c-0.772-1.006-1.552-2.007-2.31-3.023c-0.085-0.114-0.059-0.319-0.064-0.482c-0.048-1.509-0.187-3.027-0.032-4.516
    		c0.023-0.226,1.934-0.646,2.094-0.402C507.873,337.419,508.537,339.008,509.439,340.842z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M562.97,321.287c-1.871,1.605-3.124,2.681-4.376,3.755
    		c-0.892-1.115-2.747-2.678-2.479-3.255c0.624-1.346,2.152-2.471,3.572-3.133C560.136,318.445,561.4,319.985,562.97,321.287z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M619.438,46.307c-2.125,1.143-3.545,2.504-4.369,2.208
    		c-1.198-0.431-1.966-2.058-2.917-3.174c1.042-0.839,2.182-2.444,3.102-2.327C616.461,43.168,617.495,44.681,619.438,46.307z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M595.432,83.37c-0.417-0.664-1.471-1.776-1.265-2.11
    		c1.317-2.134,2.913-4.097,4.416-6.115c0.572,0.444,1.699,1.156,1.633,1.294c-1.083,2.254-2.302,4.443-3.543,6.617
    		C596.566,83.243,596.084,83.216,595.432,83.37z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M624.917,46.606c-1.188-0.939-2.107-1.667-3.025-2.394
    		c0.897-0.729,1.842-2.111,2.683-2.052c1.736,0.125,3.417,1.02,5.121,1.597C628.233,44.63,626.771,45.501,624.917,46.606z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M542.964,334.899c-2.787,1.322-4.576,2.252-6.46,2.918
    		c-0.156,0.056-0.943-1.673-1.438-2.571c1.424-0.773,2.778-1.805,4.299-2.205C540.075,332.854,541.106,333.89,542.964,334.899z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M383.255,857.146c1.289,1.065,2.187,1.808,3.085,2.551
    		c-1.075,0.745-2.138,2.06-3.229,2.085c-1.233,0.027-2.493-1.104-3.741-1.73C380.541,859.175,381.713,858.299,383.255,857.146z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M583.432,269.282c-1.316-1.134-2.369-2.038-3.421-2.943
    		c1.376-0.516,2.772-1.479,4.121-1.415c1.261,0.06,2.47,1.21,3.701,1.881C586.542,267.531,585.251,268.258,583.432,269.282z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M587.517,282.404c-2.93,1.672-4.485,2.64-6.139,3.392
    		c-0.186,0.085-1.393-1.493-1.339-1.55c1.287-1.349,2.599-2.719,4.126-3.761C584.487,280.267,585.676,281.318,587.517,282.404z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M767.694,794.313c-0.856-2.313-1.566-3.514-1.652-4.757
    		c-0.045-0.645,1.015-1.365,1.575-2.053c0.773,1.038,1.885,1.986,2.193,3.147C769.99,791.33,768.888,792.35,767.694,794.313z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M432.374,269.742c-2.191,0.839-3.318,1.635-4.385,1.561
    		c-0.838-0.059-1.602-1.191-2.397-1.847c1-0.634,1.956-1.653,3.017-1.778C429.435,267.58,430.392,268.595,432.374,269.742z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M566.245,319.084c-0.88-1.814-1.73-2.754-1.622-3.567
    		c0.115-0.871,1.132-1.622,1.755-2.426c0.58,0.826,1.556,1.62,1.626,2.487C568.071,316.403,567.192,317.306,566.245,319.084z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M411.325,47.558c-2.124,0.726-3.274,1.495-4.287,1.344
    		c-0.785-0.117-1.399-1.384-2.09-2.14c1.019-0.603,2.006-1.592,3.065-1.677C408.756,45.025,409.597,46.202,411.325,47.558z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M635.413,102.505c1.107,0.011,2.552-0.412,3.247,0.128
    		c1.244,0.968,2.062,2.5,2.97,3.854c0.065,0.098-0.579,0.672-0.895,1.024c-1.079-1.028-2.165-2.051-3.236-3.089
    		c-0.674-0.652-1.326-1.326-1.988-1.991L635.413,102.505z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M451.1,90.585c-0.759-1.04-1.469-1.835-1.387-1.927
    		c0.908-1.022,1.927-1.95,2.971-2.838c0.054-0.046,0.917,0.664,0.879,0.731C452.887,87.769,452.129,88.94,451.1,90.585z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M431.428,249.588c0.962,0.779,1.665,1.348,2.368,1.917
    		c-0.749,0.661-1.418,1.65-2.278,1.86c-0.5,0.122-1.305-1.006-1.973-1.571C430.11,251.131,430.677,250.468,431.428,249.588z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M495.618,602.022c-1.292,0.758-2.117,1.632-2.834,1.551
    		c-0.691-0.079-1.27-1.145-1.897-1.776c0.717-0.556,1.414-1.514,2.157-1.55C493.727,600.214,494.458,601.171,495.618,602.022z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M580.178,296.031c-1.305,0.982-2.032,1.956-2.685,1.908
    		c-0.745-0.055-1.424-1.02-2.131-1.589c0.561-0.502,1.095-1.373,1.689-1.416C577.843,294.877,578.682,295.464,580.178,296.031z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M434.656,277.19c-0.795,0.695-1.428,1.633-1.89,1.555
    		c-0.596-0.101-1.577-1.171-1.465-1.541c0.229-0.752,1.082-1.313,1.678-1.952C433.472,275.821,433.964,276.391,434.656,277.19z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M538.65,266.363c-0.965,0.8-1.539,1.636-2.039,1.594
    		c-0.539-0.046-1.162-0.846-1.429-1.457c-0.095-0.219,0.733-1.188,1.16-1.202C536.946,265.277,537.568,265.826,538.65,266.363z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M309.373,451.89c-6.832,0.018-11.422-6.244-9.338-12.651
    		c0.404-1.242,0.617-2.586,0.674-3.893c0.169-3.887,2.336-6.271,5.887-6.994c3.216-0.653,4.164-2.532,3.85-5.3
    		c-0.334-2.95-2.386-4.125-5.856-3.727c-6.95,0.799-10.646-2.529-9.665-8.781c0.146-0.933,0.445-2.092,1.099-2.65
    		c3.693-3.162,7.073-7.043,12.768-6.17c2.496,0.383,3.599-0.851,3.806-3.374c0.157-1.906,0.926-3.754,1.252-5.657
    		c0.175-1.017,0.298-2.235-0.101-3.117c-0.88-1.949-2.427-3.634-3.105-5.629c-0.817-2.405-1.331-5.01-1.42-7.547
    		c-0.191-5.463,1.026-10.554,5.525-14.227c1.096-0.896,2.476-1.972,3.741-1.992c1.444-0.023,3.259,0.724,4.205,1.772
    		c0.438,0.486-0.609,2.601-1.319,3.772c-1.69,2.791-3.885,5.318-5.259,8.242c-0.658,1.401-0.295,3.635,0.392,5.13
    		c0.253,0.55,2.792-0.103,4.251,0.085c1.63,0.21,3.345,0.496,4.792,1.214c1.86,0.921,3.598,2.183,5.171,3.547
    		c0.646,0.561,1.28,2.281,0.98,2.604c-1.998,2.157-3.943,5.021-6.493,5.853c-3.564,1.16-5.924,2.714-6.662,6.338
    		c-0.825,4.049,1.631,6.455,4.471,8.731c1.395,1.117,3.755,3.436,3.521,3.789c-1.518,2.301-3.497,1.632-5.483,0.104
    		c-0.502-0.387-1.824-0.06-2.591,0.312c-4.019,1.945-4.307,1.776-6.154-2.028c-0.521-1.072-2.241-2.489-3.086-2.3
    		c-2.454,0.551-4.81,1.715-7.054,2.917c-0.576,0.309-1.067,1.921-0.761,2.439c0.374,0.634,1.729,1.187,2.489,1.016
    		c5.833-1.316,10.994,1.358,16.418,2.485c1.048,0.218,2.7,0.308,3.238-0.292c2.115-2.358,4.664-2.907,7.606-2.756
    		c0.602,0.03,1.773-0.43,1.761-0.6c-0.291-4.162,4.835-7.617,1.542-12.22c-0.659-0.921-1.134-3.064-0.632-3.555
    		c3.993-3.896,1.934-8.799,2.59-13.228c0.256-1.728,0.887-3.406,1.408-5.088c0.429-1.382,1.202-2.703,1.383-4.107
    		c0.98-7.646,10.625-12.241,17.023-7.935c0.764,0.514,1.562,2.247,1.25,2.813c-0.61,1.108-2.032,2.568-3.047,2.516
    		c-3.741-0.196-6.716,0.113-6.79,4.836c-5.92,4.338-4.56,10.956-5.979,16.946c-1.896,7.995-0.714,16.038-2.34,23.911
    		c-0.61,2.957,0.821,4.839,4.134,4.728c0.499-0.017,1.177-0.221,1.468,0.023c1.113,0.936,2.661,1.858,3.011,3.082
    		c0.574,2.013-1.007,2.837-2.929,3.069c-4.211,0.51-10.331-3.66-11.415-8.565c-0.663-3.003-1.3-3.439-3.717-1.621
    		c-3.214,2.418-6.81,3.229-10.748,1.934c-2.813-0.927-3.844,0.493-3.404,3.006c0.735,4.197-0.471,7-3.98,9.83
    		c-2.723,2.195-6.113,5.064-5.068,9.821c0.403,1.839,0.904,3.69,2.898,2.985c1.249-0.44,2.16-2.399,2.764-3.865
    		c0.301-0.731-0.734-1.925-0.623-2.843c0.186-1.528,0.374-3.698,1.362-4.315c1.103-0.688,3.793-0.572,4.574,0.293
    		c2.307,2.556,5.054,2.173,7.836,2.144c3.827-0.04,7.439,1.409,10.99,1.935c6.982,1.034,13.633,3.547,20.662,4.082
    		c0.495,0.037,1.044-0.183,1.488-0.039c1.409,0.456,2.779,1.027,4.165,1.554c-1.208,1.365-2.294,3.73-3.647,3.895
    		c-3.205,0.39-6.563-0.088-9.812-0.549c-3.234-0.458-6.416-1.309-9.614-2.014c-1.763-0.389-3.511-0.845-5.271-1.247
    		c-2.561-0.587-5.134-1.119-7.686-1.74c-1.677-0.408-3.458-0.681-4.956-1.465c-2.218-1.16-3.443-1.03-3.574,1.728
    		C317.899,450.359,316.348,451.871,309.373,451.89z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M583.056,454.995c3.752,0.449,7.253,0.403,10.292-2.951
    		c1.744-1.925,4.479-1.871,6.447,0.805c0.619,0.842,1.962,1.15,2.974,1.702c0.007-1.342,0.334-2.783-0.048-4.003
    		c-0.682-2.18-1.421-4.516-2.795-6.271c-3.338-4.262-2.332-9.12-2.676-13.86c-0.172-2.354-0.903-4.757-1.842-6.939
    		c-0.762-1.773-1.208-3.235,0.729-4.214c2.072-1.046,4.104-0.765,4.769,1.774c0.991,3.785,2.537,7.714,2.281,11.476
    		c-0.467,6.862,2.253,12.543,4.764,18.47c0.742,1.752,1.107,3.786,1.124,5.695c0.023,2.764-1.592,4.318-4.468,4.146
    		c-3.958-0.237-7.912-0.828-11.864-0.793c-5.956,0.055-11.906,0.569-17.862,0.79c-2.936,0.108-5.958,0.691-8.283-2.197
    		c-0.616-0.766-2.467-0.725-3.753-0.733c-7.663-0.052-15.326-0.016-22.989,0.004c-5.076,0.014-10.152,0.07-15.229,0.068
    		c-4.021-0.001-7.225-2.983-7.398-6.969c-0.17-3.926-1.143-7.58-2.146-11.374c-1.187-4.486-3.24-8.847-3.792-13.538
    		c-0.095-0.802-0.161-1.862-0.673-2.316c-2.788-2.476-2.292-5.705-2.271-8.839c0.017-2.246,0.187-4.28-3.193-4.316
    		c-2.87-0.031-2.89-1.653-1.048-3.497c1.176-1.177,2.306-2.4,3.501-3.558c3.366-3.26,4.654-3.062,7.176,0.882
    		c0.6,0.938,1.394,2.184,2.317,2.415c7.758,1.942,15.363,4.96,23.683,2.821c4.775-1.228,9.694-1.938,14.58-2.671
    		c1.073-0.161,2.279,0.57,3.425,0.891c-0.452,0.974-0.723,2.1-1.39,2.891c-1.811,2.145-4.029,2.978-7.032,2.813
    		c-3.672-0.2-7.482-0.739-10.824,2.324c-1.173,1.076-4.104,0.19-6.229,0.29c-1.096,0.052-2.184,0.301-3.275,0.461
    		c0.26,0.988,0.742,1.979,0.734,2.966c-0.023,2.772-0.01,5.595-0.572,8.279c-0.129,0.616-2.881,1.443-3.772,0.962
    		c-1.361-0.734-2.791-2.409-3.053-3.887c-0.485-2.747-0.048-5.645-0.189-8.47c-0.113-2.258-6.349-5.448-7.594-3.803
    		c-0.968,1.278-1.354,4.137-0.562,5.377c1.826,2.862,2.352,5.728,2.277,8.954c-0.021,0.909,0.582,1.871,1.029,2.743
    		c0.374,0.729,1.199,1.299,1.374,2.044c1.221,5.215,2.438,10.437,3.409,15.7c0.373,2.021,0.229,4.154,2.884,4.51
    		c2.447,0.327,2.802-1.417,3.429-3.315c1.075-3.257,2.545-3.438,5.427-1.271c0.69,0.519,1.867,1.204,2.423,0.948
    		c2.314-1.063,4.584-2.304,6.672-3.764c4.065-2.845,10.758-2.108,13.672,1.797c0.647,0.867,0.623,2.244,1.287,3.086
    		c0.808,1.022,1.995,2.347,3.061,2.386c0.955,0.036,2.757-1.442,2.815-2.341c0.217-3.338,2.066-3.683,4.735-3.504
    		c2.488,0.166,5.009-0.106,7.488,0.117c1.317,0.119,2.576,0.88,3.862,1.351c-0.905,1.285-1.631,2.77-2.767,3.801
    		c-1.15,1.044-4.339-0.363-3.774,2.487c0.149,0.752,3.314,1.017,5.128,1.289C580.59,455.286,581.78,455.053,583.056,454.995z
    		 M548.381,453.048c1.626-0.93,3.968-1.648,3.972-2.38c0.013-2.684-2.473-2.086-4.048-1.892c-1.361,0.169-2.621,1.162-3.927,1.79
    		C545.61,451.33,546.843,452.094,548.381,453.048z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M449.818,566.574c-1.363,6.002-2.468,12.079-4.172,17.983
    		c-1.35,4.676-9.444,6.066-14.005,2.983c-1.605-1.086-3.747-1.834-5.674-1.904c-5.818-0.21-11.7,0.456-17.464-0.132
    		c-4.132-0.421-8.515-1.636-12.043-3.74c-3.169-1.889-6.175-2.322-9.446-2.02c-3.07,0.284-5.291-0.443-7.431-2.826
    		c-3.995-4.448-5.793-4.372-11.044-1.179c-2.687,1.633-5.584,1.919-8.271-0.303c-0.746-0.617-1.48-1.483-2.347-1.679
    		c-3.343-0.754-4.488-2.507-3.229-5.79c0.908-2.367-0.55-2.972-2.503-3.012c-3.493-0.07-6.989-0.053-10.481-0.161
    		c-1.141-0.034-2.525-0.006-3.343-0.616c-0.934-0.696-2.13-2.431-1.832-3.073c0.486-1.046,2.062-2.151,3.231-2.211
    		c4.148-0.212,8.35-0.281,12.473,0.149c3.989,0.416,5.805-2.219,7.443-4.775c1.188-1.852,0.65-3.844-2.145-4.402
    		c-1.571-0.313-3.206-0.78-4.56-1.598c-1.866-1.126-2.225-2.735-0.601-4.568c0.861-0.974,1.456-2.182,2.189-3.271
    		c1.817-2.7,5.79-3.521,8.226-1.362c1.422,1.261,3.022,3.052,3.264,4.782c0.862,6.191,1.593,12.595-3.908,17.385
    		c-2.188,1.905-2.481,3.614-0.572,5.929c1.723,2.087,2.997,3.536,6.15,1.844c4.891-2.624,7.567-6.113,7.369-11.791
    		c-0.107-3.061-1.014-6.629,2.363-8.596c1.498-0.871,3.521-0.841,5.305-1.22c0,1.813,0.632,3.955-0.11,5.382
    		c-2.839,5.457-3.052,12.566,1.037,15.967c3.358,2.793,7.415,5.331,12.273,4.938c2.419-0.196,4.673-0.4,6.141,2.089
    		c0.228,0.385,0.765,0.75,1.206,0.82c7.08,1.114,14.154,2.288,21.268,3.142c1.077,0.129,3.314-1.274,3.317-1.976
    		c0.006-1.542-0.706-4.04-1.779-4.456c-3.342-1.295-7.093-2.773-10.496-2.412c-7.152,0.76-12.896-2.635-19.088-4.855
    		c-1.078-0.387-2.227-0.658-3.204-1.223c-1.278-0.738-3.308-1.608-3.428-2.612c-0.232-1.937-0.007-5.106,1.188-5.815
    		c3.759-2.234,7.992-3.759,12.189-5.096c0.943-0.301,2.76,1.028,3.688,2.05c1.42,1.562,0.521,3.13-1.688,3.219
    		c-0.638,0.026-1.516-0.613-1.894-0.362c-1.982,1.316-3.84,2.82-5.74,4.259c1.735,0.546,3.758,0.696,5.157,1.708
    		c5.37,3.881,11.422,2.233,17.253,2.719c3.922,0.327,7.793,1.559,11.622,2.621c2.786,0.772,4.855,5.85,3.792,8.674
    		c-0.459,1.218-1.229,2.461-1.215,3.685c0.029,2.473,2.855,4.023,4.555,2.384c1.81-1.747,3.639-4.066,4.146-6.42
    		c0.604-2.802,0.201-5.996-0.467-8.856c-0.973-4.164,0.68-7.122,3.397-9.807c0.506-0.499,1.554-0.936,2.126-0.74
    		c0.518,0.178,0.995,1.245,1.025,1.94c0.118,2.658,0.048,5.325,0.048,7.989C449.327,566.428,449.573,566.501,449.818,566.574z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M345.946,342.164c-2.521,0-4.354-0.044-6.183,0.011
    		c-2.921,0.087-4.681-1.792-4.545-4.184c0.155-2.743,2.503-2.747,4.951-2.264c2.578,0.509,5.281,0.447,7.933,0.51
    		c3.754,0.09,7.733-0.904,10.721,2.474c8.939,0.126,12.252,8.084,17.779,12.903c3.143,2.74,3.021,2.863,4.543-1.102
    		c0.688-1.79,2.005-3.355,3.129-4.957c1.549-2.209,3.604-5.077,6.267-3.642c2.276,1.228,4.227,4.218,4.969,6.815
    		c0.802,2.805,1.163,6.138-1.75,8.782c-2.306,2.092-4.141,3.949-7.157,1.266c-0.442-0.395-1.989-0.398-2.098-0.16
    		c-0.367,0.807-0.724,2.028-0.358,2.688c1.681,3.03,3.511,5.993,5.527,8.81c0.409,0.57,2.573,0.777,2.713,0.513
    		c1.759-3.344,4.563-1.898,7.07-1.72c2.006,0.143,4.485,0.642,4.926-1.935c0.228-1.328-1.301-2.94-1.989-4.446
    		c-0.697-1.526-2.157-3.288-1.83-4.577c0.657-2.589,2.479-4.635,5.607-4.938c2.526-0.244,3.571,1.173,4.363,3.207
    		c0.417,1.07,1.139,2.017,1.642,3.059c1.28,2.646,2.215,5.293-0.472,7.713c-0.243,0.22-0.525,0.482-0.614,0.777
    		c-2.261,7.491-7.94,7.818-14.297,6.971c-1.142-0.153-3.108,0.03-3.408,0.666c-0.557,1.179-0.413,2.859-0.116,4.235
    		c0.541,2.506,1.423,4.938,2.127,7.41c0.181,0.637,0.023,1.454,0.368,1.947c4.818,6.912,2.579,14.802,2.926,22.258
    		c0.418,8.969-0.335,17.995,0.153,26.957c0.295,5.417-2.644,9.255-4.589,13.65c-0.453,1.024-1.531,2.061-1.43,2.996
    		c0.564,5.244-2.666,9.027-4.743,13.262c-0.433,0.882-1.146,1.792-1.151,2.691c-0.042,9.109-4.988,16.033-10.06,22.901
    		c-0.677,0.916-1.483,1.854-2.443,2.417c-1.62,0.949-3.231,0.607-2.954-1.562c0.304-2.378,0.956-4.84,2.047-6.961
    		c2.005-3.897,4.471-7.556,6.671-11.356c0.401-0.691,0.695-1.607,0.625-2.382c-0.325-3.622,1.13-6.549,2.681-9.751
    		c2.537-5.238,4.183-10.915,6.091-16.445c0.367-1.062,0.104-2.324,0.319-3.458c0.638-3.354,1.42-6.681,2.047-10.036
    		c0.365-1.949,0.729-3.931,0.767-5.903c0.1-5.326,0.046-10.656,0.01-15.985c-0.021-3.103,0.137-5.985-2.128-8.895
    		c-1.344-1.727-0.712-5.112-0.702-7.751c0.039-9.947-3.749-18.59-7.856-27.468c-2.833-6.123-7.68-10.323-11.542-15.267
    		c-2.739-3.505-7.063-6.778-12.41-8.354C353.79,343.289,349.833,341.529,345.946,342.164z M388.529,355.728
    		c0.687-1.581,1.273-2.31,1.212-2.979c-0.054-0.596-0.826-1.125-1.281-1.684c-0.534,0.454-1.371,0.831-1.511,1.384
    		C386.824,352.944,387.469,353.633,388.529,355.728z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M543.994,529.053c-6.661,0-13.345,0.331-19.977-0.112
    		c-4.706-0.314-9.461-1.305-13.954-2.743c-1.858-0.596-3.919-2.923-4.455-4.858c-2.109-7.612-3.451-15.382-3.337-23.381
    		c0.035-2.467-1.436-4.919-1.81-7.438c-0.481-3.238-0.747-6.542-0.659-9.806c0.021-0.785,1.807-2.128,2.761-2.11
    		c0.925,0.018,2.214,1.296,2.629,2.312c0.469,1.146,0.109,2.617,0.166,3.944c0.057,1.322-0.34,3.143,0.365,3.879
    		c4.491,4.693,2.755,10.433,2.55,15.674c-0.224,5.698,1.202,10.771,3.528,15.721c0.47,0.999,1.805,1.947,2.912,2.218
    		c2.235,0.544,4.586,0.821,6.892,0.833c14.321,0.07,28.646,0.164,42.963-0.065c3.003-0.048,6.158-1.024,8.938-2.259
    		c5.159-2.292,4.845-1.931,9.778,1.198c2.527,1.602,6.569,0.85,9.934,1.028c0.265,0.015,0.953-1.183,0.799-1.537
    		c-0.278-0.643-1.019-1.486-1.609-1.521c-2.147-0.129-4.164-0.202-3.89-3.155c0.212-2.275,3.97-5.775,6.255-5.642
    		c2.555,0.148,5.393,0.651,5.44,3.893c0.039,2.69-0.376,5.581-1.408,8.04c-1.034,2.465-0.344,3.001,1.825,3.083
    		c1.163,0.044,2.332,0.008,3.496-0.041c4.93-0.208,6.454-2.931,4.053-7.202c-0.565-1.007-1.637-2.074-1.562-3.043
    		c0.693-9.087-4.496-17.179-4.289-26.152c0.02-0.825-0.236-2.038,0.197-2.402c1.372-1.152,3.011-1.987,4.547-2.945
    		c0.659,1.501,2.282,3.503,1.808,4.415c-1.862,3.587-0.312,7.009,1.223,9.392c1.831,2.848,1.762,5.505,2.065,8.425
    		c0.219,2.098,0.739,4.249,1.579,6.179c2.792,6.42,1.117,14.5-4.987,17.994c-2.642,1.513-7.126,1.757-9.934,0.577
    		c-4.282-1.799-8.547-1.833-12.877-2.443c-2.331-0.328-4.526-1.554-6.854-2.008c-1.562-0.305-3.301-0.198-4.869,0.142
    		c-1.966,0.425-3.825,1.803-5.756,1.854c-8.153,0.216-16.316,0.092-24.477,0.092C543.994,529.069,543.994,529.062,543.994,529.053z"
    		/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M472.758,392.866c0.174-3.804,0.021-7.266-2.217-10.878
    		c-2.212-3.572,4.299-12.136,8.92-12.059c3.285,0.055,5.013-0.745,5.456-4.175c0.123-0.952,1.774-2.586,2.277-2.433
    		c1.215,0.369,2.75,1.428,3.091,2.535c0.567,1.845,0.346,3.945,0.373,5.938c0.031,2.281,1.14,3.379,3.403,3.3
    		c2.091-0.072,5.084,0.542,4.996-2.567c-0.155-5.498,5.637-6.16,7.379-9.992c0.137-0.303,0.865-0.46,1.325-0.472
    		c2.709-0.072,7.292,7.127,5.937,9.572c-1.443,2.605-3.161,5.083-4.987,7.439c-0.608,0.783-1.877,1.184-2.922,1.477
    		c-3.37,0.946-4.387,2.753-2.624,5.724c1.088,1.833,2.82,3.283,4.263,4.905c0.316-1.997,0.844-3.988,0.903-5.993
    		c0.112-3.745,2.539-6.193,6.895-7.146c2.999-0.656,5.958-1.632,8.811-2.772c2.114-0.846,3.647-0.553,5.508,0.746
    		c5.007,3.499,6.709,3.201,11.212-0.823c0.805-0.72,2.756-1.408,3.198-1.014c0.858,0.767,1.065,2.298,1.46,3.539
    		c0.334,1.051,0.552,2.138,0.822,3.209c1.262-0.771,2.821-1.307,3.684-2.395c0.579-0.729,0.032-2.269,0.247-3.397
    		c0.193-1.016,0.737-1.966,1.126-2.944c1.139,0.495,2.612,0.697,3.354,1.542c2.12,2.416,1.559,8.183-0.765,10.496
    		c-3.275,3.261-6.425,3.42-10.076,0.835c-1.105-0.782-2.654-1.19-4.033-1.288c-4.178-0.294-8.736,1.333-12.091-2.667
    		c-4.196,1.071-8.419,2.059-12.534,3.38c-0.316,0.101-0.397,3.001,0.312,3.837c2.08,2.451,0.841,3.809-1.016,5.33
    		c-4.887,4.007-11.36,3.028-14.576-2.335c-1.184-1.976-2.463-4.002-3.092-6.181c-1.438-4.985-2.892-5.853-7.021-2.997
    		c-2.923,2.021-4.498,2.165-5.792-1.668c-0.757-2.241-2.419-4.817-5.618-3.358c-3.137,1.431-3.312,4.376-1.736,6.77
    		c3.035,4.605,2.04,9.579,2.156,14.481c0.05,2.151-0.407,4.271-3.052,4.265c-2.662-0.007-3.015-2.097-2.964-4.275
    		C472.792,396.527,472.758,394.696,472.758,392.866z M504.116,376.074c0.59-0.157,1.18-0.315,1.77-0.474
    		c0.871-1.542,1.858-3.037,2.508-4.667c0.119-0.299-1.132-1.145-1.751-1.737c-1.214,1.45-2.584,2.807-3.544,4.409
    		C502.826,374.062,503.744,375.231,504.116,376.074z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M699.037,508.071c-0.958,2.868-2.283,5.838-2.884,8.947
    		c-0.486,2.516-0.366,5.247,0.077,7.778c0.117,0.67,2.405,1.3,3.725,1.361c6.319,0.296,9.781,5.972,6.527,11.381
    		c-2.111,3.509-4.921,6.591-7.31,9.942c-0.835,1.171-1.345,2.574-2.003,3.871c1.176,0.426,2.321,0.981,3.531,1.252
    		c4.002,0.896,6.167,3.731,7.175,7.841c1.33,5.424-1.32,10.406-1.111,15.676c0.043,1.101-0.991,2.512-1.926,3.323
    		c-1.625,1.413-4.132,0.332-3.814-1.739c0.534-3.498-0.579-7.163,1.978-10.536c2.986-3.938,0.943-7.984-3.934-8.507
    		c-2.433-0.26-4.935-0.115-7.379,0.118c-0.667,0.063-1.241,1.111-1.857,1.708c0.731,0.421,1.42,0.993,2.207,1.222
    		c0.841,0.244,1.776,0.163,2.67,0.224c-0.291,1.026-0.5,2.084-0.895,3.069c-0.479,1.197-0.96,2.446-1.724,3.461
    		c-0.706,0.938-1.785,2.206-2.702,2.208c-0.925,0.002-2.453-1.242-2.659-2.175c-0.493-2.229-1.492-3.371-3.704-4.074
    		c-2.768-0.88-2.804-3.423-2.531-5.839c0.268-2.377,2.016-2.396,3.929-2.845c2.066-0.485,4.14-1.736,5.729-3.183
    		c1.03-0.938,1.197-2.825,1.751-4.287c-1.316-0.469-2.702-0.805-3.932-1.443c-1.289-0.67-2.429-1.629-3.634-2.462
    		c1.148-1.037,2.232-2.162,3.478-3.065c0.456-0.331,1.295-0.121,1.957-0.181c2.863-0.26,3.75-1.934,1.869-3.938
    		c-2.136-2.275-1.511-3.475-0.351-6.165c1.244-2.887,0.656-7.156-0.516-10.308c-0.955-2.566-1.059-4.104-0.166-6.753
    		c1.313-3.9,1.21-8.305,1.439-12.51c0.027-0.512-1.801-1.729-2.444-1.537c-1.04,0.312-2.251,1.373-2.595,2.388
    		c-0.555,1.639,0.139,4.501-0.773,5.048c-1.833,1.1-4.434,0.83-6.645,1.406c-3.754,0.977-7.449,2.175-11.173,3.269
    		c-0.791,0.232-1.734,0.786-2.369,0.551c-2.035-0.758-5.426-1.566-5.638-2.808c-0.528-3.095,2.767-3.097,4.942-3.403
    		c3.928-0.554,7.891-0.898,11.85-1.173c3.148-0.219,4.982-2.021,4.05-4.819c-1.19-3.574,1.382-5.058,3.238-5.704
    		c2.006-0.698,4.814-0.047,6.938,0.828c2.019,0.833,4.177,2.386,5.233,4.221C697.963,501.969,698.186,504.848,699.037,508.071z
    		 M702.253,531.528c-0.518-0.118-1.034-0.236-1.551-0.354c-1.847,2.146-3.794,4.219-5.462,6.496
    		c-0.381,0.52-0.006,2.389,0.387,2.517c0.907,0.296,2.384,0.288,3.012-0.28c1.691-1.527,3.256-3.292,4.443-5.226
    		C703.476,534.041,702.566,532.602,702.253,531.528z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M606.143,390.025c-2.497,0-4.994,0.052-7.489-0.023
    		c-1.129-0.033-2.782,0.097-3.29-0.555c-3.38-4.331-7.933-2.379-12.061-2.496c-5.247-0.149-10.59,0.436-15.709-0.406
    		c-2.124-0.35-4.368-3.275-5.497-5.556c-1.482-2.993-1.944-6.499-2.783-9.8c-0.243-0.953-0.391-1.961-0.383-2.942
    		c0.057-7.478,0.535-14.98-1.672-22.29c-0.143-0.471-0.379-1.156-0.167-1.448c0.687-0.947,1.434-2.089,2.423-2.475
    		c0.455-0.177,1.888,1.081,2.252,1.951c1.063,2.539,1.986,5.169,2.623,7.845c0.413,1.736,0.206,3.615,0.316,5.429
    		c0.342,5.618,0.152,11.341,1.205,16.823c1.14,5.936,9.259,9.984,15.056,8.042c3.029-1.016,5.871-2.598,8.787-3.944
    		c2.573-1.188,4.235-0.492,4.685,2.364c0.316,2.013,0.954,3.718,3.351,3.424c2.524-0.311,2.225-2.412,2.349-4.249
    		c0.343-5.1,3.439-7.997,8.234-7.816c4.348,0.164,6.283,2.636,6.623,8.066c0.071,1.137,0.88,2.229,1.349,3.341
    		c0.929-0.783,2.212-1.395,2.697-2.394c0.602-1.241,1.061-2.957,0.688-4.2c-2.566-8.544-2.918-17.256-2.36-26.055
    		c0.082-1.298,0.263-2.89,1.047-3.761c0.834-0.928,2.485-1.634,3.692-1.52c0.732,0.069,1.759,1.848,1.827,2.912
    		c0.09,1.39-0.407,2.949-1.059,4.229c-1.859,3.652-2.62,7.137,0.221,10.693c0.572,0.717,0.871,1.825,0.908,2.769
    		c0.191,4.817,0.469,9.646,0.306,14.457c-0.175,5.149-4.421,9.108-9.674,9.362c-2.824,0.137-5.662,0.024-8.493,0.024
    		C606.143,389.894,606.143,389.959,606.143,390.025z M606.406,385.222c1.375-1.953,2.713-3.018,2.704-4.071
    		c-0.009-1.109-1.306-2.208-2.039-3.312c-1.065,0.973-2.687,1.79-3.016,2.969C603.805,381.71,605.186,383.068,606.406,385.222z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M394.543,496.158c4.25,1.013,6.491-0.801,8.885-2.792
    		c2.33-1.938,5.046-3.454,7.748-4.855c0.396-0.205,2.112,1.088,2.432,1.971c1.215,3.368,2.092,6.857,3.14,10.288
    		c0.135,0.44,0.515,1.132,0.811,1.148c4.109,0.218,6.843,4.229,11.525,3.535c4.885-0.722,9.965-0.282,14.949-0.091
    		c2.082,0.08,4.12,0.966,6.211,1.244c2.725,0.363,5.963,1.51,8.123,0.495c4.198-1.971,8.357-1.758,12.632-1.787
    		c4.16-0.028,8.331,0.189,12.475-0.064c2.213-0.136,4.572-0.665,6.524-1.676c4.033-2.088,7.896-0.533,9.01,4.013
    		c0.909,3.708-3.144,11.154-6.662,12.243c-3.309,1.023-8.837-1.785-10.349-5.71c-0.993-2.58-2.59-3.03-4.995-2.962
    		c-10.806,0.31-21.612,0.6-32.421,0.718c-7.134,0.078-14.303,0.21-21.389-0.426c-2.651-0.237-5.125-2.562-7.661-3.968
    		c-3.114-1.727-5.874-3.397-5.235-7.957c0.418-2.985-1.72-3.687-4.647-1.9c-3.044,1.858-5.964,3.989-9.188,5.453
    		c-1.528,0.694-4.109,0.776-5.333-0.097c-1.009-0.721-1.506-3.758-0.804-4.856C391.135,496.855,393.51,496.587,394.543,496.158z
    		 M491.985,507.799c-0.733,1.328-1.602,2.196-1.46,2.843c0.145,0.658,1.283,1.099,1.987,1.635c0.446-0.627,1.306-1.305,1.237-1.869
    		C493.659,509.657,492.845,508.994,491.985,507.799z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M422.225,373.216c0,2.165-0.093,4.335,0.02,6.494
    		c0.235,4.506,4.988,8.264,9.376,7.36c0.832-0.171,1.525-1.018,2.283-1.552c-0.616-0.624-1.156-1.356-1.865-1.847
    		c-1.096-0.759-2.307-1.352-3.469-2.015c1.143-1.128,2.149-2.998,3.455-3.236c3.049-0.556,6.284-0.684,9.361-0.308
    		c2.55,0.312,2.114,2.663,1.492,4.413c-0.828,2.329-0.599,3.962,2.146,4.366c2.693,0.397,4.267-0.809,4.086-3.581
    		c-0.289-4.435-0.777-8.857-1.197-13.283c-0.075-0.801-0.472-1.65-0.306-2.383c1.192-5.273,2.371-10.557,3.842-15.756
    		c0.357-1.264,1.789-2.224,2.727-3.324c1.164,1.67,3.713,4.148,3.282,4.886c-5.066,8.672-1.79,17.959-2.431,26.91
    		c-0.225,3.136-0.177,6.044-2.548,8.792c-4.06,4.705-7.487,5.662-12.569,2.186c-2.027-1.386-3.588-1.833-5.756-0.083
    		c-3.815,3.078-8.244,1.493-12.374,0.727c-0.907-0.168-1.554-2.227-2.143-3.498c-0.723-1.562-1.562-1.982-3.07-0.923
    		c-1.578,1.107-3.225,2.13-4.902,3.082c-6.677,3.788-9.466,2.99-12.01-3.93c-0.554-1.506-0.117-4.126,0.921-5.292
    		c1.583-1.778,3.264-0.096,4.526,1.329c0.222,0.249,0.451,0.49,0.681,0.731c3.193,3.348,6.097,2.927,7.32-1.575
    		c0.852-3.13,4.311-6.273,0.884-9.967c-0.383-0.412,0.265-1.855,0.543-2.788c0.508-1.696,0.976-3.423,1.692-5.034
    		c0.547-1.229,1.453-2.298,2.199-3.438c1.169,1.025,2.904,1.845,3.369,3.125c0.651,1.791,0.377,3.926,0.455,5.914
    		c0.046,1.164,0.009,2.332,0.009,3.497C422.245,373.216,422.234,373.216,422.225,373.216z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M653.368,526.65c-4.983-0.317-5.61-0.785-5.722-5.415
    		c-0.236-9.808-0.492-19.619-0.432-29.428c0.062-10.061,0.2-20.14,0.902-30.168c0.29-4.151,2.211-8.174,3.226-12.296
    		c0.316-1.286,0.312-2.744,0.077-4.056c-0.532-2.976-1.115-5.791,1.094-8.5c0.856-1.05,0.879-2.755,1.385-4.123
    		c0.643-1.741,1.425-3.431,2.08-5.168c0.395-1.048,1.101-2.229,0.911-3.202c-0.827-4.259,1.617-7.214,3.528-10.523
    		c0.979-1.696,1.116-3.907,1.496-5.911c0.237-1.255-0.13-2.719,0.374-3.813c2.737-5.947,5.744-11.771,8.488-17.716
    		c1.56-3.38,2.669-6.97,4.239-10.345c0.604-1.297,1.733-2.672,2.978-3.25c1.061-0.492,2.628,0.107,3.972,0.224
    		c-0.206,1.312,0.021,3.404-0.691,3.798c-3.092,1.707-2.684,4.994-4.034,7.259c-2.812,4.716-1.505,11.373-7.055,14.66
    		c-0.622,0.368-1.175,1.313-1.262,2.05c-0.718,6.083-3.796,11.378-5.53,17.082c-2.743,9.015-4.42,18.354-6.532,27.559
    		c-0.11,0.479,0.133,1.175-0.128,1.465c-5.144,5.73-2.176,12.682-2.86,19.063c-0.297,2.771,0.3,5.587-2.104,7.901
    		c-0.767,0.739-0.792,2.466-0.799,3.742c-0.045,9.655-0.026,19.312,0.039,28.966c0.01,1.472-0.111,3.262,0.634,4.355
    		c3.466,5.084,2.126,10.513,1.597,15.914L653.368,526.65z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M503.562,473.659c-3.002-1.061-5.978-2.207-9.018-3.145
    		c-1.398-0.432-2.925-0.443-4.391-0.668c-5.324-0.816-10.642-1.685-15.975-2.444c-1.965-0.28-3.987-0.575-5.944-0.406
    		c-3.494,0.301-5.01-2.263-6.71-4.398c-0.563-0.708-0.646-2.589-0.107-3.248c2.446-2.993,4.661-6.363,8.855-7.318
    		c7.479-1.703,8.802-4.961,4.62-11.284c-0.54-0.817-1.811-1.604-1.739-2.303c0.962-9.482-5.301-18.151-3.07-27.694
    		c0.304-1.3,1.549-3.166,2.599-3.343c2.22-0.373,3.055,1.479,3.072,3.566c0.02,2.331,0.355,4.714-0.01,6.983
    		c-0.937,5.823,1.965,10.519,4.021,15.507c0.735,1.784,1.48,3.688,1.601,5.578c0.231,3.627-0.248,7.185,2.604,10.319
    		c1.649,1.812,0.146,4.218-3.213,5.379c-3.441,1.189-7.012,2.001-10.486,3.102c-0.825,0.261-1.494,1.014-2.235,1.54
    		c0.844,0.59,1.777,1.778,2.515,1.667c3.396-0.513,6.742,2.24,10.198-0.023c0.719-0.471,2.568-0.186,3.264,0.447
    		c3.067,2.793,6.754,2.291,10.381,2.54c2.065,0.143,4.081,0.934,6.136,1.346c1.757,0.352,3.529,0.701,5.311,0.834
    		c2.529,0.188,5.491,0.005,5.537,3.486c0.05,3.786-3.021,3.175-5.497,3.115c-0.665-0.017-1.332-0.003-1.998-0.003
    		C503.774,473.081,503.668,473.37,503.562,473.659z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M638.878,366.012c-0.001,3.83,0.257,7.683-0.088,11.48
    		c-0.244,2.678-2.396,5.682-1.625,7.777c0.525,1.428,4.803,1.188,7.092,2.32c3.439,1.7,9.21,1.435,11.517-1.4
    		c0.765-0.94,0.83-2.725,0.628-4.032c-0.073-0.479-2.007-1.196-2.814-0.938c-2.252,0.72-4.176,0.397-5.108-1.653
    		c-0.52-1.141-0.408-3.6,0.322-4.074c1.809-1.175,4.118-2.021,6.263-2.105c1.849-0.074,3.777,1.008,5.609,1.735
    		c0.697,0.276,1.474,0.868,1.786,1.521c3.539,7.396-1.574,15.852-9.801,16.138c-3.714,0.129-7.256,1.862-11.208,0.147
    		c-4.258-1.847-8.761-7.036-8.745-11.714c0.031-9.081,0.176-18.161,0.285-27.242c0.012-0.998-0.218-2.174,0.222-2.952
    		c0.714-1.265,1.89-2.27,2.87-3.384c0.932,1.13,2.598,2.216,2.669,3.399C639.053,356.012,638.879,361.017,638.878,366.012z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M450.666,437.104c2.014-0.062,4.127,0.021,4.235,3.203
    		c0.028,0.829,0.775,1.634,1.192,2.45c0.914-0.598,2.178-0.999,2.651-1.844c0.547-0.977,0.9-2.606,0.44-3.48
    		c-0.741-1.408-2.608-2.247-3.268-3.667c-0.608-1.311-0.543-3.142-0.175-4.591c0.137-0.536,2.392-1.213,2.96-0.811
    		c1.016,0.721,1.521,2.192,2.172,3.387c1.067,1.955,1.702,4.305,3.186,5.846c5.409,5.615,2.083,8.448-2.49,11.159
    		c-2.442,1.447-4.866,3.257-6.621,5.445c-1.93,2.407-3.811,3.949-7.032,3.767c-3.423-0.193-6.925,0.332-10.275-0.196
    		c-2.823-0.445-5.672-1.637-8.12-3.134c-1.073-0.657-1.18-2.896-1.726-4.417c1.823-0.218,4.569-1.302,5.315-0.508
    		c4.104,4.366,9.179,1.654,13.715,2.407c0.545,0.091,1.854-1.012,1.784-1.225c-0.278-0.851-0.889-1.635-1.512-2.321
    		c-1.242-1.368-3.3-2.457-3.724-4.015C442.312,440.657,446,437.014,450.666,437.104z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M589.016,577.377c0.161,7.552-3.813,11.915-10.541,14.767
    		c-2.562,1.086-4.718,2.779-7.756,2.622c-2.452-0.127-4.629,0.454-6.387-2.423c-1.321-2.161-3.527-0.627-5.598,0.486
    		c-3.527,1.896-7.438,3.125-11.275,4.365c-0.816,0.264-2.006-0.628-3.024-0.986c0.568-1.091,0.857-2.663,1.756-3.184
    		c2.937-1.702,6.01-3.249,9.187-4.438c6.037-2.26,7.97-5.951,5.849-11.854c-0.387-1.076-1.097-2.251-0.922-3.252
    		c0.159-0.916,1.191-1.867,2.081-2.388c0.419-0.245,1.78,0.276,2.072,0.806c1.273,2.302,2.779,4.641,3.279,7.15
    		c0.374,1.879-0.521,4.079-1.117,6.057c-0.364,1.208-1.401,2.207-1.808,3.41c-0.221,0.656,0.244,1.543,0.399,2.325
    		c0.756-0.15,1.525-0.249,2.263-0.462c1.941-0.561,3.94-1.006,5.786-1.8c3.069-1.32,6.204-2.62,8.977-4.439
    		c1.419-0.931,1.53-2.855-0.33-4.189c-1.066-0.764-2.416-1.598-2.819-2.699c-0.474-1.297-0.18-2.935,0.026-4.39
    		c0.076-0.54,0.77-1.104,1.313-1.448c2.068-1.315,3.307-5.534,6.203-3.432c1.791,1.3,1.981,4.867,2.758,7.456
    		C589.556,575.997,589.155,576.727,589.016,577.377z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M700.875,432.011c3.365-1.054,6.877-1.793,10.044-3.272
    		c2.078-0.97,3.332-8.962,2.229-10.966c-1.162-2.11-3.541-1.951-5.755,0.18c-0.798,0.769-1.989,1.128-3.001,1.675
    		c-0.248-1.306-0.614-2.603-0.699-3.918c-0.051-0.784,0.468-1.591,0.513-2.399c0.164-2.974,1.389-4.29,4.516-3.365
    		c1.252,0.37,2.63,0.337,3.955,0.43c2.084,0.146,3.674-0.343,3.503-2.93c-0.021-0.327-0.072-0.76,0.1-0.978
    		c1.271-1.61,2.596-3.18,3.902-4.762c0.664,0.568,1.976,1.285,1.885,1.68c-0.576,2.5,1.343,5.828-2.509,7.322
    		c-3.272,1.269-4.475,5.833-2.308,8.398c2.865,3.39,2.843,7.717,0.826,10.599c-2.762,3.948-1.673,6.694,0.373,9.917
    		c0.179,0.28,0.361,0.559,0.549,0.833c4.254,6.231,3.824,7.922-3.137,10.898c-0.974,0.416-2.395-0.216-3.608-0.363
    		c0.149-1.078,0.034-2.299,0.519-3.194c0.526-0.972,1.978-1.497,2.362-2.477c0.485-1.236,0.855-3.024,0.312-4.075
    		c-2.743-5.295-8.928-6.999-14.605-4.677c-1.526,0.624-3.741,0.67-5.207,0.015c-1.36-0.608-2.143-2.509-3.18-3.841
    		c1.394-0.532,2.749-1.247,4.191-1.543c1.275-0.261,2.646-0.055,3.977-0.055C700.705,431.433,700.79,431.722,700.875,432.011z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M410.321,429.942c-0.415-4.087,2.693-5.772,5.341-7.646
    		c1.451-1.026,3.135-1.82,4.824-2.387c2.543-0.853,4.814-1.4,5.255-4.898c0.254-2.014,2.779-2.577,4.421-1.104
    		c1.855,1.666,5.036,3.146,3.762,6.338c-1.101,2.754-3.074,5.355-6.711,4.939c-3.625-0.415-5.975,1.093-7.838,4.092
    		c-1.221,1.964-2.14,3.537-0.586,5.858c0.736,1.099,0.344,2.9,0.68,4.333c0.539,2.306,1.272,4.565,1.895,6.852
    		c0.3,1.102,0.752,2.219,0.759,3.331c0.022,3.656-0.229,7.152,2.867,10.146c1.785,1.726-1.086,6.808-3.662,6.71
    		c-0.716-0.027-1.696-1.281-2.018-2.168c-1.658-4.562-3.153-9.185-4.675-13.796c-0.361-1.095-0.734-2.212-0.877-3.347
    		c-0.612-4.859,0.192-9.991-3.09-14.323C410.155,432.197,410.417,430.935,410.321,429.942z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M632.951,463.859c-6.138,0-11.596-0.014-17.054,0.007
    		c-2.021,0.008-3.842-0.302-3.598-2.9c0.206-2.191,1.575-3.179,3.807-2.883c2.604,0.345,5.203,0.727,7.816,0.974
    		c1.073,0.103,2.196,0.003,3.258-0.206c0.783-0.154,1.661-1.095,2.226-0.899c6.325,2.188,6.537-3.735,8.76-6.71
    		c1.165-1.561-0.364-2.582-1.902-2.154c-2.805,0.779-6.134,0.758-6.832-1.818c-0.453-1.674,2.26-4.332,3.795-6.367
    		c0.878-1.163,2.112-2.436,3.42-2.789c1.318-0.355,3.693-0.159,4.285,0.701c4.376,6.365,6.186,12.753,1.158,19.972
    		C639.678,462.25,637.028,465.032,632.951,463.859z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M270.293,457.124c1.066-2.85,2.107-5.709,3.206-8.546
    		c1.17-3.024,2.191-6.134,3.683-8.994c0.743-1.424,2.395-2.374,3.636-3.539c0.674,1.283,1.731,2.507,1.938,3.861
    		c0.375,2.455,0.244,4.872-1.891,6.879c-0.788,0.741-0.625,2.45-0.995,3.688c-0.451,1.507-0.746,3.149-1.572,4.436
    		c-1.747,2.716-0.589,7.524,2.437,8.563c0.472,0.162,1.835-0.785,1.932-1.362c0.146-0.879-0.906-2.04-0.64-2.798
    		c0.512-1.456,1.312-3.192,2.525-3.896c0.893-0.519,2.639,0.391,3.987,0.736c0.267,0.069,0.43,0.491,0.672,0.719
    		c7.254,6.828,7.359,7.801-1.314,12.191c-2.58,1.306-6.943,1.249-9.514-0.076c-4.247-2.189-9.069-5.086-6.975-11.734
    		C271.037,457.21,270.665,457.167,270.293,457.124z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M624.868,505.899c-0.769,6.409-1.279,12.69,1.878,18.582
    		c1.334,2.489-0.884,8.161-3.415,9.685c-0.78,0.469-2.158,0.254-3.115-0.076c-0.282-0.097-0.122-1.661-0.061-2.545
    		c0.203-2.904,1.324-6.078,0.515-8.651c-1.979-6.285-2.64-12.596-2.58-19.096c0.012-1.228-0.402-2.604-1.033-3.667
    		c-2.766-4.651-2.434-10.51,1.086-14.185c0.474-0.494,1.69-0.953,2.089-0.695c0.72,0.465,1.388,1.429,1.543,2.28
    		C622.881,493.641,623.854,499.773,624.868,505.899z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M320.402,490.656c-2.827,0-5.653,0.016-8.479-0.009
    		c-0.991-0.009-2.308,0.271-2.921-0.244c-4.384-3.674-9.714-2.619-14.715-3.203c-1.795-0.209-4.275-0.209-3.772-2.696
    		c0.234-1.155,2.615-2.654,4.011-2.641c3.374,0.031,6.736,0.886,10.111,1.353c0.939,0.13,1.992,0.333,2.843,0.051
    		c5.833-1.937,11.513-2.786,17.142,0.803c1.112,0.71,2.893,0.724,4.301,0.548c2.895-0.361,4.735,0.736,4.83,3.562
    		c0.099,2.966-2.445,2.555-4.376,2.589c-2.99,0.052-5.982,0.014-8.974,0.014C320.402,490.739,320.402,490.698,320.402,490.656z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M716.195,475.309c0-2.164-0.067-4.33,0.016-6.49
    		c0.111-2.9-0.829-4.542-4.029-5.144c-4.461-0.838-4.312-1.091-5.21,3.515c-0.217,1.114-1.114,2.588-2.05,2.926
    		c-0.85,0.307-2.318-0.51-3.258-1.188c-1.186-0.854-2.168-2.022-3.134-3.145c-1.022-1.188-2.959-3.248-2.699-3.6
    		c1.202-1.629,2.905-3.707,4.659-3.948c4.249-0.585,8.645-0.493,12.947-0.186c3.5,0.25,8.717,6.705,8.456,10.271
    		c-0.332,4.53,1.479,9.423-1.899,13.482c-0.842,1.013-2.072,1.701-3.125,2.539c-0.297-1.183-0.757-2.352-0.854-3.55
    		c-0.146-1.817-0.037-3.654-0.037-5.483C716.051,475.309,716.123,475.309,716.195,475.309z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M321.17,558.82c-5.077-0.012-10.286,0.731-14.795-2.563
    		c-0.748-0.546-1.498-1.089-2.247-1.634c0.988-1.271,1.847-2.693,3.036-3.738c0.435-0.382,2.145-0.178,2.435,0.285
    		c1.463,2.332,3.631,1.905,5.769,1.888c3.649-0.031,7.3,0.007,10.95-0.004c6.089-0.017,9.796-3.706,9.936-9.894
    		c0.03-1.326-0.271-2.723,0.054-3.962c0.292-1.115,1.413-2.988,1.857-2.903c1.264,0.242,3.232,1.179,3.374,2.084
    		c0.426,2.719,0.794,5.784-0.059,8.293c-1.548,4.556-4.58,8.374-8.672,11.093c-1.012,0.672-2.425,0.94-3.675,1.011
    		C326.486,558.924,323.825,558.82,321.17,558.82z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M460.088,385.686c1.859-5.438-0.144-9.689-1.248-14.206
    		c-0.961-3.928-0.924-8.277-0.375-12.312c0.232-1.709,2.678-3.552,4.543-4.449c1.205-0.58,4.223,4.303,3.595,6.126
    		c-2.668,7.742,1.42,15.295,0.488,22.966c-0.018,0.145,0.191,0.323,0.163,0.459c-0.38,1.807-0.545,3.72-1.312,5.35
    		c-0.259,0.552-2.597,0.907-3.037,0.447C461.639,388.74,460.855,386.952,460.088,385.686z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M618.608,429.061c-0.608,6.991,1.896,13.179,4.638,19.393
    		c0.691,1.565,0.518,3.929-0.191,5.505c-0.316,0.703-2.955,0.528-4.516,0.415c-0.574-0.042-1.497-1.02-1.548-1.629
    		c-0.489-5.897-2.521-11.313-4.274-16.908c-1.422-4.54-0.526-9.862-0.277-14.818c0.048-0.958,2.239-2.77,2.782-2.551
    		c1.334,0.537,2.961,1.882,3.239,3.168C618.976,424.01,618.608,426.575,618.608,429.061z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M420.541,324.254c4.11-0.02,5.454,1.341,4.4,4.717
    		c-2.352,7.537-2.993,15.227-2.824,23.07c0.034,1.576-0.578,3.749-1.688,4.566c-0.771,0.567-3.147-0.379-4.37-1.256
    		c-1.748-1.254-2.084-3.026-0.964-5.258c0.845-1.682,1.059-3.795,1.124-5.73c0.178-5.219,0.183-10.445,0.142-15.668
    		C416.337,325.637,417.413,323.879,420.541,324.254z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M490.386,424.055c0,6.619,0.099,13.24-0.099,19.853
    		c-0.032,1.079-1.376,2.119-2.114,3.177c-0.991-0.924-2.705-1.753-2.841-2.789c-0.43-3.266-0.276-6.607-0.33-9.921
    		c-0.13-7.904-0.36-15.812-0.252-23.714c0.016-1.156,1.48-2.996,2.597-3.287c2.217-0.578,3.264,1.169,3.295,3.26
    		c0.066,4.474,0.021,8.949,0.021,13.424C490.57,424.056,490.478,424.056,490.386,424.055z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M495.87,452.204c-0.511-0.204-1.535-0.612-2.559-1.021
    		c0.557-0.877,0.939-1.971,1.706-2.589c1.97-1.592,1.222-2.994,0.25-4.769c-2.205-4.022-1.852-5.053,1.957-7.698
    		c0.82-0.569,1.521-1.408,2.419-1.745c1.556-0.585,3.223-0.876,4.843-1.29c0.081,1.997,0.353,4.012,0.174,5.985
    		c-0.113,1.256-0.895,2.451-1.377,3.673c1.427,0.02,2.93,0.334,4.262-0.012c1.839-0.478,3.724-0.665,3.93,1.455
    		c0.144,1.468-1.353,4.469-1.818,4.41c-4.292-0.538-7.54,1.955-11.181,3.261C497.863,452.084,497.16,452.046,495.87,452.204z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M549.227,501.549c-1.895,0.979-3.905,1.788-5.66,2.976
    		c-3.402,2.302-8.203,0.312-8.621-3.814c-0.164-1.628-0.13-3.389,0.342-4.927c0.311-1.011,1.545-2.382,2.404-2.41
    		c0.966-0.031,2.142,1.124,2.92,2.026c0.856,0.992,1.365,2.285,2.026,3.447c0.567-1.071,1.279-2.094,1.671-3.226
    		c0.774-2.236,2.16-2.804,4.229-1.836c1.862,0.87,3.359,1.209,4.348-1.314c0.233-0.597,2.54-1.159,2.853-0.809
    		c1.134,1.27,3.905,1.999,2.146,4.701c-0.861,1.323-1.149,3.199-2.272,4.127c-1.55,1.278-3.645,1.896-5.506,2.797
    		C549.812,502.707,549.52,502.128,549.227,501.549z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M653.239,526.774c2.112,1.134,3.882,2.383,3.702,5.275
    		c-0.061,0.978,0.7,2.247,1.465,2.992c2.673,2.603,5.175,5.143,4.575,9.385c-0.107,0.762,0.651,1.967,1.362,2.464
    		c2.59,1.81,4.157,4.006,4.733,7.25c0.225,1.268,2.553,2.032,3.392,3.361c0.744,1.179,1.463,3.207,0.936,4.179
    		c-1.037,1.914-3.357,1.905-4.623,0.232c-2.631-3.476-6.291-6.407-6.545-11.337c-0.063-1.227-0.625-2.545-1.301-3.602
    		c-2.487-3.883-4.952-7.802-7.747-11.461c-2.418-3.167-2.283-5.957,0.18-8.863L653.239,526.774z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M292.078,538.335c-1.322-0.443-2.694-0.968-4.103-1.362
    		c-2.528-0.707-5.103-1.953-7.615-1.858c-4.178,0.159-7.71-0.798-11.175-3.066c-1.555-1.019-3.301-1.869-1.827-3.578
    		c1.063-1.232,4.334-2.5,4.759-2.038c3.814,4.144,8.739,3.111,13.063,2.595c4.666-0.557,7.404,3.175,11.488,3.664
    		c1.712,0.205,1.641,3.423-0.146,4.373C295.253,537.738,293.708,537.893,292.078,538.335z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M455.123,568.811c0-2.295-0.035-5.449,0.012-8.602
    		c0.033-2.229,0.933-4.159,3.368-4.096c2.854,0.074,2.573,2.581,2.569,4.52c-0.008,4.814-0.09,9.63-0.256,14.441
    		c-0.044,1.279-0.424,2.565-0.779,3.812c-0.658,2.313-1.418,4.598-2.123,6.897c-0.381,1.243-0.437,2.737-1.192,3.67
    		c-0.71,0.877-2.121,1.441-3.281,1.557c-0.381,0.037-1.244-1.729-1.352-2.729c-0.156-1.452-0.4-3.473,0.392-4.353
    		C456.361,579.616,454.792,574.511,455.123,568.811z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M659.304,572.363c0.621,4.923,1.317,9.839,1.812,14.775
    		c0.122,1.21-0.161,2.627-0.746,3.679c-0.344,0.619-1.678,1.007-2.505,0.919c-0.503-0.053-1.143-1.159-1.287-1.879
    		c-1.053-5.225-2.357-10.436-2.859-15.72c-0.418-4.395-0.021-8.899,0.416-13.315c0.093-0.943,2.03-2.494,2.907-2.362
    		c1.033,0.154,2.517,1.774,2.616,2.866c0.328,3.633,0.124,7.312,0.124,10.975C659.622,572.321,659.463,572.342,659.304,572.363z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M308.257,476.436c-3.576-0.237-6.08-1.989-6.906-4.7
    		c-0.692-2.271-0.735-5.819,0.599-7.379c2.059-2.406,5.728-3.817,9.032-2.078c2.553,1.344,4.815,3.434,6.786,5.579
    		c0.655,0.713,0.513,3.443-0.141,3.854C314.675,473.56,311.409,474.907,308.257,476.436z M312.181,468.532
    		c-1.05-0.69-1.671-1.423-2.262-1.398c-0.605,0.024-1.182,0.788-1.771,1.228c0.554,0.49,1.067,1.303,1.673,1.379
    		C310.407,469.814,311.089,469.131,312.181,468.532z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M533.645,595.399c-1.357-0.662-3.682-1.11-3.883-2.026
    		c-0.499-2.271-0.521-4.919,0.21-7.099c0.578-1.725,2.52-3.017,3.923-4.434c3.617-3.653,4.766-3.805,8.059-0.044
    		c1.483,1.694,3.516,5.05,2.855,6.066c-1.757,2.708-4.747,4.682-7.453,6.665c-0.784,0.574-2.268,0.195-3.431,0.253
    		C533.832,594.987,533.738,595.193,533.645,595.399z M536.508,589.723c0.709-0.808,1.605-1.403,1.535-1.845
    		c-0.117-0.733-0.854-1.368-1.331-2.044c-0.568,0.629-1.383,1.183-1.608,1.916C534.981,588.15,535.857,588.856,536.508,589.723z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M678.662,446.784c4.733-0.79,9.441-1.869,14.208-2.269
    		c3.045-0.254,6.201,0.35,9.246,0.904c0.723,0.132,1.199,1.612,1.789,2.473c-0.922,0.517-1.805,1.36-2.774,1.477
    		c-1.62,0.193-3.305,0.002-4.948-0.168c-5.059-0.523-9.804,0.643-14.65,1.997c-3.269,0.913-6.921,0.803-10.36,0.562
    		c-1.148-0.081-2.169-1.984-3.248-3.055c1.059-0.82,2.038-2.175,3.193-2.348c2.44-0.365,4.973-0.111,7.467-0.111
    		C678.61,446.426,678.636,446.605,678.662,446.784z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M417.123,550.539c-1.06-0.709-3.082-1.323-3.537-2.529
    		c-0.772-2.053,0.167-3.866,2.901-3.964c2.84-0.102,2.771-2.118,2.819-4.244c0.098-4.345,0.894-4.948,5.47-4.687
    		c3.579,0.204,6.802,3.241,6.352,6.172c-0.132,0.86-0.742,1.86-1.442,2.383c-2.472,1.847-5.017,3.616-7.661,5.203
    		C420.827,549.592,419.331,549.813,417.123,550.539z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M568.355,422.045c-1.146,0.158-2.449,0.729-3.398,0.369
    		c-1.148-0.435-2.836-1.71-2.784-2.526c0.079-1.249,1.198-2.918,2.335-3.479c1.181-0.584,2.918,0.066,4.393-0.059
    		c1.771-0.149,3.962,0.019,5.195-0.944c3.563-2.785,7.563-2.048,11.453-2.037c1.006,0.003,2.863,1.317,2.83,1.951
    		c-0.063,1.213-0.936,2.818-1.966,3.432c-1.16,0.689-2.863,0.479-4.334,0.631c-1.302,0.135-2.674,0.017-3.901,0.387
    		c-3.198,0.964-6.335,2.135-9.496,3.223C568.572,422.676,568.464,422.36,568.355,422.045z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M459.16,494.008c-1.354-0.398-2.874-0.445-3.795-1.212
    		c-1.262-1.052-2.069-2.65-3.071-4.015c1.459-0.385,2.912-0.795,4.381-1.136c0.291-0.068,0.687,0.265,0.961,0.179
    		c4.745-1.494,9.558-2.827,14.175-4.654c1.609-0.637,2.881-1.916,4.292-0.162c1.371,1.704,1.173,3.49-0.736,4.693
    		c-1.786,1.126-3.672,2.139-5.614,2.96c-2.046,0.865-4.204,1.47-6.327,2.142C462.034,493.242,460.617,493.6,459.16,494.008z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M350.153,405.3c1.48-1.464,2.509-3.317,3.941-3.715
    		c2.594-0.719,3.083-2.22,2.852-4.396c-0.479-4.49,2.379-5.243,6.101-4.775c2.425,0.305,3.956,2.872,2.549,4.739
    		c-2.816,3.735-3.624,8.935-8.105,11.4c-1.316,0.725-3.152,1.648-4.291,1.23C352.054,409.362,351.48,407.385,350.153,405.3z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M700.694,379.884c4.688,1.321,9.425,2.508,14.024,4.091
    		c0.813,0.279,1.755,2.556,1.391,3.297c-0.458,0.931-2.694,2.045-3.311,1.672c-5.246-3.174-10.95-1.842-16.491-2.181
    		c-1.298-0.079-2.83-0.744-3.702-1.663c-0.489-0.517-0.194-2.364,0.383-3.139c0.574-0.771,1.916-1.217,2.971-1.323
    		c1.458-0.147,2.962,0.157,4.446,0.268C700.502,380.564,700.598,380.224,700.694,379.884z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M327.323,538.188c-0.007,6.025-2.415,8.928-7.537,8.653
    		c-1.451-0.078-3.814-0.825-4.072-1.74c-0.517-1.835-0.348-4.208,0.428-5.968c0.827-1.881,2.479-3.572,4.147-4.857
    		c1.399-1.079,3.396-2.047,5.017-1.894c0.802,0.076,1.394,2.776,1.992,4.323C327.466,537.138,327.324,537.69,327.323,538.188z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M632.985,326.32c-0.649,0.341-1.504,0.932-2.448,1.259
    		c-4.563,1.579-9.3,2.759-13.675,4.74c-2.701,1.224-4.443,0.756-6.134-1.165c-0.592-0.673-1.237-1.844-1.023-2.524
    		c0.209-0.663,1.442-1.096,2.3-1.446c4.34-1.773,8.763-3.356,13.037-5.272C627.508,320.807,632.892,323.17,632.985,326.32z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M703.91,481.558c-0.873,0.446-1.78,0.788-2.54,1.335
    		c-0.655,0.472-1.049,1.324-1.721,1.753c-2.427,1.547-6.097,1.422-6.782-0.327c-1.221-3.117,1.649-3.93,3.409-5.171
    		c1.46-1.029,3.188-1.678,4.797-2.495c0.294-0.149,0.729-0.508,0.86-0.418c2.53,1.709,5.395,3.144,7.362,5.367
    		c0.91,1.028,0.379,3.709-0.115,5.466c-0.315,1.119-1.606,2.317-2.741,2.759c-1.327,0.517-2.96,0.25-4.46,0.322
    		c0.49-1.216,0.862-2.5,1.515-3.621c0.392-0.675,1.396-0.988,1.803-1.66c0.377-0.621,0.553-1.559,0.378-2.246
    		C705.566,482.192,704.644,481.971,703.91,481.558z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M364.232,313.978c-2.527,4.121,0.522,8.737-3.371,11.449
    		c-1.123,0.782-2.562,1.111-3.855,1.648c-0.019-1.127-0.102-2.257-0.044-3.38c0.122-2.399-1.297-2.465-2.88-1.771
    		c-1.646,0.722-3.078,1.981-4.758,2.553c-0.832,0.283-2.011-0.448-3.032-0.722c0.284-0.871,0.304-2.073,0.902-2.554
    		c2.024-1.625,4.195-3.092,6.422-4.434c2.247-1.354,4.555-2.67,6.986-3.618C361.465,312.814,362.77,313.609,364.232,313.978z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M609.174,357.486c0,1.985,0.204,4.001-0.089,5.942
    		c-0.139,0.922-1.152,2.313-1.896,2.4c-1.087,0.126-2.92-0.427-3.309-1.232c-0.796-1.654-1.407-3.773-1.081-5.512
    		c0.644-3.434,1.126-6.381-2.204-8.896c-0.81-0.612-0.411-2.823-0.569-4.299c1.51,0.03,3.322-0.472,4.475,0.185
    		C608.972,348.622,609.261,349.656,609.174,357.486z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M439.623,470.896c0.521-3.754,1.425-4.394,5.04-3.914
    		c1.862,0.247,3.818-0.157,5.665,0.143c1.771,0.287,3.651,0.804,5.124,1.768c1.748,1.146,3.623,2.701,1.348,5.176
    		c-2.112,2.297-4.563,2.728-6.877,0.776c-1.304-1.099-2.102-2.449-4.052-0.743c-1.039,0.908-3.438,1.813-4.07,1.3
    		C440.628,474.45,440.302,472.457,439.623,470.896z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M692.559,401.74c-2.984,0-6.013,0.291-8.933-0.143
    		c-1.185-0.176-2.971-1.938-2.963-2.967c0.009-1.085,1.864-3.111,2.856-3.077c6.193,0.215,12.67-1.002,18.353,2.629
    		c0.828,0.528,1.336,1.558,1.992,2.355c-0.951,0.45-1.882,1.227-2.857,1.288c-2.807,0.176-5.632,0.062-8.449,0.062
    		C692.558,401.839,692.558,401.789,692.559,401.74z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M364.384,525.84c-3.126,0.335-6.252,0.889-9.378,0.893
    		c-1.179,0.001-2.358-1.271-3.537-1.963c0.94-0.766,1.787-1.705,2.836-2.268c5.855-3.138,12.257-2.271,18.515-2.301
    		c0.853-0.004,1.708,0.74,2.562,1.139c-0.533,0.893-0.865,2.277-1.634,2.591c-2.938,1.197-6.017,2.05-9.043,3.03
    		C364.599,526.587,364.491,526.214,364.384,525.84z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M650.468,424.249c0.756,0.691,1.722,1.575,2.688,2.46
    		c-0.738,0.689-1.359,1.669-2.233,2.021c-4.391,1.764-8.787,3.563-13.312,4.909c-1.521,0.452-3.624-0.019-5.096-0.777
    		c-1.032-0.531-1.446-2.265-2.137-3.462c1.16-0.411,2.317-0.83,3.48-1.23c1.778-0.612,3.524-1.373,5.35-1.779
    		C642.704,425.611,646.245,425.038,650.468,424.249z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M392.339,313.224c-0.253-0.2-2.146-1.022-2.15-1.854
    		c-0.007-1.345,1.128-3.786,1.957-3.863c4.914-0.456,9.313-2.086,13.642-4.32c1.146-0.593,2.965-0.29,4.344,0.083
    		c1.57,0.425,2.263,1.849,0.592,2.851C405.345,309.346,400.146,313.101,392.339,313.224z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M330.86,470.033c0.912-0.184,1.559-0.393,2.216-0.432
    		c2.869-0.175,4.274,1.812,4.861,4.067c0.141,0.539-2.077,2.077-3.423,2.541c-3.078,1.061-6.259,2.253-9.453,2.439
    		c-3.543,0.207-4.545-2.354-2.594-5.316C324.57,470.142,327.5,469.207,330.86,470.033z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M443.708,427.287c-0.702,4.9-4.849,9.767-9.356,10.389
    		c-0.858,0.118-2.139-0.163-2.608-0.752c-0.358-0.448,0.165-1.708,0.462-2.548c0.622-1.754,1.384-3.458,2.029-5.204
    		c0.499-1.351,0.514-3.191,1.448-4c1.467-1.27,3.593-2.6,5.311-2.448C442.024,422.815,442.812,425.673,443.708,427.287z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M532.152,396.881c0-0.829-0.138-1.687,0.029-2.48
    		c0.319-1.526,0.554-4.14,1.279-4.305c1.935-0.441,4.323-0.304,6.135,0.499c2.086,0.924,1.821,2.929,0.239,4.487
    		c-1.243,1.226-1.626,2.245-0.302,3.806c0.628,0.738,0.937,2.89,0.549,3.15c-1.707,1.148-3.666,2.112-5.661,2.514
    		c-0.518,0.104-1.823-1.977-2.17-3.221c-0.386-1.384-0.088-2.959-0.088-4.45C532.159,396.881,532.155,396.881,532.152,396.881z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M673.305,437.055c2.486,0,4.988-0.157,7.452,0.08
    		c0.937,0.09,2.369,0.969,2.517,1.7c0.157,0.782-0.726,2.162-1.535,2.657c-1.215,0.742-2.745,1.229-4.171,1.312
    		c-3.802,0.224-7.62,0.148-11.431,0.244c-1.938,0.049-3.275-0.493-3.211-2.736c0.054-1.902,0.76-3.203,2.919-3.194
    		c2.486,0.011,4.974,0.003,7.46,0.003C673.305,437.098,673.305,437.076,673.305,437.055z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M393.16,324.277c1.651-0.003,3.303,0.001,4.954-0.004
    		c2.478-0.006,3.822,1.304,3.504,3.692c-0.317,2.386-1.899,3.909-4.446,3.679c-3.547-0.321-7.1-0.743-10.595-1.411
    		c-1.232-0.235-2.302-1.32-3.446-2.018c1.032-1.312,1.841-3.374,3.148-3.765C388.401,323.818,390.85,324.281,393.16,324.277z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M674.778,344.192c-1.985,1.594-3.197,3.249-4.75,3.685
    		c-4.439,1.243-8.998,2.116-13.558,2.832c-0.938,0.147-2.74-0.834-2.965-1.628c-0.272-0.964,0.727-3.3,1.141-3.292
    		c5.955,0.118,10.766-5.164,16.938-3.557C672.509,342.474,673.293,343.259,674.778,344.192z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M607.057,555.321c-0.98,1.703-1.589,3.271-2.635,4.451
    		c-1.414,1.597-2.975,3.271-4.829,4.201c-1.437,0.721-3.554,0.817-5.13,0.364c-2.667-0.766-2.943-3.434-0.451-4.738
    		c1.929-1.01,2.559-2.552,3.7-4.076c1.437-1.917,4.499-2.717,6.997-3.614C605.023,551.796,606.175,554.011,607.057,555.321z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M579.705,355.529c0.27,2.675-0.346,4.319-3.336,4.538
    		c-1.115,0.081-2.065,1.546-3.239,1.957c-1.251,0.438-3.255,0.982-3.883,0.383c-0.811-0.774-0.986-2.729-0.696-4.007
    		c0.348-1.533,1.788-2.806,2.194-4.34c0.724-2.729,2.271-4.938,4.984-4.385C578.388,350.219,580.254,352.464,579.705,355.529z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M401.837,529.27c0.02,3.96-5.915,11.476-8.648,11.779
    		c-1.372,0.152-2.839-0.537-4.262-0.846c0.397-1.308,0.498-2.83,1.253-3.882c1.915-2.67,4.034-5.213,6.26-7.634
    		c0.917-0.998,2.727-2.432,3.403-2.114C400.999,527.114,401.581,528.88,401.837,529.27z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M446.057,348.128c0,0.994,0.062,1.992-0.013,2.98
    		c-0.182,2.43-0.993,3.304-3.486,1.821c-1.584-0.941-3.551-1.343-5.4-1.708c-2.849-0.562-3.599-1.68-2.004-3.958
    		c1.456-2.08,2.931-4.622,5.038-5.668c3.873-1.922,5.883-0.254,5.913,4.049c0.006,0.828,0.001,1.656,0.001,2.483
    		C446.089,348.128,446.072,348.128,446.057,348.128z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M686.68,529.957c-0.81,0.551-1.807,1.638-3.012,1.968
    		c-3.353,0.918-3.441,2.41-1.588,5.015c0.652,0.917,0.365,2.504,0.507,3.784c-1.483-0.315-3.323-0.198-4.359-1.054
    		c-1.333-1.103-2.234-2.895-2.941-4.551c-1.166-2.73-1.064-5.193,2.278-6.518c1.684-0.668,3.188-1.862,4.906-2.349
    		C684.798,525.593,686.327,526.651,686.68,529.957z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M579.867,331.037c0,1.49,0.207,3.019-0.063,4.458
    		c-0.229,1.229-1.008,2.354-1.544,3.523c-1.355-0.738-3.8-1.37-3.885-2.234c-0.436-4.405-0.385-8.878-0.151-13.308
    		c0.031-0.597,2.16-1.646,3.133-1.469c0.937,0.171,2.153,1.569,2.328,2.586c0.36,2.092,0.108,4.289,0.108,6.443
    		C579.817,331.037,579.843,331.037,579.867,331.037z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M679.441,421.594c-0.268,0.282-1.235,1.016-1.823,1.98
    		c-1.196,1.962-2.634,1.808-4.151,0.646c-2.202-1.688-2.326-7.695-0.273-9.624c0.237-0.224,0.513-0.419,0.799-0.577
    		c1.381-0.761,10.087,3.146,10.388,4.652C684.77,420.616,683.291,421.793,679.441,421.594z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M725.981,422.75c-0.219-1.524-0.487-3.044-0.639-4.575
    		c-0.113-1.142-0.471-2.504-0.007-3.398c0.602-1.159,1.912-1.95,2.917-2.899c0.86,0.988,2.31,1.889,2.473,2.981
    		c0.551,3.684-0.382,7.641,2.517,10.857c0.21,0.233-0.915,2.419-1.757,2.706C729.133,429.224,725.116,425.337,725.981,422.75z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M684.245,476.639c-1.761,3.961-2.969,7.283-4.688,10.314
    		c-1.024,1.806-4.202,1.02-4.545-1.171c-0.258-1.651,0.336-3.487,0.774-5.183c0.361-1.394,1.275-2.659,1.545-4.059
    		c0.413-2.143,0.443-4.927,3.422-4.165C682.2,472.746,683.12,475.181,684.245,476.639z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M588.561,505.452c-1.414-0.386-3.876-0.825-6.134-1.757
    		c-1.493-0.616-2.694-1.941-4.026-2.949c1.941-1.014,3.829-2.704,5.839-2.866c2.421-0.196,5.028,0.683,7.393,1.544
    		c1.158,0.422,2.619,1.799,2.708,2.848c0.084,0.988-1.319,2.278-2.336,3.11C591.509,505.787,590.407,505.452,588.561,505.452z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M331.934,526.989c-2.994-1.095-5.255-1.631-7.187-2.739
    		c-1.134-0.651-2.54-2.306-2.42-3.347c0.237-2.053,4.079-3.617,5.862-2.818c1.347,0.604,2.619,1.606,4.016,1.812
    		c2.582,0.381,5.004,1.105,4.05,4.114C335.827,525.36,333.363,526.064,331.934,526.989z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M534.58,567.079c2.556,1.012,5.249,1.788,7.595,3.16
    		c0.857,0.502,0.889,2.417,1.297,3.688c-1.33,0.414-2.687,1.237-3.984,1.152c-2.233-0.146-4.477-0.708-6.625-1.383
    		c-1.547-0.486-4.153-1.395-4.183-2.192c-0.084-2.278,1.448-3.987,4.153-3.878c0.498,0.021,0.996,0.056,1.494,0.084
    		C534.412,567.499,534.496,567.289,534.58,567.079z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M265.433,512.753c-0.693,1.632-1.229,2.894-1.765,4.155
    		c-1.137-0.827-2.509-1.464-3.365-2.519c-2.156-2.657-4.982-5.23-3.706-9.182c0.378-1.17,1.697-2.037,2.585-3.043
    		c0.917,0.918,2.109,1.688,2.694,2.783C263.208,507.438,264.242,510.088,265.433,512.753z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M414.103,478.365c6.853,0.346,9.158,2.77,8.069,6.982
    		c-0.621,2.406-2.342,3.152-4.494,1.873c-1.249-0.743-2.324-2.019-3.649-2.339c-2.208-0.533-4.289-1.442-3.426-3.762
    		C411.158,479.628,413.502,478.803,414.103,478.365z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M464.163,529.973c-1.57-1.123-2.788-1.994-4.006-2.866
    		c1.003-1.282,1.754-3.015,3.064-3.746c2.214-1.235,4.729-2.088,7.221-2.611c1.534-0.322,3.261,0.265,4.9,0.443
    		c-0.765,1.28-1.237,2.982-2.353,3.755C470.322,526.793,467.352,528.2,464.163,529.973z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M712.854,529.662c0.554-1.262,0.989-2.123,1.314-3.024
    		c0.7-1.937,1.656-3.851,1.928-5.854c0.311-2.285,0.837-3.943,3.509-3.608c2.571,0.322,2.648,2.415,2.194,4.149
    		c-0.855,3.271-1.951,6.512-3.344,9.584c-0.328,0.724-2.297,1.112-3.4,0.941C714.233,531.723,713.591,530.443,712.854,529.662z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M296.118,552.97c-2.535-0.051-4.676-0.071-4.497-3.037
    		c0.159-2.652,1.94-2.835,4.172-3.054c2.152-0.212,4.188-1.434,6.328-2.009c1.14-0.307,2.395-0.187,3.598-0.26
    		c-0.279,1.141-0.182,2.711-0.904,3.344C302.168,550.272,300.133,553.937,296.118,552.97z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M544.986,482.509c-0.256,2.093-0.398,4.21-0.844,6.261
    		c-0.116,0.536-1.133,1.067-1.815,1.205c-0.325,0.065-0.97-0.73-1.24-1.255c-0.881-1.705-1.923-3.397-2.389-5.226
    		c-0.378-1.483-0.551-3.479,0.162-4.657c0.79-1.308,2.688-1.945,4.104-2.874c0.599,1.346,1.314,2.656,1.748,4.054
    		c0.229,0.738-0.033,1.63-0.073,2.452C544.754,482.482,544.87,482.496,544.986,482.509z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M530.484,366.744c-3.865-0.431-4.665-2.699-4.997-5.064
    		c-0.358-2.55,2.034-5.485,3.819-4.571c2.236,1.146,3.85,3.506,5.732,5.341C533.521,363.881,532.002,365.312,530.484,366.744z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M550.204,398.492c-0.76-0.969-2.263-2.266-2.92-3.901
    		c-0.623-1.551,3.105-4.643,5.474-4.823c2.093-0.16,3.031,0.924,3.078,2.944C555.893,395.136,553.294,398.38,550.204,398.492z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M577.144,432.816c-2.851,1.257-4.471,2.38-6.217,2.633
    		c-1.976,0.285-4.44-3.088-3.799-5.381c0.346-1.238,2.64-3.168,2.912-2.994C572.242,428.479,574.174,430.309,577.144,432.816z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M604.363,399.592c-2.197,1.898-3.734,3.227-5.271,4.554
    		c-1.149-1.469-3.234-2.977-3.177-4.396c0.054-1.33,2.226-3.278,3.753-3.604C600.731,395.918,602.282,397.974,604.363,399.592z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M603.349,318.668c-1.367,3.051-2.329,5.474-3.603,7.721
    		c-0.175,0.311-2.309,0.11-2.817-0.477c-1.782-2.056,0.816-8.469,3.468-8.587C601.398,317.28,602.444,318.23,603.349,318.668z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M591.703,467.736c1.451,2.618,2.848,4.062,2.821,5.479
    		c-0.018,0.897-2.196,2.487-3.328,2.422c-1.11-0.063-3.059-1.929-2.963-2.823C588.384,471.407,589.941,470.152,591.703,467.736z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M541.646,426.944c1.1,1.648,2.41,3.215,3.105,5.019
    		c0.128,0.329-2.168,2.151-3.489,2.357c-2.26,0.354-3.398-1.336-3.012-3.445c0.248-1.35,1.277-2.557,1.956-3.827
    		C540.687,427.013,541.167,426.979,541.646,426.944z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M593.303,398.671c-1.922,1.645-2.935,3.217-4.166,3.413
    		c-2.354,0.375-3.85-1.378-3.438-3.608c0.222-1.197,1.973-3.028,2.915-2.959C589.979,395.616,591.235,397.18,593.303,398.671z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M452.738,396.157c0.654,1.303,1.889,2.659,1.775,3.893
    		c-0.086,0.943-1.774,2.1-2.952,2.42c-0.525,0.144-2.03-1.444-2.201-2.404c-0.154-0.873,0.896-1.96,1.416-2.953
    		C451.431,396.794,452.084,396.476,452.738,396.157z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M496.509,756.385c0,2.318-0.249,4.671,0.057,6.948
    		c0.506,3.78,2.545,4.531,5.451,2.257c3.099-2.426,6.567-2.82,8.373-0.952c1.952,2.021,1.355,6.372-1.39,8.936
    		c-3.229,3.016-6.759,5.154-11.551,4.907c-4.192-0.216-8.688-1.216-12.295,2.408c-0.434,0.436-2.492,0.07-3.035-0.57
    		c-1.802-2.127-3.944-1.928-6.352-1.853c-5.821,0.183-11.651,0.144-17.476,0.037c-1.264-0.022-2.536-0.645-3.768-1.091
    		c-0.933-0.338-1.771-1.087-2.713-1.225c-8.938-1.304-17.888-2.534-26.841-3.732c-1.14-0.153-2.52,0.398-3.449-0.062
    		c-5.423-2.688-11.237-2.321-17.006-2.942c-4.452-0.479-8.659-3.129-13.007-4.728c-1.362-0.501-2.839-0.683-4.241-1.088
    		c-1.878-0.542-3.747-1.123-5.594-1.764c-0.772-0.268-1.497-1.086-2.21-1.046c-7.547,0.423-13.201-5.135-20.306-6.354
    		c-3.083-0.529-5.698-3.437-8.688-4.942c-4.67-2.351-9.857-3.867-14.128-6.766c-5.108-3.468-9.524-7.971-14.124-12.154
    		c-0.609-0.555-0.847-1.914-0.713-2.82c0.488-3.313-2.325-5.49-2.746-8.156c-0.373-2.357,1.925-5.017,2.378-7.656
    		c0.497-2.898-0.163-5.963,2.16-8.492c0.347-0.377-0.589-2.254-1.271-3.188c-1.496-2.047-5.199-3.683-3.348-6.473
    		c1.997-3.008,5.152-5.637,8.385-7.295c2.078-1.066,5.267-0.412,7.853,0.054c4.378,0.787,5.737,5.295,2.789,8.734
    		c-1.513,1.765-3.041,3.576-4.836,5.03c-7.08,5.735-8.646,10.37-6.154,18.905c0.182,0.623,0.272,1.301,0.259,1.949
    		c-0.188,8.955,2.107,12.224,10.663,15.995c2.902,1.279,5.568,3.134,8.243,4.878c2.605,1.698,4.155,1.191,5.254-1.723
    		c1.896-5.033,4.058-9.961,8.526-13.322c1.239-0.932,2.766-1.562,4.251-2.065c1.869-0.632,3.927-0.762,5.733-1.517
    		c3.512-1.468,6.558-0.626,8.001,2.718c0.938,2.173,0.153,4.009-2,4.929c-0.99,0.423-2.268,0.452-3.365,0.303
    		c-7.919-1.078-12.7,1.722-15.448,9.17c-0.057,0.153-0.04,0.337-0.107,0.483c-0.637,1.386-2.126,3.678-1.785,3.992
    		c1.638,1.511,3.727,2.568,5.737,3.631c1.735,0.917,3.606,1.574,5.375,2.436c3.861,1.88,5.811,0.901,5.82-3.269
    		c0.012-5.528,4.56-7.695,7.778-10.802c0.504-0.487,2.776-0.199,3.091,0.365c1.077,1.936,2.57,4.327,2.212,6.226
    		c-0.663,3.513-1.17,7.878-6.452,8.111c-0.559,0.025-1.075,1.012-1.612,1.553c0.557,0.525,1.025,1.298,1.686,1.532
    		c2.755,0.981,5.524,2.005,8.374,2.609c2.064,0.438,4.295,0.509,6.396,0.265c2.727-0.316,3.053-2.602,2.231-4.535
    		c-0.956-2.25-2.244-0.55-3.532,0.139c-0.853,0.455-2.474,0.61-3.062,0.104c-0.597-0.515-0.657-2.096-0.395-3.057
    		c0.386-1.415,1.342-2.664,1.918-4.041c0.733-1.752,1.788-3.535,1.888-5.351c0.196-3.563,2.763-5.172,5.078-6.937
    		c0.646-0.492,2.221-0.188,3.164,0.223c1.687,0.733,3.165,1.932,4.824,2.744c2.92,1.432,2.001,3.653,0.886,5.425
    		c-2.349,3.728-4.733,7.29-5.016,11.953c-0.072,1.178-1.924,2.289-3.038,3.345c-0.529,0.501-1.249,0.801-1.882,1.191
    		c0.505,0.591,0.917,1.562,1.529,1.712c4.437,1.084,8.911,2.012,13.38,2.965c0.808,0.173,1.866-0.056,2.426,0.388
    		c4.205,3.329,9.103,2.438,13.874,2.567c1.79,0.049,3.572,0.646,5.346,1.047c2.588,0.583,5.149,1.64,7.749,1.729
    		c4.519,0.157,8.878,0.188,12.927,2.791c1.379,0.887,3.539,0.748,5.343,0.752c10.304,0.025,20.608-0.021,30.911-0.13
    		c1.036-0.011,2.067-0.604,3.101-0.928c-0.353-0.833-0.645-1.698-1.077-2.486c-0.304-0.554-1.18-1.414-1.152-1.439
    		c3.214-2.985-0.599-5.72-0.31-8.615c0.313-3.136,0.103-6.324,0.116-9.49c0.01-2.166,0.432-4.247,3.1-4.186
    		c2.629,0.061,3.034,2.131,3.011,4.309c-0.023,2.166-0.005,4.332-0.005,6.498C496.619,756.386,496.564,756.385,496.509,756.385z
    		 M327.484,696.776c1.18-1.307,1.872-2.073,2.564-2.84c-0.908-0.693-1.764-1.815-2.742-1.937c-0.718-0.088-1.597,1.138-2.404,1.778
    		C325.645,694.641,326.387,695.503,327.484,696.776z M402.651,739.926c-1.142-0.94-1.808-1.488-2.473-2.036
    		c-0.643,0.792-1.613,1.516-1.798,2.402c-0.104,0.5,1.267,1.9,1.659,1.789C400.902,741.836,401.566,740.889,402.651,739.926z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M402.124,849.918c-2.574-5.396,1.51-9.188,2.983-13.573
    		c0.326-0.972,2.274-1.838,3.508-1.883c0.633-0.022,1.724,1.811,1.903,2.919c0.198,1.226,0.015,2.947-0.738,3.817
    		c-3.657,4.231-2.592,9.374-2.035,13.878c0.386,3.123,2.991,6.76,5.673,8.654c6.464,4.568,14.434,5.753,21.99,7.54
    		c4.927,1.165,10.119,1.183,15.181,1.812c2.951,0.367,4.553-0.787,4.495-3.77c-0.073-3.855-0.09-7.573,2.627-10.831
    		c0.688-0.824,0.229-2.576,0.369-3.895c0.138-1.295-0.07-2.959,0.648-3.794c1.566-1.819,1.899-3.541,1.637-5.896
    		c-0.246-2.214,0.806-4.525,3.553-4.476c2.596,0.047,3.035,2.145,2.933,4.342c-0.031,0.664-0.23,1.478,0.067,1.97
    		c0.699,1.154,1.578,2.921,2.52,3.011c1.001,0.094,2.767-1.266,3.112-2.34c0.631-1.96,0.702-4.287,3.422-3.884
    		c1.81,0.269,4.484,1.066,4.979,2.328c0.707,1.809,0.138,4.376-0.53,6.408c-1.34,4.069-4.939,7.207-4.754,12.012
    		c0.05,1.273-1.231,2.594-1.89,3.899c-1.301,2.582-0.331,4.549,2.549,5.036c10.296,1.74,20.625,3.215,31.104,2.956
    		c5.584-0.138,11.163-0.682,16.742-0.661c7.743,0.027,15.483,0.509,23.228,0.605c3.783,0.047,7.571-0.401,11.36-0.498
    		c1.956-0.049,3.922,0.163,5.881,0.292c1.158,0.076,2.754,0.765,3.398,0.244c5.166-4.171,10.936-0.629,16.354-1.459
    		c5.684-0.871,11.492-0.896,17.231-1.438c1.395-0.132,3.343-0.427,3.989-1.367c2.462-3.577,4.602-7.392,6.648-11.231
    		c1.24-2.327,5.259-3.19,8.836-1.593c2.673,1.193,3.574,3.425,2.401,6.114c-0.261,0.598-1.004,1.059-1.085,1.644
    		c-0.143,1.028-0.331,2.65,0.203,3.027c0.727,0.513,2.224,0.378,3.194,0.007c1.949-0.745,3.91-1.642,5.578-2.867
    		c0.892-0.654,1.509-2.078,1.675-3.237c0.28-1.961-0.228-4.071,0.249-5.953c0.271-1.066,1.742-2.271,2.866-2.513
    		c0.686-0.146,2.428,1.358,2.505,2.228c0.308,3.471,0.248,6.988,0.086,10.478c-0.036,0.762-0.903,1.794-1.651,2.155
    		c-2.362,1.141-4.811,2.142-7.298,2.98c-4.909,1.655-5.528,2.359-5.748,7.367c-0.202,4.632-0.24,4.428-4.867,4.703
    		c-3.748,0.224-6.589-0.623-9.219-3.541c-1.326-1.472-3.991-2.376-6.071-2.415c-7.979-0.148-15.969,0.14-23.953,0.313
    		c-1.317,0.029-3.004-0.158-3.878,0.549c-3.065,2.479-6.542,2.044-10.012,2.038c-12.798-0.021-25.597-0.042-38.395-0.045
    		c-6.812-0.002-13.627,0.184-20.434-0.002c-5.151-0.141-10.45-1.709-15.396-0.897c-7.265,1.192-13.589-2.896-20.719-2.15
    		c-6.922,0.724-13.972,0.238-20.966,0.249c-0.986,0.002-2.225,0.147-2.917-0.36c-3.181-2.333-6.81-2.029-10.434-2.456
    		c-6.577-0.774-13.023-2.249-19.07-5.299c-3.157-1.592-6.769-2.268-10.115-3.517c-0.997-0.372-2.343-1.125-2.59-1.976
    		c-1.505-5.177-2.737-10.432-4.062-15.661C401.359,849.983,401.741,849.951,402.124,849.918z M460.377,867.727
    		c0.453,1.754,0.338,4.282,1.469,5.111c3.052,2.238,7.356-0.124,7.765-3.854c0.143-1.309,0.647-2.761,0.257-3.897
    		c-0.44-1.284-1.507-2.965-2.643-3.294C462.348,860.381,461.162,861.601,460.377,867.727z M615.688,880.555
    		c1.099-1.211,1.89-2.083,2.681-2.954c-0.897-0.57-1.858-1.669-2.677-1.572c-0.982,0.116-1.843,1.253-2.757,1.948
    		C613.706,878.698,614.478,879.421,615.688,880.555z M618.849,867.906c-0.971-1.271-1.497-1.961-2.024-2.651
    		c-1.016,1.074-2.096,2.101-2.956,3.287c-0.097,0.134,1.317,1.788,1.57,1.678C616.57,869.729,617.534,868.853,618.849,867.906z
    		 M473.313,856.562c-1.528-0.655-2.478-1.394-3.375-1.335c-0.623,0.041-1.176,1.176-1.76,1.821c0.876,0.579,1.705,1.474,2.646,1.616
    		C471.377,858.747,472.109,857.63,473.313,856.562z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M553.912,797.694c-1.076-2.638-2.379-5.21-3.153-7.933
    		c-0.575-2.02-0.543-4.23-0.638-6.362c-0.109-2.495-0.175-5.004-0.028-7.494c0.059-1.009,0.727-1.981,1.117-2.97
    		c0.991,0.504,2.352,0.763,2.889,1.568c0.865,1.301,1.617,2.951,1.657,4.474c0.104,4.017,0.732,7.645,2.398,11.5
    		c1.555,3.6,0.615,8.204,1.296,12.279c0.409,2.453,1.775,4.759,2.79,7.096c0.818,1.887,1.773,3.714,2.632,5.584
    		c1.959,4.271,4.43,7.852,8.724,10.43c6.53,3.922,13.135,3.353,20.1,2.229c6.15-0.992,12.393-1.412,18.588-2.133
    		c1.145-0.133,2.439-0.212,3.359-0.797c2.935-1.865,5.984-2.609,9.466-2.192c1.205,0.145,2.523-0.612,3.784-0.977
    		c2.136-0.617,4.265-1.26,6.404-1.863c0.31-0.087,0.762,0.119,0.981-0.041c3.706-2.697,8.312-2.648,12.396-4.132
    		c5.296-1.923,9.93-5.241,16.129-5.137c3.205,0.054,6.448-2.702,9.692-4.146c1.295-0.576,2.622-1.4,3.967-1.468
    		c2.215-0.111,4.375,0.114,5.657-2.233c0.142-0.26,0.515-0.555,0.787-0.56c5.953-0.125,10.645-3.37,15.586-6.1
    		c1.964-1.084,4.44-1.255,6.37-2.379c4.997-2.908,9.988-5.638,15.461-7.713c4.562-1.729,8.531-5.026,12.748-7.651
    		c3.141-1.956,6.033-4.59,9.435-5.785c6.367-2.236,11.614-5.777,16.184-10.622c0.943-1,2.588-1.305,3.829-2.062
    		c2.563-1.564,5.368-3.1,5.005-6.736c-0.263-2.628,0.885-2.793,2.925-2.22c1.799,0.506,2.963-0.172,3.034-2.145
    		c0.067-1.87-0.54-2.719-2.741-3.229c-2.535-0.589-4.924-2.278-7.072-3.894c-1.355-1.02,0.439-4.354,2.75-4.776
    		c1.762-0.32,3.667-0.21,5.458,0.055c4.238,0.625,8.513,1.206,12.64,2.307c2.12,0.566,4.211,1.929,5.846,3.439
    		c1.591,1.469,1.647,3.407-0.146,5.26c-3.551,3.669-6.574,7.814-12.815,6.876c-1.667-0.25-4.123,1.369-5.45,2.828
    		c-4.927,5.416-11.368,8.762-16.963,13.206c-7.638,6.067-16.641,9.433-24.737,14.486c-4.576,2.856-8.784,6.297-13.292,9.271
    		c-2.4,1.584-4.991,2.972-7.662,4.033c-5.683,2.258-11.522,4.123-17.204,6.386c-4.084,1.625-7.923,4.819-12.041,5.144
    		c-4.328,0.342-7.888,2.142-11.364,3.76c-4.734,2.203-10.45,1.356-14.545,5.106c-0.343,0.313-0.893,0.597-1.333,0.579
    		c-5.687-0.237-9.966,3.57-15.002,5.11c-5.576,1.705-11.354,2.865-17.122,3.781c-3.061,0.486-6.148-0.171-8.862,2.319
    		c-1.081,0.992-3.459,0.66-5.257,0.763c-5.094,0.291-10.111-0.187-15.055,2.479c-2.622,1.414-6.893,1.462-9.752,0.374
    		c-5.084-1.932-10.642-2.263-15.279-6.206c-5.487-4.664-8.771-10.075-10.616-16.756c-0.438-1.582-1.309-3.044-1.979-4.562
    		c-1.272-2.881-2.544-5.762-3.815-8.643C552.684,798.231,553.298,797.963,553.912,797.694z M782.639,745.219
    		c-0.573,1.05-1.276,1.758-1.156,2.274c0.129,0.553,1.035,0.925,1.602,1.376c0.425-0.583,1.187-1.171,1.177-1.745
    		C784.252,746.594,783.415,746.077,782.639,745.219z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M507.241,837.762c-5.331,0-10.663-0.019-15.994,0.008
    		c-2.637,0.013-5.282,0.473-7.309-2.08c-0.548-0.691-2.125-0.742-3.238-0.775c-3.663-0.108-7.352,0.165-10.987-0.171
    		c-2.709-0.251-5.323-1.397-8.031-1.824c-3.401-0.536-6.849-0.941-10.286-1.039c-3.644-0.103-6.881-0.627-10.448-2.102
    		c-5.519-2.281-11.809-2.673-17.749-3.971c-2.339-0.511-4.62-1.29-6.918-1.978c-0.304-0.091-0.565-0.543-0.824-0.523
    		c-5.664,0.411-10.663-2.349-16.017-3.266c-3.699-0.634-7.366-0.294-10.996-2.272c-3.617-1.972-8.035-2.409-11.919-3.975
    		c-4.95-1.996-10.512-3.001-14.171-7.411c-0.988-1.191-1.987-2.745-2.113-4.207c-0.27-3.137,0.18-6.31-2.551-8.78
    		c-0.497-0.45-0.579-2.263-0.123-2.721c4.195-4.22,1.873-9.517,2.544-14.293c0.206-1.474-0.209-3.191,0.419-4.404
    		c0.571-1.103,2.173-1.673,3.319-2.479c0.686,0.983,1.631,1.883,1.98,2.973c0.338,1.056,0.422,2.466-0.008,3.445
    		c-1.476,3.366-1.678,6.593,0.036,9.919c0.272,0.529,0.337,1.524,0.02,1.941c-2.711,3.564-0.831,7.036-0.086,10.622
    		c0.752,3.619,3.234,5.024,6.088,6.958c4.522,3.063,10.231,1.771,14.503,5.029c0.825,0.629,2.235,0.802,3.332,0.705
    		c2.767-0.246,5.144,0.104,7.389,2.055c0.96,0.833,2.811,0.836,4.263,0.863c2.529,0.05,4.977-0.048,6.836,2.188
    		c0.449,0.541,1.521,0.919,2.234,0.826c3.476-0.45,6.358,1.099,9.522,2.108c5.546,1.771,11.392,2.603,17.111,3.83
    		c0.967,0.207,1.927,0.45,2.897,0.637c4.034,0.777,8.046,1.758,12.116,2.242c3.74,0.445,7.481-0.161,11.177,1.707
    		c2.39,1.209,5.518,0.961,8.316,1.357c4.251,0.603,8.549,0.979,12.745,1.846c7.145,1.475,14.357,1.165,21.551,1.078
    		c0.842-0.01,1.665-1.959,2.487-3.017c0.727-0.934,1.455-2.7,2.155-2.687c5.387,0.103,9.417-2.75,13.618-5.495
    		c3.509-2.293,7.24-1.235,9.87,2.26c0.652,0.866,1.651,1.471,2.49,2.195c0.604-1.005,1.315-1.961,1.787-3.024
    		c1.152-2.599,5.506-5.273,8.509-5.113c1.979,0.105,6.61,4.183,7.136,6.281c0.684,2.729-3.62,8.273-6.877,8.648
    		c-6.579,0.758-12.645-0.545-17.937-4.765c-2.095-1.671-7.635,0.237-7.953,2.807c-0.074,0.602,0.884,1.563,1.591,1.978
    		c1.233,0.723,2.819,0.918,3.922,1.769c0.935,0.723,1.396,2.059,2.067,3.123c-1.169,0.357-2.333,1.003-3.508,1.023
    		c-5.995,0.104-11.992,0.048-17.989,0.048C507.241,837.828,507.241,837.795,507.241,837.762z M547.495,825.591
    		c-0.89-0.684-1.476-1.134-2.062-1.584c-0.612,0.831-1.226,1.663-1.838,2.494c0.603,0.426,1.41,1.331,1.765,1.18
    		C546.118,827.355,546.642,826.483,547.495,825.591z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M686.348,720.045c1.15-7.558-4.329-11.49-7.855-16.313
    		c-1.65-2.257-3.513-4.183-1.215-6.938c0.765-0.917,2.152-2.483,2.593-2.284c1.363,0.614,2.744,1.735,3.509,3.022
    		c1.986,3.34,3.592,6.903,5.456,10.319c1.364,2.501,3.264,3.079,5.767,1.37c4.401-3.006,5.528,1.925,7.973,3.488
    		c2.29,1.464,1.15,3.017,0.052,5.017c-1.516,2.761-3.554,4.612-6.076,6.269c-2.356,1.548-4.469,3.461-6.771,5.096
    		c-3.669,2.604-7.256,5.379-11.155,7.593c-4.952,2.812-10.143,5.219-15.306,7.639c-3.703,1.735-7.637,3.018-10.174,6.567
    		c-0.333,0.467-1.155,0.898-1.703,0.848c-5.354-0.501-9.068,3.048-13.332,5.246c-0.713,0.368-1.544,0.505-2.314,0.766
    		c-2.093,0.705-4.188,1.407-6.271,2.14c-0.619,0.218-1.218,0.795-1.801,0.763c-6.263-0.35-11.321,2.731-16.511,5.55
    		c-0.934,0.508-2.242,0.308-3.372,0.474c-1.785,0.263-3.921,0.032-5.275,0.947c-3.144,2.125-6.551,2.17-10.027,2.295
    		c-2.951,0.105-6.008-0.387-8.361,2.202c-0.458,0.504-1.544,0.619-2.336,0.609c-6.287-0.079-12.686,0.459-18.828-0.544
    		c-6.979-1.139-13.12-4.535-16.512-11.396c-0.516-1.043-1.133-2.036-1.675-3.067c-1.054-2.002-2.154-2.12-4.402-1.215
    		c-2.439,0.982-5.458,1.155-8.112,0.812c-3.108-0.4-4.014-2.961-2.188-5.565c2.726-3.887,5.577-7.707,8.647-11.325
    		c2.318-2.733,3.747-2.63,6.008,0.228c1.203,1.519,2.987,3.746,2.607,5.133c-1.237,4.517,1.367,7.839,2.592,11.583
    		c0.396,1.213,0.931,2.812,1.891,3.334c4.034,2.193,7.669,4.789,12.427,6.139c4.643,1.318,8.088,0.693,12.258-0.381
    		c2.959-0.763,6.272-0.075,9.423-0.186c6.605-0.232,12.593-2.694,18.722-4.924c7.128-2.593,14.588-4.26,21.854-6.484
    		c3.874-1.187,7.728-2.512,11.445-4.112c5.924-2.551,11.948-4.998,17.523-8.199c6.433-3.692,14.042-5.113,19.604-10.521
    		c1.667-1.62,4.088-2.695,6.355-3.42C686.146,727.126,687.081,723.888,686.348,720.045z M542.44,745.148
    		c-1.63,1.885-3.021,3.418-4.258,5.068c-0.1,0.133,0.874,1.07,1.35,1.634c1.435-1.517,2.902-3.007,4.247-4.6
    		C543.892,747.117,543.18,746.288,542.44,745.148z M696.286,717.805c-0.893-1.216-1.512-2.06-2.131-2.903
    		c-0.718,0.727-2.039,1.478-2.01,2.173c0.042,1.035,1.038,2.031,1.629,3.044C694.519,719.433,695.263,718.747,696.286,717.805z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M644.863,792.484c1.292,4.181-1.023,6.674-5.35,8.334
    		c-5.646,2.167-11.081,4.887-16.743,7.007c-1.148,0.431-3.303-0.329-4.29-1.275c-2.032-1.946-3.37-1.218-5.642-0.268
    		c-3.926,1.642-7.22,4.627-11.931,4.864c-2.884,0.146-6.456,1.489-8.324,3.557c-3.118,3.449-6.948,1.416-10.243,2.585
    		c-0.552,0.195-2.018-0.682-2.185-1.3c-0.2-0.74,0.324-1.946,0.931-2.563c0.649-0.661,1.738-0.927,2.67-1.27
    		c6.271-2.308,12.569-4.539,18.817-6.904c2.749-1.041,5.577-2.064,8.011-3.648c0.987-0.643,1.018-2.757,1.483-4.201
    		c-1.227,0.131-2.58-0.028-3.659,0.441c-6.545,2.849-12.522,7.209-20.121,7.191c-0.873-0.002-1.715,0.903-2.631,1.208
    		c-1.59,0.531-3.807,1.789-4.715,1.181c-1.637-1.096-2.574-3.303-3.628-5.146c-0.216-0.377,0.17-1.348,0.564-1.788
    		c3.58-3.991,7.123-8.025,10.906-11.819c1.214-1.218,3.032-2.068,4.724-2.485c0.986-0.243,2.267,0.705,3.414,1.117
    		c-0.469,1.112-0.64,2.607-1.471,3.254c-1.545,1.201-3.772,1.596-5.157,2.914c-1.256,1.195-1.769,3.173-2.608,4.806
    		c1.728,0.216,3.938,1.221,5.097,0.509c4.545-2.79,10.394-2.581,14.332-7.312c1.484-1.784,6.507-1.143,9.748-0.601
    		c0.876,0.146,1.148,3.968,1.678,6.106c0.375,1.517,0.281,3.907,1.177,4.358c1.316,0.663,3.518,0.378,5.008-0.271
    		c3.866-1.685,7.334-6.947,7.905-11.216c0.133-0.987,0.429-1.965,0.45-2.951c0.062-2.864-0.379-5.868,4.064-5.808
    		c3.786,0.052,7.327,2.682,7.692,6.256C644.99,788.828,644.863,790.34,644.863,792.484z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M674.876,792.849c-4.759,1.213-8.746,0.83-11.502-3.186
    		c-3.123-4.55-4.338-9.606-3.274-15.042c0.23-1.176,1.243-2.275,2.095-3.234c1.1-1.239,2.601-2.156,3.549-3.484
    		c0.763-1.07,0.961-2.545,1.409-3.839c-1.453,0.209-3.244-0.039-4.282,0.73c-1.724,1.275-3.351,3.73-5.284,1.388
    		c-1.744-2.114-0.939-4.86,1.594-6.395c1.604-0.972,3.481-2.176,5.207-2.131c3.959,0.103,6.955-1.121,9.817-3.865
    		c1.502-1.439,3.984-2.065,6.137-2.518c0.886-0.186,2.743,0.886,2.944,1.688c0.237,0.949-0.56,2.527-1.398,3.306
    		c-2.634,2.445-5.839,4.346-8.172,7.025c-2.086,2.396-3.325,5.533-4.903,8.363c-0.783,1.405-1.369,2.932-2.249,4.27
    		c-2.789,4.24,0.352,11.031,5.409,11.353c5.74,0.364,11.325,0.223,15.274-5.117c0.936-1.264,2.564-2.107,4.021-2.859
    		c0.905-0.466,2.774-0.843,3.012-0.479c0.628,0.965,1.217,2.783,0.722,3.491c-1.269,1.812-3.041,3.325-4.802,4.719
    		c-0.975,0.772-2.679,0.756-3.452,1.629C683.415,792.422,679.693,794.734,674.876,792.849z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M498.034,820.166c-5.338,1.113-9.287-2.146-13.29-4.535
    		c-2.392-1.427-6.29-1.435-6.459-5.841c-0.074-1.92-2.569-4.064-2.153-5.624c1.066-3.997,3.028-7.783,4.873-11.534
    		c0.921-1.872,2.467-3.417,4.768-1.647c2.038,1.568,2.349,3.321,0.582,5.521c-3.054,3.804-1.442,8.234-1.133,12.413
    		c0.068,0.93,1.331,1.85,2.178,2.628c2.498,2.297,13.884,3.884,16.674,2.046c2.655-1.749,5.006-4.019,4.271-7.754
    		c-0.837-4.258,1.088-7.747,3.071-11.196c1.176-2.044,2.655-1.871,3.793,0.029c1.795,2.997-1.289,19.094-4.131,21.101
    		c-1.942,1.371-4.072,2.636-6.307,3.389C502.653,819.875,500.29,819.86,498.034,820.166z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M468.104,765.718c-2.77,1.046-5.491,2.264-8.331,3.058
    		c-1.232,0.344-3.272,0.298-3.922-0.454c-0.691-0.803-0.756-3.346-0.078-3.912c2.27-1.894,1.479-4.671,2.48-6.586
    		c1.745-3.34,4.354-7.098,7.565-8.554c3.098-1.405,7.139-1.47,10.963,1.698c7.92,6.564,5.556,8.161-0.847,14.242
    		c-1.621,1.54-4.845,1.393-7.332,2.021C468.438,766.727,468.271,766.222,468.104,765.718z M475.732,759.876
    		c-0.481-1.101-0.558-1.784-0.884-1.937c-2.134-1.005-4.312-1.924-6.518-2.758c-0.144-0.054-0.983,1.172-0.925,1.23
    		c1.581,1.584,3.102,3.304,4.957,4.495C472.974,761.3,474.482,760.293,475.732,759.876z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M677.981,713.138c-0.302,2.563-2.368,4.996-5.344,5.558
    		c-3.625,0.684-4.158,2.654-3.934,5.593c0.234,3.065,0.112,6.032-2.784,7.97c-0.757,0.506-2.15,1.258-2.467,0.986
    		c-0.76-0.653-1.412-1.834-1.45-2.827c-0.056-1.45,0.837-2.978,0.634-4.371c-0.206-1.406-1.012-2.925-2.009-3.956
    		c-0.89-0.92-3.38-1.139-3.455-1.848c-0.253-2.383-0.021-4.911,0.531-7.266c1.314-5.608,6.856-6.311,10.891-8.498
    		C671.277,703.023,678.104,709.106,677.981,713.138z M666.829,715.938c-1.123-1.169-1.82-1.896-2.518-2.622
    		c-0.635,0.81-1.627,1.557-1.784,2.45c-0.112,0.644,0.889,1.481,1.394,2.233C664.731,717.424,665.542,716.85,666.829,715.938z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M282.256,704.089c5.922,0.162,9.462,3.694,9.533,8.53
    		c0.037,2.554,0.788,4.342,3.056,5.785c2.069,1.318,4.003,3.033,5.562,4.931c3.374,4.105,8.11,5.847,12.751,7.644
    		c2.429,0.94,3.457,2.531,2.371,4.396c-0.562,0.965-3.529,1.617-4.629,1.006c-7.846-4.364-15.846-8.556-21.884-15.546
    		c-1.798-2.081-3.233-3.907-3.149-6.927c0.099-3.586-1.105-4.291-4.679-3.661c-0.814,0.143-1.658,0.621-2.409,0.49
    		c-1.045-0.182-2.016-0.792-3.018-1.22c0.519-0.9,0.826-2.106,1.599-2.642C279.206,705.596,281.271,704.633,282.256,704.089z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M425.24,756.231c-0.038-5.081,0.812-6.096,5.616-7.608
    		c3.245-1.021,6.368-2.465,9.648-3.333c4.329-1.146,6.171,2.505,8.315,5.076c1.885,2.258,1.038,4.849-0.596,6.883
    		c-2.184,2.72-4.525,5.361-8.697,4.664c-3.24-0.541-6.524-1.1-9.792-1.117C426.202,760.777,424.838,759.329,425.24,756.231z
    		 M442.068,757.867c0.982-1.705,1.807-2.858,2.277-4.141c0.126-0.343-0.832-1.082-1.292-1.64c-0.985,1.165-2.039,2.286-2.876,3.549
    		C440.067,755.802,441.06,756.699,442.068,757.867z M433.241,756.748c1.473-1.378,2.554-2.291,3.453-3.357
    		c0.109-0.13-1.064-1.608-1.122-1.574c-1.185,0.696-2.364,1.445-3.351,2.389C432.038,754.38,432.695,755.435,433.241,756.748z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M404.79,803.556c-0.866-4.021,0.251-7.453,3.615-10.08
    		c0.518-0.404,1.187-0.908,1.315-1.474c1.309-5.729,6.232-6.369,10.664-7.816c1.372-0.447,2.555-1.451,3.863-2.122
    		c2.749-1.407,4.458-0.322,4.5,2.812c0.065,4.863-3.553,8.464-8.372,8.332c-7.04-0.193-7.031-0.02-7.774,6.951
    		c-0.328,3.078-1.412,6.109-2.456,9.055c-0.282,0.797-1.807,1.804-2.521,1.644c-1.057-0.236-2.422-1.274-2.714-2.252
    		C404.455,807.081,404.79,805.32,404.79,803.556z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M520.422,863.982c-1.661,0.688-4.335,2.38-7.204,2.829
    		c-3.255,0.509-7.137,0.846-9.908-0.485c-2.727-1.309-2.59-4.97-1.259-8.055c1.04-2.41,2.275-2.66,4.053-0.898
    		c0.822,0.815,1.58,1.706,2.289,2.623c1.681,2.177,3.494,2.298,5.482,0.453c0.484-0.449,0.934-1.322,1.413-1.332
    		c4.83-0.1,8.86-1.62,11.708-5.784c0.061-0.089,2.642,1.195,3.69,2.208c1.951,1.886,1.875,4.138,0.229,6.242
    		C528.563,864.789,525.365,865.106,520.422,863.982z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M439.418,813.497c1.803-4.985,3.596-9.975,5.426-14.95
    		c0.165-0.449,0.608-0.787,0.867-1.21c0.881-1.44,1.729-4.123,2.604-4.128c3.378-0.021,6.826,0.57,10.104,1.463
    		c1.069,0.292,2.542,2.39,2.329,3.317c-0.308,1.339-1.784,2.841-3.126,3.398c-1.588,0.66-3.562,0.428-5.37,0.517
    		c-4.005,0.198-5.273,1.528-5.44,5.507c-0.124,2.983-0.427,5.958-0.643,8.937c-0.143,1.972-0.542,3.693-3.103,3.589
    		c-2.847-0.116-3.034-2.036-2.917-4.201c0.036-0.665,0.006-1.332,0.006-1.999C439.909,813.657,439.663,813.577,439.418,813.497z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M817.491,762.945c-1.249,2.418-2.414,4.681-3.585,6.941
    		c-1.649,3.182-3.193,6.427-5.028,9.497c-0.458,0.766-1.859,0.968-2.826,1.429c-0.271-1.2-0.748-2.403-0.734-3.601
    		c0.008-0.688,0.799-1.376,1.259-2.05c2.45-3.582,2.138-3.919-1.945-5.744c-4.812-2.152-8.047-6.559-2.562-10.415
    		c1.338-0.941,1.964-2.885,2.945-4.35c0.62-0.926,1.292-1.816,1.941-2.724c0.872,1.221,2.466,2.462,2.432,3.657
    		c-0.041,1.437-1.596,2.776-2.252,4.265c-0.506,1.148-0.637,2.462-0.934,3.702c1.364-0.193,2.822-0.155,4.061-0.659
    		c0.817-0.333,1.188-1.646,1.991-2.156c1.042-0.66,2.517-1.55,3.428-1.238C816.502,759.781,816.815,761.548,817.491,762.945z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M747.994,812.873c-0.541,0.314-1.273,1.09-2.053,1.139
    		c-4.714,0.292-7.896,3.076-10.912,6.284c-2.262,2.406-4.488,4.9-7.064,6.933c-1.177,0.929-3.829,1.688-4.643,1.045
    		c-2.053-1.624-5.019-3.208-4.203-6.724c0.442-1.903,1.604-1.645,3.014-0.808c1.173,0.697,2.969,1.877,3.716,1.46
    		c1.928-1.076,3.823-2.664,5.012-4.511c2.292-3.562,4.048-7.465,6.129-11.168c0.36-0.642,1.156-1.211,1.872-1.446
    		C740.738,804.459,748.037,810.388,747.994,812.873z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M324.837,763.392c-0.14,4.221-1.501,5.128-4.341,4.018
    		c-1.54-0.603-2.897-1.73-4.465-2.183c-0.894-0.259-2.61,0.061-2.933,0.665c-0.401,0.755-0.119,2.494,0.524,3.058
    		c1.469,1.284,3.321,2.141,5.054,3.104c0.57,0.317,1.353,0.296,1.867,0.667c1.393,1.005,2.77,2.066,3.94,3.306
    		c0.154,0.164-1.036,2.22-1.424,2.164c-1.62-0.236-3.188-0.902-4.74-1.498c-0.594-0.229-1.141-1.052-1.634-0.992
    		c-4.784,0.582-6.834-1.724-8.662-6.07c-1.97-4.685-0.668-8.746,4.669-9.27c2.96-0.29,6.053-0.378,8.938,0.194
    		C323.088,760.844,324.214,762.804,324.837,763.392z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M353.456,711.085c0.897,0.827,2.472,1.683,3.101,2.991
    		c1.107,2.305,0.395,4.494-1.647,6.227c-1.942,1.647-3.765,2.459-6.248,0.845c-3.896-2.534-5.655-1.55-6.586,3.123
    		c-0.34,1.705-0.991,3.446-1.928,4.894c-0.507,0.783-1.917,0.982-2.919,1.444c-0.37-0.992-0.968-1.96-1.076-2.979
    		c-0.993-9.337,4.668-12.726,12.147-14.502C349.864,712.756,351.324,711.945,353.456,711.085z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M256.221,729.667c-0.374-3.988,2.183-6.481,4.704-7.188
    		c2.675-0.75,6.001,0.385,8.918,1.166c1.053,0.281,1.656,2.012,2.614,2.926c1.993,1.902,1.562,3.418-0.52,4.851
    		c-1.508,1.038-3.101,2.002-4.428,3.242c-2.056,1.921-3.905,3.323-6.063,0.161c-0.321-0.471-1.102-0.89-1.668-0.888
    		C256.485,733.946,255.868,732.001,256.221,729.667z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M722.086,769.334c0.014,0.828,0.128,1.683-0.04,2.476
    		c-0.119,0.565-0.542,1.343-0.997,1.481c-0.431,0.133-1.153-0.353-1.606-0.734c-0.352-0.296-0.482-0.854-0.717-1.291
    		c-2.959-5.53-5.864-11.09-8.901-16.576c-1.432-2.587-3.268-4.957-4.608-7.584c-0.48-0.941-0.479-3.063,0.065-3.396
    		c1.086-0.664,3.104-1.075,4.009-0.481c1.531,1.005,2.72,2.746,3.649,4.403c1.495,2.664,2.652,5.516,3.97,8.28
    		C718.979,760.257,722.003,764.23,722.086,769.334z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M576.837,859.214c0-2.477-0.299-5.004,0.104-7.414
    		c0.272-1.632,1.348-3.262,2.469-4.536c0.318-0.362,3.019,0.589,3.003,0.828c-0.437,6.461,4.803,11.93,3.223,18.544
    		c-0.075,0.314,0.12,0.798-0.048,0.968c-1.532,1.553-3.117,3.052-4.687,4.567c-1.376-1.677-3.422-3.164-3.961-5.076
    		c-0.69-2.447-0.168-5.237-0.168-7.88C576.794,859.215,576.815,859.215,576.837,859.214z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M565.534,761.094c-0.852-0.393-1.973-0.91-3.094-1.428
    		c0.658-1.058,1.09-2.425,2.021-3.109c1.969-1.444,4.274-2.422,6.289-3.812c0.986-0.682,1.63-1.859,2.429-2.812
    		c-0.934-0.469-1.832-1.24-2.805-1.347c-2.637-0.29-2.734-2.132-2.57-3.981c0.133-1.495,0.396-3.99,1.137-4.205
    		c1.457-0.425,3.581-0.018,4.891,0.854c1.885,1.255,3.797,2.99,4.743,4.976c0.776,1.631,0.989,4.629,0.004,5.787
    		c-2.651,3.112-6.011,5.649-9.245,8.218C568.532,760.87,567.138,760.761,565.534,761.094z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M300.371,749.689c-2.06-1.044-3.707-1.73-5.199-2.666
    		c-3.051-1.912-4.579-1.501-6.281,1.629c-0.642,1.181-1.667,2.168-2.59,3.176c-1.114,1.217-2.319,2.351-3.46,3.544
    		c-0.763,0.798-1.353,2.049-2.262,2.339c-0.874,0.279-2.283-0.156-3.017-0.789c-0.398-0.344-0.196-1.982,0.281-2.641
    		c2.263-3.117,4.55-6.247,7.132-9.098c5.253-5.801,10.843-6.612,16.973-2.371c1.026,0.711,1.603,2.854,1.443,4.226
    		C303.281,747.979,301.537,748.729,300.371,749.689z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M622.577,746.46c-1.471-0.804-3.983-1.402-4.19-2.455
    		c-0.408-2.074-0.221-4.908,0.964-6.503c1.765-2.374,2.411-4.132,0.646-6.64c-0.397-0.564-0.298-2.218,0.094-2.437
    		c0.741-0.414,2.103-0.534,2.78-0.104c3.042,1.927,6.33,3.727,8.711,6.323c1.155,1.26,1.199,4.426,0.517,6.273
    		C631.078,743.675,626.1,745.854,622.577,746.46z M625.363,741.588c0.845-1.23,1.599-1.884,1.704-2.629
    		c0.08-0.565-0.664-1.249-1.042-1.88c-0.694,0.515-1.811,0.929-1.965,1.573C623.902,739.307,624.693,740.189,625.363,741.588z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M446.031,853.15c0.114,6.79-2.439,10.476-8.037,11.367
    		c-1.33,0.213-3.179-1.262-4.381-2.393c-0.74-0.696-1.294-2.471-0.922-3.318c1.688-3.844,3.15-7.975,7.031-10.301
    		c1.4-0.839,3.13-1.851,4.547-1.648c0.765,0.109,1.314,2.583,1.715,4.057C446.236,851.841,446.031,852.892,446.031,853.15z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M357.012,849.336c-4.929-0.305-8.712-4.161-6.485-6.808
    		c1.983-2.358,5.008-4.149,7.923-5.308c1.972-0.784,4.577-0.232,6.853,0.072c3.41,0.455,4.343,2.492,2.862,5.683
    		C366.499,846.562,361.184,849.595,357.012,849.336z M361.538,843.88c-1.929-1.188-2.619-1.938-3.09-1.819
    		c-0.552,0.141-1.286,1.062-1.247,1.595c0.037,0.498,0.95,1.244,1.536,1.305C359.406,845.028,360.141,844.459,361.538,843.88z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M562.281,863.437c0.011,3.234-4.497,7.483-7.971,7.527
    		c-0.817,0.01-1.639-0.199-2.458-0.307c-5.467-0.715-6.241-2.462-3.152-7.002c1.104-1.625,1.932-3.48,3.228-4.924
    		c1.227-1.365,3.557-3.595,4.257-3.233c2.172,1.125,3.91,3.205,5.576,5.123C562.313,861.258,562.137,862.527,562.281,863.437z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M740.413,730.375c1.435,1.053,2.644,1.525,3.175,2.406
    		c1.854,3.07,3.721,6.177,5.12,9.467c1.996,4.693,3.65,9.538,5.293,14.372c0.367,1.082,0.077,2.387,0.09,3.589
    		c-1.122-0.329-2.787-0.306-3.271-1.051c-2.067-3.181-5.373-5.467-5.209-10.254c0.098-2.836-2.698-5.73-4.037-8.67
    		c-1.009-2.214-1.933-4.498-2.541-6.843C738.843,732.66,739.804,731.629,740.413,730.375z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M709.129,726.843c1.437,0.73,2.812,1.011,3.29,1.821
    		c0.318,0.539-0.624,1.845-1.046,2.788c-0.397,0.887-1.298,1.797-1.207,2.61c0.51,4.568-1.189,6.352-5.898,5.845
    		c-1.569-0.17-3.266,1.397-4.985,1.88c-1.116,0.312-2.637,0.533-3.515,0.015c-1.188-0.702-2.944-2.614-2.679-3.194
    		c0.765-1.673,2.264-3.927,3.759-4.165c3.365-0.536,5.066-2.848,7.308-4.754C705.588,728.47,707.462,727.77,709.129,726.843z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M728.098,759.267c0-0.99-0.223-2.047,0.05-2.955
    		c0.472-1.571,0.974-4.087,1.96-4.351c1.975-0.527,4.738-0.49,6.346,0.571c2.276,1.504,5.033,3.492,3.647,7.27
    		c-1.121,3.056-5.452,6.76-8.411,6.928c-2.14,0.12-3.545-0.632-3.568-3.008c-0.014-1.485-0.002-2.97-0.002-4.455
    		C728.112,759.267,728.105,759.267,728.098,759.267z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M390.113,791.646c0.055,2.339-3.052,7.549-5.484,7.414
    		c-1.912-0.105-4.107-1.737-5.443-3.313c-0.905-1.066-0.939-3.291-0.627-4.85c0.3-1.492,1.867-2.698,2.318-4.198
    		c1-3.325,3.739-3.05,6.074-2.979c2.589,0.078,2.851,2.304,2.873,4.424C389.835,789.135,389.982,790.125,390.113,791.646z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M730.622,727.921c-1.154,0-2.505,0.373-3.411-0.102
    		c-0.959-0.504-2.017-1.788-2.065-2.779c-0.048-0.937,0.976-2.177,1.859-2.847c1.079-0.818,3.611-1.19,3.607-1.738
    		c-0.046-5.395,4.759-4.06,7.448-5.46c0.576-0.3,2.696,0.813,2.894,1.585c1.468,5.741-3.222,11.299-9.339,11.355
    		c-0.331,0.003-0.662,0-0.993,0C730.622,727.931,730.622,727.926,730.622,727.921z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M690.561,816.725c1.058,0.874,1.785,1.288,2.262,1.898
    		c3.729,4.776,7.541,9.498,10.99,14.472c0.681,0.982,0.324,3.236-0.386,4.396c-0.353,0.577-3.092,0.555-3.865-0.158
    		c-1.725-1.591-2.883-3.792-4.29-5.732c-1.32-1.82-2.883-3.506-3.937-5.467c-1.116-2.081-1.937-4.373-2.496-6.668
    		C688.676,818.797,689.848,817.802,690.561,816.725z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M234.318,768.366c2.855,1.077,5.826,1.28,6.988,2.835
    		c2.178,2.914-1.353,4.527-2.811,6.545c-0.195,0.27-0.393,0.537-0.611,0.788c-1.283,1.479-2.622,3.804-4.784,2.087
    		c-1.961-1.557-0.693-3.534,0.706-5.182c0.393-0.462,0.272-1.359,0.387-2.058c-0.747,0.128-1.654,0.036-2.202,0.437
    		c-0.773,0.564-1.372,1.438-1.885,2.276c-1.319,2.155-2.83,2.679-4.828,0.842c-2.072-1.904-2.144-3.984,0.302-5.264
    		C228.352,770.224,231.458,769.416,234.318,768.366z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M417.971,742.192c-1.39,2.666-2.492,4.81-3.62,6.94
    		c-1.235,2.335-2.584,4.615-3.714,6.999c-0.758,1.602-1.637,3.514-3.502,2.329c-1.212-0.77-2.567-3.766-2.109-4.32
    		c3.426-4.149,1.799-10.536,6.359-13.989c1.039-0.787,2.721-1.783,3.621-1.434C416.202,739.182,416.922,740.872,417.971,742.192z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M677.705,834.113c1.9,0.876,4.957,1.348,5.438,2.721
    		c0.764,2.18,0.714,5.801-0.651,7.278c-1.729,1.87-5.066,2.463-7.829,3.033c-0.747,0.154-2.182-1.624-2.817-2.781
    		c-0.509-0.926-0.299-2.253-0.386-3.403C671.043,835.45,671.659,834.819,677.705,834.113z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M654.736,732.979c-1.313,1.439-2.283,3.487-3.612,3.755
    		c-2.701,0.543-2.653-1.918-3.161-3.916c-0.794-3.124-1.926-6.212-3.328-9.116c-1.193-2.469-3.062-4.604-4.442-6.995
    		c-0.495-0.858-0.434-2.036-0.626-3.068c1.238-0.137,2.926-0.846,3.629-0.296c1.756,1.373,3.357,3.146,4.484,5.073
    		c1.704,2.914,2.942,6.1,4.397,9.16C652.819,729.137,653.603,730.679,654.736,732.979z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M645.321,737.707c-1.315,1.487-2.626,3.072-4.114,4.469
    		c-0.126,0.118-1.765-0.858-2.142-1.607c-2.652-5.281-3.078-11.562-7.955-15.895c-2.527-2.245,0.636-3.89,1.96-5.096
    		c1.574-1.434,2.3,0.106,3.103,1.617c2.312,4.354,4.936,8.541,7.399,12.815C644.136,734.99,644.548,736.058,645.321,737.707z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M714.205,772.525c-2.368,2.348-4.015,4.688-6.266,5.946
    		c-1.347,0.752-3.936,0.344-5.436-0.494c-2.125-1.186-2.937-3.425-1.756-6.002c0.396-0.866,0.338-1.933,0.562-2.89
    		c0.846-3.604,2.658-4.18,5.545-1.931c1.101,0.856,2.51,1.308,3.657,2.115C711.597,770.034,712.523,771.023,714.205,772.525z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M220.51,750.473c-1.613,2.545-2.78,4.316-3.877,6.132
    		c-2.415,3.997-7.549,6.067-8.106,11.445c-0.035,0.333-2.031,0.966-2.299,0.684c-1.146-1.204-2.859-3.887-2.812-3.922
    		c5.672-4.179,6.725-12.723,14.162-15.103C218.261,749.49,219.214,750.111,220.51,750.473z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M250.059,709.753c0.899,0.6,2.411,1.07,2.759,1.984
    		c0.388,1.018,0.026,3.345-0.542,3.538c-3.849,1.314-4.937,5.105-7.35,7.656c-1.479,1.564-3.239,3.188-4.94,1.575
    		c-0.908-0.86-0.79-3.771-0.054-5.199c1.583-3.072,2.411-6.957,6.467-8.16C247.484,710.825,248.523,710.344,250.059,709.753z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M209.118,745.613c-0.602,1.065-0.945,2.002-1.559,2.7
    		c-2.709,3.08-5.396,6.193-8.311,9.072c-0.978,0.965-3.082,2.133-3.845,1.711c-2.017-1.114-1.339-2.932-0.052-4.601
    		c2.813-3.649,5.395-7.483,8.329-11.027c0.59-0.713,2.461-0.891,3.49-0.552C207.997,743.189,208.445,744.615,209.118,745.613z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M796.939,786.191c-0.504,1.085-0.767,2.723-1.654,3.203
    		c-0.64,0.345-2.732-0.572-3.112-1.409c-2.328-5.133-3.478-10.827-7.655-15.089c-0.243-0.248,1.292-2.691,2.421-3.571
    		c0.358-0.278,2.552,0.944,3.11,1.93c2.002,3.535,3.696,7.249,5.438,10.928C796.043,783.359,796.385,784.638,796.939,786.191z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M662.168,690.102c0.257,0.771,1.358,2.509,0.928,3.067
    		c-1.468,1.905-3.392,3.5-5.309,4.999c-1.002,0.782-2.541,0.891-3.51,1.697c-2.289,1.906-4.257,1.847-5.834-0.659
    		c-1.322-2.099-0.266-5.413,2.212-6.242C654.047,691.828,657.609,691.204,662.168,690.102z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M256.172,783.135c-1.042,1.672-1.867,3.294-2.968,4.698
    		c-2.135,2.723-4.244,5.52-6.762,7.855c-0.772,0.717-2.843,0.032-4.319-0.011c0.355-1.262,0.327-2.91,1.137-3.714
    		c2.313-2.295,4.148-4.489,4.125-8.104c-0.015-2.344,5.927-3.976,7.841-2.337C255.675,781.908,255.867,782.595,256.172,783.135z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M262.477,787.036c2.362,0.09,3.702,3.162,1.921,5.281
    		c-2.321,2.763-5.36,4.908-6.012,8.866c-0.075,0.453-2.097,0.997-3.047,0.775c-0.904-0.211-2.052-1.248-2.284-2.134
    		c-0.792-3.021,5.328-11.992,8.431-12.683C261.809,787.071,262.146,787.068,262.477,787.036z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M498.283,798.403c1.363,1.545,3.6,2.949,3.877,4.67
    		c0.386,2.386-2.048,2.98-4.196,2.786c-2.248-0.203-4.392-0.812-4.379-3.615C493.597,799.774,495.13,798.784,498.283,798.403z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M601.705,749.109c-1.533-2.134-3.549-3.726-3.376-5.026
    		c0.189-1.425,2.433-3.613,3.718-3.57c1.34,0.044,3.581,2.32,3.627,3.681C605.719,745.553,603.534,746.986,601.705,749.109z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M677.418,779.987c-1.398-2.245-2.866-3.61-2.897-5.007
    		c-0.021-0.908,2.459-2.855,3.106-2.591c1.394,0.568,2.85,2.122,3.238,3.556C681.074,776.715,679.129,778.07,677.418,779.987z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M293.287,766.338c1.293-1.149,2.078-2.365,2.756-2.308
    		c0.724,0.062,1.837,1.295,1.884,2.071c0.054,0.906-0.861,1.871-1.356,2.811C295.657,768.196,294.745,767.48,293.287,766.338z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M578.07,1199.482c6.237,0.543,12.594,2.074,18.678,1.341
    		c5.632-0.68,11.145,2.306,16.066,0.632c5.165-1.758,10.1-1.351,15.156-1.527c2.282-0.079,5.379-1.156,6.195,2.391
    		c0.492,2.137-2.815,4.044-6.467,4.01c-23.297-0.217-46.594-0.428-69.892-0.624c-2.497-0.021-5.047,0.381-7.471-0.024
    		c-1.237-0.207-2.631-1.594-3.183-2.801c-0.235-0.514,1.378-2.625,2.369-2.804c2.79-0.503,3.539-1.987,3.438-4.524
    		c-0.094-2.325-0.012-4.662,0.087-6.99c0.125-2.931-0.856-5.037-3.599-6.515c-3.208-1.729-4.02-4.292-2.69-6.931
    		c1.845-3.661,5.963-6.094,8.782-4.868c0.69,0.3,1.146,1.923,1.119,2.922c-0.052,1.944-0.882,3.916-0.68,5.801
    		c0.119,1.113,1.747,3.021,2.433,2.912c1.277-0.203,3.024-1.397,3.414-2.565c1.748-5.254,3.738-6.864,8.791-6.465
    		c2.312,0.183,2.993,1.142,1.874,3.229c-0.077,0.145-0.264,0.252-0.293,0.398c-1.043,5.293-3.394,8.784-9.399,9.985
    		c-3.653,0.73-5.568,8.608-3.509,11.871c0.607,0.961,2.458,1.536,3.765,1.583c4.989,0.181,9.988,0.073,14.983,0.073
    		C578.049,1199.821,578.06,1199.651,578.07,1199.482z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M595.888,1377.893c5.911-1.254,11.1,0.664,15.564-2.805
    		c1.033-0.803,3.229-0.031,4.882-0.137c1.281-0.081,3.147,0.062,3.72-0.686c2.984-3.896,7.054-2.011,10.657-2.231
    		c0.886-0.054,2.764,1.749,2.643,2.026c-0.595,1.365-1.714,3.562-2.64,3.559c-7.905-0.036-15.011,4.404-23.19,3.496
    		c-4.784-0.531-9.848,0.967-14.722,1.893c-4.148,0.787-8.151,1.834-11.782-1.431c-0.555-0.498-1.533-0.75-2.31-0.739
    		c-9.234,0.125-15.67-4.874-20.812-11.757c-2.097-2.808-3.806-6.172-4.691-9.545c-1.03-3.92-0.159-7.651,3.306-10.726
    		c3.571-3.169,3.323-3.732,0.273-7.303c-2.812-3.291-2.753-4.57,0.372-8.127c2.604-2.963,7.521-4.493,9.611-2.697
    		c0.623,0.534,0.535,2.246,0.285,3.305c-0.132,0.561-1.375,0.795-2,1.316c-1.039,0.866-1.983,1.846-2.968,2.776
    		c1.448,0.438,2.91,1.256,4.341,1.204c1.357-0.05,2.806-0.821,3.995-1.607c2.52-1.666,4.249-0.306,5.475,1.528
    		c1.657,2.479-0.776,3.017-2.32,3.814c-1.31,0.677-2.698,1.859-3.993,1.786c-3.866-0.22-5.297,2.386-6.502,5.115
    		c-1.253,2.835-3.347,6.125-2.797,8.738c0.856,4.077,2.307,8.523,7.155,10.52c5.53,2.276,10.668,5.625,16.318,7.453
    		C587.888,1377.969,592.568,1377.6,595.888,1377.893z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M618.982,1134.006c-0.24,3.316-0.453,6.635-0.728,9.948
    		c-0.396,4.772,0.081,5.249,4.746,4.836c2.317-0.205,4.671-0.527,6.963-0.31c1.287,0.122,2.481,1.236,3.718,1.904
    		c-0.865,1.04-1.62,2.853-2.611,2.992c-5.726,0.806-11.247,3.408-17.372,0.187c-3.1-1.63-7.452-1.097-11.244-1.15
    		c-12.979-0.183-25.962-0.206-38.943-0.257c-3.459-0.014-6.972,0.48-10.365,0.03c-3.688-0.49-7.282-1.759-10.852-2.896
    		c-0.208-0.066-0.203-2.49,0.371-2.915c0.789-0.584,2.218-0.404,3.369-0.384c13.454,0.235,26.912,0.377,40.36,0.812
    		c7.239,0.234,14.5,0.695,21.677,1.622c3.621,0.468,4.566-0.503,4.214-3.841c-0.115-1.093-0.072-2.625,0.578-3.304
    		c3.314-3.453,1.606-7.134,0.803-10.789c-0.14-0.636-0.932-1.416-0.744-1.787c0.975-1.934,1.853-4.967,3.354-5.359
    		c2.83-0.738,2.415,2.315,2.536,4.153c0.143,2.155,0.033,4.326,0.033,6.49C618.892,1133.995,618.937,1134,618.982,1134.006z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M590.555,1318.741c-12.139,0-24.278,0.048-36.416-0.073
    		c-1.265-0.013-2.519-1.034-3.777-1.588c0.685-1.2,1.087-2.895,2.12-3.483c1.482-0.845,3.423-1.211,5.17-1.216
    		c23.455-0.069,46.91-0.04,70.365-0.036c0.83,0,1.958-0.358,2.433,0.05c1.25,1.074,2.217,2.479,3.296,3.752
    		c-1.279,0.79-2.543,2.232-3.84,2.263c-7.961,0.187-15.93,0.048-23.896,0.03c-5.151-0.012-10.304-0.002-15.455-0.002
    		C590.555,1318.538,590.555,1318.64,590.555,1318.741z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M570.373,1256.166c4.332,0,8.663-0.006,12.995,0.002
    		c3.146,0.006,6.315,0.392,8.859-2.309c0.634-0.672,2.164-0.678,3.284-0.687c10.661-0.085,21.322-0.112,31.983-0.138
    		c1.867-0.005,4.093-0.008,4.083,2.493c-0.008,2.312-1.794,3.467-4.088,3.473c-6.997,0.018-13.997-0.142-20.99,0.008
    		c-5.418,0.114-10.998-0.548-15.974,2.619c-0.746,0.475-1.944,0.274-2.937,0.319c-5.808,0.267-11.616,0.708-17.424,0.711
    		c-5.796,0.004-11.603-0.311-17.375-0.814c-0.951-0.083-2.652-2.182-2.436-2.781c0.435-1.203,1.914-2.798,3.033-2.855
    		c5.648-0.287,11.32-0.132,16.984-0.132C570.373,1256.105,570.373,1256.136,570.373,1256.166z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M602.465,1233.32c0.865-2.349,1.37-4.936,2.715-6.968
    		c0.992-1.499,2.891-2.405,3.854,0.741c1.013,3.311,2.58,2.301,3.942,0.068c0.347-0.567,0.584-1.253,1.057-1.679
    		c0.818-0.736,1.781-1.312,2.684-1.956c0.444,0.947,0.869,1.904,1.337,2.839c0.413,0.827,0.873,1.631,1.312,2.444
    		c0.808-0.484,1.719-0.857,2.4-1.48c1.094-1.002,1.853-2.528,3.096-3.161c1.13-0.575,3.172-0.82,3.974-0.173
    		c0.824,0.665,1.203,2.795,0.791,3.924c-1.993,5.464-8.415,8.332-14.191,6.634c-3.173-0.933-5.701,0.479-6.145,3.615
    		c-0.31,2.194,0.419,4.373-2.544,5.8c-4.472,2.153-8.53,5.196-13.685,1.881c-1.098-0.706-2.335-1.267-3.582-1.659
    		c-4.087-1.283-5.29-4.569-3.115-8.312c0.957-1.648,1.365-3.63,2.398-5.215c0.988-1.516,2.461-3.823,3.726-3.826
    		c2.826-0.007,2.072,2.777,2.077,4.667c0.001,0.791-0.169,1.69-0.571,2.352c-0.939,1.541-3.336,2.496-1.052,4.935
    		c2.369,2.529,5.153,2.934,8.171,2.214c2.97-0.707,2.034-3.36,2.201-5.396c0.055-0.66,0.009-1.328,0.009-1.993
    		C603.037,1233.517,602.751,1233.418,602.465,1233.32z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M613.469,1285.873c-5.145-0.273-8.611,2.874-12.014,6.041
    		c-0.438,0.407-0.584,1.619-0.318,2.217c2.371,5.354,9.267,7.392,14.732,4.655c1.572-0.788,3.491-1.272,5.229-1.216
    		c1.431,0.046,2.827,1.098,4.239,1.703c-1.187,1.451-2.316,4.068-3.571,4.13c-3.438,0.167-6.531,0.594-9.268,2.821
    		c-0.441,0.359-1.389,0.524-1.898,0.305c-5.681-2.445-11.87-4.174-15.427-9.87c-0.537-0.859-0.872-2.449-0.438-3.206
    		c1.926-3.356,4.249-6.483,6.258-9.797c0.973-1.605,2.101-3.406,2.212-5.179c0.169-2.685,0.775-4.403,3.638-5.066
    		c1.215-0.282,2.199-1.443,3.376-2.026c1.772-0.879,3.727-1.245,4.582,1.075c0.763,2.068,0.531,3.949-2.396,4.334
    		c-0.898,0.118-1.691,1.036-2.531,1.588c0.751,0.815,1.374,1.85,2.288,2.392c1.118,0.665,2.585,0.742,3.708,1.4
    		c0.999,0.587,1.734,1.621,2.587,2.458c-0.998,0.448-1.966,0.998-3.006,1.308C614.851,1286.117,614.134,1285.908,613.469,1285.873z"
    		/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M558.95,1289.874c0.001-3-0.063-6.001,0.018-8.999
    		c0.106-3.949,2.541-5.502,5.811-3.226c2.834,1.973,5.688,2.437,8.916,2.39c4.599-0.066,7.704-1.828,8.868-6.276
    		c0.882-3.371,3.108-2.879,5.374-2.937c2.468-0.062,3.602,1.178,3.72,3.645c0.062,1.289,0.158,2.86,0.895,3.779
    		c2.014,2.512,1.068,4.493-0.987,5.921c-1.286,0.894-3.637,1.731-4.706,1.141c-1.958-1.082-1.075-3.062,0.118-4.71
    		c0.481-0.665,0.476-1.683,0.691-2.539c-0.887,0.402-1.922,0.631-2.632,1.24c-1.881,1.613-3.529,3.503-5.438,5.079
    		c-3.203,2.645-6.995,1.784-10.434,0.94c-4.316-1.059-4.428-1.365-4.275,3.115c0.118,3.457,0.777,6.896,1.215,10.342
    		c0.041,0.323,0.238,0.623,0.316,0.945c0.843,3.465-0.606,7.219-2.334,6.434c-2.082-0.946-4.427-3.229-4.916-5.338
    		C558.36,1297.342,558.949,1293.54,558.95,1289.874z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M562.744,1131.903c-0.994-0.055-1.999-0.033-2.979-0.178
    		c-2.795-0.412-4.074,0.736-3.939,3.566c0.071,1.491-0.033,2.991,0.021,4.485c0.072,1.981-0.726,3.195-2.787,3.347
    		c-2.129,0.156-2.676-1.033-3.065-2.981c-0.79-3.944-2.076-7.786-3.003-11.707c-0.258-1.091-0.142-2.346,0.097-3.462
    		c0.611-2.864,2.791-3.546,5.136-1.708c4.222,3.308,4.514,3.543,7.754-0.482c3.101-3.853,6.925-4.261,11.138-3.521
    		c1.003,0.177,2.547,1.865,2.465,2.727c-0.203,2.126-1.894,1.74-3.412,1.069c-0.751-0.331-1.679-0.884-2.326-0.685
    		c-0.968,0.298-1.76,1.17-2.626,1.798c0.676,0.593,1.29,1.582,2.041,1.7c1.719,0.271,3.748,0.332,3.344,2.577
    		c-0.222,1.231-1.681,2.7-2.919,3.169c-1.453,0.55-3.273,0.129-4.935,0.129C562.746,1131.799,562.745,1131.852,562.744,1131.903z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M586.55,1360.282c-2.418-0.391-4.986-0.418-7.19-1.327
    		c-1.083-0.446-1.492-2.526-2.206-3.867c1.138-0.384,2.257-1.01,3.416-1.095c1.976-0.146,3.976,0.064,5.966,0.1
    		c1.956,0.035,4.355,0.578,4.792-2.198c0.371-2.358,1.158-5.066-1.472-6.82c-1.354-0.903-2.797-1.716-4.288-2.37
    		c-3.083-1.354-3.238-2.062-0.742-4.394c2.938-2.742,4.6-2.753,7.687,0.098c1.826,1.687,3.535,3.499,5.326,5.224
    		c3.616,3.482,3.458,7.988-0.017,11.753C594.721,1358.744,591.463,1361.17,586.55,1360.282z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M585.601,1182.488c-0.067,0.661-0.325,1.375-0.16,1.973
    		c0.282,1.019,0.83,1.964,1.266,2.939c0.822-0.437,1.853-0.689,2.431-1.343c2.081-2.356,3.854-5.002,6.062-7.222
    		c1.086-1.092,2.87-1.488,4.338-2.199c0.183,1.693,0.37,3.387,0.538,5.082c0.015,0.149-0.062,0.347-0.162,0.465
    		c-3.685,4.312-7.336,8.655-11.088,12.91c-1.538,1.744-3.284,1.301-4.781-0.146c-0.7-0.678-1.369-1.523-1.703-2.422
    		c-1.818-4.895-3.7-9.779-5.156-14.785c-0.371-1.273,0.299-3.602,1.29-4.283c0.806-0.555,2.982,0.336,4.242,1.098
    		C585.695,1176.354,585.945,1179.359,585.601,1182.488z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M616.682,1363.009c-3.848,0.601-5.914-2.507-7.954-5.238
    		c-0.625-0.837-0.428-3.441,0.223-3.824c1.134-0.667,3.076-0.657,4.377-0.152c1.409,0.546,2.652,3.009,3.652,2.834
    		c2.799-0.489,5.484-1.807,8.082-3.071c0.393-0.191,0.48-2.176,0.007-2.769c-1.544-1.932-3.373-3.646-5.171-5.36
    		c-1.34-1.277-2.349-2.588-0.58-4.143c1.427-1.253,2.44-2.127,5.033-0.814c4.884,2.473,5.285,7.567,8.146,11.12
    		c0.303,0.377,0.202,1.296-0.017,1.84C631.387,1356.146,620.08,1363.12,616.682,1363.009z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M573.858,1226.844c-7.368-0.741-9.364,1.171-8.982,7.393
    		c0.283,4.608,0.571,9.219,0.693,13.832c0.021,0.811-0.588,1.861-1.24,2.374c-0.347,0.272-1.564-0.184-2.129-0.636
    		c-3.277-2.625-4.059-6.519-2.668-10.785c0.436-1.338,0.375-3.066-0.064-4.418c-1.292-3.967,2.081-12.878,5.773-14.647
    		c2.113-1.012,10.727,0.772,12.507,2.746c0.692,0.768,1.57,2.135,1.302,2.8c-0.304,0.753-1.758,1.258-2.789,1.443
    		C575.148,1227.145,573.947,1226.85,573.858,1226.844z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M587.629,1143.637c-5.009-0.611-6.69-3.108-4.102-6.843
    		c1.751-2.526,2.135-4.101-0.156-6.399c-0.723-0.726-0.521-2.843-0.07-4.079c0.247-0.679,2.253-1.459,2.827-1.118
    		c1.104,0.654,1.86,2.012,2.563,3.194c0.782,1.316,1.344,2.764,2.001,4.153c1.348-0.899,2.79-1.688,4.018-2.729
    		c1.39-1.179,2.399-2.923,3.934-3.771c1.005-0.556,2.841-0.31,3.917,0.282c0.473,0.261,0.064,2.287-0.118,3.485
    		c-0.06,0.397-0.632,0.749-1.019,1.06c-3.404,2.732-6.81,5.333-8.816,9.519C591.875,1141.917,589.341,1142.579,587.629,1143.637z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M630.494,1325.899c-1.465,2.378-2.373,4.586-3.93,6.15
    		c-1.301,1.308-3.601,1.584-5.029,2.814c-2.446,2.109-3.373-0.323-4.981-1.104c-0.841-0.408-2.655-0.385-3.074,0.167
    		c-2.792,3.683-4.444,1.072-6.01-1.015c-0.945-1.261-1.435-2.862-2.13-4.311c1.505-0.474,2.988-1.043,4.522-1.387
    		c1.107-0.249,2.632,0.233,3.365-0.359c2.47-1.995,4.427-3.049,5.692,1.048c0.037,0.12,1.609,0.113,1.846-0.244
    		c0.623-0.943,0.636-2.384,1.386-3.125c1.059-1.046,2.838-2.53,3.815-2.229C627.643,1322.822,628.938,1324.577,630.494,1325.899z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M627.607,1180.076c-0.52,1.084-0.765,2.37-1.539,3.078
    		c-3.715,3.397-7.454,6.789-11.42,9.881c-2.718,2.119-5.267,0.827-5.302-2.703c-0.033-3.248-0.504-6.071-2.501-8.812
    		c-1.342-1.842-1.076-4.369,1.336-5.48c2.45-1.13,3.532,0.477,4.111,2.76c0.271,1.068,1.065,2.025,1.706,2.977
    		c2.463,3.655,2.438,3.612,5.414,0.291c1.32-1.474,2.744-2.89,4.299-4.103c0.66-0.515,2.021-0.865,2.625-0.521
    		C627.005,1177.823,627.173,1179.085,627.607,1180.076z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M595.837,1333.662c-3.773-0.219-7.628-0.06-11.279-0.846
    		c-1.42-0.307-3.293-2.723-3.331-4.218c-0.054-2.124,1.989-2.459,4.147-1.885c0.99,0.263,2.588-0.286,3.381-1.031
    		c2.509-2.356,4.204-2.446,5.484,1.097c0.271,0.75,1.261,1.239,1.92,1.849c0.526-0.784,1.084-1.55,1.573-2.357
    		c0.628-1.036,1.077-2.851,1.83-2.977c0.991-0.166,2.79,0.739,3.163,1.634c0.417,1.002-0.198,2.574-0.67,3.777
    		C600.943,1331.542,599.585,1334.231,595.837,1333.662z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M414.699,1137.433c3.818,0.228,7.913-1.292,11.228,2.32
    		c1.197,1.306,5.382-2.243,5.204-5.217c-0.364-6.083,2.363-12.594-2.175-18.252c-0.274-0.341,0.892-2.696,1.47-2.73
    		c6.065-0.36,12.458-2.388,17.553,3.155c1.493,1.624,3.24,1.42,4.454-0.758c0.562-1.009,1.51-1.808,2.038-2.828
    		c1.177-2.277,2.594-2.908,4.694-1.107c1.9,1.628,2.187,3.218,0.112,4.947c-0.892,0.743-1.672,1.621-2.495,2.446
    		c-4.265,4.277-6.385,4.415-11.887,1.708c-1.721-0.846-4.326-1.306-5.95-0.605c-2.478,1.07-1.837,3.783-1.564,6.194
    		c0.368,3.251,0.551,6.596,0.195,9.833c-0.338,3.073,0.832,3.756,3.543,3.834c3.885,0.113,7.994-1.307,11.618,1.461
    		c0.232,0.178,2.154-0.973,2.254-1.657c0.456-3.105,2.533-2.999,4.812-2.953c2.151,0.042,4.37,0.175,6.434-0.289
    		c1.054-0.236,2.274-1.575,2.564-2.647c0.163-0.604-1.43-1.663-2.199-2.545c-0.895-1.025-2.423-2.067-2.443-3.126
    		c-0.021-1.11,1.171-2.671,2.258-3.309c8.539-5.001,9.379-4.431,9.418,5.593c0.014,3.798,0.032,7.395-4.357,9.114
    		c-0.643,0.252-1.347,1.764-1.135,2.396c0.222,0.662,1.735,1.548,2.298,1.331c3.024-1.165,6.76,0.983,9.428-2.113
    		c0.34-0.395,2.626,0.104,3.11,0.808c0.67,0.972,0.891,2.658,0.537,3.802c-0.28,0.904-1.667,1.978-2.645,2.066
    		c-4.621,0.419-9.231,2.284-13.927-0.109c-1.839-0.937-4.095-1.42-6.173-1.462c-11.633-0.232-23.27-0.26-34.904-0.383
    		c-0.823-0.009-1.855,0.047-2.432-0.395c-5.803-4.455-12.511-2.002-18.811-2.63c-2.081-0.208-4.104-0.941-6.182-1.259
    		c-3.912-0.599-7.835-1.145-11.77-1.566c-2.514-0.269-6.315,0.841-6.288-2.874c0.028-3.805,3.772-3.571,6.612-3.186
    		c3.729,0.507,5.618-0.039,7.033-4.457c1.255-3.921,2.53-6.801-0.231-10.404c-0.752-0.981-0.575-2.973-0.274-4.384
    		c0.349-1.639,0.941-3.616,2.133-4.531c0.666-0.511,2.948,0.556,4.171,1.395c3.573,2.454,5.371,2.307,8.903-0.988
    		c0.646-0.604,1.646-0.898,2.533-1.159c1.973-0.58,4.005-0.962,5.962-1.585c2.365-0.753,3.584-0.196,3.31,2.472
    		c-0.018,0.166-0.063,0.328-0.077,0.494c-0.693,8.497-0.693,8.442-9.552,8.426c-3.653-0.007-7.893-1.385-9.897,3.453
    		c-0.435,1.05-1.399,2.228-1.191,3.132c0.787,3.416,1.777,6.818,3.12,10.048c0.303,0.729,2.316,0.933,3.571,1.038
    		C410.693,1137.555,412.701,1137.433,414.699,1137.433z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M458.123,1206.701c-9.858-1.25-19.713-2.548-29.581-3.718
    		c-2.468-0.293-4.986-0.268-7.479-0.218c-3.516,0.07-6.991,0.262-9.942-2.255c-0.569-0.485-1.644-0.829-2.333-0.651
    		c-6.086,1.568-11.304-1.931-16.654-3.434c-3.681-1.034-7.149-2.652-11.136-2.925c-1.035-0.071-1.928-2.211-2.886-3.4
    		c1.194-0.904,2.446-2.612,3.572-2.534c1.999,0.139,4.007,1.201,5.858,2.168c3.317,1.732,4.809,1.188,4.867-2.475
    		c0.037-2.398-0.265-5.04-1.268-7.172c-3.286-6.98-1.576-11.516,5.765-13.977c2.462-0.824,4.92-1.862,7.46-2.2
    		c2.713-0.361,3.234,1.885,3.165,4.012c-0.067,2.079-0.317,3.757-3.437,3.536c-4.308-0.306-5.707,1.687-5.561,6.006
    		c0.084,2.461,0.106,5.047-0.541,7.384c-1.346,4.854,0.236,8.569,5.051,8.857c10.165,0.61,19.868,4.821,30.362,3.302
    		c3.271-0.475,6.855,1.677,10.357,2.262c11.347,1.896,11.357,1.831,11.357-9.674c0.001-4.83,0.173-9.67-0.067-14.488
    		c-0.14-2.794,0.941-4.654,2.813-6.518c1.74-1.732,3.468-2.284,5.098-0.396c2.232,2.588,3.822,1.399,5.845-0.239
    		c1.733-1.405,3.834-2.356,5.771-3.509c0.396,2.348,1.065,4.687,1.137,7.044c0.17,5.659,0.072,11.326,0.052,16.989
    		c-0.005,1.156,0.298,2.723-0.314,3.382c-1.271,1.367-3.059,2.252-4.634,3.335c-0.476-1.256-1.35-2.511-1.354-3.769
    		c-0.013-4.431,0.239-8.865,0.456-13.295c0.158-3.221-0.577-3.84-3.673-2.912c-5.399,1.619-5.399,1.619-5.4,7.378
    		c-0.001,3.998-0.145,8.003,0.062,11.989c0.088,1.685,0.6,4.516,1.47,4.746c1.801,0.477,4.137-0.064,5.948-0.871
    		c2.553-1.137,6.688,0.078,7.455,2.67c0.764,2.583-0.637,4.543-3.543,4.608c-4.661,0.105-9.326,0.028-13.99,0.028
    		C458.211,1206.08,458.167,1206.391,458.123,1206.701z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M395.803,1342.052c-3.499,0-6.998,0.024-10.496-0.014
    		c-1.153-0.012-2.555,0.194-3.4-0.366c-1.43-0.947-3.458-2.414-3.473-3.682c-0.016-1.355,1.771-3.172,3.22-3.962
    		c1.147-0.625,3.602-0.616,4.383,0.183c2.367,2.416,5.015,1.842,7.844,1.98c5.562,0.273,11.234-0.565,16.665,1.757
    		c2.219,0.949,4.875,1.208,7.322,1.169c1.613-0.025,3.303-0.975,4.792-1.807c2.369-1.323,4.609-2.881,6.888-4.364
    		c2.144-1.396,2.421-3.359,0.637-4.959c-2.479-2.221-1.262-3.277,0.549-5.083c3.356-3.346,6.88-5.477,11.852-4.69
    		c2.133,0.337,3.558,0.999,3.527,3.281c-0.029,2.285-1.711,2.491-3.512,2.526c-1.847,0.036-4.795-1.089-4.894,1.684
    		c-0.1,2.786,2.643,2.062,4.628,1.846c2.492-0.271,4.567,0.784,4.382,3.394c-0.062,0.867-2.83,2.447-3.996,2.169
    		c-4.406-1.051-6.594,3.477-10.408,3.699c-0.388,0.023-1.218,2.133-0.914,2.494c0.856,1.02,2.168,2.25,3.352,2.305
    		c6.646,0.31,13.309,0.297,19.966,0.391c0.164,0.003,0.329-0.05,0.495-0.061c2.102-0.142,5.036-0.977,5.314,1.936
    		c0.287,2.992-2.562,4.078-5.183,4.141c-5.827,0.141-11.667,0.171-17.487-0.087c-1.687-0.075-3.351-1.224-4.978-1.981
    		c-5.281-2.463-10.517,0.43-10.569,6.241c-0.037,4.125-1.762,9.103,2.804,11.92c3.349,2.065,7.224,3.305,10.923,4.762
    		c1.644,0.647,3.425,0.96,5.158,1.358c2.18,0.501,4.387,0.883,6.559,1.414c1.332,0.326,2.619,0.834,3.926,1.261
    		c-0.728,1.188-1.201,2.698-2.228,3.505c-3.285,2.582-6.843,1.794-10.312,0.244c-5.734-2.562-11.469-5.124-17.162-7.772
    		c-0.967-0.449-1.831-1.24-2.559-2.041c-0.535-0.589-0.669-1.526-1.155-2.179c-3.113-4.169-6.376-7.725-2.111-13.673
    		c2.718-3.792-1.566-8.248-7.867-8.655c-4.145-0.268-8.32-0.051-12.481-0.051C395.802,1342.206,395.803,1342.129,395.803,1342.052z"
    		/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M451.013,1304.066c-3.754-0.461-7.56-1.527-11.249-1.217
    		c-3.894,0.328-7.245,0.192-10.519-2.156c-0.838-0.602-2.572,0.166-3.878,0.071c-3.338-0.242-7.105,1.208-9.673-2.347
    		c-0.246-0.342-0.896-0.603-1.331-0.566c-5.409,0.447-10.285-1.669-15.393-2.707c-3.767-0.767-7.14-2.229-10.703-3.291
    		c-2.174-0.648-4.504-2.352-2.842-4.588c0.901-1.212,5.069-1.776,6.009-0.851c2.962,2.917,6.177,2.65,9.69,2.548
    		c1.358-0.039,2.895,0.521,4.084,1.246c4.198,2.555,5.91,2.209,7.095-2.619c0.895-3.645,0.721-7.546,1.128-11.321
    		c0.173-1.608,0.25-3.395,1.008-4.742c2.127-3.784,3.497-3.811,6.471-0.565c2.756,3.008,3.098,2.952,5.791,0.08
    		c1.021-1.09,2.408-1.838,3.628-2.742c0.345,1.201,1.039,2.428,0.952,3.597c-0.132,1.785,0.72,4.059-2.13,4.937
    		c-2.852,0.878-5.588,2.154-8.324,3.37c-0.515,0.229-0.926,0.938-1.158,1.516c-0.913,2.271-1.754,4.573-2.586,6.876
    		c-1.024,2.836-0.091,5.234,2.927,5.694c4.565,0.694,9.23,0.874,13.859,0.95c2.416,0.041,3.34-1.737,3.312-4.092
    		c-0.053-4.492-0.09-8.988,0.019-13.479c0.098-4.057,1.395-4.668,4.85-2.479c5.047,3.2,5.118,3.297,9.913-0.239
    		c2.432-1.794,3.183,0.425,4.458,1.446c1.442,1.155,1.547,2.281,0.087,3.699c-1.865,1.811-3.709,2.903-6.533,2.826
    		c-6.604-0.18-6.917,0.321-6.941,7.143c-0.003,0.832-0.004,1.665,0.015,2.497c0.107,4.902,0.531,5.255,5.566,5.149
    		c3.658-0.076,7.322-0.12,10.979-0.019c1.973,0.055,3.987,0.87,3.375,3.2c-0.298,1.135-2.142,2.433-3.435,2.618
    		c-2.771,0.398-5.641,0.122-8.471,0.122C451.046,1303.777,451.029,1303.922,451.013,1304.066z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M395.637,1371.949c-3.308-0.531-7.807-1.374-12.339-1.947
    		c-4.257-0.539-8.546-0.832-12.827-1.152c-1.79-0.133-4.067,0.386-4.235-2.21c-0.132-2.034-0.203-4.669,0.918-6.035
    		c1.398-1.703,3.323-0.275,5.134,0.993c2.059,1.442,4.73,2.379,7.247,2.725c5.9,0.812,11.863,1.193,17.809,1.641
    		c2.845,0.214,4.387-1.093,4.184-4.144c-0.064-0.976-0.152-2.212,0.354-2.898c0.678-0.918,1.881-1.849,2.939-1.954
    		c0.822-0.082,2.494,1.045,2.539,1.717c0.246,3.71,1.054,7.443-1.77,10.883C403.048,1372.663,399.91,1371.582,395.637,1371.949z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M397.766,1327.926c0.75-0.125,2.25-0.215,3.644-0.649
    		c1.716-0.536,2.296-1.679,0.331-2.601c-2.025-0.949-4.131-2.309-2.099-4.225c1.455-1.372,4.492-1.846,6.62-1.495
    		c1.924,0.317,3.14,4.308,5.662,2.412c1.255-0.942,0.86-3.899,1.57-5.812c0.458-1.234,1.447-3.018,2.405-3.159
    		c1.807-0.268,3.976,0.089,5.557,0.971c0.695,0.388,0.811,2.899,0.323,4.093c-0.986,2.412-2.018,5.536-4,6.668
    		c-5.496,3.135-10.305,7.751-16.896,8.768c-1.954,0.302-4.016,0.936-5.864,0.574c-1.44-0.281-2.626-1.869-3.925-2.877
    		c1.491-1.086,2.951-2.221,4.502-3.215C395.879,1327.198,396.531,1327.594,397.766,1327.926z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M398.453,1214.243c2.189,0.195,3.227,1.551,3.301,3.942
    		c0.04,1.294,0.138,2.749,0.746,3.828c1.107,1.965,3.397,1.627,5.327-0.392c1.467-1.535,3.021-3.034,4.753-4.239
    		c0.561-0.391,2.354-0.084,2.613,0.41c0.434,0.831,0.524,2.655,0.004,3.042c-4.471,3.317-6.207,8.18-8.094,13.069
    		c-0.964,2.499-5.509,2.525-6.558-0.064c-2.094-5.171-3.768-10.521-5.429-15.854
    		C394.336,1215.478,395.624,1214.199,398.453,1214.243z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M391.817,1322.167c-4.661,0.439-4.969-3.465-6.677-5.657
    		c-1.753-2.25-0.063-3.684,1.268-5.119c2.137-2.305,4.264-4.636,6.582-6.75c2.539-2.315,4.542-1.695,6.003,1.605
    		c3.264,7.372,3.286,7.397-2.986,12.962C394.735,1320.336,393.22,1321.188,391.817,1322.167z M394.312,1315.789
    		c0.708-1.184,1.604-2.019,1.486-2.669c-0.123-0.679-1.224-1.18-1.897-1.759c-0.463,0.789-1.188,1.547-1.289,2.381
    		C392.552,1314.23,393.483,1314.841,394.312,1315.789z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M419.685,1227.758c0-3.307-0.3-6.652,0.118-9.906
    		c0.23-1.793,1.558-3.503,2.625-5.093c0.309-0.458,1.865-0.749,2.108-0.47c1.369,1.569,3.823,3.684,3.482,4.971
    		c-0.967,3.655,1.406,7.916-2.109,11.135c-0.722,0.661-0.438,2.482-0.513,3.774c-0.104,1.808-0.131,3.621-0.151,5.433
    		c-0.024,2.094-0.698,3.636-3.125,3.5c-2.473-0.139-2.769-1.957-2.775-3.902c-0.012-3.147-0.003-6.295-0.003-9.443
    		C419.456,1227.757,419.57,1227.758,419.685,1227.758z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M395.708,1273.952c0-2.15-0.222-4.333,0.09-6.438
    		c0.167-1.127,1.054-2.661,1.987-3.005c0.795-0.293,2.544,0.563,3.132,1.419c1.702,2.479,2.979,2.047,4.523-0.043
    		c0.68-0.919,1.494-2.093,2.46-2.348c1.006-0.267,2.506,0.136,3.343,0.806c0.661,0.529,1.204,2.427,0.837,2.821
    		c-2.196,2.362-4.341,5.36-7.148,6.397c-2.701,0.997-3.328,2.112-3.233,4.48c0.046,1.157,0.319,2.407-0.017,3.452
    		c-0.438,1.363-1.378,2.565-2.102,3.838c-1.23-1.15-3.165-2.11-3.536-3.491c-0.671-2.496-0.407-5.243-0.533-7.885
    		C395.576,1273.955,395.643,1273.954,395.708,1273.952z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M442.897,1173.974c0,3.971-0.005,7.941,0.005,11.912
    		c0.001,0.663,0.359,1.549,0.054,1.951c-1.401,1.841-2.693,4.062-4.596,5.12c-1.271,0.707-3.115-3.473-2.047-6.169
    		c1.108-2.798,0.602-4.88-0.897-7.418c-1.074-1.818-1.575-4.651-0.965-6.604c0.647-2.071,2.628-4.11,4.563-5.265
    		C440.357,1166.7,443.13,1171.185,442.897,1173.974z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M454.603,1235.792c-1.736-0.65-4.503-0.868-4.995-2.041
    		c-1.048-2.498-1.236-5.514,1.322-7.786c1.61-1.431,2.848-3.418,4.655-4.451c1.54-0.88,3.881-1.408,5.443-0.866
    		c1.263,0.437,2.49,2.618,2.702,4.166C464.646,1231.495,461.313,1235.206,454.603,1235.792z M458.688,1227.875
    		c-1.031-0.658-1.682-1.399-2.194-1.313c-0.575,0.097-1.026,0.939-1.531,1.458c0.531,0.461,1.048,1.263,1.599,1.288
    		C457.113,1229.333,457.701,1228.583,458.688,1227.875z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M416.554,1187.977c-0.42-0.287-2.486-0.95-2.976-2.221
    		c-0.605-1.573-0.622-4.333,0.372-5.376c2.553-2.681,5.65-4.95,8.835-6.882c1.875-1.137,5.921,3.684,5.416,6.253
    		C427.673,1182.438,420.774,1187.971,416.554,1187.977z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M387.794,1356.998c-1.487,0-3.053,0.288-4.438-0.088
    		c-1.185-0.321-2.162-1.404-3.231-2.15c0.942-1.212,1.76-3.318,2.848-3.469c3.405-0.47,6.935-0.419,10.365-0.071
    		c0.879,0.089,2.049,1.961,2.182,3.117c0.086,0.755-1.335,2.216-2.296,2.441c-1.724,0.403-3.61,0.113-5.429,0.113
    		C387.794,1356.928,387.794,1356.963,387.794,1356.998z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M470.137,681.203c-0.194-3.149-0.568-6.302-0.506-9.445
    		c0.029-1.447,0.912-2.878,1.408-4.316c1.577,1.248,3.446,2.275,4.61,3.832c0.617,0.824,0.153,2.592-0.072,3.891
    		c-1.606,9.232,2.639,16.919,6.463,24.717c0.205,0.418,0.607,0.979,0.985,1.036c7.521,1.152,14.719,6.569,22.786,1.151
    		c1.907-1.28,5.2-1.08,7.734-0.717c4.695,0.675,7.306-1.719,9.96-4.998c1.438-1.777,3.642-3.399,5.804-4.004
    		c2.373-0.664,5.562-0.969,5.766,3.276c0.024,0.512,1.615,1.534,2.064,1.343c2.607-1.109,5.079-2.535,7.621-3.805
    		c1.258-0.629,2.567-1.153,3.854-1.725c0.428,1.346,0.942,2.67,1.267,4.04c0.809,3.418-0.9,5.846-3.567,7.503
    		c-1.076,0.668-3.424,1.07-4.002,0.463c-3.404-3.58-7.828,0.037-11.404-1.84c-0.168-0.089-1.284,1.441-1.829,2.3
    		c-1.113,1.757-1.828,3.923-3.314,5.23c-0.935,0.822-3.724,1.11-4.392,0.41c-4.462-4.685-9.542-2.298-14.432-1.656
    		c-4.226,0.554-8.391,1.594-12.621,2.077c-2.731,0.312-5.722,0.797-7.826-1.946c-0.249-0.325-0.901-0.444-1.378-0.474
    		c-7.809-0.484-11.605-5.079-12.988-12.372c-0.875-4.612-2.031-9.172-3.062-13.755C469.423,681.348,469.78,681.275,470.137,681.203z
    		"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M439.937,641.735c-5.498,1.47-7.795-3.104-10.42-6.096
    		c-2.448-2.791-3.654-2.761-5.528,0.05c-2.64,3.957-5.347,7.87-8.006,11.813c-1.625,2.41-3.802,3.565-6.767,3.364
    		c-1.298-0.088-2.657,0.087-3.931,0.389c-2.629,0.624-5.718,2.677-7.726,1.884c-4.899-1.936-10.552-3.926-12.224-9.8
    		c-1.702-5.977-3.534-12.301,1.593-17.7c0.913-0.96,3.216-1.97,3.851-1.517c1.068,0.762,1.739,2.668,1.771,4.11
    		c0.024,1.129-1.218,2.262-1.818,3.434c-2.702,5.276,0.267,11.05,5.549,13.617c3.705,1.801,6.486,2.357,9.757-0.142
    		c0.441-0.337,1.291-0.161,1.953-0.186c5.276-0.19,7.498-3.537,6.238-8.84c-0.643-2.708-0.938-5.584-0.8-8.354
    		c0.065-1.297,1.254-3.054,2.422-3.66c1.806-0.937,3.054,0.397,3.6,2.265c0.988,3.386,2.915,3.623,5.601,1.721
    		c1.743-1.234,3.551-2.409,5.445-3.388c1.275-0.658,3.521-1.69,3.988-1.22c1.224,1.229,1.934,3.131,2.417,4.884
    		c0.378,1.369,0.108,2.917,0.126,4.385c1.967-0.446,4.009-0.697,5.869-1.418c0.989-0.383,1.651-1.59,2.482-2.403
    		c2.628-2.568,5.43-1.975,6.09,1.575c0.335,1.806,0.232,4.493-0.865,5.609C447.731,639.035,445.219,643.277,439.937,641.735z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M481.998,650.666c-4.718,0.544-8.781,0.021-12.652-2.536
    		c-3.297-2.179-5.947-4.517-5.101-8.719c0.827-4.107,2.095-8.142,3.454-12.11c0.196-0.572,2.282-1.131,2.97-0.75
    		c0.961,0.533,1.741,1.883,2.002,3.021c0.18,0.781-0.584,1.833-1.048,2.699c-3.831,7.14-0.972,12.191,7.357,12.839
    		c1.984,0.153,3.999,0.118,5.987-0.02c6.732-0.467,10.869-6.907,8.365-13.15c-0.351-0.873-1.54-1.763-2.49-1.975
    		c-2.481-0.553-3.848-2.158-2.692-4.189c0.735-1.294,3.098-2.034,4.838-2.286c1.884-0.271,3.92,0.632,5.838,0.457
    		c3.661-0.334,7.188-1.19,9.688-4.36c1.443-1.831,3.444-2.21,5.238-0.619c1.879,1.668,0.769,3.097-0.634,4.622
    		c-0.656,0.713-0.532,2.144-0.762,3.248c1.181-0.25,2.444-0.322,3.512-0.817c0.666-0.309,0.938-1.663,1.565-1.815
    		c0.782-0.189,2.514,0.367,2.516,0.613c0.024,2.447,0.697,5.81-0.612,7.135c-2.01,2.033-5.38,2.857-8.312,3.729
    		c-0.558,0.165-2.053-1.703-2.569-2.874c-0.426-0.964,0.187-2.344-0.093-3.428c-0.206-0.798-1.028-1.916-1.716-2.027
    		c-2.816-0.455-7.316,5.075-6.628,7.93c1.423,5.893-1.508,10.307-6.838,12.76C489.388,649.786,485.979,651.776,481.998,650.666z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M409.364,689.652c1.438-4.563,2.629-9.229,4.459-13.629
    		c0.567-1.362,2.766-2.346,4.416-2.842c0.938-0.282,2.789,0.469,3.287,1.312c0.45,0.763,0.004,2.963-0.703,3.359
    		c-6.353,3.562-4.008,9.578-4.225,14.739c-0.115,2.75,3.569,5.401,6.468,5.76c2.394,0.297,4.727,1.062,7.102,1.544
    		c0.939,0.19,2.284,0.619,2.822,0.179c2.743-2.245,5.896-1.74,9.019-1.847c4.54-0.155,6.516-4.085,9.314-6.74
    		c0.279-0.265-0.065-1.885-0.472-2.061c-3.003-1.294-2.154-3.528-1.911-5.889c0.179-1.732-0.274-3.605-0.784-5.314
    		c-0.71-2.387,0.017-4.449,2.252-4.542c1.438-0.061,3.271,1.703,4.331,3.102c0.651,0.858,0.177,2.568,0.211,3.896
    		c0.029,1.161-0.415,2.925,0.17,3.388c5.014,3.969,0.578,8.14,0.285,12.103c-0.25,3.385-6.764,8.11-10.339,8.025
    		c-4.995-0.118-9.998-0.537-14.429,2.518c-0.254,0.175-0.682,0.296-0.956,0.21c-4.411-1.38-8.811-2.796-13.208-4.218
    		c-0.139-0.044-0.209-0.3-0.347-0.345c-6.374-2.045-6.419-7.091-5.733-12.486C410.05,689.8,409.707,689.726,409.364,689.652z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M573.78,635.023c0.174,1.296,0.311,1.953,0.344,2.615
    		c0.406,7.976-5.461,12.059-12.804,8.727c-2.542-1.153-4.232,0.049-5.855,1.385c-2.52,2.074-5.057,3.73-8.522,3.084
    		c-0.461-0.086-1.047,0.006-1.46,0.229c-7.144,3.857-14.909,2.45-22.484,2.743c-4.971,0.193-8.842-2.285-12.191-5.342
    		c-1.466-1.338-1.907-3.979-2.388-6.125c-0.188-0.834,0.602-2.522,1.302-2.756c0.829-0.276,2.055,0.566,3.08,0.994
    		c0.273,0.113,0.553,0.447,0.642,0.738c2.122,6.908,7.7,6.772,13.337,6.627c3.49-0.09,6.985-0.059,10.477-0.03
    		c2.671,0.021,5.323,0.223,7.344-2.152c0.453-0.533,1.484-0.688,2.28-0.8c5.483-0.761,5.335-0.771,6.255-5.761
    		c0.241-1.306,1.815-3.396,2.646-3.335c1.488,0.108,2.98,1.45,4.28,2.512c1.306,1.066,2.188,2.86,3.624,3.482
    		c0.893,0.387,2.472-0.815,3.743-1.304c-0.465-1.135-0.921-2.272-1.405-3.398c-0.055-0.126-0.259-0.211-0.411-0.269
    		c-2.794-1.058-4.856-2.609-3.111-5.922c1.588-3.014,7.379-5.111,9.919-3.515c0.91,0.572,1.552,2.096,1.685,3.258
    		C574.289,632.319,573.878,633.997,573.78,635.023z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M580.672,674.138c-6.669,2.658-11.92-1.541-17.5-3.476
    		c-3.059-1.06-4.811-0.594-6.379,1.538c-1.863,2.53-4.35,2.842-7.053,2.569c-0.841-0.084-1.807-0.702-2.324-1.376
    		c-0.22-0.285,0.43-1.468,0.923-2.035c5.941-6.826,12.775-8.011,21.002-3.736c0.724,0.376,1.559,0.794,2.334,0.778
    		c7.599-0.153,15.196-0.385,22.794-0.623c1.284-0.041,2.628-0.502,3.822-0.231c0.711,0.161,1.608,1.355,1.638,2.115
    		c0.026,0.691-0.894,1.9-1.589,2.064c-5.828,1.37-11.7,2.551-17.56,3.789C580.744,675.056,580.708,674.597,580.672,674.138z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M584.084,707.119c-2.161,0-4.324-0.042-6.484,0.012
    		c-2.111,0.053-3.634-0.459-3.514-3c0.113-2.4,1.537-3.051,3.683-2.967c3.321,0.131,6.646,0.25,9.969,0.212
    		c1.44-0.017,2.979-0.2,4.273-0.766c0.71-0.31,1.005-1.572,1.486-2.404c-0.802-0.188-1.634-0.613-2.402-0.519
    		c-2.244,0.275-2.977-0.822-3.097-2.828c-0.193-3.245,3.477-8.406,6.223-8.65c3.081-0.273,5.557,3.448,6.226,7.617
    		c1.013,6.31-3.057,9.794-7.113,12.588c-2.219,1.528-6.104,0.638-9.238,0.838C584.092,707.208,584.088,707.163,584.084,707.119z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M460.777,637.568c-0.116,7.301-6.595,14.749-13.995,16.222
    		c-3.213,0.639-6.421,1.442-9.509,2.521c-3.055,1.065-5.566,0.523-7.683-1.747c-0.58-0.622-1.109-1.879-0.856-2.53
    		c0.269-0.69,1.492-1.018,2.312-1.478c0.122-0.068,0.332-0.016,0.49,0.022c7.926,1.931,14.229-2.532,20.844-5.5
    		c1.264-0.566,2.339-2.559,2.629-4.062c0.463-2.407,0.199-4.955,0.238-7.443c0.031-2.059,0.634-3.707,3.09-3.547
    		c2.191,0.143,2.551,1.708,2.453,3.562C460.721,634.912,460.777,636.242,460.777,637.568z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M585.354,641.578c0.18-0.356,0.408-1.557,1.081-1.909
    		c0.521-0.273,1.795,0.302,2.395,0.862c2.848,2.666,6.245,1.397,9.435,1.397c0.496,0,1.379-1.078,1.372-1.648
    		c-0.006-0.497-1.011-0.989-1.585-1.47c-1.254-1.049-3.727-2.757-3.579-3.025c1.075-1.951,2.398-4.07,4.224-5.184
    		c1.026-0.626,3.6,0.048,4.718,1.014c6.645,5.739,6.377,6.445,0.226,11.965c-3.847,3.451-8.545,4.096-13.074,5.384
    		C588.344,649.596,585.356,645.457,585.354,641.578z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M565.779,699.332c-1.089-3.372-2.609-6.676-3.154-10.134
    		c-0.635-4.039-0.585-8.22-0.413-12.321c0.029-0.704,2.221-1.925,3.285-1.802c0.982,0.113,2.633,1.805,2.54,2.642
    		c-0.5,4.547,0.626,8.479,2.092,12.831c1.493,4.431,0.339,9.803,0.022,14.742c-0.048,0.732-1.826,1.784-2.871,1.87
    		c-0.691,0.057-1.989-1.253-2.157-2.118c-0.343-1.755-0.103-3.624-0.103-5.445C565.273,699.509,565.526,699.421,565.779,699.332z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M571.798,660.197c-1.941-0.398-4.593-0.593-6.918-1.565
    		c-1.187-0.497-1.789-2.391-2.655-3.653c1.413-0.546,2.814-1.513,4.242-1.553c3.285-0.093,6.606,0.575,9.869,0.334
    		c2.3-0.169,4.503-1.416,6.795-2.007c1.47-0.378,3.105-0.79,4.495-0.462c0.533,0.126,1.162,2.574,0.716,3.415
    		c-0.541,1.018-2.124,1.574-3.352,2.111c-1.879,0.823-3.797,1.626-5.776,2.131C577.03,659.505,574.758,659.717,571.798,660.197z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M536.752,629.963c-1.824,0-3.804,0.444-5.432-0.12
    		c-1.748-0.606-3.165-2.162-4.727-3.304c1.125-1.486,1.975-3.452,3.462-4.309c1.193-0.687,3.476-0.562,4.708,0.164
    		c1.704,1.004,2.885,1.942,4.85,0.574c1.047-0.729,3.458-1.433,3.644-1.137c0.894,1.423,1.931,3.362,1.577,4.785
    		c-0.334,1.347-2.176,2.646-3.649,3.28c-1.261,0.542-2.939,0.112-4.433,0.112C536.752,629.994,536.752,629.979,536.752,629.963z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M422.394,661.864c0.045,3.762-3.884,5.586-7.604,3.164
    		c-1.819-1.184-2.89-2.145-5.063-0.552c-1.83,1.342-2.932-0.383-3.571-2.048c-0.112-0.293-0.096-0.651-0.078-0.976
    		c0.139-2.494,2.484-4.838,4.783-4.794C420.399,656.842,422.345,657.72,422.394,661.864z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M487.646,680.379c0,0.661,0.228,1.44-0.044,1.959
    		c-0.721,1.377-1.418,3.351-2.577,3.742c-1.079,0.364-2.896-0.8-4.098-1.686c-0.835-0.616-1.508-1.845-1.681-2.895
    		c-0.524-3.188-0.332-6.365,2.442-8.577c0.701-0.558,2.69-0.796,2.924-0.433c1.511,2.35,2.698,4.906,3.992,7.395
    		C488.286,680.05,487.967,680.215,487.646,680.379z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M484.292,666.402c-1.754-0.831-4.564-1.309-4.992-2.585
    		c-0.526-1.57,0.815-3.857,1.611-5.723c0.222-0.521,1.846-1.079,2.021-0.887c2.767,3.033,4.902,1.354,7.241-0.617
    		c0.419-0.353,2.056,0.101,2.555,0.679c0.423,0.488,0.209,1.77-0.087,2.564c-0.267,0.719-1.409,1.2-1.504,1.879
    		C490.57,665.788,487.918,666.202,484.292,666.402z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M522.599,673.701c-1.744,4.24-2.94,7.813-4.734,11.055
    		c-0.472,0.854-2.795,0.685-4.268,0.985c-0.292-1.464-0.922-2.957-0.8-4.385c0.207-2.409,0.965-4.766,1.351-7.166
    		c0.382-2.376,2.002-3.076,3.944-2.662C519.608,671.852,520.973,672.886,522.599,673.701z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M579.984,617.531c-0.427,2.543-0.537,4.566-3.917,4.158
    		c-2.446-0.295-5.02,0.614-7.551,0.814c-1.181,0.094-2.899,0.206-3.462-0.46c-0.653-0.774-0.769-2.608-0.305-3.604
    		c1.339-2.878,11.692-4.761,14.207-2.706C579.519,616.192,579.734,617.074,579.984,617.531z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M541.365,714.086c-1.084-0.633-2.578-1.021-2.765-1.747
    		c-0.202-0.783,0.601-2.134,1.355-2.783c1.089-0.938,2.48-1.588,3.832-2.146c2.101-0.867,4.242-1.684,6.434-2.271
    		c1.475-0.395,3.189-0.754,4.551-0.333c0.628,0.194,1.286,2.674,0.819,3.434c-0.6,0.973-2.29,1.332-3.562,1.818
    		c-1.513,0.577-3.105,0.946-4.619,1.521C545.453,712.322,543.539,713.179,541.365,714.086z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M407.539,631.192c0.166,2.871-0.306,4.96-3.951,4.875
    		c-4.054-0.095-4.891-0.628-4.98-4.36c-0.095-3.894,0.864-4.9,4.479-4.92C406.136,626.77,407.892,627.937,407.539,631.192z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M459.523,618.613c-2.682,2.32-4.303,4.806-5.988,4.85
    		c-1.414,0.037-3.745-2.583-4.146-4.369c-0.512-2.279,1.681-4.132,4.052-3.809C455.198,615.525,456.78,617.045,459.523,618.613z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M429.967,686.672c-2.705,0.043-4.771-0.406-4.711-3.413
    		c0.05-2.549,1.711-3.502,3.999-3.365c2.327,0.14,5.013,0.199,4.965,3.531C434.177,686.34,432.048,686.815,429.967,686.672z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M600.419,620.192c-0.01,3.122-2.438,5.554-4.621,4.993
    		c-1.456-0.374-2.608-1.929-3.896-2.955c1.837-1.389,3.54-3.079,5.589-3.99C598.151,617.946,599.724,619.701,600.419,620.192z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M509.487,655.867c2.783,0.876,5.093,0.935,4.831,4.09
    		c-0.185,2.217-1.742,3.497-3.701,2.726c-1.917-0.756-3.467-2.444-5.178-3.723C506.789,657.929,508.139,656.898,509.487,655.867z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M478.752,637.101c0-2.44,0.532-4.299,3.289-4.123
    		c1.839,0.118,4.283,0.4,3.589,2.887c-0.489,1.748-2.189,3.836-3.826,4.409C479.82,640.967,478.588,639.132,478.752,637.101z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M558.957,126.047c0.508,2.726-0.726,4.54-2.544,6.565
    		c-2.491,2.776-4.473,6.076-6.261,9.374c-0.495,0.914,0.471,2.62,0.766,3.962c1.037-0.66,2.241-1.156,3.081-2.01
    		c4.15-4.22,5.075-4.337,9.305-0.384c0.74,0.691,1.281,1.827,1.457,2.837c0.254,1.458,0.259,3.011,0.066,4.483
    		c-0.356,2.728,1.356,5.937-2.523,7.833c-4.43,2.166-8.496,4.72-13.364,0.99c-0.93-0.712-2.794-0.793-3.938-0.374
    		c-0.531,0.194-0.854,2.494-0.427,3.473c1.1,2.522,2.628,4.866,4.082,7.222c0.323,0.523,1.08,0.762,1.585,1.193
    		c7.59,6.472,7.015,11.896-1.907,16.748c-1.182,0.643-3.012,1.019-4.181,0.572c-2.661-1.014-4.253-0.239-6.682,1.202
    		c-5.516,3.273-11.102,6.729-17.995,6.982c-4.516,0.165-7.314-3.11-11.075-4.336c-1.648-0.537-3.307-1.447-4.967-1.463
    		c-3.537-0.033-6.642-0.981-10.001-2.116c-4.027-1.36-5.74-4.16-7.704-7.11c-1.85-2.779-1.133-5.723,1.587-7.785
    		c1.72-1.304,3.505-2.546,5.077-4.012c2.065-1.926,4.285-3.822,1.728-7.136c-0.556-0.721,0.311-2.6,0.632-3.918
    		c0.287-1.179,0.725-2.321,1.096-3.479c-1.233-0.137-2.538-0.594-3.686-0.34c-2.051,0.455-4.083,1.179-5.987,2.077
    		c-3.589,1.694-7.032,1.956-10.246-0.651c-2.582-2.095-5.021-4.364-7.53-6.549c-1.763-1.534-1.749-3.026-0.03-4.62
    		c2.32-2.149,4.496-4.46,6.885-6.526c2.304-1.993,4.695-2.604,6.716,0.518c1.696,2.622,3.351,5.277,5.176,7.808
    		c0.584,0.809,1.657,1.266,2.506,1.884c0.426-0.844,1.198-1.678,1.224-2.533c0.307-10.282-3.499-18.394-12.08-24.514
    		c-5.421-3.866-11.77-4.765-17.687-7.019c-0.903-0.344-2.225,0.272-3.314,0.583c-3.106,0.884-4.572-1.433-6.016-3.32
    		c-0.454-0.595,0.027-2.309,0.606-3.149c0.395-0.574,2.039-1.014,2.539-0.668c2.277,1.577,4.327,1.238,6.472-0.106
    		c0.252-0.158,0.741-0.239,0.964-0.102c6.671,4.129,15.657,2.618,21.45,9.061c3.748,4.168,7.93,7.952,12.013,11.807
    		c0.884,0.834,2.18,1.23,3.286,1.828c-0.135-1.493,0.281-3.436-0.504-4.396c-2.624-3.212-5.848-5.929-8.534-9.096
    		c-6.104-7.198-14.623-9.738-23.09-12.48c-0.985-0.318-2.483-0.943-2.592-1.612c-0.139-0.858,0.664-2.266,1.477-2.775
    		c0.941-0.591,2.401-0.806,3.516-0.582c2.499,0.502,4.948,1.302,7.373,2.115c3.405,1.143,6.798,2.339,10.141,3.651
    		c1.626,0.639,3.283,1.392,4.646,2.453c1.775,1.383,4.613,3.1,4.642,4.714c0.095,5.275,3.789,8.081,6.72,11.236
    		c2.528,2.722,3.124,5.584,2.188,8.664c-0.797,2.62-0.529,4.817,0.481,7.179c1.538,3.593,0.943,6.7-1.981,9.493
    		c-2.456,2.345-3.994,4.837-2.75,8.826c0.943,3.021,0.673,6.679-0.004,9.852c-0.397,1.866-2.515,3.48-4.105,4.938
    		c-1.172,1.074-2.933,1.507-4.102,2.584c-2.87,2.646-2.609,4.197,0.72,6.029c0.855,0.471,1.961,0.755,2.506,1.467
    		c1.462,1.905,2.6,2.256,4.259,0.149c1.754-2.229,3.907-1.925,5.323,0.308c2.638,4.16,7.287,5.725,11.195,6.521
    		c5.306,1.08,11.371,0.465,15.718-3.934c3.695-3.738,7.229-6.503,12.282-2.165c0.326,0.28,2.279-0.522,2.271-0.79
    		c-0.079-3.003,2.407-6.421-0.639-8.947c-3.733-3.096-5.24-7.492-7.333-11.521c-0.574-1.104-0.284-3.346,0.512-4.348
    		c2.369-2.979,2.373-5.611,0.109-8.545c-0.671-0.869-1.288-2.053-1.311-3.105c-0.128-5.996-0.222-12.003,0.053-17.988
    		c0.058-1.257,1.662-2.477,2.632-3.649c0.902-1.091,2.701-2.079,2.727-3.145c0.095-4.047,2.994-6.513,4.869-9.482
    		c1.16-1.839,1.865-3.015-0.102-4.598c-0.549-0.441-1.151-1.814-0.982-1.977c0.654-0.623,1.757-1.458,2.432-1.271
    		c7.258,2.002,13.011-1.201,18.702-4.994c1.996-1.331,4.234-2.323,6.427-3.329c1.776-0.814,3.619-0.469,3.877,1.593
    		c0.152,1.218-0.707,3.119-1.727,3.832c-4.978,3.478-12.131,2.816-16.088,8.36c-0.093,0.129-0.253,0.218-0.396,0.302
    		c-1.543,0.906-5.049-0.378-4.496,2.518c0.445,2.333,2.686,4.454,4.492,6.317c0.543,0.561,2.475,0.295,3.474-0.195
    		c4.671-2.295,9.237-4.802,13.843-7.229c1.549-0.816,3.229-1.648,4.026,0.771c0.749,2.271-0.028,3.58-2.574,4.363
    		c-2.905,0.895-5.614,2.479-8.34,3.896c-3.986,2.073-7.543,5.098-12.301,5.413c-0.912-2.114-1.697-4.297-2.799-6.307
    		c-0.428-0.781-1.569-1.172-2.386-1.74c-0.237,1.058-0.617,2.109-0.656,3.174c-0.021,0.569,0.77,1.148,0.804,1.744
    		c0.093,1.633-0.022,3.276-0.055,4.916C555.451,127.353,557.204,126.7,558.957,126.047z M557.345,156.465
    		c1.183-2.099,2.452-3.335,2.294-4.351c-0.166-1.073-1.663-2.647-2.631-2.686c-0.927-0.037-2.76,1.69-2.688,2.513
    		C554.427,153.198,555.829,154.345,557.345,156.465z M479.95,151.833c0.831-1.427,1.964-2.825,1.743-3.087
    		c-0.942-1.122-2.202-2.093-3.538-2.688c-0.331-0.148-1.381,1.315-2.1,2.039C477.19,149.186,478.325,150.274,479.95,151.833z
    		 M543.328,137.132c0.795,1.839,1.341,3.104,1.888,4.367c0.728-1.543,1.619-3.04,2.083-4.659c0.138-0.481-0.924-1.307-1.437-1.975
    		C545.042,135.599,544.222,136.333,543.328,137.132z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M507.153,244.223c-2.493,0-5.028-0.282-7.471,0.071
    		c-3.022,0.438-5.966,1.418-8.951,2.133c-0.912,0.219-2.068,0.73-2.708,0.377c-0.559-0.309-0.958-1.782-0.718-2.506
    		c1.037-3.139-0.626-5.01-2.685-6.903c-4.975-4.578-9.023-9.633-8.866-16.965c0.047-2.201-1.392-3.262-3.671-2.946
    		c-0.821,0.113-1.914,0.532-2.421,0.168c-1.054-0.756-2.442-1.918-2.481-2.958c-0.036-0.968,1.319-2.406,2.396-2.881
    		c1.101-0.486,2.631-0.202,3.932,0.005c4.455,0.708,7.536-1.113,11.029-3.956c3.238-2.636,6.741-6.375,12.102-5.318
    		c0.8,0.157,2.388-1.753,3.009-3.005c1.453-2.929,2.714-3.597,5.244-1.986c4.459,2.837,8.739,5.497,14.479,2.81
    		c2.071-0.971,4.92-0.374,7.409-0.301c0.685,0.021,1.555,0.575,1.988,1.15c2.254,2.994,4.3,6.148,6.602,9.104
    		c2.407,3.092,5.106,5.501,9.659,4.395c2.362-0.573,4.951-0.358,7.428-0.249c1.03,0.045,2.031,0.744,3.046,1.146
    		c-0.394,0.992-0.509,2.387-1.239,2.892c-1.438,0.993-3.194,2.017-4.862,2.094c-4.128,0.19-8.279-0.201-12.422-0.236
    		c-1.265-0.011-2.854-0.005-3.7,0.703c-0.75,0.626-0.676,2.239-0.965,3.417c1.018,0.108,2.183,0.594,3.029,0.264
    		c5.457-2.127,6.022-1.644,5.715,4.25c-0.159,3.046-0.378,5.588-3.983,6.485c-0.998,0.248-1.853,1.369-2.61,2.232
    		c-2.656,3.023-5.732,4.952-9.97,4.335c-0.637-0.093-1.505-0.138-1.947,0.206C517.799,245.939,512.284,243.684,507.153,244.223z
    		 M524.362,235.482c4.059,1.686,5.072-2.323,7.408-3.796c0.231-0.146,0.149-1.367-0.176-1.664c-0.354-0.323-1.481-0.43-1.723-0.152
    		c-2.969,3.412-7.123,1.411-10.638,2.252c-2.364,0.565-5.143-0.457-7.707-0.921c-2.183-0.396-4.303-1.153-6.49-1.484
    		c-2.423-0.367-5.353,0.344-7.247-0.783c-4.312-2.564-8.075-6.034-12.179-8.973c-0.882-0.632-2.26-0.572-3.408-0.831
    		c0.116,1.231,0.008,2.542,0.405,3.676c0.522,1.489,1.392,2.869,2.205,4.24c0.955,1.611,1.926,3.227,3.049,4.72
    		c0.867,1.153,2.277,1.967,2.923,3.203c1.346,2.578,3.392,3.452,6.11,3.412c3.825-0.056,7.661,0.127,11.475-0.099
    		c4.635-0.274,9.667,1.55,13.708-2.425C522.546,235.397,523.648,235.583,524.362,235.482z M532.121,215.14
    		c0.246-3.336-7.101-9.655-10.612-9.405c-5.475,0.389-10.816,0.863-16.207-1.247c-1.251-0.49-4.278,1.523-5.369,3.141
    		c-1.557,2.306,0.381,4.059,2.62,4.853c2.179,0.772,4.439,1.409,6.717,1.784c6.947,1.146,14.228-1.182,21.031,1.973
    		C530.714,216.429,531.686,215.417,532.121,215.14z M516.165,223.393c1.771,1.452,2.915,3.094,4.166,3.18
    		c0.891,0.061,2.937-2.354,2.694-2.997c-0.607-1.617-2.221-2.856-3.43-4.247C518.68,220.413,517.764,221.498,516.165,223.393z
    		 M506.58,220.813c-1.669-1.3-2.817-2.841-3.669-2.691c-1.001,0.176-2.482,1.889-2.396,2.806c0.09,0.949,1.707,2.161,2.857,2.445
    		C504.026,223.534,505.082,222.068,506.58,220.813z M491.849,208.148c-0.505,1.109-1.02,1.737-0.988,2.337
    		c0.022,0.435,0.987,1.255,1.152,1.169c0.615-0.317,1.421-0.898,1.486-1.458C493.554,209.719,492.689,209.136,491.849,208.148z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M521.436,123.192c-2.53-0.558-5.28-0.668-7.537-1.779
    		c-3.097-1.524-3.069-3.456-0.456-5.806c1.481-1.331,2.976-2.672,4.602-3.813c2.854-2.005,2.32-3.71-0.371-5.153
    		c-1.411-0.756-3.066-1.054-4.492-1.79c-0.746-0.386-1.234-1.271-1.839-1.932c0.702-0.573,1.518-1.733,2.087-1.623
    		c3.488,0.678,7.053,1.315,10.319,2.638c1.893,0.767,3.072,1.319,4.869-0.135c1.064-0.861,3.461-1.662,4.1-1.122
    		c1.201,1.019,2.245,3.093,2.098,4.606c-0.175,1.803-1.522,3.591-2.697,5.128c-0.291,0.381-1.895-0.166-2.866-0.403
    		c-0.843-0.205-1.646-0.576-2.466-0.874c-0.238,1.075-0.854,2.247-0.611,3.198c0.253,0.989,1.363,1.746,2.051,2.642
    		c0.787,1.026,1.521,2.094,2.279,3.144c-1.324,0.877-2.544,2.114-4,2.531c-1.535,0.44-3.296,0.1-4.958,0.1
    		C521.51,122.896,521.473,123.044,521.436,123.192z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M522.773,98.821c-2.072-0.302-3.385-0.442-4.677-0.691
    		c-3.884-0.748-7.776-1.467-11.628-2.361c-0.997-0.232-2.438-0.92-2.623-1.66c-0.21-0.838,0.591-2.677,1.26-2.844
    		c1.913-0.477,4.533-1.164,5.892-0.271c4.979,3.271,11.132,3.331,15.339,1.579c4.921-2.05,11.662-1.162,14.899-7.094
    		c1.1-2.016,3.079-1.825,4.618-0.248c1.533,1.571,1.048,2.997-0.16,4.809c-2.23,3.345-6.055,3.109-9.081,4.447
    		C532.025,96.516,526.998,97.55,522.773,98.821z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M463.207,123.714c-0.415,0.783-0.622,1.223-0.871,1.638
    		c-0.701,1.168-2.503,2.399-0.181,3.555c1.614,0.803,3.531,2.543,5.005-0.381c0.284-0.563,0.797-1.266,1.33-1.399
    		c1.138-0.286,2.359-0.241,3.546-0.332c-0.304,1.176-0.238,2.733-0.982,3.453c-1.865,1.802-3.938,3.55-6.242,4.672
    		c-1.348,0.657-3.45,0.488-4.946-0.051c-2.222-0.801-1.48-2.441-0.498-4.036c0.215-0.349-0.413-1.535-0.95-1.952
    		c-0.733-0.569-2.214-0.519-2.571-1.172c-1.809-3.305-3.392-2.699-5.555-0.281c-1.811,2.024-3.604,1.106-4.809-0.953
    		c-1.267-2.165-0.144-3.451,1.863-4.176c1.23-0.443,2.552-1.031,3.798-0.96c3.243,0.187,6.477,0.637,9.692,1.13
    		C461.654,122.593,462.389,123.268,463.207,123.714z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M570.643,131.311c2.746,0.111,4.098,2.964,2.437,5.162
    		c-1.587,2.101-3.425,4.068-5.435,5.759c-0.61,0.513-2.588,0.331-3.18-0.274c-0.972-0.996-2.199-3.316-1.786-3.821
    		c1.968-2.399,4.436-4.394,6.774-6.478C569.778,131.368,570.375,131.383,570.643,131.311z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M446.732,182.687c-0.886,2.673-0.196,5.281-3.527,5.304
    		c-3.134,0.021-3.014-2.311-3.057-4.512c-0.047-2.34,0.353-4.711,3.211-4.297C444.626,179.364,445.616,181.459,446.732,182.687z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M473.001,197.146c-0.52-0.363-1.651-1.154-2.784-1.946
    		c1.798-1.502,3.515-3.139,5.497-4.335c0.243-0.146,2.599,1.964,2.786,3.207C479.041,197.675,475.963,196.55,473.001,197.146z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M468.53,223.247c-2.282,1.783-3.656,3.559-4.444,3.328
    		c-1.686-0.492-3.068-2.021-4.576-3.126c1.282-1.048,2.465-2.729,3.876-2.948C464.575,220.315,466.029,221.826,468.53,223.247z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M457.31,208.52c1.135,1.445,3.214,2.937,3.148,4.325
    		c-0.069,1.485-2.163,2.875-3.372,4.307c-0.681-0.859-1.864-1.678-1.937-2.585c-0.147-1.867,0.299-3.781,0.5-5.676
    		C456.203,208.768,456.756,208.644,457.31,208.52z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M525.43,254.221c-1.349-1.885-2.593-2.872-2.779-4.03
    		c-0.484-3.022,2.368-3.217,4.198-3.712c0.716-0.194,2.881,2.024,2.778,2.198C528.615,250.395,527.267,251.913,525.43,254.221z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M480.635,167.033c-2.131,1.398-3.348,2.701-4.777,3.01
    		c-1.878,0.404-3.02-0.839-2.948-2.916c0.079-2.345,1.286-3.981,3.574-3.665C477.683,163.628,478.677,165.273,480.635,167.033z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M525.516,135.654c-1.553-1.326-2.553-2.181-3.554-3.035
    		c1.269-1.287,2.536-2.574,3.805-3.862c1.074,1.245,2.527,2.358,3.025,3.802C528.975,133.088,526.896,134.397,525.516,135.654z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M551.489,197.249c-2.409,1.324-3.752,2.676-4.812,2.481
    		c-1.184-0.218-2.11-1.836-3.149-2.845c1.126-1.145,2.159-3.048,3.406-3.2C547.998,193.556,549.307,195.441,551.489,197.249z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M528.277,156.254c-0.678-1.069-1.733-2.067-1.949-3.222
    		c-0.355-1.908,0.315-3.95,2.639-3.711c1.117,0.115,2.886,1.819,2.898,2.822c0.017,1.37-1.36,2.757-2.14,4.137
    		C529.243,156.271,528.76,156.263,528.277,156.254z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M514.615,165.896c-1.639-2.109-3.238-3.266-3.203-4.369
    		c0.037-1.146,1.558-3.003,2.613-3.141c1.055-0.138,3.289,1.451,3.303,2.312C517.352,162.081,515.973,163.487,514.615,165.896z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M546.921,233.69c1.48,2.228,2.962,3.473,2.845,4.543
    		c-0.131,1.21-1.699,2.263-2.643,3.384c-1.013-1.043-2.682-1.992-2.849-3.156C544.117,237.36,545.536,236.033,546.921,233.69z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M581.255,199.592c-2.294,1.741-3.501,3.233-4.938,3.503
    		c-0.711,0.133-2.62-2.019-2.574-3.075c0.048-1.119,1.646-2.822,2.806-3.047C577.59,196.771,578.953,198.227,581.255,199.592z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M564.627,206.911c1.557,2.252,3.076,3.484,2.999,4.605
    		c-0.079,1.156-1.715,2.204-2.669,3.3c-0.997-1.044-2.619-1.985-2.81-3.16C561.973,210.571,563.33,209.237,564.627,206.911z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M591.348,129.895c1.536,2.194,3.125,3.469,3.013,4.57
    		c-0.124,1.218-1.619,2.891-2.853,3.269c-2.195,0.673-2.895-1.408-2.704-3.027C588.959,133.389,590.081,132.185,591.348,129.895z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M515.753,63.809c-2.155,1.287-3.584,2.806-4.588,2.563
    		c-1.141-0.274-2.339-2.026-2.632-3.334c-0.136-0.605,1.904-2.457,2.796-2.354C512.591,60.832,513.702,62.267,515.753,63.809z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M581.692,169.916c-2.188,1.572-3.596,3.174-4.217,2.912
    		c-1.415-0.598-2.442-2.11-3.628-3.251c0.946-0.862,1.965-2.493,2.823-2.413C578.062,167.293,579.349,168.545,581.692,169.916z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M536.213,73.086c-1.871,1.127-3.104,2.409-3.783,2.163
    		c-1.282-0.467-2.721-1.642-3.154-2.857c-0.243-0.683,1.396-2.036,2.186-3.087C532.772,70.348,534.083,71.392,536.213,73.086z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M569.112,188.737c-2.452,1.446-3.807,2.533-5.348,2.993
    		c-0.526,0.156-1.522-1.267-2.304-1.968c1.216-1.156,2.291-2.583,3.721-3.329C565.708,186.159,567.073,187.492,569.112,188.737z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M450.009,200.207c-1.494,1.41-2.436,2.987-3.593,3.166
    		c-0.872,0.135-2.021-1.522-3.046-2.382c1.36-1.271,2.722-2.543,4.082-3.814C448.153,198.007,448.854,198.838,450.009,200.207z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M468.655,175.513c-1.728,0.621-3.442,1.621-5.189,1.684
    		c-0.726,0.025-1.523-1.952-2.288-3.019c1.146-0.566,2.299-1.595,3.438-1.582c1.366,0.016,2.723,0.919,4.084,1.438
    		C468.686,174.526,468.67,175.02,468.655,175.513z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M578.488,217.713c-1.953-1.944-3.16-3.146-4.366-4.347
    		c1.13-0.91,2.16-2.256,3.433-2.549c0.651-0.15,2.275,1.48,2.477,2.5C580.24,214.381,579.267,215.677,578.488,217.713z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M564.814,177.77c-1.44-1.961-2.851-3.278-2.625-3.682
    		c0.801-1.431,2.159-2.549,3.307-3.785c0.774,0.819,2.311,1.812,2.171,2.424C567.336,174.176,566.211,175.442,564.814,177.77z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M577.257,180.386c1.179,2.275,2.156,3.554,2.428,4.967
    		c0.122,0.632-1.172,1.536-1.822,2.317c-1.276-1.296-2.633-2.531-3.71-3.976C574.039,183.541,575.571,182.162,577.257,180.386z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M456.188,196.007c1.91,2.169,3.207,3.552,4.299,5.082
    		c0.074,0.104-1.316,1.765-2.168,1.919c-2.3,0.417-3.374-1.036-3.312-3.192C455.034,198.861,455.57,197.922,456.188,196.007z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M462.016,186.855c-1.56,1.207-2.636,2.496-3.089,2.306
    		c-1.341-0.565-2.437-1.714-3.629-2.635c0.945-0.748,1.902-2.104,2.833-2.086C459.207,184.462,460.261,185.691,462.016,186.855z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M498.263,68.002c1.272,1.636,2.547,2.671,2.363,3.151
    		c-0.515,1.354-1.629,2.479-2.507,3.692c-0.677-0.871-1.911-1.754-1.895-2.611C496.247,71.096,497.246,69.978,498.263,68.002z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M569.459,158.185c1.327-1.282,2.166-2.092,3.004-2.902
    		c0.721,0.906,2.027,1.827,2.003,2.713c-0.026,0.988-1.263,1.943-1.973,2.913C571.639,160.141,570.784,159.374,569.459,158.185z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M586.615,135.711c-2.203,1.484-3.253,2.704-4.382,2.782
    		c-0.823,0.057-1.75-1.391-2.632-2.168c0.885-0.801,1.67-2.044,2.682-2.271C583.184,133.853,584.336,134.776,586.615,135.711z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M517.438,76.747c-1.306-1.566-2.04-2.446-2.774-3.327
    		c1.062-0.865,2.027-1.952,3.238-2.467c0.387-0.165,2.134,1.376,2.019,1.809C519.608,73.939,518.635,74.942,517.438,76.747z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M491.846,86.377c-1.058,1.417-1.773,2.378-2.49,3.338
    		c-0.742-0.895-2.008-1.754-2.072-2.696c-0.06-0.867,1.172-1.822,1.835-2.739C489.9,84.881,490.683,85.482,491.846,86.377z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M550.854,74.022c-1.192,1.046-1.931,1.693-2.668,2.341
    		c-0.656-1.029-1.679-2.008-1.831-3.107c-0.091-0.654,1.184-1.499,1.846-2.258C548.963,71.866,549.726,72.735,550.854,74.022z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M487.587,192.312c-1.495,0.85-2.484,1.802-2.945,1.592
    		c-1.004-0.456-1.903-1.405-2.479-2.372c-0.164-0.274,1.259-1.954,1.405-1.881C484.781,190.251,485.865,191.112,487.587,192.312z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M583.424,150.451c-0.94-1.469-2.134-2.574-1.919-3.172
    		c0.351-0.974,1.621-1.616,2.504-2.398c0.56,0.863,1.574,1.739,1.549,2.585C585.532,148.297,584.452,149.097,583.424,150.451z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M557.546,204.042c-1.036,0.865-1.707,1.864-2.377,1.864
    		c-0.709,0-1.419-0.95-2.128-1.486c0.646-0.751,1.173-1.829,1.985-2.127C555.491,202.122,556.422,203.22,557.546,204.042z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M532.122,293.608c0.892,4.638,0.443,9.621-4.674,13.277
    		c-1.973,1.41-1.688,3.509-0.076,5.218c2.398,2.542,2.525,5.706,1.065,8.304c-1.571,2.796-3.922,5.345-8.09,4.144
    		c-1.672-0.481-3.628,0.074-5.455,0.075c-3.031,0.003-5.457-1.694-5.988-4.322c-0.37-1.829,0.734-4.481,2.057-6.007
    		c2.027-2.34,4.729-4.137,7.293-5.956c2.082-1.477,2.465-2.754,0.725-4.856c-0.95-1.148-1.277-2.852-1.742-4.349
    		c-0.29-0.932-0.126-2.023-0.489-2.911c-1.651-4.027-3.614-7.94-5.01-12.05c-0.368-1.086,0.888-2.724,1.398-4.107
    		c1.351,0.586,3.402,0.812,3.924,1.825c1.691,3.286,3.413,6.485,3.31,10.633c-0.075,3.021,1.962,6.128,3.268,9.104
    		c0.154,0.353,1.861,0.479,2.464,0.101c2.418-1.521,3.067-4.777,1.367-7.266c-2.145-3.141-1.504-6.352-0.394-9.473
    		c0.335-0.939,1.957-2.039,2.892-1.957c0.792,0.07,1.917,1.675,2.06,2.715C532.338,288.033,532.122,290.389,532.122,293.608z
    		 M518.934,319.814c1.643-1.589,3.155-2.734,4.119-4.233c0.211-0.328-2.125-2.878-2.292-2.776c-2.006,1.226-3.823,2.759-5.69,4.212
    		C516.104,317.765,517.139,318.514,518.934,319.814z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M495.876,313.545c-3.385-1.459-6.957-2.605-10.097-4.477
    		c-2.539-1.514-2.078-8.538,0.458-11.427c1.528-1.74,3.484-3.143,4.815-5.005c1.146-1.602,1.658-3.65,2.487-5.484
    		c0.607-1.345,1.273-2.663,1.914-3.992c1.312,1.238,3.114,2.242,3.839,3.761c1.89,3.957,3.309,8.14,4.912,12.234
    		c0.358,0.914,0.852,1.806,1.035,2.755C506.561,308.729,503.273,312.629,495.876,313.545z M500.186,302.644
    		c-0.926-1.836-1.797-3.729-2.812-5.54c-1.368-2.438-2.894-2.17-4.667-0.274c-3.23,3.453-2.799,5.277,2.438,8.432
    		c0.982,0.591,2.487,1.005,3.47,0.672C499.317,305.694,499.553,304.073,500.186,302.644z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M558.299,292.896c-4.104-0.46-6.903-1.047-9.696-1.018
    		c-3.691,0.038-4.667,1.856-3.047,5.206c1.125,2.326,2.642,4.483,3.559,6.878c0.312,0.813-0.419,2.345-1.123,3.159
    		c-1.363,1.576-2.854,3.755-5.351,2.318c-0.863-0.496-1.706-2.377-1.431-3.284c1.706-5.633-1.176-11.102-0.334-16.665
    		c0.222-1.461-0.101-3.035,0.281-4.434c0.751-2.753,2.957-3.573,4.753-1.42c2.726,3.269,4.169,1.418,5.648-0.809
    		c1.84-2.77,4.496-3.331,7.4-2.808c1.036,0.187,2.51,1.309,2.646,2.181c0.136,0.873-1.045,2.065-1.857,2.921
    		c-0.381,0.401-1.34,0.195-1.876,0.524c-0.639,0.393-1.104,1.065-1.645,1.617c0.551,0.533,1.019,1.359,1.668,1.542
    		c1.438,0.404,3.966-0.498,3.485,2.064C561.18,291.938,558.795,292.596,558.299,292.896z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M464.113,288.996c-0.261-5.073,2.426-9.044,5.071-12.939
    		c1.167-1.718,3.059-3.51,3.749,0.518c0.38,2.22,1.515,2.249,3.138,1.194c2.204-1.431,4.098-0.456,4.909,1.482
    		c0.428,1.021-0.305,3.159-1.231,4.002c-2.905,2.642-4.448,5.557-4.05,9.585c0.134,1.341-0.369,3.005-1.156,4.112
    		c-0.964,1.357-2.532,2.286-3.839,3.399c-0.378-1.597-0.919-3.177-1.083-4.795c-0.137-1.349,0.257-2.737-1.949-2.257
    		c-3.975,0.866-3.983,0.828-3.652-3.313C464.046,289.654,464.083,289.325,464.113,288.996z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M442.33,267.204c0.813-3.437,6.036-10.34,8.811-11.057
    		c2.768-0.715,3.807,1.493,5.094,3.149c3.062,3.938,1.821,11.938-2.512,14.122c-1.903,0.96-5.138,1.667-6.529,0.702
    		C445.019,272.613,443.897,269.587,442.33,267.204z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M454.549,284.43c1.529-3.542,3.026-7.099,4.606-10.617
    		c0.603-1.343,1.179-2.809,2.167-3.833c0.887-0.918,2.321-1.307,3.514-1.929c0.652,1.337,1.847,2.691,1.803,4.006
    		c-0.043,1.279-1.096,2.819-2.153,3.716c-2.78,2.354-3.926,5.233-3.767,8.772c0.03,0.658,0.175,1.55-0.174,1.94
    		c-1.016,1.14-2.255,2.081-3.406,3.1c-0.689-1.144-1.435-2.258-2.036-3.446c-0.2-0.396-0.031-0.979-0.031-1.477
    		C454.896,284.584,454.723,284.507,454.549,284.43z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M463.95,1059.42c-0.164-3.686,0.534-8.275-2.542-12.797
    		c-1.71-2.513-1.258-6.756-1.05-10.175c0.072-1.193,2.163-2.749,3.637-3.257c2.173-0.75,2.503,1.093,2.948,2.883
    		c0.339,1.36,1.586,2.494,2.428,3.729c1.165-1.153,2.435-2.22,3.464-3.483c1.008-1.239,1.52-3.021,2.73-3.907
    		c0.788-0.576,3.008-0.457,3.564,0.207c0.833,0.993,1.519,3.466,0.958,4.047c-3.276,3.399-5.901,7.708-10.905,9.042
    		c-2.062,0.551-3.05,2.045-1.615,3.641c2.818,3.135,2.216,6.769,2.285,10.374c0.058,2.987-0.12,6.021,0.352,8.945
    		c0.425,2.636-0.749,3.315-2.865,3.466c-2.258,0.162-3.417-0.898-3.396-3.217C463.971,1066.092,463.95,1063.264,463.95,1059.42z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M492.646,1056.729c1.093-3.688,2.46-7.326,3.165-11.088
    		c0.356-1.906-0.415-4.049-0.798-6.061c-0.389-2.042-1.311-4.057-1.301-6.08c0.012-2.473,2.746-4.632,4.665-4.008
    		c2.424,0.789,2.356,2.408,1.823,4.747c-0.48,2.109,0.458,4.503,0.492,6.774c0.091,6.012,0.031,12.024,0.03,18.037
    		c0,0.166-0.003,0.332-0.016,0.497c-0.319,4.321-2.052,7.291-4.272,6.851c-4.171-0.827-2.491-4.347-2.82-6.902
    		c-0.105-0.815-0.017-1.655-0.017-2.484C493.28,1056.918,492.963,1056.823,492.646,1056.729z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M477.261,1052.849c1.989-2.032,3.041-3.894,4.573-4.464
    		c1.703-0.635,4.25,2.729,4.186,5.141c-0.07,2.668-2.018,4.137-4.167,3.746C480.39,1057.006,479.266,1054.882,477.261,1052.849z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M374.837,992.038c0,2.49,0.106,4.986-0.057,7.466
    		c-0.058,0.869-0.767,2.397-1.193,2.402c-1.014,0.012-2.73-0.497-2.931-1.165c-0.73-2.435-1.113-5.008-1.325-7.554
    		c-0.213-2.549-0.052-5.128-0.078-7.693c-0.008-0.824-0.338-1.73-0.096-2.458c1.214-3.65,0.547-6.729-2.046-9.637
    		c-1.831-2.053-0.918-4.183,1.124-5.484c0.796-0.507,2.944-0.41,3.283,0.143c1.64,2.677,3.314,1.568,4.961,0.344
    		c3.793-2.822,8.057-3.796,12.635-3.15c1.217,0.171,2.302,1.282,3.448,1.963c-0.873,1.09-1.551,2.516-2.677,3.173
    		c-1.042,0.608-2.625,0.209-3.853,0.612c-0.771,0.254-1.324,1.171-1.975,1.789c0.557,0.517,1.045,1.162,1.692,1.511
    		c0.553,0.297,1.463,0.04,1.907,0.404c0.925,0.757,2.483,2.11,2.281,2.597c-0.468,1.129-1.723,2.477-2.846,2.674
    		c-2.251,0.396-4.63-0.018-6.949,0.08c-4.118,0.173-5.171,1.315-5.26,5.51c-0.046,2.157-0.008,4.316-0.008,6.475
    		C374.863,992.038,374.851,992.038,374.837,992.038z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M399.185,993.261c-0.099-0.776-0.101-1.803-0.381-2.745
    		c-0.357-1.199-0.94-2.332-1.426-3.493c-1.072,0.64-2.326,1.098-3.178,1.955c-1.744,1.758-3.102,3.928-4.966,5.522
    		c-0.845,0.724-2.886,1.045-3.706,0.51c-0.766-0.5-1.306-2.88-0.791-3.564c3.771-5.017,7.785-9.854,11.864-14.627
    		c0.455-0.533,2.152-0.753,2.65-0.349c1.053,0.853,2.508,2.328,2.366,3.35c-0.733,5.289,1.819,9.234,4.462,13.328
    		c0.754,1.169,1.143,3.993,0.655,4.278c-1.524,0.893-3.652,1.459-5.335,1.115c-1.03-0.21-1.733-2.288-2.449-3.601
    		C398.762,994.594,399.069,993.976,399.185,993.261z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M426.443,977.732c-1.773,4.947-3.336,9.464-5.025,13.931
    		c-0.574,1.52-1.3,3.049-2.278,4.327c-0.697,0.912-1.979,2.004-2.916,1.932c-0.957-0.075-2.479-1.387-2.599-2.308
    		c-0.693-5.308-3.133-9.88-5.555-14.48c-1.129-2.146-0.202-3.503,1.659-4.439c2.021-1.017,3.046,0.135,3.813,1.879
    		c0.664,1.511,1.348,3.04,2.254,4.408c0.476,0.72,1.447,1.111,2.195,1.651c0.477-0.795,1.068-1.544,1.407-2.395
    		c0.768-1.925,1.179-4.033,2.2-5.798c0.52-0.896,2.318-1.897,3.05-1.609C425.55,975.186,425.94,976.835,426.443,977.732z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M475.682,997.99c-1.832,0.003-3.668,0.092-5.493-0.018
    		c-2.711-0.162-4.087-1.904-2.986-4.319c1.478-3.24,3.423-6.275,5.277-9.331c0.477-0.785,1.368-1.32,2.068-1.97
    		c0.554,1.177,1.304,2.31,1.577,3.547c0.14,0.631-0.815,1.438-0.854,2.187c-0.06,1.168-0.085,3.162,0.449,3.377
    		c1.445,0.581,3.197,0.502,4.814,0.417c0.201-0.011,0.731-1.851,0.405-2.476c-1.903-3.646-3.933-7.234-6.097-10.732
    		c-1.036-1.676-3.005-3.112-0.648-5.051c1.491-1.228,5.854-0.426,6.803,1.325c2.315,4.273,4.645,8.562,6.575,13.014
    		c1.584,3.654-2.934,9.938-6.897,10.021C479.012,998.016,477.347,997.986,475.682,997.99z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M455.115,994.452c0-2.16-0.149-4.333,0.034-6.477
    		c0.298-3.48-1.284-4.695-4.621-4.833c-4.83-0.2-5.713-2.22-3.032-6.234c0.546-0.816,1.417-1.621,1.509-2.497
    		c0.312-2.955,2.125-3.231,4.559-3.247c7.415-0.049,7.772,0.631,7.303,7.835c-0.43,6.58,0.199,13.224-0.019,19.826
    		c-0.062,1.896-1.042,4.112-2.324,5.523c-1.387,1.525-3.188,1.013-3.319-1.438c-0.15-2.812-0.034-5.639-0.034-8.459
    		C455.151,994.452,455.134,994.452,455.115,994.452z M455.514,978.585c-0.788-0.671-1.346-1.451-1.679-1.365
    		c-0.654,0.17-1.184,0.821-1.765,1.273c0.41,0.45,0.845,1.287,1.227,1.264C453.941,979.717,454.553,979.133,455.514,978.585z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M504.651,986.428c1.271-0.481,2.083-1.081,2.813-0.995
    		c1.051,0.124,2.455,0.489,2.925,1.241c0.351,0.561-0.252,2.208-0.928,2.83c-2.411,2.221-5.076,4.162-7.556,6.312
    		c-0.83,0.72-1.395,1.747-2.078,2.636c-1.8,2.336-3.462,4.802-5.477,6.936c-0.663,0.702-2.236,0.545-3.395,0.78
    		c-0.047-1.228-0.608-2.819-0.045-3.616c1.715-2.427,3.817-4.59,5.853-6.777c3.741-4.021,3.832-4.713-0.571-8.133
    		c-2.44-1.896-2.965-3.37-0.348-5.419c1.303-1.021,2.531-2.18,3.612-3.432c1.502-1.74,3.331-2.754,5.036-0.905
    		c1.49,1.615,1.278,3.628-0.888,5.075c-0.687,0.46-1.026,1.438-1.525,2.18C503.03,985.616,503.979,986.091,504.651,986.428z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M635.449,1007.683c-2.959-1.229-5.812-2.304-8.57-3.58
    		c-5.739-2.652-5.901-3.876-2.049-8.478c2.494-2.979,4.429-6.327,8.204-8.152c1.213-0.586,2.358-2.961,2.194-4.36
    		c-0.248-2.129-0.074-4.26,1.812-4.403c1.46-0.11,4.108,1.622,4.418,2.955c0.732,3.16,0.319,6.572,3.228,8.98
    		c0.246,0.204-0.007,1.634-0.262,1.71c-0.855,0.254-1.854,0.334-2.73,0.151c-1.84-0.384-4.149-1.928-5.343-1.283
    		c-2.366,1.276-4.248,3.596-6.019,5.742c-0.506,0.613,0.059,2.109,0.132,3.201c0.984-0.219,2.063-0.251,2.937-0.691
    		c3.188-1.61,6.256-3.473,9.502-4.945c0.578-0.262,2.637,1.021,2.581,1.353c-0.549,3.295,0.326,7.281-3.456,9.269
    		C640.102,1006.162,637.946,1006.74,635.449,1007.683z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M604.01,988.351c-0.334,5.861-0.632,10.657-0.867,15.457
    		c-0.096,1.938-0.845,3.576-2.912,2.937c-1.183-0.366-2.692-2.023-2.772-3.188c-0.317-4.631-0.136-9.297-0.123-13.95
    		c0.001-0.493-0.068-1.121,0.191-1.458c2.153-2.794,2.385-5.943,2.266-9.331c-0.041-1.188,1.242-2.422,1.921-3.635
    		c0.766,1.255,2.073,2.461,2.191,3.773C604.216,982.412,604.01,985.917,604.01,988.351z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M614.937,982.512c0.115,2.61,0.435,5.235,0.239,7.821
    		c-0.071,0.944-1.365,1.797-2.101,2.69c-0.759-1.01-2.377-2.386-2.13-2.967c1.254-2.945-0.643-5.034-1.303-7.502
    		c-0.38-1.422,0.058-3.273,0.707-4.66c0.274-0.587,2.753-0.962,3.001-0.598c1.008,1.479,1.538,3.284,2.243,4.969
    		C615.375,982.348,615.155,982.43,614.937,982.512z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M615.196,1000.856c-0.23,0.603-0.373,2.133-1.213,2.902
    		c-1.312,1.203-3.435,2.953-4.609,2.578c-2.464-0.787-1.391-3.146-0.706-5.038c0.329-0.909,0.171-2.041,0.629-2.847
    		c0.726-1.277,1.783-2.364,2.7-3.532c0.967,1.086,2.021,2.11,2.857,3.289C615.188,998.678,615.044,999.487,615.196,1000.856z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M616.53,913.157c-5.02-0.233-7.289,1.904-7.287,6.947
    		c0.003,4.978,0.181,9.956,0.138,14.933c-0.008,1.029-0.648,2.052-0.998,3.078c-1.115-0.503-2.546-0.735-3.265-1.576
    		c-0.904-1.057-1.657-2.59-1.728-3.956c-0.342-6.712-0.465-13.438-0.593-20.159c-0.025-1.312,0.125-2.667,0.444-3.939
    		c0.886-3.523,2.706-4.242,5.108-1.615c2.191,2.396,3.329,2.132,4.756-0.478c0.314-0.577,1.482-1.254,1.967-1.078
    		c2.77,1.002,5.096,0.971,6.941-1.735c0.118-0.172,2.044,0.353,2.328,0.94c0.641,1.324,1.521,3.29,0.985,4.286
    		C623.525,912.16,620.258,913.321,616.53,913.157z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M634.443,929.499c-0.428-3.023-1.131-6.528-1.322-10.06
    		c-0.08-1.458,0.63-3.203,1.525-4.414c1.464-1.979,3.499-2.594,5.378-0.363c2.731,3.244,5.105,1.819,7.531-0.38
    		c1.718-1.558,3.531-3.494,5.981-1.478c1.505,1.239,0.233,5.077-2.466,6.726c-1.925,1.175-4.244,1.724-6.416,2.47
    		c-6.038,2.071-6.667,3.624-5.024,8.12c0.169,0.461,0.421,1.067,0.258,1.443c-0.582,1.339-1.327,2.608-2.012,3.903
    		c-1.145-1.021-2.427-1.932-3.368-3.114C634.114,931.856,634.443,930.784,634.443,929.499z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M625.355,925.135c-1.654,1.475-2.705,3.192-3.751,3.189
    		c-2.569-0.008-3.432-2.065-3.39-4.459c0.039-2.183,1.351-3.801,3.286-3.181C622.896,921.132,623.77,923.204,625.355,925.135z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M538.043,1058.941c-0.668,3.653,1.521,7.832-2.033,10.856
    		c-1.126,0.958-2.634,1.468-3.967,2.182c-0.181-1.44-0.576-2.892-0.506-4.319c0.264-5.282,0.744-10.555,0.979-15.839
    		c0.039-0.884-0.498-2.089-1.177-2.658c-3.477-2.915-1.991-6.791-1.957-10.276c0.009-0.875,1.842-2.215,2.999-2.419
    		c0.713-0.126,2.026,1.352,2.531,2.375c0.478,0.967-0.085,2.461,0.419,3.396c0.616,1.146,1.79,2.583,2.862,2.71
    		c0.877,0.103,2.541-1.367,2.845-2.429c1.084-3.79,3.709-5.934,7.159-6.899c1.246-0.349,2.931,0.871,4.414,1.376
    		c-0.461,1.267-0.555,2.985-1.452,3.714c-2.651,2.149-3.227,3.461,0.428,5.194c2.07,0.982,1.811,4.008-0.418,4.594
    		c-2.176,0.572-4.691,0.028-7.031-0.253c-1.682-0.203-3.319-0.778-4.977-1.188c-0.373,1.635-0.891,3.254-1.078,4.91
    		C537.897,1055.613,538.043,1057.294,538.043,1058.941z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M553.016,1066.337c0.23-0.488,0.791-1.99,1.605-3.34
    		c1.434-2.377,2.854-4.802,4.625-6.919c0.737-0.881,2.974-1.762,3.456-1.358c1.218,1.019,3.029,2.523,1.224,4.55
    		c-1.209,1.357-2.641,2.523-3.787,3.927c-0.515,0.628-0.575,1.628-0.842,2.459c0.759,0.053,1.862,0.452,2.217,0.099
    		c1.882-1.876,3.418-1.097,5.276,0.067c0.734,0.46,2.074-0.046,3.138-0.111c-0.19-0.901-0.267-1.847-0.591-2.696
    		c-1.387-3.624-2.751-7.262-4.316-10.81c-0.848-1.922-2.349-3.578-3.047-5.535c-0.407-1.144,0.187-2.645,0.33-3.984
    		c1.479,0.411,3.578,0.354,4.312,1.328c1.74,2.308,2.793,5.126,4.204,7.692c0.698,1.271,1.791,2.357,2.317,3.682
    		c0.621,1.56,0.908,3.272,1.17,4.945c0.146,0.933-0.236,1.951-0.072,2.876c1.179,6.646-1.994,10.039-8.817,9.409
    		c-2.629-0.242-5.267-0.456-7.903-0.511C553.431,1072.022,552.948,1071.595,553.016,1066.337z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M551.654,1059.883c-2.355,1.776-3.828,3.641-4.746,3.399
    		c-1.311-0.345-2.846-2.129-3.072-3.498c-0.152-0.923,1.731-3.017,2.805-3.089C547.855,1056.614,549.182,1058.208,551.654,1059.883z
    		"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M524.077,992.096c-1.015-3.364-2.193-6.69-2.979-10.107
    		c-0.432-1.879-0.502-3.969-0.138-5.848c0.164-0.847,1.722-1.551,2.776-2.041c0.467-0.218,1.356,0.102,1.883,0.442
    		c5.303,3.433,10.029,1.263,14.558-1.61c1.746-1.107,3.162-2.091,4.515,0.199c1.171,1.982,0.043,2.843-1.726,3.742
    		c-2.604,1.325-1.883,2.856,0.405,3.688c2.008,0.73,2.116,1.806,1.199,3.396c-0.945,1.64-2.007,2.342-4.055,1.363
    		c-2.776-1.327-5.713-2.379-8.666-3.264c-2.741-0.821-3.661,0.945-3.471,3.285c0.36,4.447,1.149,8.872,1.26,13.319
    		c0.041,1.658-1.318,3.352-2.04,5.028c-1.372-1.294-3.428-2.359-3.953-3.936c-0.758-2.271-0.402-4.914-0.522-7.397
    		C523.441,992.269,523.759,992.182,524.077,992.096z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M562.522,999.668c0.497-1.781,0.997-2.736,0.98-3.683
    		c-0.037-2.049,0.122-4.28-0.619-6.103c-1.988-4.884-0.761-9.455,0.882-13.967c0.298-0.819,2.447-1.775,3.188-1.441
    		c1.283,0.58,2.943,2.061,3.031,3.267c0.491,6.708,0.565,13.447,0.753,20.177c0.018,0.651,0.066,1.52-0.301,1.924
    		c-1.256,1.39-2.981,3.859-3.991,3.623C564.836,1003.088,563.658,1000.869,562.522,999.668z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M555.832,990.475c0,3.309,0.233,6.644-0.116,9.916
    		c-0.139,1.3-1.567,2.463-2.409,3.688c-1.152-0.896-2.826-1.556-3.357-2.731c-1.566-3.465-2.239-6.876-0.613-10.907
    		c1.226-3.042,0.654-6.835,0.75-10.297c0.049-1.772,0.189-3.845,2.31-3.481c1.297,0.223,3.088,2.092,3.267,3.409
    		c0.464,3.412,0.156,6.929,0.156,10.403C555.822,990.475,555.827,990.475,555.832,990.475z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M392.709,921.028c0,1.825,0.225,3.688-0.08,5.462
    		c-0.196,1.141-1.119,3.004-1.805,3.044c-1.65,0.098-3.55-0.373-4.946-1.253c-0.658-0.414-0.946-2.636-0.447-3.457
    		c2.194-3.608,1.388-7.348,1.14-11.135c-0.228-3.458-0.347-6.944-0.143-10.396c0.062-1.054,1.207-2.644,2.127-2.866
    		c0.824-0.199,2.317,0.926,3.001,1.849c2.514,3.39,1.852,2.951,4.747-0.431c2.066-2.414,5.917-3.363,9.079-4.715
    		c0.519-0.222,2.249,0.818,2.275,1.334c0.06,1.178-0.413,2.487-0.998,3.565c-0.578,1.065-1.561,1.911-2.366,2.853
    		c1.149,0.403,2.318,0.758,3.441,1.224c0.83,0.346,2.13,0.711,2.267,1.292c0.179,0.759-0.342,2.057-0.995,2.554
    		c-1.134,0.863-2.556,1.437-3.94,1.849c-0.914,0.273-1.976,0.043-2.971,0.055C391.866,911.979,392.755,910.242,392.709,921.028z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M366.22,911.041c0-3.817-0.178-7.646,0.077-11.445
    		c0.121-1.803,0.699-4.751,1.72-5.063c1.952-0.599,4.679-0.267,6.529,0.721c4.354,2.321,4.289,2.491,6.462-1.773
    		c0.51-1.001,1.65-2.075,2.675-2.291c0.799-0.169,2.548,0.824,2.667,1.51c0.219,1.262,0.005,3.186-0.833,3.938
    		c-3.142,2.816-5.149,7.46-10.881,6.39c-2.631-0.491-2.786,2.189-2.782,4.267c0.011,5.974,0.051,11.947-0.019,17.92
    		c-0.019,1.678,0.701,4.373-1.894,4.219c-1.285-0.077-3.327-2.477-3.468-3.972c-0.449-4.77-0.167-9.607-0.167-14.418
    		C366.277,911.041,366.249,911.041,366.22,911.041z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M428.801,919.975c-0.613,5.777-1.695,6.813-7.295,7.06
    		c-1.575,0.069-3.393,0.135-4.65,0.92c-4.197,2.617-8.909,0.549-9.436-4.396c-0.088-0.821-0.248-1.776,0.062-2.474
    		c1.259-2.828,2.486-5.71,4.155-8.293c0.647-1.003,2.437-1.269,3.706-1.869c0.276,1.814,0.91,3.673,0.7,5.43
    		c-0.133,1.115-1.812,1.972-2.25,3.148c-0.323,0.867,0.236,2.063,0.403,3.114c2.522-0.913,5.121-1.683,7.477-2.915
    		c0.298-0.156-0.22-3.185-1.138-4.115c-3.188-3.235-4.267-7.097-3.889-11.361c0.097-1.084,1.636-2.04,2.512-3.056
    		c0.968,0.942,2.267,1.718,2.839,2.857c1.668,3.32,3.068,6.775,4.525,10.198C427.331,916.119,428.045,918.057,428.801,919.975z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M604.796,1040.448c0.296,0.137,1.038,0.251,1.13,0.559
    		c0.952,3.181,3.526,7.536,2.319,9.358c-2.568,3.877-1.132,7.275-1.142,10.901c-0.006,1.719-0.195,3.44-0.122,5.152
    		c0.061,1.398-0.09,3.204,0.691,4.093c1.908,2.169,1.72,3.888-0.403,5.429c-1.044,0.758-2.995,1.78-3.596,1.354
    		c-1.943-1.374-4.021-2.974-3.039-6.117c0.986-3.157,1.819-6.396,2.332-9.659c0.617-3.922-1.241-6.297-5.107-7.199
    		c-3.997-0.934-4.774-2.3-3.529-6.203C595.341,1044.948,601.351,1040.47,604.796,1040.448z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M646.26,1051.945c-0.527,6.872-0.803,12.822-1.561,18.709
    		c-0.195,1.514-1.793,2.946-3.011,4.15c-0.274,0.272-2.185-0.348-2.421-0.931c-0.876-2.159-3.498-4.002-1.76-6.772
    		c1.056-1.683,2.541-3.116,3.469-4.855c1.701-3.191,0.472-5.098-3.104-5.178c-5.868-0.132-8.198-4.087-5.031-9.148
    		c1.242-1.985,3.376-3.65,5.463-4.82c3.896-2.185,5.961-0.866,6.673,3.583C645.315,1048.796,645.987,1050.855,646.26,1051.945z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M619.172,1066.329c-0.435-0.323-1.598-1.188-2.761-2.055
    		c1.646-1.373,3.218-2.867,5.029-3.968c0.235-0.144,2.255,1.627,2.488,2.717C624.415,1065.292,623.003,1066.37,619.172,1066.329z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M372.143,1060.203c1.65-3.756,1.204-8.431,0.261-13.475
    		c-1.035-5.536-0.4-11.418-0.167-17.136c0.056-1.372,1.401-2.692,2.154-4.036c1.178,0.729,2.688,1.207,3.469,2.237
    		c2.094,2.764,3.929,3.905,6.748,0.755c0.794-0.888,2.79-1.366,4.002-1.073c2.586,0.625,4.751,0.676,7.054-0.961
    		c1.916-1.362,3.101-0.217,3.33,1.901c0.275,2.545-0.698,4.486-2.857,5.928c-2.728,1.82-3.612,4.374-3.486,7.7
    		c0.25,6.621,0.221,13.263-0.017,19.885c-0.055,1.511-1.704,4.321-2.14,4.23c-1.96-0.409-3.927-1.431-5.485-2.722
    		c-0.446-0.369,0.491-2.375,0.749-3.639c0.845-4.15,5.146-7.717,1.561-12.687c-1.052-1.457-0.107-4.236-0.486-6.336
    		c-0.263-1.448-0.95-3.623-1.945-3.947c-1.482-0.483-3.554,0.084-5.049,0.845c-0.465,0.236,0.167,2.547,0.223,3.911
    		c0.065,1.583,0.582,3.437-0.047,4.699c-1.238,2.486-1.331,4.599-0.082,7.065c1.27,2.507,1.536,5.047-0.949,7.252
    		c-0.652,0.579-1.016,1.713-1.12,2.636c-0.253,2.217-1.473,3.497-3.562,3.293c-2.56-0.25-2.258-2.401-2.159-4.249
    		C372.157,1061.95,372.143,1061.616,372.143,1060.203z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M407.566,1059.204c0,1.996-0.037,3.993,0.009,5.988
    		c0.065,2.813-1.367,4.445-4.046,4.745c-1.354,0.152-3.55,0.146-3.992-0.601c-0.707-1.194-0.967-3.753-0.197-4.533
    		c3.658-3.712,1.555-8.158,2.064-12.228c0.284-2.27-0.064-4.627,0.3-6.877c0.63-3.899,2.073-4.533,5.188-2.207
    		c2.03,1.517,3.336,1.735,3.701-1.079c0.271-2.082,1.355-2.673,3.227-3.049c1.166-0.234,2.079-1.57,3.255-1.953
    		c1.256-0.41,2.82-0.66,3.995-0.249c0.736,0.258,1.615,2.129,1.326,2.799c-0.393,0.908-1.84,1.363-2.84,2.005
    		c-0.893,0.572-1.794,1.132-2.69,1.698c0.898,0.735,1.722,1.962,2.71,2.112c2.549,0.388,2.876,1.844,2.532,3.903
    		c-0.389,2.327-2.023,2.299-3.786,1.956c-2.682-0.522-5.342-1.243-8.048-1.509c-0.849-0.083-2.515,0.938-2.601,1.61
    		c-0.317,2.453-0.126,4.973-0.126,7.467C407.554,1059.204,407.561,1059.204,407.566,1059.204z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M572.868,924.491c-2.742-0.968-5.537-1.812-8.211-2.94
    		c-2.172-0.916-4.176-1.468-5.783,0.757c-1.449,2.005-4.32,3.515-2.229,6.838c0.996,1.584-0.439,8.173-1.869,8.96
    		c-0.886,0.489-2.371,0.695-3.191,0.268c-0.789-0.41-1.726-2.079-1.458-2.713c1.291-3.052,1.008-5.944,0.023-8.948
    		c-0.179-0.544-0.021-1.58,0.369-1.869c3.071-2.279,2.385-5.538,2.564-8.648c0.078-1.354,0.568-3.587,1.321-3.795
    		c1.226-0.339,3.177,0.264,4.181,1.176c1.866,1.694,2.968,1.084,3.911-0.649c2.592-4.762,7.124-4.055,11.353-4.057
    		c0.823,0,2.32,0.808,2.329,1.266c0.02,0.969-0.627,2.029-1.222,2.9c-0.335,0.49-1.205,0.582-1.671,1.025
    		c-0.795,0.757-1.461,1.649-2.182,2.485c1.07,0.531,2.113,1.134,3.222,1.57c0.754,0.297,1.86,0.097,2.35,0.583
    		c1.091,1.082,1.894,2.454,2.813,3.708c-1.289,0.48-2.553,1.058-3.876,1.402c-0.778,0.203-1.653,0.037-2.485,0.037
    		C573.041,924.062,572.954,924.276,572.868,924.491z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M530.685,924.71c-1.56,5.119-3.043,10.264-4.778,15.322
    		c-0.211,0.614-2.368,1.302-2.615,1.032c-1.006-1.097-2.607-3.168-2.225-3.784c4.356-7.022,1.255-14.668,2.129-21.938
    		c0.236-1.969-0.161-4.017,0.104-5.979c0.157-1.171,0.776-2.786,1.662-3.211c0.898-0.43,2.865-0.064,3.521,0.688
    		c2.397,2.746,4.52,3.231,6.907,0.152c0.194-0.25,0.504-0.5,0.8-0.563c0.634-0.134,1.827-0.308,1.865-0.184
    		c1.248,4.111,7.023,1.404,7.606,4.313c0.417,2.084-1.932,6.234-3.919,6.915c-2.646,0.906-6.544,1.746-9.278-1.361
    		c-0.491-0.559-1.996-0.938-2.488-0.603c-0.616,0.42-0.975,1.69-0.905,2.55c0.172,2.14,0.649,4.254,1.004,6.378
    		C530.277,924.528,530.481,924.619,530.685,924.71z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M566.46,935.801c-2.349,0.045-4.579-0.002-4.39-3.187
    		c0.188-3.142,2.582-3.397,4.936-3.582c2.487-0.195,3.685,1.199,3.715,3.51C570.76,935.476,568.753,935.964,566.46,935.801z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M490.668,925.351c0,4.646-0.01,9.292,0.004,13.938
    		c0.006,2.367,0.225,5.138-3.1,5.128c-3.115-0.01-2.742-2.707-2.753-4.844c-0.024-4.645-0.054-9.289-0.024-13.933
    		c0.033-5.426-0.481-6.269-5.8-7.197c-2.315-0.404-2.992-1.595-3.17-3.658c-0.321-3.732,1.972-6.31,4.705-7.544
    		c2.486-1.124,5.74-0.81,8.616-0.594c0.593,0.044,1.393,2.438,1.438,3.771c0.171,4.973,0.068,9.954,0.068,14.932
    		C490.658,925.351,490.663,925.351,490.668,925.351z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M460.632,932.115c0.455-1.708,0.578-3.609,1.431-5.088
    		c2.314-4.01,1.217-7.485-3.175-9.081c-4.171-1.516-5.301-5.588-2.473-8.944c0.212-0.252,0.501-0.438,0.731-0.677
    		c5.326-5.528,8.877-5.075,12.173,1.921c0.624,1.325,0.151,3.655-0.646,5.045c-1.152,2.008-1.191,3.352-0.023,5.413
    		c0.819,1.446,1.18,4.432,0.312,5.308c-2.385,2.403-2.069,5.079-2.122,7.856c-0.04,2.127,0.426,4.764-2.751,4.863
    		c-3.563,0.112-3.04-2.732-3.088-5.021c-0.011-0.499-0.002-0.998-0.002-1.497C460.877,932.181,460.754,932.148,460.632,932.115z
    		 M462.651,915.459c0.58-0.986,1.286-1.654,1.201-2.199c-0.079-0.509-1.029-1.259-1.538-1.215c-0.483,0.041-1.246,0.98-1.232,1.509
    		C461.095,914.091,461.905,914.608,462.651,915.459z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M505.392,921.561c0,3.647,0.021,7.296-0.006,10.943
    		c-0.024,3.307-2.018,5.223-4.896,6.135c-2.644,0.838-5.228-1.722-3.771-3.881c4.511-6.686,2.159-14.047,2.8-21.102
    		c0.119-1.307-0.124-2.922,0.555-3.832c0.862-1.155,2.493-1.737,3.793-2.566c0.501,1.119,1.377,2.22,1.436,3.362
    		c0.187,3.639,0.069,7.292,0.069,10.94C505.378,921.561,505.385,921.561,505.392,921.561z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M441.301,92.23c-2.343,1.636-3.595,2.854-5.084,3.331
    		c-0.416,0.133-2.229-1.992-2.133-2.953c0.098-0.968,1.686-2.312,2.786-2.496C437.876,89.944,439.104,91.108,441.301,92.23z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M410.957,103.262c-1.436-1.582-2.949-2.947-2.809-3.146
    		c0.951-1.349,2.251-2.451,3.438-3.633c0.689,0.767,2.062,1.688,1.931,2.271C413.221,100.073,412.184,101.228,410.957,103.262z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M426.667,104.688c-2.043,1.463-3.225,2.642-4.647,3.158
    		c-0.538,0.195-1.631-1.135-2.471-1.77c1.271-1.315,2.478-2.714,3.897-3.844C423.629,102.087,424.922,103.336,426.667,104.688z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M408.292,168.195c-1.733,1.123-2.562,2.095-3.445,2.146
    		c-2.151,0.126-3.445-1.224-3.274-3.355c0.072-0.901,1.076-1.729,1.657-2.589C404.689,165.493,406.15,166.588,408.292,168.195z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M393.541,145.719c0.955,1.772,2.021,2.905,1.775,3.466
    		c-0.578,1.324-1.779,2.376-2.729,3.538c-0.779-0.691-2.335-1.707-2.205-2.018C391.009,149.204,392.09,147.895,393.541,145.719z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M390.76,127.786c-2.004,1.217-3.13,2.334-4.367,2.475
    		c-0.655,0.074-2.304-1.968-2.115-2.327c0.574-1.096,1.72-2.314,2.843-2.556C387.904,125.21,389.017,126.566,390.76,127.786z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M404.666,104.586c1.259,1.915,2.653,3.109,2.508,4.07
    		c-0.149,0.987-1.838,2.452-2.788,2.405c-0.927-0.045-2.389-1.688-2.473-2.723C401.838,107.4,403.302,106.338,404.666,104.586z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M384.34,144.488c-1.006-1.681-2.155-2.876-1.912-3.337
    		c0.663-1.259,1.914-2.208,2.932-3.279c0.499,1.062,1.44,2.153,1.369,3.174C386.661,142.001,385.52,142.88,384.34,144.488z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M398.555,121.584c-0.983,0.57-1.956,1.553-2.955,1.581
    		c-0.829,0.024-1.691-1.075-2.539-1.676c0.794-0.818,1.483-2.113,2.412-2.318c0.885-0.194,2.02,0.749,3.044,1.191
    		C398.529,120.77,398.542,121.177,398.555,121.584z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M384.857,148.65c0.904,1.779,1.71,2.797,1.881,3.911
    		c0.104,0.671-0.782,1.493-1.224,2.247c-0.845-1.029-1.905-1.964-2.428-3.138C382.885,151.215,383.897,150.218,384.857,148.65z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M397.879,159.392c-1.418,1.091-2.158,2.034-3.045,2.2
    		c-0.524,0.099-1.279-1.031-1.931-1.609c0.602-0.637,1.118-1.639,1.83-1.805C395.413,158.02,396.295,158.732,397.879,159.392z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M408.306,120.365c-1.07,0.848-1.688,1.624-2.453,1.823
    		c-0.398,0.104-1.062-0.814-1.604-1.271c0.562-0.628,1.025-1.52,1.726-1.785C406.41,118.967,407.205,119.748,408.306,120.365z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M394.367,136.754c0.75,0.941,1.663,1.662,1.538,2.02
    		c-0.267,0.76-0.995,1.458-1.711,1.889c-0.184,0.111-1.53-0.969-1.458-1.322C392.896,138.549,393.613,137.87,394.367,136.754z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M606.079,96.828c2.941-0.524,5.87-1.141,8.83-1.516
    		c0.843-0.106,2.167,0.236,2.547,0.834c0.343,0.537-0.261,1.72-0.542,2.588c-0.087,0.268-0.627,0.388-0.716,0.656
    		c-0.569,1.712-1.07,3.446-1.594,5.174c-1.642-0.887-3.195-2.137-4.948-2.56c-1.81-0.436-3.798-0.049-3.596,2.706
    		c0.036,0.493,0.275,1.226,0.045,1.436c-0.746,0.68-1.666,1.167-2.52,1.729c-0.253-0.845-0.715-1.69-0.714-2.535
    		c0.002-1.651,0.829-3.513,0.309-4.912C601.721,96.505,601.825,96.07,606.079,96.828z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M634.477,121.537c-1.292,1.43-2.153,2.382-3.015,3.335
    		c-1.413-1.479-2.825-2.96-4.238-4.439c1.227-1.125,2.452-2.25,3.679-3.375C632.007,118.441,633.11,119.825,634.477,121.537z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M633.044,114.007c-1.495-0.089-2.035,0.001-2.474-0.171
    		c-1.11-0.435-2.176-0.985-3.259-1.49c0.765-1.176,1.301-2.688,2.372-3.409c0.724-0.488,2.205,0.097,3.332,0.296
    		c0.237,0.042,0.39,0.505,0.604,0.755c0.806,0.941,1.621,1.876,2.434,2.812C634.768,113.315,633.482,113.831,633.044,114.007z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M597.64,97.235c-1.446-1.803-2.736-2.816-3.077-4.085
    		c-0.174-0.646,1.36-1.751,2.122-2.648c1.213,1.183,2.54,2.282,3.521,3.634C600.358,94.347,598.93,95.705,597.64,97.235z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M594.787,106.598c0.952,1.189,2.152,2.046,2.08,2.775
    		c-0.076,0.759-1.567,2.08-2.11,1.934c-0.985-0.265-1.726-1.437-2.567-2.229C592.932,108.368,593.674,107.66,594.787,106.598z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M619.896,106.385c0.817,1.047,1.632,1.685,1.812,2.469
    		c0.114,0.497-0.715,1.211-1.121,1.828c-0.757-0.716-1.641-1.351-2.184-2.202C618.261,108.258,619.18,107.359,619.896,106.385z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M592.791,102.657c-0.771-0.683-1.312-1.163-1.855-1.643
    		c0.632-0.61,1.183-1.413,1.938-1.73c0.282-0.118,1.453,0.884,1.469,1.398C594.359,101.236,593.497,101.817,592.791,102.657z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M609.809,165.159c3.315,0.794,6.667,1.464,9.935,2.42
    		c2.54,0.744,2.503,2.541,0.808,4.071c-1.581,1.429-3.73,2.305-2.276,5.09c0.165,0.316-0.753,1.197-1.168,1.816
    		c-0.656-0.536-1.598-0.94-1.92-1.632c-1.833-3.927-3.517-7.923-5.252-11.895L609.809,165.159z"/>
    	<path fillRule="evenodd" clipRule="evenodd" d="M606.389,174.549c-1.03-1.386-1.912-2.156-2.146-3.089
    		c-0.095-0.376,1.314-1.646,1.886-1.555c0.918,0.148,1.708,1.09,2.552,1.695C608.052,172.409,607.423,173.219,606.389,174.549z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M548.381,453.048c-1.538-0.954-2.771-1.718-4.003-2.481
    		c1.306-0.628,2.565-1.621,3.927-1.79c1.575-0.194,4.061-0.792,4.048,1.892C552.349,451.399,550.007,452.118,548.381,453.048z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M388.529,355.728c-1.061-2.095-1.705-2.783-1.58-3.278
    		c0.14-0.553,0.977-0.93,1.511-1.384c0.455,0.559,1.228,1.088,1.281,1.684C389.803,353.418,389.216,354.146,388.529,355.728z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M504.116,376.074c-0.372-0.843-1.29-2.013-1.018-2.469
    		c0.96-1.603,2.33-2.959,3.544-4.409c0.619,0.593,1.87,1.438,1.751,1.737c-0.649,1.63-1.637,3.125-2.508,4.667
    		C505.296,375.759,504.706,375.917,504.116,376.074z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M702.253,531.528c0.313,1.073,1.223,2.513,0.829,3.152
    		c-1.188,1.934-2.752,3.698-4.443,5.226c-0.628,0.568-2.104,0.576-3.012,0.28c-0.393-0.128-0.768-1.997-0.387-2.517
    		c1.668-2.277,3.615-4.35,5.462-6.496C701.219,531.292,701.735,531.41,702.253,531.528z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M606.406,385.222c-1.221-2.153-2.602-3.512-2.351-4.414
    		c0.329-1.179,1.95-1.996,3.016-2.969c0.733,1.104,2.03,2.202,2.039,3.312C609.119,382.204,607.781,383.269,606.406,385.222z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M491.985,507.799c0.859,1.195,1.674,1.858,1.765,2.608
    		c0.068,0.564-0.791,1.242-1.237,1.869c-0.704-0.536-1.843-0.977-1.987-1.635C490.384,509.995,491.252,509.127,491.985,507.799z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M312.181,468.532c-1.092,0.599-1.773,1.282-2.36,1.208
    		c-0.605-0.076-1.119-0.889-1.673-1.379c0.59-0.439,1.166-1.203,1.771-1.228C310.51,467.109,311.131,467.842,312.181,468.532z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M536.508,589.723c-0.65-0.866-1.526-1.572-1.404-1.973
    		c0.226-0.733,1.04-1.287,1.608-1.916c0.477,0.676,1.214,1.311,1.331,2.044C538.113,588.319,537.217,588.915,536.508,589.723z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M327.484,696.776c-1.098-1.273-1.84-2.136-2.582-2.998
    		c0.808-0.641,1.687-1.866,2.404-1.778c0.979,0.121,1.834,1.243,2.742,1.937C329.356,694.703,328.664,695.47,327.484,696.776z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M402.651,739.926c-1.085,0.963-1.749,1.91-2.611,2.155
    		c-0.393,0.111-1.763-1.289-1.659-1.789c0.185-0.887,1.155-1.61,1.798-2.402C400.844,738.438,401.51,738.985,402.651,739.926z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M460.377,867.727c0.785-6.126,1.971-7.346,6.848-5.934
    		c1.136,0.329,2.202,2.01,2.643,3.294c0.391,1.137-0.114,2.589-0.257,3.897c-0.408,3.729-4.713,6.092-7.765,3.854
    		C460.715,872.009,460.83,869.48,460.377,867.727z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M615.688,880.555c-1.21-1.134-1.981-1.856-2.753-2.578
    		c0.914-0.695,1.774-1.832,2.757-1.948c0.818-0.097,1.779,1.002,2.677,1.572C617.577,878.472,616.786,879.344,615.688,880.555z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M618.849,867.906c-1.314,0.946-2.278,1.822-3.41,2.313
    		c-0.253,0.11-1.667-1.544-1.57-1.678c0.86-1.187,1.94-2.213,2.956-3.287C617.352,865.945,617.878,866.636,618.849,867.906z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M473.313,856.562c-1.204,1.068-1.937,2.186-2.488,2.103
    		c-0.941-0.143-1.771-1.037-2.646-1.616c0.584-0.646,1.137-1.78,1.76-1.821C470.836,855.168,471.785,855.906,473.313,856.562z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M782.639,745.219c0.776,0.858,1.613,1.375,1.622,1.905
    		c0.01,0.574-0.752,1.162-1.177,1.745c-0.566-0.451-1.473-0.823-1.602-1.376C781.362,746.977,782.065,746.269,782.639,745.219z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M547.495,825.591c-0.854,0.893-1.377,1.765-2.135,2.09
    		c-0.354,0.151-1.162-0.754-1.765-1.18c0.612-0.831,1.226-1.663,1.838-2.494C546.02,824.457,546.605,824.907,547.495,825.591z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M542.44,745.148c0.739,1.14,1.451,1.969,1.339,2.103
    		c-1.345,1.593-2.812,3.083-4.247,4.6c-0.476-0.563-1.449-1.501-1.35-1.634C539.419,748.566,540.811,747.033,542.44,745.148z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M696.286,717.805c-1.023,0.942-1.768,1.628-2.512,2.313
    		c-0.591-1.013-1.587-2.009-1.629-3.044c-0.029-0.695,1.292-1.446,2.01-2.173C694.774,715.745,695.394,716.589,696.286,717.805z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M475.732,759.876c-1.25,0.417-2.759,1.424-3.369,1.031
    		c-1.855-1.191-3.376-2.911-4.957-4.495c-0.059-0.059,0.781-1.284,0.925-1.23c2.206,0.834,4.384,1.753,6.518,2.758
    		C475.175,758.092,475.251,758.775,475.732,759.876z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M666.829,715.938c-1.287,0.912-2.098,1.486-2.908,2.062
    		c-0.505-0.752-1.506-1.59-1.394-2.233c0.157-0.894,1.149-1.641,1.784-2.45C665.009,714.042,665.706,714.769,666.829,715.938z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M442.068,757.867c-1.009-1.168-2.001-2.065-1.891-2.231
    		c0.837-1.263,1.891-2.384,2.876-3.549c0.46,0.558,1.418,1.297,1.292,1.64C443.875,755.009,443.051,756.162,442.068,757.867z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M433.241,756.748c-0.546-1.313-1.203-2.368-1.02-2.543
    		c0.986-0.943,2.166-1.692,3.351-2.389c0.058-0.034,1.231,1.444,1.122,1.574C435.795,754.457,434.714,755.37,433.241,756.748z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M625.363,741.588c-0.67-1.398-1.461-2.281-1.303-2.936
    		c0.154-0.645,1.271-1.059,1.965-1.573c0.378,0.631,1.122,1.314,1.042,1.88C626.962,739.704,626.208,740.357,625.363,741.588z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M361.538,843.88c-1.397,0.579-2.132,1.148-2.801,1.08
    		c-0.586-0.061-1.499-0.807-1.536-1.305c-0.039-0.532,0.695-1.454,1.247-1.595C358.919,841.941,359.609,842.691,361.538,843.88z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M394.312,1315.789c-0.828-0.948-1.76-1.559-1.7-2.047
    		c0.102-0.834,0.826-1.592,1.289-2.381c0.674,0.579,1.774,1.08,1.897,1.759C395.916,1313.771,395.02,1314.605,394.312,1315.789z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M458.688,1227.875c-0.986,0.708-1.574,1.458-2.127,1.433
    		c-0.551-0.025-1.067-0.827-1.599-1.288c0.505-0.519,0.956-1.361,1.531-1.458C457.006,1226.476,457.656,1227.217,458.688,1227.875z"
    		/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M558.957,126.047c-1.753,0.653-3.506,1.306-5.259,1.959
    		c0.032-1.64,0.147-3.283,0.055-4.916c-0.034-0.596-0.825-1.175-0.804-1.744c0.039-1.064,0.419-2.116,0.656-3.174
    		c0.816,0.568,1.958,0.959,2.386,1.74c1.102,2.01,1.887,4.192,2.799,6.307L558.957,126.047z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M557.345,156.465c-1.516-2.12-2.918-3.267-3.025-4.523
    		c-0.071-0.822,1.762-2.55,2.688-2.513c0.968,0.038,2.465,1.612,2.631,2.686C559.797,153.13,558.527,154.366,557.345,156.465z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M479.95,151.833c-1.625-1.559-2.76-2.647-3.895-3.735
    		c0.719-0.724,1.769-2.188,2.1-2.039c1.336,0.595,2.596,1.565,3.538,2.688C481.914,149.008,480.781,150.406,479.95,151.833z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M543.328,137.132c0.894-0.799,1.714-1.533,2.534-2.267
    		c0.513,0.668,1.574,1.493,1.437,1.975c-0.464,1.619-1.355,3.116-2.083,4.659C544.669,140.235,544.123,138.971,543.328,137.132z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M524.362,235.482c-0.714,0.101-1.816-0.085-2.284,0.375
    		c-4.041,3.975-9.073,2.15-13.708,2.425c-3.813,0.226-7.649,0.043-11.475,0.099c-2.719,0.04-4.765-0.834-6.11-3.412
    		c-0.646-1.236-2.056-2.05-2.923-3.203c-1.123-1.493-2.094-3.108-3.049-4.72c-0.813-1.371-1.683-2.751-2.205-4.24
    		c-0.397-1.134-0.289-2.444-0.405-3.676c1.148,0.259,2.526,0.199,3.408,0.831c4.104,2.938,7.867,6.408,12.179,8.973
    		c1.895,1.127,4.824,0.416,7.247,0.783c2.188,0.331,4.308,1.089,6.49,1.484c2.564,0.464,5.343,1.486,7.707,0.921
    		c3.515-0.841,7.669,1.16,10.638-2.252c0.241-0.277,1.368-0.171,1.723,0.152c0.325,0.297,0.407,1.518,0.176,1.664
    		C529.435,233.159,528.421,237.168,524.362,235.482z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M532.121,215.14c-0.436,0.277-1.407,1.289-1.82,1.098
    		c-6.804-3.154-14.084-0.826-21.031-1.973c-2.277-0.375-4.538-1.012-6.717-1.784c-2.239-0.794-4.177-2.547-2.62-4.853
    		c1.091-1.617,4.118-3.631,5.369-3.141c5.391,2.11,10.732,1.636,16.207,1.247C525.021,205.484,532.367,211.804,532.121,215.14z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M516.165,223.393c1.599-1.895,2.515-2.979,3.431-4.064
    		c1.209,1.391,2.822,2.63,3.43,4.247c0.242,0.644-1.804,3.058-2.694,2.997C519.08,226.486,517.936,224.845,516.165,223.393z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M506.58,220.813c-1.498,1.255-2.554,2.721-3.208,2.56
    		c-1.15-0.284-2.768-1.496-2.857-2.445c-0.086-0.917,1.396-2.63,2.396-2.806C503.763,217.973,504.911,219.514,506.58,220.813z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M491.849,208.148c0.841,0.987,1.705,1.57,1.65,2.048
    		c-0.065,0.56-0.871,1.141-1.486,1.458c-0.165,0.086-1.13-0.734-1.152-1.169C490.829,209.886,491.344,209.258,491.849,208.148z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M518.934,319.814c-1.795-1.301-2.829-2.05-3.863-2.798
    		c1.867-1.453,3.685-2.986,5.69-4.212c0.167-0.102,2.503,2.448,2.292,2.776C522.089,317.08,520.576,318.226,518.934,319.814z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M500.186,302.644c-0.633,1.43-0.868,3.051-1.572,3.289
    		c-0.982,0.333-2.487-0.081-3.47-0.672c-5.236-3.154-5.668-4.979-2.438-8.432c1.773-1.896,3.299-2.163,4.667,0.274
    		C498.389,298.915,499.26,300.808,500.186,302.644z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M455.514,978.585c-0.961,0.548-1.572,1.132-2.217,1.172
    		c-0.382,0.023-0.816-0.813-1.227-1.264c0.581-0.452,1.11-1.104,1.765-1.273C454.168,977.134,454.726,977.914,455.514,978.585z"/>
    	<path fillRule="evenodd" clipRule="evenodd" fill="black" d="M462.651,915.459c-0.746-0.851-1.557-1.368-1.569-1.905
    		c-0.014-0.528,0.749-1.468,1.232-1.509c0.509-0.044,1.459,0.706,1.538,1.215C463.938,913.805,463.231,914.473,462.651,915.459z"/>
    </g>
    </svg>
      {renderConfirmation()}
    </div>
   )
 }
