import React from "react";

export default function IconFace() {

return(
  <div className="svg-icon">
    <svg version="1.1" id="L2" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 70 60" xmlSpace="preserve" preserveAspectRatio="xMinYMinmeet">
      <path d="M55.7,27.09a24.38,24.38,0,0,0-1.9-3,3,3,0,0,1-.19,2.18c-2.11,3.64-15.83,1.77-16.36-1.2-.32-1.79,4.16-4.15,7.56-4.92A10.45,10.45,0,0,1,50,19.93a25.38,25.38,0,0,0-19.83-6.06,24,24,0,0,0-14.29,7.38,22.19,22.19,0,0,1,5.91,1.43c2.31.79,5.86,2,5.87,3.6,0,3.07-12.9,7.67-16.6,4.17,1.42,10.72,11,19,22.05,19.07C45.49,49.63,56.06,39.46,55.7,27.09ZM36.1,40.7c-1.94,1.2-5.1,1.55-6.81,0-2.73-2.46-.37-8.48,1.36-8.71.67-.09,1.53.66,1.89,1.4.55,1.17-.22,2.21.27,2.59s1.83,0,2.56-.88c1.61-2-.93-5.31.54-6.55a1.81,1.81,0,0,1,1.76-.26C40,29.27,40.7,37.87,36.1,40.7Z"/>
      <path d="M61.51,24.71a1.33,1.33,0,0,0-.13-.51A28.19,28.19,0,0,0,50,11C39.51,4.91,25.13,6.76,13.7,15.7a1.45,1.45,0,0,0-.18.16C6.9,22.41,4.18,31.46,6.6,39.33c4.27,13.88,21.86,15.42,23.78,15.56,10.67.77,22.2-4.44,27.78-14.45A28.41,28.41,0,0,0,61.51,24.71ZM54.38,41.78a27.31,27.31,0,0,1-24,10.82c-1.33-.11-17.6-1.69-21.39-14-2.18-7.07.27-15.21,6.21-21.1a1.54,1.54,0,0,1,.19-.17c1.76-1.53,10.87-9.11,22.85-7.66A25.63,25.63,0,0,1,49.6,13.89c2.06,1.43,8,5.59,9.22,13C60,34.07,55.9,39.69,54.38,41.78Z"/>
    </svg>
  </div>
  )

}
