import React, {useEffect} from "react";
import NavMini from './components/NavMini';
import {Redirect, useLocation} from "react-router-dom";
import {Auth} from "./components/Auth";
import { C } from './components/Common';

const puzzleCopy = {
    "headerText" : {
      "EN":"Access restricted",
      "FA":"ورود محدود است.",
      "AR":"الدخول مقيد."
    }
    ,"descText" : {
      "EN":"Follow the instructions below to continue to the next map",
      "FA":"برای راه یافتن به مرحله بعدی ، دستورالعمل های زیر را دنبال کنید.",
      "AR":"اتبعوا التعليمات أدناه للوصول إلى المستوى التالي."
    }
    ,"errorText": {
      "EN":"Answer is incorrect. Try again.",
      "FA":"رمز عبور اشتباه است. دوباره امتحان کنید.",
      "AR":"كلمة المرور خاطئة. المحاولة مرة اخرى."
    }
}

export default function Password(props){
  var r_passForm;
  var r_passInput;
  const [inputValue, setInputValue] = React.useState("");
  const [redirectToReferrer, setRedirectToReferrer] = React.useState(false);
  const { state } = useLocation()
  const [puzzleImagePath, setPuzzleImagePath] = React.useState(C.cdn + "/puzzles/" + props.match.params.id + ".png");
  const validate = () => Auth.authenticate(props.match.params.id, () => {
    setRedirectToReferrer(true)
  })

  var puzzleImageStyle = {
  backgroundImage:`url(${puzzleImagePath})`,
  };

  const checkInput = () => Auth.getPasswordMatch(props.match.params.id, inputValue, (match) => {
    console.log("callback" + match);
    if (match) {
      validate();
    } else {
      error();
    }
  })

  useEffect(() => {
    if (r_passInput != null)
      r_passInput.focus();
  });

  if (redirectToReferrer === true) {
    return <Redirect to={state?.from || '/'} />
  }

  function error() {
    r_passForm.classList.add("activeError");
  }

  function onInputChange(v) {
    r_passForm.classList.remove("activeError");
    setInputValue(v);
  }

  function onSubmit(e) {
    e.preventDefault();
    checkInput();
  }
 //↩
    return (
      <div className="frow">
        <div className="col-md-1-1">
          <div id="mainPuzzleContent" className="mainPuzzleContent">
            <form onSubmit={e => onSubmit(e)} ref={_r_passForm => (r_passForm = _r_passForm)}>
              <h3 className="rtl">{puzzleCopy.headerText.FA}</h3>
              <p className="rtl">{puzzleCopy.descText.FA}</p>
              <h3 className="rtl">{puzzleCopy.headerText.AR}</h3>
              <p className="rtl">{puzzleCopy.descText.AR}</p>

              <div className="puzzleImage" style={ puzzleImageStyle }></div>
              <div className="shakeable">
                <input type="text"
                type="password"
                autoFocus
                minLength="1"
                maxLength="30"
                onChange={e => onInputChange(e.target.value)}
                ref={_r_passInput => (r_passInput = _r_passInput)}
                value={inputValue} />
                  <button type="submit" value="Submit">
                    <span className="svg-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 60 60" xmlSpace="preserve" preserveAspectRatio="xMinYMinmeet">
                       <path d="M46.2,15.33A2.23,2.23,0,0,0,44,17.55V30.83a2.22,2.22,0,0,1-2.22,2.22H24V27.13L8.32,35.27,24,43.41V37.49H41.76a6.66,6.66,0,0,0,6.66-6.66V17.55A2.22,2.22,0,0,0,46.2,15.33Z"/>
                      </svg>
                    </span>
                </button>
              </div>
              <div className="errorMsg">
                <span style={{display:"block"}} className="rtl">{puzzleCopy.errorText.FA}</span>
                <span style={{display:"block"}} className="rtl">{puzzleCopy.errorText.AR}</span>
              </div>
            </form>
          </div>
          <NavMini />
        </div>
      </div>
    )
}
