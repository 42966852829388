import React from "react";
import {Link, useRouteMatch} from "react-router-dom";
import { Auth } from "./Auth";
import IconMonster from "./IconMonster";
import IconFace from "./IconFace";
import IconSun from "./IconSun";
import IconDeer from "./IconDeer";
import IconAbout from "./IconAbout";

function NavMini(){
  const curPath = useRouteMatch().path;
  const curId = useRouteMatch().params.id;

  const checkMapMatch = (checkFor) => {
    let mapMatch = false;
    if (curPath === "/" && checkFor===1)
        mapMatch = true;
    else if (curPath.startsWith("/level") && parseInt(curPath.slice(-1)) === checkFor)
        mapMatch = true;
    else if (curPath.startsWith("/archive") && Auth.getLevelNeeded(curId) === checkFor) {
        mapMatch = true;
	}
    return mapMatch;
  }

  return(
    <nav className="fixed">
        <ul>
        <Link to="/about" className={curPath==="/about" ? "active-tab" : "inactive-tab"}>
          <li><IconAbout/></li>
        </Link>
        <Link to="/level1" className={checkMapMatch(1) ? "active-tab" : "inactive-tab"} style={ Auth.authLevel > 0 ? { display:'inline'} : {display:'none'} } >
        <li><IconMonster/></li>
        </Link>
        <Link to="/level2" className={checkMapMatch(2) ? "active-tab" : "inactive-tab"} style={ Auth.authLevel > 1 ? { display:'inline'} : {display:'none'} } >
        <li><IconFace/></li>
        </Link>
        <Link to="/level3" className={checkMapMatch(3) ? "active-tab" : "inactive-tab"} style={ Auth.authLevel > 2 ? { display:'inline'} : {display:'none'} } >
        <li><IconSun/></li>
        </Link>
        <Link to="/level4" className={checkMapMatch(4) ? "active-tab" : "inactive-tab"} style={ Auth.authLevel > 3 ? { display:'inline'} : {display:'none'} } >
        <li><IconDeer/></li>
        </Link>
        </ul>
    </nav>
  )
}

export default NavMini
