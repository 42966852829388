import React from "react"
import NavMini from './components/NavMini'
import Monster from './components/Monster';
import Face from './components/Face';
import Sun from './components/Sun';
import Deer from './components/Deer';

function Archive(props){

  const renderMap = () => {
    if (props.match.path === "/level1" || props.match.path === "/" ){
      return <Monster/>;
    } else if (props.match.path === "/level2"){
      return <Face/>;
    } else if (props.match.path === "/level3"){
      return <Sun/>;
    } else if (props.match.path === "/level4"){
      return <Deer/>;
    } else {
      return <Monster/>;
    }
  };

  return(
    <div className="app">
      <NavMini />
      <div className="frow">
        <div className="frow centered">
          <div className="col-md-1-1">
            <div className="figure">
              {renderMap()}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Archive
