import React from "react";

export default function IconGallery() {

  return(
  <div className="svg-icon">
    <svg version="1.1" id="gallery" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    	 width="100px" height="100px" viewBox="0 0 100 100" enableBackground="new 0 0 100 100" xmlSpace="preserve">
      <g>
      	<path fillRule="evenodd" clipRule="evenodd" d="M19.246,25.181c26.189,0,52.22,0,78.347,0c0,21.557,0,43.056,0,64.642
      		c-26.11,0-52.177,0-78.347,0C19.246,68.289,19.246,46.792,19.246,25.181z M25.788,83.261c21.78,0,43.478,0,65.225,0
      		c0-17.21,0-34.338,0-51.466c-21.803,0-43.501,0-65.225,0C25.788,48.997,25.788,66.092,25.788,83.261z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M82.263,10.325c0,3.642,0,7.188,0,10.827c-2.135,0-4.204,0-6.445,0
      		c0-1.399,0-2.793,0-4.274c-21.866,0-43.561,0-65.341,0c0,17.165,0,34.293,0,51.538c1.479,0,2.889,0,4.396,0
      		c0,2.221,0,4.326,0,6.514c-3.631,0-7.223,0-10.932,0c0-21.5,0-43.001,0-64.605C29.991,10.325,56.061,10.325,82.263,10.325z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M62.422,70.761c2.963-5.118,5.809-10.034,8.792-15.19
      		c4.698,8.143,9.273,16.074,13.948,24.177c-17.909,0-35.558,0-53.54,0c6.009-10.411,11.939-20.686,17.985-31.161
      		C53.923,56.054,58.13,63.334,62.422,70.761z"/>
      	<path fillRule="evenodd" clipRule="evenodd" d="M56.045,47.93c0.008-2.938,2.398-5.339,5.328-5.354
      		c2.931-0.014,5.327,2.372,5.351,5.328c0.025,3.02-2.405,5.459-5.396,5.42C58.391,53.286,56.037,50.882,56.045,47.93z"/>
      </g>
    </svg>
  </div>
  )
}
