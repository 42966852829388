import React from "react";
import {Link} from "react-router-dom";
import Modal from 'react-modal';
import { Auth } from "./Auth";
import IconClose from "./IconClose";

const modalCopy = {
    "defaultHeaderText" : {
        "EN":"Move on to the next map?",
        "FA":" به نقشه ی بعدی بروید.",
        "AR":"الإنتقال إلى الخريطة التالية."
        }
    ,"defaultSubtitleText" : {
        "EN":"You will be able to return to this map using the nav bar.",
        "FA":"با استفاده از منوی راهبری همچنان می توانید به نقشه ی قبلی بروید.",
        "AR":"بإمكانك الوصول إلى الخريطة السابقة باستخدام شريط الملاحة"
    }
    ,"overrideModalHeaderText": {
        "EN":"Go back to the first map?",
        "FA":"به نقشه اول برگردید.",
        "AR":"الرجعة إلى الخريطة الأولى."
    }
    ,"continue": {
        "EN":"Continue",
        "FA":"ادامه دهید.",
        "AR": "متابعة."
    }
}

export default function ModalConfirm(props){
    //var headingRef;
    const [modalIsOpen,setIsOpen] = React.useState(false);
    const [tClosed,setTClosed] = React.useState(0);
    const [inputValue, setInputValue] = React.useState("");
    const [headingText, setHeadingText] = React.useState("");
    const [subtitleText, setSubtitleText] = React.useState("");

    //Todo: Need other components could call this openModal function
    function openModal() {
        setHeadingText(modalCopy.defaultHeaderText.EN);
        setSubtitleText(modalCopy.defaultSubtitleText.EN);
        setIsOpen(true);
    }

    //hack since I dont know how to let other components call openModal
    if (props.tForwardOpen > tClosed && !modalIsOpen) {
        openModal();
    }

    function getNextLevelPath() {
        return Auth.getNextLevelPathFromCurrentMap(props.curMap);
	}

    function afterOpenModal() {
    // references are now sync'd and can be accessed.
    }


    function closeModal(){
    setTClosed(Date.now());
    setIsOpen(false);
    }
    //&#10006;

    return (
            <Modal
                id="passInner"
                className="modalContent"
                overlayClassName="modalOverlay"
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                contentLabel="Example Modal"
                shouldFocusAfterRender={false}
                ariaHideApp={false} // TODO: figure out how to setAppElement for screenreaders, then set this to true
            >
                <div className="modalContentInner">
                <div id="modalClose"  onClick={closeModal}>
                <button className="modalButton">
                    <IconClose />
                </button>
                </div>
                <div>
                    <h3 className="rtl">{(props.curMap === "deer") ? modalCopy.overrideModalHeaderText.FA: modalCopy.defaultHeaderText.FA}</h3>
                    <p className="rtl">{modalCopy.defaultSubtitleText.FA}</p>
                    <h3 className="rtl">{(props.curMap === "deer") ? modalCopy.overrideModalHeaderText.AR: modalCopy.defaultHeaderText.AR}</h3>
                    <p className="rtl">{modalCopy.defaultSubtitleText.AR}</p>
                    <h3>{(props.curMap === "deer") ? modalCopy.overrideModalHeaderText.EN: modalCopy.defaultHeaderText.EN}</h3>
                    <p>{modalCopy.defaultSubtitleText.EN}</p>
                </div>

                <Link to={getNextLevelPath}>
                    <button onClick={getNextLevelPath}>
                        <span style={{display:"inline-block"}} className="rtl">{modalCopy.continue.FA}</span>&nbsp;|&nbsp;
                        <span style={{display:"inline-block"}} className="rtl">{modalCopy.continue.AR}</span>&nbsp;|&nbsp;
                        {modalCopy.continue.EN} &#10140;
                    </button>
                </Link>
                </div>
            </Modal>
    );
}
