import React, {useState, useEffect} from "react";
import { useHistory } from 'react-router-dom'
import Modal from 'react-modal';
import { C } from './Common';
import IconClose from './IconClose';

function Thumbs(props){
  const [activeIndex, setActiveIndex] = useState((props.openIndex != null) ? props.openIndex : -1);
  const [modalIsOpen,setIsOpen] = React.useState(false);
  const history = useHistory();

  let imageNameStem = "id" + props.bookId + "-p";
  let thumbPathStem = C.cdn + "/thumbs/" + imageNameStem;
  let imagePathStem = C.cdn + "/images/" + imageNameStem;
  let dImages = props.images;


  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal(){
    setActiveIndex(-1);
    setIsOpen(false);
  }

  useEffect(() => {
    const params = new URLSearchParams()
    if (activeIndex != -1) {
      params.append("i", activeIndex)
    } else {
      params.delete("i")
    }
    history.push({search: params.toString()})
  }, [activeIndex, history])

  const navCarousel = dir => {
    //safety clamp between expected numbers
    let newIndex = Math.min(activeIndex+dir,props.images.length-1);
    newIndex = Math.max(activeIndex+dir,0);

    setActiveIndex(newIndex);
  }

  const closeCarousel = () => {
    setActiveIndex(-1);
  }

  const renderModalGallery = () =>  {
    let imagePath = (activeIndex > -1) ? imagePathStem + props.images[activeIndex].page + '.jpg' : "";
    if (activeIndex > -1 && !modalIsOpen)
      openModal();

    return <Modal
          id="modalGallery"
          className="modalContent"
          overlayClassName="modalOverlay"
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          contentLabel="Gallery"
          ariaHideApp={false} // TODO: figure out how to setAppElement for screenreaders, then set this to true
        >
          <div id="galleryInner" className="modalContentInner" style={{maxWidth:window.innerHeight,height:"90vh"}}>
            <div id="modalClose"  onClick={closeModal}>
              <button className="modalButton"><IconClose /></button>
            </div>
            <div id="carousel">
              <div id="prevCarousel" style={(activeIndex) === 0 ? {display:'none'} : {display:'block'} } onClick={() => navCarousel(-1) }>
                <button className="modalButton">❮</button>
              </div>
              <div id="activeImage">
                <span className="helper"></span>
                <img src={imagePath} />
              </div>
              <div id="nextCarousel" style={(activeIndex+1) === props.images.length ? {display:'none'} : {display:'block'} } onClick={() => navCarousel(1) }>
                <button className="modalButton">❯</button>
              </div>
            </div>
          </div>
        </Modal>
  }

  const renderThumbs = () => dImages.map((d,i)=>{
    return <div key={i} className="thumb ltr">
            <div className="imgWrapper" onClick={() => setActiveIndex(i) }>
              <img src={thumbPathStem + d.page +'.jpg'} alt={'Image on page ' + d.page} />
            </div>
            <span className="thumbSize">{d.size+' KB'}</span>
           </div>
  })

  return(
    <div>
      {renderThumbs()}
      {renderModalGallery()}
    </div>
  )
}

export default Thumbs
