import React, {useState} from "react";
import { Link, useRouteMatch } from "react-router-dom";
import ModalConfirm from './ModalConfirm';

export default function Face() {
  const curPathID = parseInt(useRouteMatch().params.id, 10);
  const [activeIndex, setActiveIndex] = useState(curPathID);
  const [tOpenModalClickTimestamp, setTOpenModalClick] = useState(0);

  const handleOnClick = index => {
    setActiveIndex(index);
  };

  const tryNextLevel = () => {
     setTOpenModalClick(Date.now());
  }

  const renderConfirmation = () => {
    return <ModalConfirm tForwardOpen={tOpenModalClickTimestamp} curMap={"face"} />
  }

  return(
    <div className="frow centered">
    <svg version="1.1" id="face" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="1008px" height="1040px" viewBox="0 200 1008 1040" enableBackground="new 0 0 1008 1040" xmlSpace="preserve">
       <g>
       	<path className="figure-outline" fillRule="evenodd" clipRule="evenodd" d="M800.09,864.441c-15.258,3.645-30.258,3.74-45.271,3.571
       		c-21.851-0.244-43.702-0.59-65.554-0.793c-1.823-0.016-3.651,0.725-5.478,1.116c0.598,2.598,0.614,5.573,1.975,7.679
       		c1.137,1.765,3.756,2.717,5.881,3.659c2.632,1.168,5.517,1.77,8.145,2.943c6.221,2.785,6.265,7.688,5.155,12.923
       		c-0.156,0.734-2.014,1.736-2.905,1.582c-4.255-0.731-8.452-1.808-12.662-2.785c-5.104-1.183-10.165-3.06-15.314-3.331
       		c-2.988-0.159-6.607,1.636-9.065,3.633c-3.869,3.143-2.708,8.686,2.043,10.402c2.872,1.037,6.149,1.202,9.257,1.294
       		c6.398,0.183,12.806,0.055,19.205,0.055c-0.241,5.087-2.104,7.797-7.136,8.818c-4.618,0.939-9.395,1.073-14.074,1.757
       		c-2.839,0.417-5.755,0.872-8.401,1.908c-3.479,1.362-3.879,4.09-0.667,6.027c2.693,1.625,6.047,2.342,9.204,2.968
       		c3.209,0.636,6.617,0.328,9.787,1.072c2.443,0.574,5.133,1.654,6.865,3.367c3.353,3.316,2.048,7.583-2.513,8.314
       		c-5.035,0.808-10.197,0.819-15.298,1.24c-4.055,0.334-8.143,0.527-12.126,1.267c-1.507,0.28-3.977,2.035-3.891,2.912
       		c0.204,2.122,1.137,4.848,2.734,5.986c1.967,1.402,4.898,1.929,7.428,1.969c5.779,0.093,11.567-0.419,17.354-0.519
       		c2.084-0.037,4.296,0.057,6.229,0.724c1.705,0.589,4.292,1.978,4.375,3.159c0.132,1.9-0.96,4.726-2.476,5.704
       		c-2.406,1.556-5.539,2.306-8.454,2.673c-3.635,0.456-7.37,0.03-11.045,0.26c-2.865,0.179-5.762,0.555-8.543,1.25
       		c-2.881,0.724-4.929,2.589-4.918,5.86c0.009,3.384,1.596,6.036,4.867,6.869c3.015,0.769,6.229,1.064,9.347,1.028
       		c5.653-0.065,11.299-0.568,16.95-0.804c1.396-0.058,2.806,0.217,4.324,0.35c0.245,4.981-0.629,10.179,5.943,12.109
       		c-4.778,3.82-9.887,5.347-15.113,6.387c-7.479,1.49-15.05,2.513-22.519,4.045c-11.484,2.355-19.631,8.845-24.297,19.811
       		c-4.821,11.332-8.219,23.014-9.728,35.226c-1.771,14.331,7.558,26.147,22.765,27.789c20.918,2.257,39.148-4.441,54.784-18.313
       		c3.774-3.347,4.265-6.362,1.96-10.896c-1.111-2.184-2.855-4.059-4.398-6.005c-1.465-1.851-3.457-3.404-4.497-5.454
       		c-3.054-6.027-3.886-10.57,3.242-14.958c12.549-7.724,26.537-5.228,35.149,6.827c10.043,14.057,8.874,33.48-2.823,47.15
       		c-11.893,13.896-27.369,21.256-45.133,23.565c-27.292,3.551-52.328-2.48-74.199-19.659c-3.694-2.902-6.69-6.682-10.079-9.982
       		c-3.136-3.051-3.516-6.49-2.791-10.752c4.223-24.851,15.9-45.758,33.067-63.862c1.72-1.815,3.618-3.487,5.152-5.444
       		c0.995-1.271,2.474-3.615,2.005-4.305c-1.192-1.757-3.223-3.711-5.162-4.012c-2.727-0.424-5.69,0.482-8.52,0.997
       		c-2.584,0.471-5.1,1.315-7.686,1.745c-3.02,0.503-5.727-0.112-7.713-2.79c-3.044-4.104-2.628-7.572,2.116-9.592
       		c4.911-2.093,10.255-3.157,15.372-4.791c1.223-0.39,3.268-1.29,3.244-1.889c-0.061-1.539-0.683-3.496-1.797-4.446
       		c-1.406-1.199-3.486-1.68-5.342-2.261c-2.126-0.667-4.686-0.589-6.393-1.783c-1.823-1.276-3.912-3.629-3.968-5.566
       		c-0.058-1.953,1.851-4.494,3.616-5.819c1.892-1.422,4.658-1.635,6.976-2.547c4.389-1.726,4.979-3.482,2.448-7.486
       		c-1.047-1.657-2.62-2.987-3.616-4.667c-1.384-2.328-2.827-4.74-3.547-7.312c-0.762-2.722,0.122-5.047,2.534-7.242
       		c10.419-9.483,16.998-21.386,20.159-35.034c0.875-3.778,2.692-5.648,6.139-6.646c3.911-1.131,7.877-2.188,11.615-3.761
       		c2.528-1.064,4.705-2.965,7.039-4.489c-0.144-0.412-0.286-0.825-0.429-1.239c-7.406-0.911-14.788-2.29-22.22-2.65
       		c-26.279-1.271-52.568-2.826-78.866-3.086c-32.506-0.322-65.025,0.669-97.541,0.834c-13.418,0.069-26.84-0.578-40.261-0.771
       		c-1.607-0.022-3.424,0.427-4.803,1.238c-4.645,2.728-9.05,5.868-13.707,8.573c-6.988,4.06-14.112,7.887-21.398,11.936
       		c2.245,3.338,6.181,5.017,10.621,4.79c6.549-0.334,13.057-1.415,19.602-1.928c4.564-0.357,9.183-0.636,13.735-0.329
       		c4.232,0.285,8.443,3.642,8.558,5.948c0.148,3.001-3.364,7.026-7.956,7.646c-5.684,0.765-11.497,0.682-17.256,0.743
       		c-5.391,0.06-10.798-0.43-16.173-0.17c-2.799,0.134-5.663,1.082-8.282,2.179c-2.751,1.153-4.343,3.37-3.482,6.66
       		c0.825,3.154,2.82,5.418,6.054,5.236c5.351-0.303,10.654-1.379,16-1.878c4.287-0.401,8.66-0.904,12.893-0.438
       		c2.249,0.247,5.643,2.088,6.124,3.861c0.942,3.468-0.474,7.675-4.162,8.908c-4.869,1.63-10.157,2.623-15.278,2.681
       		c-3.726,0.04-5.714,1.068-5.854,4.46c-0.188,4.535-0.198,9.163,0.49,13.631c2.226,14.456,2.719,12.01-9.275,15.537
       		c-2.594,0.763-4.919,2.438-8.224,4.133c8.917,6.562,19.746,3.603,28.328,10.104c-4.576,4.019-8.355,8.399-13.062,11.252
       		c-10.551,6.398-21.639,11.904-32.356,18.038c-5.691,3.26-11.387,6.663-16.517,10.717c-11.892,9.398-15.765,22.177-13.075,36.692
       		c2.651,14.301,12.561,22.422,25.797,26.652c15.954,5.098,31.987,4.185,47.748-0.968c7.229-2.364,13.564-6.413,17.969-12.903
       		c4.495-6.623,3.676-13.226-2.738-18.034c-3.418-2.562-7.442-4.313-11.306-6.493c0.695-13.487,9.797-20.658,23.069-17.587
       		c9.738,2.253,17.565,7.687,23.228,15.896c1.213,1.759,1.566,4.441,1.523,6.684c-0.381,19.816-9.935,34.234-26.777,43.982
       		c-17.841,10.328-37.308,13.038-57.544,11.258c-13.671-1.203-26.628-4.89-38.349-12.252c-22.791-14.317-27.38-36.901-21.82-55.475
       		c2.756-9.204,9.166-15.857,16.327-21.812c9.709-8.073,19.533-16.01,29.281-24.039c0.996-0.819,1.802-1.867,2.65-2.759
       		c-2.441-4.817-6.249-6.914-12.11-6.123c-7.678,1.039-15.3,2.487-22.959,3.68c-1.409,0.218-2.887,0.154-4.318,0.039
       		c-5.416-0.434-6.171-1.668-4.542-6.986c1.3-4.242,4.382-6.743,8.472-7.373c6.084-0.937,12.274-1.177,18.41-1.798
       		c3.918-0.397,7.884-0.659,11.714-1.501c5.337-1.173,8.895-4.855,8.427-8.04c-0.465-3.17-4.131-5.161-10.025-5.157
       		c-3.679,0.003-7.355,0.405-11.037,0.495c-1.829,0.047-3.689-0.067-5.496-0.357c-3.097-0.498-5.512-1.993-5.887-5.419
       		c-0.395-3.592,0.688-7.015,4.277-8.01c4.102-1.134,8.499-1.418,12.788-1.611c5.757-0.259,11.546,0.146,17.297-0.172
       		c3.262-0.181,7.1-1.889,6.73-5.294c-0.264-2.435-3.396-5.549-5.943-6.494c-3.235-1.202-7.207-0.899-10.795-0.553
       		c-4.561,0.441-9.028,1.779-13.584,2.356c-4.242,0.537-8.052-0.584-10.894-4.576c15.158-15.58,29.693-31.586,41.482-50.522
       		c-7.044-0.74-13.559-1.373-20.062-2.119c-19.476-2.236-38.927-4.703-58.425-6.729c-25.158-2.619-50.361-4.435-75.627-1.275
       		c-13.8,1.724-27.086,5.119-39.455,12.86c0.798-8.473,1.771-16.386,2.247-24.328c1.241-20.751,2.947-41.505,3.24-62.275
       		c0.689-48.845,0.542-97.7,0.967-146.549c0.32-36.72,1.25-73.433,5.24-109.975c1.428-13.068,3.664-26.047,5.437-39.08
       		c0.317-2.33,1.412-3.265,3.66-3.843c14.753-3.796,29.787-5.614,44.948-5.369c29.068,0.468,58.123,1.749,87.191,2.166
       		c20.276,0.291,40.565-0.37,60.104-6.897c13.08-4.369,24.206-11.489,31.882-23.286c0.835-1.283,1.576-2.627,2.389-3.993
       		c-4.271-2.261-8.345-4.098-12.091-6.458c-16.905-10.649-25.465-26.816-28.184-45.911c-1.659-11.646-1.688-23.597-1.584-35.402
       		c0.104-11.633,6.858-20.572,14.397-28.606c17.787-18.958,40.296-29.64,65.166-35.694c23.055-5.614,46.231-6.471,68.862,1.677
       		c30.677,11.044,48.547,33.391,54.01,65.252c2.607,15.214-2.046,29.452-9.49,42.753c-7.456,13.327-17.374,24.737-27.888,35.678
       		c-1.721,1.791-3.428,3.596-5.675,5.957c2.893,0.279,5.159,0.462,7.416,0.722c15.49,1.779,28.461,8.86,39.964,19.027
       		c8.184,7.233,16.407,14.418,24.548,21.7c3.227,2.887,6.906,3.959,11.183,3.904c24.215-0.312,48.435-0.417,72.647-0.796
       		c24.752-0.387,49.514-0.966,74.031,3.308c6.841,1.193,13.52,3.312,20.288,4.94c2.229,0.537,3.242,1.633,3.23,4.092
       		c-0.138,31.188,0.323,62.388-0.408,93.561c-0.669,28.536-2.622,57.04-3.971,85.562c-1.557,32.857-3.445,65.705-4.477,98.579
       		c-0.729,23.278-0.272,46.592-0.346,69.89C800.087,861.072,800.09,862.629,800.09,864.441z M567.761,736
       		c-2.513,0-4.345,0.025-6.179-0.004c-17.751-0.286-35.52-0.188-53.251-0.959c-30.341-1.322-60.666-3.433-91.008-0.51
       		c-8.822,0.852-17.567,2.514-26.488,3.826c0.142,39.508-0.998,78.254,2.996,116.986c1.103,0,1.612-0.004,2.121,0
       		c24.864,0.192,49.729,0.387,74.593,0.578c31.436,0.242,62.874,0.641,94.192-2.968c2.087-0.239,3.023-0.938,3.268-3.015
       		c0.928-7.949,2.58-15.867,2.819-23.832c0.452-15.097,0.52-30.233-0.01-45.326C570.294,765.962,568.836,751.183,567.761,736z
       		 M785.095,747.813c-12.232-1.091-24.145-2.072-36.039-3.229c-29.69-2.888-59.332-6.405-89.072-8.58
       		c-17.538-1.282-35.228-0.479-52.847-0.624c-3.109-0.025-4.674,1.158-5.538,4.503c-8.409,32.517-10.321,65.604-9.043,98.983
       		c0.173,4.548,0.895,9.074,1.39,13.881c12.612,0.543,24.809,1.464,37.01,1.506c20.782,0.074,41.58-0.032,62.348-0.743
       		c25.616-0.875,51.2-2.738,76.816-3.499c5.943-0.177,8.395-2.719,10.396-7.343c3.994-9.232,5.231-19.008,5.288-28.885
       		c0.071-12.491-0.521-24.985-0.685-37.48C784.995,766.853,785.095,757.399,785.095,747.813z M758.111,613.604
       		c0,0.047,0,0.093,0.002,0.14c8.951,0,17.904-0.073,26.854,0.05c2.369,0.032,3.568-0.594,3.983-3.069
       		c3.328-19.852,6.488-39.716,5.932-59.951c-0.317-11.529-1.597-22.825-7.537-33.092c-1.265-2.184-2.791-2.968-5.375-3.002
       		c-13.155-0.177-26.312-0.51-39.461-0.962c-26.556-0.912-53.102-2.363-79.665-2.768c-18.151-0.277-36.241,1.501-53.956,6.035
       		c-2.381,0.61-3.638,1.721-4.243,3.98c-1.427,5.327-3.273,10.566-4.349,15.959c-4.571,22.96-3.863,46.014-1.166,69.141
       		c0.498,4.275,2.03,4.945,5.815,4.96c17.501,0.067,35.004,0.396,52.504,0.708C691.004,612.329,724.557,612.976,758.111,613.604z
       		 M215.699,852.182c0.875-0.049,2.286-0.123,3.697-0.207c8.786-0.52,17.617-1.864,26.352-1.403
       		c24.417,1.289,48.786,3.478,73.193,5.015c16.816,1.059,33.652,1.29,50.354-1.717c7.06-1.272,6.961-1.26,6.764-8.315
       		c-0.833-29.723-1.643-59.444-2.365-89.168c-0.188-7.722-0.029-15.452-0.029-23.368c-51.374,0-102.019,0-152.858,0
       		c-0.239,1.389-0.588,2.665-0.66,3.956c-1.033,18.505-2.261,37.003-2.967,55.521C216.427,812.199,216.18,831.923,215.699,852.182z
       		 M388.069,607.902c64.823,4.664,129.387,4.88,194.225-0.037c-1.17-31.331,1.641-62.136,0.051-93.112c-2.7,0-4.668,0.04-6.634-0.006
       		c-23.277-0.543-46.551-1.333-69.831-1.593c-28.667-0.322-57.338-0.348-86.006-0.194c-8.606,0.046-17.207,1.12-25.814,1.723
       		C390.268,545.871,382.491,576.152,388.069,607.902z M391.642,718.142c0.269,0.046,1.019,0.26,1.779,0.298
       		c21.404,1.057,42.798,2.488,64.215,3.032c23.8,0.604,47.629,0.849,71.429,0.384c16.532-0.321,33.033-2.187,49.559-3.161
       		c2.936-0.174,3.745-1.064,3.734-3.955c-0.102-26.73-0.076-53.46-0.032-80.19c0.005-2.261-0.346-3.359-3.071-3.81
       		c-31.108-5.146-62.512-6.229-93.93-6.709c-18.545-0.284-37.104,0.383-55.658,0.521c-12.875,0.096-25.752,0.019-38.494,0.019
       		c-3.209,11.745-3.063,22.634-2.342,33.549c0.893,13.508,2.008,27.003,2.75,40.519C391.933,705.037,391.642,711.473,391.642,718.142
       		z M597.702,718.416c1.34,0,2.889-0.069,4.433,0.01c16.669,0.86,33.367,1.399,50.004,2.698c27.247,2.127,54.421,5.311,81.693,6.954
       		c15.158,0.913,30.45-0.341,45.678-0.798c1.087-0.033,2.877-1.391,3.064-2.376c0.837-4.378,1.374-8.833,1.716-13.283
       		c1.071-13.888,2.262-27.775,2.903-41.685c0.453-9.804,0.088-19.646,0.088-29.765c-2.785-0.268-6.183-0.556-9.571-0.928
       		c-17.763-1.954-35.497-4.203-53.289-5.826c-21.589-1.97-43.203-4.193-64.851-4.847c-17.805-0.537-35.684,0.874-53.51,1.801
       		c-6.548,0.341-6.449,0.85-7.424,7.385c-3.946,26.461-0.909,53.047-1.469,79.567C597.163,717.546,597.379,717.771,597.702,718.416z
       		 M220.999,506.549c0,2.285,0.039,4.631-0.007,6.977c-0.188,9.598-0.32,19.198-0.623,28.792
       		c-0.498,15.767-1.342,31.528-1.562,47.298c-0.084,6.054,1.143,12.126,1.779,18.253c50.327,3.578,99.859,6.776,149.609,0.045
       		c0-1.527,0.08-2.567-0.012-3.592c-1.202-13.462,0.394-26.739,2.214-40.056c1.724-12.61,3.11-25.289,4.008-37.983
       		c0.609-8.614,0.116-8.649-8.484-10.803c-26.597-6.66-53.804-7.557-80.996-8.463c-11.043-0.368-22.101-0.38-33.151-0.453
       		C243.002,506.493,232.229,506.549,220.999,506.549z M424.856,352.661c9.121,0,18.441-0.371,27.72,0.094
       		c11.675,0.586,20.832,5.806,26.606,16.36c1.164,2.127,0.906,3.55-0.824,5.13c-4.247,3.878-9.462,5.174-14.91,5.301
       		c-9.735,0.228-19.516-0.445-29.205,0.255c-6.287,0.454-12.495,2.496-18.643,4.177c-3.327,0.909-3.957,3.362-3.132,6.81
       		c3.262,13.628,10.155,25.079,20.479,34.367c20.137,18.117,44.274,25.894,70.963,27.462c22.732,1.336,40.761-6.403,54.127-25.126
       		c4.799-6.719,9.834-13.645,16.132-18.805c6.216-5.095,8.934-10.37,8.168-18.15c-0.705-7.144-0.635-14.361-1.264-21.515
       		c-0.251-2.846-2.178-3.724-5.145-2.647c-7.773,2.825-15.575,5.61-23.487,8.018c-6.967,2.121-14.26,3.67-21.196,0.392
       		c-3.215-1.518-7.369-4.167-8.111-7.068c-1.152-4.499,2.459-8.283,6.506-10.754c1.676-1.023,3.491-1.825,5.277-2.659
       		c2.736-1.278,5.624-2.284,8.217-3.802c2.285-1.338,4.254-3.218,6.621-5.059c-9.636-4.942-11.088-6.472-15.644-16.118
       		c-0.393-0.831-0.894-1.638-1.12-2.515c-0.654-2.526-2.445-3.461-4.813-3.949c-12.147-2.506-24.284-3.941-36.783-2.677
       		C464.893,322.862,443.541,335.572,424.856,352.661z M369.634,625.498c-1.027,0-2.305,0.019-3.583-0.003
       		c-20.239-0.349-40.5-0.233-60.711-1.186c-22.467-1.06-44.891-3.244-67.399-1.267c-5.679,0.499-11.324,1.384-16.554,2.038
       		c0,5.655,0.094,10.512-0.017,15.364c-0.368,16.045-1.002,32.085-1.15,48.132c-0.087,9.484,1.074,18.869,4.297,27.929
       		c1.212,3.409,2.795,4.903,6.749,4.828c17.901-0.338,35.811-0.354,53.717-0.345c27.52,0.016,55.038,0.125,82.556,0.279
       		c2.239,0.012,3.516-0.715,3.923-2.865c1.395-7.333,3.776-14.655,3.902-22.013c0.185-10.863-1.098-21.765-2.021-32.628
       		C372.265,651.083,370.9,638.433,369.634,625.498z M411.113,500.271c3.439,0,5.65,0.041,7.859-0.007
       		c8.022-0.174,16.044-0.608,24.062-0.527c21.579,0.218,43.156,0.92,64.733,0.92c20,0,40.005-0.503,59.998-1.107
       		c7.647-0.231,15.265-1.357,23.781-2.158c-1.611-1.738-2.434-2.715-3.347-3.597c-10.2-9.846-22.341-16.148-36.084-19.307
       		c-16.271-3.74-32.79-4.22-49.413-3.544c-20.504,0.833-41.021,1.355-61.528,2.129c-1.211,0.046-2.555,0.939-3.54,1.785
       		c-3.074,2.638-5.883,5.592-9.022,8.144c-3.869,3.145-8.204,5.748-11.875,9.093C414.558,494.082,413.269,497.048,411.113,500.271z"
       		/>
      <Link to={"/archive/016"} className={activeIndex === 16 ? "active" : "inactive"} onClick={() => handleOnClick(16) } >
       	<path className="clickable reverse" fillRule="evenodd" clipRule="evenodd" fill="#000" d="M567.761,736c1.075,15.183,2.533,29.962,3.053,44.777
       		c0.529,15.093,0.462,30.229,0.01,45.326c-0.239,7.965-1.892,15.883-2.819,23.832c-0.244,2.077-1.181,2.775-3.268,3.015
       		c-31.318,3.608-62.757,3.21-94.192,2.968c-24.864-0.191-49.729-0.386-74.593-0.578c-0.509-0.004-1.018,0-2.121,0
       		c-3.993-38.732-2.854-77.479-2.996-116.986c8.921-1.312,17.666-2.975,26.488-3.826c30.342-2.923,60.667-0.812,91.008,0.51
       		c17.731,0.771,35.5,0.673,53.251,0.959C563.416,736.025,565.248,736,567.761,736z M474.576,763.051
       		c-2.69,16.785-4.925,33.454-2.405,50.32c1.123,7.522,3.237,14.729,10.107,19.284c3.667,2.432,4.636,2.135,6.591-1.751
       		c4.309-8.565,4.985-17.774,3.461-26.941c-1.891-11.376-4.596-22.622-7.122-33.886C483.431,762.155,483.302,762.185,474.576,763.051
       		z"/>
      </Link>
      <Link to={"/archive/020"} className={activeIndex === 20 ? "active" : "inactive"} onClick={() => handleOnClick(20) } >
       	<path className="clickable reverse" fillRule="evenodd" clipRule="evenodd" fill="#000" d="M785.095,747.813c0,9.586-0.1,19.039,0.023,28.489
       		c0.164,12.495,0.756,24.989,0.685,37.48c-0.057,9.877-1.294,19.652-5.288,28.885c-2.001,4.624-4.452,7.166-10.396,7.343
       		c-25.616,0.761-51.2,2.624-76.816,3.499c-20.768,0.711-41.565,0.817-62.348,0.743c-12.201-0.042-24.397-0.963-37.01-1.506
       		c-0.495-4.807-1.217-9.333-1.39-13.881c-1.278-33.379,0.634-66.467,9.043-98.983c0.864-3.345,2.429-4.528,5.538-4.503
       		c17.619,0.145,35.309-0.658,52.847,0.624c29.74,2.175,59.382,5.692,89.072,8.58C760.95,745.741,772.862,746.723,785.095,747.813z
       		 M672.035,834.276c1.457-0.22,3.133-0.096,4.438-0.734c6.801-3.33,14.012-6.146,20.134-10.476
       		c10.255-7.255,21.09-13.251,32.273-18.876c6.493-3.266,12.264-7.979,18.314-12.106c2.384-1.628,2.386-3.842,0.423-5.634
       		c-1.987-1.814-4.204-3.864-6.67-4.555c-3.465-0.97-7.278-1.211-10.899-0.996c-8.257,0.492-16.481,1.923-24.729,2.013
       		c-3.797,0.04-8.202-1.349-11.307-3.539c-6.104-4.309-5.104-8.98,1.842-11.481c1.354-0.487,2.782-0.772,4.183-1.125
       		c4.076-1.025,8.2-1.884,12.217-3.103c3.394-1.028,6.011-3.144,7.132-6.694c-4.004-3.811-8.871-5.881-14.201-6.074
       		c-13.112-0.475-25.939,8.381-31.455,21.718c-4.271,10.33-2.344,17.289,6.381,24.27c2.734,2.189,5.504,4.606,7.431,7.474
       		c3.406,5.067,2.396,9.015-2.641,12.474c-2.594,1.782-5.5,3.102-8.121,4.846C672.203,824.724,670.674,828.762,672.035,834.276z"/>
      </Link>
      <Link to={"/archive/014"} className={activeIndex === 14 ? "active" : "inactive"} onClick={() => handleOnClick(14) } >
       	<path className="clickable reverse" fillRule="evenodd" clipRule="evenodd" fill="#000" d="M758.111,613.604c-33.555-0.627-67.107-1.274-100.662-1.872
       		c-17.5-0.311-35.003-0.64-52.504-0.708c-3.785-0.015-5.317-0.685-5.815-4.96c-2.697-23.126-3.405-46.18,1.166-69.141
       		c1.075-5.394,2.922-10.632,4.349-15.959c0.605-2.26,1.862-3.37,4.243-3.98c17.715-4.534,35.805-6.312,53.956-6.035
       		c26.563,0.405,53.109,1.855,79.665,2.768c13.149,0.452,26.306,0.785,39.461,0.962c2.584,0.034,4.11,0.819,5.375,3.002
       		c5.94,10.267,7.22,21.562,7.537,33.092c0.557,20.235-2.604,40.099-5.932,59.951c-0.415,2.476-1.614,3.102-3.983,3.069
       		c-8.949-0.123-17.902-0.05-26.854-0.05C758.111,613.696,758.111,613.65,758.111,613.604z M731.443,539.81
       		c-0.467-0.482-1.137-1.616-2.125-2.108c-3.436-1.714-6.902-1.378-10.176,0.542c-2.948,1.729-5.746,3.729-8.752,5.346
       		c-9.296,4.997-19.058,2.898-25.66-5.265c-1.218-1.507-2.601-3.073-4.251-3.997c-2.976-1.666-5.797,0.443-5.449,3.846
       		c0.294,2.879,0.515,5.778,1.039,8.618c2.026,10.967,3.795,22,6.397,32.832c1.492,6.201,5.21,11.585,10.739,15.129
       		c2.55,1.635,5.665,2.389,8.523,3.542c0.968-3.065,2.58-6.092,2.772-9.206c0.404-6.551,0.233-13.155-0.046-19.721
       		c-0.12-2.855,1.008-4.161,3.385-5.37c5.146-2.617,10.31-5.278,15.126-8.441C728.211,552.113,730.742,546.79,731.443,539.81z"/>
      </Link>
      <Link to={"/archive/018"} className={activeIndex === 18 ? "active" : "inactive"} onClick={() => handleOnClick(18) } >
       	<path className="clickable reverse" fillRule="evenodd" clipRule="evenodd" fill="#000" d="M215.699,852.182c0.481-20.259,0.729-39.982,1.48-59.687
       		c0.706-18.519,1.935-37.017,2.967-55.521c0.072-1.291,0.421-2.567,0.66-3.956c50.84,0,101.484,0,152.858,0
       		c0,7.916-0.159,15.646,0.029,23.368c0.723,29.724,1.532,59.445,2.365,89.168c0.198,7.056,0.296,7.043-6.764,8.315
       		c-16.702,3.007-33.538,2.775-50.354,1.717c-24.408-1.537-48.776-3.726-73.193-5.015c-8.735-0.461-17.566,0.884-26.352,1.403
       		C217.985,852.059,216.574,852.133,215.699,852.182z M260.014,827.74c0.235,0.457,0.47,0.915,0.706,1.372
       		c3.928-1.602,8.243-2.626,11.684-4.946c4.312-2.911,7.836-6.968,11.836-10.371c2.991-2.547,6.129-4.935,9.324-7.225
       		c3.497-2.505,6.733-1.847,9.114,1.622c3.338,4.862,6.461,9.873,9.63,14.849c3.449,5.417,6.553,11.084,10.367,16.228
       		c2.621,3.533,6.448,5.903,11.593,6.802c-6.095-26.623-11.852-52.72-23.849-77.306C288.043,784.025,270.454,802.829,260.014,827.74z
       		"/>
      </Link>
      <Link to={"/archive/022"} className={activeIndex === 22 ? "active" : "inactive"} onClick={() => handleOnClick(22) } >
       	<path className="clickable reverse" fillRule="evenodd" clipRule="evenodd" fill="#000" d="M388.069,607.902c-5.578-31.75,2.199-62.031,5.991-93.219
       		c8.607-0.604,17.208-1.677,25.814-1.723c28.667-0.154,57.339-0.128,86.006,0.194c23.28,0.26,46.554,1.049,69.831,1.593
       		c1.966,0.046,3.934,0.006,6.634,0.006c1.59,30.976-1.221,61.781-0.051,93.112C517.456,612.782,452.892,612.566,388.069,607.902z
       		 M519.781,595.572c-0.212-2.763,0.18-5.445-0.714-7.591c-1.743-4.184-3.897-8.278-6.444-12.024
       		c-3.714-5.459-5.751-10.917-4.33-17.738c0.868-4.158,0.642-8.67,0.207-12.952c-1.119-11.003-8.632-19.381-18.337-21.02
       		c-10.341-1.747-20.262,3.817-24.912,13.972c-3.925,8.571-2.564,13.265,5.375,18.571c5.752,3.845,12.273,5.265,19.01,5.812
       		c2.226,0.181,3.002,1.094,3.591,3.196c1.484,5.288,2.843,10.717,5.208,15.63C502.528,589.944,509.56,594.753,519.781,595.572z"/>
      </Link>
      <Link to={"/archive/013"} className={activeIndex === 13 ? "active" : "inactive"} onClick={() => handleOnClick(13) } >
       	<path className="clickable reverse" fillRule="evenodd" clipRule="evenodd" fill="#000" d="M391.642,718.142c0-6.669,0.291-13.104-0.061-19.503
       		c-0.742-13.516-1.857-27.011-2.75-40.519c-0.722-10.915-0.868-21.804,2.342-33.549c12.742,0,25.618,0.078,38.494-0.019
       		c18.553-0.138,37.112-0.805,55.658-0.521c31.418,0.48,62.822,1.563,93.93,6.709c2.726,0.451,3.076,1.548,3.071,3.81
       		c-0.044,26.729-0.069,53.46,0.032,80.19c0.011,2.891-0.799,3.781-3.734,3.955c-16.525,0.974-33.026,2.84-49.559,3.161
       		c-23.799,0.465-47.628,0.22-71.429-0.384c-21.417-0.544-42.812-1.975-64.215-3.032C392.661,718.402,391.91,718.188,391.642,718.142
       		z M526.932,710.402c-6.175-26.749-11.875-52.894-23.919-77.492c-22.43,15.302-40.049,34.14-50.102,59.738
       		c3.872-0.863,7.895-1.599,11.009-3.69c4.425-2.971,8.069-7.079,12.258-10.439c3.865-3.1,7.973-5.905,12.044-8.739
       		c1.904-1.325,3.873-0.957,5.233,0.84c3.393,4.48,6.836,8.947,9.886,13.66c3.844,5.94,7.049,12.303,11.04,18.136
       		C517.16,706.479,521.39,709.022,526.932,710.402z"/>
      </Link>
      <Link to={"/archive/019"} className={activeIndex === 19 ? "active" : "inactive"} onClick={() => handleOnClick(19) } >
       	<path className="clickable reverse" fillRule="evenodd" clipRule="evenodd" fill="#000" d="M597.702,718.416c-0.323-0.644-0.539-0.87-0.534-1.091
       		c0.56-26.521-2.478-53.106,1.469-79.567c0.975-6.536,0.876-7.044,7.424-7.385c17.826-0.928,35.705-2.338,53.51-1.801
       		c21.647,0.654,43.262,2.877,64.851,4.847c17.792,1.623,35.526,3.873,53.289,5.826c3.389,0.372,6.786,0.66,9.571,0.928
       		c0,10.119,0.365,19.961-0.088,29.765c-0.642,13.91-1.832,27.797-2.903,41.685c-0.342,4.45-0.879,8.905-1.716,13.283
       		c-0.188,0.985-1.978,2.343-3.064,2.376c-15.228,0.457-30.52,1.711-45.678,0.798c-27.272-1.644-54.446-4.827-81.693-6.954
       		c-16.637-1.299-33.335-1.838-50.004-2.698C600.591,718.347,599.042,718.416,597.702,718.416z M685.114,706.018
       		c2.393-0.524,6.005-1.085,9.477-2.115c23.42-6.947,33.962-26.729,45.802-45.56c-7.313-3.57-13.742-2.835-19.488,2.14
       		c-3.577,3.097-6.558,6.921-10.29,9.788c-4.102,3.149-8.634,5.806-13.215,8.23c-2.845,1.504-5.396,0.588-7.396-2.345
       		c-3.406-4.997-6.991-9.897-10.838-14.56c-4.183-5.068-9.498-8.43-16.585-9.135c0.117,2.521,0.146,4.748,0.335,6.96
       		c0.839,9.818,5.968,17.764,10.987,25.86C677.812,691.59,681.003,698.343,685.114,706.018z"/>
      </Link>
      <Link to={"/archive/015"} className={activeIndex === 15 ? "active" : "inactive"} onClick={() => handleOnClick(15) } >
       	<path className="clickable reverse" fillRule="evenodd" clipRule="evenodd" fill="#000" d="M220.999,506.549c11.23,0,22.003-0.056,32.776,0.016
       		c11.051,0.073,22.108,0.085,33.151,0.453c27.192,0.906,54.399,1.803,80.996,8.463c8.6,2.154,9.093,2.189,8.484,10.803
       		c-0.897,12.694-2.284,25.373-4.008,37.983c-1.82,13.316-3.417,26.593-2.214,40.056c0.092,1.025,0.012,2.065,0.012,3.592
       		c-49.75,6.731-99.283,3.533-149.609-0.045c-0.636-6.126-1.863-12.199-1.779-18.253c0.22-15.77,1.063-31.531,1.562-47.298
       		c0.303-9.594,0.435-19.194,0.623-28.792C221.038,511.18,220.999,508.833,220.999,506.549z M304.637,601.773
       		c0.17-2.292,0.646-4.046,0.349-5.66c-0.731-3.988-1.79-7.917-2.764-11.859c-1.597-6.457-0.183-9.259,6.003-11.604
       		c1.223-0.464,2.503-0.875,3.792-1.046c6.123-0.81,12.342-1.135,18.362-2.406c4.508-0.953,8.957-2.725,13.038-4.879
       		c1.471-0.777,2.836-4.046,2.365-5.611c-0.545-1.815-2.976-3.859-4.922-4.291c-3.93-0.871-8.092-0.667-12.149-1.009
       		c-2.607-0.219-5.312-0.256-7.772-1.02c-4.59-1.423-5.314-4.736-2.182-8.321c1.287-1.473,2.797-2.874,3.646-4.584
       		c1.522-3.07,0.549-6.221-1.733-6.893c-6.817-2.009-14.401,0.344-18.675,6.101c-2.264,3.051-4.147,6.391-6.134,9.641
       		c-4.014,6.568-9.706,9.805-17.503,8.984c-1.152-0.122-2.311-0.164-3.282-0.231c-0.958,2.371-1.812,4.485-2.713,6.718
       		C282.778,576.406,287.5,593.897,304.637,601.773z"/>
      </Link>
       	<path onClick={() => tryNextLevel()} className="clickable nextLevel" fillRule="evenodd" clipRule="evenodd" fill="#000" d="M424.856,352.661c18.685-17.088,40.036-29.799,66.538-32.479
       		c12.5-1.265,24.636,0.17,36.783,2.677c2.368,0.488,4.159,1.423,4.813,3.949c0.227,0.877,0.728,1.684,1.12,2.515
       		c4.556,9.646,6.008,11.176,15.644,16.118c-2.367,1.841-4.336,3.721-6.621,5.059c-2.593,1.518-5.48,2.524-8.217,3.802
       		c-1.786,0.833-3.602,1.635-5.277,2.659c-4.047,2.471-7.658,6.255-6.506,10.754c0.742,2.901,4.896,5.55,8.111,7.068
       		c6.937,3.278,14.229,1.729,21.196-0.392c7.912-2.408,15.714-5.193,23.487-8.018c2.967-1.077,4.894-0.199,5.145,2.647
       		c0.629,7.154,0.559,14.372,1.264,21.515c0.766,7.781-1.952,13.055-8.168,18.15c-6.298,5.16-11.333,12.086-16.132,18.805
       		c-13.366,18.723-31.395,26.462-54.127,25.126c-26.689-1.568-50.826-9.345-70.963-27.462c-10.324-9.288-17.217-20.739-20.479-34.367
       		c-0.824-3.448-0.194-5.9,3.132-6.81c6.148-1.681,12.356-3.723,18.643-4.177c9.689-0.7,19.47-0.027,29.205-0.255
       		c5.447-0.127,10.663-1.423,14.91-5.301c1.73-1.58,1.988-3.003,0.824-5.13c-5.774-10.554-14.931-15.774-26.606-16.36
       		C443.298,352.29,433.978,352.661,424.856,352.661z M513.155,374.284c-0.933,3.021-1.851,5.318-2.333,7.703
       		c-0.909,4.5-1.206,9.153-2.457,13.542c-0.786,2.756-2.539,5.409-4.424,7.629c-1.752,2.063-3.864,1.428-4.869-1.14
       		c-0.76-1.944-1.093-4.05-1.756-6.038c-1.431-4.278-5.72-5.282-9.032-2.166c-8.715,8.199-9.156,19.286-1.124,28.158
       		c5.938,6.56,13.583,7.837,20.867,2.802c11.103-7.676,15.689-18.682,14.89-31.985C522.487,385.624,519.21,379.798,513.155,374.284z
       		 M513.688,346.526c0.073,0.681,0.147,1.362,0.221,2.043c1.772,0,3.544,0,5.317,0c-0.406-0.96-0.736-2.686-1.232-2.735
       		C516.594,345.696,515.127,346.247,513.688,346.526z"/>
      <Link to={"/archive/021"} className={activeIndex === 21 ? "active" : "inactive"} onClick={() => handleOnClick(21) } >
       	<path className="clickable reverse" fillRule="evenodd" clipRule="evenodd" fill="#000" d="M369.634,625.498c1.267,12.935,2.631,25.586,3.709,38.262
       		c0.923,10.863,2.206,21.765,2.021,32.628c-0.126,7.358-2.508,14.68-3.902,22.013c-0.408,2.15-1.685,2.876-3.923,2.865
       		c-27.519-0.154-55.037-0.263-82.556-0.279c-17.906-0.009-35.815,0.007-53.717,0.345c-3.954,0.075-5.537-1.418-6.749-4.828
       		c-3.223-9.061-4.385-18.445-4.297-27.929c0.147-16.046,0.782-32.087,1.15-48.132c0.111-4.852,0.017-9.708,0.017-15.364
       		c5.23-0.654,10.875-1.54,16.554-2.038c22.509-1.977,44.933,0.207,67.399,1.267c20.211,0.952,40.472,0.836,60.711,1.186
       		C367.329,625.516,368.607,625.498,369.634,625.498z M286.659,718.123c0-1.387,0.113-2.7-0.018-3.986
       		c-0.975-9.521-1.929-19.045-3.054-28.549c-0.293-2.475-0.121-4.011,2.858-4.273c2.335-0.205,4.625-0.922,6.935-1.417
       		c11.017-2.361,22.184-4.098,32.436-9.269c3.945-1.991,6.215-4.277,5.521-9.052c-0.75-5.167-0.609-10.482-1.607-15.586
       		c-0.372-1.907-2.767-4.613-4.409-4.74c-1.64-0.126-3.936,2.225-5.142,4.027c-1.576,2.357-2.299,5.27-3.504,7.892
       		c-2.257,4.914-4.834,6.442-10.226,6.171c-2.19-0.111-4.419-0.629-6.558-0.369c-5.732,0.699-8.516-0.869-10.516-6.434
       		c-1.02-2.835-1.764-5.812-3.125-8.473c-1.796-3.517-5.224-4.308-8.091-1.722c-2.685,2.422-5.836,5.107-6.955,8.314
       		c-2.171,6.222-4.546,12.979-4.17,19.356c0.763,12.904,2.994,25.757,5.211,38.527c1.853,10.663,2.227,10.598,13.131,9.995
       		C285.622,718.522,285.858,718.386,286.659,718.123z"/>
      </Link>
      <Link to={"/archive/017"} className={activeIndex === 17 ? "active" : "inactive"} onClick={() => handleOnClick(17) } >
       	<path className="clickable" fillRule="evenodd" clipRule="evenodd" fill="#000" d="M411.113,500.271c2.156-3.223,3.444-6.189,5.625-8.177
       		c3.671-3.346,8.006-5.949,11.875-9.093c3.139-2.552,5.948-5.506,9.022-8.144c0.985-0.845,2.329-1.739,3.54-1.785
       		c20.507-0.774,41.024-1.295,61.528-2.129c16.623-0.676,33.141-0.196,49.413,3.544c13.743,3.159,25.884,9.461,36.084,19.307
       		c0.913,0.882,1.735,1.859,3.347,3.597c-8.517,0.801-16.134,1.927-23.781,2.158c-19.993,0.604-39.998,1.107-59.998,1.107
       		c-21.578,0-43.155-0.702-64.733-0.92c-8.018-0.081-16.04,0.353-24.062,0.527C416.763,500.312,414.552,500.271,411.113,500.271z"/>
      </Link>
      <Link to={"/archive/016"} className={activeIndex === 16 ? "active" : "inactive"} onClick={() => handleOnClick(16) } >
       	<path className="clickable" fillRule="evenodd" clipRule="evenodd" d="M474.576,763.051c8.726-0.866,8.855-0.896,10.632,7.026
       		c2.526,11.264,5.231,22.51,7.122,33.886c1.524,9.167,0.848,18.376-3.461,26.941c-1.955,3.886-2.924,4.183-6.591,1.751
       		c-6.87-4.556-8.984-11.762-10.107-19.284C469.65,796.505,471.885,779.836,474.576,763.051z"/>
      </Link>
      <Link to={"/archive/020"} className={activeIndex === 20 ? "active" : "inactive"} onClick={() => handleOnClick(20) } >
       	<path className="clickable" fillRule="evenodd" clipRule="evenodd" d="M672.035,834.276c-1.361-5.515,0.168-9.553,4.743-12.601
       		c2.621-1.744,5.527-3.063,8.121-4.846c5.036-3.459,6.047-7.406,2.641-12.474c-1.927-2.867-4.696-5.284-7.431-7.474
       		c-8.725-6.98-10.652-13.939-6.381-24.27c5.516-13.337,18.343-22.192,31.455-21.718c5.33,0.193,10.197,2.264,14.201,6.074
       		c-1.121,3.551-3.738,5.666-7.132,6.694c-4.017,1.219-8.141,2.077-12.217,3.103c-1.4,0.353-2.828,0.638-4.183,1.125
       		c-6.945,2.501-7.945,7.173-1.842,11.481c3.104,2.19,7.51,3.579,11.307,3.539c8.248-0.09,16.473-1.521,24.729-2.013
       		c3.621-0.215,7.435,0.026,10.899,0.996c2.466,0.69,4.683,2.74,6.67,4.555c1.963,1.792,1.961,4.006-0.423,5.634
       		c-6.051,4.128-11.821,8.841-18.314,12.106c-11.184,5.625-22.019,11.621-32.273,18.876c-6.122,4.33-13.333,7.146-20.134,10.476
       		C675.168,834.181,673.492,834.057,672.035,834.276z"/>
      </Link>
      <Link to={"/archive/014"} className={activeIndex === 14 ? "active" : "inactive"} onClick={() => handleOnClick(14) } >
       	<path className="clickable" fillRule="evenodd" clipRule="evenodd" d="M731.443,539.81c-0.701,6.979-3.232,12.303-8.477,15.747
       		c-4.816,3.163-9.98,5.825-15.126,8.441c-2.377,1.209-3.505,2.515-3.385,5.37c0.279,6.566,0.45,13.17,0.046,19.721
       		c-0.192,3.114-1.805,6.141-2.772,9.206c-2.858-1.153-5.974-1.907-8.523-3.542c-5.529-3.544-9.247-8.928-10.739-15.129
       		c-2.603-10.831-4.371-21.865-6.397-32.832c-0.524-2.84-0.745-5.739-1.039-8.618c-0.348-3.403,2.474-5.512,5.449-3.846
       		c1.65,0.924,3.033,2.49,4.251,3.997c6.603,8.163,16.364,10.261,25.66,5.265c3.006-1.617,5.804-3.617,8.752-5.346
       		c3.273-1.92,6.74-2.256,10.176-0.542C730.307,538.194,730.977,539.328,731.443,539.81z"/>
      </Link>
      <Link to={"/archive/018"} className={activeIndex === 18 ? "active" : "inactive"} onClick={() => handleOnClick(18) } >
       	<path className="clickable" fillRule="evenodd" clipRule="evenodd" d="M260.014,827.74c10.44-24.911,28.03-43.715,50.405-58.976
       		c11.997,24.586,17.754,50.683,23.849,77.306c-5.146-0.898-8.973-3.269-11.593-6.802c-3.813-5.144-6.917-10.811-10.367-16.228
       		c-3.169-4.976-6.292-9.986-9.63-14.849c-2.381-3.469-5.618-4.127-9.114-1.622c-3.194,2.29-6.333,4.678-9.324,7.225
       		c-4,3.403-7.523,7.46-11.836,10.371c-3.441,2.32-7.756,3.345-11.684,4.946C260.484,828.655,260.249,828.197,260.014,827.74z"/>
      </Link>
      <Link to={"/archive/022"} className={activeIndex === 22 ? "active" : "inactive"} onClick={() => handleOnClick(22) } >
       	<path className="clickable" fillRule="evenodd" clipRule="evenodd" d="M519.781,595.572c-10.221-0.818-17.253-5.628-21.347-14.142
       		c-2.364-4.913-3.723-10.342-5.208-15.63c-0.589-2.102-1.366-3.015-3.591-3.196c-6.737-0.547-13.257-1.966-19.01-5.812
       		c-7.939-5.306-9.3-10-5.375-18.571c4.65-10.155,14.571-15.719,24.912-13.972c9.705,1.638,17.218,10.017,18.337,21.02
       		c0.435,4.281,0.661,8.793-0.207,12.952c-1.422,6.821,0.616,12.279,4.33,17.738c2.547,3.746,4.701,7.84,6.444,12.024
       		C519.961,590.127,519.569,592.809,519.781,595.572z M482.011,548.909c3.24-0.973,6.22-1.312,8.567-2.712
       		c3.259-1.939,2.845-5.293-0.78-6.293c-2.74-0.755-5.947-0.855-8.707-0.217c-2.961,0.684-4.421,3.665-3.707,6.5
       		C477.689,547.398,480.376,548.01,482.011,548.909z"/>
      </Link>
      <Link to={"/archive/013"} className={activeIndex === 13 ? "active" : "inactive"} onClick={() => handleOnClick(13) } >
       	<path className="clickable" fillRule="evenodd" clipRule="evenodd" d="M526.932,710.402c-5.542-1.379-9.771-3.922-12.552-7.986
       		c-3.991-5.833-7.196-12.195-11.04-18.136c-3.05-4.713-6.494-9.18-9.886-13.66c-1.36-1.797-3.329-2.166-5.233-0.84
       		c-4.071,2.833-8.179,5.639-12.044,8.739c-4.188,3.36-7.833,7.469-12.258,10.439c-3.114,2.091-7.137,2.827-11.009,3.69
       		c10.053-25.598,27.672-44.436,50.102-59.738C515.057,657.508,520.757,683.653,526.932,710.402z"/>
      </Link>
      <Link to={"/archive/019"} className={activeIndex === 19 ? "active" : "inactive"} onClick={() => handleOnClick(19) } >
       	<path className="clickable" fillRule="evenodd" clipRule="evenodd" d="M685.114,706.018c-4.111-7.674-7.302-14.427-11.211-20.736
       		c-5.02-8.097-10.148-16.042-10.987-25.86c-0.189-2.213-0.218-4.44-0.335-6.96c7.087,0.706,12.402,4.067,16.585,9.135
       		c3.847,4.662,7.432,9.562,10.838,14.56c1.999,2.934,4.551,3.849,7.396,2.345c4.581-2.425,9.113-5.082,13.215-8.23
       		c3.732-2.867,6.713-6.691,10.29-9.788c5.746-4.975,12.175-5.71,19.488-2.14c-11.84,18.831-22.382,38.612-45.802,45.56
       		C691.119,704.932,687.507,705.493,685.114,706.018z"/>
      </Link>
      <Link to={"/archive/015"} className={activeIndex === 15 ? "active" : "inactive"} onClick={() => handleOnClick(15) } >
       	<path className="clickable" fillRule="evenodd" clipRule="evenodd" d="M304.637,601.773c-17.137-7.876-21.858-25.367-32.274-37.97
       		c0.902-2.233,1.755-4.347,2.713-6.718c0.971,0.067,2.13,0.11,3.282,0.231c7.797,0.821,13.489-2.416,17.503-8.984
       		c1.987-3.25,3.87-6.589,6.134-9.641c4.274-5.757,11.858-8.11,18.675-6.101c2.282,0.672,3.255,3.822,1.733,6.893
       		c-0.849,1.711-2.359,3.112-3.646,4.584c-3.133,3.584-2.409,6.898,2.182,8.321c2.46,0.764,5.165,0.8,7.772,1.02
       		c4.058,0.342,8.219,0.138,12.149,1.009c1.947,0.432,4.377,2.475,4.922,4.291c0.471,1.565-0.894,4.834-2.365,5.611
       		c-4.081,2.155-8.53,3.927-13.038,4.879c-6.021,1.271-12.239,1.597-18.362,2.406c-1.289,0.17-2.569,0.582-3.792,1.046
       		c-6.186,2.346-7.6,5.148-6.003,11.604c0.974,3.942,2.033,7.871,2.764,11.859C305.282,597.727,304.807,599.481,304.637,601.773z"/>
      </Link>
       	<path onClick={() => tryNextLevel()} className="clickable" fillRule="evenodd" clipRule="evenodd" d="M513.155,374.284c6.055,5.515,9.332,11.34,9.762,18.506
       		c0.799,13.303-3.787,24.31-14.89,31.985c-7.284,5.035-14.928,3.758-20.867-2.802c-8.033-8.873-7.591-19.959,1.124-28.158
       		c3.312-3.116,7.602-2.112,9.032,2.166c0.663,1.987,0.997,4.094,1.756,6.038c1.005,2.567,3.117,3.203,4.869,1.14
       		c1.885-2.22,3.638-4.873,4.424-7.629c1.251-4.39,1.548-9.043,2.457-13.542C511.305,379.602,512.223,377.305,513.155,374.284z"/>
       	<path fillRule="evenodd" clipRule="evenodd" d="M513.688,346.526c1.439-0.279,2.906-0.83,4.306-0.691
       		c0.496,0.049,0.826,1.774,1.232,2.735c-1.773,0-3.545,0-5.317,0C513.835,347.888,513.761,347.207,513.688,346.526z"/>
      <Link to={"/archive/021"} className={activeIndex === 21 ? "active" : "inactive"} onClick={() => handleOnClick(21) } >
       	<path className="clickable" fillRule="evenodd" clipRule="evenodd" d="M286.659,718.123c-0.8,0.263-1.037,0.399-1.281,0.412
       		c-10.905,0.603-11.279,0.667-13.131-9.995c-2.218-12.77-4.449-25.623-5.211-38.527c-0.376-6.377,1.999-13.135,4.17-19.356
       		c1.119-3.207,4.27-5.892,6.955-8.314c2.867-2.586,6.295-1.795,8.091,1.722c1.36,2.662,2.105,5.638,3.125,8.473
       		c2,5.565,4.784,7.133,10.516,6.434c2.138-0.261,4.367,0.258,6.558,0.369c5.391,0.271,7.968-1.257,10.226-6.171
       		c1.205-2.622,1.928-5.535,3.504-7.892c1.206-1.802,3.501-4.153,5.142-4.027c1.642,0.126,4.037,2.833,4.409,4.74
       		c0.998,5.104,0.857,10.418,1.607,15.586c0.694,4.775-1.576,7.061-5.521,9.052c-10.251,5.171-21.418,6.908-32.436,9.269
       		c-2.31,0.494-4.6,1.211-6.935,1.417c-2.979,0.263-3.151,1.799-2.858,4.273c1.125,9.504,2.079,19.028,3.054,28.549
       		C286.772,715.423,286.659,716.735,286.659,718.123z"/>
      </Link>
       	<path fillRule="evenodd" clipRule="evenodd" fill="#000" d="M482.011,548.909c-1.635-0.899-4.322-1.511-4.627-2.722
       		c-0.714-2.836,0.746-5.817,3.707-6.5c2.76-0.638,5.967-0.539,8.707,0.217c3.625,1,4.04,4.354,0.78,6.293
       		C488.23,547.597,485.25,547.936,482.011,548.909z"/>
       </g>
    </svg>
    {renderConfirmation()}
    </div>
  )
}
